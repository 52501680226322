import React from 'react'
import { SelectButton } from 'primereact/selectbutton'
import { useCurrentOrganization } from '../App'

function DosePrnPermissionInput({
  value,
  onChange,
  disabled,
}) {
  const organization = useCurrentOrganization()
  return (
    <SelectButton
      name="PRN"
      disabled={disabled}
      options={[
        { label: organization.patientLabelSingular, value: 'client' },
        { label: organization.caregiverLabelSingular, value: 'staff' },
        { label: 'Both', value: 'both' },
        { label: 'None', value: 'no_restriction' },
      ]}
      value={value}
      onChange={(e) => onChange(e.value)}
    />
  )
}

export default DosePrnPermissionInput
