import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'

const useIntegrationDataMessage = (messageUuid) => useQuery({
  queryKey: ['integrationDataMessage', messageUuid],
  queryFn: () => ApiClient.get(`/integration_data/for_message?message_uuid=${messageUuid}`),
  enabled: !!messageUuid,
})

export default useIntegrationDataMessage
