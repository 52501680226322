import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'

export const useConsumptionHistory = ({ consumption }) => {
  const { id } = consumption || {}

  const query = useQuery({
    queryKey: ['consumptionHistory', id],
    queryFn: () => ApiClient.get(`/consumptions/${id}/history`),
    enabled: !!id,
  })

  return useMemo(() => ({
    ...query,
    data: query.data || {},
  }), [query])
}

export default useConsumptionHistory
