import React, { useState, useEffect } from 'react'
import { filter } from 'lodash'
import { Card } from 'primereact/card'
import { Divider } from 'primereact/divider'
import { Chart } from 'primereact/chart'
import { Skeleton } from 'primereact/skeleton'
import { dateTemplate } from '@services/utils'

const dateLabelTemplate = (datestring) => {
  const date = new Date(datestring)
  return new Date(
    date.getTime(),
  ).toLocaleDateString('en-us', { weekday: 'long', month: 'short', day: 'numeric' })
}

function ConsumptionsChart({
  title = 'Medication Adherence', consumptions, isLoading,
}) {
  const [chartData, setChartData] = useState({})

  const today = new Date()
  const currentWeek = Array.from({ length: 7 }).map((_, index) => {
    const next = new Date()
    next.setDate(today.getDate() - index)
    return { date: next, label: dateLabelTemplate(next) }
  }).reverse()

  const calculateWeeklyConsumptions = (status) => currentWeek.map((day) => {
    const daysConsumptions = filter(consumptions, (consumption) => {
      if (dateTemplate(consumption.expectedAt) === dateTemplate(day.date)) {
        return consumption.status === status
      }
    })
    return daysConsumptions.length
  })

  useEffect(() => {
    if (consumptions) {
      const data = {
        labels: currentWeek.map((date) => date.label),
        datasets: [
          {
            label: 'On Time',
            backgroundColor: '#caf1d8',
            borderColor: '#4cd07d',
            data: calculateWeeklyConsumptions('confirmed'),
          },
          {
            label: 'Missed',
            backgroundColor: '#ffd0ce',
            borderColor: '#ff6259',
            data: calculateWeeklyConsumptions('missed'),
          },
          {
            label: 'Refused',
            backgroundColor: '#dadee3',
            borderColor: '#818ea1',
            data: calculateWeeklyConsumptions('refused'),
          },
          {
            label: 'LOA',
            // TODO: Maybe pick better colors
            backgroundColor: '#bab6b8',
            borderColor: '#bab6b8',
            data: calculateWeeklyConsumptions('LOA'),
          },
        ],
      }
      setChartData(data)
    }
    return () => setChartData({})
  }, [consumptions])
  const chartStyle = { position: 'relative', height: '200px' }

  return (
    <Card className="h-full">
      <div className="flex flex-column gap-3 text-base text-900">
        <div className="flex flex-row justify-content-between align-items-center">
          <span className="text-lg">
            <i className="pi pi-chart-bar mr-2" />
            {title}
          </span>
        </div>
        <Divider className="my-0" />
        { isLoading
          && (
          <div className="flex flex-column gap-2">
            <Skeleton className="inline-flex align-self-center" width="50%" height="1.25rem" />
            <Skeleton className="inline-flex" width="100%" height="9rem" />
          </div>
          )}
        { !isLoading
          && (
          <Chart
            className="fadein"
            style={chartStyle}
            type="bar"
            data={chartData}
            options={{ responsive: true, maintainAspectRatio: false, animations: false }}
          />
          )}
      </div>
    </Card>
  )
}

export default ConsumptionsChart
