import React, { useState, useRef, useCallback } from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'
import { useQueryClient } from '@tanstack/react-query'
import Cropper from 'react-easy-crop'
import { Slider } from 'primereact/slider'
import OverlayAction from '@components/display/OverlayAction'
import useAvatarMutation from './patientAvatarHooks'

// 10MB
const MAX_UPLOAD_SIZE = 1024 * 1024 * 10
const IMAGE_SIZE = {
  small: {
    className: 'max-w-4rem',
    overlay: '64px',
    text: 'text-base',
  },
  large: {
    className: 'max-w-10rem',
    overlay: '160px',
    text: 'text-lg',
  },
}

function AvatarWithEdit({ src, size = 'small' }) {
  const textSizeClassName = IMAGE_SIZE[size].text
  return (
    <OverlayAction
      icon="pi pi-camera"
      text="Change"
      textClassName={textSizeClassName}
      overlayEnabled
      overlayClassName="border-circle"
    >
      <img
        className="w-full overflow-hidden border-circle relative top-0 left-0 shadow"
        src={src}
        alt="Current Avatar"
      />
    </OverlayAction>
  )
}

export function PatientAvatar({ patient, size = 'small' }) {
  const [currentAvatar, setCurrentAvatar] = useState(patient?.avatar?.url)
  const [cropDialogVisible, setCropDialogVisible] = useState(false)
  const [dialogReady, setDialogReady] = useState(false)

  const [fileToCrop, setFileToCrop] = useState('')

  // Controls the placement of the crop overlay
  const [currentCrop, setCurrentCrop] = useState({ x: 0, y: 0 })
  // Controls the zoom of the image behind the crop overlay
  const [zoom, setZoom] = useState(1)
  // Provides a complete x, y, width, height to feed to e.g. canvas.drawImage
  const [confirmedCrop, setConfirmedCrop] = useState(null)
  // Called whenever the zoom or
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setConfirmedCrop(croppedAreaPixels)
  }, [])

  const fileInputRef = useRef(null)
  const cropImgRef = useRef(null)
  const uploadErrorMessage = useRef(null)
  const queryClient = useQueryClient()

  const readFileAsDataURL = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () => {
      resolve(reader.result)
    }

    reader.onerror = (error) => {
      reject(error)
    }

    reader.readAsDataURL(file)
  })

  const showCropPhotoDialogNew = async (file) => {
    try {
      const dataURL = await readFileAsDataURL(file)

      setCropDialogVisible(true)
      setFileToCrop(dataURL)
      fileInputRef.current.value = ''
    } catch (error) {
      uploadErrorMessage.current.show([
        { severity: 'warn', summary: 'There was an error processing the file' },
      ])
    }
  }

  const {
    isLoading,
    mutateAsync,
  } = useAvatarMutation(uploadErrorMessage, setCurrentAvatar)

  const onCropConfirm = () => {
    setCropDialogVisible(false)
    mutateAsync({
      patientId: patient.id,
      image: fileToCrop,
      crop: confirmedCrop,
      queryClient,
    })
    setFileToCrop(null)
  }

  const emptyImage = (
    <Button disabled={isLoading} className="p-button-text p-button-rounded flex flex-column justify-content-center surface-100 hover:bg-blue-50" style={{ height: IMAGE_SIZE[size].overlay, width: IMAGE_SIZE[size].overlay }}>
      {isLoading && (
        <i className="pi pi-spin pi-spinner" style={{ fontSize: '2.5rem', color: '#6c757d' }} />
      )}
      {!isLoading && (
        <>
          <i className="pi pi-camera text-lg" style={{ color: 'var(--text-color-secondary)' }} />
          <p className={`${IMAGE_SIZE[size].text} m-0`} style={{ color: 'var(--text-color-secondary)' }}>Upload</p>
        </>
      )}
    </Button>
  )

  const avatarImage = <AvatarWithEdit src={currentAvatar} size={size} />
  const avatarMaxWidthClass = IMAGE_SIZE[size].className

  return (
    <div>
      <Toast ref={uploadErrorMessage} />
      <input
        className="hidden"
        type="file"
        accept="image/jpeg"
        ref={fileInputRef}
        onChange={() => {
          const file = fileInputRef.current.files[0]
          if (file) {
            if (file.size > MAX_UPLOAD_SIZE) {
              uploadErrorMessage.current.show([
                { severity: 'warn', summary: 'Image file is too large, maximum is 10MB' },
              ])
              return
            }
          }
          showCropPhotoDialogNew(file)
        }}
      />
      <div onClick={() => fileInputRef.current.click()} className={`${avatarMaxWidthClass} flex ml-1`}>
        {currentAvatar ? avatarImage : emptyImage}
      </div>
      <Dialog
        onShow={() => {
          setDialogReady(true)
        }}
        resizable={false}
        visible={cropDialogVisible}
        header="Crop Image"
        onHide={() => setCropDialogVisible(false)}
        style={{ maxWidth: '900px', maxHeight: '650px' }}
      >
        <div style={{ position: 'relative', height: '360px', width: '600px' }}>
          <Cropper
            image={dialogReady ? fileToCrop : null}
            crop={currentCrop}
            zoom={zoom}
            minZoom={0.5}
            cropShape="round"
            aspect={1}
            objectFit="cover"
            onCropChange={setCurrentCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            setImageRef={(image) => { cropImgRef.current = image }}
          />
        </div>
        <Slider
          className="my-4"
          min={0.5}
          max={3}
          step={0.01}
          value={zoom}
          onChange={(e) => setZoom(e.value)}
        />
        <div className="flex justify-content-start gap-3">
          <Button
            className="p-button-sm"
            label="Confirm"
            onClick={onCropConfirm}
          />
          <Button
            className="p-button-sm"
            label="Cancel"
            onClick={() => setCropDialogVisible(false)}
          />
        </div>
      </Dialog>
    </div>
  )
}

export default PatientAvatar
