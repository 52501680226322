import { useMemo } from 'react'
import { get, map } from 'lodash'
import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'

export const useDoseFills = (model, id, status) => {
  const path = model === 'Dose'
    ? `/doses/${id}/dose_fills?status=${status}`
    : `/dose_fills?model=${model}&id=${id}`

  const query = useQuery({
    queryKey: ['doseFills', path],
    queryFn: () => ApiClient.get(path),
    enabled: !!id,
  })

  return useMemo(() => ({
    ...query,
    data: map(get(query, 'data', [])),
  }), [query])
}

export default useDoseFills
