import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { map, get } from 'lodash'
import {
  errorMessageFromError,
  formErrorsFromError,
} from '@services/errorHandler'

export function usePharmaciesQuery(statusMessage) {
  const query = useQuery({
    queryKey: ['pharmacies'],
    queryFn: () => ApiClient.get('/pharmacies'),
    onError: (error) => {
      statusMessage.current.clear()
      statusMessage.current.show([
        errorMessageFromError(error),
        ...formErrorsFromError(error),
      ])
    },
  })

  return useMemo(() => ({
    ...query,
    data: map(get(query, 'data.pharmacies'), (pharmacy) => (pharmacy)),
  }), [query.data])
}

export function usePharmacyQuery(pharmacyId, statusMessage) {
  return useQuery({
    queryKey: ['pharmacy', pharmacyId],
    queryFn: () => ApiClient.get(`/pharmacies/${pharmacyId}`),
    onError: () => statusMessage.current.show([
      {
        severity: 'error',
        sticky: true,
        summary: 'Unable to fetch pharmacy at this time.',
      },
    ]),
  })
}

export function usePharmacyCreateMutation(statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['pharmacyCreate'],
    mutationFn: (pharmacy) => ApiClient.post('/pharmacies', pharmacy),
    onSuccess: () => {
      queryClient.invalidateQueries('pharmacies')
    },
    onError: async (error) => {
      if (!statusMessage) return
      await statusMessage.current.clear()
      statusMessage.current.show([
        errorMessageFromError(error),
      ])
    },
  })
}

export function usePharmacyUpdateMutation(pharmacyId, statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['pharmacyUpdate', pharmacyId],
    mutationFn: (pharmacy) => ApiClient.put(`/pharmacies/${pharmacyId}`, pharmacy),
    onSuccess: () => {
      statusMessage.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Pharmacy details updated',
      })
      queryClient.invalidateQueries(['pharmacy', pharmacyId])
      queryClient.invalidateQueries('pharmacies')
    },
    onError: async (error) => {
      if (!statusMessage) return
      await statusMessage.current.clear()
      statusMessage.current.show([
        errorMessageFromError(error),
      ])
    },
  })
}

export function usePharmacyDeleteMutation(pharmacyId, statusMessage) {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation({
    mutationKey: ['pharmacyDelete', pharmacyId],
    mutationFn: () => ApiClient.delete(`/pharmacies/${pharmacyId}`),
    onSuccess: () => {
      queryClient.invalidateQueries(['pharmacy', pharmacyId])
      queryClient.invalidateQueries('pharmacies')
      navigate('/admin/pharmacies')
    },
    onError: () => {
      if (!statusMessage) return
      statusMessage.current.show([
        {
          severity: 'error',
          sticky: true,
          summary: 'Unable to delete pharmacy at this time.',
        },
      ])
    },
  })
}
