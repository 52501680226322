import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'

export const useTaskOccurrenceHistory = ({ taskOccurrence }) => {
  const { id } = taskOccurrence || {}

  const query = useQuery({
    queryKey: ['taskOccurrenceHistory', id],
    queryFn: () => ApiClient.get(`/task_occurrences/${id}/history`),
    enabled: !!id,
  })

  return useMemo(() => ({
    ...query,
    data: query.data || {},
  }), [query])
}

export default useTaskOccurrenceHistory
