import React from 'react'
import InputTextWithLabel from '@components/display/InputTextWithLabel'
import { useCurrentUser } from '@components/App'

function ProfileForm({ profile = {}, setProfile }) {
  const {
    email, id, initials, phone, firstName, lastName, customerId, isDirectoryManaged = false,
  } = profile

  const currentUser = useCurrentUser()
  const isSuperAdminOrSiteAdmin = ['superadmin', 'site_admin'].includes(currentUser.role)
  const canEditInitials = !!id

  return (
    <div className="flex flex-column">
      {
        isDirectoryManaged && (
          <div className="text-sm opacity-80 mb-3">
            Disabled settings are managed by your organization's IT administrators.
          </div>
        )
      }

      {
        (canEditInitials && isSuperAdminOrSiteAdmin) && (
        <InputTextWithLabel
          label="Initials"
          value={initials || ''}
          inputId="initials"
          name="initials"
          onChange={({ target: { value } }) => setProfile({ initials: value.toUpperCase() })}
        />
        )
      }

      <InputTextWithLabel
        label="Email"
        value={email || ''}
        inputId="email"
        name="email"
        onChange={({ target: { value } }) => setProfile({ email: value })}
        disabled={isDirectoryManaged}
      />
      <InputTextWithLabel
        label="Phone"
        value={phone || ''}
        inputId="phone"
        name="phone"
        onChange={({ target: { value } }) => setProfile({ phone: value })}
      />
      <InputTextWithLabel
        label="First Name*"
        value={firstName || ''}
        inputId="firstName"
        name="firstName"
        onChange={({ target: { value } }) => setProfile({ firstName: value })}
        disabled={isDirectoryManaged}
      />
      <InputTextWithLabel
        label="Last Name*"
        value={lastName || ''}
        inputId="lastName"
        name="lastName"
        onChange={({ target: { value } }) => setProfile({ lastName: value })}
        disabled={isDirectoryManaged}
      />
      <InputTextWithLabel
        label="Employee ID"
        value={customerId || ''}
        inputId="employeeId"
        name="employeeId"
        onChange={({ target: { value } }) => setProfile({ customerId: value })}
      />
    </div>
  )
}

export default ProfileForm
