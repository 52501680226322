import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'
import { useMutation, useQueryClient } from '@tanstack/react-query'

const useDoseHoldDelete = (statusMessage) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['deleteDoseHold'],
    mutationFn: (doseHoldId) => ApiClient.delete(`/dose_holds/${doseHoldId}`),
    onSuccess: () => {
      queryClient.invalidateQueries('doseHolds')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export default useDoseHoldDelete
