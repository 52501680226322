import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import SuperAdminCard from '@components/display/SuperAdminCard'
import { useIntegrationDataMessage } from '@hooks/integrationData'

function IntegrationData({ useHeader }) {
  const params = useParams()
  const { messageUuid } = params
  const { setHeader } = useHeader()

  const { data: integrationData, isLoading } = useIntegrationDataMessage(messageUuid)

  const pageTitle = {
    label: (
      <span>
        <i className="text-xl pi pi-file-import mr-2" />
        Integration Data
      </span>
    ),
    linkTo: '/admin/integrations',
  }

  useEffect(() => {
    setHeader({ title: pageTitle, primaryAction: null })
  }, [])

  return (
    <div id="integration-data" className="flex flex-column">
      <div className="flex flex-row flex-wrap">
        <div className="flex flex-column gap-1 col-6">
          <SuperAdminCard title="Mirth Message" isLoading={isLoading}>
            <pre>{JSON.stringify(integrationData?.originalMessage, null, 2)}</pre>
          </SuperAdminCard>
        </div>
      </div>
    </div>
  )
}

export default IntegrationData
