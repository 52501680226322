import React, { useEffect, useState } from 'react'
import { find, uniq } from 'lodash'
import { useParams } from 'react-router-dom'
import { Divider } from 'primereact/divider'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { useCurrentOrganization } from '@components/App'
import Exports from '@components/patients/Exports'
import SearchField from '@components/display/Form/SearchField'
import { exactSearch } from '@services/utils/search'
import {
  usePatientCaregivers,
  usePossiblePatientCaregivers,
  usePatientCaregiversMutation,
} from './caregiverListHooks'

function CaregiverList({ patient, handleSetHeader }) {
  const { patientId } = useParams()
  const [isEditing, setIsEditing] = useState(false)
  const [selectedCaregivers, setSelectedCaregivers] = useState([])
  const [searchTerm, setSearchTerm] = useState()
  const [filteredCaregivers, setFilteredCaregivers] = useState([])

  const organization = useCurrentOrganization()

  const {
    data: assignedCaregivers,
    isLoading: isLoadingAssignedCaregivers,
    refetch: assignedRefetch,
  } = usePatientCaregivers(patientId)

  const {
    data: possibleCaregivers,
    isLoading: isLoadingPossibleCaregivers,
  } = usePossiblePatientCaregivers(patientId)

  const mutation = usePatientCaregiversMutation(patientId)

  const saveCaregivers = async () => {
    const caregiverIds = selectedCaregivers.map((caregiver) => caregiver.id)
    await mutation.mutateAsync({ caregiverIds, siteId: patient.site?.id })
    await assignedRefetch()
    setIsEditing(false)
    setSearchTerm()
  }

  const isRowSelectable = ({ data }) => {
    const sharedSite = find(data.caregiverSites, ({ siteId }) => siteId === patient?.site?.id)
    if (sharedSite && sharedSite.clientAccess === 'all') {
      return false
    }
    return true
  }

  const rowClassName = (data) => {
    let className = 'fadein cursor-pointer'
    if (isEditing && !isRowSelectable({ data })) {
      className += ' p-disabled '
    }
    return className
  }

  const handleSelection = ({ value }) => {
    const unSelectable = assignedCaregivers.filter(
      (caregiver) => !isRowSelectable({ data: caregiver }),
    )
    const rows = uniq([...unSelectable, ...value])
    setSelectedCaregivers(rows)
    setSearchTerm()
  }

  useEffect(() => {
    if (!isEditing) {
      setSelectedCaregivers([])
    } else {
      setSelectedCaregivers(assignedCaregivers)
    }
  }, [isEditing])

  useEffect(() => {
    if (isEditing) {
      setFilteredCaregivers(exactSearch(possibleCaregivers, ['firstName', 'lastName'], searchTerm))
    } else {
      setFilteredCaregivers(exactSearch(assignedCaregivers, ['firstName', 'lastName'], searchTerm))
    }
  }, [assignedCaregivers, possibleCaregivers, searchTerm, isEditing])

  const primaryActionSetup = (action) => {
    handleSetHeader({ primaryAction: action })
  }

  return (
    <div className="col-12">
      <Exports
        patientId={patientId}
        exportsMenuSetupCallBack={primaryActionSetup}
      />
      <Card className="h-full">
        <div className="flex flex-column gap-3 text-base text-900">
          <div className="flex flex-row justify-content-between align-items-center px-1">
            <SearchField
              className="w-18rem"
              isLoading={isLoadingAssignedCaregivers || isLoadingPossibleCaregivers}
              placeholder={`Search ${organization.caregiverLabelPlural} by name`}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
            { !isEditing
              && (
              <Button
                label="Edit Assignments"
                className="p-button-sm"
                onClick={() => setIsEditing(true)}
              />
              )}
            { isEditing
              && (
              <Button
                className="p-button-sm"
                icon="pi pi-times"
                onClick={() => setIsEditing(false)}
              />
              )}
          </div>
          <Divider className="my-0" />
          <DataTable
            value={filteredCaregivers}
            loading={
              isEditing
                ? isLoadingPossibleCaregivers
                : isLoadingAssignedCaregivers
            }
            emptyMessage={isEditing ? 'No caregivers available' : 'No caregivers assigned'}
            selection={isEditing ? selectedCaregivers : assignedCaregivers}
            onSelectionChange={handleSelection}
            isDataSelectable={isRowSelectable}
            dataKey="id"
            sortField="lastName"
            sortOrder={1}
            rowClassName={rowClassName}
            onRowClick={(e) => {
              if (isEditing) return
              window.location.href = `/admin/caregivers/${e.data.id}`
            }}
          >
            {isEditing
              && <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />}
            <Column field="firstName" header="First Name" />
            <Column field="lastName" header="Last Name" sortable />
            <Column field="email" header="Email" />
            <Column field="phone" header="Phone" />
          </DataTable>
          <div className="flex flex-row">
            {isEditing
              && (
              <Button
                label="Save Assignments"
                className="p-button-sm p-button-success mb-3"
                loading={mutation.isLoading || isLoadingAssignedCaregivers}
                onClick={() => saveCaregivers()}
              />
              )}
          </div>
        </div>
      </Card>
    </div>
  )
}

export default CaregiverList
