import React from 'react'
import { Link } from 'react-router-dom'
import { DataView } from 'primereact/dataview'
import { DoseDetails } from '@components/pharmacyOrders/Details'
import { Divider } from 'primereact/divider'

function OrderGroup({
  doseDirectives, compositeOrder, groupKey, prescription,
}) {
  return (
    <Link to={groupKey} className="w-full cursor-pointer hover:bg-blue-50">
      <DoseDetails
        doseDirectives={doseDirectives}
        pharmacyMedicine={compositeOrder.pharmacyMedicine}
        orderType={compositeOrder.orderType}
        prescription={prescription}
        sigs={compositeOrder.sigs}
      />
      <Divider className="my-0" />
    </Link>
  )
}

function ReviewList({ reviewableOrders, isLoading }) {
  return (
    <div className="flex flex-column">
      <DataView value={reviewableOrders} itemTemplate={OrderGroup} loading={isLoading} />
    </div>
  )
}

export default ReviewList
