// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".slotIdentifier-module__slotVisualRow___f8sPI{height:10px}.slotIdentifier-module__visualSlot___1u2KV{width:8px;height:8px;border-radius:30%;display:inline-block;margin:1px;background-color:#bcc3cd}.slotIdentifier-module__highlightedSlot___3iade{width:8px;height:8px;border-radius:30%;display:inline-block;margin:1px;background-color:#3b82f6 !important}", "",{"version":3,"sources":["/var/app/app/javascript/components/inventoryManagement/SlotIdentifier/slotIdentifier.module.sass"],"names":[],"mappings":"AAAA,8CACE,WAAA,CAEF,2CACE,SAAA,CACA,UAAA,CACA,iBAAA,CACA,oBAAA,CACA,UAAA,CACA,wBAAA,CAEF,gDACE,SAAA,CACA,UAAA,CACA,iBAAA,CACA,oBAAA,CACA,UAAA,CACA,mCAAA","file":"slotIdentifier.module.sass","sourcesContent":[".slotVisualRow\n  height: 10px\n\n.visualSlot\n  width: 8px\n  height: 8px\n  border-radius: 30%\n  display: inline-block\n  margin: 1px\n  background-color: #bcc3cd\n\n.highlightedSlot\n  width: 8px\n  height: 8px\n  border-radius: 30%\n  display: inline-block\n  margin: 1px\n  background-color: #3b82f6 !important\n"]}]);
// Exports
exports.locals = {
	"slotVisualRow": "slotIdentifier-module__slotVisualRow___f8sPI",
	"visualSlot": "slotIdentifier-module__visualSlot___1u2KV",
	"highlightedSlot": "slotIdentifier-module__highlightedSlot___3iade"
};
module.exports = exports;
