import ApiClient from '@services/ApiClient'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { displayErrorMessages } from '@services/errorHandler'

// Stop use update for assign drawer, instead use `simpills/:id/assign_ekit_drawer`
// and `simpills/:id/assign_slot`
export function useAssignPendingDoseFill(doseId, statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['loadPendingDoseFill', doseId],
    mutationFn: async ({ doseFillId, drawerId, slotId }) => {
      if (drawerId) {
        return ApiClient.post('/ekits/drawer_assignments', { doseFillId, drawerId })
      }
      if (slotId) {
        return ApiClient.post('/ekits/slot_assignments', { doseFillId, slotId })
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('ekitDoses')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useLoadDose(doseId, statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['loadDose'],
    mutationFn: (props) => ApiClient.post(`/simpills/${props.deviceId}/assign_drawer`, { drawer: props.drawer, doseIds: [doseId] }),
    onSuccess: () => {
      queryClient.invalidateQueries('ekitDoses')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}
