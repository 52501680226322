import React from 'react'

function ApprovalOverlay({ marApprovals }) {
  return (
    <div className="date-overlay-content">
      <div className="date-overlay-title">
        {
        marApprovals.map((approval) => (
          <div key={approval.id} className="date-overlay-approval">
            Approved by
            {' '}
            {approval.approvedBy.fullName}
          </div>
        ))
      }
      </div>
      <div className="date-overlay-body" />
    </div>
  )
}

export default ApprovalOverlay
