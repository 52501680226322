import React, { useState, useEffect } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { useUpdateNotificationSetting } from '@hooks/notificationSettings'
import { offsetOptions, getFriendlyTime } from './utils'

function EditNotificationSettingModal({
  visible, onHide, notificationSetting, statusMessage,
}) {
  const {
    mutate: updateNotificationSettings,
    isLoading,
  } = useUpdateNotificationSetting(
    notificationSetting.id,
    statusMessage,
  )

  const [selectedOffset, setSelectedOffset] = useState(notificationSetting.offset)

  const handleSave = async () => {
    updateNotificationSettings({
      id: notificationSetting.id,
      offset: selectedOffset,
    }, {
      onSuccess: () => {
        onHide()
      },
    })
  }

  const handleCancel = () => {
    onHide()
  }

  useEffect(() => {
    if (visible) {
      setSelectedOffset(notificationSetting.offset)
    }
  }, [visible, setSelectedOffset, notificationSetting])

  return (
    <Dialog
      header={`Notification - ${notificationSetting.title}`}
      visible={visible}
      style={{ width: '50vw' }}
      onHide={onHide}
      className="editNotificationSettingModal"
      headerClassName="pb-0"
      modal
    >
      <div className="p-fluid">
        <div className="field">
          <label>{getFriendlyTime(notificationSetting.notificationType, selectedOffset)}</label>
        </div>
        <div className="field">
          <label htmlFor="offset">Offset</label>
          <Dropdown
            id="offset"
            value={selectedOffset}
            options={offsetOptions}
            optionValue="minutes"
            optionLabel="title"
            onChange={
              (e) => {
                setSelectedOffset(e.target.value)
              }
            }
          />
        </div>
        <div className="field flex justify-end gap-2">
          <Button label="Cancel" className="p-button-secondary" onClick={handleCancel} severity="secondary" outlined />
          <Button
            label="Save"
            onClick={handleSave}
            className="mr-2"
            severity="secondary"
            loading={isLoading}
            disabled={isLoading}
            raised
          />
        </div>
      </div>
    </Dialog>
  )
}

export default EditNotificationSettingModal
