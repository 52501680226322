import React from 'react'
import { Tooltip } from 'primereact/tooltip'
import AttributeCard from '@components/display/AttributeCard'
import { useCurrentUser } from '@components/App'

function SuperAdminCardTitle({ title }) {
  return (
    <div className="flex flex-row">
      <div className="mr-2">
        <Tooltip
          content="This card is only visible to Super Admins"
          position="right"
          target=".superadmin-icon"
        />
        <i className="superadmin-icon fa-solid fa-staff-snake text-primary text-xl" />
      </div>
      <div>{title}</div>
    </div>
  )
}

function SuperAdminCard({ children, title, ...props }) {
  const { role } = useCurrentUser()

  if (role !== 'superadmin') {
    return null
  }

  return (
    <AttributeCard
      title={{ body: (<SuperAdminCardTitle title={title} />) }}
      {...props}
    >
      {children}
    </AttributeCard>
  )
}

export default SuperAdminCard
