import React, { useState, useEffect } from 'react'
import { find, first } from 'lodash'
import { Fieldset } from 'primereact/fieldset'
import { Timeline } from 'primereact/timeline'
import MedicineName from './Name'
import MedicinePhysicalForm from './PhysicalForm'
import MedicineStrength from './Strength'
import MedicinePurpose from './Purpose'

import { useRxTermSearch } from '../../../features/ClientDoses/clientDosesHooks'
import { csaScheduleText } from '../../medicines/csaScheduleLabel'

function MedicationFieldset({
  medication = {}, setActiveField, isEdit, handleCompletion,
}) {
  const [activeStep, setActiveStep] = useState(null)
  const [name, setName] = useState()
  const [csaSchedule, setCsaSchedule] = useState()
  const [form, setForm] = useState()
  const [strength, setStrength] = useState()
  const [purpose, setPurpose] = useState()
  const [medicineSearchTerm, setMedicineSearchTerm] = useState()

  const [rxTermSearchResults] = useRxTermSearch(medicineSearchTerm)

  const steps = [
    { label: 'Name', value: name },
    { label: 'Physical Form', value: form },
    { label: 'Strength', value: strength },
    { label: 'Purpose', value: purpose },
  ]

  const handleFieldSelection = (index) => {
    setActiveField('medication')
    setActiveStep(index)
  }

  const fieldMarker = (item, index) => {
    let color = '#dee2e6'
    let icon = 'pi pi-stop'
    if (activeStep === index) {
      color = '#fa8e42'
      icon = 'pi pi-file-edit'
    } else if (item.value) {
      color = '#0bda51'
      icon = 'pi pi-check-square'
    }

    return (
      <span onClick={() => handleFieldSelection(index)} className="cursor-pointer p-1" style={{ color }}>
        <i className={icon} />
      </span>
    )
  }

  const fieldContent = (item, index) => {
    const getTextColor = () => (activeStep === index ? 'text-color' : 'text-color-secondary')

    return (
      <div onClick={() => handleFieldSelection(index)} className={`cursor-pointer flex flex-column pt-1 gap-2 ${getTextColor()}`} style={{ wordWrap: 'break-word', maxWidth: '12rem' }}>
        <span className="text-base">{item.label}</span>
        <span className="text-sm">{item.value}</span>
      </div>
    )
  }

  const handleNameChange = (value) => {
    setName(value)
    if (!value) { setMedicineSearchTerm(null) }
    setForm(null)
    setStrength(null)
  }

  const handleConfirmName = () => {
    const matchingNames = rxTermSearchResults.filter((rxTerm) => rxTerm.displayName === name)
    if (matchingNames.length > 1 || matchingNames.length === 0) {
      setActiveStep(activeStep + 1)
    } else {
      setForm(first(matchingNames)?.form)
      setActiveStep(activeStep + 2)
    }
  }

  const handleLastStep = async () => {
    setActiveStep(null)
    const params = {
      name, form, strength, purpose,
    }
    const searchResult = find(rxTermSearchResults, (rxTerm) => rxTerm.displayName === name
      && rxTerm.form === form
      && rxTerm.strength === strength)
    if (searchResult) {
      params.rcui = searchResult.rxcui
      params.csaSchedule = searchResult.csaSchedule
    }
    handleCompletion(params)
  }

  useEffect(() => {
    if (isEdit) {
      setActiveStep(0)
    } else {
      setActiveStep(null)
    }
  }, [isEdit])

  useEffect(() => {
    if (medication) {
      setName(medication.csaSchedule
        ? `${medication.name} (${csaScheduleText(medication.csaSchedule)} Controlled Substance)`
        : name)
      setForm(medication.form)
      setStrength(medication.strength)
      setCsaSchedule(medication.csaSchedule)
    }
  }, [medication])

  const medicineName = csaSchedule ? `${name} (${csaScheduleText(csaSchedule)} Controlled Substance)`
    : name
  return (
    <Fieldset legend="Medication">
      <div className="flex flex-row">
        <Timeline
          style={{ minWidth: '16em' }}
          value={steps}
          marker={fieldMarker}
          content={fieldContent}
        />
        {activeStep === 0
          && (
            <MedicineName
              rxTermSearchResults={rxTermSearchResults}
              medicineName={medicineName}
              setMedicineSearchTerm={setMedicineSearchTerm}
              setMedicineName={handleNameChange}
              handleConfirm={handleConfirmName}
            />
          )}
        {activeStep === 1
          && (
            <MedicinePhysicalForm
              rxTermSearchResults={rxTermSearchResults}
              medicineForm={form}
              setMedicineForm={setForm}
              medicineName={name}
              handleConfirm={() => setActiveStep(activeStep + 1)}
            />
          )}
        {activeStep === 2
          && (
            <MedicineStrength
              rxTermSearchResults={rxTermSearchResults}
              medicineForm={form}
              medicineName={name}
              medicineStrength={strength}
              handleConfirm={() => setActiveStep(activeStep + 1)}
              setMedicineStrength={setStrength}
              buttonText="Next"
            />
          )}
        {
          activeStep === 3
          && (
            <MedicinePurpose
              handleConfirm={handleLastStep}
              purpose={purpose}
              setPurpose={setPurpose}
            />
          )
        }
      </div>
    </Fieldset>
  )
}

export default MedicationFieldset
