import React from 'react'
import {
  ConditionCheckSection,
  DateTimeInput,
  NoteInput,
  ReasonDropdown,
  ReasonInput,
  UserDropdown,
} from '.'

function StatusFormSection({
  formData,
  setFormData,
  showUserDropdown,
  userDropdownLabel,
  selectedDateFormatted,
  canEditTime,
  showDateTimeInput,
  showReasonInput,
  showReasonDropdown,
  showNotes,
  showConditionChecks,
  deactivatedUserAgreed,
  setDeactivatedUserAgreed,
  statusMessage,
  patientId,
}) {
  return (
    <>
      {showUserDropdown && (
        <UserDropdown
          label={userDropdownLabel}
          time={formData.defaultTime}
          selectedUser={formData.administeredBy}
          setSelectedUser={(administeredBy) => setFormData({ ...formData, administeredBy })}
          deactivatedUserAgreed={deactivatedUserAgreed}
          setDeactivatedUserAgreed={setDeactivatedUserAgreed}
          statusMessage={statusMessage}
          patientId={patientId}
        />
      )}
      {showDateTimeInput && (
        <DateTimeInput
          date={selectedDateFormatted}
          time={formData.time}
          setTime={(time) => setFormData({ ...formData, time })}
          canEditTime={canEditTime}
        />
      )}
      {showReasonInput && (
        <ReasonInput
          reason={formData.reason}
          setReason={(reason) => setFormData({ ...formData, reason })}
        />
      )}
      {showReasonDropdown && (
        <ReasonDropdown
          reason={formData.reason}
          setReason={(reason) => setFormData({ ...formData, reason })}
          status={formData.status}
        />
      )}
      {showNotes && (
        <NoteInput
          label="Notes"
          note={formData.note}
          setNote={(note) => setFormData({ ...formData, note })}
        />
      )}
      <ConditionCheckSection
        conditions={formData.conditions}
        conditionChecks={formData.conditionChecks}
        setConditionChecks={(conditionChecks) => setFormData(
          { ...formData, conditionChecks },
        )}
        visible={showConditionChecks}
      />
    </>
  )
}

export default StatusFormSection
