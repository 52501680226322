import React from 'react'
import { Card } from 'primereact/card'
import { Divider } from 'primereact/divider'
import { classNames } from 'primereact/utils'

function FormWrapper({ children, title, fullHeight = false }) {
  return (
    <Card className={classNames({ 'h-full': fullHeight })}>
      {
        title && (
          <>
            <div className="flex flex-column gap-3 text-base text-900">
              <div className="flex flex-row justify-space-between align-items-center px-1">
                <span className="text-lg w-full">
                  {title}
                </span>
              </div>
            </div>
            <Divider />
          </>
        )
      }
      <div className="flex flex-column">
        {children}
      </div>
    </Card>
  )
}

export default FormWrapper
