import moment from 'moment'
import { get } from 'lodash'
import ApiClient from '@services/ApiClient'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import {
  displayErrorMessages,
} from '@services/errorHandler'

export function useUpdateDrawer(medBoxId, statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (drawer) => {
      const { doseScheduleMinTime, doseScheduleMaxTime } = drawer
      const drawerFields = drawer
      if (doseScheduleMinTime && doseScheduleMaxTime) {
        drawerFields.dose_schedule_min_time_hh_mm = moment(get(doseScheduleMinTime, 'time')).format('HH:mm')
        drawerFields.dose_schedule_max_time_hh_mm = moment(get(doseScheduleMaxTime, 'time')).format('HH:mm')
      } else {
        drawerFields.dose_schedule_min_time_hh_mm = null
        drawerFields.dose_schedule_max_time_hh_mm = null
      }

      return ApiClient.patch(`simpills/${medBoxId}/medbox_drawers/${drawer.id}`, {
        drawer: drawerFields,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries('medbox')
      statusMessage.current.show([
        {
          severity: 'success',
          summary: 'Drawer updated!',
        },
      ])
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useSaveSlotConfigQuery(medBoxId, statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (simpillDrawers) => ApiClient.post(`simpills/${medBoxId}/save_slot_config`, { simpill: { simpillDrawers } }),
    onSuccess: () => {
      queryClient.invalidateQueries('simpill')
      statusMessage.current.show([
        {
          severity: 'success',
          summary: 'Ekit saved successfully',
        },
      ])
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useMedBoxesQuery(organizationId, { includeArchived = false }) {
  return useQuery({
    queryKey: ['medBoxes', organizationId, includeArchived],
    queryFn: () => {
      const params = []
      if (organizationId) {
        params.push(`organization_id=${organizationId}`)
      }
      if (includeArchived) {
        params.push('include_archived=true')
      }
      return ApiClient.get(`/simpills?${params.join('&')}`)
    },
  })
}

export function useMedBoxQuery(id) {
  return useQuery({ queryKey: ['medBox', id], queryFn: () => ApiClient.get(`/simpills/${id}`) })
}

export function useMedBoxMutation(statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (medBox) => ApiClient.post('/simpills', medBox),
    onSuccess: () => {
      queryClient.invalidateQueries('medBox')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useUpdateMedBoxMutation(medBoxId, statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (medBox) => ApiClient.put(`/simpills/${medBoxId}`, medBox),
    onSuccess: () => {
      queryClient.invalidateQueries('medBox')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useDeleteMedBoxMutation(statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['medbox'],
    mutationFn: (medBoxId) => ApiClient.delete(`/simpills/${medBoxId}`),
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['medbox'])
    },
  })
}

export function useArchiveMedBoxMutation(statusMessage, medBoxId) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['medbox'],
    mutationFn: () => ApiClient.post(`/simpills/${medBoxId}/archive`),
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['medBox', medBoxId])
      statusMessage.current.show([
        {
          severity: 'success',
          summary: 'MedBox archived successfully',
        },
      ])
    },
  })
}

export function useUnarchiveMedBoxMutation(statusMessage, medBoxId) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['medbox'],
    mutationFn: () => ApiClient.post(`/simpills/${medBoxId}/unarchive`),
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['medBox', medBoxId])
      statusMessage.current.clear()
      statusMessage.current.show([
        {
          severity: 'success',
          summary: 'MedBox unarchived successfully',
        },
      ])
    },
  })
}
