import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'

const useDirectoryUser = ({ id }) => useQuery({
  queryKey: ['directoryUser', id],
  queryFn: () => ApiClient.get(`/directory_users/${id}`),
  enabled: !!id,
})

export default useDirectoryUser
