import { useMutation } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

const useRefreshPatient = (statusMessage, integrationPatientId) => useMutation({
  mutationFn: () => ApiClient.post('/drx/refresh_patient', {
    integrationPatientId,
  }),
  onError: (error) => {
    displayErrorMessages(error, statusMessage)
  },
})

export default useRefreshPatient
