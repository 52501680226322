/**
 * @param {Object} order - A composite order or source order
 * @returns {Object} - A medicine object for the order
 */
export const getOrderMedicine = (order) => {
  const { pharmacyMedicine } = order

  return {
    id: pharmacyMedicine?.id,
    orderText: pharmacyMedicine?.medicineText,
    rcui: pharmacyMedicine?.rxCui,
    source: pharmacyMedicine?.source,
    code: pharmacyMedicine?.code,
    name: pharmacyMedicine?.name,
    strength: pharmacyMedicine?.strength,
    form: pharmacyMedicine?.form,
    csaSchedule: pharmacyMedicine?.csaSchedule,
  }
}

/**
 * @param {Object} order - A composite order or source order containing sigs
 * @returns {Array}
 */
export const getOrderSigs = (order) => {
  if (!order || !Array.isArray(order.sigs)) {
    return []
  }

  return order.sigs
}

export default {}
