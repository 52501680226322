import React, { useState, useEffect, useRef } from 'react'
import { isEmpty, isBoolean, get } from 'lodash'
import { Fieldset } from 'primereact/fieldset'
import { Timeline } from 'primereact/timeline'
import { SelectButton } from 'primereact/selectbutton'
import { Button } from 'primereact/button'
import { useCurrentOrganization } from '@components/App'
import DoseCalendar from './DoseCalendar'
import CheckTimeInput from '../../display/CheckTimeInput'
import { formatMinutesDuration, permissionValueToLabel } from '../../../services/utils'
import DosePrnPermissionInput from '../../display/DosePrnPermissionInput'
import DosePrnReasonRequiredInput from '../../display/DosePrnReasonRequiredInput'

function ScheduleFieldset({
  clientId, dose, doseSig, schedules, isEdit, setActiveField, handleCompletion,
  handleUpdateSchedule, handleDestroySchedule, handleDoseCalendarCompletion,
}) {
  const organization = useCurrentOrganization()
  const { impactChecksEnabled, impactCheckTime } = organization
  const scheduleFieldsetRef = useRef(null)
  const [activeStep, setActiveStep] = useState(null)
  const [prn, setPrn] = useState()
  const [checkTime, setCheckTime] = useState(impactCheckTime)
  const [
    prnPermissionRequired, setPrnPermissionRequired,
  ] = useState(organization.prnPermissionRequired)
  const [
    prnReasonRequired, setPrnReasonRequired,
  ] = useState()

  const shouldShowImpactCheckInput = prn && impactChecksEnabled

  const steps = () => {
    const stepsArray = [
      { label: 'PRN', value: prn },
    ]

    const isNarcotic = get(dose, 'medicine.csaSchedule', 0) >= 2
    const orgPrnReasonRequired = organization.prnReasonRequired

    if (prn) {
      if (impactChecksEnabled) {
        stepsArray.push({ label: 'Impact Check Time', value: checkTime })
      }
      stepsArray.push({ label: 'Permissions', value: prnPermissionRequired })
      const prnReasonStep = { label: 'PRN Reason', value: prnReasonRequired }
      if (orgPrnReasonRequired === 'not_required' || (orgPrnReasonRequired === 'schedule_ii_v' && isNarcotic)) {
        stepsArray.push(prnReasonStep)
      }
    } else {
      stepsArray.push({ label: 'Administration', value: schedules })
    }
    return stepsArray
  }

  const handleFieldSelection = (index) => {
    setActiveField('schedule')
    setActiveStep(index)
  }

  const fieldMarker = (item, index) => {
    let color = '#0bda51'
    let icon = 'pi pi-check-square'
    if (activeStep === index) {
      color = '#fa8e42'
      icon = 'pi pi-file-edit'
    } else if (!isBoolean(item.value) && isEmpty(item.value)) {
      color = '#dee2e6'
      icon = 'pi pi-stop'
    }

    const handleClick = () => {
      const previousValuesFilled = steps().slice(0, index - 1).reduce((result, step) => {
        if ((!result && isEmpty(step.value)) || ![true, false].includes(step.value)) {
          return false
        }
        return true
      }, true)

      if (isEdit && previousValuesFilled) {
        handleFieldSelection(index)
      }
    }

    return (
      <span onClick={handleClick} className="cursor-pointer p-1" style={{ color }}>
        <i className={icon} />
      </span>
    )
  }

  const fieldContent = (item, index) => {
    const getTextColor = () => (activeStep === index ? 'text-color' : 'text-color-secondary')

    return (
      <div onClick={() => handleFieldSelection(index)} className={`cursor-pointer flex flex-column pt-1 gap-2 ${getTextColor()}`}>
        <span className="text-base">{item.label}</span>
        {item.label === 'PRN'
          && <span className="text-sm">{item.value ? 'YES' : 'NO'}</span>}
        {item.label === 'Impact Check Time'
          && (
          <span className="text-sm">
            {`${formatMinutesDuration(item.value)} after administration`}
          </span>
          )}
        {item.label === 'Permissions'
          && (
          <span className="text-sm">
            {permissionValueToLabel(item.value, organization)}
          </span>
          )}
        {item.label === 'PRN Reason'
          && (
          <span className="text-sm">
            {item.value ? 'YES' : 'NO'}
          </span>
          )}
      </div>
    )
  }

  const nextStep = () => {
    setActiveStep(activeStep + 1)
  }

  const handlePRNConfirmation = () => {
    handleCompletion({
      prn,
      impactCheckTime: checkTime !== impactCheckTime ? checkTime : null,
      prnPermissionRequired: prnPermissionRequired !== organization.prnPermissionRequired
        ? prnPermissionRequired
        : null,
      prnReasonRequired: prnReasonRequired !== dose.prnReasonRequired
        ? prnReasonRequired
        : null,
    })
  }

  useEffect(() => {
    if (isEdit) {
      setActiveStep(0)
      scheduleFieldsetRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    } else {
      setActiveStep(null)
    }
  }, [isEdit])

  useEffect(() => {
    setPrnReasonRequired(get(dose, 'prnReasonRequired'))
  }, [dose])

  const currentSteps = steps()

  const hasNextStep = activeStep !== null && activeStep < currentSteps.length - 1
  const currentActiveStepLabel = get(currentSteps[activeStep], 'label')

  return (
    <Fieldset legend="Schedule">
      <div ref={scheduleFieldsetRef} className="flex flex-row align-items-center">
        {isEmpty(dose) && activeStep === null
          && <div>Please select and save a medicine and dose first.</div>}
        {!isEmpty(dose)
          && (
            <>
              <div className="w-3">
                <Timeline
                  style={{ minWidth: '2em' }}
                  value={currentSteps}
                  marker={fieldMarker}
                  content={fieldContent}
                />
              </div>
              <div className="w-9">
                {currentActiveStepLabel === 'PRN'
                  && (
                    <div className="grid flex justify-content-center align-items-center flex-grow-1 fadein">
                      <div className="col-9 flex flex-column gap-2 text-center">
                        <label className="text-lg font-normal mb-1 text-center">
                          Is this medication given on an as needed basis?
                        </label>
                        <SelectButton
                          name="PRN"
                          options={[{ label: 'YES', value: true }, { label: 'NO', value: false }]}
                          value={prn}
                          onChange={(e) => setPrn(e.value)}
                        />
                        <Button
                          onClick={
                            hasNextStep ? nextStep : () => handleCompletion({ prn: false })
                          }
                          disabled={![true, false].includes(prn)}
                          label={hasNextStep ? 'Next' : 'Save'}
                          className="p-button-text mt-2"
                        />
                      </div>
                    </div>
                  )}
                {
                currentActiveStepLabel === 'Administration'
                  && (
                    <div className="grid flex justify-content-center align-items-center flex-grow-1 fadein">
                      <div className="flex flex-column gap-2 text-center">
                        <label className="text-lg font-normal mb-2 text-center">
                          What days and times should this medication be administered?
                        </label>
                        <DoseCalendar
                          headingTextClassName="text-base"
                          clientId={clientId}
                          dose={dose}
                          doseSig={doseSig}
                          schedules={schedules}
                          handleCreateSchedule={handleDoseCalendarCompletion}
                          handleUpdateSchedule={handleUpdateSchedule}
                          handleDestroySchedule={handleDestroySchedule}
                        />
                        <Button
                          onClick={handleCompletion}
                          label="Save and Continue"
                          className="p-button-text mt-2"
                          disabled={isEmpty(schedules)}
                        />
                      </div>
                    </div>
                  )
                }
                {
                currentActiveStepLabel === 'Impact Check Time'
                  && (
                    <div className="grid flex justify-content-center align-items-center flex-grow-1 fadein">
                      {
                        shouldShowImpactCheckInput ? (
                          <div className="flex flex-column gap-2 text-center">
                            <CheckTimeInput
                              value={checkTime}
                              onChange={setCheckTime}
                            />
                            <Button
                              onClick={hasNextStep ? nextStep : handlePRNConfirmation}
                              label={hasNextStep ? 'Next' : 'Save'}
                              className="p-button-text mt-2"
                            />
                          </div>
                        ) : (
                          <div className="flex flex-column gap-2 text-center">
                            <p>
                              A PRN dose does not have a schedule,
                              {' '}
                              instead it is given on an as needed basis.
                            </p>
                          </div>
                        )
                      }
                    </div>
                  )
                }
                {
                currentActiveStepLabel === 'Permissions'
                  && (
                    <div className="grid flex justify-content-center align-items-center flex-grow-1 fadein">
                      <div className="flex flex-column gap-2 text-center">
                        <label className="text-lg font-normal mb-2 text-center">
                          Who needs permission before administering this medication?
                        </label>
                        <DosePrnPermissionInput
                          value={prnPermissionRequired}
                          onChange={setPrnPermissionRequired}
                        />
                        <Button
                          onClick={hasNextStep ? nextStep : handlePRNConfirmation}
                          label={hasNextStep ? 'Next' : 'Save'}
                          className="p-button-text mt-2"
                        />
                      </div>
                    </div>
                  )
                }
                {
                  currentActiveStepLabel === 'PRN Reason'
                    && (
                      <div className="grid flex justify-content-center align-items-center flex-grow-1 fadein">
                        <div className="flex flex-column gap-2 text-center">
                          <label className="text-lg font-normal mb-2 text-center">
                            Is a reason required for administering this medication?
                          </label>
                          <DosePrnReasonRequiredInput
                            value={prnReasonRequired}
                            onChange={setPrnReasonRequired}
                          />
                          <Button
                            onClick={hasNextStep ? nextStep : handlePRNConfirmation}
                            label={hasNextStep ? 'Next' : 'Save and Continue'}
                            className="p-button-text mt-2"
                          />
                        </div>
                      </div>
                    )
                }
              </div>
            </>
          )}
      </div>
    </Fieldset>
  )
}

export default ScheduleFieldset
