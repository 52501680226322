import React, { useState } from 'react'
import { find, get } from 'lodash'
import { Toast } from 'primereact/toast'
import { useCurrentUser } from '@components/App'
import {
  DoseSection, DoseFillsSection, HeaderSection, OverviewSection, SigSection, SourceOrdersSection,
} from './Sections'

// TODO: Give this component a better name
function ReviewDoseOrder({
  patientId, reviewableOrder, statusMessage,
}) {
  const currentUser = useCurrentUser()

  const primaryDirective = find(reviewableOrder?.doseDirectives, (directive) => directive.isPrimary)
  const [selectedDirectiveCode, setSelectedDirectiveCode] = useState(primaryDirective?.code)
  const selectedDirective = find(
    reviewableOrder?.doseDirectives,
    (directive) => directive.code === selectedDirectiveCode,
  )

  // We're pulling the first match to get this out quickly but we will allow selecting from
  // multiple matches in the future
  const matchedDoseId = selectedDirective?.matchId

  return (
    <div className="flex flex-column">
      <Toast ref={statusMessage} />
      <HeaderSection
        matchedDoseId={matchedDoseId}
        patientId={patientId}
        reviewableOrder={reviewableOrder}
        selectedDirective={selectedDirective}
      />
      <OverviewSection
        doseDirectives={reviewableOrder?.doseDirectives}
        patientId={patientId}
        prescription={reviewableOrder?.prescription}
        reviewableOrder={reviewableOrder}
        selectedDirective={selectedDirective}
        setSelectedDirectiveCode={setSelectedDirectiveCode}
      />
      <DoseSection
        compositeOrder={reviewableOrder?.compositeOrder}
      />
      {
        reviewableOrder?.compositeOrder?.sigs?.map((sig, index) => (
          <SigSection
            key={index} // eslint-disable-line react/no-array-index-key
            compositeOrderSig={sig}
            doseSig={get(reviewableOrder, `dose.doseSigs[${index}]`)}
          />
        ))
      }
      <DoseFillsSection />
      {
        currentUser.role === 'superadmin' && (
          <SourceOrdersSection
            reviewableOrder={reviewableOrder}
          />
        )
      }
    </div>
  )
}

export default ReviewDoseOrder
