import React, { useEffect, useState } from 'react'
import { first, map, isEmpty } from 'lodash'
import { RRule } from 'rrule'
import { Dropdown } from 'primereact/dropdown'
import { InputNumber } from 'primereact/inputnumber'
import { daysOfWeek } from './config'

function MonthlyControls({
  selectedDaysOfWeek, setSelectedDaysOfWeek,
}) {
  const [weekdayOridinal, setWeekdayOrdinal] = useState(1)
  const weekdaysWithOrdinalValue = map(daysOfWeek, (day) => (
    {
      label: day.title,
      value: day.value.nth(weekdayOridinal),
    }
  ))

  const handleSetWeekdayOrdinal = ({ value }) => {
    setSelectedDaysOfWeek(map(selectedDaysOfWeek, (day) => day.nth(value)))
    setWeekdayOrdinal(value)
  }

  useEffect(() => {
    if (isEmpty(selectedDaysOfWeek)) {
      setSelectedDaysOfWeek([RRule.SU.nth(1)])
    }
  }, [selectedDaysOfWeek])

  return (
    <div className="p-inputgroup w-full">
      <span className="p-inputgroup-addon p-0 font-semibold w-4">
        Occurs on the
      </span>
      <span className="p-inputgroup-addon p-0 bg-white font-semibold w-3">
        <InputNumber
          showButtons
          value={weekdayOridinal}
          className="h-full"
          incrementButtonClassName="p-button-secondary p-button-text w-3rem"
          decrementButtonClassName="p-button-secondary p-button-text w-3rem"
          inputClassName="h-full text-center"
          inputStyle={{ border: 0 }}
          onValueChange={handleSetWeekdayOrdinal}
        />
      </span>
      <Dropdown
        value={first(selectedDaysOfWeek)}
        options={weekdaysWithOrdinalValue}
        onChange={(e) => setSelectedDaysOfWeek([e.value])}
      />
    </div>
  )
}

export default MonthlyControls
