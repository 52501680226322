import { useMemo } from 'react'
import { get, map, sortBy } from 'lodash'
import { useQuery } from '@tanstack/react-query'
import { useDebounce } from '@hooks/core'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

function useResponsibleUsers({ statusMessage, patientId, responsibleAt }) {
  const debouncedResponsibleAt = useDebounce(responsibleAt, 500)

  const query = useQuery(
    {
      queryKey: ['responsibleUsers', patientId, debouncedResponsibleAt],
      queryFn: () => ApiClient.get(`/patients/${patientId}/responsible_users?responsible_at=${debouncedResponsibleAt}`),
      enabled: !!patientId && !!debouncedResponsibleAt,
      onError: (error) => displayErrorMessages(error, statusMessage),
    },
  )

  return useMemo(() => ({
    ...query,
    data: sortBy(map(get(query, 'data'), (user) => user)),
  }), [query.data])
}

export default useResponsibleUsers
