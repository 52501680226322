import React from 'react'
import { Dropdown } from 'primereact/dropdown'

function SimpleSearchDropdownWithLabel({
  label, inputId, value, options, onChange, error = false,
}) {
  return (
    <div className="flex flex-row justify-content-start align-items-center">
      <label htmlFor={inputId} className="flex-column justify-content-start w-3">
        {label}
      </label>
      <div className="flex flex-column justify-content-start w-3 gap-1">
        <Dropdown
          id={inputId}
          value={value}
          options={options}
          onChange={onChange}
          optionLabel="name"
          optionValue="value"
          filter
          filterBy="name"
        />
        {!!error && <small className="p-error">{error}</small>}
      </div>
    </div>
  )
}

export default SimpleSearchDropdownWithLabel
