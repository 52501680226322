import React, { useState, useEffect } from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { InputTextarea } from 'primereact/inputtextarea'

function NotesEditor({ doseNote, setDoseNote }) {
  const [dialogVisible, setDialogVisible] = useState(false)
  const [note, setNote] = useState()

  const handleConfirmation = () => {
    setDoseNote({ note })
    setDialogVisible(false)
  }

  useEffect(() => {
    if (doseNote) {
      setNote(doseNote)
    }
  }, [doseNote])

  return (
    <>
      <Button className="p-button-text p-button-sm p-button-warning" label="Edit" icon="pi pi-file-edit" onClick={() => setDialogVisible(true)} />
      <Dialog header="Notes" visible={dialogVisible} style={{ width: '75vw' }} onHide={() => setDialogVisible(false)}>
        <div className="flex flex-column gap-2">
          <InputTextarea
            value={note}
            onChange={({ target: { value } }) => setNote(value)}
          />
          <Button
            label="Confirm"
            className="p-button-sm p-button-success mt-4"
            onClick={handleConfirmation}
          />
        </div>
      </Dialog>
    </>
  )
}

export default NotesEditor
