import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { ProfileForm, SettingsForm, SiteForm } from '@components/pharmacists/PharmacistForm'
import FormWrapper from '@components/display/Form/FormWrapper'
import { useAllSitesQuery } from '@components/sites/sitesHooks'
import { usePharmacistCreateMutation } from './pharmacistsHooks'

function CreatePharmacist({ usePharmacistsHeader }) {
  const statusMessage = useRef(null)
  const { setHeader } = usePharmacistsHeader()
  const navigate = useNavigate()
  const {
    data: sites,
    isLoading: isSitesLoading,
  } = useAllSitesQuery()

  const [assignedSiteIds, setAssignedSiteIds] = useState([])

  const [profile, setProfile] = useState({
    email: '',
    firstName: '',
    lastName: '',
  })

  const [settings, setSettings] = useState({
    inventoryAlertsEnabled: false,
    trackableActivitiesEmailEnabled: true,
  })

  const {
    mutateAsync: createPharmacist,
    isLoading: createIsLoading,
  } = usePharmacistCreateMutation(statusMessage)

  const handleCreate = async () => {
    const pharmacist = await createPharmacist({
      ...profile,
      ...settings,
      siteIds: assignedSiteIds,
    })

    if (pharmacist) {
      navigate(`../${pharmacist.id}`)
    }
  }

  const title = {
    label: (
      <span>
        <i className="text-xl pi pi-users mr-2" />
        Pharmacists
      </span>
    ),
    linkTo: '/admin/pharmacists',
  }

  const breadcrumbs = [
    {
      label: 'Create',
      template: <span>Create</span>,
    },
  ]

  useEffect(() => (
    setHeader({ title, breadcrumbs, primaryAction: null })
  ), [])

  return (
    <div className="flex flex-row flex-wrap">
      <Toast ref={statusMessage} />
      <div className="col-8 xl:col-6">
        <FormWrapper title="Create Pharmacist" fullHeight>
          <ProfileForm
            profile={profile}
            setProfile={(value) => setProfile((p) => ({ ...p, ...value }))}
          />
          <SettingsForm
            settings={settings}
            setSettings={(value) => setSettings((p) => ({ ...p, ...value }))}
          />
          <div className="field">
            {/* eslint-disable-next-line */}
            <label className="block" htmlFor="create-site-form">Sites</label>
            <SiteForm
              inputId="create-site-form"
              sites={sites}
              selectedSites={assignedSiteIds}
              setSelectedSites={(siteIds) => setAssignedSiteIds(siteIds)}
              isSitesLoading={isSitesLoading}
            />
          </div>
          <Button
            label="Create Pharmacist"
            className="p-button-sm mt-4"
            loading={createIsLoading}
            onClick={handleCreate}
          />
        </FormWrapper>
      </div>
    </div>
  )
}

export default CreatePharmacist
