import React, { useState, useRef } from 'react'
import { get, map, isEmpty } from 'lodash'
import moment from 'moment'
import { Toast } from 'primereact/toast'
import CardWithHeader from '../../components/display/CardWithHeader'
import ActivityFeed from '../../components/medManagement/ActivityFeed'
import DoseTrend from './DoseTrend'
import { timeTemplate, dateTemplate } from '../../services/utils'
import {
  medicineAttribute,
  patientAttribute,
  caregiversAttribute,
} from '../../components/medManagement/DoseAttributes'
import { useConsumptionReminder } from './medManagementHooks'

function HighRiskDoses({
  organization, timeframe, highRiskConsumptions, onTimeConsumptions, maxDataViewHeight,
}) {
  const statusMessage = useRef()
  const [view, setView] = useState('feed')
  const [reminderSendingForConsumptionId, setReminderSendingForConsumptionId] = useState()

  const {
    isLoading,
    mutateAsync: issueConsumptionReminder,
  } = useConsumptionReminder(statusMessage)

  const handleConsumptionReminder = async (consumption) => {
    setReminderSendingForConsumptionId(consumption.id)
    await issueConsumptionReminder(consumption)
    setReminderSendingForConsumptionId()
  }

  const expectedAtAttribute = (consumption) => {
    const isRefusal = get(consumption, 'refused', false)
    const timeSinceExpected = moment(new Date(get(consumption, 'expectedAt'))).fromNow()
    return {
      title: `${isRefusal ? 'Refused' : 'Expected'} ${timeSinceExpected}`,
      width: '25%',
      details: [
        timeTemplate(get(consumption, 'expectedAt')),
        dateTemplate(get(consumption, 'expectedAt')),
      ],
    }
  }

  const feedItems = map(highRiskConsumptions.data, (consumption) => ({
    actions: [{
      icon: 'pi pi-send',
      label: 'Remind',
      loading: isLoading && consumption.id === reminderSendingForConsumptionId,
      actionType: 'HighRiskDose',
      expiryDate: get(consumption, 'willExpireAt'),
      command: () => handleConsumptionReminder(consumption),
    }],
    attributes: [
      expectedAtAttribute(consumption),
      medicineAttribute(consumption),
      patientAttribute(consumption, organization),
      caregiversAttribute(consumption, organization),
    ],
    key: consumption.id,
  }))

  const viewMenuItems = [
    {
      label: 'Feed',
      isActive: view === 'feed',
      command: () => setView('feed'),
    },
    {
      label: 'Trend',
      isActive: view === 'trend',
      command: () => setView('trend'),
    },
  ]

  return (
    <CardWithHeader title="High Risk Doses" menuItems={viewMenuItems}>
      <Toast ref={statusMessage} />
      {isEmpty(highRiskConsumptions.data) && !highRiskConsumptions.isLoading
        && (
        <div className="mt-3">
          No High Risk Consumptions for
          {' '}
          {timeframe}
        </div>
        )}
      {view === 'feed' && (!isEmpty(highRiskConsumptions.data) || highRiskConsumptions.isLoading)
        && (
        <ActivityFeed
          loading={highRiskConsumptions.isLoading}
          maxDataViewHeight={maxDataViewHeight}
          items={feedItems}
        />
        )}
      {view === 'trend' && (!isEmpty(highRiskConsumptions.data) || highRiskConsumptions.isLoading)
        && (
        <DoseTrend
          timeframe={timeframe}
          loading={highRiskConsumptions.isLoading || onTimeConsumptions.isLoading}
          consumptions={[...highRiskConsumptions.data, ...onTimeConsumptions.data]}
        />
        )}
    </CardWithHeader>
  )
}

export default HighRiskDoses
