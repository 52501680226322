import React, { useRef, useState } from 'react'
import { Toast } from 'primereact/toast'

import AttributeCard from '@components/display/AttributeCard'
import { useUpsertDose } from '../clientDosesHooks'
import PharmacyInfo from './PharmacyInfo'
import PrescribingPhysician from './PrescribingPhysician'

function Source({ dose }) {
  const statusMessages = useRef(null)
  const {
    mutateAsync: updateDose,
    isLoading,
  } = useUpsertDose({
    patientId: dose.patientId,
    statusMessage: statusMessages,
    isUpdate: true,
  })

  const [editAttribute, setEditAttribute] = useState(null)

  const handleUpdateAttribute = (attribute, value) => {
    updateDose({
      id: dose.id,
      [attribute]: value,
    }, {
      onSuccess: () => {
        setEditAttribute(null)
      },
    })
  }

  return (
    <div className="col-12">
      <Toast ref={statusMessages} />
      <AttributeCard title={{ label: 'Source' }}>
        { !dose.externalId && (
          <PharmacyInfo
            dose={dose}
            editAttribute={editAttribute}
            setEditAttribute={setEditAttribute}
            isLoading={isLoading}
            handleUpdateAttribute={handleUpdateAttribute}
          />
        )}
        <PrescribingPhysician
          dose={dose}
          editAttribute={editAttribute}
          setEditAttribute={setEditAttribute}
          isLoading={isLoading}
          handleUpdateAttribute={handleUpdateAttribute}
        />
      </AttributeCard>
    </div>
  )
}

export default Source
