import React from 'react'
import { Dialog } from 'primereact/dialog'
import DoseHoldForm from './DoseHoldForm'

function DoseHoldDialog({
  visible,
  onHide,
  onConfirm,
  onDelete,
  showDeleteButton,
  isLoading,
  holdToEdit,
}) {
  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header="Dose Hold"
      modal
      className="p-fluid w-3"
      closable={!isLoading}
    >
      <DoseHoldForm
        onCancel={onHide}
        onConfirm={onConfirm}
        onDelete={onDelete}
        mode={holdToEdit ? 'edit' : 'add'}
        showDeleteButton={showDeleteButton}
        isLoading={isLoading}
        defaultStartAt={holdToEdit?.startAt}
        defaultEndAt={holdToEdit?.endAt}
        defaultNote={holdToEdit?.note}
      />
    </Dialog>
  )
}

export default DoseHoldDialog
