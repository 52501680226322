import React, { useState, useEffect } from 'react'
import { get } from 'lodash'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Tag } from 'primereact/tag'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useCurrentOrganization, useCurrentUser } from '@components/App'
import PatientAvatarThumbnail from '@components/patients/PatientList/PatientAvatarThumbnail'
import MedsDue from '@components/patients/PatientList/MedsDue'
import { exactSearch } from '@services/utils/search'

function ActivePatients({
  patients,
  isFetching,
  searchTerm,
  setSearchLoading,
  rootPath,
  maxDataViewHeight,
  setFooter,
  selectedSites,
}) {
  const navigate = useNavigate()

  const [filteredPatients, setFilteredPatients] = useState([])
  const organization = useCurrentOrganization()
  const currentUser = useCurrentUser()
  const [searchParams] = useSearchParams()

  const canViewMobileVersion = currentUser.role === 'superadmin'

  useEffect(() => {
    const siteFilteredPatients = selectedSites?.length > 0 ? patients.filter((patient) => selectedSites.includes(get(patient, 'site.id'))) : patients
    setSearchLoading(true)
    if (searchTerm) {
      setFilteredPatients(exactSearch(siteFilteredPatients, ['firstName', 'lastName'], searchTerm))
    } else {
      setFilteredPatients(siteFilteredPatients)
    }
    setSearchLoading(false)
  }, [isFetching, searchTerm, selectedSites])

  const footTemplate = () => (
    <span className="text-sm ml-1 my-0 py-0">
      {!isFetching ? `${organization.patientLabelPlural} Count: ${filteredPatients.length}` : '' }
    </span>
  )

  useEffect(() => {
    setFooter(footTemplate)
  }, [filteredPatients])

  const pharmacyOrdersTemplate = ({ id, numReviewableOrders }) => {
    if (numReviewableOrders && numReviewableOrders > 0) {
      return (
        <Tag
          severity="warning"
          className="p-mr-2"
          onClick={(e) => {
            navigate(`${rootPath}/${id}/orders/needs-review`)
            e.stopPropagation()
          }}
        >
          {`${numReviewableOrders} PENDING`}
        </Tag>
      )
    }
  }

  const medsDueTemplate = (patient) => (
    <MedsDue dueNow={patient.medsDueNow} dueLater={patient.medsDueLater} />
  )

  return (
    <div>
      <div className="flex flex-column gap-3 text-base text-900">
        <DataTable
          dataKey="id"
          loading={isFetching}
          value={filteredPatients}
          selectionMode="single"
          onSelectionChange={(e) => navigate(`${rootPath}/${e.value.id}`)}
          sortField={searchParams.get('sortField') || 'lastName'}
          sortOrder={searchParams.get('sortOrder') || 1}
          rowClassName="fadein patient-row"
          scrollable
          scrollHeight={maxDataViewHeight ? (maxDataViewHeight - 10) : null}
        >
          <Column headerStyle={{ width: '3rem' }} body={PatientAvatarThumbnail} />
          <Column header="First Name" field="firstName" />
          <Column header="Last Name" field="lastName" sortable />
          <Column header="Meds Due" body={medsDueTemplate} />
          <Column
            header="Note"
            field="patientSettings.note"
            style={{ width: '20%' }}
          />
          {organization.hasIntegration
              && (
              <Column
                header="Pharmacy Orders"
                field="numReviewableOrders"
                body={pharmacyOrdersTemplate}
                sortable
              />
              )}
          <Column header="Room #" field="roomNumber" sortable />
          {
            canViewMobileVersion && (
              <Column header="Mobile App Version" field="mobileClientVersion" sortable />
            )
          }
        </DataTable>
      </div>
    </div>
  )
}

export default ActivePatients
