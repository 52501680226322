import React, { useState, useEffect } from 'react'
import { map, uniqBy, first } from 'lodash'
import { AutoComplete } from 'primereact/autocomplete'
import { Button } from 'primereact/button'

function MedicineStrength({
  medicineName, medicineStrength, setMedicineStrength, rxTermSearchResults, medicineForm, handleConfirm, buttonText = 'Save and Continue',
}) {
  const [filteredMedicineStrengths, setFilteredMedicineStrengths] = useState([])

  useEffect(() => {
    const applicableResults = rxTermSearchResults.filter(
      (rxTerm) => rxTerm.displayName === medicineName && rxTerm.form === medicineForm,
    )
    const strengths = map(uniqBy(applicableResults, 'strength'), ({ strength }) => strength)
    strengths.sort((a, b) => a.localeCompare(b))
    setFilteredMedicineStrengths(strengths)
    setMedicineStrength(first(strengths))
  }, [rxTermSearchResults])

  const filterMedicineStrengths = (event) => {
    setTimeout(() => {
      let filtered
      if (!event.query.trim().length) {
        filtered = [...filteredMedicineStrengths]
      } else {
        filtered = filteredMedicineStrengths.filter((form) => (
          form.toLowerCase().startsWith(event.query.toLowerCase())
        ))
      }
      setFilteredMedicineStrengths(filtered)
    }, 250)
  }

  return (
    <div className="grid flex flex-grow-1 justify-content-center align-items-center fadein">
      <div className="col-9 flex flex-column gap-2 text-center">
        <label className="text-lg font-normal mb-1" htmlFor="dose-medicine-strength">
          What is the strength and measurement unit of each individual medicine
          {' '}
          {medicineForm}
          ?
          <p className="text-sm font-normal">Example, 20mg</p>
        </label>
        <AutoComplete
          inputId="dose-medicine-strength"
          value={medicineStrength}
          data-testid="medicine-strength"
          suggestions={filteredMedicineStrengths}
          completeMethod={filterMedicineStrengths}
          dropdown
          autoFocus
          onChange={(e) => setMedicineStrength(e.value)}
          aria-label="Medicine Strength"
          dropdownarialabel="Select Medicine Strength"
        />
        <Button
          onClick={handleConfirm}
          label={buttonText}
          className="p-button-text mt-2"
          disabled={!medicineStrength}
        />
      </div>
    </div>
  )
}

export default MedicineStrength
