import React from 'react'
import { isEqual } from 'lodash'

function MatchLabel({ referenceValue, comparedValue, displayValue }) {
  if (!referenceValue || !comparedValue) {
    return <span className="mx-2">{displayValue}</span>
  }

  const isMatch = isEqual(referenceValue.toUpperCase(), comparedValue.toUpperCase())

  if (isMatch) {
    return (
      <div className="flex flex-row gap-2 mx-2">
        <i className="pi pi-check text-green-500" />
        <span className="font-semibold">{displayValue}</span>
      </div>
    )
  }

  return <span className="mx-2">{displayValue}</span>
}

export default MatchLabel
