import React, { useRef } from 'react'
import PharmacyOrdersTable from '@components/pharmacyOrders/Table/PharmacyOrdersTable'
import { usePatientPharmacyOrders } from '@hooks/pharmacyOrders'

function PatientPharmacyOrders({ patientId, orderType }) {
  const statusMessage = useRef(null)

  const {
    data: pharmacyOrders,
    isLoading,
  } = usePatientPharmacyOrders({ patientId, orderType, statusMessage })

  return (
    <PharmacyOrdersTable
      pharmacyOrders={pharmacyOrders}
      isLoading={isLoading}
    />
  )
}

export default PatientPharmacyOrders
