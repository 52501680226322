import React, { useState, useRef } from 'react'
import { isEmpty, startCase, orderBy } from 'lodash'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Knob } from 'primereact/knob'
import { Divider } from 'primereact/divider'
import { Menu } from 'primereact/menu'
import DoseFillInfo from '@components/ekits/DoseFillAssigment/DoseFillInfo'
import DoseFillStatusTags from '../../ekits/DoseFillAssigment/DoseFillStatusTags'
import { DoseAssignment, ArchiveFill } from '../DoseAssignment'
import DoseRefill, { FulfillmentStatus } from '../DoseRefill'

function DrawerDoseFill({
  doseFill, ekitId, drawer,
}) {
  const menu = useRef(null)
  const [showDialog, setShowDialog] = useState(false)
  const isNewRefill = doseFill.status === FulfillmentStatus.loadedByAgency

  const actions = [
    {
      label: isNewRefill ? 'Refill' : 'Confirm Pending Refill',
      icon: 'pi pi-refresh',
      command: () => setShowDialog('refill'),
    },
    {
      label: 'Archive',
      icon: 'pi pi-exclamation-triangle',
      command: () => setShowDialog('archive'),
    },
  ]

  return (
    <>
      <Dialog header={`${startCase(showDialog)} for Drawer ${drawer.position}`} visible={showDialog} onHide={() => setShowDialog(false)}>
        { showDialog === 'refill'
          && (
          <DoseRefill
            shouldCreate={doseFill.status === FulfillmentStatus.loadedByAgency}
            newFulfillmentStatus={FulfillmentStatus.loadedByAgency}
            drawerId={drawer.id}
            doseFill={doseFill}
            onSuccess={() => setShowDialog(false)}
          />
          )}
        { showDialog === 'archive'
          && (
          <ArchiveFill
            ekitId={ekitId}
            doseFill={doseFill}
            onSuccess={() => setShowDialog(false)}
          />
          )}
      </Dialog>
      <div className="flex flex-row justify-space-between align-items-center p-3 my-2 shadow-1">
        <div className="grid flex-grow-1 align-items-center px-2">
          <Knob
            size={40}
            min={0}
            max={doseFill.dosesInFill}
            value={doseFill.fillsLeft}
            valueColor="#caf1d8"
            readOnly
          />
          <div className="flex flex-column col-4">
            <span className="text-sm font-bold">
              {doseFill.medicine.name}
            </span>
            <span className="text-sm">
              {doseFill.medicine.strength}
            </span>
          </div>
          <div className="flex flex-column gap-2 col-4">
            <DoseFillStatusTags doseFill={doseFill} />
            <DoseFillInfo doseFill={doseFill} />
          </div>
        </div>
        <div>
          <Menu model={actions} popup ref={menu} id="popup_menu" />
          <Button className="p-button-text p-button-sm p-0" icon="pi pi-bars" onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup />
        </div>
      </div>
    </>
  )
}

function Drawer({
  ekitId, siteId, drawer, doseFills,
}) {
  const [showDialog, setShowDialog] = useState(false)
  const orderedDoseFills = orderBy(doseFills, 'expiresAt')

  return (
    <>
      <Dialog header="Dose Assignment" visible={showDialog} onHide={() => setShowDialog(false)} blockScroll>
        <DoseAssignment
          siteId={siteId}
          ekitId={ekitId}
          drawerId={drawer.id}
          onSuccess={() => setShowDialog(false)}
        />
      </Dialog>
      <div className="col-12 p-0">
        <Button
          onClick={() => setShowDialog(true)}
          icon="pi pi-plus"
          label="Assign Dose"
          className="text-left pl-0 p-button-text p-button-sm"
        />
        <Divider />
        { !isEmpty(doseFills)
          && orderedDoseFills.map((doseFill) => (
            <DrawerDoseFill
              key={doseFill.id}
              doseFill={doseFill}
              siteId={siteId}
              ekitId={ekitId}
              drawer={drawer}
            />
          ))}
      </div>
    </>
  )
}

export default Drawer
