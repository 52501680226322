import React, { useState, useEffect } from 'react'
import { Card } from 'primereact/card'
import { Dropdown } from 'primereact/dropdown'
import { useCurrentOrganization, useCurrentUser } from '@components/App'
import PatientsSelection from './PatientSelection'

function accessibleSites(currentOrganization, currentUser, caregiver) {
  if (!currentOrganization.hasSites) return null
  if (currentUser.role === 'superadmin') return caregiver.sites
  if (!currentUser.sites || currentUser.sites.length === 0) return null
  const mySiteIds = currentUser.sites.map((site) => site.id)
  return caregiver.sites.filter((site) => mySiteIds.includes(site.id))
}

function noSiteMessage(currentOrg, caregiverSites, availableSites) {
  if (caregiverSites === null || caregiverSites.length === 0) {
    return `To manage ${currentOrg.patientLabelPlural}, please assign a site to this ${currentOrg.caregiverLabelSingular}.`
  }
  if (availableSites === null || availableSites.length === 0) {
    return `You do not have access to any site assigned to this ${currentOrg.caregiverLabelSingular}.`
  }
}

function CaregiverPatientsAssignment({
  caregiver,
  handleSetHeader,
}) {
  if (!caregiver) return null

  const currentUser = useCurrentUser()
  const currentOrg = useCurrentOrganization()
  const availableSites = accessibleSites(currentOrg, currentUser, caregiver)
  const [
    selectedSite,
    setSelectedSite,
  ] = useState(availableSites && availableSites.length > 0 ? availableSites[0] : null)

  useEffect(() => {
    handleSetHeader({ primaryAction: null })
  }, [caregiver])

  const sitesSelectorTemplate = () => {
    if (currentOrg.hasSites) {
      return (
        <Dropdown
          value={selectedSite.id}
          options={availableSites}
          optionLabel="name"
          optionValue="id"
          onChange={(e) => setSelectedSite(caregiver.sites.find((site) => site.id === e.value))}
          placeholder="Select a site"
        />
      )
    }
    return null
  }

  if (currentOrg.hasSites && !selectedSite) {
    return (
      <Card>
        <span>
          {noSiteMessage(currentOrg, caregiver.sites, availableSites)}
        </span>
      </Card>
    )
  }

  return (
    <Card className="m-2">
      <div className="flex flex-column">
        <PatientsSelection
          caregiver={caregiver}
          site={selectedSite}
          siteSelector={sitesSelectorTemplate}
        />
      </div>
    </Card>
  )
}

export default CaregiverPatientsAssignment
