import React from 'react'
import {
  filter, flatMap, groupBy, isEmpty, isEqual, map, sortBy,
} from 'lodash'
import { Checkbox } from 'primereact/checkbox'
import { EditableScheduleTime, UnsupportedScheduleError } from './ScheduleComponents'

function ScheduleEditor({
  orderSchedules,
  schedules,
  invalidSchedules,
  initializeSchedules,
  handleAddSchedule,
  handleUpdateSchedule,
  handleDeleteSchedule,
}) {
  const sortSchedules = (s) => sortBy(s, (schedule) => `${schedule.rrule}:${schedule.time.format('HH:mm')}`)
  const activeSchedules = sortSchedules(filter(schedules, ({ _destroy }) => !_destroy))
  const sortedOrderSchedules = sortSchedules(orderSchedules)

  const schedulesByRruleText = groupBy(activeSchedules, 'text')
  const orderSchedulesByRruleText = groupBy(orderSchedules, 'text')

  const schedulesTemplate = (schedulesToDisplay, editable = true) => (
    <ul className="list-none p-0 m-0 gap-2 flex flex-column">
      {flatMap(schedulesToDisplay, (scheduleGroup, rruleText) => (
        <li key={rruleText} className="flex flex-row gap-1">
          {rruleText}
          <span className="mr-1">at</span>
          {
            map(scheduleGroup, (schedule, index) => (
              <div key={index} className="flex flex-row align-items-baseline gap-2">
                {editable && (
                  <EditableScheduleTime
                    schedule={schedule}
                    handleCreateSchedule={handleAddSchedule}
                    handleUpdateSchedule={handleUpdateSchedule}
                    handleDestroySchedule={handleDeleteSchedule}
                  />
                )}
                {!editable && <span>{schedule.time?.format('h:mm A')}</span>}
                {index + 1 !== scheduleGroup.length && (
                  <span className="text-500">|</span>
                )}
              </div>
            ))
          }
        </li>
      ))}
      <UnsupportedScheduleError invalidSchedules={invalidSchedules} />
    </ul>
  )

  const hasScheduleDiff = !isEmpty(sortedOrderSchedules) && !isEqual(
    map(activeSchedules, ({ rrule, time }) => `${rrule}:${time.format('HH:mm')}`),
    map(sortedOrderSchedules, ({ rrule, time }) => `${rrule}:${time.format('HH:mm')}`),
  )

  if (hasScheduleDiff) {
    return (
      <div className="flex flex-column gap-3 py-2">
        <div className="flex flex-row gap-2 align-items-center">
          <Checkbox
            inputId="currentValue:schedules"
            name="currentValue:schedules"
            value="currentSchedules"
            readOnly
            checked
          />
          <label htmlFor="currentValue:schedules" className="flex flex-grow-1 text-900 font-medium">
            {schedulesTemplate(schedulesByRruleText)}
          </label>
        </div>
        <div className="flex flex-row gap-2 align-items-center">
          <Checkbox
            inputId="newValue:schedules"
            name="newValue:schedules"
            value="newSchedules"
            onChange={() => initializeSchedules(orderSchedules)}
            checked={false}
          />
          <label htmlFor="newValue:schedules" className="flex flex-row gap-2">
            <span className="text-600 font-italic">Order Value:</span>
            <span className="flex flex-grow-1 text-600 font-italic">
              {schedulesTemplate(orderSchedulesByRruleText, false)}
            </span>
          </label>
        </div>
      </div>
    )
  }

  return schedulesTemplate(schedulesByRruleText)
}

export default ScheduleEditor
