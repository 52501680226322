import { DataTable } from 'primereact/datatable'
import React, { useEffect, useState } from 'react'
import { Column } from 'primereact/column'
import moment from 'moment-timezone'
import { momentFormats } from '@services/utils/moment'
import { Button } from 'primereact/button'
import SearchField from '@components/display/Form/SearchField'
import { Tooltip } from 'primereact/tooltip'
import { Link } from 'react-router-dom'
import { useCurrentOrganization } from '@components/App'
import { exactSearch } from '@services/utils/search'
import DetailsDialog from './DetailsDialog'
import RawSigsTable from './RawSigsTable'

function MedicineDetails({ rowData }) {
  const {
    code,
    medicineText,
  } = rowData.pharmacyMedicine || {}

  return (
    <div className="flex flex-column gap-1">
      <span>{medicineText}</span>
      {code && (
      <span>
        NDC:
        {' '}
        {code}
      </span>
      )}
    </div>
  )
}

function PharmacyOrdersTable({
  pharmacyOrders,
  isLoading,
  showDoseId = true,
  showAcknowledge = true,
  showDosing = true,
  showMedicine = true,
  showRawAdministrationTimes = false,
  actions = [],
}) {
  const [searchTerm, setSearchTerm] = useState()
  const [selectedPharmacyOrderId, setSelectedPharmacyOrderId] = useState(null)
  const [filteredOrders, setFilteredOrders] = useState([])
  const [expandedRows, setExpandedRows] = useState([])
  const currentOrganization = useCurrentOrganization()
  const { timezone } = currentOrganization

  const medicineTemplate = (rowData) => (
    <MedicineDetails rowData={rowData} />
  )

  useEffect(() => {
    if (searchTerm) {
      setFilteredOrders(exactSearch(
        pharmacyOrders,
        ['pharmacyMedicine.medicineText', 'orderControl', 'orderNumber', 'pharmacyMedicine.code', 'pharmacyMedicine.rxCui', 'pharmacyMedicine.source', 'doseId'],
        searchTerm,
      ))
    } else {
      setFilteredOrders(pharmacyOrders)
    }
  }, [pharmacyOrders, searchTerm])

  const showIntegrationDataButton = (rowData) => (
    <Button
      label="Details"
      className="p-button-text p-button-sm"
      onClick={() => setSelectedPharmacyOrderId(rowData.id)}
    />
  )

  const rowExpansionTemplate = (rowData) => (
    <RawSigsTable
      key={rowData.id}
      sigs={rowData.sigs}
      showDosing={showDosing}
    />
  )

  const dispenseAtTemplate = (rowData) => {
    if (rowData.pharmacyDispensedAt) {
      return moment(rowData.pharmacyDispensedAt).tz(timezone).format(momentFormats.datetime_12)
    }
  }

  const ackTemplate = (rowData) => {
    let content = 'No'
    let tooltipContent = ''

    // Need to check discardedAt first, because acknowledgedAt is also set when discarded
    if (rowData.discardedAt) {
      content = (<i className="pi pi-trash" />)
      const formattedTime = moment(rowData.discardedAt)
        .tz(timezone)
        .format(momentFormats.datetime_12)
      tooltipContent = `Discarded: ${formattedTime} by ${rowData.acknowledgedBy?.fullName}`
    } else if (rowData.acknowledgedAt) {
      content = 'Yes'
      const formattedTime = moment(rowData.acknowledgedAt)
        .tz(timezone)
        .format(momentFormats.datetime_12)
      tooltipContent = `Acknowledged: ${formattedTime} by ${rowData.acknowledgedBy?.fullName}`
    }

    return (
      <>
        <Tooltip target={`#ack-${rowData.id}`} />
        <span id={`ack-${rowData.id}`} data-pr-tooltip={tooltipContent}>
          {content}
        </span>
      </>
    )
  }

  const doseIdTemplate = (rowData) => {
    if (rowData.doseId) {
      return (
        <Link to={`/admin/patients/${rowData.patientId}/doses/manage/${rowData.doseId}`}>
          {rowData.doseId}
        </Link>
      )
    }

    return null
  }

  return (
    <div className="flex flex-column gap-2">
      <DetailsDialog
        pharmacyOrder={pharmacyOrders?.find((order) => order.id === selectedPharmacyOrderId)}
        isVisible={selectedPharmacyOrderId !== null}
        hide={() => setSelectedPharmacyOrderId(null)}
      />
      <div className="flex flex-row gap-2">
        <SearchField
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          placeholder="Search"
        />
        <div className="flex-grow-1" />
        {actions}
      </div>
      <DataTable
        value={filteredOrders}
        loading={isLoading}
        rowExpansionTemplate={rowExpansionTemplate}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        dataKey="id"
        removableSort
        scrollable
        scrollHeight="70vh"
        sortField="orderEffectiveDatetime"
        sortOrder={-1}
      >
        <Column expander style={{ maxWidth: '1rem' }} />
        <Column field="id" header="ID" sortable />
        <Column
          field="orderControl"
          header="CTRL"
          filter
          filterField="orderControl"
          filterPlaceholder="Search by control code"
        />
        {
          showMedicine && (
            <Column
              field="pharmacyMedicine.medicineText"
              header="Medicine"
              body={medicineTemplate}
              filter
              filterField="pharmacyMedicine.medicineText"
              filterPlaceholder="Search by medicine"
            />
          )
        }
        <Column
          field="orderNumber"
          header="Order #"
          filter
          filterField="orderNumber"
          filterPlaceholder="Search by order number"
        />
        {
          showDoseId && (
            <Column field="doseId" header="Dose" body={doseIdTemplate} />
          )
        }
        {
          showAcknowledge && (
            <Column field="acknowledgedAt" header="Ack" body={ackTemplate} />
          )
        }
        {
          showRawAdministrationTimes && (
            <Column field="rawAdministrationTimes" header="Raw Administration Times" />
          )
        }
        <Column
          field="orderEffectiveDatetime"
          header="Effective"
          body={
            (rowData) => moment(rowData.orderEffectiveDatetime)
              .tz(timezone)
              .format(momentFormats.datetime_12)
          }
          sortable
          filter
          filterField="orderEffectiveDatetime"
          filterPlaceholder="Search by date"
        />
        <Column
          field="pharmacyDispensedAt"
          header="Dispensed"
          body={dispenseAtTemplate}
          sortable
          filter
          filterField="pharmacyDispensedAt"
          filterPlaceholder="Search by date"
        />
        <Column field="showIntegrationData" header="" body={showIntegrationDataButton} />
      </DataTable>
    </div>
  )
}

export default PharmacyOrdersTable
