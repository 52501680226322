import React from 'react'
import { Password } from 'primereact/password'

function PasswordWithLabel({
  label, value, inputId, name, onChange, disabled, inputTextClassName, toggleMask,
}) {
  return (
    <div className="field">
      <label className="block" htmlFor={inputId}>{label}</label>
      <Password
        key={inputId}
        className={`w-12 ${inputTextClassName}`}
        id={inputId}
        value={value}
        name={name}
        onChange={onChange}
        disabled={disabled}
        toggleMask={toggleMask}
        feedback={false}
      />
    </div>
  )
}

export default PasswordWithLabel
