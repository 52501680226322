import { useMemo } from 'react'
import { get } from 'lodash'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

export function useCreateEkitMutation(statusMessage) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['ekit'],
    mutationFn: async (ekit) => ApiClient.post('/simpills', ekit),
    onSuccess: () => {
      queryClient.invalidateQueries('ekits')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useUpdateEkitMutation(ekitId, statusMessage) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['ekit'],
    mutationFn: async (ekit) => ApiClient.put(`/simpills/${ekitId}`, ekit),
    onSuccess: () => {
      queryClient.invalidateQueries('ekits')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useEkitQuery(ekitId, statusMessage) {
  return useQuery({
    queryKey: ['ekit', ekitId],
    queryFn: () => ApiClient.get(`/simpills/${ekitId}`),
    enabled: !!ekitId,
    onError: () => statusMessage.current.show([{
      severity: 'error',
      sticky: true,
      summary: 'Unable to fetch E-KIT configuration at this time.',
    }]),
  })
}

export function useEkitsQuery(statusMessage) {
  const query = useQuery({
    queryKey: ['ekits'],
    queryFn: () => ApiClient.get('/simpills'),
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })

  return useMemo(() => ({
    ...query,
    ekits: get(query, 'data.simpills'),
  }), [query])
}

export function useOpenDrawer(ekitId, statusMessage) {
  const mutation = useMutation({
    mutationKey: ['drawer', ekitId],
    mutationFn: (drawer) => ApiClient.post(`/simpills/${ekitId}/open_drawer`, { drawer }),
    onError: () => statusMessage.current.show([{
      severity: 'error',
      sticky: true,
      summary: 'Unable to open E-KIT drawer at this time.',
    }]),
    onSuccess: (_, drawer) => statusMessage.current.show([{
      severity: 'success',
      summary: `Drawer #${drawer} opened successfully.`,
    }]),
  })

  return { openDrawer: mutation.mutateAsync, isLoading: mutation.isLoading }
}
