import React, { useState, useEffect } from 'react'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import { formatAdministrationAmount } from '@components/clientDoses/doseUtils'

function AdministrationAmount({
  doseAmount, setDoseAmount, medicine, handleConfirm,
}) {
  const [filteredDoseAmounts, setFilteredDoseAmounts] = useState([])

  useEffect(() => {
    const doseAmounts = Array.from(
      { length: 10 },
      (_, index) => ({
        label: formatAdministrationAmount({ administrationAmount: (index + 1), medicine }),
        amount: index + 1,
      }),
    )

    setFilteredDoseAmounts(doseAmounts)
  }, [medicine])

  return (
    <div className="grid flex flex-grow-1 justify-content-center align-items-center fadein">
      <div className="col-9 flex flex-column gap-2 text-center">
        <label className="text-lg font-normal mb-1" htmlFor="dose-administration-amount">
          {/* eslint-disable-next-line */}
          What is the dose amount and measurement unit that should be administered each time it is due?
          <p className="text-sm font-normal">Example, 1 medication of 20 mg</p>
        </label>
        <Dropdown
          inputId="dose-administration-amount"
          value={doseAmount}
          data-testid="dose-amount"
          options={filteredDoseAmounts}
          optionLabel="label"
          optionValue="amount"
          onChange={(e) => setDoseAmount(e.value)}
          aria-label="Dose Amount"
          dropdownarialabel="Select Dose Amount"
        />
        <Button
          onClick={handleConfirm}
          label="Next"
          className="p-button-text mt-2"
          disabled={!medicine.strength}
        />
      </div>
    </div>
  )
}

export default AdministrationAmount
