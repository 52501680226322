import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import MatchLabel from '@components/misc/MatchLabel'
import PatientsToLinkTableHeader from './PatientsToLinkTableHeader'

const fieldColumnTemplate = (patientValue, pendingUserValue, displayValue) => (
  <MatchLabel
    referenceValue={patientValue}
    comparedValue={pendingUserValue}
    displayValue={displayValue || ''}
  />
)

const linkButton = (
  patient,
  pendingUser,
  patientRoleLabel,
  setShowUsersLinkConfirm,
  setPendingUserToLink,
  setPatientToLink,
  setTabIndex,
) => (
  <Button
    icon="pi pi-link"
    className="p-button-sm p-button-outlined"
    label={`Link to this ${patientRoleLabel}`}
    onClick={() => {
      const typeAddedPendingUser = { ...pendingUser, type: 'Pending User' }
      const selectedPatient = { ...patient, type: `Existing ${patientRoleLabel}` }

      setShowUsersLinkConfirm(true)
      setPendingUserToLink(typeAddedPendingUser)
      setPatientToLink(selectedPatient)
      setTabIndex(2)
    }}
  />
)

function PatientsToLinkTable({
  patients,
  pendingUser,
  isLoading,
  patientRoleLabel,
  patientBirthSexOptions,
  setShowUsersLinkConfirm,
  setPendingUserToLink,
  setPatientToLink,
  setTabIndex,
}) {
  if (patients && patients.length === 0 && !isLoading) {
    return (
      <div className="text-lg font-bold">
        <span>No match found</span>
      </div>
    )
  }

  return (
    <DataTable
      header={(
        <PatientsToLinkTableHeader
          usersLength={patients.length}
          patientRoleLabel={patientRoleLabel}
        />
      )}
      loading={isLoading}
      value={patients}
      showGridlines
      tableStyle={{ minWidth: '50rem' }}
      data-testid="patientsToLinkTable"
    >
      <Column
        field="firstName"
        header="First Name"
        body={(patient) => fieldColumnTemplate(
          patient.firstName,
          pendingUser.firstName,
          patient.firstName,
        )}
      />
      <Column
        field="lastName"
        header="Last Name"
        body={(patient) => fieldColumnTemplate(
          patient.lastName,
          pendingUser.lastName,
          patient.lastName,
        )}
      />
      <Column
        field="dateOfBirth"
        header="Date of Birth"
        body={(patient) => fieldColumnTemplate(
          patient.dateOfBirth,
          pendingUser.dateOfBirth,
          patient.dateOfBirth,
        )}
      />
      <Column
        field="birthSex"
        header="Birth Sex"
        body={(patient) => {
          const patientSexDisplay = patientBirthSexOptions.find(
            (option) => option.value === patient.birthSex,
          )?.label
          return fieldColumnTemplate(patient.birthSex, pendingUser.birthSex, patientSexDisplay)
        }}
      />
      <Column
        field="site.name"
        header="Site"
      />
      <Column
        header="Link"
        className="w-2"
        body={(patient) => linkButton(
          patient,
          pendingUser,
          patientRoleLabel,
          setShowUsersLinkConfirm,
          setPendingUserToLink,
          setPatientToLink,
          setTabIndex,
        )}
      />
    </DataTable>
  )
}

export default PatientsToLinkTable
