import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'
import { useMutation, useQueryClient } from '@tanstack/react-query'

const useDoseHoldCreate = (doseId, statusMessage) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['createDoseHold', doseId],
    mutationFn: (payload) => ApiClient.post('/dose_holds', payload),
    onSuccess: () => {
      queryClient.invalidateQueries('doseHolds')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export default useDoseHoldCreate
