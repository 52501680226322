import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

export function useCreateTaskCondition(statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['useCreateTaskCondition'],
    mutationFn: (taskCondition) => ApiClient.post('/task_conditions', { taskCondition }),
    onSuccess: () => {
      statusMessage.current.show([
        {
          severity: 'success',
          summary: 'Task criteria / vitals created!',
        },
      ])
      queryClient.invalidateQueries('task')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export default {}
