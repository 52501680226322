import React from 'react'
import { InputText } from 'primereact/inputtext'
import { Password } from 'primereact/password'

function InputTextWithLabel({
  label,
  value,
  inputId,
  name,
  onChange,
  disabled,
  fieldContainerClassName = '',
  labelClassName = '',
  inputTextClassName,
  type = 'text',
  tooltip = null,
  tooltipOptions = {},
}) {
  const commonProps = {
    key: inputId,
    id: inputId,
    value,
    name,
    onChange,
    tooltip,
    tooltipOptions,
  }

  return (
    <div className={`field ${fieldContainerClassName}`}>
      <label className={`block ${labelClassName}`} htmlFor={inputId}>{label}</label>
      {
        type === 'password'
          ? (
            <Password
              className="w-12"
              toggleMask
              feedback={false}
              {...commonProps}
            />
          )
          : (
            <InputText
              className={`p-2 w-12 ${inputTextClassName}`}
              disabled={disabled}
              {...commonProps}
            />
          )
    }
    </div>
  )
}

export default InputTextWithLabel
