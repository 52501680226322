import React from 'react'
import moment from 'moment-timezone'
import { groupBy, isEmpty, map } from 'lodash'
import ConsumptionTag from './ConsumptionTag'
import EmptyCell from '../../EmptyCell'

function StatusIndicator({
  consumptions,
  marTimeRow,
  cellTime,
  canEdit,
  onEmptyCellClick,
  onConsumptionsClick,
}) {
  if (isEmpty(consumptions)) {
    if (cellTime.isAfter(moment())) return null
    if (!canEdit) return null
    return (
      <EmptyCell onClick={() => onEmptyCellClick(marTimeRow, cellTime)} />
    )
  }

  return (
    <div
      className="flex flex-column justify-content-center align-items-center gap-1 w-full h-full cursor-pointer"
    >
      {map(groupBy(consumptions, 'status'), (consumptionsByStatus, status) => (
        <ConsumptionTag
          onClick={(e) => onConsumptionsClick(consumptions, marTimeRow, cellTime, e)}
          key={status}
          type={status}
          count={consumptionsByStatus.length}
        />
      ))}
      <span className="flex flex-row w-full justify-content-center align-content-center cursor-pointer">
        {canEdit && (
          <i
            className="pi pi-plus-circle text-400"
            style={{ fontSize: '1rem' }}
            onClick={() => onEmptyCellClick(marTimeRow, cellTime)}
          />
        )}
      </span>
    </div>
  )
}

export default StatusIndicator
