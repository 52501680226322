import React, { useState, useRef } from 'react'
import { get, isEmpty } from 'lodash'
import { useLocation, Link } from 'react-router-dom'
import { Divider } from 'primereact/divider'
import { BreadCrumb } from 'primereact/breadcrumb'
import { Menu } from 'primereact/menu'
import { Button } from 'primereact/button'
import BarcodeDialog from '@components/clientDoses/Barcode/DoseBarcodeDialog'
import DoseTag from '@components/clientDoses/Header/DoseTag'
import { calculateDoseStatus, calculateDoseType, hasDoseEnded } from '@components/clientDoses/doseUtils'
import { useCurrentUser } from '@components/App'
import { MED_BOX_STATUS_TAG_COLOR } from '../../MedBoxes/config'

import './client-dose.scss'
import RestartDoseDialog from './RestartDoseDialog'
import ClientDoseHoldDialog from './ClientDoseHoldDialog'

function DoseHeader({
  dose, endDose, unpauseDose, setMarDialogVisible, generateConsumptions,
}) {
  const [showPauseDialog, setShowPauseDialog] = useState(false)
  const [showBarcodeDialog, setShowBarcodeDialog] = useState(false)
  const [isRestartDialogVisible, setIsRestartDialogVisible] = useState(false)

  const doseActionMenu = useRef(null)
  const { state: locationState } = useLocation()
  const currentUser = useCurrentUser()
  const isCurrentUserSuperAdmin = currentUser.role === 'superadmin'

  const doseStatus = calculateDoseStatus(dose)
  const doseType = calculateDoseType(dose)
  const medbox = get(dose, 'medbox')
  const medBoxStatus = {
    text: get(medbox, 'status'),
    color: MED_BOX_STATUS_TAG_COLOR[medbox?.status],
  }

  const handleEndDoseNow = () => {
    endDose({ id: dose.id })
  }

  const handleGenerateConsumptions = () => {
    generateConsumptions(dose.id)
  }

  const handleUnpauseDose = () => {
    unpauseDose(dose.id)
  }

  // TODO: Consider adding an action for creating a dose sig
  const doseActions = () => {
    const currentPause = get(dose, 'currentPause')
    const isEnded = hasDoseEnded(dose)
    const actions = []
    if (isEnded) {
      actions.push({
        label: 'Restart Dose',
        icon: 'pi pi-play',
        command: () => setIsRestartDialogVisible(true),
      })
    } else {
      if (currentPause) {
        actions.push({
          label: 'Remove Hold',
          icon: 'pi pi-play',
          command: handleUnpauseDose,
        })
      } else {
        actions.push({
          label: 'Hold Medication',
          icon: 'pi pi-pause',
          command: () => setShowPauseDialog(true),
        })

        if (isCurrentUserSuperAdmin) {
          actions.push({
            label: 'Generate Consumptions',
            icon: 'pi pi-wrench',
            command: handleGenerateConsumptions,
          })
        }
      }
      actions.push({
        label: 'End Dose Now',
        icon: 'pi pi-stopwatch',
        command: handleEndDoseNow,
      })
      actions.push({
        label: 'Export MAR',
        icon: 'pi pi-file-export',
        command: () => setMarDialogVisible(true),
      })
    }

    return actions
  }

  const titleTemplate = () => {
    if (get(locationState, 'previous')) {
      const needsReviewTemplate = <Link to={get(locationState, 'previous')}>Needs Review</Link>

      const doseInfoTemplate = (
        <div className="flex flex-row align-items-center">
          <span>Dose</span>
          <span className="ml-2">
            <DoseTag color={doseStatus.color} text={doseStatus.text} />
          </span>
          <span className="ml-2">
            <DoseTag color={doseType.color} text={doseType.text} />
          </span>
        </div>
      )

      return (
        <span className="text-lg flex align-items-end">
          <BreadCrumb
            model={[{ label: 'Dose', template: doseInfoTemplate }]}
            home={{ label: 'Order Review', template: needsReviewTemplate }}
          />
        </span>
      )
    }

    return (
      <span className="text-lg flex align-items-end">
        <span>Dose</span>
        <span className="ml-2">
          <DoseTag color={doseStatus.color} text={doseStatus.text} />
        </span>
        <span className="ml-2">
          <DoseTag color={doseType.color} text={doseType.text} />
        </span>
      </span>
    )
  }

  return (
    <>
      <ClientDoseHoldDialog
        visible={showPauseDialog}
        onHide={() => setShowPauseDialog(false)}
        doseId={dose.id}
      />
      {isCurrentUserSuperAdmin && dose.externalId
      && (
      <BarcodeDialog
        visible={showBarcodeDialog}
        onHide={() => setShowBarcodeDialog(false)}
        dose={dose}
      />
      )}
      <RestartDoseDialog
        isVisible={isRestartDialogVisible}
        onHide={() => setIsRestartDialogVisible(false)}
        patientId={get(dose, 'patientId')}
        doseId={get(dose, 'id')}
      />
      <div className="col-12" data-testid="dose-header">
        <div className="p-menubar bg-white flex flex-row align-items-center px-3">
          <div className="flex flex-column gap-2 align-items-baseline">
            {titleTemplate()}
            <div className="flex flex-column gap-2 align-items-start">
              <span className="text-sm text-600 mr-2">
                ID:
                <span className="text-900 ml-1">{dose.id}</span>
              </span>
              {
               !isEmpty(dose.activePrescriptionNumbers) && (
               <div className="flex gap-1 align-items-center">
                 <span className="text-sm text-600 mr-2">
                   Rx:
                   <span className="text-900 ml-1">{dose.externalId}</span>
                 </span>
                 {
                    isCurrentUserSuperAdmin
                    && (
                    <div
                      className="cursor-pointer"
                      onClick={() => setShowBarcodeDialog(true)}
                    >
                      <i className="fa-solid fa-barcode" />
                    </div>
                    )
                  }
               </div>
               )
              }
              {
                medbox
                && (
                  <>
                    <Divider layout="vertical" className="p-0 m-2" align="center" />
                    <div className="flex align-items-center">
                      <span className="text-sm text-600 mr-2">
                        Med Box:
                        <a className="text-blue-500 ml-1 cursor-pointer" href={`/admin/med_boxes/${medbox?.id}`}>
                          <span>{medbox?.label}</span>
                        </a>
                      </span>
                      <span className="flex align-items-center text-sm text-600 mr-2">
                        Status:
                        <span className="ml-2">
                          <DoseTag color={medBoxStatus.color} text={medBoxStatus.text} />
                        </span>
                      </span>
                    </div>
                  </>
                )
              }
            </div>
            {dose.pharmacyInstructions
              && (
                <span className="text-sm text-600">
                  Pharmacy Instructions:
                  <span className="text-900 ml-1">{dose.pharmacyInstructions}</span>
                </span>
              )}
            <div>
              {
                dose.currentPause && dose.currentPause.note
                && (
                  <span className="text-sm text-600">
                    Hold Note:
                    <span className="text-900 ml-1">{dose.currentPause.note}</span>
                  </span>
                )
              }
            </div>
          </div>
          <div className="ml-auto">
            <Menu model={doseActions()} popup ref={doseActionMenu} id="popup_menu" />
            <Button
              label="Actions"
              icon="pi pi-chevron-down"
              className="p-button-sm"
              iconPos="right"
              aria-controls="popup_menu"
              aria-haspopup
              onClick={(event) => doseActionMenu.current.toggle(event)}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default DoseHeader
