import React from 'react'
import { RRule } from 'rrule'
import { Dropdown } from 'primereact/dropdown'
import { InputNumber } from 'primereact/inputnumber'
import { frequencies } from './config'
import DaysOfWeekControls from './DaysOfWeekControls'
import MonthlyControls from './MonthlyControls'

function AdvancedPatternControls({
  interval, setInterval, frequency, setFrequency, selectedDaysOfWeek, setSelectedDaysOfWeek,
}) {
  const handleFrequencyChange = ({ value }) => {
    setSelectedDaysOfWeek([])
    setFrequency(value)
  }

  return (
    <div className="flex flex-column gap-4">
      <div className="p-inputgroup w-full">
        <span className="p-inputgroup-addon p-0 font-semibold w-4">
          Occurs Every
        </span>
        <span className="p-inputgroup-addon p-0 bg-white font-semibold w-3">
          <InputNumber
            showButtons
            value={interval}
            className="h-full"
            incrementButtonClassName="p-button-secondary p-button-text w-3rem"
            decrementButtonClassName="p-button-secondary p-button-text w-3rem"
            inputClassName="h-full text-center"
            inputStyle={{ border: 0 }}
            onValueChange={(e) => setInterval(e.value)}
          />
        </span>
        <Dropdown
          className="w-4"
          inputId="dose-schedule-pattern"
          value={frequency}
          options={frequencies}
          onChange={handleFrequencyChange}
        />
      </div>
      {
        frequency === RRule.WEEKLY && (
          <DaysOfWeekControls
            selectedDaysOfWeek={selectedDaysOfWeek}
            setSelectedDaysOfWeek={setSelectedDaysOfWeek}
          />
        )
      }
      {
        frequency === RRule.MONTHLY && (
          <MonthlyControls
            selectedDaysOfWeek={selectedDaysOfWeek}
            setSelectedDaysOfWeek={setSelectedDaysOfWeek}
          />
        )
      }
    </div>
  )
}

export default AdvancedPatternControls
