import React, { useState, useEffect } from 'react'
import moment from 'moment'
import {
  get, find, reduce,
} from 'lodash'
import { classNames } from 'primereact/utils'
import { Dropdown } from 'primereact/dropdown'
import { generateTimeIncrements, getTimeFromMinutes } from '@services/utils'

function TimePickerWithLabel({
  label,
  value,
  inputId,
  onChange,
  placeholder = 'Select a time',
  showClear = false,
  minTime = getTimeFromMinutes(0),
}) {
  const [invalid, setInvalid] = useState(false)
  // Use set state to calculate time increments only when the component is mounted
  const [timeIncrements] = useState(
    generateTimeIncrements({ minuteIncrement: 15, durationHours: 24 }),
  )
  const timeOptions = reduce(
    timeIncrements,
    (options, increment) => {
      if (moment(increment.value).isAfter(moment(minTime))) {
        options.push({ label: increment.label, value: { time: increment.value } })
      }
      return options
    },
    [],
  )

  const validateInput = () => {
    if (find(timeIncrements, (increment) => increment.value === value?.time)) {
      setInvalid(false)
      return true
    }

    const time = moment(value, ['h:mm A'])
    if (time.isValid()) {
      time.set({ second: 0, millisecond: 0 })
      timeIncrements.push({
        label: time.format('h:mm A'),
        value: time.valueOf(),
      })
      onChange({ time: time.valueOf() })
      setInvalid(false)
      return true
    }

    if (!value?.time) {
      setInvalid(false)
      return true
    }

    setInvalid(true)
  }

  const formatAndSetTimeValue = (timeValue) => {
    const timeLabel = moment(timeValue).format('h:mm A')
    const existsInIncrements = find(timeIncrements, (increment) => increment.label === timeLabel)
    const time = moment(timeLabel, ['h:mm A'])
    if (time.isValid()) {
      onChange({ time: time.valueOf() })
      setInvalid(false)
    }

    if (!existsInIncrements) {
      time.set({ second: 0, millisecond: 0 })
      timeIncrements.push({
        label: time.format('h:mm A'),
        value: time.valueOf(),
      })
    }
  }

  useEffect(() => {
    let time = get(value, 'time')
    if (!time && value) {
      time = getTimeFromMinutes(value)
    }

    if (time && moment(time).isValid()) {
      formatAndSetTimeValue(time)
    }
  }, [])

  return (
    <div className="field">
      <label className="block" htmlFor={inputId}>{label}</label>
      <Dropdown
        inputId={inputId}
        className={classNames('time-picker w-full', { 'p-invalid': invalid })}
        value={value}
        options={timeOptions}
        onChange={(event) => onChange(event.value)}
        onBlur={() => validateInput()}
        onHide={() => validateInput()}
        onMouseLeave={() => validateInput()}
        filter
        editable
        filterBy="label"
        filterMatchMode="startsWith"
        placeholder={placeholder}
        showClear={showClear}
      />
    </div>
  )
}

export default TimePickerWithLabel
