import React, { useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { Menu } from 'primereact/menu'
import UnlinkPrescriptionDialog from '@components/prescriptions/UnlinkPrescriptionDialog'

export function PrescriptionActionMenu({ statusMessage, prescription }) {
  const actionMenu = useRef(null)
  const [showUnlinkDialog, setShowUnlinkDialog] = useState(false)

  if (!prescription) {
    return null
  }

  const actions = []

  // TODO: Probably add actions for linking, discontinuing, deleting, and re-syncing (if DRX)

  if (prescription.doseId) {
    actions.push({
      label: 'Unlink',
      icon: 'fa-solid fa-unlink',
      command: () => setShowUnlinkDialog(true),
    })
  }

  if (!actions.length) {
    return null
  }

  return (
    <div className="ml-auto">
      <Menu model={actions} popup ref={actionMenu} id="popup_menu" />
      <Button
        label="Actions"
        icon="pi pi-chevron-down"
        className="p-button-sm"
        iconPos="right"
        aria-controls="popup_menu"
        aria-haspopup
        onClick={(event) => actionMenu.current.toggle(event)}
      />
      <UnlinkPrescriptionDialog
        isVisible={showUnlinkDialog}
        prescriptionId={prescription.id}
        messagesRef={statusMessage}
        onHide={() => setShowUnlinkDialog(false)}
      />
    </div>
  )
}

export default PrescriptionActionMenu
