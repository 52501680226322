import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'
import { useMutation, useQueryClient } from '@tanstack/react-query'

const useLeaveOfAbsenceCreateMutation = (patientId, statusMessage) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['patient', patientId],
    mutationFn: (payload) => ApiClient.post('/leave_of_absences', payload),
    onSuccess: () => {
      queryClient.invalidateQueries('leave_of_absences')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export default useLeaveOfAbsenceCreateMutation
