import React, { useEffect, useRef, useState } from 'react'
import { filter, split } from 'lodash'
import { Button } from 'primereact/button'
import { SelectButton } from 'primereact/selectbutton'
import { Toast } from 'primereact/toast'
import AttributeCard from '@components/display/AttributeCard'
import { ReviewTaskTable } from '@components/tasks/ReviewTaskTable'
import { useAcknowledgeTreatment } from '@hooks/treatments'

const getActionOptions = (directives) => {
  const actionOptions = []

  if (directives.includes('new_treatment')) {
    actionOptions.push(
      { label: 'Add as Task', value: 'create_task' },
      { label: 'Add as Reminder in Med Pass', value: 'create_remind' },
    )
  }

  if (directives.includes('treatment_update')) {
    actionOptions.push(
      { label: 'Update as Task', value: 'update_task' },
      { label: 'Update as Reminder in Med Pass', value: 'update_remind' },
    )
  }

  if (directives.includes('discontinue_treatment') || directives.includes('cancel_treatment')) {
    actionOptions.push(
      { label: 'Discontinue Treatment', value: 'discontinue' },
    )
  }

  actionOptions.push(
    { label: 'Ignore Changes', value: 'ignore' },
  )

  return actionOptions
}

const getActionButtonText = (mode) => {
  switch (mode) {
    case 'create_task':
      return 'Onboard Treatment'
    case 'create_remind':
      return 'Onboard Treatment'
    case 'update_task':
      return 'Update Treatment'
    case 'update_remind':
      return 'Update Treatment'
    case 'ignore':
      return 'Ignore Changes'
    case 'discontinue':
      return 'Discontinue Treatment'
    default:
      return ''
  }
}

const getDefaultMode = (treatment, directive) => {
  switch (directive) {
    case 'new_treatment':
      return 'create_task'
    case 'treatment_update':
      return treatment?.tasks?.length ? 'update_task' : 'update_remind'
    case 'discontinue_treatment':
    case 'cancel_treatment':
      return 'discontinue'
    default:
      return 'ignore'
  }
}

const getModeText = (mode) => {
  switch (mode) {
    case 'create_task':
      return 'A task will be created using the treatment details and the following information.'
    case 'create_remind':
      return 'Select this option if this treatment has no task to complete and is more of a user preference (e.g. ENCOURAGE SECONDS OF FRUITS/VEGGIES). This treatment will appear as a reminder during each med pass.'
    case 'update_task':
      return 'The treatment and task will be updated using the treatment details and the following information.'
    case 'update_remind':
      return 'The treatment will be updated and appear as a reminder during each med pass.'
    case 'ignore':
      return 'The pharmacy order will be ignored and no changes will be made to the treatment or task.'
    case 'discontinue':
      return 'The treatment will be updated and discontinued and any task will be updated and ended.'
    default:
      return ''
  }
}

function ActionSection({ patient, treatment, directives }) {
  const statusMessage = useRef(null)
  const [actionOptions, setActionOptions] = useState([])
  const [selectedMode, setSelectedMode] = useState(null)
  const [task, setTask] = useState({})

  const {
    mutateAsync: acknowledgeTreatment,
  } = useAcknowledgeTreatment({ statusMessage })

  useEffect(() => {
    setActionOptions(getActionOptions(directives?.map((d) => d.directive)) || [])
    setSelectedMode(getDefaultMode(treatment, directives?.find((d) => d.isPrimary)?.directive))
  }, [treatment, directives])

  const helpText = 'Choose how to handle onboarding this treatment.'
  const showReviewTaskTable = ['create_task', 'update_task'].includes(selectedMode)

  const handleActionChange = (e) => {
    setSelectedMode(e.value)
  }

  const handleAcknowledgeTreatment = async () => {
    const params = {
      id: treatment.id,
      mode: selectedMode,
      treatment: {
        pharmacyInstructions: treatment.pharmacyInstructions,
        rawAdministrationTimes: treatment.rawAdministrationTimes,
        startAt: treatment.startAt,
        endAt: treatment.endAt,
      },
    }

    if (['create_task', 'update_task'].includes(selectedMode)) {
      params.task = {
        id: task.id,
        taskType: task.taskType,
        taskAction: task.taskAction,
        title: task.title,
        description: task.description,
        showOnTar: task.showOnTar,
        startAt: task.startAt,
        endAt: task.endAt,
        schedules: task.schedules.map((schedule) => ({
          flexibilityAttributes: schedule.flexibility,
          time_hh_mm: schedule.time_hh_mm,
          // The rrule.js library converts rrules to string
          // in format "RRULE:FREQ=WEEKLY;INTERVAL=1;BYDAY=WE,FR"
          // The below line extracts the rule itself, omitting the "RRULE:"
          // so that it is compatible with API validations
          rrule: filter(split(schedule.rrule, ':'), (rule) => rule !== 'RRULE').join(';'),
        })),
      }
    }

    await acknowledgeTreatment(params)
  }

  const actionButtonTemplate = () => (
    <Button
      label={getActionButtonText(selectedMode)}
      className="p-button-sm"
      onClick={handleAcknowledgeTreatment}
    />
  )

  return (
    <div className="col-12">
      <Toast ref={statusMessage} />
      <AttributeCard title={{ helpText, label: 'Action' }} ActionComponent={actionButtonTemplate}>
        <div className="flex flex-row justify-content-center">
          <SelectButton
            allowEmpty={false}
            onChange={handleActionChange}
            optionLabel="label"
            optionValue="value"
            options={actionOptions}
            placeholder="Select an option"
            value={selectedMode}
          />
        </div>
        <div className="flex flex-row justify-content-center">
          <span className="opacity-70 text-center pt-2 pb-2 w-8">{getModeText(selectedMode)}</span>
        </div>
        {
          showReviewTaskTable && (
            <ReviewTaskTable
              patient={patient}
              treatment={treatment}
              task={task}
              setTask={setTask}
            />
          )
        }
      </AttributeCard>
    </div>
  )
}

export default ActionSection
