import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

export const useUpdateNotificationSetting = (
  notificationSettingId,
  statusMessage,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (notificationSetting) => ApiClient.put(
      `notification_settings/${notificationSettingId}`,
      notificationSetting,
    ),
    onSuccess: () => {
      queryClient.invalidateQueries('notificationSettings')
      statusMessage.current.clear()
      statusMessage.current.show([
        {
          severity: 'success',
          summary: 'Notification setting updated',
        },
      ])
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export default useUpdateNotificationSetting
