import React from 'react'
import { Checkbox } from 'primereact/checkbox'
import { useAuth } from '../../../context/AuthContext'

function EulaCheckbox() {
  const { eulaAccepted, setEulaAccepted } = useAuth()

  return (
    <div className="field flex flex-row">
      <Checkbox
        id="eula"
        checked={eulaAccepted}
        onChange={(e) => setEulaAccepted(e.checked)}
        className="mr-2"
      />
      <span
        className="text-justify"
        onClick={() => setEulaAccepted(!eulaAccepted)}
      >
        By logging in, you accept our terms and conditions. Please click to
        read our entire
        {' '}
        <a href="/privacy/index" target="_blank">
          EULA
        </a>
      </span>
    </div>
  )
}

export default EulaCheckbox
