import React, { useState } from 'react'
import { get, sortBy } from 'lodash'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { Divider } from 'primereact/divider'
import { MultiSelect } from 'primereact/multiselect'
import moment from 'moment'
import { Calendar } from 'primereact/calendar'
import { momentFormats } from '@services/utils/moment'
import { useExportSiteConsumptions, useExportSiteDoses } from '../../../features/InventoryManagement/inventoryHooks'

function ExportConsumptionsContent({
  onExportConsumptions, sites, hide, loading,
}) {
  const [selectedSites, setSelectedSites] = useState([])
  const [exportRange, setExportRange] = useState('7d')
  const [customRange, setCustomRange] = useState({ startDate: moment().subtract(7, 'days'), endDate: moment() })
  const exportRangeOptions = [
    { label: 'Last 7 days', value: '7d' },
    { label: 'Last 30 days', value: '30d' },
    { label: 'Last 90 days', value: '90d' },
    { label: 'All time', value: 'all' },
    { label: 'Custom', value: 'custom' },
  ]

  return (
    <div className="flex flex-column gap-2">
      <div className="flex flex-row gap-2 align-items-center">
        <span className="font-normal">Site:</span>
        <MultiSelect
          value={selectedSites}
          options={sortBy(sites.map((s) => ({ label: s.name, value: s })), 'label')}
          showClear
          onChange={(e) => setSelectedSites(e.value)}
          placeholder="Select a Site"
          className="p-column-filter w-18rem"
          disabled={loading}
        />
        <span className="font-normal">Export:</span>
        <Dropdown
          value={exportRange}
          options={exportRangeOptions}
          onChange={(e) => setExportRange(e.value)}
          placeholder="Select a Range"
          className="flex-grow-1"
        />
        {
          exportRange === 'custom' && (
            <div className="flex flex-row gap-2 align-items-center">
              <span className="font-normal">From:</span>
              <Calendar
                value={customRange.startDate.toDate()}
                onChange={(e) => setCustomRange({ ...customRange, startDate: moment(e.value) })}
                dateFormat="mm/dd/yy"
                className="p-column-filter"
                showIcon
                disabled={loading}
              />
              <span className="font-normal">To:</span>
              <Calendar
                value={customRange.endDate.toDate()}
                onChange={(e) => setCustomRange({ ...customRange, endDate: moment(e.value) })}
                dateFormat="mm/dd/yy"
                className="p-column-filter"
                showIcon
                disabled={loading}
              />
            </div>
          )
        }
      </div>
      <Button
        className="p-button-sm p-button-outlined ml-auto mt-2"
        label="Export"
        onClick={async () => {
          await onExportConsumptions(
            selectedSites.map((s) => s.id),
            exportRange,
            customRange.startDate.format(momentFormats.date),
            customRange.endDate.format(momentFormats.date),
          )
          hide()
        }}
        loading={loading}
        disabled={selectedSites.length === 0}
      />
    </div>
  )
}

function ExportDosesContent({
  site, onExportDoses, sites, onSiteSelect, hide, loading,
}) {
  return (
    <div className="flex flex-column gap-2">
      <span className="font-normal">Select a site to export doses for, if you don't select a site, you will receive multiple files for each site to your email.</span>
      <div className="flex flex-row gap-2 align-items-center">
        <Dropdown
          value={site}
          options={sortBy(sites.map((s) => ({ label: s.name, value: s })), 'label')}
          showClear
          onChange={(e) => onSiteSelect(e.value)}
          placeholder="Select a Site"
          className="p-column-filter"
          style={{ width: '100%' }}
          disabled={loading}
        />
      </div>
      <Button
        className="p-button-sm p-button-outlined ml-auto"
        label="Export"
        onClick={async () => {
          await onExportDoses(get(site, 'id'))
          hide()
        }}
        loading={loading}
      />
    </div>
  )
}

function ExportDialog({
  statusMessage, visible, hide, sites, organizationId,
}) {
  const [selectedSite, setSelectedSite] = useState()
  const [exportType, setExportType] = useState('consumptions')
  const exportTypeOptions = [
    { label: 'Consumption History', value: 'consumptions' },
    { label: 'Doses', value: 'doses' },
  ]
  const {
    exportSiteConsumptions,
    isLoading: isSiteConsumptionsLoading,
  } = useExportSiteConsumptions(statusMessage)

  const {
    exportSiteDoses,
    isLoading: isSitedosesLoading,
  } = useExportSiteDoses(statusMessage, organizationId)

  return (
    <Dialog header="Export" visible={visible} onHide={() => hide()}>
      <div className="flex flex-column text-base font-normal mt-2">
        <span className="font-normal mb-2">What would you like to export?</span>
        <Dropdown
          value={exportType}
          options={exportTypeOptions}
          onChange={(e) => setExportType(e.value)}
          placeholder="Select a Type"
          className="flex-grow-1"
          disabled={isSiteConsumptionsLoading || isSitedosesLoading}
        />
        <Divider />
        {exportType === 'consumptions' && (
          <ExportConsumptionsContent
            site={selectedSite}
            sites={sites}
            onSiteSelect={setSelectedSite}
            onExportConsumptions={exportSiteConsumptions}
            hide={hide}
            loading={isSiteConsumptionsLoading}
          />
        )}
        {exportType === 'doses' && (
          <ExportDosesContent
            site={selectedSite}
            sites={sites}
            onSiteSelect={setSelectedSite}
            onExportDoses={exportSiteDoses}
            hide={hide}
            loading={isSitedosesLoading}
          />
        )}
      </div>
    </Dialog>
  )
}

export default ExportDialog
