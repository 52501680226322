import React from 'react'
import { Dropdown } from 'primereact/dropdown'

function WeekDayDropdownWithLabel({
  classNameDiv = 'field',
  classNameLabel = 'block',
  classNameDropdown = '',
  label = 'What day of the week?',
  onChange,
  placeholder = 'Select a day',
  value,
}) {
  return (
    <div className={classNameDiv}>
      <label className={classNameLabel} htmlFor="week-day-dropdown">{label}</label>
      <Dropdown
        className={classNameDropdown}
        id="week-day-dropdown"
        onChange={onChange}
        // Issue: Dropdown returns option object as value when using { label: 'Sunday', value: 0 }
        // So use string value to avoid misinterpretation as falsy value
        // and leads to unexpected behavior, Rails will handle the conversion
        // Related issue https://github.com/primefaces/primereact/issues/4560
        options={[
          { label: 'Monday', value: '1' },
          { label: 'Tuesday', value: '2' },
          { label: 'Wednesday', value: '3' },
          { label: 'Thursday', value: '4' },
          { label: 'Friday', value: '5' },
          { label: 'Saturday', value: '6' },
          { label: 'Sunday', value: '0' },
        ]}
        placeholder={placeholder}
        // Convert value from backend to string to match the options value
        value={value?.toString()}
      />
    </div>
  )
}

export default WeekDayDropdownWithLabel
