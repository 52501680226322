import React, { useState, useEffect, useRef } from 'react'
import { useParams, Link } from 'react-router-dom'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { DetailsForm } from '@components/sites/WifiForm'
import { compact, get } from 'lodash'
import { useSiteWifis, useUpsertSiteWifi } from '@hooks/sites'
import FormWrapper from '../../components/display/Form/FormWrapper'

function UpsertWifi({ handleSetHeader }) {
  const statusMessage = useRef(null)
  const { siteId } = useParams()

  const [details, setDetails] = useState({
    ssid: '',
    password: '',
  })

  const {
    data: wifis,
    isLoading: wifisIsLoading,
  } = useSiteWifis(siteId, statusMessage)

  const {
    mutateAsync: upsertWifi,
    isLoading: upsertWifiIsLoading,
  } = useUpsertSiteWifi(siteId, statusMessage)

  const handleUpsertWifi = () => {
    const wifiDetails = {
      ...details,
      id: get(wifis[0], 'id'),
    }
    upsertWifi({ siteWifi: wifiDetails })
  }

  const breadcrumbs = compact([
    {
      label: 'Site',
      template: <Link to={`/admin/sites/${siteId}`}>Site</Link>,
    },
    {
      label: 'Wifi',
      template: <span>Wi-Fi</span>,
    },
  ])

  useEffect(() => {
    if (wifis && wifis.length > 0) {
      setDetails(wifis[0])
    }
    handleSetHeader({ breadcrumbs })
  }, [wifis])

  const buttonLabel = wifis.length > 0 ? 'Update' : 'Create'

  return (
    <div id="upsert-wifi" className="flex flex-column">
      <div className="flex flex-row flex-wrap">
        <Toast ref={statusMessage} />
        <div className="col-6">
          <FormWrapper fullHeight title="Wi-Fi">
            <DetailsForm
              details={details}
              setDetails={(data) => setDetails({ ...details, ...data })}
            />
            <Button
              label={buttonLabel}
              className="p-button-sm m-2"
              loading={upsertWifiIsLoading || wifisIsLoading}
              onClick={handleUpsertWifi}
            />
          </FormWrapper>
        </div>
      </div>
    </div>
  )
}

export default UpsertWifi
