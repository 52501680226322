import React from 'react'
import { Button } from 'primereact/button'

function RevealableIdNumber({
  idNumber, idNumberRevealed, onRevealToggle,
}) {
  if (!idNumber) {
    return <i>None</i>
  }

  if (idNumberRevealed) {
    return (
      <div className="revealable-id-number">
        <span>{idNumber}</span>
        <Button
          icon="pi pi-eye-slash"
          className="p-button-text p-0"
          onClick={() => onRevealToggle(!idNumberRevealed)}
        />
      </div>
    )
  }

  if (!idNumberRevealed) {
    return (
      <div className="revealable-id-number">
        <span />
        <span>{idNumber.slice(0, -1).replace(/./g, '*') + idNumber.slice(-1)}</span>
        <Button
          icon="pi pi-eye"
          className="p-button-text p-0"
          onClick={() => onRevealToggle(!idNumberRevealed)}
        />
      </div>
    )
  }
}

export default RevealableIdNumber
