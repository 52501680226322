import React, { useRef } from 'react'
import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'
import { titleize } from '@services/utils/string'
import MarAdherenceForm from './MarAdherenceForm'

const dialogHeaderTemplate = (consumption) => {
  const title = consumption
    ? `Edit ${titleize(`${consumption.status} Status`)}`
    : 'Administer Dose'

  return (
    <div className="flex flex-column align-content-start gap-2">
      <div className="font-bold text-lg">{title}</div>
      <span className="font-normal line-height-2 text-sm">Please enter the details of the consumption</span>
    </div>
  )
}

function MarDialog({
  visible,
  onHide,
  consumption,
  cellTime,
  marTimeRow,
  timezone,
  mode,
  patientId,
}) {
  const statusMessage = useRef(null)

  if (!cellTime || !marTimeRow) {
    return null
  }

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header={() => dialogHeaderTemplate(consumption)}
      className="w-30rem"
      draggable={false}
    >
      <Toast ref={statusMessage} />
      <div className="flex flex-column w-full gap-4">
        <MarAdherenceForm
          onHide={onHide}
          consumption={consumption}
          cellTime={cellTime}
          marTimeRow={marTimeRow}
          timezone={timezone}
          mode={mode}
          patientId={patientId}
          statusMessage={statusMessage}
        />
      </div>
    </Dialog>
  )
}

export default MarDialog
