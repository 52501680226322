import { FulfillmentStatus, StatusTags } from '@components/inventoryManagement/FullfillmentStatuses'
import moment from 'moment'
import { Tag } from 'primereact/tag'
import React from 'react'

function DoseFillStatusTags({ doseFill }) {
  if (!doseFill) {
    return null
  }
  const tags = []
  const { status, finishedAt, expiresAt } = doseFill

  if (status !== FulfillmentStatus.loadedByAgency) {
    tags.push(StatusTags.pending)
  } else if (finishedAt) {
    tags.push(StatusTags.finished)
  } else {
    tags.push(StatusTags.loadedByAgency)
    const expiresAtMoment = moment(expiresAt)
    const isExpired = expiresAtMoment.isBefore(moment())
    const isExpiringSoon = expiresAtMoment.isBefore(moment().add(1, 'week'))
    if (isExpired) {
      tags.push(StatusTags.expired)
    } else if (isExpiringSoon) {
      tags.push(StatusTags.expiringSoon)
    }
  }

  return <div className="flex flex-row gap-2">{tags.map((tag) => <Tag key={tag.value} {...tag} />)}</div>
}

export default DoseFillStatusTags
