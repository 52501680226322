import React from 'react'
import { find } from 'lodash'
import { classNames } from 'primereact/utils'
import HoveredContainer from '@components/display/HoveredContainer'
import LocationFillsDetails from './LocationFillsDetails'

function SlotSelection({
  drawer, selectedSlot = {}, setSelectedSlotId,
}) {
  const visualSlot = (row, column) => {
    const slot = find(drawer?.drawerSlots, (s) => (s.xPos === column + 1)
      && (s.yPos === 4 - row))
    const isSelected = slot?.id === selectedSlot?.id
    const classes = classNames('border-round p-2 w-4  cursor-pointer', {
      'border-3 border-primary-500 bg-primary-50': isSelected,
    })

    return (
      <HoveredContainer
        key={column}
        id={`slot-${column}-${row}`}
        onClick={() => setSelectedSlotId(slot.id)}
        className={classes}
        hoveredClassName="border-3 border-primary-500 bg-primary-50"
        nonHoveredClassName="border-1 border-200"
      >
        <LocationFillsDetails
          doseFills={slot?.doseFills}
        />
      </HoveredContainer>
    )
  }

  return (
    <div className="flex flex-column gap-2">
      <span className="text-lg font-semibold">Choose your preferred slot</span>
      <div className="mb-2" />
      {
        Array.from({ length: 4 }, (_, row) => (
          <div key={row} className="flex flex-row gap-2" style={{ minHeight: '4rem' }}>
            {Array.from({ length: 3 }, (__, column) => visualSlot(row, column))}
          </div>
        ))
      }
    </div>
  )
}

export default SlotSelection
