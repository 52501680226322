import React, { useRef, useState } from 'react'
import { Timeline } from 'primereact/timeline'
import { InputMask } from 'primereact/inputmask'
import { Button } from 'primereact/button'
import { Messages } from 'primereact/messages'
import { get } from 'lodash'
import moment from 'moment'
import MedicalConditions from '@components/display/MedicalConditions/MedicalConditions'
import { useHrstPatientMatchMutation, useHrstUpdateDiagnosesMutation } from './hrstHooks'
import { dateTimeTemplateFromMoment } from '../../../services/utils'

function ScoreStep({ patient }) {
  const statusMessage = useRef()
  const [ssn, setSsn] = useState(patient.ssn)

  const {
    isLoading,
    mutateAsync: matchPatient,
  } = useHrstPatientMatchMutation(statusMessage, patient.id)

  const handleMatchPatient = async () => {
    await matchPatient({ ssn })
  }

  const hasHrstMatch = !!patient.hrstParticipant

  return (
    <div className="flex flex-column gap-2 w-full mb-4">
      <span className="text-lg font-bold">Match</span>
      <Messages ref={statusMessage} />
      <div className="flex flex-column gap-2 col-12 xl:col-6">
        <label htmlFor="ssn" className="block">SSN</label>
        <InputMask
          autoClear={false}
          id="ssn"
          mask="999-99-9999"
          placeholder="999-99-9999"
          value={ssn}
          disabled={hasHrstMatch || isLoading}
          onChange={({ target: { value } }) => setSsn(value)}
        />
        {
          hasHrstMatch
            ? (
              <Button
                label="Matched"
                className="p-button-sm p-button-success"
                icon="pi pi-check"
                disabled
                loading={isLoading}
              />
            )
            : (
              <Button
                label="Search HRST"
                className="p-button-sm"
                icon="pi pi-search"
                onClick={handleMatchPatient}
                disabled={isLoading}
                loading={isLoading}
              />
            )
        }

      </div>
    </div>
  )
}

function DiagnosesStep({ patient }) {
  const statusMessage = useRef()
  const {
    isLoading,
    mutateAsync: updateHrstDiagnoses,
  } = useHrstUpdateDiagnosesMutation(statusMessage, patient.id)

  const handleUpdateDiagnoses = async () => {
    await updateHrstDiagnoses()
  }

  const hasHrstMatch = !!patient.hrstParticipant
  const diagnosesUpdated = get(patient, 'hrstParticipant.diagnosesUpdatedAt', false)

  return (
    <div className="flex flex-column gap-2 w-full mb-4">
      <span className="text-lg font-bold">Diagnoses</span>
      <Messages ref={statusMessage} />
      {
          hasHrstMatch ? (
            <>
              {
                diagnosesUpdated && (
                  <span className="text-sm">
                    Updated
                    {' '}
                    {dateTimeTemplateFromMoment(moment(diagnosesUpdated))}
                  </span>
                )
              }
              <MedicalConditions patientId={patient.id} />
              <Button
                label="Update Diagnoses in HRST"
                className="p-button-sm w-3"
                icon="pi pi-plus"
                loading={isLoading}
                onClick={() => handleUpdateDiagnoses()}
              />
            </>
          ) : (
            <span>Match HRST to update diagnoses</span>
          )
        }
    </div>
  )
}

function Demographic({ patient }) {
  if (!patient) return null

  const hasHrstMatch = !!patient.hrstParticipant
  const diagnosesSynced = get(patient, 'hrstParticipant.diagnosesUpdatedAt', false)

  const steps = [
    {
      value: 'Match',
      content: <ScoreStep patient={patient} />,
      color: hasHrstMatch ? 'var(--green-500)' : 'transparent',
      icon: 'pi pi-fw pi-user',
      iconColor: hasHrstMatch ? 'white' : 'black',
    },
    {
      value: 'Diagnoses',
      content: <DiagnosesStep patient={patient} />,
      icon: 'pi pi-fw pi-sitemap',
      color: diagnosesSynced ? 'var(--green-500)' : 'transparent',
      iconColor: diagnosesSynced ? 'white' : 'black',
    },
  ]

  const customizedMarker = (item) => (
    <span className="custom-marker shadow-1 p-2 border-round" style={{ backgroundColor: item.color, color: item.iconColor }}>
      <i className={item.icon} />
    </span>
  )

  return (
    <Timeline
      value={steps}
      className="customized-timeline"
      marker={customizedMarker}
      content={(item) => item.content}
    />
  )
}

export default Demographic
