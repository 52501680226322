import { useMemo } from 'react'
import { get, map } from 'lodash'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

export function useIntegrationFacilityQuery(integrationFacilityId) {
  return useQuery({
    queryKey: ['integrationFacility', integrationFacilityId],
    queryFn: () => ApiClient.get(`/integration_facilities/${integrationFacilityId}`),
    enabled: !!integrationFacilityId,
  })
}

export function useUnmappedIntegrationFacilitiesQuery(statusMessage, organizationId) {
  const query = useQuery({
    queryKey: ['unmappedIntegrationFacilities', organizationId],
    queryFn: () => ApiClient.get(`/integration_facilities/unmapped?organization_id=${organizationId}`),
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
    enabled: !!organizationId,
  })

  return useMemo(() => ({
    ...query,
    data: map(get(query, 'data'), (facility) => (facility)),
  }), [query.data])
}

export function useCreateIntegrationFacilityMutation(statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (integrationFacility) => ApiClient.post('/integration_facilities', integrationFacility),
    onSuccess: () => {
      queryClient.invalidateQueries('integrationFacilities')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useUpdateIntegrationFacilityMutation(statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (integrationFacility) => ApiClient.put(`/integration_facilities/${integrationFacility.id}`, integrationFacility),
    onSuccess: () => {
      queryClient.invalidateQueries('integrationFacilities')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useDeleteIntegrationFacilityMutation(statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (integrationFacilityId) => ApiClient.delete(`/integration_facilities/${integrationFacilityId}`),
    onSuccess: () => {
      queryClient.invalidateQueries('integrationFacilities')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}
