import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import {
  displayErrorMessages,
} from '@services/errorHandler'

export function useCreateSiteShift(statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['useCreateSiteShift'],
    mutationFn: (siteShift) => ApiClient.post('/site_shifts', { siteShift }),
    onSuccess: () => {
      statusMessage.current.show([
        {
          severity: 'success',
          summary: 'Shift created!',
        },
      ])
      queryClient.invalidateQueries('site')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useUpdateSiteShift(statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['useUpdateSiteShift'],
    mutationFn: (siteShift) => ApiClient.put(`/site_shifts/${siteShift.id}`, { siteShift }),
    onSuccess: () => {
      statusMessage.current.show([
        {
          severity: 'success',
          summary: 'Shift updated!',
        },
      ])
      queryClient.invalidateQueries('site')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useDeleteSiteShift(statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['useDeleteSiteShift'],
    mutationFn: (siteShift) => ApiClient.delete(`/site_shifts/${siteShift.id}`),
    onSuccess: () => {
      statusMessage.current.show([
        {
          severity: 'success',
          summary: 'Shift deleted!',
        },
      ])
      queryClient.invalidateQueries('site')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}
