import React from 'react'

function AdherenceTableHeader({
  date, formattedDate, approvals, showDateOverlay, hideDateOverlay,
}) {
  const onMouseEnter = (e) => {
    if (approvals && approvals.length > 0) {
      showDateOverlay(date, e)
    }
  }

  const checkTemplate = () => {
    if (!approvals || approvals.length === 0) return
    return (
      <i className="pi pi-check text-color-given" />
    )
  }

  return (
    <div className="select-none" onMouseEnter={onMouseEnter} onMouseLeave={hideDateOverlay}>
      <span className="flex flex-row gap-1">
        {checkTemplate()}
        {formattedDate}
      </span>
    </div>
  )
}

export default AdherenceTableHeader
