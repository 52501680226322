import React, { useRef, useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { Messages } from 'primereact/messages'
import { Link, useNavigate } from 'react-router-dom'
import { useCurrentOrganization } from '@components/App'
import SearchField from '@components/display/Form/SearchField'
import { useSites } from '@hooks/sites'
import { fuzzySearch } from '@services/utils/search'

function Title({ searchTerm, setSearchTerm, isLoading }) {
  return (
    <div className="flex flex-row gap-4 px-4 pb-2 pt-2 align-items-center">
      <span>
        <i className="text-xl pi pi-users mr-2" />
        Sites
      </span>
      <SearchField
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        placeholder="Search sites by name"
        isLoading={isLoading}
      />
    </div>
  )
}

function SitesTable({ useSiteHeader }) {
  const navigate = useNavigate()
  const statusMessage = useRef(null)
  const { setHeader, maxDataViewHeight } = useSiteHeader()

  const [searchLoading, setSearchLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredSites, setFilteredSites] = useState([])
  const organization = useCurrentOrganization()

  const { data: sites, isLoading } = useSites(organization.id, statusMessage)

  useEffect(() => {
    setSearchLoading(true)
    if (searchTerm) {
      setFilteredSites(fuzzySearch(sites, ['name', 'address'], searchTerm))
    } else {
      setFilteredSites(sites)
    }
    setSearchLoading(false)
  }, [sites, searchTerm])

  const title = {
    label: (
      <Title
        organization={organization}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        isLoading={searchLoading}
      />
    ),
  }

  const primaryAction = (
    <Link to="/admin/sites/create">
      <Button className="p-button-sm" label="Create Site" />
    </Link>
  )

  const footTemplate = () => (
    <span className="text-sm ml-1 my-0 py-0">
      {!isLoading ? `Sites Count: ${filteredSites.length}` : '' }
    </span>
  )

  useEffect(() => {
    setHeader({
      title, tabs: [], breadcrumbs: [], primaryAction,
    })
  }, [searchTerm, searchLoading])

  return (
    <div className="col-12">
      <Messages ref={statusMessage} />
      <Card className="h-full" footer={footTemplate}>
        <div className="flex flex-column gap-3 text-base text-900">
          <DataTable
            dataKey="id"
            loading={isLoading}
            value={filteredSites}
            selectionMode="single"
            onSelectionChange={(e) => navigate(`/admin/sites/${e.value.id}`)}
            sortField="lastName"
            sortOrder={1}
            className="mt-1"
            rowClassName="fadein site-row"
            scrollable
            scrollHeight={maxDataViewHeight ? (maxDataViewHeight - 10) : null}
          >
            <Column header="Name" field="name" />
            <Column header="Address" field="address" sortable />
          </DataTable>
        </div>
      </Card>
    </div>
  )
}

export default SitesTable
