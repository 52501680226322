import { useMutation } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

function useOnboardPendingUser(statusMessage) {
  return useMutation({
    mutationKey: ['onboardPendingUser'],
    mutationFn: (pendingUser) => ApiClient.post(`/pending_users/${pendingUser.id}/onboard`, { pendingUser }),
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export default useOnboardPendingUser
