import axios from 'axios'
import ApiClient from '@services/ApiClient'
import { useMutation } from '@tanstack/react-query'

export function base64ToImage(base64Data) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.onerror = reject
    img.src = base64Data
  })
}

async function getCroppedImg(imageSrc, pixelCrop) {
  const image = await base64ToImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    return null
  }

  // Set canvas size to match the image size
  canvas.width = image.width
  canvas.height = image.height

  // Draw the image onto the canvas
  ctx.drawImage(image, 0, 0)

  const croppedCanvas = document.createElement('canvas')
  const croppedCtx = croppedCanvas.getContext('2d')

  if (!croppedCtx) {
    return null
  }

  // Set the size of the cropped canvas
  croppedCanvas.width = pixelCrop.width
  croppedCanvas.height = pixelCrop.height

  // Draw the cropped image onto the new canvas
  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height,
  )

  // As a blob
  return new Promise((resolve) => {
    croppedCanvas.toBlob((file) => {
      resolve((file))
    }, 'image/jpeg')
  })
}

async function updateAvatar({
  patientId, image, crop, queryClient,
}) {
  const { version, url } = await ApiClient.get('/avatars/upload_url', {
    params: { avatarUserId: patientId },
  })

  const croppedImage = await getCroppedImg(image, crop)

  if (!croppedImage) {
    throw new Error('Canvas is empty')
  }

  await axios.create().put(url, croppedImage, {
    headers: {
      'Content-Type': 'image/jpeg',
    },
  })

  await ApiClient.post('/avatars/confirm_upload', {
    avatarUserId: patientId, avatarVersion: version,
  })

  queryClient.invalidateQueries(['patient'])

  return croppedImage
}

const useAvatarMutation = (uploadErrorMessage, setCurrentAvatar) => useMutation({
  mutationFn: updateAvatar,
  onSuccess: (croppedImage) => {
    setCurrentAvatar(URL.createObjectURL(croppedImage))
    uploadErrorMessage.current.show([
      { severity: 'success', summary: 'Upload successful' },
    ])
  },
  onFailure: () => {
    uploadErrorMessage.current.show([
      { severity: 'warn', summary: 'There was a problem saving your image' },
    ])
  },
})

export default useAvatarMutation
