import { useMutation } from '@tanstack/react-query'
import { downloadFileFromResponse } from '@services/utils'
import ApiClient from '@services/ApiClient'

export function useExportUserConsumption(statusMessage) {
  const mutation = useMutation({
    mutationKey: ['exportUserConsumptions'],
    mutationFn: async (params) => {
      const { userId, role, startFrom } = params
      const roleParam = {
        patient: 'patient_id',
        caregiver: 'caregiver_id',
        admin: 'admin_id',
      }[role]
      const response = await ApiClient.get(`/consumptions/export?${roleParam}=${userId}&period=${startFrom}`)
      downloadFileFromResponse(response)
    },
    onError: () => statusMessage.current.show([{
      severity: 'error',
      sticky: true,
      summary: 'Unable to export consumptions at this time.',
    }]),
  })
  return mutation
}

export default {}
