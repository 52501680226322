import React from 'react'
import { RadioButton } from 'primereact/radiobutton'

function RadioButtonWithLabel({
  label, inputId, checked, name, value, onChange,
}) {
  return (
    <div className="field-radiobutton">
      <RadioButton
        inputId={inputId}
        value={value}
        name={name}
        onChange={onChange}
        checked={checked}
      />
      <label htmlFor={inputId}>{label}</label>
    </div>
  )
}

export default RadioButtonWithLabel
