import React, { useState, useEffect, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Button } from 'primereact/button'
import { Skeleton } from 'primereact/skeleton'
import { Toast } from 'primereact/toast'
import { useCurrentOrganization } from '@components/App'
import FormWrapper from '@components/display/Form/FormWrapper'
import { DetailsForm, SiteForm } from '@components/sharedDevices/SharedDeviceForm'
import { useSites } from '@hooks/sites'
import {
  useUpdateSharedDeviceMutation,
  useSharedDeviceQuery,
} from './sharedDeviceHooks'

function FormLoadingPlaceHolder() {
  const fieldPlaceholder = (label) => (
    <div className="field">
      <label className="block" htmlFor="site">{label}</label>
      <Skeleton width="100%" height="2rem" />
    </div>
  )

  return (
    <div id="create-shared-device" className="flex flex-column">
      <div className="flex flex-row flex-wrap">
        <div className="col-6">
          <FormWrapper title="Update Shared Device" fullHeight>
            {fieldPlaceholder('Label')}
            {fieldPlaceholder('Phone')}
            {fieldPlaceholder('Site')}
            <Button label="Loading" disabled />
          </FormWrapper>
        </div>
      </div>
    </div>
  )
}

function UpdateSharedDevice({ useSharedDevicesHeader }) {
  const { sharedDeviceId } = useParams()
  const statusMessage = useRef(null)
  const organization = useCurrentOrganization()
  const navigate = useNavigate()
  const { setHeader } = useSharedDevicesHeader()

  const [details, setDetails] = useState({
    label: '',
    phone: '',
  })

  const [selectedSiteId, setSelectedSiteId] = useState(null)

  const {
    mutateAsync: updateSharedDevice,
    isLoading: updateIsLoading,
  } = useUpdateSharedDeviceMutation(sharedDeviceId, statusMessage)

  const {
    data: sharedDevice,
    isLoading: sharedDeviceIsLoading,
  } = useSharedDeviceQuery(sharedDeviceId)

  const { data: sites, isLoading: isSitesLoading } = useSites(organization.id, statusMessage)

  const handleUpdateSharedDevice = (e) => {
    e.preventDefault()
    updateSharedDevice(
      {
        sharedDevice: {
          ...details,
          siteId: selectedSiteId,
        },
      },
      {
        onSuccess: () => {
          navigate('/admin/shared_devices')
        },
      },
    )
  }

  const handleSiteChange = (site) => {
    setSelectedSiteId(site)
  }

  const pageTitle = {
    label: (
      <span>
        <i className="text-xl pi pi-users mr-2" />
        Shared Devices
      </span>
    ),
    linkTo: '/admin/shared_devices',
  }

  const breadcrumbs = [
    {
      label: 'Update',
      template: <span>Update</span>,
    },
  ]

  useEffect(() => {
    if (sharedDevice) {
      setDetails({
        label: sharedDevice.label,
        phone: sharedDevice.phone,
      })
      setSelectedSiteId(sharedDevice.siteId)
    }

    setHeader({ title: pageTitle, breadcrumbs, primaryAction: null })
  }, [sharedDevice])

  if (sharedDeviceIsLoading) {
    return <FormLoadingPlaceHolder />
  }

  return (
    <div id="create-shared-device" className="flex flex-column">
      <div className="flex flex-row flex-wrap">
        <Toast ref={statusMessage} />
        <div className="col-6">
          <FormWrapper title="Update Shared Device" fullHeight>
            <DetailsForm
              details={details}
              setDetails={(data) => setDetails({ ...details, ...data })}
              isDetailsLoading={sharedDeviceIsLoading}
            />
            <SiteForm
              sites={sites}
              selectedSite={selectedSiteId}
              setSelectedSite={handleSiteChange}
              isSitesLoading={isSitesLoading}
            />
            <Button
              label="Save"
              className="p-button-sm m-2"
              loading={updateIsLoading}
              onClick={handleUpdateSharedDevice}
            />
          </FormWrapper>
        </div>
      </div>
    </div>
  )
}

export default UpdateSharedDevice
