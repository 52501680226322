import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { get } from 'lodash'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { Toast } from 'primereact/toast'
import { Skeleton } from 'primereact/skeleton'
import FormWrapper from '@components/display/Form/FormWrapper'
import InputTextWithLabel from '@components/display/InputTextWithLabel'
import { useOrganizationsQuery } from '@services/hooks/organizationHooks'
import { useIntegrationDataFacilityExternalIdCountQuery } from '@components/integrationData/integrationDataHooks'
import { usePharmaciesQuery } from '../Pharmacies/pharmaciesHooks'
import { useCreateIntegrationFacilityMutation } from '../Facilities/facilitiesHooks'

function MapFacility({ useFacilityHeader }) {
  const statusMessage = useRef(null)
  const params = useParams()
  const { externalId: facilityExternalId } = params
  const pharmacyExternalId = new URLSearchParams(window.location.search).get('pharmacyExternalId')
  const [pharmacy, setPharmacy] = useState()
  const [lockPharmacy, setLockPharmacy] = useState(false)
  const [organization, setOrganization] = useState()

  const { setHeader } = useFacilityHeader()

  const {
    mutateAsync: mapIntegrationFacility,
    isLoading: isCreatingFacility,
  } = useCreateIntegrationFacilityMutation(statusMessage)

  const {
    data: organizations,
    isLoading: isOrganizationsLoading,
  } = useOrganizationsQuery()

  const {
    data: pharmacyOptions,
    isLoading: isPharmacyOptionsLoading,
  } = usePharmaciesQuery()

  const {
    data: integrationDataCount,
    isLoading: isIntegrationDataCountLoading,
  } = useIntegrationDataFacilityExternalIdCountQuery(facilityExternalId, pharmacy?.externalId)

  const handleCreateFacility = () => {
    mapIntegrationFacility({
      externalId: facilityExternalId,
      pharmacyId: get(pharmacy, 'id'),
      organizationId: organization.id,
    }, {
      onSuccess: () => {
        window.location.href = '/admin/facilities'
      },
    })
  }

  const pageTitle = {
    label: (
      <span>
        <i className="text-xl pi pi-users mr-2" />
        Unmapped Facilities
      </span>
    ),
    linkTo: '/admin/integrations',
  }

  const breadcrumbs = [
    {
      label: 'Create',
      template: <span>Create</span>,
    },
  ]

  useEffect(() => {
    setHeader({ title: pageTitle, breadcrumbs, primaryAction: null })
  }, [])

  useEffect(() => {
    if (pharmacyExternalId) {
      const selectedPharmacy = pharmacyOptions
        .find((pharmacyOption) => pharmacyOption.externalId === pharmacyExternalId)
      if (selectedPharmacy) {
        setLockPharmacy(true)
        setPharmacy(selectedPharmacy)
      }
    }
  }, [pharmacyOptions])

  const messageCountTemplate = () => {
    if (isIntegrationDataCountLoading) {
      return <Skeleton width="10rem" height="1.5rem" />
    }

    return (
      <span className="text-sm px-2">
        {`${get(integrationDataCount, 'total', 0)} messages to be processed.`}
      </span>
    )
  }

  return (
    <div id="create-facility" className="flex flex-column">
      <div className="flex flex-row flex-wrap">
        <Toast ref={statusMessage} />
        <div className="col-6">
          <FormWrapper title="Profile" fullHeight>
            <InputTextWithLabel
              label="External ID"
              value={facilityExternalId || ''}
              inputId="externalId"
              name="externalId"
              tooltip="Comma separate external IDs to add multiple facilties in one shot."
              tooltipOptions={{ position: 'right' }}
              disabled
            />
            <div className="field">
              <label className="block" htmlFor="pharmacy">Pharmacy</label>
              <Dropdown
                inputId="pharmacy"
                value={pharmacy}
                options={pharmacyOptions}
                dataKey="id"
                optionLabel="name"
                itemTemplate={(option) => `${option.name} - ${option.externalId}`}
                valueTemplate={(option) => (option ? `${option.name} - ${option.externalId}` : 'Select Pharmacy')}
                onChange={(e) => setPharmacy(e.value)}
                placeholder="Select Pharmacy"
                loading={isPharmacyOptionsLoading || isCreatingFacility}
                disabled={isCreatingFacility || lockPharmacy}
                className="w-full"
                filter
              />
            </div>
            <div className="field">
              <label className="block" htmlFor="organization">Organization</label>
              <Dropdown
                inputId="organization"
                value={organization}
                options={organizations}
                dataKey="id"
                optionLabel="name"
                placeholder="Select Organization"
                onChange={(e) => setOrganization(e.value)}
                loading={isOrganizationsLoading}
                filter
                className="w-full"
              />
            </div>
            <Button
              label="Map"
              className="p-button-sm m-2"
              loading={isCreatingFacility}
              onClick={handleCreateFacility}
              disabled={!facilityExternalId || !pharmacy}
            />
            {messageCountTemplate()}
          </FormWrapper>
        </div>
      </div>
    </div>
  )
}

export default MapFacility
