import React, { useState, useRef } from 'react'
import {
  findIndex, get, startCase,
} from 'lodash'
import { Knob } from 'primereact/knob'
import { Divider } from 'primereact/divider'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Menu } from 'primereact/menu'
import moment from 'moment'
import DoseFillInfo from '@components/ekits/DoseFillAssigment/DoseFillInfo'
import DoseRefill, { FulfillmentStatus } from '../DoseRefill'
import { ArchiveFill } from '../DoseAssignment'
import DoseFillStatusTags from '../../ekits/DoseFillAssigment/DoseFillStatusTags'

function SlotDetails({
  doseFill, setActionDialog, renderFillScroller,
}) {
  const menu = useRef(null)
  const isNewRefill = doseFill.status === FulfillmentStatus.loadedByAgency

  const actions = [
    {
      label: isNewRefill ? 'Refill' : 'Confirm Pending Refill',
      icon: 'pi pi-refresh',
      command: () => setActionDialog('refill'),
    },
    {
      label: 'Archive',
      icon: 'pi pi-exclamation-triangle',
      command: () => setActionDialog('archive'),
    },
  ]

  return (
    <div className="flex flex-column flex-grow-1 gap-1">
      <div className="flex flex-row gap-2 justify-space-between">
        <div className="flex flex-row gap-2 align-items-center">
          <Knob
            size={40}
            min={0}
            max={doseFill.dosesInFill}
            value={doseFill.fillsLeft}
            valueColor="#caf1d8"
            readOnly
          />
          <div className="flex flex-column">
            <span className="text-sm font-bold">
              {doseFill.medicine.name}
            </span>
            <span className="text-sm">
              {doseFill.medicine.strength}
            </span>
          </div>
        </div>
        <Menu model={actions} popup ref={menu} id="popup_menu" />
        <Button className="p-button-text p-button-sm p-0" icon="pi pi-bars" onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup />
      </div>
      <Divider className="my-2" />
      <DoseFillStatusTags doseFill={doseFill} />
      <DoseFillInfo doseFill={doseFill} />
      {renderFillScroller()}
    </div>
  )
}

function DoseFill({
  slot, ekitId, drawerId, doseFill, renderFillScroller, setCurrentIndex,
}) {
  const [showDialog, setShowDialog] = useState(false)

  const handleArchive = () => {
    setCurrentIndex(0)
    setShowDialog(false)
  }

  return (
    <>
      <Dialog header={`${startCase(showDialog)}`} visible={!!showDialog} onHide={() => setShowDialog(false)}>
        { showDialog === 'refill'
          && (
          <DoseRefill
            newFulfillmentStatus={FulfillmentStatus.loadedByAgency}
            slot={slot}
            drawerId={drawerId}
            doseFill={doseFill}
            onSuccess={() => setShowDialog(false)}
          />
          )}
        { showDialog === 'archive'
          && (
          <ArchiveFill
            ekitId={ekitId}
            doseFill={doseFill}
            onSuccess={handleArchive}
          />
          )}
      </Dialog>
      <div className="flex flex-grow-1 p-3 text-left shadow-1">
        <SlotDetails
          doseFill={doseFill}
          renderFillScroller={renderFillScroller}
          setActionDialog={setShowDialog}
        />
      </div>
    </>
  )
}

function SlotCardWithAssignment({ slot, ekitId, drawerId }) {
  const doseFills = get(slot, 'doseFills', []).map((doseFill) => (
    {
      drawerId,
      slot,
      doseFill,
    }
  ))
  doseFills.sort((a, b) => {
    const aAgencyLoadedAt = moment(a.doseFill.agencyLoadedAt)
    const bAgencyLoadedAt = moment(b.doseFill.agencyLoadedAt)
    return aAgencyLoadedAt.isBefore(bAgencyLoadedAt) ? -1 : 1
  })

  // the initial index is the first dose fill that is not loaded by the agency or has expired
  // if we don't find any, we default to the first loaded fill
  const initialIndex = findIndex(
    doseFills,
    (fill) => fill.doseFill.status !== FulfillmentStatus.loadedByAgency
     || moment(fill.doseFill.expiresAt).isBefore(moment()),
  )

  const mostRecentFinishedDoseFill = get(slot, 'mostRecentFinishedDoseFill', null)
  if (mostRecentFinishedDoseFill) {
    doseFills.unshift({
      drawerId,
      slot,
      doseFill: mostRecentFinishedDoseFill,
    })
  }

  const [currentIndex, setCurrentIndex] = useState(initialIndex >= 0 ? initialIndex : 0)

  const handleNext = () => {
    setCurrentIndex(() => currentIndex + 1)
  }

  const handleBack = () => {
    setCurrentIndex(() => currentIndex - 1)
  }

  const renderFillScroller = () => {
    if (doseFills.length === 1) {
      return null
    }
    return (
      <div className="flex flex-row justify-space-between mt-4">
        <Button
          icon="pi pi-angle-left"
          className="p-button-text p-0 w-1rem"
          aria-label="Back"
          disabled={currentIndex === 0}
          onClick={handleBack}
        />
        <span className="text-sm font-normal">
          (
          {currentIndex + 1}
          {' '}
          /
          {' '}
          {doseFills.length}
          )
        </span>
        <Button
          icon="pi pi-angle-right"
          className="p-button-text p-0 w-1rem"
          aria-label="Next"
          disabled={currentIndex === doseFills.length - 1}
          onClick={handleNext}
        />
      </div>
    )
  }

  return (
    <div className="col-4 flex flex-column">
      <DoseFill
        ekitId={ekitId}
        renderFillScroller={renderFillScroller}
        setCurrentIndex={setCurrentIndex}
        {...doseFills[currentIndex]}
      />
    </div>
  )
}

export default SlotCardWithAssignment
