import React, { Fragment } from 'react'
import { Divider } from 'primereact/divider'
import StatusOverlayContent from '../StatusOverlayContent'

function PRNStatusOverlay({ timezone, consumptions, handleEdit }) {
  if (!consumptions) return null
  return (
    <div className="w-22rem p-1 pb-0">
      {consumptions.map((consumption, index) => (
        <Fragment key={`${consumption.status}-${consumption.confirmedAt}`}>
          <StatusOverlayContent
            timezone={timezone}
            consumption={consumption}
            handleEdit={handleEdit}
          />
          {index < consumptions.length - 1 && <Divider className="my-4" />}
        </Fragment>
      ))}
    </div>
  )
}

export default React.memo(PRNStatusOverlay)
