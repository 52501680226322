import React from 'react'
import SitesSelect from '../../../features/Selects/SitesSelect/SitesSelect'

function SiteForm({
  sites, selectedSite, setSelectedSite, isSitesLoading,
}) {
  return (
    <div className="field">
      <label className="block" htmlFor="site">Site</label>
      <div className="flex flex-column">
        <SitesSelect
          id="site"
          data-testid="site-select"
          sites={sites}
          loading={isSitesLoading}
          onChange={(newSite) => setSelectedSite(newSite[0])}
          singleSelection
          selectedSites={[selectedSite]}
          className="w-full"
        />
      </div>
    </div>
  )
}

export default SiteForm
