import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { RRule } from 'rrule'
import {
  get, result, map, orderBy, isEmpty, first, last, forEach,
} from 'lodash'
import FullCalendar from '@fullcalendar/react'
import rrulePlugin from '@fullcalendar/rrule'
import momentPlugin from '@fullcalendar/moment'
import timeGridPlugin from '@fullcalendar/timegrid'
import listGridPlugin from '@fullcalendar/list'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import './calendar.scss'

export const formatSchedulesForDisplay = ({ schedules, dose, includeDuration = true } = {}) => (
  map(schedules, (schedule) => {
    const flexibilityStart = schedule.flexibility.recommendedStart
    const flexibilityEnd = schedule.flexibility.recommendedEnd
    const eventStartTime = moment(schedule.time).subtract(flexibilityStart, 'minutes')
    const eventEndTime = moment(schedule.time).add(flexibilityEnd, 'minutes')

    // Ruby IceCube outputs timezones such as "EST" but not IANA timezones like America/New_York.
    // Since FullCalendar requires IANA but the backend cannot serve it, because we rely on IceCube,
    // we have to convert to UTC below.
    const rruleOptions = RRule.parseString(schedule.rruleForDose)
    // The consumption is expected at rruleOptions.dtstart, but we want to show the event
    // starting at the beginning of the flexibility window.
    rruleOptions.dtstart = moment.utc(rruleOptions.dtstart).subtract(flexibilityStart, 'minutes').format()
    if (schedule.doseSig.endAt) {
      rruleOptions.until = moment.utc(rruleOptions.until).format()
    }
    rruleOptions.tzid = 'UTC'
    const event = {
      rrule: rruleOptions,
      allDay: false,
      id: schedule.id,
      extendedProps: {
        dose,
        doseSig: schedule.doseSig,
        expectedAt: schedule.time,
        start: eventStartTime,
        end: eventEndTime,
      },
      display: 'block',
    }

    if (includeDuration) {
      const duration = { minutes: eventEndTime.diff(eventStartTime, 'minutes') }
      event.duration = duration
    } else {
      event.duration = { minutes: 0 }
    }

    return event
  }))

const viewSpecificOptions = {
  views: {
    listWeek: {
      duration: { days: 7 },
      eventColor: '#3b82f6',
      eventTextColor: '#495057',
    },
    dayGridWeek: {
      eventColor: '#3b82f6',
      eventTextColor: '#ffffff',
    },
    timeGridWeek: {
      duration: { days: 7 },
      eventColor: '#3b82f6',
      eventTextColor: '#ffffff',
    },
    timeGridDay: {
      duration: { days: 1 },
      eventMaxStack: 4,
      eventColor: '#3b82f6',
      eventTextColor: '#ffffff',
      dayHeaderFormat: 'dddd MMMM D, YYYY',
    },
  },
}

function Calendar({
  events,
  initialView,
  height = 'auto',
  renderControls,
  eventContent,
  handleEventSelection,
  showCalendar = true,
}) {
  const calendarRef = useRef(null)
  const [calendarData, setCalendarData] = useState()

  const handleEventMouseEnter = ({ event }) => {
    event.setProp('color', '#609af8')
    event.setProp('textColor', '#ffffff')
  }

  const handleEventMouseLeave = ({ event, view }) => {
    const viewSpecificOption = viewSpecificOptions.views[view.type]
    event.setProp('color', viewSpecificOption.eventColor)
    event.setProp('textColor', viewSpecificOption.eventTextColor)
  }

  const getFullCalendarApi = () => result(calendarRef, 'current.getApi', {})

  const refreshCalendarData = () => {
    const fullCalendarApi = getFullCalendarApi()

    if (!isEmpty(fullCalendarApi)) {
      const newView = get(fullCalendarApi, 'currentData.viewApi.type')
      const viewSpecificOption = viewSpecificOptions.views[newView]
      forEach(
        fullCalendarApi.getEvents(),
        (event) => {
          if (event.textColor && event.textColor !== viewSpecificOption.eventTextColor) {
            event.setProp('color', viewSpecificOption.eventColor)
            event.setProp('textColor', viewSpecificOption.eventTextColor)
          }
        },
      )
      fullCalendarApi.setOption('eventColor', viewSpecificOption.eventColor)
      fullCalendarApi.setOption('eventTextColor', viewSpecificOption.eventTextColor)
      setCalendarData(get(fullCalendarApi, 'currentData'))
    }
  }

  const calendar = {
    data: calendarData,
    handleNext: () => getFullCalendarApi().next(),
    handlePrevious: () => getFullCalendarApi().prev(),
    handleViewChange: (v) => getFullCalendarApi().changeView(v),
  }

  const moreLinkContent = ({ num }) => (
    <div className="flex flex-column gap-1 px-1">
      <span>{`${num} more`}</span>
      <span>events</span>
    </div>
  )

  const sortedEvents = orderBy(events, 'extendedProps.start', ['asc'])
  const eventColor = viewSpecificOptions.views[get(calendar, 'data.currentViewType')]?.eventColor
  const eventTextColor = viewSpecificOptions.views[get(calendar, 'data.currentViewType')]?.eventTextColor
  const slotMinTime = moment(first(sortedEvents)?.extendedProps.start).format('HH:mm:ss')
  const slotMaxTime = moment(last(sortedEvents)?.extendedProps.end).format('HH:mm:ss')

  useEffect(() => { if (showCalendar) refreshCalendarData() }, [calendarRef, showCalendar])

  return (
    <div className="flex flex-column gap-3 impruvon-calendar">
      {renderControls(calendar)}
      {
        showCalendar && (
          <FullCalendar
            {...viewSpecificOptions}
            ref={calendarRef}
            plugins={[
              rrulePlugin,
              momentPlugin,
              dayGridPlugin,
              timeGridPlugin,
              listGridPlugin,
              interactionPlugin,
            ]}
            initialView={initialView}
            events={sortedEvents}
            eventContent={eventContent}
            eventColor={eventColor}
            eventTextColor={eventTextColor}
            datesSet={refreshCalendarData}
            eventClick={handleEventSelection}
            eventMouseEnter={handleEventMouseEnter}
            eventMouseLeave={handleEventMouseLeave}
            listDayFormat="dddd MMMM D, YYYY"
            listDaySideFormat={false}
            eventBorderColor="#3b82f6"
            eventClassNames="word-break"
            headerToolbar={false}
            eventMaxStack={1}
            moreLinkContent={moreLinkContent}
            slotMinTime={slotMinTime}
            slotMaxTime={slotMaxTime}
            height={height}
            allDaySlot={false}
            // Default timezone in utc because the events are stored in utc
            // see formatSchedulesForDisplay above
            timeZone="UTC"
            eventOrder="expectedAt"
          />
        )
      }
    </div>
  )
}

export default Calendar
