import React from 'react'
import {
  Routes, Route, Navigate, useLocation,
} from 'react-router-dom'
import PageLayout, { usePageOutlet } from '@components/display/PageLayout'
import { withApp, useCurrentUser, useCurrentOrganization } from '@components/App'
import pluralize from 'pluralize'
import {
  E_KITS_PATH,
  E_KIT_TYPE,
  MED_BOXES_PATH,
  MED_BOX_TYPE,
  SUPER_ADMIN_ROLE,
  LABELS,
} from './config'
import CreateMedBox from './CreateMedBox'
import MedBox from './MedBox'
import MedBoxesTable from './MedBoxesTable'
import EditMedBox from './EditMedBox'
import EkitsConfiguration from '../Ekits/EkitConfiguration'

function MedBoxes() {
  const useMedBoxesHeader = () => usePageOutlet()
  const user = useCurrentUser()
  const organization = useCurrentOrganization()
  const isSuperAdmin = user.role === SUPER_ADMIN_ROLE
  const isPharmacist = user.role === 'pharmacist'
  const showEkits = isPharmacist || organization?.ekitsEnabled
  const { pathname } = useLocation()

  const routePath = showEkits && pathname.includes(E_KITS_PATH) ? E_KITS_PATH : MED_BOXES_PATH
  const medBoxDeviceType = showEkits && pathname.includes(E_KITS_PATH)
    ? E_KIT_TYPE
    : MED_BOX_TYPE
  const label = LABELS[medBoxDeviceType]

  return (
    <div className="med_boxes">
      <Routes>
        <Route
          path={`/${routePath}/*`}
          element={
            <PageLayout rootPath={`/${routePath}`} rootLabel={pluralize(label)} />
          }
        >
          <Route
            index
            element={(
              <MedBoxesTable
                useMedBoxesHeader={useMedBoxesHeader}
                type={medBoxDeviceType}
              />
            )}
          />
          <Route
            path="new"
            element={
              isSuperAdmin ? (
                <CreateMedBox
                  useMedBoxesHeader={useMedBoxesHeader}
                  type={medBoxDeviceType}
                  isSuperAdmin={isSuperAdmin}
                />
              ) : (
                <Navigate to={`/${routePath}`} replace />
              )
            }
          />
          <Route
            path=":medBoxId/edit"
            element={(
              <EditMedBox
                useMedBoxesHeader={useMedBoxesHeader}
                type={medBoxDeviceType}
                isSuperAdmin={isSuperAdmin}
              />
            )}
          />
          <Route
            path=":medBoxId/ekit_configure"
            element={
              medBoxDeviceType === E_KIT_TYPE
                ? (
                  <EkitsConfiguration
                    useMedBoxesHeader={useMedBoxesHeader}
                    type={medBoxDeviceType}
                  />
                )
                : <Navigate to={`/${routePath}`} replace />
}
          />
          <Route path=":medBoxId/*" element={<MedBox useMedBoxesHeader={useMedBoxesHeader} type={medBoxDeviceType} />} />
        </Route>
      </Routes>
    </div>
  )
}

export default withApp(MedBoxes)
