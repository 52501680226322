import React from 'react'
import { Button } from 'primereact/button'
import { InputTextarea } from 'primereact/inputtextarea'

function Notes({ notes, setNotes, handleConfirm }) {
  return (
    <div className="grid flex flex-grow-1 justify-content-center align-items-center fadein">
      <div className="col-9 flex flex-column gap-2 text-center">
        <label className="text-lg font-normal mb-1" htmlFor="dose-notes">
          Are there any notes to be displayed during administration?
        </label>
        <InputTextarea
          id="dose-notes"
          rows={5}
          cols={30}
          data-testid="notes"
          autoResize
          value={notes}
          onChange={(event) => setNotes(event.target.value)}
        />
        <Button
          onClick={handleConfirm}
          label="Next"
          className="p-button-text mt-2"
        />
      </div>
    </div>
  )
}

export default Notes
