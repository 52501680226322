export const getTaskActionFriendlyName = (taskAction) => {
  switch (taskAction) {
    case 'binary':
      return 'Simple'
    case 'text':
      return 'Require Text'
    case 'vital':
      return 'Require Vital'
    default:
      return ''
  }
}

export default {}
