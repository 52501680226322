import React, { useState, useEffect } from 'react'
import { InputNumber } from 'primereact/inputnumber'
import { Button } from 'primereact/button'
import '../../style.scss'

function FillsLeft({ value = {}, handleFillChange, handleFillRemove }) {
  const [isEditing, setIsEditing] = useState(false)
  const [fillsLeft, setFillsLeft] = useState()

  const handleEdit = () => {
    setIsEditing(true)
  }

  const handleSave = () => {
    setIsEditing(false)
    handleFillChange({ ...value, fillsLeft })
  }

  const handleCancel = () => {
    setIsEditing(false)
  }

  useEffect(() => {
    if (value.fillsLeft !== fillsLeft) {
      setFillsLeft(value.fillsLeft)
    }
  }, [value])

  if (isEditing) {
    return (
      <div className="flex justify-content-start align-items-center gap-2 h-2rem">
        <InputNumber
          className="h-2rem"
          value={fillsLeft}
          onValueChange={(e) => setFillsLeft(e.value)}
        />
        <Button
          onClick={handleSave}
          className="p-button-sm p-button-text p-button-success"
          icon="pi pi-check"
        />
        <Button
          onClick={handleCancel}
          className="p-button-sm p-button-text p-button-secondary"
          icon="pi pi-times"
        />
      </div>
    )
  }

  return (
    <div className="flex justify-content-start align-items-center gap-2 h-2rem">
      <span>{fillsLeft}</span>
      <Button
        onClick={handleEdit}
        className="p-button-sm p-button-text p-button-secondary"
        icon="pi pi-pencil"
      />
      <Button
        onClick={() => handleFillRemove(value)}
        className="p-button-sm p-button-text p-button-danger"
        icon="pi pi-times"
      />
    </div>
  )
}

export default FillsLeft
