import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import {
  displayErrorMessages,
} from '@services/errorHandler'
import { useMemo } from 'react'
import { map, get } from 'lodash'

// TODO: Move to @hooks/sitesHooks
export function useOrgSitesQuery(orgId, statusMessage) {
  const query = useQuery({
    queryKey: ['orgSites', orgId],
    queryFn: () => ApiClient.get(`/organizations/${orgId}/sites`),
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })

  return useMemo(() => ({
    ...query,
    data: map(get(query, 'data.sites'), (site) => (site)),
  }))
}

// TODO: Move to @hooks/sitesHooks
export function useMySitesQuery(organizationId, statusMessage) {
  const query = useQuery({
    queryKey: ['myAccessibleSites', organizationId],
    queryFn: () => ApiClient.get(`/sites?organization_id=${organizationId}`),
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })

  return useMemo(() => ({
    ...query,
    data: map(get(query, 'data.sites'), (site) => (site)),
  }))
}

// TODO: Move to @hooks/sitesHooks
export function useAllSitesQuery({ statusMessage, enabled = true } = {}) {
  const query = useQuery({
    queryKey: ['allSites'],
    queryFn: () => ApiClient.get('/sites/all'),
    onError: (error) => {
      if (!statusMessage) return
      displayErrorMessages(error, statusMessage)
    },
    enabled,
  })

  return useMemo(() => ({
    ...query,
    data: map(get(query, 'data.sites'), (site) => (site)),
  }))
}
