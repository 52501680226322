import React from 'react'
import { Tag } from 'primereact/tag'
import { Tooltip } from 'primereact/tooltip'

const toRomanNumeral = (scheduleNumber) => {
  let romanNumeral = null
  switch (scheduleNumber) {
    case 1:
      romanNumeral = 'I'
      break
    case 2:
      romanNumeral = 'II'
      break
    case 3:
      romanNumeral = 'III'
      break
    case 4:
      romanNumeral = 'IV'
      break
    case 5:
      romanNumeral = 'V'
      break
    default:
      break
  }
  return romanNumeral
}

export const csaScheduleText = (scheduleNumber) => {
  const romanNumeral = toRomanNumeral(scheduleNumber)
  return `Schedule ${romanNumeral}`
}

export const csaScheduleLabel = (scheduleNumber) => (
  <span>
    <Tooltip target=".csa-schedule-tag" />
    <Tag
      className="csa-schedule-tag"
      data-pr-tooltip="US Controlled Substances Act"
      data-pr-position="left"
      data-pr-at="left+5 center"
      data-pr-my="right center-2"
      rounded
      severity="warning"
      style={{ fontSize: '0.6rem', minWidth: '90px' }}
      value={csaScheduleText(scheduleNumber)}
    />
  </span>
)
