import React, { useState } from 'react'

/**
 * A component that displays an clickable overlay on top of a components.
 *
 * @component
 * @param {object} props
 * @param {string} props.icon - The icon to display.
 * @param {string} props.text - The text to display.
 * @param {string} props.textClassName - The class name for the text.
 * @param {JSX.Element} props.children - The children to display.
 * @param {Function} props.onClick - The function to call on click.
 * @param {boolean} props.overlayEnabled - Whether the overlay is enabled.
 * @param {number} props.opacity - The opacity of the overlay.
 * @param {string} props.overlayClassName - The class name for the overlay.
 * @param {string} props.color - The color of the overlay.
 * @param {string} props.className - The class name for the component.
 * @return {JSX.Element}
 */
function OverlayAction({
  icon,
  text,
  textClassName,
  children,
  onClick,
  overlayEnabled = true,
  opacity = 0.7,
  overlayClassName,
  color = 'rgb(244, 250, 254)',
  className,
}) {
  const [overlayClassNames, setOverlayClassNames] = useState('hidden')

  const onMouseEnter = () => {
    if (!overlayEnabled) return
    return setOverlayClassNames(`absolute top-0 left-0 flex justify-content-center align-items-center text-center ${overlayClassName}`)
  }
  const onMouseLeave = () => {
    if (!overlayEnabled) return
    return setOverlayClassNames('hidden')
  }

  return (
    <div className={`relative top-0 left-0 cursor-pointer ${className}`} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {children}
      <div
        className={overlayClassNames}
        style={{
          backgroundColor: color,
          width: '100%',
          height: '100%',
          opacity,
        }}
        onClick={onClick && overlayEnabled ? () => {
          onMouseLeave()
          onClick()
        } : null}
      >
        <div>
          {
            icon && <i className={`pi text-lg text-color ${icon}`} />
          }
          <p className={`${textClassName} m-0 text-color`}>{text}</p>
        </div>
      </div>
    </div>
  )
}

export default OverlayAction
