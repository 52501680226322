import React from 'react'
import { Link } from 'react-router-dom'
import { DataView } from 'primereact/dataview'
import { Divider } from 'primereact/divider'
import { useReviewableTreatmentOrders } from '@hooks/treatments'
import { getTreatmentRoute } from '@utils/routes'
import TreatmentDetails from './TreatmentDetails'

function ReviewableTreatmentOrder(patientId, reviewableOrder) {
  const { compositeOrder, directives, treatment } = reviewableOrder

  const treatmentRoute = getTreatmentRoute(patientId, treatment.id, 'review')

  return (
    <Link to={treatmentRoute} className="w-full cursor-pointer hover:bg-blue-50">
      <TreatmentDetails
        treatment={{ ...treatment, ...compositeOrder }}
        directives={directives}
      />
      <Divider className="my-0" />
    </Link>
  )
}

export function ReviewableTreatmentsList({ patientId }) {
  const { data: reviewableOrders, isLoading } = useReviewableTreatmentOrders(patientId)

  return (
    <div className="flex flex-column">
      <DataView
        value={reviewableOrders}
        itemTemplate={(reviewableOrder) => ReviewableTreatmentOrder(patientId, reviewableOrder)}
        loading={isLoading}
      />
    </div>
  )
}

export default ReviewableTreatmentsList
