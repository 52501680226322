import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

const useAcknowledgeTreatment = ({ statusMessage }) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params) => ApiClient.post(`/treatments/${params.id}/acknowledge`, { treatment: params }),
    onSuccess: async () => {
      await queryClient.invalidateQueries('treatment')
      await queryClient.invalidateQueries('treatments')
      statusMessage.current?.clear()
      statusMessage.current?.show([
        { severity: 'success', summary: 'Treatment Acknowledged!' },
      ])
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export default useAcknowledgeTreatment
