import React from 'react'
import { Button } from 'primereact/button'
import { Tag } from 'primereact/tag'

function RevealablePin({
  pin, pinRevealed, onRevealToggle, isTemporaryPin,
}) {
  if (!pin) {
    return <i>None</i>
  }

  if (pinRevealed) {
    return (
      <div className="revealable-pin">
        <span>{pin}</span>
        <Button
          icon="pi pi-eye-slash"
          className="p-0 p-button-text"
          onClick={() => onRevealToggle(!pinRevealed)}
        />
        {isTemporaryPin
          && <Tag className="temporary-pin-indicator">Temporary</Tag>}
      </div>
    )
  }

  if (!pinRevealed) {
    return (
      <div className="revealable-pin">
        <span>{pin[0]}</span>
        <span>***</span>
        <Button
          icon="pi pi-eye"
          className="p-button-text p-0"
          onClick={() => onRevealToggle(!pinRevealed)}
        />
        {isTemporaryPin
          && <Tag className="temporary-pin-indicator">Temporary</Tag>}
      </div>
    )
  }
}

export default RevealablePin
