import React, { useEffect, useRef, useState } from 'react'
import SignaturePad from 'signature_pad'
import { Button } from 'primereact/button'
import SignatureView from '@components/display/SignatureView'
import Box from '../../display/Box'
import pencil from './pencil.svg'
import './ElectronicSignature.scss'

function ElectronicSignature({
  signature, setSignature, isViewingCurrentUser, isEditingSignature,
}) {
  const signatureRef = useRef(null)
  const [signaturePad, setSignaturePad] = useState(null)

  const handleCanvasSizing = (canvas, pad) => {
    const ratio = Math.max(window.devicePixelRatio || 1, 1)
    canvas.width = canvas.offsetWidth * ratio // eslint-disable-line
    canvas.height = canvas.offsetHeight * ratio // eslint-disable-line
    canvas.getContext('2d').scale(ratio, ratio)
    pad.clear()
  }

  useEffect(() => {
    if (signatureRef.current) {
      const canvas = signatureRef.current
      const pad = new SignaturePad(canvas, { penColor: 'black', backgroundColor: '#F5F5F5' })
      setSignaturePad(signaturePad)
      pad.addEventListener('endStroke', () => setSignature(pad.toSVG()))
      handleCanvasSizing(canvas, pad)
    }
  }, [signatureRef, isEditingSignature])
  return (
    <Box direction="column" gap={3} alignContent="center" className="e-signature">
      { isViewingCurrentUser && isEditingSignature
        && (
        <>
          <span className="text-xs">Use the space below to draw a signature</span>
          <div style={{
            margin: '0 auto', width: '100%', position: 'relative', aspectRatio: '16/9', cursor: `url('${pencil}'), pointer`,
          }}
          >
            <div style={{
              position: 'absolute', top: '0', right: '0', zIndex: '1', padding: '0.5rem',
            }}
            >
              <Button
                icon="pi pi-eraser"
                className="p-button-rounded p-button-text p-button-icon-only"
                onClick={() => {
                  signaturePad.clear()
                  setSignature(null)
                }}
              />
            </div>
            <canvas
              ref={signatureRef}
              className="border-round"
              style={{ height: '100%', width: '100%', position: 'absolute' }}
            />
          </div>
        </>
        )}
      { signature && (!isEditingSignature || !isViewingCurrentUser)
        && (
          <SignatureView signature={signature} />
        )}
    </Box>
  )
}

export default ElectronicSignature
