import React, {
  createContext, useContext, useMemo, useState,
} from 'react'

const AuthContext = createContext()

export function LoginProvider({ children }) {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [eulaAccepted, setEulaAccepted] = useState(false)
  const [loginType, setLoginType] = useState('Email')

  const value = useMemo(() => ({
    username,
    setUsername,
    password,
    setPassword,
    email,
    setEmail,
    eulaAccepted,
    setEulaAccepted,
    loginType,
    setLoginType,
  }), [
    username,
    password,
    email,
    eulaAccepted,
    loginType,
  ])

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  return useContext(AuthContext)
}
