import React from 'react'
import { medicineDisplayNameWithStrength } from '@components/clientDoses/doseUtils'

function DoseDetails({ marTimeRow }) {
  if (!marTimeRow) {
    return null
  }
  return (
    <div className="flex flex-column gap-2 align-items-start text-left">
      <span style={{ wordBreak: 'break-all' }}>
        {medicineDisplayNameWithStrength(marTimeRow.doseSig.dose)}
      </span>
      {
        marTimeRow.medicalConditions.length > 0 && (
          marTimeRow.medicalConditions.map((condition) => (
            <span key={condition.id} style={{ fontSize: '0.60rem' }}>
              {condition.description}
            </span>
          ))
        )
      }
    </div>
  )
}

export default DoseDetails
