import React from 'react'
import { DoseFillsEditor } from '@components/pharmacyOrders/Editors'
import { Section } from './SharedComponents'
import {
  useReviewableOrderHandlers,
  useReviewableOrderState,
} from '../../../features/ClientDoses/PharmacyOrders/reviewableOrderState/ReviewableOrderContext'

function DoseFillsSection() {
  const { doseFills } = useReviewableOrderState()

  const {
    handleUpdateFillsLeft,
    handleRemoveDoseFill,
  } = useReviewableOrderHandlers()

  if (!doseFills || doseFills.length === 0) {
    return null
  }

  const helpText = `Review and edit pharmacy-dispensed dose fills.
    It is recommended to match any physical inventory being onboarded.`

  return (
    <Section header="Dose Fills" helpText={helpText}>
      <DoseFillsEditor
        handleUpdateFillsLeft={handleUpdateFillsLeft}
        handleRemoveDoseFill={handleRemoveDoseFill}
        doseFills={doseFills}
      />
    </Section>
  )
}

export default DoseFillsSection
