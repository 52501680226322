import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

const useUpdateTask = ({ statusMessage }) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (task) => {
      const params = {
        id: task.id,
        taskType: task.taskType,
        taskAction: task.taskAction,
        title: task.title,
        description: task.description,
        startAt: task.startAt,
        endAt: task.endAt,
        showOnTar: task.showOnTar,
      }

      return ApiClient.patch(`/tasks/${task.id}`, params)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries('task')
      await queryClient.invalidateQueries('tasks')
      statusMessage.current.clear()
      statusMessage.current.show([
        { severity: 'success', summary: 'Task Updated!' },
      ])
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export default useUpdateTask
