import React from 'react'
import { momentFormats, momentTzWihtoutChangingTime } from '@services/utils/moment'
import { useCurrentOrganization } from '@components/App'
import moment from 'moment'
import { useDoseHoldCreate } from '@hooks/doseHolds'
import DoseHoldForm from '@components/DoseHold/DoseHoldForm'

function DoseHoldSection({
  doseId,
  consumptionDate,
  onCancel,
  statusMessage,
}) {
  const { timezone } = useCurrentOrganization()
  const {
    mutateAsync: createDoseHold,
    isLoading: isCreatingDoseHold,
  } = useDoseHoldCreate(doseId, statusMessage)

  if (!doseId) return null

  const handleCreateHold = async ({ startAt, endAt, note }) => {
    const payload = {
      doseId,
      startAt: startAt.utc().format(momentFormats.iso8601_utc),
      endAt: endAt.utc().format(momentFormats.iso8601_utc),
      note,
    }
    await createDoseHold(payload, {
      onSuccess: () => {
        onCancel()
      },
    })
  }

  return (
    <div className="flex flex-column gap-2">
      <span className="text-xs">
        To change the status of the seleted item to on hold
        you need to create a hold period.
        This will affect all med administrations in that time period, not only the selected one.
      </span>
      <DoseHoldForm
        onCancel={onCancel}
        onConfirm={handleCreateHold}
        isLoading={isCreatingDoseHold}
        mode="add"
        defaultStartAt={momentTzWihtoutChangingTime(moment(consumptionDate), timezone).startOf('day')}
        defaultEndAt={momentTzWihtoutChangingTime(moment(consumptionDate), timezone).endOf('day')}
        showCancelButton={false}
      />
    </div>
  )
}

export default DoseHoldSection
