import React, { useEffect, useRef } from 'react'
import { Button } from 'primereact/button'
import { useCurrentOrganization, useCurrentUser } from '@components/App'
import Block from '@components/display/Block'
import SuperAdminCard from '@components/display/SuperAdminCard'
import DirectorySyncHeaderTitle from '@components/sso/DirectorySyncHeaderTitle'
import DirectoryUsersTable from '@components/sso/DirectoryUsersTable'
import WorkosMessagesTable from '@components/sso/WorkosMessagesTable'
import { useRefreshFromWorkos } from '@hooks/sso'

function DirectorySyncDashboard({ useDirectorySyncHeader }) {
  const statusMessage = useRef(null)
  const organization = useCurrentOrganization()
  const user = useCurrentUser()
  const { setHeader } = useDirectorySyncHeader()

  const {
    mutate: refreshFromWorkos,
  } = useRefreshFromWorkos({
    model: 'Organization',
    id: organization.id,
    statusMessage,
  })

  const primaryAction = (
    <Button
      className="p-button-sm"
      icon="pi pi-sync"
      label="Refresh"
      onClick={refreshFromWorkos}
    />
  )

  useEffect(() => {
    setHeader({
      primaryAction,
      title: {
        label: (<DirectorySyncHeaderTitle />),
      },
    })
  }, [])

  const canViewDirectorySync = user.role === 'superadmin' && organization.ssoEnabled

  if (!canViewDirectorySync) {
    return (
      <div className="col-12">
        <div className="p-4 text-center">
          <h1 className="text-2xl">Directory Sync is not enabled</h1>
          <p className="text-lg">Please enable Directory Sync to view data</p>
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-row flex-wrap">
      <Block col={12}>
        <SuperAdminCard title="Directory Users">
          <DirectoryUsersTable
            id={organization.id}
            model="Organization"
          />
        </SuperAdminCard>
      </Block>
      <Block col={12}>
        <SuperAdminCard title="WorkOS Messages">
          <WorkosMessagesTable
            id={organization.id}
            model="Organization"
          />
        </SuperAdminCard>
      </Block>
    </div>
  )
}

export default DirectorySyncDashboard
