import React, { useState, useEffect, useRef } from 'react'
import { first } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { ProfileForm, SiteForm } from '@components/patients/PatientForm'
import FormWrapper from '@components/display/Form/FormWrapper'
import { useCurrentOrganization } from '@components/App'
import { usePageOutlet as usePageLayoutContext } from '@components/display/PageLayout'
import { useSitesQuery } from '../Selects/SitesSelect/SitesSelectHooks'
import { useUpsertPatient } from './patientHooks'

function CreatePatient() {
  const statusMessage = useRef(null)
  const { setHeader, rootPath } = usePageLayoutContext()
  const navigate = useNavigate()
  const organization = useCurrentOrganization()

  const [assignedSiteIds, setAssignedSiteIds] = useState([])
  const [profile, setProfile] = useState({
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    customerId: '',
    roomNumber: '',
    dateOfBirth: '',
    gender: '',
    birthSex: '',
    ssn: '',
    medicaidIdNumber: '',
    mrn: '',
    note: '',
  })

  const {
    data: { sites } = [],
    isLoading: isSitesLoading,
  } = useSitesQuery({ statusMessage, organizationId: organization.id })

  const {
    mutateAsync: upsertPatient,
    isLoading: updateIsLoading,
  } = useUpsertPatient(null, statusMessage)

  const handleUpdate = async () => {
    const patient = await upsertPatient({
      ...profile,
      organizationId: organization.id,
      siteId: first(assignedSiteIds),
      patientSettingsAttributes: {
        selfAdmin: false,
        medicaidIdNumber: profile.medicaidIdNumber,
        mrn: profile.mrn,
        note: profile.note,
      },
    })

    if (patient) {
      navigate(`../${patient.id}`)
    }
  }

  const title = {
    label: (
      <span>
        <i className="text-xl pi pi-users mr-2" />
        {organization.patientLabelPlural}
      </span>
    ),
    linkTo: rootPath,
  }

  const breadcrumbs = [
    {
      label: 'Create',
      template: <span>Create</span>,
    },
  ]

  useEffect(() => (
    setHeader({
      title, breadcrumbs, primaryAction: null,
    })
  ), [])

  return (
    <div className="flex flex-row flex-wrap">
      <Toast ref={statusMessage} />
      <div className="col-8 xl:col-6">
        <FormWrapper title={`Create ${organization.patientLabelSingular}`} fullHeight>
          <ProfileForm
            profile={profile}
            setProfile={(value) => setProfile((p) => ({ ...p, ...value }))}
          />
          <div className="field">
            <label className="block">Site</label>
            <SiteForm
              sites={sites}
              selectedSites={assignedSiteIds}
              setSelectedSites={(siteIds) => setAssignedSiteIds(siteIds)}
              isSitesLoading={isSitesLoading}
            />
          </div>
          <Button
            label={`Create ${organization.patientLabelSingular}`}
            className="p-button-sm mt-4"
            loading={updateIsLoading}
            onClick={handleUpdate}
          />
        </FormWrapper>
      </div>
    </div>
  )
}

export default CreatePatient
