import React, { useState, useRef } from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'
import InputTextWithLabel from '@components/display/InputTextWithLabel'
import { useCreatePatientInsurance } from './patientInsurancesHooks'

function CreatePatientInsuranceDialog({
  patient,
  visible,
  onHide,
}) {
  const statusMessage = useRef(null)
  const [insuranceGroupNumber, setInsuranceGroupNumber] = useState()
  const [insuranceName, setInsuranceName] = useState()
  const [insurancePolicyNumber, setInsurancePolicyNumber] = useState()

  const {
    mutateAsync: createInsurance,
    isLoading: isCreatingInsurance,
  } = useCreatePatientInsurance(statusMessage)

  const onCreateInsurance = () => {
    const insurance = {
      patientId: patient.id,
      groupNumber: insuranceGroupNumber,
      name: insuranceName,
      policyNumber: insurancePolicyNumber,
    }
    createInsurance(
      insurance,
      {
        onSuccess: () => {
          onHide()
        },
      },
    )
  }

  const dialogHeaderTemplate = () => (
    <div className="flex flex-row align-items-baseline w-full justify-content-center">
      <h3 className="font-semibold text-lg flex flex-column gap-2">
        Add Insurance
      </h3>
    </div>
  )

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header={dialogHeaderTemplate}
    >
      <Toast ref={statusMessage} />
      <div className="flex flex-column gap-2 align-items-start px-2">
        <InputTextWithLabel
          label="Name"
          inputId="insurance_name"
          value={insuranceName}
          name="insurance_name"
          onChange={(e) => setInsuranceName(e.target.value)}
        />
        <InputTextWithLabel
          label="Group number"
          inputId="insurance_group_number"
          value={insuranceGroupNumber}
          name="insurance_group_number"
          onChange={(e) => setInsuranceGroupNumber(e.target.value)}
        />
        <InputTextWithLabel
          label="Policy number"
          inputId="insurance_policy_number"
          value={insurancePolicyNumber}
          name="insurance_policy_number"
          onChange={(e) => setInsurancePolicyNumber(e.target.value)}
        />
        <Button
          label="Create"
          className="flex w-full p-button-sm"
          loading={isCreatingInsurance}
          onClick={onCreateInsurance}
          disabled={!insuranceName || !insuranceGroupNumber || !insurancePolicyNumber}
        />
      </div>
    </Dialog>
  )
}

export default CreatePatientInsuranceDialog
