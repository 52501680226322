import React from 'react'
import InputTextWithLabel from '@components/display/InputTextWithLabel'

function EmailField({ email, setEmail, isLoading }) {
  return (
    <div className="field w-full rounded">
      <InputTextWithLabel
        label="Email"
        name="email"
        inputId="auth-email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        disabled={isLoading}
      />
    </div>
  )
}

export default EmailField
