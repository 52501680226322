import React, { useState, useEffect, useRef } from 'react'
import {
  get, compact, pick, isEqual, keys,
} from 'lodash'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { Menu } from 'primereact/menu'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import ElectronicSignature from '@components/user/ElectronicSignature'
import { ProfileForm, SharedDeviceForm } from '@components/caregivers/CaregiverForm'
import FormWrapper from '@components/display/Form/FormWrapper'
import { useCurrentUser, useCurrentOrganization } from '@components/App'
import {
  useSignature,
  useCreateSignature,
} from '@components/user/ElectronicSignature/signatureHooks'
import {
  useUpdateCaregiverMutation,
  useUserTitleOptions,
  useSetUserTitleMutation,
  useCaregiverDeactivateMutation,
  useCaregiverSetPinMutation,
} from './caregiversHooks'

function buildMenuItems({
  caregiver, currentOrganization, currentUserRole, handleDeactivate,
}) {
  const isSuperAdmin = currentUserRole === 'superadmin'
  const isAdmin = currentUserRole === 'site_admin'
  const items = []

  if (!caregiver) {
    return items
  }

  if (!caregiver.isDirectoryManaged && (isSuperAdmin || isAdmin)) {
    items.push({
      label: 'Deactivate',
      icon: 'pi pi-times',
      command: () => confirmDialog({
        message: `Are you sure you want to deactivate this ${currentOrganization.caregiverLabelSingular}?`,
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => handleDeactivate(),
      }),
    })
  }

  return items
}

function UpdateCaregiver({ caregiver, handleSetHeader }) {
  const caregiverActionMenu = useRef(null)
  const statusMessage = useRef(null)
  const { caregiverId } = useParams()
  const currentUser = useCurrentUser()
  const isSameUser = caregiverId.toString() === currentUser.id.toString()
  const organization = useCurrentOrganization()
  const navigate = useNavigate()

  const [profile, setProfile] = useState({
    id: '',
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    customerId: '',
    isDirectoryManaged: false,
  })
  const [title, setTitle] = useState('')
  const [sharedDevice, setSharedDevice] = useState({ pin: '', pinExpiresAt: '' })
  const [signature, setSignature] = useState()
  const [isEditingSignature, setIsEditingSignature] = useState(false)

  const isCurrentUserAdmin = ['superadmin', 'site_admin'].includes(currentUser.role)
  const canViewSharedDevice = organization.caregiversCanShareDevice
    && organization.pinSwitchingEnabled

  const {
    mutateAsync: updateCaregiver,
    isLoading: updateIsLoading,
  } = useUpdateCaregiverMutation(caregiverId, statusMessage)

  const {
    mutateAsync: setPin,
    isLoading: setPinIsLoading,
  } = useCaregiverSetPinMutation(caregiverId, statusMessage)

  const {
    data: titleOptions,
    isLoading: isTitleOptionsLoading,
  } = useUserTitleOptions()

  const {
    mutateAsync: setUserTitle,
  } = useSetUserTitleMutation(caregiverId, statusMessage)

  const {
    data: userSignature,
  } = useSignature({ userId: caregiverId, enabled: isSameUser, statusMessage })

  const {
    mutateAsync: createSignature,
    isLoading: createSignatureIsLoading,
  } = useCreateSignature(caregiverId, statusMessage)

  const {
    mutateAsync: deactivateCaregiver,
  } = useCaregiverDeactivateMutation(statusMessage, organization.caregiverLabelSingular)

  const handleUpdateProfile = () => {
    updateCaregiver({ ...profile }, {
      onSuccess: () => {
        statusMessage.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Profile updated',
        })
      },
    })
  }

  const handleSetPin = () => {
    setPin({ ...sharedDevice }, {
      onSuccess: () => {
        statusMessage.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Pin updated',
        })
      },
    })
  }

  const handleSetUserTitle = (value) => {
    setUserTitle(value)
  }

  const handleCreateSignature = async () => {
    createSignature(signature, {
      onSuccess: () => {
        setIsEditingSignature(false)
        statusMessage.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Signature updated',
        })
      },
    })
  }

  const handleDeactivate = async () => {
    await deactivateCaregiver(caregiverId)
    navigate('/admin/caregivers')
  }

  const breadcrumbs = isCurrentUserAdmin ? compact([
    {
      label: organization.caregiverLabelPlural,
      template: <Link to={`/admin/caregivers/${caregiverId}`}>{organization.caregiverLabelSingular}</Link>,
    },
    {
      label: 'Edit',
      template: <span>Edit</span>,
    },
  ]) : []

  const menuItems = buildMenuItems({
    caregiver,
    currentUserRole: get(currentUser, 'role'),
    handleDeactivate,
    currentOrganization: organization,
  })

  const primaryAction = menuItems.length !== 0 ? (
    <>
      <Menu
        model={menuItems}
        popup
        ref={caregiverActionMenu}
        id="caregiverActionMenu"
      />
      <Button
        label="Actions"
        className="p-button-sm"
        icon="pi pi-chevron-down"
        onClick={(event) => caregiverActionMenu.current.toggle(event)}
      />
    </>
  ) : null

  const signatureTitle = () => {
    if (isEditingSignature) {
      return 'Electronic Signature'
    }
    return (
      <div className="flex flex-row justify-content-between align-items-baseline w-full">
        <span>Electronic Signature</span>
        <Button
          label="Edit Signature"
          className="p-button-sm p-button-text py-1"
          onClick={() => setIsEditingSignature(true)}
        />
      </div>
    )
  }

  useEffect(() => {
    if (caregiver) {
      setProfile({
        id: caregiver.id,
        email: caregiver.email,
        initials: caregiver.initials,
        phone: caregiver.phone,
        firstName: caregiver.firstName,
        lastName: caregiver.lastName,
        customerId: caregiver.customerId,
        isDirectoryManaged: caregiver.isDirectoryManaged,
      })
      setTitle(caregiver.title)
      setSharedDevice({
        pin: caregiver.pin,
        pinExpiresAt: caregiver.pinExpiresAt,
      })
    }

    handleSetHeader({ breadcrumbs, primaryAction })
  }, [caregiver])

  useEffect(() => {
    if (caregiver && titleOptions) {
      setTitle(titleOptions.find((t) => t.abbreviation === get(caregiver, 'title.title')))
    }
  }, [caregiver, titleOptions])

  useEffect(() => {
    if (userSignature) {
      setSignature(userSignature.svg)
      setIsEditingSignature(false)
    } else {
      setIsEditingSignature(true)
    }
  }, [userSignature])

  return (
    <div className="flex flex-row flex-wrap">
      <Toast ref={statusMessage} />
      <ConfirmDialog />
      <div className="xl:col-6 col-12">
        <FormWrapper title="Profile" fullHeight={false}>
          <ProfileForm
            profile={profile}
            setProfile={(value) => setProfile((p) => ({ ...p, ...value }))}
          />
          <Button
            label="Save Profile"
            className="p-button-sm mt-4"
            disabled={isEqual(profile, pick(caregiver, keys(profile)))}
            loading={updateIsLoading}
            onClick={handleUpdateProfile}
          />
        </FormWrapper>
      </div>
      <div className="xl:col-6 col-12 flex flex-column gap-3">
        <FormWrapper title="Title">
          <Dropdown
            value={title}
            options={titleOptions}
            dataKey="abbreviation"
            optionLabel="title"
            onChange={(e) => handleSetUserTitle(e.value.abbreviation)}
            placeholder="Select a title"
            loading={isTitleOptionsLoading || updateIsLoading}
            disabled={updateIsLoading}
            className="w-full"
            filter
          />
        </FormWrapper>
        {
          canViewSharedDevice && (
            <FormWrapper title="Shared Device">
              <SharedDeviceForm
                sharedDevice={sharedDevice}
                setSharedDevice={(value) => setSharedDevice((p) => ({ ...p, ...value }))}
                loading={updateIsLoading}
              />
              <Button
                label="Save Pin"
                className="p-button-sm mt-4"
                disabled={
                  isEqual(sharedDevice, pick(caregiver, keys(sharedDevice)))
                  || sharedDevice.pin.length !== 4
                }
                loading={setPinIsLoading}
                onClick={handleSetPin}
              />
            </FormWrapper>
          )
        }
        {
          isSameUser && (
            <FormWrapper title={signatureTitle()}>
              <ElectronicSignature
                isEditingSignature={isEditingSignature}
                isViewingCurrentUser={isSameUser}
                signature={signature}
                setSignature={setSignature}
              />
              {
                isEditingSignature && (
                  <Button
                    label="Save Signature"
                    className="p-button-sm mt-4"
                    loading={createSignatureIsLoading}
                    onClick={handleCreateSignature}
                  />
                )
              }
            </FormWrapper>
          )
        }
      </div>
    </div>
  )
}

export default UpdateCaregiver
