import Fuse from 'fuse.js'

export const exactSearch = (list, searchKeys, searchValue) => {
  if (!searchValue) {
    return list
  }
  const searchValueLower = searchValue.toLowerCase()
  return list.filter((item) => searchKeys.some((key) => item[key]
    .toLowerCase().includes(searchValueLower)))
}

export const fuzzySearch = (list, searchKeys, searchValue, threshold = 0.4) => {
  if (!searchValue) {
    return list
  }
  const options = {
    keys: searchKeys,
    threshold: 0.3,
    includeScore: true,
  }
  const fuse = new Fuse(list, options)
  const search = fuse.search(searchValue)
  const filtered = search.filter(({ score }) => score <= threshold)
  return filtered.map(({ item }) => item)
}
