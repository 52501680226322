import React from 'react'
import { Route, Routes, useParams } from 'react-router-dom'
import Redirect from '@components/routing/Redirect'
import ManagePrescriptionPage from './ManagePrescriptionPage'
import PrescriptionsOverviewPage from './PrescriptionsOverviewPage'

function Prescriptions({
  patient, handleSetHeader,
}) {
  const { patientId: clientId } = useParams()

  return (
    <Routes>
      <Route index element={<Redirect to="all" />} />
      <Route
        path="manage/:prescriptionId"
        element={(
          <ManagePrescriptionPage
            patient={patient}
            handleSetHeader={handleSetHeader}
          />
        )}
      />
      <Route
        path="/*"
        element={(
          <PrescriptionsOverviewPage clientId={clientId} />
        )}
      />
    </Routes>
  )
}

export default Prescriptions
