import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Skeleton } from 'primereact/skeleton'
import AttributeCard, { Attribute } from '@components/display/AttributeCard'
import Box from '@components/display/Box'
import Block from '@components/display/Block'
import FacilityIntegrationData from './FacilityIntegrationData'

function Profile({
  facility, rootPath, isLoading,
}) {
  const title = {
    label: 'Profile',
    icon: 'pi-user',
  }
  const navigate = useNavigate()
  const {
    externalId,
    pharmacy,
    mappedIntegrationPatientCount,
    unmappedIntegrationPatientCount,
    failedMessagesCount,
    succeededMessagesCount,
  } = facility || {}

  const valueTemplate = (value) => (
    isLoading ? <Skeleton className="inline-flex" width="5rem" height="1rem" /> : value
  )

  const editClick = () => {
    navigate(`${rootPath}/${facility.id}/update`)
  }

  const action = { label: 'Edit', command: editClick }

  return (
    <div id="patient-dashboard-profile" className="h-full">
      <AttributeCard title={title} action={action} className="h-full">
        <Attribute label="External ID" value={valueTemplate(externalId)} />
        <Attribute label="Pharmacy" value={valueTemplate(`${pharmacy?.name} (${pharmacy?.externalId})`)} />
        <Attribute label="Patient Stats" value={valueTemplate(`${mappedIntegrationPatientCount} of ${unmappedIntegrationPatientCount} onboarded`)} />
        <Attribute label="Integration" value={valueTemplate(`${succeededMessagesCount} successful, ${failedMessagesCount} failed`)} />
      </AttributeCard>
    </div>
  )
}

function FacilityDashboard({
  facility,
  facilityLoading,
  handleSetHeader,
  rootPath,
}) {
  useEffect(() => {
    handleSetHeader()
  }, [facility])

  return (
    <Box>
      <Block col={12}>
        <Profile
          facility={facility}
          rootPath={rootPath}
          isLoading={facilityLoading}
        />
      </Block>
      <Block col={12}>
        <FacilityIntegrationData
          facility={facility}
        />
      </Block>
    </Box>
  )
}

export default FacilityDashboard
