import { get } from 'lodash'
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
import { camelizeKeys, decamelizeKeys } from 'humps'

const ApiClient = axios.create({ baseURL: '/' })

// Axios middleware to convert all api responses to camelCase
ApiClient.interceptors.response.use(
  (response = AxiosResponse) => {
    let formatted = response
    if (
      response.data
      && response.headers['content-type'].includes('application/json')
    ) {
      formatted = { ...camelizeKeys(response.data) }
    }

    return formatted
  },
  (error) => {
    if (get(error, 'response.status') === 401) {
      window.location.replace('/admin/login')
    }
    throw error
  },
)

// Axios middleware to convert all api requests to snake_case
ApiClient.interceptors.request.use((config = AxiosRequestConfig) => {
  const newConfig = { ...config }
  const csrfParam = document.querySelector('meta[name="csrf-token"]')
  const csrfToken = csrfParam ? csrfParam.content : null
  newConfig.headers = { 'X-CSRF-Token': csrfToken, ...newConfig.headers }

  if (newConfig.headers['Content-Type'] === 'multipart/form-data') {
    return newConfig
  }

  if (config.params) {
    newConfig.params = decamelizeKeys(config.params)
  }

  if (config.data) {
    newConfig.data = decamelizeKeys(config.data)
  }

  return newConfig
})

export default ApiClient
