import React from 'react'
import { Routes, Route } from 'react-router-dom'
import PageLayout, { usePageOutlet } from '@components/display/PageLayout'
import { withApp } from '@components/App'
import FacilitiesTable from './FacilitiesTable'
import CreateFacility from './CreateFacility'
import Facility from './Facility'

function Facilities() {
  const useFacilityHeader = () => usePageOutlet()

  return (
    <div className="facilities">
      <Routes>
        <Route path="/admin/facilities/*" element={<PageLayout rootPath="/admin/facilities" rootLabel="Facilities" />}>
          <Route
            index
            element={(
              <FacilitiesTable
                useFacilityHeader={useFacilityHeader}
              />
            )}
          />
          <Route path="create" element={<CreateFacility useFacilityHeader={useFacilityHeader} />} />
          <Route path=":facility/*" element={<Facility useFacilityHeader={useFacilityHeader} />} />
        </Route>
      </Routes>
    </div>
  )
}

export default withApp(Facilities)
