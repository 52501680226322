import React from 'react'

function Title() {
  return (
    <div className="flex flex-row gap-4 px-4 pb-2 pt-2 align-items-center">
      <span>
        <i className="text-xl pi pi-mobile mr-2" />
        Shared Devices
      </span>
    </div>
  )
}

export default Title
