import React, { useState } from 'react'
import { Card } from 'primereact/card'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { get } from 'lodash'
import EditPatientAllergyDialog from './EditAllergyDialog'
import CreatePatientAllergyDialog from './CreateAllergyDialog'
import { ALLERGEN_TYPE_LABELS } from '../../display/Allergies/config'

function PatientAllergies({
  patient,
  isLoading,
  className = '',
}) {
  const [selectedAllergy, setSelectedAllergy] = useState(false)
  const [showCreateAllergyDialog, setShowCreateAllergyDialog] = useState(false)

  return (
    <div className={className}>
      {
        selectedAllergy && (
          <EditPatientAllergyDialog
            visible
            allergy={selectedAllergy}
            patient={patient}
            onHide={() => setSelectedAllergy(null)}
          />
        )
      }
      {
        showCreateAllergyDialog
        && (
        <CreatePatientAllergyDialog
          visible
          patient={patient}
          onHide={() => setShowCreateAllergyDialog(false)}
        />
        )
      }
      <Card className="h-full">
        <div className="flex flex-column gap-3 text-base text-lg">
          <div className="flex flex-row justify-content-between align-items-center">
            <div>
              Allergies
            </div>
            <Button
              label="Add Allergy"
              icon="fa-solid fa-ruler"
              className="p-button-sm p-button-info"
              onClick={() => setShowCreateAllergyDialog(true)}
            />
          </div>
        </div>
        <DataTable
          dataKey="id"
          value={get(patient, 'allergies', [])}
          loading={isLoading}
          className="mt-2"
          rowClassName="fadein"
          selectionMode="single"
          onSelectionChange={(e) => setSelectedAllergy(e.value)}
        >
          <Column
            key="allergen_type"
            field="allergenType"
            header="Allergen Type"
            body={(rowData) => ALLERGEN_TYPE_LABELS[rowData.allergenType]}
          />
          <Column
            key="text"
            field="text"
            header="Description"
          />
        </DataTable>
      </Card>
    </div>
  )
}

export default PatientAllergies
