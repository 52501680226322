import React from 'react'

function RequestStatus({ buttonState }) {
  if (buttonState === 'error') {
    return (
      <div style={{ color: 'red' }}>
        Error
        <i className="pi pi-times" />
      </div>
    )
  } if (buttonState === 'success') {
    return (
      <div style={{ color: 'green' }}>
        Success
        <i className="pi pi-check" />
      </div>
    )
  }
  return null
}

export default RequestStatus
