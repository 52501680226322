import React from 'react'
import { Dialog } from 'primereact/dialog'
import PrepareDoseRefill from '../PrepareDoseRefill'

function Header({ dose }) {
  return (
    <div className="flex flex-column gap-1 text-base font-normal">
      <span className="text-lg font-bold">{dose.site?.name}</span>
      <span className="text-sm">
        {dose.medicineName}
        {' '}
        {dose.medicineStrength}
        {' '}
      </span>
    </div>
  )
}

function CreateRefillDialog({
  dose, isVisible, setIsVisible,
}) {
  const header = () => (
    <Header dose={dose} />
  )

  return (
    <Dialog
      header={() => header()}
      visible={isVisible}
      onHide={() => setIsVisible(false)}
      style={{ width: '40vw' }}
    >
      <PrepareDoseRefill dose={dose} onSuccess={() => setIsVisible(false)} />
    </Dialog>
  )
}

export default CreateRefillDialog
