import React, { useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { Menu } from 'primereact/menu'
import PRNDialog from '@components/clientDoses/Schedule/PRNDialog'

function TaskActionMenu({ task, handleSetPrn, isLoading }) {
  const actionMenu = useRef(null)
  const [prnDialogVisible, setPrnDialogVisible] = useState(false)

  if (!task) {
    return null
  }

  const prnControls = ({ handleShowDialog }) => (
    <Button
      label={task.taskType === 'prn' ? 'Change to schedule' : 'Change to PRN'}
      icon="fa-solid fa-triangle-exclamation"
      className="p-button-sm p-button-warning p-button-text w-full"
      onClick={handleShowDialog}
    />
  )

  const taskActions = () => {
    const actions = []

    actions.push({
      template: prnControls({ handleShowDialog: () => setPrnDialogVisible(true) }),
    })

    return actions
  }

  return (
    <div className="ml-auto">
      {
        handleSetPrn && (
          <PRNDialog
            isVisible={prnDialogVisible}
            setIsVisible={setPrnDialogVisible}
            handlePrnChange={handleSetPrn}
            isLoading={isLoading}
            schedulableType="Task"
            toPrnValue={task.taskType !== 'prn'}
          />
        )
      }
      <Menu model={taskActions()} popup ref={actionMenu} id="popup_menu" />
      <Button
        label="Actions"
        icon="pi pi-chevron-down"
        className="p-button-sm"
        iconPos="right"
        aria-controls="popup_menu"
        aria-haspopup
        onClick={(event) => actionMenu.current.toggle(event)}
      />
    </div>
  )
}

export default TaskActionMenu
