import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { get } from 'lodash'
import { useMemo } from 'react'

const useDoseHolds = (doseId) => {
  const query = useQuery({
    queryKey: ['doseHolds', doseId],
    queryFn: () => ApiClient.get('/dose_holds', { params: { doseId } }),
    enabled: !!doseId,
  })

  return useMemo(() => ({
    ...query,
    data: get(query, 'data.doseHolds'),
  }), [query.data])
}

export default useDoseHolds
