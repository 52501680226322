import React from 'react'
import { Tag } from 'primereact/tag'
import 'primeflex/primeflex.css'
import './styles.scss'

const baseStatusStyleWithContent = {
  confirmed: { style: 'text-xl font-semibold line-height-3 cursor-pointer text-color-given bg-transparent' },
  completed: { style: 'text-xl font-semibold line-height-3 cursor-pointer text-color-given bg-transparent' },
  refused: { content: 'R', style: 'w-2rem text-xl px-3 py-0 font-medium bg-white line-height-3 text-color-refused border-color-refused cursor-pointer override-p-tag' },
  missed: { content: 'X', style: 'bg-white cursor-pointer missed-tag' },
  LOA: { content: 'LOA', style: 'font-medium px-3 py-0 bg-white line-height-3 bg-white cursor-pointer text-color-loa border-color-loa override-p-tag' },
  on_hold: { content: 'H', style: 'font-medium px-3 py-0 bg-white line-height-3 bg-white cursor-pointer text-color-on-hold border-color-on-hold override-p-tag' },
}

function StatusTag({
  status, initials, className = '', onClick,
}) {
  const statusStyleWithContent = baseStatusStyleWithContent[status]

  if (!statusStyleWithContent) {
    return null
  }
  const { content, style } = statusStyleWithContent
  const mergedClassNames = `${style} flex align-items-center justify-content-center ${className} w-2rem`

  const valueHandler = () => {
    if (status !== 'confirmed' && status !== 'completed') {
      return <span>{content}</span>
    }

    // TODO: Remove this when we add not null constraint to initials
    if (!initials) {
      return ''
    }

    if (initials.length <= 2) {
      return <span>{initials}</span>
    }

    return (
      <>
        <span>{initials.slice(0, 2)}</span>
        <span style={{ fontSize: '0.5rem' }}>{initials.slice(2)}</span>
      </>
    )
  }

  return (
    <Tag
      className={mergedClassNames}
      onClick={onClick}
      value={valueHandler()}
      rounded
    />
  )
}

export default StatusTag
