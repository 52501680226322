import React, { useRef, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Toast } from 'primereact/toast'
import PharmacyOrdersCard from '@components/pharmacyOrders/Card/PharmacyOrdersCard'
import Redirect from '@components/routing/Redirect'
import TreatmentTable from '@components/treatments/TreatmentTable'
import { ActionSection } from '@components/treatments/Sections'
import { useReviewableTreatmentOrders } from '@hooks/treatments'
import { getTreatmentRoute } from '@utils/routes'

function ReviewTreatmentPage({ handleSetHeader, patient }) {
  const statusMessage = useRef(null)
  const [loaded, setLoaded] = useState(false)
  const [reviewableOrder, setReviewableOrder] = useState(null)
  const [treatment, setTreatment] = useState(null)
  const { patientId: clientId, treatmentId } = useParams()

  const { data: reviewableOrders, isLoading } = useReviewableTreatmentOrders(clientId)

  useEffect(() => handleSetHeader({ primaryAction: null }), [patient])

  // TODO: Pretty gross, should refactor as part of the Needs Review refactor
  useEffect(() => {
    if (reviewableOrders) {
      const order = reviewableOrders.find((ro) => ro.treatment.id === Number(treatmentId))
      setReviewableOrder(order)
      setTreatment(order?.treatment)
      setLoaded(true)
    }
  }, [reviewableOrders, treatmentId])

  if (!loaded || isLoading || !reviewableOrders || !patient) {
    return 'Loading...'
  }

  // If the treatment doesn't have any orders for review, redirect to the treatment's manage page
  if (loaded && (!treatment || !reviewableOrder?.sourceOrders?.length)) {
    const treatmentRoute = getTreatmentRoute(clientId, treatmentId, 'manage')
    return <Redirect to={treatmentRoute} />
  }

  return (
    <>
      <Toast ref={statusMessage} />
      <TreatmentTable
        treatment={treatment}
        setTreatment={setTreatment}
        timezone={patient.timezone}
        canEdit
        compositePharmacyOrder={reviewableOrder.compositeOrder}
      />
      <ActionSection
        patient={patient}
        treatment={treatment}
        directives={reviewableOrder.directives}
      />
      <PharmacyOrdersCard model="Treatment" id={treatment.id} />
    </>
  )
}

export default ReviewTreatmentPage
