import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import DirectorySyncHeaderTitle from '@components/sso/DirectorySyncHeaderTitle'
import Block from '@components/display/Block'
import SuperAdminCard from '@components/display/SuperAdminCard'
import DirectorySitesTable from '@components/sso/DirectorySitesTable'
import DirectoryUserCard from '@components/sso/DirectoryUserCard'
import WorkosMessagesTable from '@components/sso/WorkosMessagesTable'
import { useDirectoryUser } from '@hooks/sso'

function DirectoryUser({ useDirectorySyncHeader }) {
  const { directoryUserId } = useParams()
  const { setHeader } = useDirectorySyncHeader()

  const { data: directoryUser, isLoading } = useDirectoryUser({ id: directoryUserId })

  useEffect(() => {
    setHeader({
      title: {
        label: (<DirectorySyncHeaderTitle directoryUser={directoryUser} />),
      },
    })
  }, [directoryUser])

  return (
    <div className="flex flex-row flex-wrap">
      <Block col={6}>
        <DirectoryUserCard
          directoryUser={directoryUser}
          isLoading={isLoading}
        />
      </Block>
      <Block col={6}>
        <SuperAdminCard title="Directory-Managed Sites">
          <DirectorySitesTable
            sites={directoryUser?.sites}
            isLoading={isLoading}
          />
        </SuperAdminCard>
      </Block>
      <Block col={12}>
        <SuperAdminCard title="WorkOS Messages">
          <WorkosMessagesTable
            id={directoryUserId}
            model="DirectoryUser"
          />
        </SuperAdminCard>
      </Block>
    </div>
  )
}

export default DirectoryUser
