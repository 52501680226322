import React from 'react'
import UsernameFields from './UsernameFields'
import EmailField from './EmailField'
import { useAuth } from '../../../context/AuthContext'

function LoginFields({ isLoading }) {
  const {
    loginType, username, setUsername, password, setPassword, email, setEmail,
  } = useAuth()

  return (
    <>
      {loginType === 'Email' && (
      <div className="flex flex-row align-items-center">
        <EmailField email={email} setEmail={setEmail} isLoading={isLoading} />
      </div>
      )}
      {loginType === 'Username' && (
        <UsernameFields
          username={username}
          setUsername={setUsername}
          password={password}
          setPassword={setPassword}
          isLoading={isLoading}
        />
      )}
    </>
  )
}

export default LoginFields
