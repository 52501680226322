import { useMemo } from 'react'
import { get, map } from 'lodash'
import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

export function useIntegrationFacilities(statusMessage, params) {
  const { organizationId, pharmacyId } = params
  const url = organizationId
    ? `/integration_facilities?organization_id=${organizationId}`
    : `/integration_facilities?pharmacy_id=${pharmacyId}`

  const query = useQuery({
    queryKey: ['integrationFacilities', url],
    queryFn: () => ApiClient.get(url),
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
    enabled: !!organizationId || !!pharmacyId,
  })

  return useMemo(() => ({
    ...query,
    data: map(get(query, 'data'), (facility) => (facility)),
  }), [query.data])
}

export default useIntegrationFacilities
