import React from 'react'
import { SelectButton } from 'primereact/selectbutton'

function DosePrnReasonRequiredInput({
  value,
  onChange,
  disabled,
}) {
  return (
    <SelectButton
      name="PRN"
      disabled={disabled}
      options={[{ label: 'YES', value: true }, { label: 'NO', value: false }]}
      value={value}
      onChange={(e) => onChange(e.value)}
    />
  )
}

export default DosePrnReasonRequiredInput
