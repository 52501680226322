import React, { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Messages } from 'primereact/messages'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { useCurrentOrganization } from '@components/App'
import UserDirectoryServiceIcon from '@components/integrations/UserDirectoryServiceIcon'
import { formatDateTimeLocale } from '@services/utils'
import { exactSearch } from '@services/utils/search'
import { useDeactivatedAdmins, useReactivateAdmin } from './adminsHooks'

function DeactivatedAdmins({
  searchTerm, setSearchLoading, maxDataViewHeight, footer,
}) {
  const navigate = useNavigate()
  const statusMessage = useRef(null)
  const organization = useCurrentOrganization()

  const [filteredAdmins, setFilteredAdmins] = useState([])
  const [reactivatedAdminId, setReactivatedAdminId] = useState()

  const { data: admins, isLoading } = useDeactivatedAdmins(organization.id, statusMessage)
  const {
    mutateAsync: reactivateAdmin,
    isLoading: isReactivatedAdminLoading,
  } = useReactivateAdmin(statusMessage, organization.adminLabelSingular)

  useEffect(() => {
    setSearchLoading(true)
    if (searchTerm) {
      setFilteredAdmins(exactSearch(admins, ['firstName', 'lastName'], searchTerm))
    } else {
      setFilteredAdmins(admins)
    }
    setSearchLoading(false)
  }, [admins, searchTerm])

  const handleReactivate = async (adminId) => {
    setReactivatedAdminId(adminId)
    await reactivateAdmin(adminId)
    setReactivatedAdminId(null)
  }

  const reactivateButton = (rowData) => (
    <Button
      className="p-button-sm"
      label="Reactivate"
      onClick={() => handleReactivate(rowData.id)}
      loading={reactivatedAdminId === rowData.id && isReactivatedAdminLoading}
      disabled={rowData.isDirectoryManaged}
    />
  )

  const formatDirectorySync = ({ isDirectoryManaged }) => (
    isDirectoryManaged
      ? <UserDirectoryServiceIcon service="active-directory" tooltipPosition="left" />
      : null
  )

  return (
    <div className="flex flex-column text-base text-900">
      <Messages ref={statusMessage} />
      <DataTable
        dataKey="id"
        loading={isLoading}
        value={filteredAdmins}
        selectionMode="single"
        onSelectionChange={(e) => navigate(`/admin/admins/${e.value.id}`)}
        sortField="lastName"
        sortOrder={1}
        rowClassName="fadein admin-row"
        scrollable
        scrollHeight={maxDataViewHeight ? (maxDataViewHeight - 10) : null}
      >
        <Column header="First Name" field="firstName" />
        <Column header="Last Name" field="lastName" sortable />
        <Column
          body={(rowData) => formatDateTimeLocale(
            rowData.deactivatedAt,
            organization.timezone,
          )}
          header="Deactivated"
        />
        <Column
          header=""
          body={reactivateButton}
        />
        <Column
          field="directorySync"
          body={formatDirectorySync}
        />
      </DataTable>
      {footer(filteredAdmins, isLoading)}
    </div>
  )
}

export default DeactivatedAdmins
