import React, { useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { convertTimeToDate } from '@services/utils'
import ShiftDialogBody from './ShiftDialogBody'
import { useCreateSiteShift } from './siteShiftsHooks'

function CreateShiftDialog({
  onHide,
  site,
  statusMessage,
  visible,
}) {
  const [startDayOfWeek, setStartDayOfWeek] = useState()
  const [endDayOfWeek, setEndDayOfWeek] = useState()
  const [startTime, setStartTime] = useState(convertTimeToDate(9, 0))
  const [endTime, setEndTime] = useState(convertTimeToDate(17, 0))

  const {
    mutateAsync: createShift,
    isLoading: isCreatingShift,
  } = useCreateSiteShift(statusMessage)

  const onCreateShift = () => {
    const shift = {
      siteId: site.id,
      startDayOfWeek,
      endDayOfWeek,
      startTimeHour: startTime.getHours(),
      startTimeMinute: startTime.getMinutes(),
      endTimeHour: endTime.getHours(),
      endTimeMinute: endTime.getMinutes(),
    }
    createShift(
      shift,
      {
        onSuccess: () => {
          onHide()
        },
      },
    )
  }

  const dialogHeaderTemplate = () => (
    <div className="flex flex-row align-items-baseline w-full justify-content-center">
      <span className="font-semibold text-lg flex flex-column gap-2">
        Add Shift
      </span>
    </div>
  )

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header={dialogHeaderTemplate}
    >
      <ShiftDialogBody
        startDayOfWeek={startDayOfWeek}
        setStartDayOfWeek={setStartDayOfWeek}
        startTime={startTime}
        setStartTime={setStartTime}
        endDayOfWeek={endDayOfWeek}
        setEndDayOfWeek={setEndDayOfWeek}
        endTime={endTime}
        setEndTime={setEndTime}
        isSubmitting={isCreatingShift}
        onSubmit={onCreateShift}
        onSubmitLabel="Create"
      />
    </Dialog>
  )
}

export default CreateShiftDialog
