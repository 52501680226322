import { useMemo } from 'react'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { map, get } from 'lodash'
import {
  errorMessageFromError,
  formErrorsFromError,
} from '@services/errorHandler'

export function useReportsQuery(statusMessage) {
  const query = useQuery({
    queryKey: ['reports'],
    queryFn: () => ApiClient.get('/reports'),
    onError: (error) => {
      statusMessage.current.clear()
      statusMessage.current.show([
        errorMessageFromError(error),
        ...formErrorsFromError(error),
      ])
    },
    refetchInterval: (data) => {
      if (!data) return false
      const hasProcessing = data.reports.some((item) => item.status === 'processing')
      return hasProcessing ? 3000 : false
    },
  })

  return useMemo(() => ({
    ...query,
    data: map(get(query, 'data.reports'), (report) => (report)),
  }), [query.data])
}

export function useCreateReport(statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['createReport'],
    mutationFn: async (params) => ApiClient.post('/reports', params),
    onSuccess: () => {
      queryClient.invalidateQueries('reports')
      if (!get(statusMessage, 'current')) return
      statusMessage.current.clear()
      statusMessage.current.show([
        {
          severity: 'success',
          summary: 'PDF Export',
        },
      ])
    },
    onError: (error) => {
      if (!get(statusMessage, 'current')) return
      statusMessage.current.clear()
      statusMessage.current.show([
        errorMessageFromError(error),
      ])
    },
  })
}
