import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Card } from 'primereact/card'
import { Divider } from 'primereact/divider'
import LinkButtonNavigator from '@components/display/LinkButtonNavigator'
import Demographic from '@components/patients/HRST/Demographic'
import HRSTDoses from '@components/patients/HRST/HRSTDoses'

const viewLinks = [
  {
    label: 'Demographics',
    linkTo: 'demographics',
  },
  {
    label: 'Doses',
    linkTo: 'doses',
  },
]

function HRST({ patient, handleSetHeader }) {
  const [searchTerm, setSearchTerm] = useState()

  useEffect(() => {
    handleSetHeader({ primaryAction: null })
  }, [patient])

  return (
    <div className="col-12">
      <Card className="h-full">
        <div className="flex flex-column gap-3 text-base text-900">
          <LinkButtonNavigator
            buttons={viewLinks}
            className="flex flex-row justify-space-between align-items-center px-1"
          />
          <Divider className="my-0" />
          <Routes>
            <Route
              path="demographics"
              element={(
                <Demographic
                  patient={patient}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                />
              )}
            />
            <Route
              path="doses"
              element={
                <HRSTDoses patient={patient} handleSetHeader={handleSetHeader} />
              }
            />
          </Routes>
        </div>
      </Card>
    </div>
  )
}

export default HRST
