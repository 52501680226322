import React from 'react'
import {
  compact, isEmpty, map, size,
} from 'lodash'
import pluralize from 'pluralize'
import { getDisplayTimeFromMinutes } from '@services/utils/moment'

/**
 * @param {Array<Number>} explicitTimes
 */
const renderExplicitTimes = (explicitTimes) => {
  if (isEmpty(explicitTimes)) {
    return null
  }

  const displayTimes = explicitTimes.map((explicitTime) => getDisplayTimeFromMinutes(explicitTime))

  return (
    <span className="ml-2">
      {`at ${pluralize('time', size(explicitTimes))}: ${displayTimes.join(', ')}`}
    </span>
  )
}

/**
 * @param {Array<String>} invalidScheduleCodes
 * @param {Array<Number>} explicitTimes
 */
const renderErrorMessage = (invalidScheduleCodes, explicitTimes) => {
  if (isEmpty(invalidScheduleCodes)) {
    return (
      <>
        <span>Blank Schedule Code</span>
        {renderExplicitTimes(explicitTimes)}
      </>
    )
  }

  return (
    <>
      <span className="mr-1">
        Unsupported schedule
        {' '}
        {pluralize('code', size(invalidScheduleCodes))}
        :
      </span>
      <span>
        {invalidScheduleCodes.join(', ')}
      </span>
      {renderExplicitTimes(explicitTimes)}
    </>
  )
}

/**
 * Displays an error message for unsupported schedule codes.
 */
function UnsupportedScheduleError({ invalidSchedules = [] }) {
  if (isEmpty(invalidSchedules)) {
    return null
  }

  const invalidScheduleCodes = compact(map(invalidSchedules, ({ hl7Code }) => hl7Code))
  const explicitTimes = compact(map(invalidSchedules, ({ time }) => time))

  return (
    <li className="flex flex-row align-items-center">
      <i className="pi pi-exclamation-triangle text-orange-500 text-xl mr-2" />
      {renderErrorMessage(invalidScheduleCodes, explicitTimes)}
    </li>
  )
}

export default UnsupportedScheduleError
