import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

function useTarDashboard(patientId, dateRange, type, statusMessage) {
  return useQuery({
    queryKey: ['tarDashboard', patientId, dateRange, type],
    queryFn: () => {
      const params = {
        startDate: dateRange.startDate.toISOString(),
        endDate: dateRange.endDate.toISOString(),
        type,
      }
      return ApiClient.get(`/patients/${patientId}/tar_dashboard`, { params })
    },
    onError: (error) => displayErrorMessages(error, statusMessage),
  })
}

export default useTarDashboard
