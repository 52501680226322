import React, { useState, useEffect, useRef } from 'react'
import {
  get, compact, pick, isEqual, keys,
} from 'lodash'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { Menu } from 'primereact/menu'
import { ConfirmDialog } from 'primereact/confirmdialog'
import {
  ProfileForm, SiteForm, SettingsForm, DeactivateUserDialog,
} from '@components/patients/PatientForm'
import FormWrapper from '@components/display/Form/FormWrapper'
import { useCurrentUser, useCurrentOrganization } from '@components/App'
import { PatientAvatar } from '@components/patients/Profile'
import LeaveOfAbsenceDialog from '@components/display/LeaveOfAbsence/LeaveOfAbsenceDialog'
import { useLeaveOfAbsenceCreateMutation } from '@hooks/leaveOfAbsences'
import { useSitesQuery } from '../Selects/SitesSelect/SitesSelectHooks'
import {
  useUpsertPatient,
  useUpdatePatientSite,
  usePatientDeactivateMutation,
  usePatientUnpauseMutation,
  useReactivatePatient,
} from './patientHooks'
import ReactivatePatientDialog from '../../components/patients/PatientForm/ReactivatePatientDialog'

function buildMenuItems({
  currentUserRole,
  patient,
  handlePauseUser,
  handleUnpauseUser,
  handleDeactivateUser,
  handleReactivateUser,
}) {
  const isSuperAdmin = currentUserRole === 'superadmin'
  const isAdmin = currentUserRole === 'site_admin'
  const isPaused = get(patient, 'currentPause')
  const items = []

  if (isPaused) {
    items.push({
      label: 'Resume',
      icon: 'pi pi-play',
      command: () => handleUnpauseUser(),
    })
  } else {
    items.push({
      label: 'Leave of Absence',
      icon: 'pi pi-pause',
      command: () => handlePauseUser(),
    })
  }

  if (isSuperAdmin || isAdmin) {
    if (!get(patient, 'isActive')) {
      items.push({
        label: 'Reactivate',
        icon: 'pi pi-check',
        command: () => handleReactivateUser(),
      })
    } else {
      items.push({
        label: 'Deactivate',
        icon: 'pi pi-times',
        command: () => handleDeactivateUser(),
      })
    }
  }

  return items
}

function UpdatePatient({
  patient, handleSetHeader, rootPath,
}) {
  const statusMessage = useRef(null)
  const patientActionMenu = useRef(null)
  const navigate = useNavigate()
  const { patientId } = useParams()
  const organization = useCurrentOrganization()

  const currentUser = useCurrentUser()

  const [assignedSiteIds, setAssignedSiteIds] = useState([])
  const [settings, setSettings] = useState({})
  const [profile, setProfile] = useState({
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    customerId: '',
    currentPause: null,
    roomNumber: '',
    dateOfBirth: '',
    gender: '',
    birthSex: '',
    ssn: '',
    selfAdmin: '',
    medicaidIdNumber: '',
    mrn: '',
    note: '',
  })
  const [showPauseUserDialog, setShowPauseUserDialog] = useState(false)
  const [showDeactivateUserDialog, setShowDeactivateUserDialog] = useState(false)
  const [showReactivateUserDialog, setShowReactivateUserDialog] = useState(false)

  const {
    data: { sites } = [],
    isLoading: isSitesLoading,
  } = useSitesQuery({ statusMessage, organizationId: organization.id })

  const {
    mutateAsync: upsertPatient,
    isLoading: profileUpdateIsLoading,
  } = useUpsertPatient(patientId, statusMessage)

  const {
    mutateAsync: updatePatientSite,
  } = useUpdatePatientSite(patientId, statusMessage)

  const {
    mutateAsync: deactivatePatient,
    isLoading: deactivateIsLoading,
  } = usePatientDeactivateMutation(patientId, statusMessage, organization.patientLabelSingular)

  const {
    mutateAsync: reactivatePatient,
    isLoading: isReactivatePatientLoading,
  } = useReactivatePatient(statusMessage, organization.patientLabelSingular)

  const {
    mutateAsync: leaveOfAbsence,
    isLoading: isLOALoading,
  } = useLeaveOfAbsenceCreateMutation(patientId, statusMessage)

  const {
    mutateAsync: unpausePatient,
  } = usePatientUnpauseMutation(patientId, statusMessage)

  const handleDeactivate = async (note) => {
    await deactivatePatient({ note })
    navigate('/admin/patients')
  }

  const handleReactivate = async () => {
    await reactivatePatient(patientId)
    navigate(`/admin/patients/${patientId}/profile`)
  }

  const handlePause = async ({ startDate, endDate, note }) => {
    const payload = {
      patient_id: patientId,
      start_at: startDate,
      end_at: endDate,
      note,
    }
    await leaveOfAbsence(payload, {
      onSuccess: () => {
        navigate(`/admin/patients/${patientId}/profile`)
      },
    })
  }

  const handleUnpause = async () => {
    await unpausePatient(patientId, {
      onSuccess: () => {
        navigate(`/admin/patients/${patientId}/profile`)
      },
    })
  }

  const handleUpdateProfile = () => {
    upsertPatient({
      ...profile,
      patientSettingsAttributes: {
        id: get(patient, 'patientSettings.id'),
        selfAdmin: profile.selfAdmin,
        medicaidIdNumber: profile.medicaidIdNumber,
        mrn: profile.mrn,
        note: profile.note,
      },
    })
  }

  const handleUpdateSite = ([siteId]) => {
    setAssignedSiteIds([siteId])
    updatePatientSite({ patient, siteId })
  }

  const handleUpdateSettings = (setting) => {
    if (keys(setting).includes('ccmEligible')) {
      upsertPatient(setting)
    } else {
      upsertPatient({ patientSettingsAttributes: setting })
    }
    setSettings((s) => ({ ...s, ...setting }))
  }

  const breadcrumbs = compact([
    {
      label: organization.patientLabelSingular,
      template: <Link to={`${rootPath}/${patientId}`}>{organization.patientLabelSingular}</Link>,
    },
    {
      label: 'Edit',
      template: <span>Edit</span>,
    },
  ])
  const primaryAction = (
    <>
      <Menu
        model={buildMenuItems({
          currentUserRole: get(currentUser, 'role'),
          patient,
          handlePauseUser: () => setShowPauseUserDialog(true),
          handleUnpauseUser: handleUnpause,
          handleDeactivateUser: () => setShowDeactivateUserDialog(true),
          handleDeactivate,
          handleReactivateUser: () => setShowReactivateUserDialog(true),
          currentOrganization: organization,
        })}
        popup
        ref={patientActionMenu}
        id="patientActionMenu"
      />
      <Button
        label="Actions"
        className="p-button-sm"
        icon="pi pi-chevron-down"
        onClick={(event) => patientActionMenu.current.toggle(event)}
      />
    </>
  )

  useEffect(() => {
    if (patient) {
      setProfile({
        id: patient.id,
        initials: patient.initials,
        email: patient.email,
        phone: patient.phone,
        firstName: patient.firstName,
        lastName: patient.lastName,
        customerId: patient.customerId,
        currentPause: patient.currentPause,
        roomNumber: patient.roomNumber,
        dateOfBirth: patient.dateOfBirth,
        gender: patient.gender,
        birthSex: patient.birthSex,
        ssn: patient.ssn,
        selfAdmin: patient.patientSettings.selfAdmin,
        medicaidIdNumber: patient.patientSettings.medicaidIdNumber,
        mrn: patient.patientSettings.mrn,
        note: patient.patientSettings.note,
      })
      const assignedSiteId = get(patient, 'site.id')
      setAssignedSiteIds(assignedSiteId ? [assignedSiteId] : [])
      const patientSettings = {
        id: get(patient, 'patientSettings.id'),
        ...(organization.ccmEnabled ? { ccmEligible: patient.ccmEligible } : {}),
        selfAdmin: get(patient, 'patientSettings.selfAdmin', false),
        medicaidIdNumber: get(patient, 'patientSettings.medicaidIdNumber'),
        bowelMovementTrackingEnabled: get(patient, 'patientSettings.bowelMovementTrackingEnabled'),
        mrn: get(patient, 'patientSettings.mrn'),
      }
      setSettings(patientSettings)
    }

    handleSetHeader({ breadcrumbs, primaryAction })

    return () => {
      handleSetHeader({ breadcrumbs: breadcrumbs.slice(0, -1), primaryAction: null })
    }
  }, [patient])

  return (
    <div className="flex flex-row flex-wrap">
      {
        showPauseUserDialog && (
          <LeaveOfAbsenceDialog
            patient={patient}
            visible={showPauseUserDialog}
            onHide={() => setShowPauseUserDialog(false)}
            onConfirm={handlePause}
            isLoading={isLOALoading}
          />
        )
      }
      <DeactivateUserDialog
        visible={showDeactivateUserDialog}
        onHide={() => setShowDeactivateUserDialog(false)}
        onConfirm={handleDeactivate}
        isLoading={deactivateIsLoading}
      />
      <ReactivatePatientDialog
        visible={showReactivateUserDialog}
        onHide={() => setShowReactivateUserDialog(false)}
        onConfirm={handleReactivate}
        isLoading={isReactivatePatientLoading}
      />
      <ConfirmDialog />
      <Toast ref={statusMessage} />
      <div className="col-6">
        <FormWrapper title="Profile" fullHeight>
          <ProfileForm
            profile={profile}
            setProfile={(value) => setProfile((p) => ({ ...p, ...value }))}
          />
          <Button
            label="Save Profile"
            className="p-button-sm mt-4"
            disabled={isEqual(profile, pick(patient, keys(profile)))}
            loading={profileUpdateIsLoading}
            onClick={handleUpdateProfile}
          />
        </FormWrapper>
      </div>
      <div className="col-6 flex flex-column gap-3">
        <FormWrapper title="Site">
          <SiteForm
            sites={sites}
            selectedSites={assignedSiteIds}
            setSelectedSites={handleUpdateSite}
            isSitesLoading={isSitesLoading}
          />
        </FormWrapper>
        <FormWrapper title="Settings">
          <SettingsForm
            settings={settings}
            setSettings={handleUpdateSettings}
            ccmEnabled={organization.ccmEnabled}
            orgBowelMovementTrackingEnabled={organization.bowelMovementTrackingEnabled}
          />
        </FormWrapper>
        <FormWrapper title="Avatar">
          <PatientAvatar patient={patient} size="large" />
        </FormWrapper>
      </div>
    </div>
  )
}

export default UpdatePatient
