import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { get, map } from 'lodash'
import { useMemo } from 'react'
import {
  displayErrorMessages,
} from '@services/errorHandler'

// eslint-disable-next-line import/prefer-default-export
export function useOrganizationsQuery(statusMessage) {
  const query = useQuery({
    queryKey: 'organizations',
    queryFn: () => ApiClient.get('/organizations'),
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })

  return useMemo(() => ({
    ...query,
    data: map(get(query, 'data.organizations'), (organization) => (organization)),
  }), [query.data])
}
