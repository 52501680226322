import { useMemo } from 'react'
import { get, map } from 'lodash'
import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'

const getPath = (model, id) => {
  switch (model) {
    case 'Patient':
      return `/integration_data/for_patient?patient_id=${id}`
    default:
      throw new Error(`Unsupported model: ${model}`)
  }
}

const useIntegrationDataMessages = (model, id) => {
  const path = getPath(model, id)

  const query = useQuery({
    queryKey: ['integrationDataMessages', path],
    queryFn: () => ApiClient.get(path),
    enabled: !!id,
  })

  return useMemo(() => ({
    ...query,
    isLoading: query.isLoading,
    data: map(get(query, 'data.integrationData'), (data) => (data)),
  }), [query.data])
}

export default useIntegrationDataMessages
