import React from 'react'
import pluralize from 'pluralize'
import { InputNumber } from 'primereact/inputnumber'
import { Button } from 'primereact/button'

function PackageQuantity({
  dosesInFill, setDosesInFill, medicineForm, handleConfirm,
}) {
  return (
    <div className="grid flex flex-grow-1 justify-content-center align-items-center fadein">
      <div className="col-9 flex flex-column gap-2 text-center">
        <label className="text-lg font-normal mb-1" htmlFor="dose-package-quantity">
          {`What is the total number of ${medicineForm && pluralize(medicineForm, 2)} in the first fill?`}
          <p className="text-sm font-normal">Provide a whole number</p>
        </label>
        <InputNumber
          inputId="dose-package-quantity"
          value={dosesInFill}
          data-testid="doses-in-fill"
          showButtons
          placeholder="10"
          onChange={(e) => setDosesInFill(e.value)}
        />
        <Button
          onClick={handleConfirm}
          label="Next"
          className="p-button-text mt-2"
          disabled={!dosesInFill}
        />
      </div>
    </div>
  )
}

export default PackageQuantity
