import React, { memo, useRef } from 'react'
import { useCurrentCapabilities } from '@components/App'
import UserDirectoryServiceIcon from '@components/integrations/UserDirectoryServiceIcon'
import { useRefreshFromWorkos } from '@hooks/sso'
import { Toast } from 'primereact/toast'
import { classNames } from 'primereact/utils'

/**
 * Displays the title for a user profile card with an icon for the user directory service in use.
 *
 * @param {object} user - The user to display the profile card title for.
 */
function UserProfileCardTitle({ user }) {
  const statusMessageRef = useRef()
  const {
    mutateAsync: refreshFromWorkOs,
    loading: loadingRefresh,
  } = useRefreshFromWorkos({ statusMessage: statusMessageRef, model: 'User', id: user?.id })
  const { organization: { canManageSsoForOrg } } = useCurrentCapabilities()

  if (!user) {
    return null
  }

  const cardIcon = user.isDirectoryManaged
    ? <UserDirectoryServiceIcon service="active-directory" />
    : <i className="pi pi-user" />

  return (
    <div className="flex flex-row gap-2 align-items-center">
      <Toast ref={statusMessageRef} />
      <div>
        {cardIcon}
      </div>
      <div>Profile</div>
      {canManageSsoForOrg && (
        <i
          className={classNames('pi', {
            'pi-refresh': !loadingRefresh,
            'pi-spin': loadingRefresh,
          })}
          onClick={() => refreshFromWorkOs()}
        />
      )}
    </div>
  )
}

export default memo(UserProfileCardTitle)
