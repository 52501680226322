import React, { useState, useEffect } from 'react'
import { Button } from 'primereact/button'

function Header({
  userSites,
  isEditing,
  setSelectedSites,
  setIsEditing,
  editEnabled,
}) {
  const [assignedSites, setAssignedSites] = useState([])

  useEffect(() => {
    setAssignedSites(userSites)
  }, [userSites])

  const handleCancel = () => {
    setSelectedSites(assignedSites)
    setIsEditing(false)
  }

  const handleEdit = () => {
    setIsEditing(true)
  }

  return (
    <div className="flex flex-row gap-1 align-items-center">
      <span className="text-lg py-2">
        <i className="pi pi-building mr-2" />
        Site Access
      </span>
      <div className="flex flex-row flex-grow-1 gap-3 align-items-center">
        <div className="flex flex-grow-1 justify-content-end ml-auto">
          {isEditing ? (
            <div className="flex ml-auto">
              <Button
                icon="pi pi-times"
                onClick={handleCancel}
                className="mr-2 p-button-sm"
              />
            </div>
          ) : (
            editEnabled && (
            <Button label="Edit Assignments" onClick={handleEdit} className="p-button-sm" />
            )
          )}
        </div>
      </div>
    </div>
  )
}

export default Header
