import React from 'react'
import { Dialog } from 'primereact/dialog'
import LeaveOfAbsenceForm from './LeaveOfAbsenceForm'

function LeaveOfAbsenceDialog({
  visible,
  onHide,
  onConfirm,
  onDelete,
  showDeleteButton,
  isLoading,
  leaveOfAbsenceToEdit,
}) {
  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header="Leave Of Absence"
      modal
      className="p-fluid w-3"
      closable={!isLoading}
    >
      <LeaveOfAbsenceForm
        onCancel={onHide}
        onConfirm={onConfirm}
        onDelete={onDelete}
        mode={leaveOfAbsenceToEdit ? 'edit' : 'add'}
        showDeleteButton={showDeleteButton}
        isLoading={isLoading}
        defaultStartAt={leaveOfAbsenceToEdit?.startAt}
        defaultEndAt={leaveOfAbsenceToEdit?.endAt}
        defaultNote={leaveOfAbsenceToEdit?.note}
      />
    </Dialog>
  )
}

export default LeaveOfAbsenceDialog
