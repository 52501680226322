import React from 'react'
import { compact, get } from 'lodash'
import { Route, Routes, useParams } from 'react-router-dom'
import { Skeleton } from 'primereact/skeleton'
import FacilityDashboard from '@components/integrationFacilities/FacilityDashboard'
import { usePageOutlet as usePageLayoutContext } from '@components/display/PageLayout'
import { useIntegrationFacilityQuery } from './facilitiesHooks'
import UpdateFacility from './UpdateFacility'

function Facility() {
  const { facility: facilityId } = useParams()
  const {
    setHeader, rootPath,
  } = usePageLayoutContext()
  const {
    data: facility,
    isLoading,
  } = useIntegrationFacilityQuery(facilityId)

  const title = {
    label: (
      <span className="flex flex-row align-items-center py-2" id="caregiver-name-tab">
        <i className="text-xl pi pi-building mr-2" />
        {isLoading && (
          <Skeleton className="inline-flex" width="10rem" height="1.5rem" />
        )}
        {!isLoading && (
          <span className="fadein">
            {facility.externalId}
            {' '}
            {get(facility, 'organization.name')}
          </span>
        )}
      </span>
    ),
    linkTo: '/admin/facilities',
  }

  const tabs = compact([])

  const breadcrumbs = [
    {
      label: 'Facility',
      template: <span>Facility</span>,
    },
  ]

  const handleSetHeader = (props = {}) => {
    setHeader({
      title, tabs, breadcrumbs, ...props,
    })
  }

  return (
    <Routes>
      <Route
        index
        path="/*"
        element={(
          <FacilityDashboard
            facilityId={facilityId}
            handleSetHeader={handleSetHeader}
            facilityLoading={isLoading}
            facility={facility}
            rootPath={rootPath}
          />
        )}
      />
      <Route
        path="update"
        element={(
          <UpdateFacility
            facility={facility}
            handleSetHeader={handleSetHeader}
          />
        )}
      />
    </Routes>
  )
}

export default Facility
