import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React from 'react'
import moment from 'moment'
import { momentFormats } from '@services/utils/moment'
import SignatureView from '@components/display/SignatureView'

function ApprovedBy({ rowData }) {
  return (
    <div className="flex flex-row align-items-center" width="33%">
      <div className="flex flex-column align-items-center justify-content-center">
        <div className="w-10rem">
          <SignatureView signature={rowData.approvedBy.currentSignature.svg} />
        </div>
        <span>{rowData.approvedBy.fullName}</span>
      </div>
    </div>
  )
}

const Days = ({ rowData }) => {
  const sortedDays = rowData.days.sort((a, b) => moment(a).diff(moment(b)))
  const format = momentFormats.dateYear
  const formattedDates = []
  formattedDates.push(moment(sortedDays[0]).format(format))
  if (sortedDays.length > 1) {
    formattedDates.push(moment(sortedDays[sortedDays.length - 1]).format(format))
  }
  return formattedDates.join(' - ')
}

function MARApprovalsTable({
  marApprovals,
  isLoading,
}) {
  if (!marApprovals || marApprovals.length === 0) return null

  const approvedByTemplate = (rowData) => (
    <ApprovedBy rowData={rowData} />
  )

  const daysTemplate = (rowData) => (
    <Days rowData={rowData} />
  )

  return (
    <DataTable
      value={marApprovals}
      loading={isLoading}
    >
      <Column field="approvedBy" header="Approved By" body={approvedByTemplate} />
      <Column field="approvedAt" header="" body={(rowData) => moment(rowData.approvedAt).format(momentFormats.dateYearTime)} />
      <Column field="days" header="Days" body={daysTemplate} />
    </DataTable>
  )
}

export default MARApprovalsTable
