import ApiClient from '@services/ApiClient'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import {
  displayErrorMessages,
} from '@services/errorHandler'

export function useSharedDevicesQuery(organizationId, statusMessage) {
  const query = useQuery({
    queryKey: ['sharedDevices', organizationId],
    queryFn: () => ApiClient.get(`/shared_devices?organization_id=${organizationId}`),
    onError: () => statusMessage.current.show([
      { severity: 'error', summary: 'Unable to fetch shared devices at this time.' },
    ]),
  })
  return query
}

export function useSetPin() {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationKey: ['caregiver'],
    mutationFn: (userId) => ApiClient.post(`/caregivers/${userId}/set_temp_pin`),
    onSuccess: () => {
      queryClient.invalidateQueries(['sharedDevices'])
    },
  })
  return mutation
}

export function useSharedDeviceQuery(id) {
  return useQuery({ queryKey: ['sharedDevice', id], queryFn: () => ApiClient.get(`/shared_devices/${id}`) })
}

export function useCreateSharedDeviceMutation(statusMessage) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (sharedDevice) => ApiClient.post('/shared_devices', sharedDevice),
    onSuccess: () => {
      queryClient.invalidateQueries('sharedDevices')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useUpdateSharedDeviceMutation(sharedDeviceId, statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (sharedDevice) => ApiClient.put(`/shared_devices/${sharedDeviceId}`, sharedDevice),
    onSuccess: () => {
      queryClient.invalidateQueries('sharedDevices')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}
