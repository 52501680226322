import React from 'react'
import { Tag } from 'primereact/tag'

const getStatusLabel = (status) => {
  switch (status) {
    case 'confirmed':
      return 'Administered'
    case 'refused':
      return 'Refusal'
    case 'on_hold':
      return 'On Hold'
    default:
      return 'unspecified'
  }
}

function ConsumptionTag({ count, type, onClick }) {
  const label = getStatusLabel(type)
  return (
    <Tag
      onClick={onClick}
      rounded
      className={`white-space-nowrap text-xs font-light
      center-tag bg-${type}
      ${type === 'confirmed' ? 'text-green-900' : 'text-white'}`}
    >
      {`${count} ${label}`}
    </Tag>
  )
}

export default ConsumptionTag
