import React from 'react'
import CheckboxWithLabel from '@components/display/CheckboxWithLabel'
import { useCurrentOrganization, useCurrentCapabilities } from '@components/App'

function Settings({ settings, setSettings }) {
  const {
    canApprovePermissionRequests,
    shouldReceivePharmacyOrderNotifications,
    shouldReceivePendingRefillsNotifications,
    ssoAdminAccess,
  } = settings

  const organization = useCurrentOrganization()
  const { organization: { canManageSsoForOrg } } = useCurrentCapabilities()

  return (
    <div className="flex flex-column gap-2">
      <CheckboxWithLabel
        key="approvePermissionRequests"
        label="Approve Permission Requests"
        checked={canApprovePermissionRequests}
        inputId="approvePermissionRequests"
        name="approvePermissionRequests"
        onChange={({ value }) => setSettings({
          ...settings,
          canApprovePermissionRequests: value,
        })}
      />
      <CheckboxWithLabel
        key="shouldReceivePharmacyOrderNotifications"
        label="Receive Pharmacy Order Emails"
        checked={shouldReceivePharmacyOrderNotifications}
        inputId="shouldReceivePharmacyOrderNotifications"
        name="shouldReceivePharmacyOrderNotifications"
        onChange={({ value }) => setSettings({
          ...settings,
          shouldReceivePharmacyOrderNotifications: value,
        })}
      />
      {
        organization.ssoEnabled && canManageSsoForOrg && (
          <CheckboxWithLabel
            key="ssoAdminAccess"
            label="SSO Admin Access"
            checked={ssoAdminAccess}
            inputId="ssoAdminAccess"
            name="ssoAdminAccess"
            onChange={({ value }) => setSettings({
              ...settings,
              ssoAdminAccess: value,
            })}
          />
        )
      }
      {
        organization.ekitsEnabled && (
          <CheckboxWithLabel
            key="shouldReceivePendingRefillsNotifications"
            label="Receive Pending Refills Emails"
            checked={shouldReceivePendingRefillsNotifications}
            inputId="shouldReceivePendingRefillsNotifications"
            name="shouldReceivePendingRefillsNotifications"
            onChange={({ value }) => setSettings({
              ...settings,
              shouldReceivePendingRefillsNotifications: value,
            })}
          />
        )
      }
    </div>
  )
}

export default Settings
