import { useMemo } from 'react'
import { get } from 'lodash'
import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'

// eslint-disable-next-line import/prefer-default-export
export function usePatientBowelMovements(patientId, statusMessage) {
  const query = useQuery({
    queryKey: ['patientBowelMovements', patientId],
    queryFn: () => ApiClient.get(`/bowel_movements?patient_id=${patientId}`),
    onError: (error) => {
      statusMessage.current.show([
        {
          severity: 'error',
          sticky: true,
          summary: `Unable to load bowel movements at this time. ${error.message}`,
        },
      ])
    },
  })
  return useMemo(() => ({
    ...query,
    data: get(query, 'data.bowelMovements'),
  }), [query.data])
}
