import React from 'react'
import {
  isEqual,
} from 'lodash'
import { classNames } from 'primereact/utils'
import { Checkbox } from 'primereact/checkbox'
import './style.scss'

/**
 * A row that displays an attribute and its value. If the newValue is present
 * and different from the currentValue, the row will display both values and
 * allow the user to select the other value. The row can also be in editing mode
 * where the editor will be displayed.
 *
 * @param {Object} props
 * @param {string} props.label - The label for the attribute
 * @param {string} props.currentValue - The current value of the attribute
 * @param {string} props.currentValueLabel - The label for the current value,
 * only used when newValue is present and different from currentValue
 * @param {string} props.newValue - The other value of the attribute, only
 * used when newValue is present and different from currentValue
 * @param {string} props.newValueLabel - The label for the other value, only
 * used when newValue is present and different from currentValue
 * @param {React.ReactNode} props.action - The action to be displayed on the
 * right side of the row
 * @param {boolean} props.backgroundHighlight - Whether the row should have a
 * background highlight
 * @param {Function} props.valueChangeHandler - The function to be called when
 * the other value is selected
 * @param {React.ReactNode} props.editor - The editor to be displayed when the
 * row is in editing mode
 * @param {boolean} props.isEditing - Whether the row is in editing mode
 * @returns {React.ReactElement}
 */
function DoseAttributeRow({
  label,
  currentValue,
  currentValueLabel = 'Dose Value',
  newValue,
  newValueLabel = 'Pharmacy Order Value',
  action,
  backgroundHighlight,
  valueChangeHandler,
  editor,
  isEditing,
}) {
  const className = classNames('flex flex-row align-items-center px-2', {
    'surface-ground': backgroundHighlight,
    'py-2': action,
    'py-3': !action,
  })

  const doseHasDifferentValueFromOrder = newValue && !isEqual(currentValue, newValue)

  const valueContentTemplate = () => {
    if (isEditing) {
      return editor
    }

    if (doseHasDifferentValueFromOrder) {
      return (
        <div className="flex flex-column gap-3 py-2">
          <div className="flex flex-row gap-2 align-items-center">
            <Checkbox
              inputId={`currentValue:${label}`}
              name={`currentValue:${label}`}
              value={currentValue}
              readOnly
              checked
            />
            <label htmlFor={`currentValue:${label}`} className="flex flex-row gap-2 flex-grow-1 text-900 font-medium">
              <span>
                {currentValueLabel}
                :
              </span>
              <span>{currentValue}</span>
            </label>
          </div>
          <div className="flex flex-row gap-2 align-items-center">
            <Checkbox
              inputId={`newValue:${label}`}
              name={`newValue:${label}`}
              value={newValue}
              onChange={valueChangeHandler}
              checked={false}
            />
            <label htmlFor={`newValue:${label}`} className="flex flex-row gap-2">
              <span className="text-600">
                {newValueLabel}
                :
              </span>
              <span className="flex flex-grow-1 text-600">
                {newValue}
              </span>
            </label>
          </div>
        </div>
      )
    }

    return (
      <span className="flex flex-grow-1 text-900 font-medium">{currentValue}</span>
    )
  }

  return (
    <li className={className}>
      <span className="text-500 w-3 font-medium">{label}</span>
      <div className="flex flex-grow-1">
        {valueContentTemplate()}
      </div>
      {action && <div>{action}</div>}
    </li>
  )
}

export default DoseAttributeRow
