import { displayErrorMessages } from '@services/errorHandler'
import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { get, map } from 'lodash'
import { useMemo } from 'react'

// eslint-disable-next-line import/prefer-default-export
export function useMedicalConditionsQuery({ patientId, doseId, statusMessage }) {
  const queryKey = doseId ? ['doseMedicalConditions', doseId] : ['patientMedicalConditions', patientId]
  const queryFn = () => {
    if (doseId) {
      return ApiClient.get(`/doses/${doseId}/medical_conditions`, { params: { patientId } })
    } if (patientId) {
      return ApiClient.get(`/patients/${patientId}/medical_conditions`)
    }
    throw new Error('Neither doseId nor patientId provided')
  }

  const query = useQuery({
    queryKey,
    queryFn,
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })

  return useMemo(() => ({
    ...query,
    data: map(get(query, 'data'), (medicalCondition) => (medicalCondition)),
  }), [query.data])
}
