import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { get, map } from 'lodash'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

function usePatientsAvailableToOnboard(organizationId, pendingUserId, statusMessage) {
  const query = useQuery({
    queryKey: ['patientsAvailableToOnboard', organizationId, pendingUserId],
    queryFn: () => {
      let params = { pendingUserId }

      if (organizationId) {
        params = { ...params, organizationId }
      }

      return ApiClient.get('/patients/available_to_onboard', { params })
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
    enabled: !!organizationId && !!pendingUserId,
  })

  return useMemo(() => ({
    ...query,
    data: map(get(query, 'data.patients'), (patient) => (patient)),
  }), [query])
}

export default usePatientsAvailableToOnboard
