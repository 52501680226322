import React from 'react'
import { Routes, Route } from 'react-router-dom'
import PageLayout, { usePageOutlet } from '@components/display/PageLayout'
import { withApp } from '@components/App'
import PharmacistsTable from './PharmacistsTable'
import CreatePharmacist from './CreatePharmacist'
import Pharmacist from './Pharmacist'
import './pharmacists.scss'

function Pharmacists() {
  const usePharmacistsHeader = () => usePageOutlet()

  return (
    <div className="pharmacists">
      <Routes>
        <Route path="/admin/pharmacists/*" element={<PageLayout rootPath="/admin/pharmacists" rootLabel="Pharmacists" />}>
          <Route index element={<PharmacistsTable usePharmacistsHeader={usePharmacistsHeader} />} />
          <Route path="create" element={<CreatePharmacist usePharmacistsHeader={usePharmacistsHeader} />} />
          <Route path=":pharmacistId/*" element={<Pharmacist usePharmacistsHeader={usePharmacistsHeader} />} />
        </Route>
      </Routes>
    </div>
  )
}

export default withApp(Pharmacists)
