import React from 'react'
import { InputNumber } from 'primereact/inputnumber'
import { Button } from 'primereact/button'

function PARLevel({ parLevel, setParLevel, handleConfirm }) {
  return (
    <div className="grid flex flex-grow-1 justify-content-center align-items-center fadein">
      <div className="col-9 flex flex-column gap-2 text-center">
        <label className="text-lg font-normal mb-1">
          At what inventory level should this dose be replenished?
          <p className="text-sm font-normal">Periodic Automatic Replenishment (PAR)</p>
        </label>
        <InputNumber
          value={parLevel}
          showButtons
          placeholder="1"
          onValueChange={(e) => setParLevel(e.value)}
        />
        <Button
          onClick={handleConfirm}
          label="Next"
          className="p-button-text mt-2"
          disabled={!parLevel}
        />
      </div>
    </div>
  )
}

export default PARLevel
