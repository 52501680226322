import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { get } from 'lodash'
import { InputTextWithLabel, TimePickerWithLabel } from '@components/display'
import { Card } from 'primereact/card'
import { Button } from 'primereact/button'
import { useParams } from 'react-router-dom'
import { useEventTime, useEventTimesMutation } from '../../features/Schedules/eventTimesHooks'

function Form({
  eventTimeId, ownerType, ownerId, onSubmit, useRouteParams = false,
}) {
  const [start, setStart] = useState()
  const [end, setEnd] = useState()
  const [name, setName] = useState('')
  const { eventTimeId: routeEventTimeId } = useParams()
  const isCreate = useRouteParams ? !routeEventTimeId : !eventTimeId
  const mutation = useEventTimesMutation({ ownerType, ownerId, isCreate })
  const {
    data: eventTime,
    isLoading,
  } = useEventTime(useRouteParams ? routeEventTimeId : eventTimeId)

  useEffect(() => {
    if (eventTime) {
      setName(eventTime.name)
      setStart(eventTime.startTimeMinutes)
      setEnd(eventTime.endTimeMinutes)
    }
  }, [eventTime])

  const handleSubmit = async () => {
    await mutation.mutateAsync({
      name,
      id: eventTime?.id,
      start_time_hh_mm: moment(get(start, 'time')).format('HH:mm'),
      end_time_hh_mm: moment(get(end, 'time')).format('HH:mm'),
    })
    onSubmit()
  }

  // TO DO: clean this up once organizations page is off active admin
  return (
    <Card className="col-6">
      <div className="gap-2 fadein">
        {
          (
            ((eventTimeId || routeEventTimeId) && start && end)
            || (!eventTimeId && !routeEventTimeId)
          ) && (
            <>
              <InputTextWithLabel
                value={name}
                label="Name"
                inputId="event-time-name"
                name="name"
                onChange={(event) => setName(event.target.value)}
                disabled={!isCreate && isLoading}
              />
              <TimePickerWithLabel
                value={start}
                label="Start Time"
                inputId="event-time-start"
                onChange={(value) => setStart(value)}
              />
              <TimePickerWithLabel
                value={end}
                label="End Time"
                inputId="event-time-end"
                minTime={start?.time}
                onChange={(value) => setEnd(value)}
              />
            </>
          )
        }
        <Button
          label="Submit"
          className="p-button-sm w-12"
          type="button"
          onClick={handleSubmit}
          disabled={mutation.isLoading || (!isCreate && isLoading)}
          loading={mutation.isLoading || (!isCreate && isLoading)}
        />
      </div>
    </Card>
  )
}

export default Form
