import { Dialog } from 'primereact/dialog'
import React, { useEffect, useState } from 'react'
import { Button } from 'primereact/button'
import { map, omitBy, uniq } from 'lodash'
import { AutoComplete } from 'primereact/autocomplete'
import { Calendar } from 'primereact/calendar'
import moment from 'moment'
import { momentFormats } from '@services/utils/moment'
import { Dropdown } from 'primereact/dropdown'
import { useIntegrationDataBulkIgnoreMutation, useIntegrationDataFilterCountMutation, useIntegrationDataRetryFailedMutation } from './integrationDataHooks'
import { ignoreCodes } from './integrationDataConfig'

function SearchField({
  id, label, value, suggestions, setSuggestions, allOptions, setSearchCriteria, setCurrentCount,
}) {
  const handleCompleteMethod = (e) => {
    const filtered = allOptions.filter((option) => option
      .toLowerCase().includes(e.query.toLowerCase()))
    setSuggestions(filtered)
  }

  const handleChange = (e) => {
    if (setCurrentCount) setCurrentCount(null)
    setSearchCriteria((prevState) => ({ ...prevState, [id]: e.target.value }))
  }

  return (
    <div className="field">
      <label className="block" htmlFor={id}>{label}</label>
      <AutoComplete
        id={id}
        className="w-12"
        value={value}
        suggestions={suggestions}
        dropdown
        completeMethod={handleCompleteMethod}
        onChange={handleChange}
      />
    </div>
  )
}

function RunMessagesDialog({
  facilityId,
  isVisible,
  setIsVisible,
  integrationData,
  messagesRef,
}) {
  const [searchCriteria, setSearchCriteria] = useState({
    patientExternalId: '',
    errorCode: '',
    messageCode: '',
    startAtPeriod: null,
    endAtPeriod: null,
  })
  const [currentCount, setCurrentCount] = useState(null)
  const [patientExternalIdAllOptions, setPatientExternalIdAllOptions] = useState([])
  const [suggestedPatientExternalId, setSuggestedPatientExternalId] = useState([])
  const [errorCodeAllOptions, setErrorCodeAllOptions] = useState([])
  const [suggestedErrorCode, setSuggestedErrorCode] = useState([])
  const [messageCodeAllOptions, setMessageCodeAllOptions] = useState([])
  const [suggestedMessageCode, setSuggestedMessageCode] = useState([])
  const [ignoreCode, setIgnoreCode] = useState(null)

  useEffect(() => {
    if (!integrationData) return
    setPatientExternalIdAllOptions(uniq(map(integrationData, (data) => data.patientExternalId))
      .filter((value) => value))
    setErrorCodeAllOptions(uniq(map(integrationData, (data) => data.errorCode))
      .filter((value) => value))
    setMessageCodeAllOptions(uniq(map(integrationData, (data) => data.messageCode))
      .filter((value) => value))
  }, [integrationData])

  const removeEmptyFields = (obj) => omitBy(obj, (value) => value === '')

  const {
    mutateAsync: filterCount,
    isLoading: isFilterCountLoading,
  } = useIntegrationDataFilterCountMutation(messagesRef)

  const {
    mutateAsync: retryFailed,
    isLoading: isRetryFailedLoading,
  } = useIntegrationDataRetryFailedMutation(messagesRef)

  const {
    mutateAsync: bulkIgnore,
    isLoading: isBulkIgnoreLoading,
  } = useIntegrationDataBulkIgnoreMutation(messagesRef)

  const resetState = () => {
    setSearchCriteria({
      patientExternalId: '',
      errorCode: '',
      messageCode: '',
      startAtPeriod: null,
      endAtPeriod: null,
    })
    setCurrentCount(null)
    setIgnoreCode(null)
  }

  const handleDateChange = (e, field) => {
    if (currentCount) setCurrentCount(null)
    setSearchCriteria({ ...searchCriteria, [field]: e.target.value })
  }

  const searchValues = () => {
    const nonEmptyFields = removeEmptyFields(searchCriteria)
    if (nonEmptyFields.startAtPeriod) {
      nonEmptyFields.startAtPeriod = moment(nonEmptyFields.startAtPeriod)
        .startOf('day')
        .format(momentFormats.datetime_24)
    }
    if (nonEmptyFields.endAtPeriod) {
      nonEmptyFields.endAtPeriod = moment(nonEmptyFields.endAtPeriod)
        .endOf('day')
        .format(momentFormats.datetime_24)
    }
    return nonEmptyFields
  }

  const handleCalculate = async () => {
    await filterCount({
      facilityId,
      ...searchValues(),
    }, {
      onSuccess: (data) => {
        setCurrentCount(data.count)
      },
    })
  }

  const handleRun = async () => {
    await retryFailed({
      facilityId,
      ...searchValues(),
    }, {
      onSuccess: (data) => {
        messagesRef.current.show([
          {
            severity: 'success',
            summary: `${data.enqueuedCount} messages enqueued`,
          },
        ])
        resetState()
        setIsVisible(false)
      },
    })
  }

  const handleIgnore = async () => {
    await bulkIgnore({
      facilityId,
      ignoreCode,
      ...searchValues(),
    }, {
      onSuccess: (data) => {
        messagesRef.current.show([
          {
            severity: 'success',
            summary: `${data.ignoredCount} messages ignored`,
          },
        ])
        resetState()
        setIsVisible(false)
      },
    })
  }

  return (
    <Dialog
      header="Run Failed Integration Data"
      visible={isVisible}
      style={{ width: '50vw' }}
      onHide={() => {
        resetState()
        return setIsVisible(false)
      }}
      dismissableMask
    >
      <div className="flex flex-column gap-2">
        <span className="text-xs">
          Choose the criteria to run the failed integration data, empty fields will be ignored.
        </span>
        <span className="text-xs">
          Suggestions are based on the failed integration data in the last 3 months.
        </span>
        <SearchField
          id="patientExternalId"
          label="Patient External ID"
          value={searchCriteria.patientExternalId}
          suggestions={suggestedPatientExternalId}
          setSuggestions={setSuggestedPatientExternalId}
          allOptions={patientExternalIdAllOptions}
          setSearchCriteria={setSearchCriteria}
          setCurrentCount={setCurrentCount}
        />
        <SearchField
          id="errorCode"
          label="Error Code"
          value={searchCriteria.errorCode}
          suggestions={suggestedErrorCode}
          setSuggestions={setSuggestedErrorCode}
          allOptions={errorCodeAllOptions}
          setSearchCriteria={setSearchCriteria}
          setCurrentCount={setCurrentCount}
        />
        <SearchField
          id="messageCode"
          label="Order Type"
          value={searchCriteria.messageCode}
          suggestions={suggestedMessageCode}
          setSuggestions={setSuggestedMessageCode}
          allOptions={messageCodeAllOptions}
          setSearchCriteria={setSearchCriteria}
          setCurrentCount={setCurrentCount}
        />
        <div className="field">
          <label className="block" htmlFor="startAtPeriod">Create Date Range</label>
          <div className="flex flex-row gap-2">
            <div className="flex flex-column gap-2 w-6">
              <label className="block" htmlFor="startAtPeriod">Start</label>
              <Calendar
                id="startAtPeriod"
                className="w-12"
                value={searchCriteria.startAtPeriod?.toDate}
                onChange={(e) => handleDateChange(e, 'startAtPeriod')}
              />
            </div>
            <div className="flex flex-column gap-2 w-6">
              <label className="block" htmlFor="endAtPeriod">End</label>
              <Calendar
                id="endAtPeriod"
                className="w-12"
                value={searchCriteria.endAtPeriod?.toDate}
                onChange={(e) => handleDateChange(e, 'endAtPeriod')}
              />
            </div>
          </div>
          <span className="text-xs">If one of the dates is empty, it will be considered as the beginning or end of time.</span>
        </div>
        {
          currentCount === null ? (
            <Button
              label="Search"
              className="p-button-sm"
              loading={isFilterCountLoading}
              onClick={handleCalculate}
              disabled={currentCount === 0}
            />
          ) : (
            <div className="flex flex-row gap-2 align-items-end justify-space-between">
              <div className="flex flex-column gap-2 w-6">
                <Dropdown
                  value={ignoreCode}
                  options={map(ignoreCodes, (value, key) => ({ label: value, value: key }))}
                  onChange={(e) => setIgnoreCode(e.value)}
                  placeholder="Ignore Code"
                  disabled={isFilterCountLoading || isRetryFailedLoading}
                />
                <Button
                  label={`Ignore ${currentCount} messages`}
                  className="p-button-sm"
                  onClick={handleIgnore}
                  loading={isBulkIgnoreLoading}
                  disabled={isBulkIgnoreLoading || !ignoreCode}
                />
              </div>
              <div className="w-6">
                <Button
                  label={`Run ${currentCount} messages`}
                  className="p-button-sm w-12"
                  onClick={handleRun}
                  loading={isRetryFailedLoading}
                  disabled={isFilterCountLoading || isRetryFailedLoading}
                />
              </div>
            </div>
          )
        }
      </div>
    </Dialog>
  )
}

export default RunMessagesDialog
