import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'primereact/button'
import InputTextWithLabel from '@components/display/InputTextWithLabel'

function IntegrationDataSearchForm() {
  const navigate = useNavigate()
  const [messageUuid, setMessageUuid] = useState('')

  const handleSearch = (e) => {
    e.preventDefault()
    navigate(`/admin/integrations/integrationData/${messageUuid}`)
  }

  return (
    <div className="flex flex-column gap-1 text-base text-900">
      <InputTextWithLabel
        inputId="message-uuid"
        label="Message UUID"
        name="message-uuid"
        onChange={(e) => setMessageUuid(e.target.value)}
        value={messageUuid}
      />
      <Button
        className="p-button-primary"
        disabled={!messageUuid}
        label="Search"
        onClick={handleSearch}
      />
    </div>
  )
}

export default IntegrationDataSearchForm
