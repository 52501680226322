import React, { useState } from 'react'
import { Card } from 'primereact/card'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { formatDateTimeLocale } from '@services/utils'
import LeaveOfAbsenceDialog from '@components/display/LeaveOfAbsence/LeaveOfAbsenceDialog'
import {
  useLeaveOfAbsenceCreateMutation,
  useLeaveOfAbsenceDeleteMutation,
  useLeaveOfAbsencesQuery,
  useLeaveOfAbsenceUpdateMutation,
} from '@hooks/leaveOfAbsences'
import { isEmpty } from 'lodash'
import { momentFormats } from '@services/utils/moment'

function LOAHistory({
  patient, patientLoading, statusMessage,
}) {
  const [showLeaveOfAbsenceDialog, setShowLeaveOfAbsenceDialog] = useState(false)
  const [leaveOfAbsenceToEdit, setLeaveOfAbsenceToEdit] = useState(null)

  const query = useLeaveOfAbsencesQuery(patient?.id)
  const { leaveOfAbsences = [] } = query?.data || {}

  const {
    mutateAsync: createLOA,
    isLoading: isCreatingLOA,
  } = useLeaveOfAbsenceCreateMutation(patient?.id, statusMessage)

  const {
    mutateAsync: updateLOA,
    isLoading: isUpdatingLOA,
  } = useLeaveOfAbsenceUpdateMutation(statusMessage)

  const {
    mutateAsync: deleteLOA,
    isLoading: isDeletingLOA,
  } = useLeaveOfAbsenceDeleteMutation(statusMessage)

  const handleCreateLOA = async ({ startAt, endAt, note }) => {
    if (!startAt) {
      statusMessage.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Start Date is required',
      })
      return
    }
    const payload = {
      patientId: patient.id,
      startAt: startAt.utc().format(momentFormats.iso8601_utc),
      endAt: endAt?.utc().format(momentFormats.iso8601_utc),
      note,
    }
    await createLOA(payload, {
      onSuccess: () => {
        setShowLeaveOfAbsenceDialog(false)
      },
    })
  }

  const handleUpdateLOA = async ({ startAt, endAt, note }) => {
    const payload = {
      id: leaveOfAbsenceToEdit.id,
      startAt: startAt.utc().format(momentFormats.iso8601_utc),
      endAt: endAt?.utc().format(momentFormats.iso8601_utc),
      note,
    }
    await updateLOA(payload, {
      onSuccess: () => {
        setShowLeaveOfAbsenceDialog(false)
        setLeaveOfAbsenceToEdit(null)
      },
    })
  }

  const handleDeleteLOA = async (LOA) => {
    await deleteLOA(LOA.id, {
      onSuccess: () => {
        setShowLeaveOfAbsenceDialog(false)
        setLeaveOfAbsenceToEdit(null)
      },
    })
  }

  const handleEdit = (LOA) => {
    setShowLeaveOfAbsenceDialog(true)
    setLeaveOfAbsenceToEdit(LOA)
  }

  const handleHide = () => {
    setShowLeaveOfAbsenceDialog(false)
    setLeaveOfAbsenceToEdit(null)
  }

  const renderEditButton = (LOA) => (
    <Button
      icon="pi pi-pencil"
      className="p-button-outlined p-button-text text-xs p-1"
      label="Edit"
      type="button"
      onClick={() => handleEdit(LOA)}
    />
  )

  return (
    <Card data-test="loa-history">
      <div className="flex flex-row justify-content-between align-items-center mb-2 text-lg">
        <span>Leave of Absence</span>
        <Button
          label="Add LOA"
          className="p-button-sm"
          type="button"
          icon="pi pi-plus"
          onClick={() => setShowLeaveOfAbsenceDialog(true)}
        />
      </div>
      {
        showLeaveOfAbsenceDialog && (
          <LeaveOfAbsenceDialog
            patient={patient}
            visible={showLeaveOfAbsenceDialog}
            onHide={handleHide}
            onConfirm={leaveOfAbsenceToEdit ? handleUpdateLOA : handleCreateLOA}
            onDelete={leaveOfAbsenceToEdit ? () => handleDeleteLOA(leaveOfAbsenceToEdit) : null}
            showDeleteButton={!isEmpty(leaveOfAbsenceToEdit)}
            leaveOfAbsenceToEdit={leaveOfAbsenceToEdit}
            isLoading={isCreatingLOA || isUpdatingLOA || isDeletingLOA}
            statusMessage={statusMessage}
          />
        )
      }
      <DataTable
        dataKey="id"
        className="fadein"
        loading={patientLoading || isCreatingLOA || isUpdatingLOA}
        sortField="startAt"
        sortOrder={1}
        sortMode="single"
        value={leaveOfAbsences}
      >
        <Column
          className="w-3"
          header="Start Date"
          field="startAt"
          body={({ startAt }) => formatDateTimeLocale(startAt, patient.timezone)}
        />
        <Column
          className="w-3"
          header="End Date"
          field="endAt"
          body={({ endAt }) => formatDateTimeLocale(endAt, patient.timezone)}
        />
        <Column
          className="w-3"
          header="Added by"
          field="pausedBy"
          body={({ pausedBy }) => pausedBy?.fullName}
        />
        <Column className="w-4" header="Note" field="note" />
        <Column className="w-2" body={renderEditButton} />
      </DataTable>
    </Card>
  )
}

export default LOAHistory
