import React, { useRef } from 'react'
import { Toast } from 'primereact/toast'
import { Attribute } from '@components/display/AttributeCard'
import SuperAdminCard from '@components/display/SuperAdminCard'
import PrescriptionActionMenu from '@components/prescriptions/PrescriptionActionMenu'
import { dateTemplate } from '@services/utils'

const doseLink = (prescription) => {
  if (!prescription.dose) {
    return 'Unlinked'
  }

  const { doseId, patientId, dose: { orderText } } = prescription

  const path = `/admin/patients/${patientId}/doses/manage/${doseId}`

  return (
    <a className="text-blue-500 hover:text-blue-700" href={path}>
      {orderText}
    </a>
  )
}

const pharmacyLink = (prescription) => {
  if (!prescription.pharmacy) {
    return 'Unlinked'
  }

  const { pharmacyId, pharmacy: { name, externalId } } = prescription

  const path = `/admin/pharmacies/${pharmacyId}`

  return (
    <a className="text-blue-500 hover:text-blue-700" href={path}>
      {`${name} (${externalId})`}
    </a>
  )
}

const prescriptionLink = (prescription) => {
  if (!prescription) {
    return 'Unlinked'
  }

  const { id, patientId, prescriptionNumber } = prescription

  const path = `/admin/patients/${patientId}/prescriptions/manage/${id}`

  return (
    <a className="text-blue-500 hover:text-blue-700" href={path}>
      {prescriptionNumber}
    </a>
  )
}

function PrescriptionCard({ prescription }) {
  const statusMessage = useRef(null)

  if (!prescription) {
    return null
  }

  const tags = prescription.active
    ? [{ value: 'Active', severity: 'success' }]
    : [{ value: 'Inactive', severity: 'danger' }]

  const actionMenuTemplate = () => (
    <PrescriptionActionMenu
      statusMessage={statusMessage}
      prescription={prescription}
    />
  )

  return (
    <div className="col-12">
      <Toast ref={statusMessage} />
      <SuperAdminCard title="Prescription" tags={tags} ActionComponent={actionMenuTemplate}>
        <Attribute
          label="Prescription Number:"
          value={prescription.prescriptionNumber}
        />
        <Attribute
          label="Order Number:"
          value={prescription.orderNumber}
        />
        <Attribute
          label="Dose:"
          value={doseLink(prescription)}
        />
        <Attribute
          label="Total Refills:"
          value={prescription.refills}
        />
        <Attribute
          label="Refills Remaining:"
          value={prescription.refillsRemaining}
        />
        <Attribute
          label="Total Quantity Remaining:"
          value={prescription.totalQuantityRemaining}
        />
        <Attribute
          label="Started:"
          value={dateTemplate(prescription.startAt)}
        />
        <Attribute
          label="Discontinued:"
          value={prescription.discontinuedAt ? dateTemplate(prescription.discontinuedAt) : null}
        />
        <Attribute
          label="Prescriber Name:"
          value={prescription.prescriberFullName}
        />
        <Attribute
          label="Prescriber ID:"
          value={prescription.prescriberIdNumber}
        />
        <Attribute
          label="Pharmacy:"
          value={pharmacyLink(prescription)}
        />
        <Attribute
          label="Previous Prescription:"
          value={prescriptionLink(prescription.previousPrescription)}
        />
        <Attribute
          label="Next Prescription:"
          value={prescriptionLink(prescription.nextPrescription)}
        />
        <Attribute
          label="Created:"
          value={dateTemplate(prescription.createdAt)}
        />
        <Attribute
          label="Updated:"
          value={dateTemplate(prescription.updatedAt)}
        />
        <Attribute
          label="Deleted:"
          value={prescription.deletedAt ? dateTemplate(prescription.deletedAt) : null}
        />
      </SuperAdminCard>
    </div>
  )
}

export default PrescriptionCard
