import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import FormWrapper from '@components/display/Form/FormWrapper'
import { MultiSelect } from 'primereact/multiselect'
import { Dropdown } from 'primereact/dropdown'
import moment from 'moment'
import { find, get, isEmpty } from 'lodash'
import { useCurrentOrganization } from '@components/App'
import { useCreateReport } from './reportsHooks'
import { usePatients } from '../Patients/patientHooks'
import { useSitesQuery } from '../Selects/SitesSelect/SitesSelectHooks'
import { generateAllMonths, generateYears } from '../../services/utils'
import { REPORT_TYPES } from './config'

function CreateReport({ useReportsHeader }) {
  const currentOrganization = useCurrentOrganization()
  const statusMessage = useRef(null)
  const { setHeader } = useReportsHeader()
  const navigate = useNavigate()

  const [reportType, setReportType] = useState('mar')
  const [selectedSites, setSelectedSites] = useState([])
  const [availablePatients, setAvailablePatients] = useState([])
  const [selectedPatients, setSelectedPatients] = useState([])
  const [selectedStartYear, setSelectedStartYear] = useState(moment().year())
  const [selectedStartMonth, setSelectedStartMonth] = useState(moment().month() + 1)
  const [selectedEndYear, setSelectedEndYear] = useState(moment().year())
  const [selectedEndMonth, setSelectedEndMonth] = useState(moment().month() + 1)
  const years = generateYears(2)
  const months = generateAllMonths()
  const now = moment()

  const availableStartMonths = months.filter((month) => (
    selectedStartYear === now.year()
      ? month.value <= now.month() + 1
      : true
  ))

  const availableEndMonths = months.filter((month) => {
    const sameYear = selectedEndYear === selectedStartYear
    const currentYear = selectedEndYear === now.year()

    // if start year is same as end year and current year is same as end year show monts
    // before current month but after start month
    if (sameYear && currentYear) {
      return month.value <= now.month() + 1 && month.value >= selectedStartMonth
    }
    // if start year is same as end year and current year is not same as end year show months
    // after start month
    if (sameYear && !currentYear) {
      return month.value >= selectedStartMonth
    }
    return true
  })

  const availableEndYears = years.filter((year) => (
    selectedStartYear <= year
  ))

  const {
    mutateAsync: createReport,
    isLoading: isCreateLoading,
  } = useCreateReport(statusMessage)

  const {
    data: { sites } = [],
    isLoading: sitesLoading,
  } = useSitesQuery({ statusMessage, organizationId: get(currentOrganization, 'id') })

  const {
    data: patients,
    isLoading: patientsLoading,
  } = usePatients(get(currentOrganization, 'id'), statusMessage)

  const handleCreate = async () => {
    await createReport({
      reportType,
      organizationId: currentOrganization.id,
      siteIds: selectedSites,
      patientIds: selectedPatients,
      startMonth: selectedStartMonth,
      startYear: selectedStartYear,
      endMonth: selectedEndMonth,
      endYear: selectedEndYear,
    }, {
      onSuccess: () => {
        navigate('../')
      },
    })
  }

  const title = {
    label: (
      <span>
        <i className="text-xl pi pi-users mr-2" />
        Reports
      </span>
    ),
    linkTo: '/admin/reports',
  }

  const breadcrumbs = [
    {
      label: 'Create',
      template: <span>Create</span>,
    },
  ]

  useEffect(() => (
    setHeader({ title, breadcrumbs, primaryAction: null })
  ), [])

  const setPatientOptions = (selectedSiteIds, selectedSiteObjects) => {
    // the avaialble patients are the patients that are in the selected sites
    // if the site is empty, don't show it because there is a bug in the multiselect
    // when the first group is empty (site with no patients) the select all button
    // doesn't work
    const patientsbySite = selectedSiteObjects.map((site) => ({
      label: site.name,
      code: site.id,
      items: patients.filter((patient) => patient.site?.id === site.id),
    })).filter((site) => !isEmpty(site.items))
    setAvailablePatients(patientsbySite)

    // default is all patients selected
    const sitesToAdd = selectedSiteIds.filter((id) => !selectedSites.includes(id))
    setSelectedSites(selectedSiteIds)
    const newPatients = patients.filter((patient) => (
      sitesToAdd.includes(patient.site.id)
    ))

    // remove patients where are not in available patients
    const filteredPatients = selectedPatients.filter((patient) => (
      patientsbySite.map((p) => p.id).includes(patient.id)
    ))

    setSelectedPatients([...filteredPatients, ...newPatients.map((p) => p.id)])
  }

  const onSiteSelect = (e) => {
    const selectedSiteIds = e.value
    const selectedSiteObjects = selectedSiteIds.map((id) => find(sites, { id }))

    setPatientOptions(selectedSiteIds, selectedSiteObjects)
  }

  return (
    <div className="flex flex-row flex-wrap">
      <Toast ref={statusMessage} />
      <div className="col-8 xl:col-6">
        <FormWrapper title="Create Report" fullHeight>
          <div className="field">
            <label className="block" htmlFor="reportType">Report Type</label>
            <Dropdown
              value={reportType}
              options={REPORT_TYPES}
              onChange={(e) => setReportType(e.value)}
              optionLabel="label"
              optionValue="value"
              placeholder="Select a type"
              className="w-12"
            />
          </div>
          <div className="field">
            <label className="block" htmlFor="sites">Sites</label>
            <MultiSelect
              value={selectedSites}
              options={sites}
              onChange={onSiteSelect}
              placeholder="Select Sites"
              className="w-12"
              optionLabel="name"
              optionValue="id"
              disabled={sitesLoading}
              filter
              filterBy="name"
              filterPlaceholder="Search by name"
            />
          </div>
          {reportType !== 'barcode_scans' && (
            <div className="field">
              <label className="block" htmlFor="patients">{currentOrganization.patientLabelPlural}</label>
              <MultiSelect
                value={selectedPatients}
                options={availablePatients}
                onChange={(e) => setSelectedPatients(e.value)}
                placeholder={`Select ${currentOrganization.patientLabelPlural}`}
                className="w-12"
                optionLabel="fullName"
                optionValue="id"
                disabled={patientsLoading || sitesLoading || !selectedSites.length}
                filter
                filterBy="name"
                filterPlaceholder="Search by name"
                optionGroupLabel="label"
                optionGroupChildren="items"
                showSelectAll
              />
            </div>
          )}
          <div className="field">
            <label className="block" htmlFor="sites">Date</label>
            <span className="block text-sm text-gray-500 mb-2">Start</span>
            <div className="flex flex-row gap-2">
              <Dropdown
                value={selectedStartMonth}
                options={availableStartMonths}
                onChange={(e) => setSelectedStartMonth(e.value)}
                placeholder="Select a Month"
                className="w-6"
              />
              <Dropdown
                value={selectedStartYear}
                options={years}
                onChange={(e) => setSelectedStartYear(e.value)}
                placeholder="Select a Year"
                className="w-6"
              />
            </div>
            <span className="block text-sm text-gray-500 mb-2 mt-2">End</span>
            <div className="flex flex-row gap-2">
              <Dropdown
                value={selectedEndMonth}
                options={availableEndMonths}
                onChange={(e) => setSelectedEndMonth(e.value)}
                placeholder="Select a Month"
                className="w-6"
              />
              <Dropdown
                value={selectedEndYear}
                options={availableEndYears}
                onChange={(e) => setSelectedEndYear(e.value)}
                placeholder="Select a Year"
                className="w-6"
              />
            </div>
          </div>
          <Button
            label="Create Report"
            className="p-button-sm mt-4"
            loading={isCreateLoading}
            onClick={handleCreate}
            disabled={reportType === 'barcode_scans' ? isEmpty(selectedSites) : isEmpty(selectedPatients)}
          />
        </FormWrapper>
      </div>
    </div>
  )
}

export default CreateReport
