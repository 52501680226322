import React from 'react'
import {
  Route, Routes, useParams, useNavigate,
} from 'react-router-dom'
import { Skeleton } from 'primereact/skeleton'
import PharmacyDetails from '@components/pharmacies/PharmacyDetails'
import { usePharmacyQuery } from './pharmaciesHooks'
import UpdatePharmacy from './UpdatePharmacy'

function Pharmacy({ isCurrentUserSuperadmin, rootPath, usePharmaciesHeader }) {
  const navigate = useNavigate()

  if (!isCurrentUserSuperadmin) {
    navigate(rootPath)
  }

  const { pharmacyId } = useParams()
  const { maxDataViewHeight, setHeader } = usePharmaciesHeader()
  const { data: pharmacy, isLoading } = usePharmacyQuery(pharmacyId)

  const title = {
    label: (
      <div className="flex flex-row gap-4 px-4 pb-2 pt-2 align-items-center">
        <span className="flex flex-row align-items-center" id="pharmacy-name-tab">
          <i className="text-xl pi pi-user mr-2" />
          {isLoading && (
            <Skeleton className="inline-flex" width="10rem" height="1.5rem" />
          )}
          {!isLoading && (
            <span className="fadein">
                {pharmacy.name}
            </span>
          )}
        </span>
      </div>
    ),
  }

  const tabs = []

  const breadcrumbs = [
    {
      label: 'Pharmacies',
      template: <span>Pharmacy</span>,
    },
  ]

  const handleSetHeader = (props = {}) => {
    setHeader({
      title, tabs, breadcrumbs, ...props,
    })
  }

  return (
    <Routes>
      <Route
        index
        element={(
          <PharmacyDetails
            maxDataViewHeight={maxDataViewHeight}
            rootPath={rootPath}
            pharmacyId={pharmacyId}
            pharmacy={pharmacy}
            pharmacyLoading={isLoading}
            handleSetHeader={handleSetHeader}
          />
        )}
      />
      <Route
        path="update"
        element={(
          <UpdatePharmacy
            isCurrentUserSuperadmin={isCurrentUserSuperadmin}
            rootPath={rootPath}
            pharmacy={pharmacy}
            handleSetHeader={handleSetHeader}
          />
        )}
      />
    </Routes>
  )
}

export default Pharmacy
