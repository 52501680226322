import ApiClient from '@services/ApiClient'
import { get } from 'lodash'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'

export function useAvailableSiteDoses(siteId, statusMessage) {
  return useQuery({
    queryKey: ['availableSiteDoses', siteId],
    queryFn: () => ApiClient.get(`/sites/${siteId}/available_doses`),
    onError: () => statusMessage.current.show([{
      severity: 'error',
      sticky: true,
      summary: 'Unable to load available doses at this time.',
    }]),
  })
}

export function useLastUsedDoseFillQuery(doseId) {
  return useQuery({
    queryKey: ['lastUsedDoseFill', doseId],
    queryFn: () => ApiClient.get(`/doses/${doseId}/last_used_dose_fill`),
    enabled: !!doseId,
  })
}

export function useArchiveDoseFillMutation(ekitId, statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['ekit', ekitId],
    mutationFn: (doseFillId) => ApiClient.put(`/dose_fills/${doseFillId}`, { archived_at: new Date() }),
    onError: (error) => statusMessage.current.show([{
      severity: 'error',
      sticky: true,
      summary: 'Dose Deletion Error: ',
      detail: get(error, 'response.data.error'),
    }]),
    onSuccess: () => {
      queryClient.invalidateQueries(['ekit'])
    },
  })
}

export function useDrawerAssignmentMutation(ekitId, statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['ekit', ekitId],
    mutationFn: (assignment) => ApiClient.post(`/simpills/${ekitId}/assign_ekit_drawer`, assignment),
    onError: (error) => statusMessage.current.show([{
      severity: 'error',
      sticky: true,
      summary: 'Dose Assignment Error: ',
      detail: get(error, 'response.data.error'),
    }]),
    onSuccess: () => {
      queryClient.invalidateQueries(['ekit'])
    },
  })
}

function useSlotAssignmentMutation(ekitId, statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['ekit', ekitId],
    mutationFn: (assignment) => ApiClient.post(`/simpills/${ekitId}/assign_slot`, assignment),
    onError: (error) => statusMessage.current.show([{
      severity: 'error',
      sticky: true,
      summary: 'Dose Assignment Error: ',
      detail: get(error, 'response.data.error'),
    }]),
    onSuccess: () => {
      queryClient.invalidateQueries(['ekit'])
    },
  })
}

export function useAssignmentMutation(ekitId, slot, statusMessage) {
  if (slot) {
    return useSlotAssignmentMutation(ekitId, statusMessage)
  }
  return useDrawerAssignmentMutation(ekitId, statusMessage)
}
