/**
 * Get the treatment route based on the context.
 *
 * @param {string} patientId - The ID of the patient.
 * @param {string} treatmentId - The ID of the treatment.
 * @param {string} context - The context, either 'manage' or 'review'.
 * @returns {string | null} The treatment route.
 */
// eslint-disable-next-line import/prefer-default-export
export const getTreatmentRoute = (patientId, treatmentId, context) => {
  if (!patientId || !treatmentId || !context) {
    return null
  }

  if (!['manage', 'review'].includes(context)) {
    return null
  }

  return `/admin/patients/${patientId}/treatments/${context}/${treatmentId}`
}
