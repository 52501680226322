import { useMemo } from 'react'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { map, get } from 'lodash'
import {
  errorMessageFromError,
  formErrorsFromError,
} from '@services/errorHandler'

export function usePharmacistsQuery(statusMessage) {
  const query = useQuery({
    queryKey: ['pharmacists'],
    queryFn: () => ApiClient.get('/pharmacists'),
    onError: (error) => {
      statusMessage.current.clear()
      statusMessage.current.show([
        errorMessageFromError(error),
        ...formErrorsFromError(error),
      ])
    },
  })

  return useMemo(() => ({
    ...query,
    data: map(get(query, 'data.pharmacists'), (pharmacist) => (pharmacist)),
  }), [query.data])
}

export function usePharmacistQuery(pharmacistId, statusMessage) {
  return useQuery({
    queryKey: ['pharmacist', pharmacistId],
    queryFn: () => ApiClient.get(`/pharmacists/${pharmacistId}`),
    onError: () => statusMessage.current.show([
      {
        severity: 'error',
        sticky: true,
        summary: 'Unable to fetch pharmacist at this time.',
      },
    ]),
  })
}

export function usePharmacistCreateMutation(statusMessage) {
  return useMutation({
    mutationKey: ['pharmacistCreate'],
    mutationFn: (pharmacist) => ApiClient.post('/pharmacists', pharmacist),
    onError: async (error) => {
      if (!statusMessage) return
      await statusMessage.current.clear()
      statusMessage.current.show([
        errorMessageFromError(error),
      ])
    },
  })
}

export function usePharmacistUpdateMutation(pharmacistId, statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['pharmacistUpdate', pharmacistId],
    mutationFn: (pharmacist) => ApiClient.put(`/pharmacists/${pharmacistId}`, pharmacist),
    onError: async (error) => {
      if (!statusMessage) return
      await statusMessage.current.clear()
      statusMessage.current.show([
        errorMessageFromError(error),
      ])
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['pharmacist', pharmacistId])
    },
  })
}

export function usePharmacistDelete(statusMessage) {
  return useMutation({
    mutationKey: ['pharmacistDelete'],
    mutationFn: (pharmacistId) => ApiClient.delete(`/pharmacists/${pharmacistId}`),
    onError: () => {
      if (!statusMessage) return
      statusMessage.current.show([
        {
          severity: 'error',
          sticky: true,
          summary: 'Unable to delete pharmacist at this time.',
        },
      ])
    },
  })
}
