import React from 'react'
import { get } from 'lodash'

import AttributeCard from '@components/display/AttributeCard'
import { EditButton, DoseAttributeRow, InputTextEditor } from '@components/DoseAttributeRow'

function PrescribingPhysician({
  dose, editAttribute, setEditAttribute, isLoading, handleUpdateAttribute,
}) {
  const physicianAttributes = [
    {
      label: 'Name',
      currentValue: get(dose, 'physicianName'),
      action: <EditButton
        onClick={() => (editAttribute === 'physicianName' ? setEditAttribute(null) : setEditAttribute('physicianName'))}
        isEditing={editAttribute === 'physicianName'}
        loading={isLoading}
        disabled={editAttribute && editAttribute !== 'physicianName'}
      />,
      isEditing: editAttribute === 'physicianName',
      editor: <InputTextEditor
        text={get(dose, 'physicianName')}
        setText={(value) => handleUpdateAttribute('physicianName', value)}
        isLoading={isLoading}
      />,
    },
    {
      label: 'Phone',
      currentValue: get(dose, 'physicianPhone'),
      action: <EditButton
        onClick={() => (editAttribute === 'physicianPhone' ? setEditAttribute(null) : setEditAttribute('physicianPhone'))}
        isEditing={editAttribute === 'physicianPhone'}
        loading={isLoading}
        disabled={editAttribute && editAttribute !== 'physicianPhone'}
      />,
      isEditing: editAttribute === 'physicianPhone',
      editor: <InputTextEditor
        text={get(dose, 'physicianPhone')}
        setText={(value) => handleUpdateAttribute('physicianPhone', value)}
        isLoading={isLoading}
      />,
    },
  ]

  return (
    <AttributeCard title={{ label: 'Prescribing Physician' }}>
      <ul className="list-none p-0 m-0 dose-order-fields">
        {physicianAttributes
          .filter(({ display = true }) => display)
          .map(({
            label, currentValue, newValue, action, valueChangeHandler, editor, isEditing,
          }, index) => (
            <DoseAttributeRow
              key={label}
              label={label}
              valueChangeHandler={valueChangeHandler}
              currentValue={currentValue}
              newValue={newValue}
              action={action}
              editor={editor}
              isEditing={isEditing}
              backgroundHighlight={index % 2 === 0}
            />
          ))}
      </ul>
    </AttributeCard>
  )
}

export default PrescribingPhysician
