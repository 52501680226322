import { Button } from 'primereact/button'
import { classNames } from 'primereact/utils'
import React from 'react'

/**
 * Tab button for resorce type menu (e.g. Patients -> Active, Deactivated).
 *
 * @param {object} props - The component's props
 * @param {string} props.label - The tab's label.
 * @param {boolean} props.active - Whether the tab is active.
 * @param {Function} props.onClick - The function to call when the tab is clicked.
 * @returns {React.Component} The tab.
 */
function MenuTab({
  label, active, onClick, ...rest
}) {
  return (
    <div className="flex align-items-center gap-2 pr-3 py-2" style={{ cursor: 'pointer' }} onClick={onClick}>
      <Button
        className={
          classNames('flex flex-row align-items-baseline p-button-sm font-light', {
            'p-button-text text-gray-500': !active,
            'p-button-primary p-button-outlined': active,
          })
        }
        label={label}
        {...rest}
      />
    </div>
  )
}

export default MenuTab
