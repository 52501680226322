import React from 'react'
import { Button } from 'primereact/button'
import { useAuth } from '../../../context/AuthContext'

function LoginButton({
  onUsernamePasswordLogin, onEmailLogin, isLoading,
}) {
  const {
    loginType, eulaAccepted, username, password, email,
  } = useAuth()

  return (
    <div className="field text-center">
      <Button
        className="flex flex-row justify-content-center gap-2 p-button-filled w-full rounded login-button-otp"
        rounded
        disabled={
          isLoading
          || !eulaAccepted
          || (loginType === 'Username'
            && (username.length === 0 || password.length === 0))
          || (loginType === 'Email' && email.length === 0)
        }
        onClick={() => {
          if (loginType === 'Username') {
            onUsernamePasswordLogin(username, password)
          } else {
            onEmailLogin(email)
          }
        }}
      >
        {isLoading && <i className="pi pi-spin pi-spinner mr-2" />}
        <div className="flex justify-content-center align-items-center flex-grow-1">
          <i className="pi pi-envelope font-medium" />
          <span className="ml-2 font-medium">{loginType === 'Email' ? 'Send Login Link' : 'Login'}</span>
        </div>
      </Button>
    </div>
  )
}

export default LoginButton
