import React from 'react'
import DropdownWithLabel from '@components/display/DropdownWithLabel'
import InputTextWithLabel from '@components/display/InputTextWithLabel'

function Details({ details = {}, setDetails, showScimDetails = false }) {
  const {
    name, address, isScimManaged, scimGroup,
  } = details

  return (
    <div className="flex flex-column">
      <InputTextWithLabel
        label="Name"
        value={name || ''}
        inputId="name"
        name="name"
        onChange={({ target: { value } }) => setDetails({ name: value })}
      />
      <InputTextWithLabel
        label="Address"
        value={address || ''}
        inputId="address"
        name="address"
        onChange={({ target: { value } }) => setDetails({ address: value })}
      />
      {
        showScimDetails && (
          <>
            <DropdownWithLabel
              inputId="isScimManaged"
              label="SCIM Managed"
              onChange={({ target: { value } }) => setDetails({ isScimManaged: value })}
              type="yes-no"
              value={isScimManaged || false}
            />
            <InputTextWithLabel
              inputId="scimGroup"
              label="SCIM Group"
              name="scimGroup"
              onChange={({ target: { value } }) => setDetails({ scimGroup: value })}
              value={scimGroup || ''}
            />
          </>
        )
      }
    </div>
  )
}

export default Details
