import React from 'react'
import { Dropdown } from 'primereact/dropdown'
import { map } from 'lodash'
import { ALLERGEN_TYPE_LABELS } from './config'

function AllergenTypeDropdown({ value, setValue }) {
  const allergenTypes = map(ALLERGEN_TYPE_LABELS, (description, type) => ({
    value: type,
    label: description,
  }))

  return (
    <Dropdown
      value={value}
      options={allergenTypes}
      onChange={(e) => setValue(e.value)}
      placeholder="Select a type"
      className="w-12"
    />
  )
}

export default AllergenTypeDropdown
