import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { useMemo } from 'react'
import { map } from 'lodash'

export const useNotificationSettings = (organizationId, currentUser) => {
  const query = useQuery({
    queryKey: ['notificationSettings', organizationId],
    queryFn: () => ApiClient.get(`/organizations/${organizationId}/notification_settings`),
    enabled: currentUser.role !== 'caregiver',
  })

  return useMemo(() => (
    {
      ...query,
      data: map(query.data, (data) => data),
    }
  ), [query.data])
}

export default useNotificationSettings
