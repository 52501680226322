import React, { useState } from 'react'
import moment from 'moment'
import { get, isEmpty, pick } from 'lodash'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { useCurrentOrganization } from '@components/App'
import TimeSelector from './TimeSelector'
import ConsumptionThresholds from '../ConsumptionThresholds'
import SchedulePattern from '../SchedulePattern'

import './time-selection.scss'

function TimeSelectionDialog({
  render,
  clientId,
  dose,
  doseSig,
  handleCreateSchedule,
  handleUpdateSchedule,
  handleDestroySchedule,
  schedulableType,
}) {
  const organization = useCurrentOrganization()
  const [timePickerVisible, setTimePickerVisible] = useState(false)
  const [selectedSchedule, setSelectedSchedule] = useState()
  const [selectedTime, setSelectedTime] = useState()
  const [flexibility, setFlexibility] = useState({})
  const [scheduleRrule, setScheduleRrule] = useState()

  const handleEditorShow = (schedule) => {
    // TODO: Refactor component and schedule data flow to cleanly handle type collisions between
    //       schedule formats. This will be easier to do when we introduce TypeScript.
    const scheduleTime = moment(schedule.momentTime || schedule.time)
    scheduleTime.set({
      hour: scheduleTime.hours(),
      minute: scheduleTime.minutes(),
      second: 0,
      millisecond: 0,
    })

    if (schedule.eventTime) {
      setSelectedTime({ eventTimeId: schedule.eventTime.id, time: scheduleTime.valueOf() })
    } else {
      setSelectedTime({ time: scheduleTime.valueOf() })
    }

    setSelectedSchedule(schedule)
    setFlexibility(schedule.flexibility)
    setScheduleRrule(schedule.rrule)
    setTimePickerVisible(true)
  }

  const handleAddTime = () => {
    const defaultTime = moment()
    defaultTime.set({
      hour: 9,
      minute: 0,
      second: 0,
      millisecond: 0,
    })

    const orgFlexibility = pick(
      get(organization, 'flexibility'),
      ['maxStart', 'maxEnd', 'recommendedStart', 'recommendedEnd'],
    )

    setSelectedTime({ time: defaultTime.valueOf() })
    setFlexibility(orgFlexibility)
    setTimePickerVisible(true)
  }

  const handleEditorHide = () => {
    setTimePickerVisible(false)
    setSelectedTime(null)
    setSelectedSchedule()
    setFlexibility({})
    setScheduleRrule()
  }

  const onCreateSchedule = () => {
    const time = moment(selectedTime.time)
    const schedule = {
      momentTime: time,
      flexibilityAttributes: flexibility,
      rrule: scheduleRrule,
    }

    if (doseSig) {
      schedule.doseSigId = doseSig.id
    }

    if (selectedTime.eventTimeId) {
      schedule.eventTimeId = selectedTime.eventTimeId
      schedule.eventTimeDirective = 'start_time'
    } else {
      schedule.time_hh_mm = time.format('HH:mm')
    }

    handleCreateSchedule({ doseId: dose.id, schedule })
    handleEditorHide()
  }

  const onUpdateSchedule = () => {
    const time = moment(selectedTime.time)
    const schedule = {
      ...selectedSchedule,
      momentTime: time,
      flexibilityAttributes: flexibility,
      rrule: scheduleRrule,
    }

    if (selectedTime.eventTimeId) {
      schedule.eventTimeId = selectedTime.eventTimeId
      schedule.eventTimeDirective = 'start_time'
    } else {
      schedule.eventTimeId = null
      schedule.eventTimeDirective = null
      schedule.time_hh_mm = time.format('HH:mm')
    }

    handleUpdateSchedule({ doseId: dose.id, schedule })
    handleEditorHide()
  }

  const onDeleteSchedules = () => {
    handleDestroySchedule({
      doseId: dose.id,
      schedule: selectedSchedule,
      scheduleId: selectedSchedule.id,
    })
    handleEditorHide()
  }

  const dialogHeaderTemplate = () => {
    const title = schedulableType === 'Task'
      ? 'Task Completion Time'
      : 'Med Administration Time'

    return (
      <div className="flex flex-row align-items-baseline w-full justify-content-center">
        <h3 className="font-semibold text-lg flex flex-column gap-2">
          {title}
        </h3>
        {!isEmpty(selectedSchedule)
          && (
            <Button
              label="Delete"
              onClick={onDeleteSchedules}
              className="p-button-danger p-button-text p-button-sm"
            />
          )}
      </div>
    )
  }

  return (
    <>
      {render({ handleAddTime, handleEditorShow })}
      <Dialog
        visible={timePickerVisible}
        onHide={handleEditorHide}
        header={dialogHeaderTemplate}
      >
        <div className="flex flex-column gap-5 align-items-center px-2" style={{ width: '30rem' }}>
          <TimeSelector
            patientId={clientId}
            schedulableType={schedulableType}
            selectedTime={selectedTime}
            setSelectedTime={setSelectedTime}
          />
          <ConsumptionThresholds
            time={moment(selectedTime?.time).toDate()}
            flexibility={flexibility}
            schedulableType={schedulableType}
            setFlexibility={setFlexibility}
          />
          <SchedulePattern
            schedulableType={schedulableType}
            scheduleRrule={scheduleRrule}
            setScheduleRrule={setScheduleRrule}
          />
          <Button
            label="Confirm"
            className="flex w-full p-button-sm"
            disabled={!selectedTime?.time}
            onClick={selectedSchedule ? onUpdateSchedule : onCreateSchedule}
          />
        </div>
      </Dialog>
    </>
  )
}

export default TimeSelectionDialog
