import { useState, useEffect } from 'react'
import * as Sentry from '@sentry/react'

/**
 * A custom hook for reporting errors to Sentry.
 * @param {Array} dependencies - An array of dependencies that will dedupe the error reporting.
 * @returns {Function} reportSentryError - A function that reports a given error message to Sentry.
 */
export function useReportSentryError(dependencies) {
  const [errorReported, setErrorReported] = useState(false)

  useEffect(() => {
    setErrorReported(false)
  }, dependencies)

  return (errorMessage) => {
    if (!errorReported) {
      Sentry.captureException(new Error(errorMessage))
      setErrorReported(true)
    }
  }
}

export default useReportSentryError
