import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import FormWrapper from '@components/display/Form/FormWrapper'
import InputTextWithLabel from '@components/display/InputTextWithLabel'
import { Dropdown } from 'primereact/dropdown'
import { get } from 'lodash'
import { useCurrentOrganization } from '@components/App'
import { useCreateIntegrationFacilityMutation } from './facilitiesHooks'
import { usePharmaciesQuery } from '../Pharmacies/pharmaciesHooks'

function CreateFacility({ useFacilityHeader }) {
  const organization = useCurrentOrganization()
  const statusMessage = useRef(null)
  const navigate = useNavigate()
  const [externalId, setExternalId] = useState()
  const [pharmacy, setPharmacy] = useState()

  const { setHeader } = useFacilityHeader()

  const {
    mutateAsync: createIntegrationFacility,
    isLoading: isCreatingFacility,
  } = useCreateIntegrationFacilityMutation(statusMessage)

  const {
    data: pharmacyOptions,
    isLoading: isPharmacyOptionsLoading,
  } = usePharmaciesQuery()

  const handleCreateFacility = () => {
    createIntegrationFacility({
      externalId,
      pharmacyId: get(pharmacy, 'id'),
      organizationId: organization.id,
    }, {
      onSuccess: () => {
        navigate('/admin/facilities/')
      },
    })
  }

  const pageTitle = {
    label: (
      <span>
        <i className="text-xl pi pi-users mr-2" />
        Facility
      </span>
    ),
    linkTo: '/admin/facilities',
  }

  const breadcrumbs = [
    {
      label: 'Create',
      template: <span>Create</span>,
    },
  ]

  useEffect(() => {
    setHeader({ title: pageTitle, breadcrumbs, primaryAction: null })
  }, [])

  return (
    <div id="create-facility" className="flex flex-column">
      <div className="flex flex-row flex-wrap">
        <Toast ref={statusMessage} />
        <div className="col-6">
          <FormWrapper title="Profile" fullHeight>
            <InputTextWithLabel
              label="External ID"
              value={externalId || ''}
              inputId="externalId"
              name="externalId"
              tooltip="Comma separate external IDs to add multiple facilties in one shot."
              tooltipOptions={{ position: 'right' }}
              onChange={({ target: { value } }) => setExternalId(value)}
            />
            <div className="field">
              <label className="block" htmlFor="pharmacy">Pharmacy</label>
              <Dropdown
                inputId="pharmacy"
                value={pharmacy}
                options={pharmacyOptions}
                dataKey="id"
                optionLabel="name"
                itemTemplate={(option) => `${option.name} - ${option.externalId}`}
                valueTemplate={(option) => (option ? `${option.name} - ${option.externalId}` : 'Select Pharmacy')}
                onChange={(e) => setPharmacy(e.value)}
                placeholder="Select Pharmacy"
                loading={isPharmacyOptionsLoading || isCreatingFacility}
                disabled={isCreatingFacility}
                className="w-full"
                filter
              />
            </div>
            <Button
              label="Save"
              className="p-button-sm m-2"
              loading={isCreatingFacility}
              onClick={handleCreateFacility}
              disabled={!externalId || !pharmacy}
            />
          </FormWrapper>
        </div>
      </div>
    </div>
  )
}

export default CreateFacility
