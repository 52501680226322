import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'

import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import {
  capitalize, map,
} from 'lodash'
import { Dropdown } from 'primereact/dropdown'
import { Messages } from 'primereact/messages'
import { formattedTextsFromSchedules, medicineDisplayName } from '@components/clientDoses/doseUtils'
import { usePatientTherapDoses, useUpsertDose } from '../ClientDoses/clientDosesHooks'
import { useTherapExportMutation } from './patientHooks'

function ScheduleTemplate({ schedules }) {
  const formattedScheduleTexts = formattedTextsFromSchedules(schedules)

  return (
    <div className="flex flex-column gap-2">
      {
        formattedScheduleTexts.map((text) => (
          <span key={text}>
            {capitalize(text)}
          </span>
        ))
      }
    </div>
  )
}

function MedicationTypeTemplate({
  dose, onEdit, isUpdating,
}) {
  return (
    <Dropdown
      className="w-12rem"
      value={dose.therapSettings.medicationType}
      options={['Medication', 'Treatment']}
      loading={isUpdating}
      invalid={!dose.therapSettings.medicationType}
      onChange={(e) => {
        onEdit(dose, e.value)
      }}
    />
  )
}

function ScheduleIntervalTemplate({
  dose, onEdit, isUpdating,
}) {
  return (
    <Dropdown
      className="w-12rem"
      value={dose.therapSettings.scheduleInterval}
      invalid={!dose.therapSettings.scheduleInterval}
      loading={isUpdating}
      options={['Daily', 'Weekly', 'Monthly', 'PRN']}
      onChange={(e) => {
        onEdit(dose, e.value)
      }}
    />
  )
}

function Therap({ patient, handleSetHeader }) {
  const statusMessage = useRef()
  const {
    data: doses,
    isLoading: isLoadingDoses,
  } = usePatientTherapDoses(patient?.id)

  const {
    mutateAsync: therapExport,
    isLoading: isExporting,
  } = useTherapExportMutation(statusMessage, patient?.id)

  const {
    mutateAsync: updateDose,
  } = useUpsertDose({
    patientId: patient?.id,
    statusMessage,
    isUpdate: true,
  })

  const [selectedDoses, setSelectedDoses] = useState([])
  const [updatingDoseId, setUpdatingDoseId] = useState()

  useEffect(() => {
    handleSetHeader({ primaryAction: null })
  }, [patient])

  useEffect(() => {
    if (doses) {
      setSelectedDoses(doses)
    }
  }, [doses])

  if (!patient) return null

  const handleExport = async () => {
    await therapExport(map(selectedDoses.map((dose) => dose.id)))
  }

  const handleUpdateMedicationType = async (dose, medicationType) => {
    setUpdatingDoseId(dose.id)
    await updateDose({
      id: dose.id,
      therapSettings: { ...dose.therapSettings, medicationType },
    })
    setUpdatingDoseId(null)
  }

  const handleUpdateScheduleInterval = async (dose, scheduleInterval) => {
    setUpdatingDoseId(dose.id)
    await updateDose({
      id: dose.id,
      therapSettings: { ...dose.therapSettings, scheduleInterval },
    })
    setUpdatingDoseId(null)
  }

  const hasWarnings = selectedDoses.some(
    (dose) => !dose.therapSettings.medicationType || !dose.therapSettings.scheduleInterval,
  )

  const renderMedicationType = (dose) => (
    <MedicationTypeTemplate
      dose={dose}
      onEdit={handleUpdateMedicationType}
      isUpdating={updatingDoseId === dose.id}
    />
  )

  const renderScheduleInterval = (dose) => (
    <ScheduleIntervalTemplate
      dose={dose}
      onEdit={handleUpdateScheduleInterval}
      isUpdating={updatingDoseId === dose.id}
    />
  )

  const renderSchedule = (dose) => {
    if (dose.prn) {
      return 'PRN'
    }
    return (
      <ScheduleTemplate schedules={dose.schedules} />
    )
  }

  return (
    <div className="col-12">
      <Card className="h-full">
        <Messages ref={statusMessage} />
        <div className="flex flex-column gap-3 text-base text-900">
          <div className="flex flex-row justify-content-end">
            <Button
              className="p-button-sm"
              label="Export"
              tooltip={hasWarnings ? 'You need to select all medication types and schedule intervals to export.' : ''}
              tooltipOptions={{ position: 'top', showOnDisabled: true }}
              onClick={() => handleExport()}
              loading={isExporting}
              disabled={hasWarnings || selectedDoses.length === 0}
            />
          </div>
          <DataTable
            value={doses}
            selection={selectedDoses}
            onSelectionChange={(e) => setSelectedDoses(e.value)}
            selectionMode="checkbox"
            sortField="id"
            sortOrder={1}
            dataKey="id"
            loading={isLoadingDoses}
            className="p-datatable-sm"
          >
            <Column selectionMode="multiple" style={{ width: '3rem' }} />
            <Column header="Medicine" body={(dose) => medicineDisplayName(dose)} sortable sortField="medicineName" />
            <Column
              header="Medication Type"
              body={renderMedicationType}
            />
            <Column header="Schedules" body={renderSchedule} />
            <Column
              header="Schedule Interval"
              body={renderScheduleInterval}
            />
          </DataTable>
        </div>
      </Card>
    </div>
  )
}

export default Therap
