import React from 'react'
import { Tooltip } from 'primereact/tooltip'
import { useEllipsisTooltip } from '@services/hooks'

function TextWithTooltip({ text, linkTo }) {
  const [spanRef, isOverflowing] = useEllipsisTooltip()

  const content = linkTo ? (
    <a href={linkTo}>{text}</a>
  ) : text

  return (
    <>
      <span
        ref={spanRef}
        className="text-overflow-ellipsis white-space-nowrap overflow-hidden line-height-3"
        data-pr-tooltip={isOverflowing ? text : null}
      >
        {content}
      </span>
      <Tooltip target=".text-overflow-ellipsis" />
    </>
  )
}

export default TextWithTooltip
