import {
  useMemo, useState, useRef, useCallback,
} from 'react'
import {
  get, map, startCase, camelCase,
} from 'lodash'
import ApiClient from '@services/ApiClient'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { useCurrentUser } from '@components/App'
import { getWorkOSAdminPortalLink, updateWorkOSOrgId } from './orgSettingsUtils'

export function useSettingsQuery(organizationId, statusMessage) {
  const query = useQuery({
    queryKey: ['settings', organizationId],
    enabled: !!organizationId,
    queryFn: () => ApiClient.get(`/organizations/${organizationId}/system_settings`),
    onError: () => statusMessage.current.show([
      { severity: 'error', summary: 'Unable to fetch organization settings at this time.' },
    ]),
  })

  return query
}

export function useSettingsMutation(organizationId, statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['settings'],
    mutationFn: (settings) => ApiClient.put(`/organizations/${organizationId}`, settings),
    onSuccess: () => {
      queryClient.invalidateQueries(['settings'])
      statusMessage.current.show([
        { severity: 'success', summary: 'Settings Saved!' },
      ])
    },
    onError: (error) => {
      const formErrors = get(error, 'response.data.form_errors', {})
      if (formErrors) {
        statusMessage.current.show(
          Object.entries(formErrors).map(([key, value]) => ({
            id: key,
            severity: 'error',
            summary: `${startCase(camelCase(key))}: ${value}`,
            sticky: true,
          })),
        )
      } else {
        statusMessage.current.show([{
          severity: 'error',
          summary: `Error: ${error.message}`,
          sticky: true,
        },
        ])
      }
    },
  })
}

export function useDisabledNarcoticCounts(organizationId) {
  const query = useQuery({
    queryKey: ['disabledNarcoticCounts', organizationId],
    queryFn: () => ApiClient.get(`/organizations/${organizationId}/disabled_narcotic_counts`),
  })

  return useMemo(() => ({
    ...query,
    isLoading: query.isLoading,
    data: map(get(query, 'data.disabledNarcoticCounts'), (data) => (data)),
  }), [query.data])
}

export function useRemoveDisabledNarcoticCount(organizationId) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['removeDisabledNarcoticCount', organizationId],
    mutationFn: (settingId) => ApiClient.delete(`/disabled_narcotic_counts/${settingId}`),
    onSuccess: () => {
      queryClient.invalidateQueries('disabledNarcoticCounts', organizationId)
    },
  })
}

export function useIAM(organization, workosOrganizationId, statusMessage) {
  const [loadingIntent, setLoadingIntent] = useState(null)
  const [inputWorkosOrgId, setInputWorkosOrgId] = useState('')
  const [error, setError] = useState('')
  const workosOrgIdRef = useRef(null)

  const { role } = useCurrentUser()
  const queryClient = useQueryClient()

  const orgId = organization.id
  const workosOrgId = workosOrganizationId?.value
  const isWorkosOrgIdSet = !!workosOrgId

  const handleUpdateWorkOSOrgId = useCallback(async () => {
    if (!inputWorkosOrgId) {
      setError('WorkOS organization id is required.')
      workosOrgIdRef.current.focus()
      return
    }
    setError('')
    const { error: err } = await updateWorkOSOrgId(orgId, inputWorkosOrgId)
    if (!err) {
      queryClient.invalidateQueries(['settings'])
      setInputWorkosOrgId('')
    } else {
      setError(err)
    }
  }, [orgId, inputWorkosOrgId])

  const handleRedirectToWorkOSAdminPortal = useCallback(async (intent) => {
    setLoadingIntent(intent)
    const {
      adminPortalLink = null,
    } = await getWorkOSAdminPortalLink(orgId, workosOrgId, intent, statusMessage)

    if (adminPortalLink) {
      window.location.href = adminPortalLink
    }
    setLoadingIntent(null)
  }, [orgId, workosOrgId, inputWorkosOrgId])

  return {
    isSuperAdmin: role === 'superadmin',
    isWorkosOrgIdSet,
    inputWorkosOrgId,
    setInputWorkosOrgId,
    error,
    loadingIntent,
    workosOrgIdRef,
    handleUpdateWorkOSOrgId,
    handleRedirectToWorkOSAdminPortal,
  }
}
