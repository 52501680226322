import React, { useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { Menu } from 'primereact/menu'
import PRNDialog from '@components/clientDoses/Schedule/PRNDialog'

// TODO: Consider adding menu items for sig-specific pausing, unpausing, MAR export, and soft delete
function SigActionMenu({ sig, handleSetPrn, isLoading }) {
  const actionMenu = useRef(null)
  const [prnDialogVisible, setPrnDialogVisible] = useState(false)

  const prnControls = ({ handleShowDialog }) => (
    <Button
      label={sig.prn ? 'Change to schedule' : 'Change to PRN'}
      icon="fa-solid fa-triangle-exclamation"
      className="p-button-sm p-button-warning p-button-text w-full"
      onClick={handleShowDialog}
    />
  )

  const sigActions = () => {
    const actions = []

    if (typeof sig.prn === 'boolean') {
      actions.push({
        template: prnControls({ handleShowDialog: () => setPrnDialogVisible(true) }),
      })
    }

    return actions
  }

  return (
    <div className="ml-auto">
      {
        handleSetPrn && (
          <PRNDialog
            isVisible={prnDialogVisible}
            setIsVisible={setPrnDialogVisible}
            handlePrnChange={handleSetPrn}
            isLoading={isLoading}
            toPrnValue={!sig.prn}
            schedulableType="DoseSig"
          />
        )
      }
      <Menu model={sigActions()} popup ref={actionMenu} id="popup_menu" />
      <Button
        label="Actions"
        icon="pi pi-chevron-down"
        className="p-button-sm"
        iconPos="right"
        aria-controls="popup_menu"
        aria-haspopup
        onClick={(event) => actionMenu.current.toggle(event)}
      />
    </div>
  )
}

export default SigActionMenu
