import React from 'react'
import { Route, Routes, useParams } from 'react-router-dom'
import Exports from '@components/patients/Exports'
import Redirect from '@components/routing/Redirect'
import CreateTaskPage from './CreateTaskPage'
import ManageTaskPage from './ManageTaskPage'
import TasksOverviewPage from './TasksOverviewPage'
import './client-tasks-styles.scss'

function ClientTasks({
  patient, handleSetHeader, maxDataViewHeight, layoutRef,
}) {
  const { patientId: clientId } = useParams()

  const primaryActionSetup = (action) => {
    handleSetHeader({ primaryAction: action })
  }

  return (
    <>
      <Exports
        patientId={clientId}
        exportsMenuSetupCallBack={primaryActionSetup}
      />
      <Routes>
        <Route index element={<Redirect to="all" />} />
        <Route
          path="create"
          element={(
            <CreateTaskPage
              clientId={clientId}
              handleSetHeader={handleSetHeader}
              layoutRef={layoutRef}
            />
          )}
        />
        <Route
          path="manage/:taskId"
          element={(
            <ManageTaskPage
              clientId={clientId}
              patient={patient}
              handleSetHeader={handleSetHeader}
            />
          )}
        />
        <Route
          path="/*"
          element={(
            <TasksOverviewPage
              clientId={clientId}
              patient={patient}
              maxDataViewHeight={maxDataViewHeight}
              handleSetHeader={handleSetHeader}
            />
          )}
        />
      </Routes>
    </>
  )
}

export default ClientTasks
