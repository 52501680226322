/**
 * Returns the contrast color (black or white) based on the brightness of the given hex color
 *
 * @param {string} hex
 * @return {string} The contrast color
 */
const contrastColor = (hex) => {
  const r = parseInt(hex.substr(1, 2), 16)
  const g = parseInt(hex.substr(3, 2), 16)
  const b = parseInt(hex.substr(5, 2), 16)
  const brightness = (r * 299 + g * 587 + b * 114) / 1000
  return brightness > 125 ? '#000' : '#fff'
}

// eslint-disable-next-line import/prefer-default-export
export { contrastColor }
