import { filter, split } from 'lodash'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { errorMessageFromError, formErrorsFromError } from '@services/errorHandler'

export function useCreateSchedule(statusMessage) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['createSchedule'],
    mutationFn: ({ schedule }) => ApiClient.post('/schedules', {
      schedule: {
        ...schedule,
        // The rrule.js library converts rrules to string in the format:
        //  "RRULE:FREQ=WEEKLY;INTERVAL=1;BYDAY=WE,FR"
        // The following line extracts the rule itself, omitting the "RRULE:"
        // so that it is compatible with API validations.
        rrule: filter(split(schedule.rrule, ':'), (rule) => rule !== 'RRULE').join(';'),
      },
    }),
    onSuccess: () => {
      statusMessage.current.show([
        { severity: 'success', summary: 'Schedule Created!' },
      ])
      queryClient.invalidateQueries('patientDose')
      queryClient.invalidateQueries('schedule')
      queryClient.invalidateQueries('task')
    },
    onError: (error) => {
      statusMessage.current.show([
        errorMessageFromError(error, 'Unable to create schedule at this time.'),
        ...formErrorsFromError(error),
      ])
    },
  })
}

export default useCreateSchedule
