import React from 'react'
import { Divider } from 'primereact/divider'
import { InfoTooltip } from '@components/display'

// TODO: I think it would look nicer to replace this with an AttributeCard, but would need
//       to refactor ClientDoses since the tab content currently resides in a single card.
function Section({ children, header = 'Section', helpText }) {
  return (
    <div className="flex flex-column overflow-auto">
      <div className="px-4 py-2 flex flex-row align-items-center">
        <InfoTooltip target={header} text={helpText} />
        <span className="text-lg text-900">{header}</span>
      </div>
      <div className="px-4 flex flex-column">
        <Divider className="my-0" />
      </div>
      <div className="p-4 flex flex-column gap-4">
        {children}
      </div>
    </div>
  )
}

export default Section
