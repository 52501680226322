import React from 'react'

/**
 * A component that displays a signature in SVG format.
 * @param {Object} props
 * @param {string} props.signature - The SVG string of the signature.
 * @returns {JSX.Element}
 * @constructor
 * @module SignatureView
 * @example
 * <SignatureView signature="<svg>...</svg>" />
*/
function SignatureView({ signature }) {
  if (!signature) return null
  const svg = new DOMParser().parseFromString(signature, 'image/svg+xml').documentElement
  svg.setAttribute('preserveAspectRatio', 'xMidYMid meet')
  svg.setAttribute('class', 'signature')
  svg.setAttribute('style', 'width: 100%; height: 100%;')
  return (
    // eslint-disable-next-line react/no-danger
    <div style={{ margin: '0 auto', width: '100%', aspectRatio: '16/9' }} dangerouslySetInnerHTML={{ __html: svg.outerHTML }} />
  )
}

export default SignatureView
