import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Messages } from 'primereact/messages'
import { useCurrentOrganization } from '@components/App'
import PatientAvatarThumbnail from '@components/patients/PatientList/PatientAvatarThumbnail'
import { exactSearch } from '@services/utils/search'

function UnassignedPatients({
  statusMessage,
  patients,
  isFetching,
  searchTerm,
  setSearchLoading,
  maxDataViewHeight,
  setFooter,
  rootPath,
}) {
  const navigate = useNavigate()
  const organization = useCurrentOrganization()
  const [filteredPatients, setFilteredPatients] = useState([])

  useEffect(() => {
    setSearchLoading(true)
    if (searchTerm) {
      setFilteredPatients(exactSearch(patients, ['firstName', 'lastName'], searchTerm))
    } else {
      setFilteredPatients(patients)
    }
    setSearchLoading(false)
  }, [isFetching, searchTerm])

  const footTemplate = () => (
    <span className="text-sm ml-1 my-0 py-0">
      {!isFetching ? `${organization.patientLabelPlural} Count: ${filteredPatients.length}` : ''}
    </span>
  )

  useEffect(() => {
    setFooter(footTemplate)
  }, [filteredPatients])

  return (
    <div>
      <Messages ref={statusMessage} />
      <div className="flex flex-column gap-3 text-base text-900">
        <DataTable
          dataKey="id"
          loading={isFetching}
          value={filteredPatients}
          selectionMode="single"
          onSelectionChange={(e) => navigate(`${rootPath}/${e.value.id}`)}
          sortField="lastName"
          sortOrder={1}
          rowClassName="fadein patient-row"
          scrollable
          scrollHeight={maxDataViewHeight ? (maxDataViewHeight - 10) : null}
        >
          <Column headerStyle={{ width: '3rem' }} body={PatientAvatarThumbnail} />
          <Column header="First Name" field="firstName" />
          <Column header="Last Name" field="lastName" sortable />
          <Column header="Room #" field="roomNumber" sortable />
        </DataTable>
      </div>
    </div>
  )
}

export default UnassignedPatients
