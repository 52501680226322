import React from 'react'
import { InputTextarea } from 'primereact/inputtextarea'

function NoteInput({ label, note, setNote }) {
  return (
    <div className="flex flex-column gap-1">
      <label className="block" htmlFor="adherence-dialog-form-note">{label}</label>
      <InputTextarea
        id="adherence-dialog-form-note"
        value={note}
        onChange={(e) => setNote(e.target.value)}
        rows={3}
        cols={30}
      />
    </div>
  )
}

export default NoteInput
