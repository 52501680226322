import { filter, split } from 'lodash'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { errorMessageFromError, formErrorsFromError } from '@services/errorHandler'

export function useCreateDoseSchedule(statusMessage) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['createDoseSchedule'],
    mutationFn: ({ doseId, schedule }) => ApiClient.post(`/doses/${doseId}/schedules`, {
      schedule: {
        ...schedule,
        // The rrule.js library converts rrules to string
        //  in format "RRULE:FREQ=WEEKLY;INTERVAL=1;BYDAY=WE,FR"

        // The below line extracts the rule itself, omitting the "RRULE:"
        //  so that is is compatible with API validations"
        rrule: filter(split(schedule.rrule, ':'), (rule) => rule !== 'RRULE').join(';'),
      },
    }),
    onSuccess: () => {
      statusMessage.current.show([
        { severity: 'success', summary: 'Schedule Saved!' },
      ])
      queryClient.invalidateQueries('patientDose')
      queryClient.invalidateQueries('doseSchedule')
    },
    onError: (error) => {
      statusMessage.current.show([
        errorMessageFromError(error, 'Unable to save schedule at this time.'),
        ...formErrorsFromError(error),
      ])
    },
  })
}

export default useCreateDoseSchedule
