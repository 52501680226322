import React from 'react'
import { FormV2 } from '@components/display/FormV2'
import { Card } from 'primereact/card'
import useScrollTo from '@services/hooks/useScrollTo'
import TimezoneField from '../OrgSettings/Details/TimezoneField'
import SettingFeatures from '../OrgSettings/Details/SettingFeatures'
import { getSettingFeatures, getCurrentSettings } from '../OrgSettings/orgSettingsUtils'
import OrgTextField from '../OrgSettings/Details/OrgTextField'
import useCreateOrganizationMutation from './organizationHooks'

function CreateOrganization({
  timezones, statusMessage,
}) {
  const scrollTo = useScrollTo()
  const mutation = useCreateOrganizationMutation(statusMessage)

  const currentSettings = getCurrentSettings()
  const settingFeatures = getSettingFeatures()

  const onSubmit = async (data) => {
    await mutation.mutateAsync({ organization: data })
    scrollTo('top')
  }

  const options = timezones.map((tz) => ({ label: tz, value: tz }))
  return (
    <div className="flex w-full">
      <FormV2
        className="flex flex-column gap-4"
        onSubmit={onSubmit}
        submitLabel="Create Organization"
        currentFormValues={currentSettings}
      >
        <Card>
          <OrgTextField name="name" label="Name" />
          <TimezoneField options={options} />
          <OrgTextField name="hrstDomain" label="HRST domain" />
          <SettingFeatures features={settingFeatures} />
        </Card>
      </FormV2>
    </div>
  )
}

export default CreateOrganization
