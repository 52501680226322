import React, { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { FilterMatchMode } from 'primereact/api'
import { Column } from 'primereact/column'
import { Divider } from 'primereact/divider'
import { DataTable } from 'primereact/datatable'
import { TaskStatusTag, TaskTypeTag } from '@components/tasks/tags'
import SearchField from '@components/display/Form/SearchField'
import { useCurrentOrganization } from '@components/App'
import { useTasks } from '@hooks/tasks'
import { momentFormats } from '@services/utils/moment'
import { fuzzySearch } from '@services/utils/search'

const appliedFilters = (filters) => filters.reduce((result, filter) => {
  const newResult = result
  newResult[filter.name] = {
    value: filter.value,
    matchMode: FilterMatchMode.EQUALS,
  }
  return newResult
}, {})

const dateCellTemplate = (dateString, timezone) => (
  <div className="flex flex-column gap-2">
    {dateString ? moment(dateString).tz(timezone).format(momentFormats.dateYear) : null}
  </div>
)

const taskStatusTemplate = (task) => (<TaskStatusTag task={task} />)
const taskTypeTemplate = (task) => (<TaskTypeTag task={task} />)

function TasksList({ clientId, filters = [] }) {
  const navigate = useNavigate()
  const [tasks, setTasks] = useState([])
  const [searchTerm, setSearchTerm] = useState()
  const { timezone } = useCurrentOrganization()

  const query = useTasks(clientId)

  useEffect(() => {
    if (!isEmpty(query.data)) {
      setTasks(fuzzySearch(query.data, ['description'], searchTerm))
    }
  }, [query.data, searchTerm])

  return (
    <div className="flex flex-column gap-3 fadein">
      <SearchField
        className="w-19rem"
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        placeholder="Search Tasks by Description"
      />
      <Divider className="my-0" />
      <DataTable
        dataKey="id"
        loading={query.isLoading}
        value={tasks}
        filters={appliedFilters(filters)}
        sortField="status"
        sortOrder={1}
        selectionMode="single"
        onSelectionChange={({ value }) => navigate(`../manage/${value.id}`)}
        scrollable
      >
        <Column
          header="Status"
          field="status"
          sortField="status"
          sortable
          body={taskStatusTemplate}
        />
        <Column
          header="Title"
          field="title"
        />
        <Column
          header="Description"
          field="description"
          className="w-4"
        />
        <Column
          header="Start"
          field="startAt"
          body={({ startAt }) => dateCellTemplate(startAt, timezone)}
        />
        <Column
          header="End"
          field="endAt"
          body={({ endAt }) => dateCellTemplate(endAt, timezone)}
        />
        <Column
          header="Task Type"
          field="taskType"
          body={taskTypeTemplate}
        />
      </DataTable>
    </div>
  )
}

export default TasksList
