import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

export const useLeaveOfAbsenceAtTimeQuery = (patientId, time, statusMessage) => useQuery({
  queryKey: ['leaveOfAbsenceAtTime', patientId, time],
  queryFn: () => ApiClient.get('/leave_of_absences/at_time', { params: { patientId, time } }),
  onError: (error) => {
    if (statusMessage) displayErrorMessages(error, statusMessage)
  },
  enabled: !!patientId && !!time,
})

export default useLeaveOfAbsenceAtTimeQuery
