import { get } from 'lodash'

export const getPatientNameFromActivity = (activity) => {
  const trackable = get(activity, 'trackable.type', '')
  let patientName = get(activity, 'current.patientName', '')

  if (trackable === 'User') {
    patientName = get(activity, 'current.fullName', '')
  }

  return patientName
}

export default getPatientNameFromActivity
