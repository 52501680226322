import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'

function OnboardExistingUserConfirm({
  pendingUserToLink,
  patientToLink,
  patientBirthSexOptions,
  handleLinkUsers,
  onboardingInProgress,
  onCloseDialog,
}) {
  const usersToLink = [pendingUserToLink, patientToLink]

  return (
    <div className="flex flex-column w-full gap-3">
      <div>
        <i className="pi pi-exclamation-triangle" />
        <span className="ml-2">Are you sure you want to link the following two users?</span>
      </div>
      <DataTable value={usersToLink} showGridlines tableStyle={{ minWidth: '50rem' }}>
        <Column field="type" header="" />
        <Column field="firstName" header="First Name" />
        <Column field="lastName" header="Last Name" />
        <Column field="dateOfBirth" header="Date of Birth" />
        <Column
          header="Birth Sex"
          body={
            (user) => patientBirthSexOptions.find(
              (option) => option.value === user.birthSex,
            )?.label
          }
        />
      </DataTable>
      <div className="flex flex-row gap-2 justify-content-end">
        <Button
          label="Cancel"
          icon="pi pi-times"
          className="p-button-text p-button-sm"
          onClick={onCloseDialog}
        />
        <Button
          label="Confirm"
          icon="pi pi-check"
          className="p-button-sm"
          loading={onboardingInProgress}
          disabled={onboardingInProgress}
          onClick={() => handleLinkUsers(patientToLink.id)}
        />
      </div>
    </div>
  )
}

export default OnboardExistingUserConfirm
