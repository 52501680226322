import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'

const getQueryArg = (model, id, prescriptionNumber) => {
  if (!id && model !== 'Prescription') {
    return null
  }

  if (!prescriptionNumber && model === 'Prescription') {
    return null
  }

  switch (model) {
    case 'Dose':
      return `dose_id=${id}`
    case 'Patient':
      return `patient_id=${id}`
    case 'PendingUser':
      return `pending_user_id=${id}`
    case 'Pharmacy':
      return `pharmacy_id=${id}`
    case 'Prescription':
      return `prescription_number=${prescriptionNumber}`
    default:
      return null
  }
}

export const usePrescriptions = ({ model, id, prescriptionNumber }) => {
  const queryArg = getQueryArg(model, id, prescriptionNumber)

  const query = useQuery({
    queryKey: ['prescriptions', queryArg],
    queryFn: () => ApiClient.get(`/prescriptions?${queryArg}`),
    enabled: queryArg !== null,
  })

  return useMemo(() => ({
    ...query,
    data: query.data?.prescriptions || [],
  }), [query])
}

export default usePrescriptions
