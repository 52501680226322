import React from 'react'
import { filter, isEmpty } from 'lodash'
import { Tag } from 'primereact/tag'
import { FulfillmentStatus } from '../FullfillmentStatuses'

function DoseFillsCell({ doseFills }) {
  const loadedByAgencyDoseFills = filter(doseFills, {
    status: FulfillmentStatus.loadedByAgency,
  })
  const pendingDoseFills = filter(doseFills, {
    status: FulfillmentStatus.pending,
  })

  return (
    <div className="inline-flex flex-column gap-2 justify-content-center">
      {!isEmpty(pendingDoseFills) && (
        <Tag
          className="text-small font-normal p-1"
          style={{ background: 'var(--orange-300)' }}
          value={`${pendingDoseFills.length} Pending`}
        />
      )}
      {!isEmpty(loadedByAgencyDoseFills) && (
        <Tag
          className="text-small font-normal p-1"
          style={{ background: 'var(--green-300)' }}
          value={`${loadedByAgencyDoseFills.length} Loaded`}
        />
      )}
      {isEmpty(loadedByAgencyDoseFills) && isEmpty(pendingDoseFills) && (
        <Tag
          style={{ background: 'var(--bluegray-200)' }}
          className="text-small font-normal p-1"
          value="No Inventory"
        />
      )}
    </div>
  )
}

export default DoseFillsCell
