import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Card } from 'primereact/card'
import { Divider } from 'primereact/divider'
import { useCurrentOrganization, useCurrentUser } from '@components/App'
import LinkButtonNavigator from '@components/display/LinkButtonNavigator'
import Exports from '@components/patients/Exports'
import IgnoredOrders from '@components/patients/IgnoredOrders'
import PatientPharmacyOrders from '@components/patients/Orders/PatientPharmacyOrders'
import ReviewableTreatmentsList from '@components/treatments/ReviewableTreatmentsList/ReviewableTreatmentsList'
import PharmacyOrders from '../ClientDoses/PharmacyOrders'

function Menu({
  numReviewablePrescriptions,
  numReviewableTreatments,
  showRawOrders,
  showTreatments,
  showIgnoredOrders,
}) {
  const viewLinks = [
    {
      label: 'Prescriptions',
      linkTo: 'needs-review',
      badge: numReviewablePrescriptions && { severity: 'warning', value: numReviewablePrescriptions },
    },
  ]

  if (showTreatments) {
    viewLinks.push({
      label: 'Treatments',
      linkTo: 'treatments',
      badge: numReviewableTreatments && { severity: 'warning', value: numReviewableTreatments },
    })
  }

  if (showIgnoredOrders) {
    viewLinks.push({
      label: 'Ignored Orders',
      linkTo: 'ignored_orders',
    })
  }

  if (showRawOrders) {
    viewLinks.push({
      label: 'All Raw Orders',
      linkTo: 'raw_orders',
    })

    viewLinks.push({
      label: 'Prescription Raw Orders',
      linkTo: 'prescription_raw_orders',
    })

    viewLinks.push({
      label: 'Treatment Raw Orders',
      linkTo: 'treatment_raw_orders',
    })
  }

  return (
    <LinkButtonNavigator
      buttons={viewLinks}
      className="flex flex-row justify-space-between align-items-center px-1"
    />
  )
}

function Orders({ patient, handleSetHeader }) {
  const currentUser = useCurrentUser()
  const { treatmentsEnabled } = useCurrentOrganization()
  const showIgnoredOrders = ['superadmin', 'site_admin'].includes(currentUser.role)

  const primaryActionSetup = (action) => {
    handleSetHeader({ primaryAction: action })
  }

  if (!patient) {
    return null
  }

  return (
    <div className="col-12">
      <Exports
        patientId={patient?.id}
        exportsMenuSetupCallBack={primaryActionSetup}
      />
      <Card className="h-full">
        <div className="flex flex-column gap-3 text-base text-900">
          <Menu
            numReviewablePrescriptions={patient?.numReviewablePrescriptions}
            numReviewableTreatments={patient?.numReviewableTreatments}
            showRawOrders={currentUser.role === 'superadmin'}
            showTreatments={treatmentsEnabled}
            showIgnoredOrders={showIgnoredOrders}
          />
          <Divider className="my-0" />
          <Routes>
            <Route
              path="needs-review/*"
              element={<PharmacyOrders patientId={patient.id} />}
            />
            {
              treatmentsEnabled && (
                <Route
                  path="treatments"
                  element={
                    <ReviewableTreatmentsList patientId={patient.id} />
                  }
                />
              )
            }
            {
              showIgnoredOrders && (
                <Route
                  path="ignored_orders"
                  element={
                    <IgnoredOrders patientId={patient.id} showTreatments={treatmentsEnabled} />
                  }
                />
              )
            }
            {
              currentUser.role === 'superadmin' && (
                <>
                  <Route
                    path="raw_orders"
                    element={
                      <PatientPharmacyOrders patientId={patient.id} orderType="all" />
                    }
                  />
                  <Route
                    path="prescription_raw_orders"
                    element={
                      <PatientPharmacyOrders patientId={patient.id} orderType="prescription" />
                    }
                  />
                  <Route
                    path="treatment_raw_orders"
                    element={
                      <PatientPharmacyOrders patientId={patient.id} orderType="treatment" />
                    }
                  />
                </>
              )
            }
          </Routes>
        </div>
      </Card>
    </div>
  )
}

export default Orders
