import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

const useReactivateTreatment = ({ statusMessage }) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (treatment) => ApiClient.post(`/treatments/${treatment.id}/reactivate`),
    onSuccess: async () => {
      await queryClient.invalidateQueries('treatment')
      await queryClient.invalidateQueries('treatments')
      statusMessage.current.clear()
      statusMessage.current.show([
        { severity: 'success', summary: 'Treatment Reactivated!' },
      ])
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export default useReactivateTreatment
