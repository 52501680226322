import React, { useState } from 'react'
import { Timeline } from 'primereact/timeline'
import moment from 'moment'
import { formatDateTimeLocale, getAdherenceStatusColor } from '@services/utils'
import { getConsumptionStatusLabel } from './utils'

// Return history in descending order
const sortHistory = (history) => history
  .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))

const customizedMarker = (item) => (
  <span
    className="w-1rem h-1rem border-circle border-solid"
    style={{ borderColor: getAdherenceStatusColor(item.code) }}
  />
)

const historyOppositeContent = (item) => (
  <div className="w-6rem px-3 text-sm font-semibold text-left">
    {getConsumptionStatusLabel(item.code)}
  </div>
)

function DoseConsumptionsHistory({ historyData = [], timezone }) {
  const [showHistory, setShowHistory] = useState(false)
  const sortedHistory = sortHistory(historyData)

  const toggleHistory = () => {
    setShowHistory(!showHistory)
  }

  if (sortedHistory.length === 0) {
    return null
  }

  const historyContent = (item) => (
    <div>
      <div className="text-sm font-normal">{item?.subject?.fullName}</div>
      <div className="text-xs font-normal">{`Recorded: ${formatDateTimeLocale(moment(item.createdAt), timezone)}`}</div>
      <div className="text-xs font-normal">{`Time: ${formatDateTimeLocale(moment(item.recordedAt), timezone)}`}</div>
      {
        item?.note && (
          <div className="text-xs font-normal">
            Note:
            {' '}
            {item?.note}
          </div>
        )
      }
    </div>
  )

  return (
    <div>
      <div className="flex flex-row align-items-center cursor-pointer mt-1 w-4rem" onClick={toggleHistory}>
        <div className="text-xs font-medium font-light text-primary">
          Details
        </div>
        <i className={`text-primary text-sm ${showHistory ? 'pi pi-angle-up' : 'pi pi-angle-down'}`} />
      </div>
      {showHistory && (
        <div className="card px-0">
          <Timeline
            className="flex flex-column align-items-start w-full"
            value={sortedHistory}
            align="left"
            opposite={historyOppositeContent}
            content={historyContent}
            marker={customizedMarker}
          />
        </div>
      )}
    </div>
  )
}

export default DoseConsumptionsHistory
