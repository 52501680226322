import React from 'react'
import { map } from 'lodash'
import { Dropdown } from 'primereact/dropdown'
import SitesSelect from '../../features/Selects/SitesSelect/SitesSelect'

function ControlBar({
  children,
  timeframeOptions,
  timeframe,
  setTimeframe,
  isSitesLoading,
  availableSites,
  selectedSites = [],
  setSelectedSites,
}) {
  const selectedTimeframeTemplate = (option) => (
    <>
      <i className="pi pi-calendar mr-2" />
      <span>{option}</span>
    </>
  )

  return (
    <div className="flex flex-row gap-3 align-items-center">
      <Dropdown
        className="w-14rem"
        options={timeframeOptions.map(({ label }) => label)}
        onChange={(e) => setTimeframe(e.value)}
        value={timeframe}
        valueTemplate={selectedTimeframeTemplate}
      />
      <SitesSelect
        oneOrganization
        sites={availableSites}
        selectedSites={map(selectedSites, (id) => Number(id))}
        loading={isSitesLoading}
        onChange={setSelectedSites}
        placeholder="Filter by site"
      />
      {children}
    </div>
  )
}

export default ControlBar
