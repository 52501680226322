import React from 'react'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import AttributeCard from '@components/display/AttributeCard'

// TODO: Switch to react router once we are off active admin and routes for each page are defined
//       at the root of the react app
const navigate = (to) => {
  window.location.href = to
}

function PharmacyFacilitiesTable({ facilities, isLoading }) {
  return (
    <AttributeCard title={{ label: 'Facilities', icon: 'pi-building' }} className="h-full">
      <div className="flex flex-column gap-3 text-base text-900">
        <DataTable
          dataKey="id"
          loading={isLoading}
          value={facilities}
          selectionMode="single"
          onSelectionChange={(e) => navigate(`/admin/facilities/${e.value.id}`)}
          sortField="id"
          sortOrder={1}
          className="mt-1"
          rowClassName="fadein facility-row"
          scrollable
          removableSort
        >
          <Column header="ID" field="id" sortable />
          <Column header="External ID" field="externalId" sortable />
          <Column header="Organization" field="organizationName" sortable />
          <Column header="Message Count" field="integrationDataCount" sortable />
          <Column header="Created" field="createdAt" sortable />
          <Column header="Updated" field="updatedAt" sortable />
        </DataTable>
      </div>
    </AttributeCard>
  )
}

export default PharmacyFacilitiesTable
