import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { dateTemplate } from '@services/utils'
import '../style.scss'
import { sortBy } from 'lodash'
import { FillsLeft } from './DoseFillComponents'

function DoseFillsEditor({ doseFills, handleUpdateFillsLeft, handleRemoveDoseFill }) {
  const handleFillChange = (fill) => {
    handleUpdateFillsLeft({
      pharmacyOrderId: fill.pharmacyOrderId,
      fillsLeft: fill.fillsLeft,
    })
  }

  const handleFillRemove = (fill) => {
    handleRemoveDoseFill({ pharmacyOrderId: fill.pharmacyOrderId })
  }

  const sortedFills = sortBy(doseFills, 'pharmacyDispensedAt')

  const fillsLeftTemplate = (fill) => (
    <FillsLeft
      value={fill}
      handleFillChange={handleFillChange}
      handleFillRemove={handleFillRemove}
    />
  )

  return (
    <div className="w-full">
      <DataTable value={sortedFills} dataKey="pharmacyOrderId" editMode="cell">
        <Column
          className="w-2"
          key="pharmacyDispensedAt"
          header="Pharmacy Dispensed"
          field="pharmacyDispensedAt"
          body={({ pharmacyDispensedAt }) => (
            pharmacyDispensedAt ? dateTemplate(pharmacyDispensedAt) : null
          )}
        />
        <Column
          className="w-2"
          key="expiresAt"
          header="Expires"
          field="expiresAt"
          body={({ expiresAt }) => (
            expiresAt ? dateTemplate(expiresAt) : null
          )}
        />
        <Column
          className="w-2"
          key="medicineText"
          header="Medicine"
          field="medicineText"
        />
        <Column
          className="w-2"
          key="refillsRemaining"
          header="Refills Remaining"
          field="refillsRemaining"
        />
        <Column
          className="w-2"
          key="dosesInFill"
          header="Dispense Quantity"
          field="dosesInFill"
        />
        <Column
          className="w-2"
          key="fillsLeft"
          body={fillsLeftTemplate}
          header="Current Quantity"
          field="fillsLeft"
        />
      </DataTable>
    </div>
  )
}

export default DoseFillsEditor
