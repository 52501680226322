import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

// eslint-disable-next-line import/prefer-default-export
export function useApproveMARMutation({ statusMessage }) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ patientId, dayMin, dayMax }) => ApiClient.post('/mar_approvals', { marApproval: { patientId, dayMin, dayMax } }),
    onError: (error) => statusMessage?.current && displayErrorMessages(error, statusMessage),
    onSuccess: () => {
      queryClient.invalidateQueries('mar_approval')
    },
  })
}
