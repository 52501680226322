import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import {
  displayErrorMessages,
} from '@services/errorHandler'

export function useCreatePatientAllergy(statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['useCreatePatientAllergy'],
    mutationFn: (patientAllergy) => ApiClient.post('/patient_allergies', { patientAllergy }),
    onSuccess: () => {
      statusMessage.current.show([
        {
          severity: 'success',
          summary: 'Allergy created!',
        },
      ])
      queryClient.invalidateQueries('patientAllergies')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useUpdatePatientAllergy(statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['useUpdatePatientAllergy'],
    mutationFn: (patientAllergy) => ApiClient.put(`/patient_allergies/${patientAllergy.id}`, { patientAllergy }),
    onSuccess: () => {
      statusMessage.current.show([
        {
          severity: 'success',
          summary: 'Allergy updated!',
        },
      ])
      queryClient.invalidateQueries('patientAllergies')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useDeletePatientAllergy(statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['useDeletePatientAllergy'],
    mutationFn: (patientAllergy) => ApiClient.delete(`/patient_allergies/${patientAllergy.id}`),
    onSuccess: () => {
      statusMessage.current.show([
        {
          severity: 'success',
          summary: 'Allergy deleted!',
        },
      ])
      queryClient.invalidateQueries('patientAllergies')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}
