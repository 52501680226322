import React, { useRef } from 'react'
import { Skeleton } from 'primereact/skeleton'
import { Toast } from 'primereact/toast'
import { Attribute } from '@components/display/AttributeCard'
import { useRefreshFromWorkos } from '@hooks/sso'
import { dateTemplate } from '@services/utils'
import SuperAdminCard from '@components/display/SuperAdminCard'

const userLink = (directoryUser) => {
  const { userId, role } = directoryUser || {}

  if (!userId) {
    return null
  }

  const path = role === 'site_admin'
    ? `/admin/admins/${userId}`
    : `/admin/caregivers/${userId}`

  return (
    <a className="text-blue-500 hover:text-blue-700" href={path}>
      {userId}
    </a>
  )
}

function DirectoryUserCard({
  directoryUser,
  isLoading,
}) {
  const statusMessage = useRef(null)

  const {
    mutate: refreshFromWorkos,
    isLoading: isLoadingRefresh,
  } = useRefreshFromWorkos({
    model: 'DirectoryUser',
    id: directoryUser?.id,
    statusMessage,
  })

  const action = { label: 'Refresh', command: refreshFromWorkos }

  const valueTemplate = (value) => (
    isLoading || isLoadingRefresh ? <Skeleton className="inline-flex" width="5rem" height="1rem" /> : value || 'Empty'
  )

  return (
    <div id="directory-user-card">
      <Toast ref={statusMessage} />
      <SuperAdminCard
        action={action}
        title="Directory User"
      >
        <Attribute
          label="Email:"
          value={valueTemplate(directoryUser?.email)}
        />
        <Attribute
          label="Username:"
          value={valueTemplate(directoryUser?.username)}
        />
        <Attribute
          label="Phone:"
          value={valueTemplate(directoryUser?.phone)}
        />
        <Attribute
          label="Role:"
          value={valueTemplate(directoryUser?.role)}
        />
        <Attribute
          label="First Name:"
          value={valueTemplate(directoryUser?.firstName)}
        />
        <Attribute
          label="Last Name:"
          value={valueTemplate(directoryUser?.lastName)}
        />
        <Attribute
          label="Onboard Type:"
          value={valueTemplate(directoryUser?.onboardType)}
        />
        <Attribute
          label="Onboarded:"
          value={valueTemplate(dateTemplate(directoryUser?.onboardedAt))}
        />
        <Attribute
          label="User ID:"
          value={valueTemplate(userLink(directoryUser))}
        />
        <Attribute
          label="WorkOS IdP ID:"
          value={valueTemplate(directoryUser?.workosIdpId)}
        />
        <Attribute
          label="Created:"
          value={valueTemplate(dateTemplate(directoryUser?.createdAt))}
        />
        <Attribute
          label="Updated:"
          value={valueTemplate(dateTemplate(directoryUser?.updatedAt))}
        />
      </SuperAdminCard>
    </div>
  )
}

export default DirectoryUserCard
