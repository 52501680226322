import React from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { useUnlinkPrescription } from '@hooks/prescriptions'

function UnlinkPrescriptionDialog({
  prescriptionId, isVisible, onHide, messagesRef,
}) {
  const {
    mutateAsync: unlinkPrescription,
    isLoading,
  } = useUnlinkPrescription(messagesRef)

  if (!prescriptionId) {
    return null
  }

  return (
    <Dialog
      header="Unlink Prescription"
      visible={isVisible}
      onHide={() => onHide()}
      style={{ width: '50vw' }}
      dismissableMask
    >
      <div className="flex flex-column gap-1">
        <div className="flex flex-row gap-2 align-items-center">
          <i className="pi pi-exclamation-triangle text-orange-500" style={{ fontSize: '2rem' }} />
          <span className="text-lg">
            Are you sure you want to unlink this prescription?
            <br />
            The pharmacy orders will also be unacknowledged.
          </span>
        </div>
        <div className="flex flex-row gap-2 justify-content-end">
          <Button
            label="Cancel"
            className="p-button-text"
            onClick={() => onHide()}
            disabled={isLoading}
          />
          <Button
            label="Unlink"
            onClick={() => {
              unlinkPrescription(prescriptionId, {
                onSuccess: () => onHide(),
              })
            }}
            loading={isLoading}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default UnlinkPrescriptionDialog
