import React from 'react'
import InputTextWithLabel from '@components/display/InputTextWithLabel'

function Details({ details = {}, setDetails }) {
  const { ssid, password } = details
  return (
    <div className="flex flex-column">
      <InputTextWithLabel
        label="SSID"
        value={ssid || ''}
        inputId="SSID"
        name="SSID"
        onChange={({ target: { value } }) => setDetails({ ssid: value })}
      />
      <InputTextWithLabel
        label="Password"
        value={password || ''}
        inputId="password"
        name="password"
        onChange={({ target: { value } }) => setDetails({ password: value })}
        type="password"
      />
    </div>
  )
}

export default Details
