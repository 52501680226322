import React from 'react'
import { map, sortBy } from 'lodash'
import { classNames } from 'primereact/utils'
import HoveredContainer from '@components/display/HoveredContainer'

function DeviceCard({
  device, isSelected, setSelectedDeviceId,
}) {
  return (
    <HoveredContainer
      className={classNames('border-round cursor-pointer', { 'border-3 border-primary-500': isSelected })}
      style={{ width: '49%' }}
      key={device.id}
      onClick={() => setSelectedDeviceId(device.id)}
      hoveredClassName="border-3 border-primary-500 bg-primary-50"
      nonHoveredClassName="border-1 border-200"
    >
      <div className="p-2">
        <div className="flex flex-column gap-1">
          <div className="flex flex-row justify-content-between">
            <i className="pi pi-tablet" style={{ fontSize: '1.2rem' }} />
            {
              isSelected && <i className="pi pi-check-circle" style={{ fontSize: '1.25rem', color: 'var(--primary-500)' }} />
            }
          </div>
          <div className="text-sm font-bold">
            {device.label}
          </div>
          <div className="text-xs flex-row">
            <span>Mac: </span>
            <span className="font-light">{device.mac}</span>
          </div>
        </div>
      </div>
    </HoveredContainer>
  )
}

function DeviceSelection({
  devices, selectedDeviceId, setSelectedDeviceId, isLoading,
}) {
  if (isLoading) {
    return (
      <div className="flex flex-row device-selection">
        Loading devices...
      </div>
    )
  }

  if (!devices.length) {
    return (
      <div className="flex flex-row device-selection">
        No devices found
      </div>
    )
  }

  return (
    <div className="flex flex-column gap-2">
      <span className="text-lg font-semibold">Choose your preferred E-Kit</span>
      <div className="mb-2" />
      <div className="flex flex-wrap device-selection justify-content-between row-gap-2">
        {map(sortBy(devices, 'id'), (device) => (
          <DeviceCard
            device={device}
            isSelected={device.id === selectedDeviceId}
            setSelectedDeviceId={setSelectedDeviceId}
          />
        ))}
      </div>
    </div>
  )
}

export default DeviceSelection
