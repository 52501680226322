import React from 'react'
import HoveredContainer from '@components/display/HoveredContainer'
import { contrastColor } from '@services/utils/color'
import LocationFillsDetails from './LocationFillsDetails'
import { defaultdrawerColor } from './loadToDeviceConfig'

function DrawerSelection({
  ekit, selectedDrawer = {}, setSelectedDrawerId,
}) {
  const drawersTemplate = ekit?.simpillDrawers.map((drawer) => {
    const textColor = contrastColor(drawer.color || defaultdrawerColor)

    const styles = {
      backgroundColor: drawer.position === selectedDrawer.position ? drawer.color : null,
      color: drawer.position === selectedDrawer.position ? textColor : null,
      borderColor: drawer.color,
      minHeight: '3rem',
    }

    return (
      <HoveredContainer
        key={drawer.position}
        id={`drawer-${drawer.position}`}
        className="flex flex-column gap-2 align-items-center justify-content-center cursor-pointer border-round border-2 p-2"
        style={styles}
        onClick={() => (setSelectedDrawerId ? setSelectedDrawerId(drawer.id) : null)}
        hoveredStyle={{ backgroundColor: drawer.color, color: textColor }}
      >
        <div className="flex flex-row gap-2">
          <i className="pi pi-inbox" style={{ fontSize: '1.2rem' }} />
          <span className="text-md">
            Drawer
            {' '}
            {drawer.position}
          </span>
        </div>
        <LocationFillsDetails
          doseFills={drawer.doseFills}
        />
      </HoveredContainer>
    )
  }) || []

  return (
    <div className="flex flex-column gap-2">
      <span className="text-lg font-semibold">Choose your preferred drawer</span>
      <div className="mb-2" />
      {drawersTemplate}
    </div>
  )
}

export default DrawerSelection
