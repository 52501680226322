import React, { useState } from 'react'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { csaScheduleText } from '@components/medicines/csaScheduleLabel'
import { useUpdateDoseCsaSchedule } from '../../../features/InventoryManagement/inventoryHooks'

function MedicineCell({
  id: doseId,
  medicineId,
  medicineCsaSchedule,
  medicineName,
  medicineSynonym,
  medicineStrength,
}) {
  const [isEditing, setIsEditing] = useState(false)
  const [csaSchedule, setCsaSchedule] = useState(medicineCsaSchedule)

  const {
    mutateAsync: updateDoseCsaSchedule,
    isLoading: isUpdatingCsaSchedule,
  } = useUpdateDoseCsaSchedule()

  const handleEdit = () => {
    setIsEditing(true)
  }

  const handleSave = async () => {
    await updateDoseCsaSchedule({
      doseId,
      medicineId,
      csaSchedule,
    })
    setIsEditing(false)
  }

  const handleCancel = () => {
    setCsaSchedule(medicineCsaSchedule)
    setIsEditing(false)
  }

  const options = [
    { label: 'Schedule I', value: 1 },
    { label: 'Schedule II', value: 2 },
    { label: 'Schedule III', value: 3 },
    { label: 'Schedule IV', value: 4 },
    { label: 'Schedule V', value: 5 },
  ]

  return (
    <div className="flex flex-row gap-2">
      <div className="flex flex-column gap-2 justify-content-center">
        {medicineSynonym && <span>{medicineSynonym}</span>}
        <span>{medicineName}</span>
        <span>{medicineStrength}</span>
        { csaSchedule && !isEditing && (
          <div className="flex flex-row align-items-center">
            <span>
              {csaScheduleText(csaSchedule)}
              {' '}
              Controlled Substance
            </span>
            <Button
              onClick={handleEdit}
              className="p-button-sm p-button-text p-button-secondary p-0 ml-1"
              icon="pi pi-pencil"
            />
          </div>
        )}
        { csaSchedule && isEditing && (
          <div className="flex flex-row align-items-center">
            <Dropdown
              value={csaSchedule}
              options={options}
              optionLabel="label"
              onChange={(e) => setCsaSchedule(e.value)}
              onClick={(e) => e.stopPropagation()}
            />
            <Button
              onClick={handleSave}
              loading={isUpdatingCsaSchedule}
              className="p-button-sm p-button-text p-button-success"
              icon="pi pi-check"
            />
            <Button
              onClick={handleCancel}
              className="p-button-sm p-button-text p-button-secondary"
              icon="pi pi-times"
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default MedicineCell
