import React from 'react'
import { Routes, Route } from 'react-router-dom'
import PageLayout, { usePageOutlet } from '@components/display/PageLayout'
import { withApp } from '@components/App'
import UpdateSharedDevice from './UpdateSharedDevice'
import CreateSharedDevice from './CreateSharedDevice'
import SharedDevicesTable from './SharedDevicesTable'

function SharedDevices(props) {
  const useSharedDevicesHeader = () => usePageOutlet()

  return (
    <div className="shared_devices">
      <Routes>
        <Route
          path="/admin/shared_devices/*"
          element={
            <PageLayout rootPath="/admin/shared_devices" rootLabel="Shared Devices" />
          }
        >
          <Route
            index
            element={
              <SharedDevicesTable useSharedDevicesHeader={useSharedDevicesHeader} {...props} />
            }
          />
          <Route
            path="create"
            element={
              <CreateSharedDevice useSharedDevicesHeader={useSharedDevicesHeader} />
            }
          />
          <Route
            path=":sharedDeviceId/*"
            element={
              <UpdateSharedDevice useSharedDevicesHeader={useSharedDevicesHeader} {...props} />
            }
          />
        </Route>
      </Routes>
    </div>
  )
}

export default withApp(SharedDevices)
