import React, { useEffect, useRef, useState } from 'react'
import { find, get } from 'lodash'
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { Messages } from 'primereact/messages'
import {
  EkitsDoseList,
  ControlBar,
} from '@components/inventoryManagement/Inventory'
import ExportDialog from '@components/inventoryManagement/Dashboard/ExportDialog'
import { useCurrentOrganization, useCurrentUser } from '@components/App'
import { Link } from 'react-router-dom'
import { useSitesQuery } from '../Selects/SitesSelect/SitesSelectHooks'
import { useDosesWithInventory } from './inventoryHooks'
import './dashboard.scss'

function PageTitle({ children }) {
  const currentOrganization = useCurrentOrganization()
  const currentUser = useCurrentUser()
  const isEkit = currentUser.role === 'pharmacist' || currentOrganization.ekitsEnabled
  if (!isEkit) {
    throw new Error('This component should only be used for E-Kits')
  }
  return (
    <div className="flex flex-row gap-4 px-4 pb-2 pt-2 align-items-center">
      <span>
        <i className="text-xl pi pi-chart-bar mr-2" />
        E-KIT Inventory
      </span>
      {children}
    </div>
  )
}

function DoseList(props) {
  const currentOrganization = useCurrentOrganization()
  const currentUser = useCurrentUser()

  const isEkit = currentUser.role === 'pharmacist' || currentOrganization.ekitsEnabled
  if (!isEkit) {
    throw new Error('This component should only be used for E-Kits')
  }

  return <EkitsDoseList {...props} />
}

function InventoryTable({ useInventoryManagementHeader }) {
  const statusMessage = useRef(null)
  const [exportDialogVisible, setExportDialogVisible] = useState(false)
  const { setHeader, maxDataViewHeight } = useInventoryManagementHeader()
  const organization = useCurrentOrganization()
  const currentUser = useCurrentUser()

  const {
    doses,
    totalRecords,
    isFetching: dosesLoading,
    queryParams: {
      siteIds,
      offset,
      sort,
      doseStatusFilters,
      medicineSearchTerm,
    },
    handleParamChange,
  } = useDosesWithInventory({ statusMessage })

  const { data: { sites } = [], isLoading: sitesLoading } = useSitesQuery({
    statusMessage,
  })

  const handlePageChange = (newPage) => {
    const newParams = { offset: newPage * 100 }
    handleParamChange({ newParams })
  }

  const handleSortingChange = (newSort) => {
    const { sortField, sortOrder } = newSort
    const direction = sortOrder === 1 ? 'ASC' : 'DESC'
    const newParams = { offset: 0, sort: `${sortField} ${direction}` }
    handleParamChange({ clearCache: true, newParams })
  }

  const handleFillStatusFilterChange = (filters) => {
    const newParams = { offset: 0, doseStatusFilters: filters }
    handleParamChange({ clearCache: true, newParams })
  }

  const handleMedSearchChange = (term) => {
    const newParams = { offset: 0, medicineSearchTerm: term }
    handleParamChange({ clearCache: true, newParams })
  }

  const handleSiteIdsChange = (selection) => {
    const newParams = { offset: 0, siteIds: selection }
    handleParamChange({ clearCache: true, newParams })
  }

  const handleDoseFocus = (doseId) => {
    const dose = find(doses, ({ id }) => id === doseId)
    const site = get(dose, 'site.id') || get(dose, 'patient.site.id')
    const newParams = {
      offset: 0,
      medicineSearchTerm: dose.medicineName,
      siteIds: [site],
      filters: ['pending', 'loaded', 'no_inventory'],
    }
    handleParamChange({ clearCache: true, newParams })
  }

  const title = {
    label: (
      <PageTitle>
        <ControlBar
          isSitesLoading={sitesLoading}
          availableSites={sites}
          selectedSites={siteIds}
          setSelectedSites={handleSiteIdsChange}
          medicineSearchTerm={medicineSearchTerm}
          setMedicineSearchTerm={handleMedSearchChange}
          isLoading={dosesLoading}
        />
      </PageTitle>
    ),
  }

  const primaryAction = () => {
    const exportsButton = (
      <Button
        label="Exports"
        icon="pi pi-file-export"
        className="p-button-sm p-button-primary"
        onClick={() => setExportDialogVisible(true)}
      />
    )

    if (currentUser.role === 'pharmacist') {
      return (
        <div className="flex flex-row gap-2">
          <Link to="/admin/inventory_management/doses/create">
            <Button className="p-button-sm" label="Create Dose" />
          </Link>
          {exportsButton}
        </div>
      )
    }
    return exportsButton
  }

  useEffect(() => {
    setHeader({
      title,
      tabs: [],
      breadcrumbs: [],
      primaryAction: primaryAction(),
    })
  }, [dosesLoading, sitesLoading, medicineSearchTerm, siteIds])

  return (
    <div className="col-12">
      <Messages ref={statusMessage} />
      <ExportDialog
        visible={exportDialogVisible}
        hide={() => setExportDialogVisible(false)}
        sites={sites}
        statusMessage={statusMessage}
        organizationId={organization.id}
      />
      <Card className="h-full">
        <div className="flex flex-column gap-3 text-base text-900">
          <DoseList
            statusMessage={statusMessage}
            doses={doses}
            isLoading={dosesLoading}
            totalRecords={totalRecords}
            sort={sort}
            doseStatusFilters={doseStatusFilters}
            handleDoseFocus={handleDoseFocus}
            handleFillStatusFilterChange={handleFillStatusFilterChange}
            handleSortingChange={handleSortingChange}
            page={Math.ceil(offset / 100)}
            handlePageChange={handlePageChange}
            maxDataViewHeight={maxDataViewHeight}
          />
        </div>
      </Card>
    </div>
  )
}

export default InventoryTable
