import React from 'react'
import { Routes, Route } from 'react-router-dom'
import PageLayout from '@components/display/PageLayout'
import { withApp, useCurrentOrganization } from '@components/App'
import './notification_settings.scss'
import NotificationSettingsTable from './NotificationSettingsTable'

function NotificationSettings() {
  const organization = useCurrentOrganization()

  return (
    <div className="notification_settings">
      <Routes>
        <Route path="/admin/notification_settings/*" element={<PageLayout rootPath="/admin/notification_settings" rootLabel={organization.caregiverLabelPlural} />}>
          <Route
            index
            element={(
              <NotificationSettingsTable />
            )}
          />
        </Route>
      </Routes>
    </div>
  )
}

export default withApp(NotificationSettings)
