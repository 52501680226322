import React, { useState, useRef, useEffect } from 'react'
import { Messages } from 'primereact/messages'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Link } from 'react-router-dom'
import SharedPhoneGroup from '@components/sharedDevices/SharedPhoneGroup'
import Header from '@components/sharedDevices/Header'
import Title from '@components/sharedDevices/Title'
import { useSharedDevicesQuery } from './sharedDeviceHooks'
import SitesSelect from '../Selects/SitesSelect/SitesSelect'
import { useSitesQuery } from '../Selects/SitesSelect/SitesSelectHooks'

function SharedDevicesTable({ useSharedDevicesHeader, organizationId }) {
  const statusMessage = useRef(null)
  const { setHeader } = useSharedDevicesHeader()
  const {
    data: { sharedDevices } = {},
    isLoading,
  } = useSharedDevicesQuery(organizationId, statusMessage)

  const {
    data: { sites } = [],
    isLoading: isSitesLoading,
  } = useSitesQuery({ statusMessage, organizationId })

  const [expandedRows, setExpandedRows] = useState({})
  const [selectedSites, setSelectedSites] = useState([])

  let filteredSharedDevices
  if (selectedSites.length > 0) {
    filteredSharedDevices = sharedDevices.filter(
      (sharedDevice) => selectedSites.includes(sharedDevice.siteId),
    )
  } else {
    filteredSharedDevices = sharedDevices
  }

  const title = {
    label: (
      <Title />
    ),
  }

  const primaryAction = (
    <Link to="/admin/shared_devices/create">
      <Button className="p-button-sm" label="Create Shared Device" />
    </Link>
  )

  const sharedPhoneGroupTemplate = (rowData) => (
    <SharedPhoneGroup
      caregivers={rowData.caregivers}
      statusMessage={statusMessage}
    />
  )

  const headerTemplate = (rowData) => (
    <Header sharedDevice={rowData} />
  )

  useEffect(() => {
    setHeader({
      title, tabs: [], breadcrumbs: [], primaryAction,
    })
  }, [isLoading])

  return (
    <div className="m-2">
      <div className="d-flex justify-content-end">
        <SitesSelect
          sites={sites}
          selectedSites={selectedSites}
          loading={isSitesLoading}
          onChange={setSelectedSites}
          placeholder="Filter by site"
          className="mb-2"
        />
      </div>
      <Messages ref={statusMessage} />
      {sharedDevices
        && (
        <DataTable
          loading={isLoading}
          value={filteredSharedDevices}
          expandedRows={expandedRows}
          rowExpansionTemplate={sharedPhoneGroupTemplate}
          onRowToggle={(e) => setExpandedRows(e.data)}
          dataKey="id"
        >
          <Column expander style={{ width: '3em' }} />
          <Column
            field="phone"
            className="justify-content-end"
            body={headerTemplate}
          />
        </DataTable>
        )}
    </div>
  )
}

export default SharedDevicesTable
