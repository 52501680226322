import React, { useEffect, useRef } from 'react'
import { isEmpty } from 'lodash'
import {
  useCreateSignature,
} from '@components/user/ElectronicSignature/signatureHooks'
import { Button } from 'primereact/button'
import { Messages } from 'primereact/messages'
import ElectronicSignature from '@components/user/ElectronicSignature/ElectronicSignature'
import OverlayAction from '@components/display/OverlayAction'

/**
 * @component
 * @param {object} props
 * @param {object} props.signature - The signature to display.
 * @return {JSX.Element}
*/
function ApprovalSignature({
  userSignature,
  adminId,
  signature,
  setSignature,
  isEditing,
  setIsEditing,
}) {
  const statusMessageRef = useRef(null)
  const {
    mutateAsync: createSignature,
    isLoading: createSignatureIsLoading,
  } = useCreateSignature(adminId, statusMessageRef)

  useEffect(() => {
    if (!isEmpty(userSignature)) {
      setSignature(userSignature.svg)
      setIsEditing(false)
    } else {
      setIsEditing(true)
    }
  }, [userSignature])

  const handleCreateSignature = async () => {
    await createSignature(signature)
  }

  return (
    <div style={{ width: '28rem' }}>
      <Messages ref={statusMessageRef} />
      {
        !createSignatureIsLoading
        && (
        <OverlayAction
          icon="pi pi-pencil"
          onClick={() => setIsEditing(true)}
          overlayEnabled={!isEditing}
          size="28rem"
          text="Edit"
          textClassName="text-md text-bold"
          opacity={0.85}
        >
          <ElectronicSignature
            isEditingSignature={isEditing}
            isViewingCurrentUser
            signature={signature}
            setSignature={setSignature}
          />
        </OverlayAction>
        )
}
      <div className="flex flex-row justify-content-end">
        {isEditing
        && (
        <Button
          label="Save Signature"
          className="p-button-sm p-button-text py-1"
          loading={createSignatureIsLoading}
          onClick={handleCreateSignature}
        />
        )}
      </div>
    </div>
  )
}

export default ApprovalSignature
