import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'

const useSite = (siteId) => useQuery({
  queryKey: ['site', siteId],
  queryFn: () => ApiClient.get(`/sites/${siteId}`),
  enabled: !!siteId,
})

export default useSite
