import { Dialog } from 'primereact/dialog'
import React from 'react'
import { useIntegrationDataQuery } from './integrationDataHooks'

function DetailsDialog({ integrationDataId, isVisible, hide }) {
  const {
    data: integrationData,
    isLoading,
  } = useIntegrationDataQuery(integrationDataId)

  if (!integrationData) {
    return null
  }

  return (
    <Dialog
      header="Details"
      visible={isVisible}
      onHide={() => hide()}
      style={{ width: '50vw' }}
      dismissableMask
    >
      {isLoading && <p>Loading...</p>}
      <div className="flex flex-column gap-1">
        <h3 className="sticky top-0 bg-white">Integration Data</h3>
        <pre>{JSON.stringify(integrationData, null, 2)}</pre>
      </div>
    </Dialog>
  )
}

export default DetailsDialog
