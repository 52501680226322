import React, { useRef, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Messages } from 'primereact/messages'
import { useRestartDoseMutation } from '../clientDosesHooks'

function RestartDoseDialog({
  patientId, doseId, isVisible, onHide,
}) {
  const statusMessage = useRef()
  const [newEndAt, setNewEndAt] = useState()

  const {
    mutateAsync: restartDose,
    isLoading,
  } = useRestartDoseMutation(patientId, statusMessage)

  const handleRestartDose = async () => {
    await restartDose({ id: doseId, endAt: newEndAt })
    onHide()
  }

  return (
    <Dialog header="Restart Dose" visible={isVisible} onHide={onHide}>
      <Messages ref={statusMessage} />
      <div className="flex flex-column gap-4">
        <label htmlFor="newEndDate">New End Date</label>
        <Calendar
          id="newEndDate"
          value={newEndAt}
          onChange={(e) => setNewEndAt(e.value)}
          showIcon
          minDate={new Date()}
        />
        <div className="flex flex-row justify-content-end">
          <Button
            label="Restart Dose"
            icon="pi pi-check"
            onClick={handleRestartDose}
            disabled={isLoading || !newEndAt}
            loading={isLoading}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default RestartDoseDialog
