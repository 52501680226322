import React, { useState, useEffect } from 'react'
import {
  get, minBy, maxBy, isEmpty, filter,
} from 'lodash'
import { Chart } from 'primereact/chart'
import { Skeleton } from 'primereact/skeleton'
import { enumerateDaysBetweenDates, dateTemplate } from '../../services/utils'

function DoseTrend({ consumptions, loading }) {
  const [chartData, setChartData] = useState({})
  const startDate = get(minBy(consumptions, 'expectedAt'), 'expectedAt')
  const endDate = get(maxBy(consumptions, 'expectedAt'), 'expectedAt')
  const days = enumerateDaysBetweenDates(startDate, endDate)

  const calculateDailyConsumptions = (status) => days.map((day) => {
    const daysConsumptions = filter(consumptions, (consumption) => {
      if (dateTemplate(consumption.expectedAt) === dateTemplate(day)) {
        if (consumption.status === 'confirmed' && status === 'on_time') {
          return true
        } if (consumption.status === 'refused' && status === 'refused') {
          return true
        } if (status === 'missed') {
          return true
        }
        return false
      }
    })
    return daysConsumptions.length
  })

  useEffect(() => {
    if (consumptions) {
      const data = {
        labels: days.map((day) => dateTemplate(day)),
        datasets: [
          {
            label: 'On Time',
            backgroundColor: '#caf1d8',
            borderColor: '#4cd07d',
            data: calculateDailyConsumptions('on_time'),
          },
          {
            label: 'Missed',
            backgroundColor: '#ffd0ce',
            borderColor: '#ff6259',
            data: calculateDailyConsumptions('missed'),
          },
          {
            label: 'Refused',
            backgroundColor: '#feddc7',
            borderColor: '#fa8e42',
            data: calculateDailyConsumptions('refused'),
          },
        ],
      }
      setChartData(data)
    }
    return () => setChartData({})
  }, [consumptions])

  if (loading || isEmpty(chartData)) {
    return (
      <div className="flex flex-column gap-2 mt-3">
        <Skeleton className="inline-flex align-self-center" width="50%" height="1.25rem" />
        <Skeleton className="inline-flex" width="100%" height="9rem" />
      </div>
    )
  }

  return (
    <Chart
      className="fadein"
      style={{ position: 'relative', height: '200px' }}
      type="bar"
      data={chartData}
      options={{
        maintainAspectRatio: false,
        animations: false,
        tooltip: { enabled: false },
      }}
    />
  )
}

export default DoseTrend
