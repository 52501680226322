import React from 'react'
import { Button } from 'primereact/button'
import InputTextWithLabel from '@components/display/InputTextWithLabel'

function WalkInRx({
  walkInRx, setWalkInRx, handleConfirm,
}) {
  return (
    <div className="grid flex flex-grow-1 justify-content-center align-items-center fadein">
      <div className="col-9 flex flex-column gap-2 text-center">
        <InputTextWithLabel
          label="Walk In Rx"
          value={walkInRx}
          inputId="walkInRx"
          name="walkInRx"
          onChange={({ target: { value } }) => setWalkInRx(value)}
        />
        <Button
          onClick={handleConfirm}
          label="Save and Continue"
          className="p-button-text mt-2"
        />
      </div>
    </div>
  )
}

export default WalkInRx
