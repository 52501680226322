import React from 'react'
import InputTextWithLabel from '@components/display/InputTextWithLabel'

function DetailsForm({ details = {}, setDetails }) {
  const { label, phone } = details
  return (
    <div className="flex flex-column">
      <InputTextWithLabel
        label="Label"
        value={label || ''}
        inputId="label"
        name="label"
        onChange={({ target: { value } }) => setDetails({ label: value })}
      />
      <InputTextWithLabel
        label="Phone"
        value={phone || ''}
        inputId="phone"
        name="phone"
        onChange={({ target: { value } }) => setDetails({ phone: value })}
      />
    </div>
  )
}

export default DetailsForm
