import { useMemo } from 'react'
import { get } from 'lodash'
import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { errorMessageFromError, formErrorsFromError } from '@services/errorHandler'

function usePatientPharmacyOrders({ patientId, orderType, statusMessage }) {
  const query = useQuery({
    queryKey: ['patientPharmacyOrders', patientId, orderType],
    queryFn: () => ApiClient.get(`/patients/${patientId}/pharmacy_orders?order_type=${orderType}`),
    onError: (error) => {
      statusMessage.current.show([
        errorMessageFromError(error, 'Unable to fetch pharmacy orders at this time.'),
        ...formErrorsFromError(error),
      ])
    },
  })

  return useMemo(() => ({
    ...query,
    isLoading: query.isLoading,
    data: get(query, 'data.pharmacyOrders'),
  }), [query.data])
}

export default usePatientPharmacyOrders
