import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { formatSchedules } from '@services/utils/schedule'

export function useDoseSchedules(doseId) {
  const query = useQuery({
    queryKey: ['doseSchedule', doseId],
    queryFn: () => ApiClient.get(`doses/${doseId}/schedules`),
    enabled: !!doseId,
  })

  return useMemo(() => {
    if (!query.data) return query
    const schedules = formatSchedules(query.data.schedules)
    return {
      ...query,
      data: schedules,
    }
  }, [query.data])
}

export default useDoseSchedules
