import React from 'react'
import InputTextWithLabel from '@components/display/InputTextWithLabel'
import PasswordWithLabel from '@components/display/PasswordWithLabel'

function UsernameFields({
  username, setUsername, password, setPassword, isLoading,
}) {
  return (
    <>
      <div className="field">
        <InputTextWithLabel
          label="Username"
          name="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          disabled={isLoading}
          inputId="auth_username"
        />
      </div>
      <div className="field">
        <PasswordWithLabel
          label="Password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          disabled={isLoading}
          inputId="auth_password"
          toggleMask
        />
      </div>
    </>
  )
}
export default UsernameFields
