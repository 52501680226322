import ApiClient from '@services/ApiClient'

async function getWorkOSAdminPortalLink(orgId, workosOrgId, intent, statusMessage) {
  if (!workosOrgId) {
    statusMessage.current.show([
      { severity: 'error', summary: 'Unable to find WorkOS organization.' },
    ])
    return { adminPortalLink: null }
  }
  if (!intent) {
    statusMessage.current.show([
      { severity: 'error', summary: 'Intent not found' },
    ])
    return { adminPortalLink: null }
  }

  try {
    const response = await ApiClient.get(`/single_sign_on/auth_admin_portal/${orgId}?intent=${intent}`)
    if (response && response.adminPortalLink) {
      return { adminPortalLink: response.adminPortalLink }
    }
    statusMessage.current.show([
      { severity: 'error', summary: 'Unable to find WorkOS admin portal link.' },
    ])
  } catch (e) {
    statusMessage.current.show([
      { severity: 'error', summary: 'Something went wrong.' },
    ])
  }
}

async function updateWorkOSOrgId(orgId, workosOrgId) {
  let error = null
  try {
    await ApiClient.put(`/single_sign_on/update_workos_org/${orgId}`, { workos_organization_id: workosOrgId })
  } catch (e) {
    error = e?.response?.data?.error || 'An unexpected error occurred'
  }

  return { error }
}

const extractSettings = (query) => {
  const settings = query?.data || {}
  const fields = [
    'name',
    'hrstDomain',
    'timezone',
    'shiftsEnabled',
    'caregiversCanShareDevice',
    'pinSwitchingEnabled',
    'ekitsEnabled',
    'ccmEnabled',
    'qs1Enabled',
    'ssoEnabled',
    'usernamePasswordAuthEnabled',
    'guardiansEnabled',
    'patientHrstEnabled',
    'narcoticCountsEnabled',
    'therapExportEnabled',
    'treatmentsEnabled',
    'staffEsignatureRequired',
  ]

  return fields.reduce((acc, field) => {
    acc[field] = settings[field]?.value ?? settings[field]
    return acc
  }, {})
}

const getCurrentSettings = (query) => {
  const settings = extractSettings(query)
  return {
    ...settings,
    name: settings.name || '',
  }
}

const getSettingFeatures = (query) => {
  const settings = extractSettings(query)

  const features = [
    { name: 'shiftsEnabled', label: 'Shifts Enabled' },
    { name: 'caregiversCanShareDevice', label: 'Caregivers Can Share Device', disabled: settings.caregiversCanShareDevice },
    { name: 'pinSwitchingEnabled', label: 'Pin Switching Enabled', disabled: settings.pinSwitchingEnabled },
    { name: 'ekitsEnabled', label: 'Ekits Enabled' },
    { name: 'ccmEnabled', label: 'CCM Time Tracking' },
    { name: 'qs1Enabled', label: 'QS1 Enabled', disabled: settings.qs1Enabled },
    { name: 'ssoEnabled', label: 'SSO Enabled' },
    { name: 'usernamePasswordAuthEnabled', label: 'Username/Password Auth Enabled' },
    { name: 'guardiansEnabled', label: 'Guardians Enabled' },
    { name: 'patientHrstEnabled', label: 'Patient HRST Enabled' },
    { name: 'narcoticCountsEnabled', label: 'Narcotic Counts Enabled' },
    { name: 'therapExportEnabled', label: 'Therap Export Enabled' },
    { name: 'treatmentsEnabled', label: 'Treatments (and tasks) Enabled' },
    { name: 'staffEsignatureRequired', label: 'Staff Esignature Required', showSetting: !!settings.staffEsignatureRequired },
  ]

  return features.map((feature) => ({
    ...feature,
    value: settings[feature.name],
  }))
}

export {
  getWorkOSAdminPortalLink,
  updateWorkOSOrgId,
  getCurrentSettings,
  getSettingFeatures,
}
