import React, { useState, useEffect } from 'react'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Tooltip } from 'primereact/tooltip'
import { classNames } from 'primereact/utils'
import get from 'lodash/get'
import RequirementsTooltip from './RequirementsTooltip'

function UsernameInput({ caregiver, onSubmit }) {
  const [isEditing, setIsEditing] = useState(false)
  const [newUsername, setUsername] = useState(get(caregiver, 'username') || '')

  useEffect(() => {
    const username = get(caregiver, 'username') || ''
    setUsername(username)
  }, [caregiver])

  const handleEdit = () => {
    setIsEditing(true)
  }

  const handleSave = () => {
    setIsEditing(false)
    onSubmit(newUsername)
  }

  const handleCancel = () => {
    setIsEditing(false)
  }

  const isUsernameValid = () => newUsername?.length >= 6

  const buttonClass = classNames(
    'p-button-sm',
    'p-button-text',
    'p-button-success',
    { 'disabled-button': !isUsernameValid() },
  )

  if (isEditing) {
    return (
      <div className="flex flex-row p-grid align-items-center">
        <Tooltip target=".username-input" position="top">
          {RequirementsTooltip(newUsername)}
        </Tooltip>
        <div className="p-col">
          <InputText
            className={classNames('p-inputtext-sm', 'block', 'username-input')}
            style={{ width: '100%' }}
            placeholder="username"
            value={newUsername}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="p-col-fixed">
          <Button
            onClick={handleSave}
            className={buttonClass}
            icon="pi pi-check"
            disabled={!isUsernameValid()}
          />
          <Button
            onClick={handleCancel}
            className="p-button-sm p-button-text p-button-secondary extra-small-square-button"
            icon="pi pi-times"
          />
        </div>
      </div>
    )
  }
  return (
    <div className="flex flex-row align-items-center">
      <div className="p-col">
        <div className="d-flex align-items-center">
          <span className="text-sm mr-2 overflow-hidden text-ellipsis">
            {get(caregiver, 'username') || 'Username not set'}
          </span>
        </div>
      </div>
      <div className="p-col-fixed">
        <Button
          onClick={handleEdit}
          className="p-button-sm p-button-text p-button-secondary"
          icon="pi pi-pencil"
        />
      </div>
    </div>
  )
}

export default UsernameInput
