import React from 'react'
import { sigHeader } from '@services/utils/sig'
import { Section } from './SharedComponents'
import { SigFieldGroup } from './SigComponents'

function SigSection({ compositeOrderSig, doseSig }) {
  const { hl7SetId = 1 } = compositeOrderSig
  const helpText = 'Review and edit administration instructions.'

  return (
    <Section header={sigHeader(hl7SetId)} helpText={helpText}>
      <SigFieldGroup compositeOrderSig={compositeOrderSig} doseSig={doseSig} />
    </Section>
  )
}

export default SigSection
