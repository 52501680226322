import React from 'react'
import SelectionSummary from './SelectionSummary'

function ConfirmDetails({
  ekit, drawer, slot, isLoaded,
}) {
  return (
    <div className="flex flex-column gap-2">
      <span className="text-lg font-semibold">Confirm your selection</span>
      <div className="flex flex-row justify-content-between gap-1 align-items-center">
        <SelectionSummary
          ekit={ekit}
          drawer={drawer}
          slot={slot}
        />
        {
          isLoaded && (
          <div className="flex flex-row gap-1">
            <i className="pi pi-check-circle" style={{ fontSize: '1.25rem', color: 'var(--primary-500)' }} />
            <span className="text-primary-500">Loaded</span>
          </div>
          )
        }
      </div>
    </div>
  )
}

export default ConfirmDetails
