import React, { useState } from 'react'
import { Timeline } from 'primereact/timeline'
import moment from 'moment'
import { formatDateTimeLocale, getAdherenceStatusColor } from '@services/utils'
import { getOccurrenceHistoryLabel } from './utils'

// TODO: Standardize history data structure on the backend for generic use
const getHistory = (occurrence) => {
  const history = []

  if (occurrence.completions) {
    occurrence.completions.forEach((completion) => {
      history.push({
        actionedBy: completion.responsible,
        actionedAt: completion.responsibleAt,
        recordedAt: completion.createdAt,
        note: completion.note,
        status: 'completed',
      })
    })
  }

  if (occurrence.holds) {
    occurrence.holds.forEach((hold) => {
      history.push({
        actionedBy: hold.responsible,
        actionedAt: hold.heldAt,
        recordedAt: hold.createdAt,
        note: hold.note || hold.reason,
        status: 'on_hold',
      })
    })
  }

  if (occurrence.misses) {
    occurrence.misses.forEach((miss) => {
      history.push({
        actionedBy: miss.responsible,
        actionedAt: miss.responsibleAt,
        recordedAt: miss.createdAt,
        note: miss.note || miss.reason,
        status: 'missed',
      })
    })
  }

  if (occurrence.refusals) {
    occurrence.refusals.forEach((refusal) => {
      history.push({
        actionedBy: refusal.responsible,
        actionedAt: refusal.responsibleAt,
        recordedAt: refusal.createdAt,
        note: refusal.note || refusal.reason,
        status: 'refused',
      })
    })
  }

  // Return history in descending order
  return history.sort((a, b) => moment(b.recordedAt).diff(moment(a.recordedAt)))
}

const customizedMarker = (item) => (
  <span
    className="w-1rem h-1rem border-circle border-solid"
    style={{ borderColor: getAdherenceStatusColor(item.status) }}
  />
)

function OccurrenceHistory({ occurrence, timezone }) {
  const [showHistory, setShowHistory] = useState(false)

  const history = getHistory(occurrence)

  if (!history.length) {
    return null
  }

  const toggleHistory = () => {
    setShowHistory(!showHistory)
  }

  const historyContent = (item) => (
    <div>
      <div className="text-sm font-normal">
        {`${item?.actionedBy?.firstName} ${item?.actionedBy?.lastName}`}
      </div>
      <div className="text-xs font-normal">{`Recorded: ${formatDateTimeLocale(moment(item.recordedAt), timezone)}`}</div>
      <div className="text-xs font-normal">{`Time: ${formatDateTimeLocale(moment(item.actionedAt), timezone)}`}</div>
      {
        item?.note && (
          <div className="text-xs font-normal">
            Note:
            {' '}
            {item?.note}
          </div>
        )
      }
    </div>
  )

  const historyOppositeContent = (item) => (
    <div className="w-6rem px-3 text-sm font-semibold text-left">
      {getOccurrenceHistoryLabel(item.status)}
    </div>
  )

  return (
    <div>
      {history.length > 0 && (
        <div className="flex flex-row align-items-center cursor-pointer mt-1 w-4rem" onClick={toggleHistory}>
          <div className="text-xs font-medium font-light text-primary">
            Details
          </div>
          <i className={`text-primary text-sm ${showHistory ? 'pi pi-angle-up' : 'pi pi-angle-down'}`} />
        </div>
      )}
      {showHistory && (
        <div className="card px-0">
          <Timeline
            className="flex flex-column align-items-start w-full"
            value={history}
            align="left"
            opposite={historyOppositeContent}
            content={historyContent}
            marker={customizedMarker}
          />
        </div>
      )}
    </div>
  )
}

export default OccurrenceHistory
