import React from 'react'
import { Toast } from 'primereact/toast'
import { isPrn } from '@components/clientDoses/doseUtils'
import EditDoseConditionDialog from './EditDoseConditionDialog'
import CreateDoseConditionDialog from './CreateDoseConditionDialog'

function DoseConditionDialog({
  doseSchedules,
  dose,
  doseConditionStatusMessage,
  selectedDoseCondition,
  setSelectedDoseCondition,
  showCreateDoseConditionDialog,
  setShowCreateDoseConditionDialog,
}) {
  return (
    <>
      <Toast ref={doseConditionStatusMessage} />
      {
        selectedDoseCondition && (
          <EditDoseConditionDialog
            visible
            doseCondition={selectedDoseCondition}
            doseConditionStatusMessage={doseConditionStatusMessage}
            doseSchedules={doseSchedules}
            existingDoseConditions={dose.conditions}
            onHide={() => setSelectedDoseCondition(null)}
            isPrn={isPrn(dose, setSelectedDoseCondition?.doseSig)}
          />
        )
      }
      {
        showCreateDoseConditionDialog && (
          <CreateDoseConditionDialog
            visible
            dose={dose}
            doseConditionStatusMessage={doseConditionStatusMessage}
            doseSchedules={doseSchedules}
            existingDoseConditions={dose.conditions}
            onHide={() => setShowCreateDoseConditionDialog(false)}
          />
        )
      }
    </>
  )
}

export default DoseConditionDialog
