import React from 'react'
import { useCurrentOrganization } from '@components/App'
import LinkButtonNavigator from '@components/display/LinkButtonNavigator'

function Menu() {
  const currentOrganization = useCurrentOrganization()
  const viewLinks = []
  if (!currentOrganization.ekitsEnabled) {
    viewLinks.push({
      label: 'Schedule',
      linkTo: 'schedule',
    })
  }
  viewLinks.push({
    label: 'PRN',
    linkTo: 'prn',
  })

  return (
    <LinkButtonNavigator
      buttons={viewLinks}
      className="flex flex-row justify-space-between align-items-center pr-4"
    />
  )
}
export default Menu
