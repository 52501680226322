import React from 'react'
import { isEmpty, map } from 'lodash'
import { classNames } from 'primereact/utils'
import { Badge } from 'primereact/badge'
import { BreadCrumb } from 'primereact/breadcrumb'
import { Link, useLocation } from 'react-router-dom'
import Notifications from './Notifications'

function PageTitle({ label, linkTo, isActive }) {
  if (!linkTo) {
    return (
      <div className={classNames('p-tabmenuitem mb-2')}>
        <span className="text-2xl text-900">
          {label}
        </span>
      </div>
    )
  }
  return (
    <div className={classNames('p-tabmenuitem cursor-pointer', { 'p-highlight': isActive })}>
      <Link to={linkTo} className="p-menuitem-link flex flex-row align-items-center" data-testid="patient-name-link">
        <div className="p-menuitem-text pl-2">
          <span className="text-2xl text-900 flex align-items-center text-center">
            {label}
          </span>
        </div>
      </Link>
    </div>
  )
}

function PageHeader({
  rootPath, rootLabel, title, tabs, breadcrumbs, primaryAction, notifications,
}) {
  const location = useLocation()

  const homeBreadcrumbTemplate = () => (
    <Link to={rootPath}>
      {rootLabel}
    </Link>
  )

  return (
    <div className="page-header surface-section w-full flex flex-column">
      <div className="flex flex-row align-items-center">
        <div className="flex flex-column w-full">
          {!isEmpty(breadcrumbs)
            && (
            <div className="px-4 pt-3 pb-2">
              <BreadCrumb
                model={breadcrumbs}
                home={{ label: 'Organization', template: homeBreadcrumbTemplate }}
              />
            </div>
            )}
          <div className="p-tabmenu">
            <div className="p-tabmenu-nav w-full flex flex-column md:flex-row">
              <PageTitle
                label={title.label}
                linkTo={title.linkTo}
                isActive={(title.linkTo === location.pathname)}
              />
              {map(tabs, ({
                linkTo, label, badge, icon,
              }) => (
                <div
                  className={classNames(
                    'p-tabmenuitem cursor-pointer',
                    {
                      'p-highlight': location.pathname.includes(linkTo),
                    },
                  )}
                  key={linkTo}
                >
                  <Link to={linkTo} className="p-menuitem-link flex flex-row align-items-center gap-2">
                    {!!badge && <Badge severity={badge.severity} value={badge.value} />}
                    {!!icon
                      && <i className={`pi ${icon}`} />}
                    <div className="p-menuitem-text">
                      {label}
                    </div>
                  </Link>
                </div>
              ))}
              {primaryAction
                && (
                <div
                  className={
                    classNames('md:absolute ml-3 mb-3 md:mr-3 md:ml-0', {
                      'md:mt-2': !isEmpty(breadcrumbs),
                      'md:mt-3': isEmpty(breadcrumbs),
                    })
}
                  style={{ right: '0' }}
                >
                  {primaryAction}
                </div>
                )}
            </div>
          </div>
          <Notifications notifications={notifications} />
        </div>
      </div>
    </div>
  )
}

export default PageHeader
