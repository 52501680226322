import { get, map } from 'lodash'
import { RRule } from 'rrule'
import { dateFromHoursMinutes } from '@services/utils/time'

// eslint-disable-next-line import/prefer-default-export
export const formatSchedules = (scheduleData) => map(scheduleData, (schedule) => {
  const eventTime = get(schedule, 'eventTime')
  let rrule
  if (schedule.rrule) {
    rrule = new RRule(RRule.parseString(schedule.rrule))
  }
  return {
    ...schedule,
    time: dateFromHoursMinutes(schedule.timeHours, schedule.timeMinutes),
    text: rrule ? rrule.toText() : '',
    eventTime: eventTime ? {
      ...eventTime,
      startTime: dateFromHoursMinutes(
        eventTime.startTimeHours,
        eventTime.startTimeMinutes,
      ),
      endTime: dateFromHoursMinutes(
        eventTime.endTimeHours,
        eventTime.endTimeMinutes,
      ),
    } : null,
  }
})
