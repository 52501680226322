import React from 'react'
import { InputTextarea } from 'primereact/inputtextarea'

function ReasonInput({ reason, setReason }) {
  return (
    <div className="flex flex-column gap-1">
      <label className="block" htmlFor="adherence-dialog-form-reason-input">Reason</label>
      <InputTextarea
        id="adherence-dialog-form-reason-input"
        value={reason}
        onChange={(e) => setReason(e.target.value)}
        placeholder="Please enter a reason"
        rows={3}
        cols={30}
      />
    </div>
  )
}

export default ReasonInput
