import { numberToOrdinalWord } from './string'

/**
 * @param {Integer | null} number
 * @returns {String}
 */
export const sigHeader = (number) => {
  if (number === null) {
    return 'Sig'
  }

  const ordinalWord = numberToOrdinalWord(number)
  return ordinalWord ? `${ordinalWord} Sig` : `Sig #${number}`
}

export default {}
