import React, {
  useRef, useState, useEffect, useMemo,
} from 'react'
import { get, isEmpty } from 'lodash'
import { useNavigate, Link, useSearchParams } from 'react-router-dom'
import { Button } from 'primereact/button'
import { Tag } from 'primereact/tag'
import { useCurrentUser, useCurrentOrganization } from '@components/App'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Checkbox } from 'primereact/checkbox'
import { useMedBoxesQuery } from './medboxesHooks'
import { useSitesQuery } from '../Selects/SitesSelect/SitesSelectHooks'
import SitesSelect from '../Selects/SitesSelect/SitesSelect'
import {
  SUPER_ADMIN_ROLE,
  MED_BOX_TYPE,
  LABELS,
  MED_BOXES_PATH,
  MED_BOX_STATUS_TAG_COLOR,
  NOT_APPLICABLE_TEXT,
  E_KITS_PATH,
  STRIP_MED_BOX_TYPE,
} from './config'

const filterMedBoxesBySite = (medBoxes, siteIds) => {
  const medBoxesFiltered = medBoxes.filter((medBox) => {
    const includesSiteId = isEmpty(siteIds) || siteIds.includes(medBox.siteId)
    return includesSiteId
  })
  return medBoxesFiltered
}

function MedBoxesTable({ useMedBoxesHeader, type }) {
  const statusMessage = useRef(null)
  const navigate = useNavigate()
  const [queryParams, setQueryParams] = useSearchParams()
  const organization = useCurrentOrganization()
  const user = useCurrentUser()
  const isSuperAdmin = user.role === SUPER_ADMIN_ROLE
  const { setHeader, maxDataViewHeight } = useMedBoxesHeader()
  const [medBoxes, setMedBoxes] = useState([])
  const [isShowingArchived, setIsShowingArchived] = useState(false)
  const query = useMedBoxesQuery(organization.id, { includeArchived: isShowingArchived })
  const { data: { sites } = [], isLoading: sitesLoading } = useSitesQuery({
    statusMessage,
    organizationId: organization.id,
  })

  const labelText = LABELS[type]

  const basePath = type === MED_BOX_TYPE ? MED_BOXES_PATH : E_KITS_PATH
  const siteIdsParams = useMemo(() => queryParams.getAll('siteIds').map((id) => parseInt(id, 10)), [queryParams.toString()])

  const handleSiteIdsChange = (selection) => {
    const newParams = { siteIds: selection }
    setQueryParams({ ...newParams })
    const medboxes = get(query, 'data.simpills', [])
    const medBoxesFiltered = filterMedBoxesBySite(medboxes, selection)
    setMedBoxes(medBoxesFiltered)
  }

  const primaryAction = (
    <div className="flex flex-row-reverse gap-2 justify-content-start">
      {
        isSuperAdmin && (
          <Link to={`/${basePath}/new`}>
            <Button className="p-button-sm p-button-custom" label={`Create ${labelText}`} />
          </Link>
        )
      }
      <div className="flex flex-row gap-3 align-items-center">
        <SitesSelect
          oneOrganization
          sites={sites}
          selectedSites={siteIdsParams}
          loading={sitesLoading}
          onChange={handleSiteIdsChange}
          placeholder="Filter by Site"
        />
      </div>
    </div>
  )

  const title = {
    label: (
      <div className="p-3">
        <span className="flex align-items-center justify-content-center">
          <i className="text-xl pi pi-box mx-2" />
          {labelText}
        </span>
      </div>
    ),
  }

  useEffect(() => {
    setHeader({
      title,
      tabs: [],
      breadcrumbs: [],
      primaryAction,
    })
  }, [sitesLoading, siteIdsParams])

  useEffect(() => {
    if (query.data) {
      const medBoxesFiltered = filterMedBoxesBySite(query.data.simpills, siteIdsParams)
      setMedBoxes(medBoxesFiltered)
    }
  }, [query.data])

  const devicesFiltered = medBoxes.filter((simpill) => (simpill.deviceType === type
    || (type === MED_BOX_TYPE && simpill.deviceType === STRIP_MED_BOX_TYPE)))

  if (!query.isLoading && devicesFiltered.length === 0) {
    return (
      <div className="m-4 bg-white p-4">
        <div>
          No
          <span className="mx-1">
            {labelText}
          </span>
          found
        </div>
      </div>
    )
  }

  const userListTemplate = (rowData) => (
    <ul>
      {rowData.patients.map((userRow) => (
        <li key={userRow.id}>{userRow.fullName}</li>
      ))}
    </ul>
  )

  const medBoxStatusTemplate = (rowData) => {
    const { status } = rowData
    const tagColor = MED_BOX_STATUS_TAG_COLOR[status] || ''
    return (
      <div>
        {tagColor ? <Tag style={{ background: tagColor }}>{status}</Tag> : NOT_APPLICABLE_TEXT}
      </div>
    )
  }

  const medBoxArchivedTemplate = (rowData) => {
    const { archivedAt } = rowData
    if (isEmpty(archivedAt)) return
    return (
      <Tag style={{ background: 'var(--bluegray-200)' }}>Archived</Tag>
    )
  }

  const archivedFilterTemplate = () => {
    const options = [
      {
        label: 'Archived',
        color: 'var(--bluegray-200)',
      },
    ]

    const handleFilterChange = (event) => {
      setIsShowingArchived(event.checked)
    }

    const itemTemplate = (option) => (
      <div className="flex align-items-center" key={option.label}>
        <Checkbox
          inputId={option.label}
          name={option.label}
          value={option.value}
          onChange={handleFilterChange}
          checked={isShowingArchived}
        />
        <label htmlFor={option.label} className="ml-2">
          <Tag
            style={{ background: option.color }}
            className="text-small font-normal p-1"
            value={option.label}
          />
        </label>
      </div>
    )

    return (
      <div className="flex flex-column gap-2">{options.map(itemTemplate)}</div>
    )
  }

  return (
    <div>
      <div className="m-4 bg-white p-4">
        <div className="grid">
          <DataTable
            dataKey="id"
            value={devicesFiltered}
            loading={query.isLoading}
            selectionMode="single"
            onSelectionChange={(e) => navigate(`/${basePath}/${e.value.id}`)}
            sortField="label"
            sortOrder={1}
            className="mt-1 w-full"
            rowClassName="fadein caregiver-row"
            scrollable
            scrollHeight={maxDataViewHeight ? maxDataViewHeight - 10 : null}
          >
            <Column field="label" header="Label" sortable />
            <Column field="status" header="Status" sortable body={medBoxStatusTemplate} />
            <Column field="site.name" header="Site" sortable />
            {
                type === MED_BOX_TYPE && (
                  <Column
                    body={userListTemplate}
                    header="Users"
                    className="w-auto"
                  />
                )
              }
            {
                isSuperAdmin && (
                  <Column
                    body={medBoxArchivedTemplate}
                    header="Archived"
                    className="w-auto"
                    sortable
                    filter
                    filterElement={archivedFilterTemplate}
                    showFilterMatchModes={false}
                    showApplyButton={false}
                    showClearButton={false}
                    showAddButton={false}
                    showFilterMenuOptions={false}
                    style={{ wordBreak: 'break-all', minWidth: '15%' }}
                  />
                )
              }
          </DataTable>
        </div>
      </div>
    </div>
  )
}

export default MedBoxesTable
