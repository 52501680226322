import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { ProfileForm, SettingsForm, SiteForm } from '@components/admins/AdminForm'
import FormWrapper from '@components/display/Form/FormWrapper'
import { useCurrentOrganization } from '@components/App'
import { useOrgSitesQuery } from '@components/sites/sitesHooks'
import { usePageOutlet as useAdminHeader } from '@components/display/PageLayout/PageLayout'
import {
  useUserTitleOptions,
  useCreateAdminMutation,
} from './adminsHooks'
import getAssignedSiteIds from '../SSODirectoryUsers/utils'

function CreateAdmin({
  createAdminMutation,
  pendingUser = {},
  isSsoOnboarding = false,
  formTitle,
  shouldRenderHeader = true,
  onboardingInProgress,
}) {
  const {
    email = '', firstName = '', lastName = '', sites = [],
  } = pendingUser

  const statusMessage = useRef(null)
  const navigate = useNavigate()
  const { setHeader } = useAdminHeader()
  const organization = useCurrentOrganization()
  const {
    data: orgSites,
    isLoading: isSitesLoading,
  } = useOrgSitesQuery(organization.id, statusMessage)

  const [
    assignedSiteIds,
    setAssignedSiteIds,
  ] = useState(getAssignedSiteIds(sites))

  const canViewSites = !organization.deviceBasedAccess

  const [profile, setProfile] = useState({
    email,
    phone: '',
    firstName,
    lastName,
    customerId: '',
  })
  const [userTitle, setUserTitle] = useState({})

  const [settings, setSettings] = useState({
    ccmEligible: false,
    canApprovePermissionRequests: true,
    shouldReceivePharmacyOrderNotifications: true,
    shouldReceivePendingRefillsNotifications: organization.ekitsEnabled,
    ssoAdminAccess: false,
  })

  const {
    mutateAsync: defaultOnCreateAdmin,
    isLoading: createIsLoading,
  } = useCreateAdminMutation(statusMessage)

  const {
    data: titleOptions,
    isLoading: isTitleOptionsLoading,
  } = useUserTitleOptions()

  const handleCreateAdmin = () => {
    const createAdmin = createAdminMutation || defaultOnCreateAdmin
    createAdmin({
      ...profile,
      ...settings,
      userTitleAttributes: { title: userTitle.abbreviation },
      organizationId: organization.id,
      siteAdminSiteAccessIds: assignedSiteIds,
      adminSettingsAttributes: {
        canApprovePermissionRequests: settings.canApprovePermissionRequests,
        shouldReceivePharmacyOrderNotifications: settings.shouldReceivePharmacyOrderNotifications,
        shouldReceivePendingRefillsNotifications: settings.shouldReceivePendingRefillsNotifications,
      },
    }, {
      onSuccess: (data) => {
        navigate(`/admin/admins/${data.id}`)
      },
    })
  }

  const pageTitle = {
    label: (
      <span>
        <i className="text-xl pi pi-users mr-2" />
        {organization.adminLabelSingular}
      </span>
    ),
    linkTo: '/admin/admins',
  }

  const breadcrumbs = [
    {
      label: 'Create',
      template: <span>Create</span>,
    },
  ]

  useEffect(() => {
    if (shouldRenderHeader) {
      setHeader({ title: pageTitle, breadcrumbs, primaryAction: null })
    }
  }, [])

  return (
    <div id="create-admin" className="flex flex-column">
      <div className="flex flex-row flex-wrap">
        <Toast ref={statusMessage} />
        <div className="col-6">
          <FormWrapper title={formTitle} fullHeight>
            <ProfileForm
              profile={profile}
              setProfile={(value) => setProfile((p) => ({ ...p, ...value }))}
              isSsoOnboarding={isSsoOnboarding}
            />
            <div className="field">
              {/* eslint-disable-next-line */}
              <label className="block">Title</label>
              <Dropdown
                value={userTitle}
                options={titleOptions}
                dataKey="abbreviation"
                optionLabel="title"
                onChange={(e) => setUserTitle(e.value)}
                placeholder="Select a title"
                loading={isTitleOptionsLoading || createIsLoading}
                disabled={createIsLoading}
                className="w-full"
                filter
              />
            </div>
            <SettingsForm settings={settings} setSettings={setSettings} className="mt-2" />
            {
              canViewSites && (
                <div className="field">
                  {/* eslint-disable-next-line */}
                  <label className="block">Sites</label>
                  <SiteForm
                    sites={orgSites}
                    selectedSites={assignedSiteIds}
                    setSelectedSites={(siteIds) => setAssignedSiteIds(siteIds)}
                    isSitesLoading={isSitesLoading}
                    isSsoOnboarding={isSsoOnboarding}
                  />
                </div>
              )
            }
            <Button
              label={isSsoOnboarding ? 'Onboard' : 'Save'}
              className="p-button-sm m-2"
              loading={createIsLoading || onboardingInProgress}
              onClick={handleCreateAdmin}
            />
          </FormWrapper>
        </div>
      </div>
    </div>
  )
}

export default CreateAdmin
