import React, { useState } from 'react'
import moment from 'moment'
import { Button } from 'primereact/button'
import { useCurrentUser } from '@components/App'
import { dateTimeTemplateFromMoment } from '@services/utils'
import UpdateClinicalNoteDialog from './UpdateClinicalNoteDialog'

function ClinicalNoteCard({ statusMessage, ...clinicalNote }) {
  const [isUpdateDialogVisible, setIsUpdateDialogVisible] = useState(false)
  const currentUser = useCurrentUser()

  return (
    <>
      <UpdateClinicalNoteDialog
        visible={isUpdateDialogVisible}
        setVisible={setIsUpdateDialogVisible}
        clinicalNote={clinicalNote}
        statusMessage={statusMessage}
      />
      <div className="flex flex-column w-full py-2">
        <div className="flex flex-row align-items-center w-full gap-2">
          <span className="font-semibold">{dateTimeTemplateFromMoment(moment(clinicalNote.updatedAt))}</span>
          <span>|</span>
          <span className="text-600">
            {clinicalNote.author.fullName}
          </span>
          {
            currentUser.id === clinicalNote.author.id && (
              <Button
                className="p-button-sm p-button-text ml-auto"
                icon="pi pi-pencil"
                label="Edit"
                tooltipOptions={{ position: 'top' }}
                onClick={() => {
                  setIsUpdateDialogVisible(true)
                }}
              />
            )
          }
        </div>
        <p>{clinicalNote.note}</p>
      </div>
    </>
  )
}

export default ClinicalNoteCard
