import React, { useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { Toast } from 'primereact/toast'
import Exports from '@components/patients/Exports'
import PharmacyOrdersCard from '@components/pharmacyOrders/Card/PharmacyOrdersCard'
import Redirect from '@components/routing/Redirect'
import { TaskConditionsCard } from '@components/tasks/TaskConditionsCard'
import { TaskTable } from '@components/tasks/TaskTable'
import TreatmentTable from '@components/treatments/TreatmentTable'
import { useTreatment } from '@hooks/treatments'
import { getTreatmentRoute } from '@utils/routes'

function ManageTreatmentPage({ handleSetHeader, patient }) {
  const statusMessage = useRef(null)
  const { patientId: clientId, treatmentId } = useParams()

  const {
    data: treatment,
    isLoading,
  } = useTreatment({ treatmentId })

  useEffect(() => handleSetHeader({ primaryAction: null }), [patient])

  if (isLoading || isEmpty(treatment) || isEmpty(patient)) {
    return 'Loading...'
  }

  // If the treatment hasn't been onboarded yet, redirect to the treatment's review page
  if (!treatment.onboardedAt) {
    const treatmentRoute = getTreatmentRoute(clientId, treatment.id, 'review')
    return <Redirect to={treatmentRoute} />
  }

  const primaryActionSetup = (action) => {
    handleSetHeader({ primaryAction: action })
  }

  const tasks = treatment.tasks || []
  const showTaskConditions = tasks.length === 1

  return (
    <>
      <Toast ref={statusMessage} />
      <Exports
        patientId={clientId}
        exportsMenuSetupCallBack={primaryActionSetup}
      />
      {/* TODO: TreatmentHeader component with treatment lifecycle actions */}
      <TreatmentTable
        treatment={treatment}
        timezone={patient.timezone}
        canEdit
      />
      {
        tasks.map((task) => (
          <TaskTable
            key={task.id}
            task={task}
            timezone={patient.timezone}
          />
        ))
      }
      {
        showTaskConditions && (
          <TaskConditionsCard task={tasks[0]} />
        )
      }
      <PharmacyOrdersCard model="Treatment" id={treatment.id} />
    </>
  )
}

export default ManageTreatmentPage
