import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { roleToOrganizationLabel } from '@services/utils'

function DataTableHeader({ title, action }) {
  return (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="font-bold">{title}</span>
      {action && <Button icon="pi pi-user-plus" label="Create New User" raised onClick={action} />}
    </div>
  )
}

function UserInfoTableWithMatchIndicator({
  users,
  loading,
  organization,
  title,
  linkButton,
  matchTemplate,
}) {
  if (users && users.length === 0 && !loading) {
    return (
      <div className="text-lg font-bold">
        <span>No match found</span>
      </div>
    )
  }

  return (
    <DataTable
      header={(<DataTableHeader title={title} />)}
      loading={loading}
      value={users}
      showGridlines
      tableStyle={{ minWidth: '50rem' }}
    >
      <Column
        field="firstName"
        header="First Name"
        {...(matchTemplate && { body: (linkable) => matchTemplate('firstName', linkable.user.firstName) })}
      />
      <Column
        field="lastName"
        header="Last Name"
        {...(matchTemplate && { body: (linkable) => matchTemplate('lastName', linkable.user.lastName) })}
      />
      <Column
        field="email"
        header="Email"
        {...(matchTemplate && { body: (linkable) => matchTemplate('email', linkable.user.email) })}
      />
      <Column
        field="role"
        header="Role"
        {...(matchTemplate && { body: (linkable) => matchTemplate('role', roleToOrganizationLabel(linkable.user.role, organization, { singular: true })) })}
      />
      <Column
        field="dateOfBirth"
        header="Date of Birth"
        {...(matchTemplate && { body: (linkable) => matchTemplate('dateOfBirth', linkable.user.dateOfBirth) })}
      />
      <Column
        header="Link"
        className="w-2"
        {...(linkButton && { body: (linkable) => linkButton(linkable.user) })}
      />
    </DataTable>
  )
}

export default UserInfoTableWithMatchIndicator
