import React from 'react'
import InputTextWithLabel from '@components/display/InputTextWithLabel'

function ProfileForm({ profile = {}, setProfile }) {
  const { email, firstName, lastName } = profile
  return (
    <div className="flex flex-column">
      <InputTextWithLabel
        label="Email"
        value={email || ''}
        inputId="email"
        name="email"
        onChange={({ target: { value } }) => setProfile({ email: value })}
      />
      <InputTextWithLabel
        label="First Name"
        value={firstName || ''}
        inputId="firstName"
        name="firstName"
        onChange={({ target: { value } }) => setProfile({ firstName: value })}
      />
      <InputTextWithLabel
        label="Last Name"
        value={lastName || ''}
        inputId="lastName"
        name="lastName"
        onChange={({ target: { value } }) => setProfile({ lastName: value })}
      />
    </div>
  )
}

export default ProfileForm
