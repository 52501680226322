import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

// eslint-disable-next-line import/prefer-default-export
export function useNarcoticCountsDashboardsQuery(siteId, dateRange, statusMessage) {
  const query = useQuery({
    queryKey: ['patientNarcotics', siteId, dateRange],
    queryFn: async () => {
      const params = {
        startDate: dateRange.startDate.toISOString(),
        endDate: dateRange.endDate.toISOString(),
        siteId,
      }
      return ApiClient.get('/narcotic_counts/dashboards', { params })
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })

  const transformedData = useMemo(() => {
    if (query.data && query.data.patientsWithNarcotics) {
      return query.data.patientsWithNarcotics
    }
    return null
  }, [query.data])

  return {
    ...query,
    data: transformedData,
  }
}
