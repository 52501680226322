import React, { useRef } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Toast } from 'primereact/toast'
import ReviewList from './ReviewList'
import ReviewDoseOrders from './ReviewDoseOrders'
import { useReviewablePharmacyOrders } from './pharmacyOrderHooks'

function PharmacyOrders({ patientId }) {
  const statusMessage = useRef(null)

  const {
    isLoading,
    data: reviewableOrders,
  } = useReviewablePharmacyOrders(statusMessage, patientId)

  return (
    <div className="flex flex-column">
      <Toast ref={statusMessage} />
      <Routes>
        <Route
          index
          element={<ReviewList reviewableOrders={reviewableOrders} isLoading={isLoading} />}
        />
        <Route
          path=":groupKey"
          element={(
            <ReviewDoseOrders
              reviewableOrders={reviewableOrders}
              isLoading={isLoading}
              statusMessage={statusMessage}
            />
          )}
        />
      </Routes>
    </div>
  )
}

export default PharmacyOrders
