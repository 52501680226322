import React from 'react'
import pluralize from 'pluralize'
import TextWithTooltip from '@components/display/TextWithTooltip'

function MedBoxesList({ medboxes }) {
  const label = 'Med Box'
  return (
    <div className="flex flex-row align-items-center">
      <span className="p-0 mr-1 text-600 col-5">
        {medboxes.length > 1 ? pluralize(label) : label}
        :
      </span>
      <div className="col-7 flex flex-column gap-2 p-0">
        {
        medboxes.map((medbox) => (
          <TextWithTooltip key={medbox.id} linkTo={`/admin/med_boxes/${medbox.id}`} text={medbox.label} />
        ))
      }
      </div>
    </div>
  )
}

export default MedBoxesList
