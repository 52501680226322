import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import {
  get, map, startCase, camelCase,
} from 'lodash'

const titleCase = (name) => startCase(camelCase(name))

function usePendingUsers(organizationId, canViewPendingUsers) {
  const query = useQuery({
    queryKey: ['pendingUsers', organizationId],
    queryFn: () => (
      ApiClient.get(`/organizations/${organizationId}/pending_users`)
    ),
    enabled: !!organizationId && canViewPendingUsers,
  })

  return useMemo(() => ({
    ...query,
    data: map(get(query.data, 'pendingUsers'), (user) => ({
      ...user,
      firstName: titleCase(user.firstName),
      lastName: titleCase(user.lastName),
    })),
  }), [query])
}

export default usePendingUsers
