import React, { useRef } from 'react'
import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'
import { titleize } from '@services/utils/string'
import TarAdherenceForm from './TarAdherenceForm'

const dialogHeaderTemplate = (taskOccurrence) => {
  const title = taskOccurrence
    ? `Edit ${titleize(`${taskOccurrence.status} Task Result`)}`
    : 'Add Task Result'

  return (
    <div className="flex flex-column align-content-start gap-2">
      <div className="font-bold text-lg">{title}</div>
      <span className="font-normal line-height-2 text-sm">Please enter the details of the task</span>
    </div>
  )
}

function TarDialog({
  visible,
  onHide,
  taskOccurrence,
  cellTime,
  tarTimeRow,
  timezone,
  mode,
  patientId,
}) {
  const statusMessage = useRef(null)

  if (!cellTime || !tarTimeRow) {
    return null
  }

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header={() => dialogHeaderTemplate(taskOccurrence)}
      className="w-30rem"
      draggable={false}
    >
      <Toast ref={statusMessage} />
      <div className="flex flex-column w-full gap-4">
        <TarAdherenceForm
          onHide={onHide}
          taskOccurrence={taskOccurrence}
          cellTime={cellTime}
          tarTimeRow={tarTimeRow}
          timezone={timezone}
          mode={mode}
          patientId={patientId}
          statusMessage={statusMessage}
        />
      </div>
    </Dialog>
  )
}

export default TarDialog
