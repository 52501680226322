import React, {
  createContext, useContext, useMemo, useReducer,
} from 'react'
import createActionHandlers from './actionHandlers'
import initialState from './initialState'
import reviewableOrderReducer from './reviewableOrderReducer'

export const ReviewableOrderStateContext = createContext(null)
export const ReviewableOrderHandlersContext = createContext(null)

export const useReviewableOrderState = () => {
  const context = useContext(ReviewableOrderStateContext)

  if (context === undefined) {
    throw new Error('useReviewableOrderState must be used within a ReviewableOrderProvider')
  }

  return context
}

export const useReviewableOrderHandlers = () => {
  const context = useContext(ReviewableOrderHandlersContext)

  if (context === undefined) {
    throw new Error('useReviewableOrderHandlers must be used within a ReviewableOrderProvider')
  }

  return context
}

export function ReviewableOrderProvider({ children, reviewableOrder = {} }) {
  const [state, dispatch] = useReducer(reviewableOrderReducer, reviewableOrder, initialState)

  const handlers = useMemo(() => createActionHandlers(dispatch, state), [dispatch, state])

  return (
    <ReviewableOrderStateContext.Provider value={state}>
      <ReviewableOrderHandlersContext.Provider value={handlers}>
        {children}
      </ReviewableOrderHandlersContext.Provider>
    </ReviewableOrderStateContext.Provider>
  )
}

export default {}
