import React from 'react'
import { Button } from 'primereact/button'
import moment from 'moment'
import { useCurrentOrganization } from '@components/App'
import { getReasonLabel } from '@components/patients/AdherenceDashboard/Dialog/Form/ReasonDropdown'
import { useConsumptionHistory } from '@hooks/consumptions'
import { momentFormats } from '@services/utils/moment'
import { titleize } from '@services/utils/string'
import DoseConsumptionsHistory from './DoseConsumptionsHistory'

const getStatusInfo = (status) => {
  if (!status) return {}
  switch (status) {
    case 'confirmed':
      return {
        label: 'Confirmed',
        iconClass: 'p-bg-circle-given',
      }
    case 'refused':
      return {
        label: 'Refused',
        iconClass: 'p-bg-circle-refusal',
      }
    case 'missed':
      return {
        label: 'Missed',
        iconClass: 'p-bg-circle-missed',
      }
    case 'LOA':
      return {
        label: 'Leave of Absence',
        iconClass: 'p-bg-circle-loa',
      }
    case 'on_hold':
      return {
        label: 'On Hold',
        iconClass: 'p-bg-circle-on-hold',
      }
    default:
      return {
        label: 'Unspecified',
        iconClass: 'p-bg-circle-unspecified',
      }
  }
}

function StatusHeader({ consumption, handleEdit }) {
  const { status } = consumption
  const { label, iconClass } = getStatusInfo(status)

  return (
    <div className="flex flex-row align-items-center">
      <div className="flex flex-row gap-2">
        <i className={`pi pi-circle-fill ${iconClass}`} />
        <div className="text-base font-medium line-height-2">{label}</div>
      </div>
      { handleEdit && (
        <Button label="Edit" className="p-button-sm p-button-text text-xs p-0 ml-auto" onClick={() => handleEdit(consumption)} />
      )}
    </div>
  )
}

function StatusDetails({
  expectedAt, confirmedBy, confirmedAt, missedReason, refusedReason, status, isPrn, prnReason, note,
  timezone,
}) {
  const { patientLabelSingular } = useCurrentOrganization()

  const missedOrRefused = status === 'missed' || status === 'refused'
  const hasReasons = isPrn || missedOrRefused
  const refusedReasonText = getReasonLabel(patientLabelSingular, 'refused', refusedReason)
  const reason = status === 'missed' ? missedReason : refusedReasonText

  return (
    <div className="flex flex-column w-full text-left">
      <div className="flex flex-column mt-2 row-gap-2">
        { expectedAt && (
        <div className="flex flex-row gap-1">
          <span className="text-xs">Expected:</span>
          <span className="text-xs font-medium">{moment(expectedAt).tz(timezone).format(momentFormats.dateYearTime)}</span>
        </div>
        )}
        {confirmedAt && (
        <div className="flex flex-row gap-1">
          <div className="flex flex-row gap-1">
            <span className="text-xs">{`${titleize(status)}:`}</span>
            <span className="text-xs font-medium">{moment(confirmedAt).tz(timezone).format(momentFormats.dateYearTime)}</span>
            <span className="text-xs">by</span>
            <span className="text-xs font-medium">{confirmedBy.fullName}</span>
          </div>
        </div>
        )}
      </div>
      {!hasReasons ? <div className="mt-2" /> : (
        <div className="flex flex-column row-gap-2 mt-3 mb-2">
          {isPrn && (
            <div className="flex flex-row gap-1">
              <span className="text-xs">PRN Reason: </span>
              <span className="text-xs font-medium">{prnReason || 'Unspecified'}</span>
            </div>
          )}
          {missedOrRefused && (
            <div className="flex flex-row gap-1">
              <span className="text-xs">Reason: </span>
              <span className="text-xs font-medium">{reason || note || 'Unspecified'}</span>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

function StatusOverlayContent({ timezone, consumption, handleEdit }) {
  const {
    data: consumptionWithHistory,
    isLoading,
  } = useConsumptionHistory({ consumption })

  if (isLoading) {
    return null
  }

  return (
    <div className="flex flex-column p-1">
      <StatusHeader consumption={consumptionWithHistory} handleEdit={handleEdit} />
      <StatusDetails {...consumptionWithHistory} timezone={timezone} />
      <DoseConsumptionsHistory
        timezone={timezone}
        historyData={consumptionWithHistory.administrationEvents || []}
      />
    </div>
  )
}

export default React.memo(StatusOverlayContent)
