import { filter, split } from 'lodash'
import { RRule } from 'rrule'
import { titleCase } from '@services/utils'
import { formatTimeAsHoursAndMinutes } from '@services/utils/time'

export function rruleWithStartEnd(rrule) {
  const rruleOptions = RRule.parseString(rrule)
  return new RRule(rruleOptions)
}

export function formatSchedule(action) {
  const { schedule, startAt, endAt } = action
  const { momentTime } = schedule
  const time = momentTime || schedule.time
  const rruleForDose = rruleWithStartEnd(schedule.rrule, { expectedAt: time, startAt, endAt })

  return {
    ...schedule,
    time,
    time_hh_mm: formatTimeAsHoursAndMinutes(time),
    rruleForDose: rruleForDose.toString(),
    text: titleCase(rruleForDose.toText()),
    flexibility: schedule.flexibilityAttributes || schedule.flexibility,
    // The rrule.js library converts rrules to string
    // in format "RRULE:FREQ=WEEKLY;INTERVAL=1;BYDAY=WE,FR"
    // The below line extracts the rule itself, omitting the "RRULE:"
    // so that it is compatible with API validations
    rrule: filter(split(schedule.rrule, ':'), (rule) => rule !== 'RRULE').join(';'),
  }
}

export const updateSig = (state, action, field) => {
  const newState = {
    ...state,
    sigs: state.sigs.map((sig) => {
      if (sig.id === action.id) {
        return {
          ...sig,
          [field]: action[field],
        }
      }
      return { ...sig }
    }),
  }

  if (state.sigs[0].id === action.id) {
    newState[field] = action[field]
  }

  return newState
}

export default {}
