import React from 'react'
import { Avatar } from 'primereact/avatar'

function PatientAvatarThumbnail(patient) {
  const { avatar, initials } = patient

  if (avatar == null) {
    // TODO: Remove this optional chaining operator when we add not null constraint to initials
    return <Avatar label={initials?.slice(0, 2)} style={{ fontSize: '1rem' }} size="large" shape="circle" />
  }

  return <Avatar image={avatar.url} size="large" shape="circle" />
}

export default PatientAvatarThumbnail
