import React, { useState, useEffect } from 'react'
import { InputText } from 'primereact/inputtext'
import { InputIcon } from 'primereact/inputicon'
import { classNames } from 'primereact/utils'
import { IconField } from 'primereact/iconfield'

function SearchField({
  className = 'w-18rem', isLoading, placeholder, searchTerm, setSearchTerm, debounceMs = 200,
}) {
  const [localSearchTerm, setLocalSearchTerm] = useState(searchTerm)

  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchTerm(localSearchTerm)
    }, debounceMs)

    return () => {
      clearTimeout(handler)
    }
  }, [localSearchTerm, setSearchTerm, debounceMs])

  return (
    <IconField iconPosition="left">
      <InputIcon className={classNames({ 'pi pi-spin pi-spinner': isLoading, 'pi pi-search': !isLoading })} />
      <InputText
        className={className}
        value={localSearchTerm || ''}
        onChange={(e) => setLocalSearchTerm(e.target.value)}
        placeholder={placeholder}
      />
    </IconField>
  )
}

export default SearchField
