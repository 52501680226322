import React from 'react'
import IntegrationDataTable from '@components/integrationData/IntegrationDataTable'
import { useFacilityIntegrationDataQuery } from '@components/integrationData/integrationDataHooks'

function SucceededIntegrationData({ facilityId }) {
  const {
    data: failedData,
    isLoading,
  } = useFacilityIntegrationDataQuery(facilityId, 'succeeded')

  return (
    <div>
      <IntegrationDataTable
        integrationData={failedData}
        isLoading={isLoading}
        showIgnored={false}
        showFailed={false}
      />
    </div>
  )
}

export default SucceededIntegrationData
