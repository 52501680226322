import React, { useState } from 'react'
import { Button } from 'primereact/button'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { Dialog } from 'primereact/dialog'
import { convertTimeToDate } from '@services/utils'
import ShiftDialogBody from './ShiftDialogBody'
import { useUpdateSiteShift, useDeleteSiteShift } from './siteShiftsHooks'

function EditShiftDialog({
  onHide,
  shift,
  statusMessage,
  visible,
}) {
  const [startDayOfWeek, setStartDayOfWeek] = useState(shift.startDayOfWeek)
  const [endDayOfWeek, setEndDayOfWeek] = useState(shift.endDayOfWeek)
  const [startTime, setStartTime] = useState(convertTimeToDate(
    shift.startTimeHour,
    shift.startTimeMinute,
  ))
  const [endTime, setEndTime] = useState(convertTimeToDate(shift.endTimeHour, shift.endTimeMinute))

  const {
    mutateAsync: updateShift,
    isLoading: isUpdatingShift,
  } = useUpdateSiteShift(statusMessage)

  const {
    mutateAsync: deleteShift,
    isLoading: isDeletingShift,
  } = useDeleteSiteShift(statusMessage)

  const onUpdateShift = () => {
    const newShift = {
      id: shift.id,
      startDayOfWeek,
      endDayOfWeek,
      startTimeHour: startTime.getHours(),
      startTimeMinute: startTime.getMinutes(),
      endTimeHour: endTime.getHours(),
      endTimeMinute: endTime.getMinutes(),
    }
    updateShift(
      newShift,
      {
        onSuccess: () => {
          onHide()
        },
      },
    )
  }

  const onDeleteShift = () => {
    deleteShift(
      {
        id: shift.id,
      },
      {
        onSuccess: () => {
          onHide()
        },
      },
    )
  }

  const dialogHeaderTemplate = () => (
    <div className="flex flex-row align-items-baseline w-full justify-content-center">
      <span className="font-semibold text-lg flex flex-column gap-2">
        Edit Shift
      </span>
      <Button
        icon="pi pi-trash"
        className="p-button-text p-button-danger"
        type="button"
        loading={isDeletingShift}
        onClick={() => confirmDialog({
          message: 'Are you sure you want to delete this shift?',
          header: 'Confirm',
          icon: 'pi pi-exclamation-triangle',
          accept: () => onDeleteShift(),
        })}
      />
    </div>
  )

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header={dialogHeaderTemplate}
    >
      <ConfirmDialog />
      <ShiftDialogBody
        startDayOfWeek={startDayOfWeek}
        setStartDayOfWeek={setStartDayOfWeek}
        startTime={startTime}
        setStartTime={setStartTime}
        endDayOfWeek={endDayOfWeek}
        setEndDayOfWeek={setEndDayOfWeek}
        endTime={endTime}
        setEndTime={setEndTime}
        isSubmitting={isUpdatingShift}
        onSubmit={onUpdateShift}
        onSubmitLabel="Update"
      />
    </Dialog>
  )
}

export default EditShiftDialog
