import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

function SelectableUserList({
  users, possibleUsers, selectedUsers, setSelectedUsers, isEditing, isLoading, onUserClick,
}) {
  return (
    <DataTable
      value={
        isEditing ? possibleUsers : users
      }
      selection={selectedUsers}
      loading={isLoading}
      onSelectionChange={(e) => {
        setSelectedUsers(e.value)
      }}
      dataKey="id"
      onRowClick={(e) => {
        if (onUserClick && !isEditing) {
          onUserClick(e.data)
        }
      }}
      rowClassName={() => {
        if (onUserClick) {
          return 'cursor-pointer'
        }
      }}
      size="large"
    >
      {isEditing && (
        <Column
          selectionMode="multiple"
          headerStyle={{ width: '3rem' }}
        />
      )}
      <Column field="firstName" header="First Name" />
      <Column field="lastName" header="Last Name" />
      <Column field="email" header="Email" />
    </DataTable>
  )
}

export default SelectableUserList
