import { momentFormats, momentFromIso8601Str } from '@services/utils/moment'
import React from 'react'

function MarSigDetails({ doseSig }) {
  const startAt = momentFromIso8601Str(doseSig.startAtWall)
  const endAt = momentFromIso8601Str(doseSig.endAtWall)
  return (
    <div className="flex flex-column gap-1 text-left" style={{ fontSize: '0.60rem' }}>
      {doseSig.pharmacyInstructions && <span>{doseSig.pharmacyInstructions}</span>}
      { startAt?.isValid() && (
      <span>
        Start:
        {' '}
        {startAt.format(momentFormats.dateYear)}
      </span>
      ) }
      { endAt?.isValid() && (
      <span>
        End:
        {' '}
        {endAt.format(momentFormats.dateYear)}
      </span>
      ) }
    </div>
  )
}

export default MarSigDetails
