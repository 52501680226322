import { useMemo } from 'react'
import { get } from 'lodash'
import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'

const getPath = (model, id) => {
  switch (model) {
    case 'Dose':
      return `/doses/${id}/pharmacy_orders`
    case 'Prescription':
      return `/prescriptions/${id}/pharmacy_orders`
    case 'Treatment':
      return `/treatments/${id}/pharmacy_orders`
    default:
      throw new Error(`Unsupported model: ${model}`)
  }
}

const usePharmacyOrders = (model, id) => {
  const path = getPath(model, id)

  const query = useQuery({
    queryKey: ['pharmacyOrders', path],
    queryFn: () => ApiClient.get(path),
    enabled: !!id,
  })

  return useMemo(() => ({
    ...query,
    isLoading: query.isLoading,
    data: get(query, 'data.pharmacyOrders'),
  }), [query.data])
}

export default usePharmacyOrders
