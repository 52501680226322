import React from 'react'
import '@components/Theme'
import { ReloadDropdown } from '@components/display'

function groupSitesByOrganization(sites) {
  return sites.reduce((acc, site) => {
    const { organization } = site
    const organizationSites = acc.find(
      (organizationSite) => organizationSite.label === organization.name,
    )
    if (organizationSites) {
      organizationSites.value.push({
        label: site.name,
        value: site.id,
      })
    } else {
      acc.push({
        label: organization.name,
        value: [
          {
            label: site.name,
            value: site.id,
          },
        ],
      })
    }
    return acc
  }, [])
}

function ReloadSiteSelect({ current_site_id: currentSiteId, sites }) {
  const selectedSite = currentSiteId

  return (
    <div className="flex-row justify-content-center">
      <ReloadDropdown
        value={selectedSite}
        paramName="site_id"
        options={groupSitesByOrganization(sites)}
        optionGroupLabel="label"
        optionGroupChildren="value"
        optionLabel="label"
        placeholder="Select Site"
        showClear
        style={{ width: '300px' }}
      />
    </div>
  )
}

export default ReloadSiteSelect
