import React from 'react'
import { Routes, Route } from 'react-router-dom'
import PageLayout, { usePageOutlet } from '@components/display/PageLayout'
import { withApp } from '@components/App'
import Dashboard from './Dashboard'

function MedManagement() {
  const useMedManagementHeader = () => usePageOutlet()

  return (
    <div className="patients">
      <Routes>
        <Route path="/admin/*" element={<PageLayout rootPath="/admin" rootLabel="Med Management" />}>
          <Route path="dashboard" element={<Dashboard useMedManagementHeader={useMedManagementHeader} />} />
          <Route index element={<Dashboard useMedManagementHeader={useMedManagementHeader} />} />
        </Route>
      </Routes>
    </div>
  )
}

export default withApp(MedManagement)
