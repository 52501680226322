import { useCallback } from 'react'

/**
 * Hook that scrolls the window to a specified direction with smooth behavior.
 *
 * @returns {Function} scrollTo - Function to scroll the window to a specified direction.
 * @param {string} direction - The direction to scroll the window.
 * Valid values are 'top', 'bottom', 'left', 'right'.
 */
const useScrollTo = () => {
  const scrollTo = useCallback((direction) => {
    const scrollOptions = { behavior: 'smooth' }
    const {
      scrollTop, scrollLeft, scrollHeight, scrollWidth,
    } = document.documentElement

    switch (direction) {
      case 'top':
        if (scrollTop === 0) return
        scrollOptions.top = 0
        break
      case 'bottom':
        if (scrollTop === scrollHeight - window.innerHeight) return
        scrollOptions.top = scrollHeight
        break
      case 'left':
        if (scrollLeft === 0) return
        scrollOptions.left = 0
        break
      case 'right':
        if (scrollLeft === scrollWidth - window.innerWidth) return
        scrollOptions.left = scrollWidth
        break
      default:
        return
    }

    window.scrollTo(scrollOptions)
  }, [])

  return scrollTo
}

export default useScrollTo
