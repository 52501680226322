import React from 'react'
import '@components/Theme'
import { ReloadDropdown } from '@components/display'

function OrgSelect({ current_org_id: currentOrgId, organizations }) {
  return (
    <ReloadDropdown
      value={currentOrgId}
      inputId="organization-select"
      options={organizations.map((org) => ({
        label: org.name,
        value: org.id,
      }))}
      paramName="organization_id"
      placeholder="Select Organization"
      showClear
      style={{
        width: '300px', margin: '.5rem', position: 'absolute', top: 0, right: 140, zIndex: 1000,
      }}
    />
  )
}

export default OrgSelect
