import React from 'react'
import { Calendar } from 'primereact/calendar'
import { InputText } from 'primereact/inputtext'
import { timeTemplate } from '@services/utils'

function DateTimeInput({
  date,
  time,
  setTime,
  canEditTime = true,
}) {
  return (
    <div className="flex flex-row gap-2">
      <div className="flex-1">
        <label className="block mb-1" htmlFor="adherence-dialog-form-date">Date</label>
        <InputText
          id="adherence-dialog-form-date"
          className="w-full"
          value={date}
          disabled
        />
      </div>
      <div className="flex-1">
        <label className="block mb-1" htmlFor="adherence-dialog-form-time">Time</label>
        <Calendar
          id="adherence-dialog-form-time"
          className="w-full custom-calendar border-black-alpha-70"
          value={time}
          onChange={(e) => setTime(e.value)}
          selectionMode="single"
          timeOnly
          hourFormat="12"
          formatDateTime={timeTemplate}
          showIcon
          icon={<i className="pi pi-clock text-black-alpha-70 text-white" />}
          disabled={!canEditTime}
        />
      </div>
    </div>
  )
}

export default DateTimeInput
