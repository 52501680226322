import React from 'react'
import { Tag } from 'primereact/tag'
import 'primeflex/primeflex.css'

function MissedTag({
  missedCount, onClick,
}) {
  return (
    <Tag
      onClick={onClick}
      severity="danger"
      className="w-7rem cursor-pointer"
      value={`${missedCount} Missed`}
      rounded
    />
  )
}

export default MissedTag
