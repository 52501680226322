import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { errorMessageFromError, formErrorsFromError } from '@services/errorHandler'

export const useUnlinkPrescription = (statusMessage) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['unlinkPrescription'],
    mutationFn: (prescriptionId) => (
      ApiClient.post(`/prescriptions/${prescriptionId}/unlink`)
    ),
    onSuccess: async () => {
      statusMessage.current.show([
        { severity: 'warn', summary: 'Prescription Unlinked' },
      ])
      await queryClient.invalidateQueries('prescriptions')
    },
    onError: (error) => {
      statusMessage.current.show([
        errorMessageFromError(error, 'Unable to unlink prescription at this time.'),
        ...formErrorsFromError(error),
      ])
    },
  })
}

export default useUnlinkPrescription
