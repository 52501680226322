import React from 'react'
import { Tooltip } from 'primereact/tooltip'
import { convertToCssClassName } from '@services/utils/string'

/**
 * Displays an info icon with a tooltip.
 *
 * @param {String} target
 * @param {String} text
 */
function InfoTooltip({ target, text }) {
  if (!target || !text) {
    return null
  }

  const tooltipClassName = convertToCssClassName(target, 'tooltip')

  if (!tooltipClassName) {
    return null
  }

  return (
    <>
      <Tooltip
        target={`.${tooltipClassName}`}
        content={text}
        position="right"
      />
      <i className={`pi pi-info-circle text-xl text-primary-500 mr-2 ${tooltipClassName}`} />
    </>
  )
}

export default InfoTooltip
