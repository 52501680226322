import React from 'react'
import { map } from 'lodash'
import DoseTag from '@components/clientDoses/Header/DoseTag'
import { Button } from 'primereact/button'
import SigDetails from './SigDetails'

function Title({ prescription, pharmacyMedicine }) {
  const { prescriptionNumber } = prescription
  const medicineText = pharmacyMedicine?.medicineText ?? 'Unknown Medicine'
  const title = `Rx${prescriptionNumber} - ${medicineText}`

  return (<span>{title}</span>)
}

function DoseDetails({
  doseDirectives,
  pharmacyMedicine,
  orderType,
  prescription,
  sigs,
  handleUndo,
}) {
  return (
    <div className="flex flex-row">
      <div className="flex flex-column col-10 p-4 gap-2">
        <div className="flex flex-row gap-2">
          <div className="flex flex-row gap-2">
            {map(doseDirectives, (directive, index) => (
              <DoseTag key={index} color={directive.color} text={directive.text.tag} />
            ))}
            {orderType && (
              // TODO: Adopt a pattern for color configuration
              <DoseTag color="var(--blue-400)" text={orderType} />
            )}
          </div>
          <Title prescription={prescription} pharmacyMedicine={pharmacyMedicine} />
        </div>
        {map(sigs, (sig, index) => (
          <SigDetails
            key={index}
            pharmacyMedicine={pharmacyMedicine}
            administrationAmount={sig.administrationAmount}
            startAtWall={sig.startAtWall}
            endAtWall={sig.endAtWall}
            units={sig.units}
            pharmacyInstructions={sig.pharmacyInstructions}
          />
        ))}
      </div>
      {handleUndo && (
        <Button
          onClick={handleUndo}
          className="align-self-center col-2 p-4 gap-2 max-w-8rem max-h-1rem"
          label="Undo"
          icon="pi pi-undo"
          outlined
        />
      )}
    </div>
  )
}

export default DoseDetails
