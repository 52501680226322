import React from 'react'
import IntegrationDataTable from '@components/integrationData/IntegrationDataTable'
import { useIntegrationDataMessages } from '@hooks/integrationData'

function PatientIntegrationData({ patientId }) {
  const {
    data: integrationData,
    isLoading,
  } = useIntegrationDataMessages('Patient', patientId)

  return (
    <div>
      <IntegrationDataTable
        integrationData={integrationData}
        isLoading={isLoading}
        showIgnored={false}
        scrollHeight="50vh"
      />
    </div>
  )
}

export default PatientIntegrationData
