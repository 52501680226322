import React from 'react'
import { Routes, Route } from 'react-router-dom'
import PageLayout, { usePageOutlet } from '@components/display/PageLayout'
import { withApp, useCurrentOrganization, useCurrentCapabilities } from '@components/App'
import './admins.scss'
import AdminsTable from './AdminsTable'
import Admin from './Admin'
import CreateAdmin from './CreateAdmin'

function Admins() {
  const useAdminHeader = () => usePageOutlet()
  const organization = useCurrentOrganization()
  const { organization: { canManageSsoForOrg } } = useCurrentCapabilities()

  return (
    <div className="admins">
      <Routes>
        <Route path="/admin/admins/*" element={<PageLayout rootPath="/admin/admins" rootLabel={organization.adminLabelPlural} />}>
          <Route
            index
            element={(
              <AdminsTable
                useAdminHeader={useAdminHeader}
                canManageSsoForOrg={canManageSsoForOrg}
              />
            )}
          />
          <Route path="create" element={<CreateAdmin formTitle={`Create ${organization.adminLabelSingular}`} />} />
          <Route path=":adminId/*" element={<Admin useAdminHeader={useAdminHeader} />} />
        </Route>
      </Routes>
    </div>
  )
}

export default withApp(Admins)
