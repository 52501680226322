import React, { useRef } from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Messages } from 'primereact/messages'
import { useExportPhysicianOrders } from '../../features/Patients/patientHooks'

function ExportPatientPhysicianOrdersDialog({ patientId, isVisible, setIsVisible }) {
  const messages = useRef()
  const {
    mutateAsync: exportPatientPhysicianOrders,
    isLoading: isExporting,
  } = useExportPhysicianOrders(messages, patientId)

  const handleExportPatientPhysicianOrders = async () => {
    exportPatientPhysicianOrders({}, {
      onSuccess: () => {
        setIsVisible(false)
      },
    })
  }

  return (
    <Dialog
      header="Export Physician Orders"
      visible={isVisible}
      onHide={() => setIsVisible(false)}
    >
      <Messages ref={messages} />
      <div className="flex flex-column text-base font-normal mt-2">
        <Button
          className="p-button-sm p-button-outlined"
          label="Export"
          onClick={() => handleExportPatientPhysicianOrders()}
          loading={isExporting}
        />
      </div>
    </Dialog>
  )
}

export default ExportPatientPhysicianOrdersDialog
