import React, { useRef, useState, useEffect } from 'react'
import {
  Routes, Route, Navigate,
  useParams, useLocation,
} from 'react-router-dom'
import { withApp } from '@components/App'
import { Messages } from 'primereact/messages'
import { Card } from 'primereact/card'
import OrgDetailsSettings from './Details/OrgDetailsSettings'
import AdminSettings from './AdminSettings/AdminSettings'
import OrganizationEventTimes from '../Organizations/OrganizationEventTimes'
import MainMenu from './MainMenu'
import CreateOrganization from '../Organizations/CreateOrganization'
import { useSettingsQuery } from './settingsHooks'
import PageHeader from './PageHeader'

function OrgSettings({ organization, timezones }) {
  const statusMessage = useRef(null)
  const { pathname, state } = useLocation()
  const params = useParams()

  const [
    organizationId,
    setOrganizationId,
  ] = useState(() => organization?.id || state?.organizationId || params.id)

  useEffect(() => {
    if (organization?.id) {
      setOrganizationId(organization.id)
    } else if (state?.organizationId) {
      setOrganizationId(state.organizationId)
    } else if (params.id) {
      setOrganizationId(params.id)
    }
  }, [organization?.id, state?.organizationId, params.id])

  const shouldShowMainMenu = pathname !== '/admin/organizations/new'
  const isCreateOrganization = pathname === '/admin/organizations/new'
  const query = useSettingsQuery(organizationId, statusMessage)

  return (
    <>
      <PageHeader isCreateOrganization={isCreateOrganization} />
      <Card>
        <Messages ref={statusMessage} />
        {shouldShowMainMenu && <MainMenu organizationId={organizationId} />}
        <Routes>
          <Route
            path="/admin/organizations/new"
            element={(
              <CreateOrganization
                timezones={timezones}
                statusMessage={statusMessage}
              />
            )}
          />
          <Route path="/admin/organizations/:id/edit/*" element={<Navigate to="details" replace />} />
          <Route
            index
            path="/admin/organizations/:id/edit/details"
            element={(
              <OrgDetailsSettings
                query={query}
                organizationId={organizationId}
                organization={organization}
                timezones={timezones}
                statusMessage={statusMessage}
              />
            )}
          />
          <Route
            path="/admin/organizations/:id/edit/admin-settings"
            element={(
              <AdminSettings
                query={query}
                organizationId={organizationId}
                organization={organization}
                statusMessage={statusMessage}
              />
            )}
          />
          <Route
            path="/admin/organizations/:id/edit/event-times"
            element={<OrganizationEventTimes />}
          />
        </Routes>
      </Card>
    </>
  )
}

export default withApp(OrgSettings)
