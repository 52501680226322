import React from 'react'
import { Routes, Route } from 'react-router-dom'
import PageLayout, { usePageOutlet } from '@components/display/PageLayout'
import { withApp } from '@components/App'
import SitesTable from './SitesTable'
import Site from './Site'
import CreateSite from './CreateSite'

function Sites() {
  const useSiteHeader = () => usePageOutlet()

  return (
    <div className="sites">
      <Routes>
        <Route path="/admin/sites/*" element={<PageLayout rootPath="/admin/sites" rootLabel="Sites" />}>
          <Route index element={<SitesTable useSiteHeader={useSiteHeader} />} />
          <Route path="create" element={<CreateSite useSiteHeader={useSiteHeader} />} />
          <Route path=":siteId/*" element={<Site useSiteHeader={useSiteHeader} />} />
        </Route>
      </Routes>
    </div>
  )
}

export default withApp(Sites)
