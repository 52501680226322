import { useEffect, useRef } from 'react'

/**
 * This hook is useful for scenarios where you want to hide an overlay, tooltip, modal, or any other
 * element in response to a user scrolling a particular container.
 *
 * The hook returns a ref that should be attached to the element you want to hide.
 * The element must have a `hide` method to control its visibility,
 * which will be called when the scroll event is detected.

 * @param {HTMLElement} scrollElement - The element to listen for scroll events on.
 * @returns {Object} An object containing the reference to the element to be hidden.
 */
function useHideElementOnScroll(scrollElement) {
  const ref = useRef(null)

  useEffect(() => {
    if (!scrollElement) return

    const handleScroll = () => {
      if (ref.current) {
        ref.current.hide()
      }
    }

    scrollElement.addEventListener('scroll', handleScroll)

    return () => {
      scrollElement.removeEventListener('scroll', handleScroll)
    }
  }, [scrollElement])

  return { ref }
}

export default useHideElementOnScroll
