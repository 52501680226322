import React from 'react'
import { getCountColor } from '@components/narcoticCounts/narcoticCountUtils'

function ColoredCircle({
  type, onClick, height = '16px', width = '16px', additionalClassNames = '',
}) {
  const cursorStyle = onClick ? 'pointer' : 'default'

  return (
    <div className={`flex justify-content-center align-items-center ${additionalClassNames}`}>
      <div
        data-testid={`colored-circle-${type}`}
        style={{
          backgroundColor: getCountColor(type),
          width,
          height,
          borderRadius: '50%',
          cursor: cursorStyle,
        }}
        onClick={onClick}
      />
    </div>
  )
}

export default ColoredCircle
