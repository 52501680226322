/**
 * The left-side labels for the MAR history timeline.
 * All administration event codes for consumptions should be included here.
 */
export const marHistoryStatusLabels = {
  CONDITION_CHECK: 'Condition Check',
  CONFIRMED: 'Given',
  COULD_NOT_LOCATE: 'Could not locate',
  CREATE_CONFIRMED: 'Given',
  CREATE_MISSED: 'Missed',
  CREATE_REFUSED: 'Refused',
  OVERRIDE_CONFIRMED: 'Given',
  OVERRIDE_DETAILS: 'Updated Details',
  OVERRIDE_LOA: 'Leave of Absence',
  OVERRIDE_MISSED: 'Missed',
  OVERRIDE_ON_HOLD: 'On Hold',
  OVERRIDE_PENDING: 'Pending',
  OVERRIDE_REFUSED: 'Refused',
  PATIENT_REFUSED: 'Refused',
  BARCODE_SCANNED: 'Barcode Scanned',
}

/**
 * The left-side labels for the TAR history timeline.
 * All task occurrence statuses should be included here.
 */
export const tarHistoryStatusLabels = {
  completed: 'Completed',
  missed: 'Missed',
  on_hold: 'Held',
  refused: 'Refused',
}
