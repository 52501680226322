import React from 'react'
import { Controller } from 'react-hook-form'
import { useFormV2Context } from '@components/display/FormV2/FormContext'
import CheckboxWithLabel from '@components/display/CheckboxWithLabel'

function SettingFeatures({ features }) {
  const { control } = useFormV2Context()

  if (features.length === 0) return null

  return (
    <div className="flex flex-column gap-2">
      {features.map((feature) => {
        const {
          name, label, showSetting = true, disabled,
        } = feature

        return showSetting && (
          <Controller
            key={name}
            name={name}
            control={control}
            render={({ field }) => (
              <CheckboxWithLabel
                name={name}
                label={label}
                inputId={name}
                checked={field.value}
                disabled={disabled}
                onChange={(e) => {
                  field.onChange(e.value)
                }}
              />
            )}
          />
        )
      })}
    </div>
  )
}

export default SettingFeatures
