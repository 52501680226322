import React, { useRef } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { Messages } from 'primereact/messages'
import { approvePermissionRequestMutation } from '../../features/MedManagement/permissionRequestsHooks'

function ApprovePermissionRequestDialog({
  permissionRequest, visible, onHide, resourceTemplate,
}) {
  const statusMessages = useRef()

  const {
    mutateAsync: approvePermissionRequest,
    isLoading,
  } = approvePermissionRequestMutation(permissionRequest.id, statusMessages)

  const footer = (
    <div className="flex flex-row gap-2 justify-content-end">
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text p-button-sm"
        onClick={onHide}
        disabled={isLoading}
      />

      <Button
        label="Approve"
        icon="pi pi-check"
        className="p-button-sm"
        onClick={async () => {
          approvePermissionRequest(
            {},
            {
              onSuccess: () => {
                onHide()
              },
            },
          )
        }}
        loading={isLoading}
      />
    </div>
  )

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header="Approve"
      footer={footer}
      modal
      className="p-fluid"
      closable={!isLoading}
    >
      <Messages ref={statusMessages} />
      <div className="flex flex-column gap-2">
        <span className="font-normal mb-2">Are you sure you want to approve the request?</span>
        {resourceTemplate(permissionRequest)}
      </div>
    </Dialog>
  )
}

export default ApprovePermissionRequestDialog
