import React from 'react'
import moment from 'moment'
import { InputTextarea } from 'primereact/inputtextarea'
import { SelectButton } from 'primereact/selectbutton'
import DatePickerWithLabel from '@components/display/DatePickerWithLabel'
import InputTextWithLabel from '@components/display/InputTextWithLabel'
import { getTaskActionFriendlyName } from '@components/tasks/utils'

const getTaskActionHelperText = (taskAction) => {
  switch (taskAction) {
    case 'binary':
      return 'No input required.'
    case 'text':
      return 'Text field input required.'
    case 'vital':
      return 'Vital required.'
    default:
      return ''
  }
}

export function TaskForm({ task = {}, setTask }) {
  return (
    <div className="flex flex-column">
      <InputTextWithLabel
        label="Title"
        value={task.title || ''}
        inputId="title"
        name="title"
        onChange={({ target: { value } }) => setTask({ title: value })}
      />
      <div className="field">
        <label htmlFor="description" className="block">Description</label>
        <InputTextarea
          id="description"
          value={task.description || ''}
          onChange={({ target: { value } }) => setTask({ description: value })}
          rows={3}
          cols={30}
        />
      </div>
      <div className="field">
        <label htmlFor="showOnTar" className="block text-center">Should this task be included on the TAR?</label>
        <SelectButton
          id="showOnTar"
          className="flex justify-content-center w-full"
          allowEmpty={false}
          value={task.showOnTar || false}
          onChange={({ target: { value } }) => setTask({ showOnTar: value })}
          optionLabel="name"
          optionValue="value"
          options={[
            { name: 'Yes', value: true },
            { name: 'No', value: false },
          ]}
        />
      </div>
      <div className="field">
        <label htmlFor="taskType" className="block text-center">Is this task completed on an as needed basis?</label>
        <SelectButton
          id="taskType"
          className="flex justify-content-center w-full"
          allowEmpty={false}
          value={task.taskType || 'prn'}
          onChange={({ target: { value } }) => setTask({ taskType: value })}
          optionLabel="name"
          optionValue="value"
          options={[
            { name: 'Yes', value: 'prn' },
            { name: 'No', value: 'routine' },
          ]}
        />
      </div>
      <div className="field">
        <label htmlFor="taskAction" className="block">Action</label>
        <SelectButton
          id="taskAction"
          className="flex justify-content-center w-full"
          allowEmpty={false}
          value={task.taskAction || 'binary'}
          onChange={({ target: { value } }) => {
            if (typeof value === 'object') {
              setTask({ taskAction: value.value })
            } else {
              setTask({ taskAction: value })
            }
          }}
          optionLabel="name"
          optionValue="value"
          options={[
            { name: getTaskActionFriendlyName('binary'), value: 'binary' },
            { name: getTaskActionFriendlyName('text'), value: 'text' },
            { name: getTaskActionFriendlyName('vital'), value: 'vital' },
          ]}
        />
        <div className="flex flex-row justify-content-center mt-2">
          <span className="opacity-70">
            {getTaskActionHelperText(task.taskAction)}
          </span>
        </div>
      </div>
      <div className="flex flex-row justify-content-between">
        <DatePickerWithLabel
          label="Start Date"
          value={task.startAt ? moment(task.startAt).toDate() : null}
          inputId="startAt"
          name="startAt"
          onChange={({ target: { value } }) => setTask({ startAt: value })}
          minDate={moment().subtract(5, 'years').toDate()}
          maxDate={task.endAt ? moment(task.endAt).toDate() : moment().add(3, 'months').toDate()}
        />
        <DatePickerWithLabel
          label="End Date"
          value={task.endAt ? moment(task.endAt).toDate() : null}
          inputId="endAt"
          name="endAt"
          onChange={({ target: { value } }) => setTask({ endAt: value })}
          minDate={task.endAt ? moment(task.endAt).toDate() : moment().toDate()}
          maxDate={moment().add(5, 'years').toDate()}
        />
      </div>
    </div>
  )
}

export default TaskForm
