import React from 'react'
import { Dropdown } from 'primereact/dropdown'
import { LABELS, MED_BOX_TYPE, STRIP_MED_BOX_TYPE } from '../../../features/MedBoxes/config'

function DeviceType({ deviceType, setDeviceType }) {
  const deviceTypes = [
    { label: LABELS[MED_BOX_TYPE], type: MED_BOX_TYPE },
    { label: LABELS[STRIP_MED_BOX_TYPE], type: STRIP_MED_BOX_TYPE },
  ]

  return (
    <div className="field">
      <label className="block" htmlFor="deviceType">Device Type</label>
      <div className="flex flex-column">
        <Dropdown
          id="deviceType"
          value={deviceType}
          onChange={(e) => setDeviceType(e.value)}
          options={deviceTypes}
          optionLabel="label"
          optionValue="type"
          placeholder="Select a device type"
          className="w-full"
        />
      </div>
    </div>
  )
}

export default DeviceType
