import React, { useState } from 'react'

import { Knob } from 'primereact/knob'
import { Divider } from 'primereact/divider'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { DoseAssignment } from '../DoseAssignment'

function SlotCardWithoutAssignment({
  siteId, ekitId, drawerId, slot,
}) {
  const [showDialog, setShowDialog] = useState(false)

  return (
    <>
      <Dialog header="Dose Assignment" visible={showDialog} onHide={() => setShowDialog(false)}>
        <DoseAssignment
          siteId={siteId}
          ekitId={ekitId}
          drawerId={drawerId}
          slot={slot}
          onSuccess={() => setShowDialog(false)}
        />
      </Dialog>
      <div className="col-4 p-2 bottom-20">
        <div className="shadow-1 p-3 h-full">
          <div className="flex flex-column">
            <div className="flex flex-row justify-content-between align-items-center">
              <div className="flex flex-row gap-2 align-items-center">
                <Knob size={40} value={0} readOnly />
                <span className="text-lg">Empty</span>
              </div>
            </div>
            <Divider className="my-2" />
            <Button
              onClick={() => setShowDialog(true)}
              icon="pi pi-plus"
              label="Assign Dose"
              className="text-left pl-0 p-button-text p-button-sm assign-dose-slot-button"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default SlotCardWithoutAssignment
