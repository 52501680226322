import React, {
  useRef, useState, useEffect, useMemo,
} from 'react'
import { uniqBy } from 'lodash'
import { Card } from 'primereact/card'
import { useCurrentOrganization, useCurrentUser } from '@components/App'
import { DataTable } from 'primereact/datatable'
import { Column } from '@components/display/Box/Box.stories'
import { Button } from 'primereact/button'
import { usePageOutlet } from '@components/display/PageLayout'
import { useNotificationSettings } from '@hooks/organizations'
import { Toast } from 'primereact/toast'
import NotificationSettingSwitch from './NotificationSettingSwitch'
import EditNotificationSettingModal from './EditNotificationSettingModal'

function Title() {
  return (
    <div className="flex flex-row gap-4 px-4 pb-2 pt-2 align-items-center">
      <span>
        <i className="text-xl pi pi-users mr-2" />
        Notification Settings
      </span>
    </div>
  )
}

function NotificationSettingsTable() {
  const useNotificationSettingHeader = () => usePageOutlet()
  const statusMessage = useRef(null)
  const { setHeader, maxDataViewHeight } = useNotificationSettingHeader()

  const [selectedNotificationSetting, setSelectedNotificationSetting] = useState(null)

  const handleEditClick = (notificationSetting) => {
    setSelectedNotificationSetting(notificationSetting)
  }

  const [searchLoading, setSearchLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  const organization = useCurrentOrganization()
  const currentUser = useCurrentUser()
  const [filteredNotificationSettings, setFilteredNotificationSettings] = useState([])

  const { data: notificationSettings, isLoading } = useNotificationSettings(
    organization.id,
    currentUser,
  )

  const title = useMemo(() => ({
    label: (
      <Title
        organization={organization}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        isLoading={searchLoading}
      />
    ),
  }), [organization, searchTerm, searchLoading])

  const notificationSettingEditButton = (rowData) => {
    // We don't allow to edit offset for due_now and reminder notification types
    // See: DOSE_NOTIFICATION_PRESETS in app/models/notification_setting.rb
    // for the min and max offset values for each notification type
    if (['due_now', 'reminder'].includes(rowData.notificationType)) {
      return null
    }
    return (
      <Button
        label="Edit"
        className="p-button-secondary border-1 bg-gray-100 border-gray-200"
        onClick={() => handleEditClick(rowData)}
        size="small"
        pt={{
          label: 'text-gray-600 font-semibold',
        }}
      />
    )
  }

  useEffect(() => {
    setHeader({
      title, tabs: [], breadcrumbs: [],
    })
  }, [title, searchTerm, searchLoading])

  useEffect(() => {
    const siteNotificationSettings = uniqBy(notificationSettings, 'id')

    setSearchLoading(true)
    setFilteredNotificationSettings(siteNotificationSettings)
    setSearchLoading(false)
  }, [notificationSettings])

  const renderNotificationSettingSwitch = (rowData) => (
    <NotificationSettingSwitch
      notificationSetting={rowData}
      organizationId={organization.id}
      statusMessage={statusMessage}
    />
  )

  return (
    <div>
      <Toast ref={statusMessage} />
      <Card className="h-full">
        <DataTable
          dataKey="id"
          loading={isLoading}
          value={filteredNotificationSettings}
          selectionMode="single"
          onSelectionChange={false}
          sortField="title"
          sortOrder={1}
          rowClassName="fadein"
          scrollable
          scrollHeight={maxDataViewHeight ? (maxDataViewHeight - 10) : null}
        >
          <Column header="Type" field="title" sortable />
          <Column header="Time / Criteria" field="friendlyTime" sortable />
          <Column
            header="Active?"
            body={renderNotificationSettingSwitch}
          />
          <Column
            header="Actions"
            body={notificationSettingEditButton}
          />
        </DataTable>
      </Card>
      {selectedNotificationSetting && (
        <EditNotificationSettingModal
          visible={!!selectedNotificationSetting}
          onHide={() => setSelectedNotificationSetting(null)}
          notificationSetting={selectedNotificationSetting}
          statusMessage={statusMessage}
        />
      )}
    </div>
  )
}

export default NotificationSettingsTable
