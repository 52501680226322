import React from 'react'
import { Button } from 'primereact/button'
import InputTextWithLabel from '@components/display/InputTextWithLabel'

function PharmacyInfo({
  data, handlePharmacyInfo, handleConfirm,
}) {
  return (
    <div className="grid flex flex-grow-1 justify-content-center align-items-center fadein">
      <div className="col-9 flex flex-column gap-2 text-center">
        <div className="col-9 flex flex-row gap-2 text-center">
          <div className="col-9 flex flex-column gap-2 text-center">
            <InputTextWithLabel
              label="Pharmacy Name"
              value={data.name}
              inputId="name"
              name="name"
              onChange={({ target: { value } }) => handlePharmacyInfo('name', value)}
            />
          </div>
          <div className="col-9 flex flex-column gap-2 text-center">
            <InputTextWithLabel
              label="Pharmacy Phone"
              value={data.phone}
              inputId="phone"
              name="phone"
              onChange={({ target: { value } }) => handlePharmacyInfo('phone', value)}
            />
          </div>
        </div>

        <Button
          onClick={handleConfirm}
          label="Next"
          className="p-button-text mt-2"
        />
      </div>
    </div>
  )
}

export default PharmacyInfo
