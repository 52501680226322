import React from 'react'
import { Link } from 'react-router-dom'

function Header({ sharedDevice }) {
  return (
    <div className="flex align-items-center justify-content-start gap-4">
      <span className="text-base font-semibold">
        {sharedDevice.label}
      </span>

      <div className="flex flex-column align-items-center justify-content-between gap-3">
        <div className="flex justify-content-between align-items-center w-full">
          <i className="pi pi-phone" style={{ color: 'var(--grey-500)', fontSize: '90%' }} />
          <span className="pl-1 flex-grow-1">
            {
              sharedDevice.phone
                ? sharedDevice.phone.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$2-$3-$4')
                : 'No phone number'
            }
          </span>
        </div>

        <div className="flex justify-content-between align-items-center w-full">
          <i className="pi pi-map-marker" style={{ color: 'var(--grey-500)', fontSize: '90%' }} />
          <span className="pl-1 flex-grow-1">
            {sharedDevice.site.name}
          </span>
        </div>

        <div className="flex justify-content-between align-items-center w-full">
          <i className="pi pi-users text-gray-500" />
          <span className="text-sm font-medium pl-1 flex-grow-1">
            {sharedDevice.caregivers.length}
          </span>
        </div>
      </div>
      <Link to={`/admin/shared_devices/${sharedDevice.id}`} className="flex align-items-center">
        <i className="pi pi-pencil text-blue-500" />
      </Link>
    </div>
  )
}

export default Header
