const ACTIVITY_TYPES = {
  TOILET: {
    name: 'Toilet',
  },
  BRIEF: {
    name: 'Brief',
  },
  BAG: {
    name: 'Bag',
  },
  SELF_SOILED: {
    name:
      'Self-Soiled',
  },
}

const WITNESS_TYPE = {
  WITNESSED: {
    name: 'Witnessed',
  },
  SELF_REPORTED: {
    name: 'Self-Reported',
  },
  THIRD_PARTY_REPORTED: {
    name: 'Third-Party Reported',
  },
}

const BRISTOL_STOOL_SCALE = {
  1: {
    description: 'Separate hard lumps, like nuts (hard to pass)',
  },
  2: {
    description: 'Sausage-shaped, but lumpy',
  },
  3: {
    description: 'Like a sausage but with cracks on its surface',
  },
  4: {
    description: 'Like a sausage or snake, smooth and soft',
  },
  5: {
    description: 'Soft blobs with clear cut edges (passed easily)',
  },
  6: {
    description: 'Fluffy pieces with ragged edges, a mushy stool',
  },
  7: {
    description: 'Watery, no solid pieces, entirely liquid',
  },
}

const SIZES = {
  SMALL: {
    name: 'Small',
  },
  MEDIUM: {
    name: 'Medium',
  },
  LARGE: {
    name: 'Large',
  },
}

export {
  ACTIVITY_TYPES,
  WITNESS_TYPE,
  BRISTOL_STOOL_SCALE,
  SIZES,
}
