import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Card } from 'primereact/card'
import { Divider } from 'primereact/divider'
import LinkButtonNavigator from '@components/display/LinkButtonNavigator'
import TasksList from './TasksList'
import '../client-tasks-styles.scss'

const wrapper = (component) => (
  <div className="p-4">
    {component}
  </div>
)

const viewLinks = [
  {
    label: 'All',
    linkTo: 'all',
  },
  {
    label: 'Routine',
    linkTo: 'routine',
  },
  {
    label: 'PRN',
    linkTo: 'prn',
  },
]

function TasksOverviewPage({ clientId }) {
  return (
    <div className="col-12 client-tasks">
      <Card className="h-full client-task-card-body">
        <div className="flex flex-column text-base text-900">
          <LinkButtonNavigator
            buttons={viewLinks}
            actionButton={{ label: 'Create Task', linkTo: 'create' }}
          />
          <Divider className="my-0" />
          <Routes>
            <Route
              path="all"
              element={
                wrapper(
                  <TasksList
                    clientId={clientId}
                    filters={[{ name: 'taskCategory', value: 'custom' }]}
                  />,
                )
              }
            />
            <Route
              path="routine"
              element={
                wrapper(
                  <TasksList
                    clientId={clientId}
                    filters={[{ name: 'taskType', value: 'routine' }, { name: 'taskCategory', value: 'custom' }]}
                  />,
                )
              }
            />
            <Route
              path="prn"
              element={
                wrapper(
                  <TasksList
                    clientId={clientId}
                    filters={[{ name: 'taskType', value: 'prn' }, { name: 'taskCategory', value: 'custom' }]}
                  />,
                )
              }
            />
          </Routes>
        </div>
      </Card>
    </div>
  )
}

export default TasksOverviewPage
