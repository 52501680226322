import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'

const useReviewableTreatmentOrders = (clientId) => {
  const query = useQuery({
    queryKey: ['reviewableTreatmentOrders', clientId],
    queryFn: () => ApiClient.get(`/patients/${clientId}/treatments/needs_review`),
    enabled: !!clientId,
  })

  return useMemo(() => ({
    ...query,
    data: query.data?.reviewableOrders,
  }), [query])
}

export default useReviewableTreatmentOrders
