import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { withApp } from '@components/App'
import PageLayout, { usePageOutlet } from '@components/display/PageLayout'
import DirectorySyncDashboard from './DirectorySyncDashboard'
import DirectoryUser from './DirectoryUser'

function DirectorySync() {
  const useDirectorySyncHeader = () => usePageOutlet()

  return (
    <div className="directory_sync">
      <Routes>
        <Route
          path="/admin/directory_sync/*"
          element={(
            <PageLayout
              rootPath="/admin/directory_sync"
              rootLabel="Directory Sync"
            />
          )}
        >
          <Route
            index
            element={(
              <DirectorySyncDashboard
                useDirectorySyncHeader={useDirectorySyncHeader}
              />
            )}
          />
          <Route
            path=":directoryUserId/*"
            element={(
              <DirectoryUser
                useDirectorySyncHeader={useDirectorySyncHeader}
              />
            )}
          />
        </Route>
      </Routes>
    </div>
  )
}

export default withApp(DirectorySync)
