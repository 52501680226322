import React from 'react'
import { get } from 'lodash'

import AttributeCard from '@components/display/AttributeCard'
import { EditButton, DoseAttributeRow, InputTextEditor } from '@components/DoseAttributeRow'

function PharmacyInfo({
  dose, editAttribute, setEditAttribute, isLoading, handleUpdateAttribute,
}) {
  const pharmacyAttributes = [
    {
      label: 'Name',
      currentValue: get(dose, 'pharmacyName'),
      action: <EditButton
        onClick={() => (editAttribute === 'pharmacyName' ? setEditAttribute(null) : setEditAttribute('pharmacyName'))}
        isEditing={editAttribute === 'pharmacyName'}
        loading={isLoading}
        disabled={editAttribute && editAttribute !== 'pharmacyName'}
      />,
      isEditing: editAttribute === 'pharmacyName',
      editor: <InputTextEditor
        text={get(dose, 'pharmacyName')}
        setText={(value) => handleUpdateAttribute('pharmacyName', value)}
        isLoading={isLoading}
      />,
    },
    {
      label: 'Phone',
      currentValue: get(dose, 'pharmacyPhone'),
      action: <EditButton
        onClick={() => (editAttribute === 'pharmacyPhone' ? setEditAttribute(null) : setEditAttribute('pharmacyPhone'))}
        isEditing={editAttribute === 'pharmacyPhone'}
        loading={isLoading}
        disabled={editAttribute && editAttribute !== 'pharmacyPhone'}
      />,
      isEditing: editAttribute === 'pharmacyPhone',
      editor: <InputTextEditor
        text={get(dose, 'pharmacyPhone')}
        setText={(value) => handleUpdateAttribute('pharmacyPhone', value)}
        isLoading={isLoading}
      />,
    },
    {
      label: 'Walk In Rx',
      currentValue: get(dose, 'walkInRx'),
      action: <EditButton
        onClick={() => (editAttribute === 'walkInRx' ? setEditAttribute(null) : setEditAttribute('walkInRx'))}
        isEditing={editAttribute === 'walkInRx'}
        loading={isLoading}
        disabled={editAttribute && editAttribute !== 'walkInRx'}
      />,
      isEditing: editAttribute === 'walkInRx',
      editor: <InputTextEditor
        text={get(dose, 'walkInRx')}
        setText={(value) => handleUpdateAttribute('walkInRx', value)}
        isLoading={isLoading}
      />,
    },
  ]

  return (
    <AttributeCard title={{ label: 'Pharmacy Info' }}>
      <ul className="list-none p-0 m-0 dose-order-fields">
        {pharmacyAttributes
          .filter(({ display = true }) => display)
          .map(({
            label, currentValue, newValue, action, valueChangeHandler, editor, isEditing,
          }, index) => (
            <DoseAttributeRow
              key={label}
              label={label}
              valueChangeHandler={valueChangeHandler}
              currentValue={currentValue}
              newValue={newValue}
              action={action}
              editor={editor}
              isEditing={isEditing}
              backgroundHighlight={index % 2 === 0}
            />
          ))}
      </ul>
    </AttributeCard>
  )
}

export default PharmacyInfo
