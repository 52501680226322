/**
 * @param {number} hours
 * @param {number} minutes
 * @returns {string}
 */
const formatHoursAndMinutes = (hours, minutes) => `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`

/**
 * This function formats a given time as hours and minutes.
 * @param {number|string|Date|{format: function}} time
 * @returns {string|null}
 */
export const formatTimeAsHoursAndMinutes = (time) => {
  if (typeof time === 'number') {
    const hours = Math.floor(time / 60)
    const minutes = time % 60
    return formatHoursAndMinutes(hours, minutes)
  }

  if (typeof time === 'string') {
    const timeParts = time.split(':')
    if (timeParts.length === 2 && !Number.isNaN(timeParts[0]) && !Number.isNaN(timeParts[1])) {
      return time
    }
  }

  if (time instanceof Date) {
    const hours = time.getHours()
    const minutes = time.getMinutes()
    return formatHoursAndMinutes(hours, minutes)
  }

  if (time && typeof time.format === 'function') {
    return time.format('HH:mm')
  }

  return null
}

/**
 * Formats the time left object into a human-readable string.
 *
 * @param {Object} timeLeft - The time left object.
 * @param {Object} [options={}] - Optional settings
 * @param {boolean} [options.includeSeconds=true] - Optional setting wether to include seconds
 * in the return, default to true.
 * @returns {string} The return value is a human-readable string that represents the time left.
 * The format of the string. If the time left is 0, the string will be empty.
 */
export function formatTimeLeft(timeLeft, options = {}) {
  const { includeSeconds = true } = options
  const {
    days, hours, minutes, seconds,
  } = timeLeft

  let adjustedMinutes = minutes

  // If seconds are not included, adjust minutes to account for the incomplete minute.
  if (!includeSeconds) {
    adjustedMinutes += 1
  }

  const formattedDays = days > 0 ? `${days}d ` : ''
  const formattedHours = (days > 0 || hours > 0) ? `${hours}h ` : ''
  const formattedMinutes = (days > 0 || hours > 0 || adjustedMinutes > 0) ? `${adjustedMinutes}m ` : ''
  const formattedSeconds = includeSeconds ? `${seconds}s` : ''

  return `${formattedDays}${formattedHours}${formattedMinutes}${formattedSeconds}`.trim()
}

/**
 * @typedef {Object} TimeLeft
 * @property {number} days - The number of days left.
 * @property {number} hours - The number of hours left.
 * @property {number} minutes - The number of minutes left.
 * @property {number} seconds - The number of seconds left.
 */

class TimeLeft {
  constructor(days, hours, minutes, seconds) {
    this.days = days
    this.hours = hours
    this.minutes = minutes
    this.seconds = seconds
  }

  isTimeUp() {
    return this.days === 0 && this.hours === 0 && this.minutes === 0 && this.seconds === 0
  }
}

/**
 * Helper function to calculate time left
 * @param {number} fromTime - The starting timestamp in milliseconds.
 * @param {number} toTime - The target timestamp in milliseconds.
 * @returns {TimeLeft} The time left object.
 * @throws {Error} If fromTime is not provided.
 * @throws {Error} If toTime is not provided.
*/

export function calculateTimeLeft(fromTime, toTime) {
  const MS_IN_A_DAY = 1_000 * 60 * 60 * 24
  const MS_IN_AN_HOUR = 1_000 * 60 * 60
  const MS_IN_A_MINUTE = 1_000 * 60
  const MS_IN_A_SECOND = 1_000

  if (!toTime) {
    throw new Error('toTime is required')
  }

  if (!fromTime) {
    throw new Error('fromTime is required')
  }

  const difference = toTime - fromTime

  if (difference <= 0) {
    return new TimeLeft(0, 0, 0, 0)
  }

  return new TimeLeft(
    Math.floor(difference / MS_IN_A_DAY),
    Math.floor((difference % MS_IN_A_DAY) / MS_IN_AN_HOUR),
    Math.floor((difference % MS_IN_AN_HOUR) / MS_IN_A_MINUTE),
    Math.floor((difference % MS_IN_A_MINUTE) / MS_IN_A_SECOND),
  )
}

export const dateFromHoursMinutes = (hours, minutes) => {
  const date = new Date()
  date.setHours(hours)
  date.setMinutes(minutes)
  date.setSeconds(0)
  return date
}

export default {}
