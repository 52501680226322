import React from 'react'
import { Message } from 'primereact/message'

function Notifications({ notifications }) {
  return notifications.map((notification, index) => {
    let margin = 'mx-3 mt-3'

    if (index === notifications.length - 1) {
      margin += ' mb-3'
    }

    const key = notification.key || `notification${index}`

    return (
      <Message
        key={key}
        className={`border-primary justify-content-start custom-message-success ${margin}`}
        severity="info"
        content={notification}
      />
    )
  })
}

export default Notifications
