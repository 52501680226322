import { useMemo } from 'react'
import { map, get, uniqBy } from 'lodash'
import { useQuery, useMutation } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'

export function usePatientCaregivers(patientId) {
  const query = useQuery({ queryKey: ['patientCaregivers', patientId], queryFn: () => ApiClient.get(`/patients/${patientId}/caregivers`) })

  return useMemo(() => {
    const caregivers = map(get(query, 'data.caregivers'), (caregiver) => (caregiver))
    return {
      ...query,
      data: uniqBy(caregivers, 'id'),
    }
  }, [query.data])
}

export function usePossiblePatientCaregivers(patientId) {
  const query = useQuery({ queryKey: ['possiblePatientCaregivers', patientId], queryFn: () => ApiClient.get(`/patients/${patientId}/assignable_caregivers`) })

  return useMemo(() => {
    const caregivers = map(get(query, 'data.caregivers'), (caregiver) => (caregiver))
    return {
      ...query,
      data: uniqBy(caregivers, 'id'),
    }
  }, [query.data])
}

export function usePatientCaregiversMutation(patientId) {
  return useMutation({ mutationFn: ({ caregiverIds, siteId }) => ApiClient.post(`/patients/${patientId}/replace_caregiver_assignments`, { caregiverIds, siteId }) })
}
