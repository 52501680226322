import React from 'react'
import SitesSelect from '../../../features/Selects/SitesSelect/SitesSelect'

function SiteForm({
  sites, selectedSites, setSelectedSites, isSitesLoading, isSsoOnboarding,
}) {
  return (
    <div className="flex flex-column">
      <SitesSelect
        sites={sites}
        loading={isSitesLoading}
        onChange={(newSites) => setSelectedSites(newSites)}
        selectedSites={selectedSites}
        className="w-full"
        showSelectAll
        isSsoOnboarding={isSsoOnboarding}
      />
    </div>
  )
}

export default SiteForm
