import React, { useRef, useState, useEffect } from 'react'
import { get, map } from 'lodash'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { Messages } from 'primereact/messages'
import { Link, useNavigate } from 'react-router-dom'
import { useCurrentOrganization } from '@components/App'
import SiteFilterDropdown from '@components/sites/SiteFilterDropdown'
import SearchField from '@components/display/Form/SearchField'
import { exactSearch } from '@services/utils/search'
import { usePharmacistsQuery } from './pharmacistsHooks'

function Title({ searchTerm, setSearchTerm, isLoading }) {
  return (
    <div className="flex flex-row gap-4 px-4 pb-2 pt-2 align-items-center mt-2">
      <span>
        <i className="text-xl pi pi-users mr-2" />
        Pharmacists
      </span>
      <SearchField
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        placeholder="Search pharmacists by name"
        isLoading={isLoading}
      />
    </div>
  )
}

function PharmacistsTable({ usePharmacistsHeader }) {
  const navigate = useNavigate()
  const statusMessage = useRef(null)
  const { setHeader, maxDataViewHeight } = usePharmacistsHeader()

  const [searchLoading, setSearchLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [siteFilter, setSiteFilter] = useState('')
  const [filteredPharmacists, setFilteredPharmacists] = useState([])

  const { data: pharmacists, isLoading } = usePharmacistsQuery(statusMessage)

  const organization = useCurrentOrganization()

  useEffect(() => {
    const siteFilteredPharmacists = siteFilter ? pharmacists.filter((pharmacist) => get(pharmacist, 'sites').map((site) => site.name).includes(siteFilter)) : pharmacists
    setSearchLoading(true)
    if (searchTerm) {
      setFilteredPharmacists(exactSearch(siteFilteredPharmacists, ['firstName', 'lastName'], searchTerm))
    } else {
      setFilteredPharmacists(siteFilteredPharmacists)
    }
    setSearchLoading(false)
  }, [pharmacists, searchTerm, siteFilter])

  const rootPath = '/admin/pharmacists'

  const title = {
    label: (
      <Title
        organization={organization}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        isLoading={searchLoading}
      />
    ),
  }

  const sitesOptions = Array.from(new Set(
    map(pharmacists, (pharmacist) => (get(pharmacist, 'sites') || []).map((site) => site.name)).flat(),
  ))

  const handleSiteChange = (e) => {
    setSiteFilter(e.value)
    document.querySelector('.p-column-filter-menu-button-open').click()
  }

  const primaryAction = (
    <Link to="/admin/pharmacists/create">
      <Button className="p-button-sm" label="Create Pharmacist" />
    </Link>
  )

  const footTemplate = () => (
    <span className="text-sm ml-1 my-0 py-0">
      {!isLoading ? `Pharmacist Count: ${filteredPharmacists.length}` : ''}
    </span>
  )

  useEffect(() => {
    setHeader({
      rootPath, title, tabs: [], breadcrumbs: [], primaryAction,
    })
  }, [searchTerm, searchLoading])

  return (
    <div className="col-12">
      <Messages ref={statusMessage} />
      <Card className="h-full" footer={footTemplate}>
        <div className="flex flex-column gap-3 text-base text-900">
          <DataTable
            dataKey="id"
            loading={isLoading}
            value={filteredPharmacists}
            selectionMode="single"
            onSelectionChange={(e) => navigate(`/admin/pharmacists/${e.value.id}`)}
            sortField="lastName"
            sortOrder={1}
            className="mt-1"
            rowClassName="fadein pharmacist-row"
            scrollable
            scrollHeight={maxDataViewHeight ? (maxDataViewHeight - 10) : null}
          >
            <Column header="First Name" field="firstName" />
            <Column header="Last Name" field="lastName" sortable />
            <Column
              header="Sites"
              field="sites"
              body={(rowData) => {
                const { sites } = rowData
                if (sites) {
                  if (sites.length === 0) {
                    return 'No sites assigned'
                  }
                  if (sites.length === 1) {
                    return sites[0].name
                  }
                  return `Assigned to ${sites.length} sites`
                }
              }}
              filter
              filterElement={(
                <SiteFilterDropdown
                  sites={sitesOptions}
                  value={siteFilter}
                  handleSiteChange={handleSiteChange}
                />
              )}
              showFilterMatchModes={false}
              showFilterMenuOptions={false}
              showApplyButton={false}
              showClearButton={false}
              filterMenuClassName="pharmacists-site-filter"
            />
          </DataTable>
        </div>
      </Card>
    </div>
  )
}

export default PharmacistsTable
