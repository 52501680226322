import React from 'react'
import { Dropdown } from 'primereact/dropdown'

const statusOptions = {
  mar_prn: [
    { value: 'confirmed', label: 'Given' },
    { value: 'refused', label: 'Refused' },
  ],
  mar_routine: [
    { value: 'confirmed', label: 'Given' },
    { value: 'missed', label: 'Missed' },
    { value: 'refused', label: 'Refused' },
    { value: 'leave_of_absence', label: 'Leave of Absence' },
    { value: 'on_hold', label: 'On Hold' },
  ],
  tar_prn: [
    { value: 'completed', label: 'Completed' },
    { value: 'refused', label: 'Refused' },
  ],
  tar_routine: [
    { value: 'completed', label: 'Completed' },
    { value: 'missed', label: 'Missed' },
    { value: 'on_hold', label: 'On Hold' },
    { value: 'refused', label: 'Refused' },
    { value: 'leave_of_absence', label: 'Leave of Absence' },
  ],
}

function StatusDropdown({ mode, status, setStatus }) {
  const options = statusOptions[mode] || []

  if (options.length === 0) {
    return null
  }

  return (
    <div className="flex flex-column gap-1">
      <label className="block" htmlFor="adherence-dialog-form-status">Status</label>
      <Dropdown
        id="adherence-dialog-form-status"
        value={status}
        options={options}
        onChange={(e) => setStatus(e.value)}
        placeholder="Please select a status"
        className="w-full text-sm"
      />
    </div>
  )
}

export default StatusDropdown
