import { useMemo } from 'react'
import { map, get, isEmpty } from 'lodash'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import {
  errorMessageFromError,
  displayErrorMessages,
} from '@services/errorHandler'
import { downloadFileFromBase64Response, downloadFileFromResponse } from '@services/utils'

export function usePatientMARApprovals({
  patientId, dayMin, dayMax,
}) {
  const query = useQuery({
    queryKey: ['marApprovals', patientId, dayMin, dayMax],
    queryFn: () => ApiClient.get('/mar_approvals', { params: { patientId, dayMin, dayMax } }),
    enabled: !!patientId,
  })

  return useMemo(() => ({
    ...query,
    data: map(get(query, 'data.marApprovals'), (marApproval) => (marApproval)),
  }), [query.data])
}

export function useCreatePatientConsumptionMutation(statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (body) => ApiClient.post('/consumptions', body),
    onSuccess: async () => {
      await queryClient.invalidateQueries('marDashboard')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useUpdatePatientConsumptionMutation(consumptionId, statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (body) => ApiClient.post(`/consumptions/${consumptionId}/override`, body),
    onSuccess: async () => {
      await queryClient.invalidateQueries('marDashboard')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useMedPassPatients(organizationId, statusMessage) {
  const query = useQuery({
    queryKey: ['medPassPatients', organizationId],
    queryFn: () => {
      let params = {}

      if (organizationId) {
        params = { organizationId }
      }

      return ApiClient.get('/med_pass/patients', { params })
    },
    onError: (error) => {
      if (!get(statusMessage, 'current')) return
      statusMessage.current.clear()
      statusMessage.current.show([
        errorMessageFromError(error),
      ])
    },
  })

  return useMemo(() => ({
    ...query,
    data: map(get(query, 'data.patients'), (patient) => (patient)),
  }), [query])
}

export function usePatients(organizationId, statusMessage) {
  const query = useQuery({
    queryKey: ['patients', organizationId],
    queryFn: () => {
      let params = {}

      if (organizationId) {
        params = { organization_id: organizationId }
      }

      return ApiClient.get('/patients', { params })
    },
    onError: (error) => {
      if (!get(statusMessage, 'current')) return
      statusMessage.current.clear()
      statusMessage.current.show([
        errorMessageFromError(error),
      ])
    },
  })

  return useMemo(() => ({
    ...query,
    data: map(get(query, 'data.patients'), (patient) => (patient)),
  }), [query])
}

export function usePatientsBySite(siteId) {
  const query = useQuery({ queryKey: ['patientsBySite', siteId], queryFn: () => ApiClient.get(`/sites/${siteId}/patients`), enabled: !!siteId })

  return useMemo(() => ({
    ...query,
    data: map(get(query, 'data.patients'), (patient) => (patient)),
  }), [query.data])
}

export function usePatient(patientId) {
  return useQuery({ queryKey: ['patient', patientId], queryFn: () => ApiClient.get(`/patients/${patientId}`), enabled: !!patientId })
}

export function useUpdatePatientSite(patientId, statusMessage) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['updatePatientSite'],
    mutationFn: ({ patient, siteId }) => {
      if (isEmpty(patient?.site)) {
        return ApiClient.post(`/patients/${patientId}/add_to_site`, { siteId })
      }
      return ApiClient.post(`/patients/${patientId}/change_site`, { siteId })
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['patients'])
      queryClient.invalidateQueries(['patient'])
      queryClient.invalidateQueries(['medPassPatients'])
      statusMessage.current.show([
        {
          severity: 'success',
          summary: 'Patient site updated',
        },
      ])
    },
    onError: () => {
      statusMessage.current.show([
        {
          severity: 'error',
          summary: 'Error updating patient site',
          sticky: true,
        },
      ])
    },
  })
}

export function useUpsertPatient(patientId, statusMessage) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['upsertPatient'],
    mutationFn: async (patient) => {
      const { siteId } = patient

      // Update patient
      if (patientId) {
        const result = await ApiClient.put(`/patients/${patientId}`, { patient })

        if (siteId) {
          await ApiClient.post(`/patients/${patientId}/change_site`, { siteId })
          return result
        }

        return result
      }

      // Create patient
      return ApiClient.post('/patients', { patient })
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['patients'])
      queryClient.invalidateQueries(['patient'])
      queryClient.invalidateQueries(['medPassPatients'])
      statusMessage.current.show([
        {
          severity: 'success',
          summary: 'Patient Updated!',
        },
      ])
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function usePatientDeactivateMutation(patientId, statusMessage, patientLabelSingular) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['patient'],
    mutationFn: ({ note }) => ApiClient.post(`/users/${patientId}/deactivate`, { note }),
    onSuccess: () => {
      queryClient.invalidateQueries(['patients'])
      queryClient.invalidateQueries(['patient'])
      queryClient.invalidateQueries(['medPassPatients'])
      statusMessage.current.show([
        {
          severity: 'success',
          summary: `${patientLabelSingular} deactivated successfully`,
        },
      ])
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function usePatientUnpauseMutation(patientId, statusMessage) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['patient'],
    mutationFn: () => ApiClient.post(`/patients/${patientId}/unpause`),
    onSuccess: () => {
      queryClient.invalidateQueries(['patients'])
      queryClient.invalidateQueries(['patient'])
      queryClient.invalidateQueries(['medPassPatients'])
      queryClient.invalidateQueries('leave_of_absences')
      statusMessage.current.show([
        {
          severity: 'success',
          summary: 'Patient successfully returned from LOA',
        },
      ])
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useExportMAR(statusMessage, patientId) {
  return useMutation({
    mutationKey: ['pdfExportMAR'],
    mutationFn: async ({ year, month }) => {
      const response = await ApiClient.get(`/patients/${patientId}/export_mar.pdf?month=${month}&year=${year}`)
      downloadFileFromBase64Response(response)
    },
    onError: (error) => {
      if (!get(statusMessage, 'current')) return
      statusMessage.current.clear()
      statusMessage.current.show([
        errorMessageFromError(error),
      ])
    },
  })
}

export function useExportPRNMAR(statusMessage, patientId) {
  return useMutation({
    mutationKey: ['pdfExportPRNMAR'],
    mutationFn: async ({ year, month }) => {
      const response = await ApiClient.get(`/patients/${patientId}/export_prn_mar?month=${month}&year=${year}`)
      downloadFileFromBase64Response(response)
    },
    onError: (error) => {
      if (!get(statusMessage, 'current')) return
      statusMessage.current.clear()
      statusMessage.current.show([
        errorMessageFromError(error),
      ])
    },
  })
}

export function useExportMedicationErrorsReport(statusMessage, patientId) {
  return useMutation({
    mutationKey: ['pdfExportPRNMAR'],
    mutationFn: async ({ year, month }) => {
      const response = await ApiClient.get(`/patients/${patientId}/export_medication_errors_report?month=${month}&year=${year}`)
      downloadFileFromBase64Response(response)
    },
    onError: (error) => {
      if (!get(statusMessage, 'current')) return
      statusMessage.current.clear()
      statusMessage.current.show([
        errorMessageFromError(error),
      ])
    },
  })
}

export function useExportClinicalNotesReport(statusMessage, patientId) {
  return useMutation({
    mutationKey: ['pdfExportClinicalNotesReport'],
    mutationFn: async ({ year, month }) => {
      const response = await ApiClient.get(`/patients/${patientId}/export_clinical_notes_report?month=${month}&year=${year}`)
      downloadFileFromBase64Response(response)
    },
    onError: (error) => {
      if (!get(statusMessage, 'current')) return
      statusMessage.current.clear()
      statusMessage.current.show([
        errorMessageFromError(error),
      ])
    },
  })
}

export function useExportPhysicianOrders(statusMessage, patientId) {
  return useMutation({
    mutationKey: ['pdfExportPhysicianOrders'],
    mutationFn: async () => {
      const response = await ApiClient.get(`/patients/${patientId}/export_physician_orders`)
      downloadFileFromBase64Response(response)
    },
    onError: (error) => {
      if (!get(statusMessage, 'current')) return
      statusMessage.current.clear()
      statusMessage.current.show([
        errorMessageFromError(error),
      ])
    },
  })
}

export function useExportBowelMovementsReport(statusMessage, patientId) {
  return useMutation({
    mutationKey: ['pdfExportBowelMovementsReport'],
    mutationFn: async ({ year, month }) => {
      const response = await ApiClient.get(`/patients/${patientId}/export_bowel_movements_report?month=${month}&year=${year}`)
      downloadFileFromBase64Response(response)
    },
    onError: (error) => {
      if (!get(statusMessage, 'current')) return
      statusMessage.current.clear()
      statusMessage.current.show([
        errorMessageFromError(error),
      ])
    },
  })
}

export function useDeactivatedPatients(organizationId, statusMessage) {
  const query = useQuery({
    queryKey: ['deactivatedPatients', organizationId],
    queryFn: () => {
      let params = {}

      if (organizationId) {
        params = { organization_id: organizationId }
      }

      return ApiClient.get('/patients/deactivated', { params })
    },
    onError: (error) => {
      if (!get(statusMessage, 'current')) return
      statusMessage.current.clear()
      statusMessage.current.show([
        errorMessageFromError(error),
      ])
    },
  })

  return useMemo(() => ({
    ...query,
    data: map(get(query, 'data.patients'), (patient) => (patient)),
  }), [query])
}

export function useReactivatePatient(statusMessage, patientLabelSingular) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['reactivatePatient'],
    mutationFn: (patientId) => ApiClient.post(`/patients/${patientId}/reactivate`),
    onSuccess: () => {
      statusMessage.current.show([
        { severity: 'success', summary: `${patientLabelSingular} Reactivated!` },
      ])
      queryClient.invalidateQueries('patients')
      queryClient.invalidateQueries('medPassPatients')
      queryClient.invalidateQueries('deactivatedPatients')
    },
    onError: () => {
      statusMessage.current.show([
        { severity: 'danger', summary: `Error while Reactivating ${patientLabelSingular}.` },
      ])
    },
  })
}

export function useUnassignedPatients(organizationId, canViewUnassignedPatients, statusMessage) {
  const query = useQuery({
    queryKey: ['unassignedPatients', organizationId],
    queryFn: () => {
      let params = {}

      if (organizationId) {
        params = { organization_id: organizationId }
      }

      return ApiClient.get('/patients/unassigned', { params })
    },
    enabled: !!organizationId && canViewUnassignedPatients,
    onError: (error) => {
      if (!get(statusMessage, 'current')) return
      statusMessage.current.clear()
      statusMessage.current.show([
        errorMessageFromError(error),
      ])
    },
  })

  return useMemo(() => ({
    ...query,
    data: map(get(query, 'data.patients'), (patient) => (patient)),
  }), [query])
}

export function useExportNarcoticCountsReport(statusMessage, patientId) {
  return useMutation({
    mutationKey: ['pdfExportNarcoticCountsReport'],
    mutationFn: async ({ year, month }) => {
      const response = await ApiClient.get(`/patients/${patientId}/export_narcotic_counts_report?month=${month}&year=${year}`)
      downloadFileFromBase64Response(response)
    },
    onError: (error) => {
      if (!get(statusMessage, 'current')) return
      statusMessage.current.clear()
      statusMessage.current.show([
        errorMessageFromError(error),
      ])
    },
  })
}

export function useTherapExportMutation(statusMessage, patientId) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (doseIds) => {
      const response = await ApiClient.post(`/patients/${patientId}/therap_export`, { doseIds })
      downloadFileFromResponse(response)
    },
    onError: (error) => {
      if (!get(statusMessage, 'current')) return
      statusMessage.current.clear()
      statusMessage.current.show([errorMessageFromError(error)])

      queryClient.invalidateQueries(['patientTherapDoses'])
    },
  })
}

export function useIgnorePendingUser(statusMessage, patientId) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pendingUser }) => {
      await ApiClient.post(`/patients/${patientId}/pending_user_matches`, { pendingUserId: pendingUser.id, matchAction: 'ignore' })
    },
    onSuccess: () => {
      statusMessage.current.show([
        { severity: 'success', summary: 'The matching user was ignored' },
      ])
      queryClient.invalidateQueries(['patient', patientId])
    },
    onError: (error) => {
      if (!get(statusMessage, 'current')) return
      statusMessage.current.clear()
      statusMessage.current.show([errorMessageFromError(error)])
    },
  })
}
