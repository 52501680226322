// Keep this in sync with app/services/treatments/category_mapper.rb
const TREATMENT_CATEGORY_MAP = {
  ADAPTIVE: 'Adaptive Equipment',
  ADMIT: 'Admission',
  CBG: 'Diabetic/Insulin',
  DIET: 'Dietary',
  DIETARY: 'Dietary',
  INFO: 'Informational',
  LABS: 'Lab',
  LITERALORDER: 'Literal',
  MONITORING: 'Monitoring',
  PRN: 'PRN',
  RTN: 'Routine',
  TPRN: 'PRN',
  TREAT: 'Treatment',
  TRTN: 'Routine',
  VITALS: 'Vitals',
}

function friendlyName(category) {
  return TREATMENT_CATEGORY_MAP[category.toUpperCase()] || 'Treatment'
}

export { TREATMENT_CATEGORY_MAP, friendlyName }
