import React from 'react'
import moment from 'moment'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { useCurrentOrganization } from '@components/App'
import { useDirectoryUsers } from '@hooks/sso'
import { useReportSentryError } from '@hooks/useReportSentryError'
import { momentFormats } from '@services/utils/moment'

function timeTemplate(field, timezone = 'America/New_York', placeholder = '') {
  return function tableCell(rowData) {
    const value = rowData[field]
    return (
      <div>
        { value ? moment(value).tz(timezone).format(momentFormats.datetime_12) : placeholder }
      </div>
    )
  }
}

function DirectoryUsersTable({ model, id }) {
  const { timezone } = useCurrentOrganization()
  const { data: directoryUsers, error, isLoading } = useDirectoryUsers(model, id)
  const reportSentryError = useReportSentryError([model, id])

  if (error) {
    reportSentryError(`Error getting directory users for ${model} ${id}: ${JSON.stringify(error)}`)
    return (<div>Error getting directory users.</div>)
  }

  const handleDirectoryUserSelection = (e) => {
    // TODO: Use react-router-dom to navigate to the directory sync page after removing ActiveAdmin
    window.location.href = `/admin/directory_sync/${e.value.id}`
  }

  return (
    <DataTable
      dataKey="id"
      loading={isLoading}
      onSelectionChange={handleDirectoryUserSelection}
      removableSort
      scrollable
      scrollHeight="60vh"
      selectionMode="single"
      value={directoryUsers}
    >
      <Column
        field="id"
        header="ID"
      />
      <Column
        body={(rowData) => `${rowData.firstName} ${rowData.lastName}`}
        field="name"
        header="Name"
      />
      <Column
        field="role"
        header="Role"
      />
      <Column
        body={timeTemplate('onboardedAt', timezone)}
        field="onboardedAt"
        header="Onboarded"
      />
      <Column
        field="onboardType"
        header="Onboard Type"
      />
      <Column
        field="email"
        header="Email"
      />
      <Column
        field="username"
        header="Username"
      />
      <Column
        body={timeTemplate('createdAt', timezone)}
        field="createdAt"
        header="Created"
      />
    </DataTable>
  )
}

export default DirectoryUsersTable
