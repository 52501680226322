import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Card } from 'primereact/card'
import { Divider } from 'primereact/divider'
import LinkButtonNavigator from '@components/display/LinkButtonNavigator'
import PrescriptionsTable from '@components/prescriptions/PrescriptionsTable'

const wrapper = (component) => (
  <div className="p-4">
    {component}
  </div>
)

const viewLinks = [
  {
    label: 'All',
    linkTo: 'all',
  },
  {
    label: 'Active',
    linkTo: 'active',
  },
  {
    label: 'Needs Review',
    linkTo: 'needs_review',
  },
  {
    label: 'No Refills',
    linkTo: 'no_refills',
  },
  {
    label: 'Low Refills',
    linkTo: 'low_refills',
  },
  {
    label: 'Unlinked',
    linkTo: 'unlinked',
  },
  {
    label: 'Discontinued',
    linkTo: 'discontinued',
  },
  {
    label: 'Deleted',
    linkTo: 'deleted',
  },
]

function PrescriptionsOverviewPage({ clientId }) {
  return (
    <div className="col-12 treatments">
      <Card className="h-full treatment-card-body">
        <div className="flex flex-column text-base text-900">
          <LinkButtonNavigator buttons={viewLinks} />
          <Divider className="my-0" />
          <Routes>
            <Route
              path="all"
              element={wrapper(<PrescriptionsTable model="Patient" id={clientId} filter="all" />)}
            />
            <Route
              path="active"
              element={
                wrapper(<PrescriptionsTable model="Patient" id={clientId} filter="active" />)
              }
            />
            <Route
              path="needs_review"
              element={
                wrapper(<PrescriptionsTable model="Patient" id={clientId} filter="needs_review" />)
              }
            />
            <Route
              path="no_refills"
              element={
                wrapper(<PrescriptionsTable model="Patient" id={clientId} filter="no_refills" />)
              }
            />
            <Route
              path="low_refills"
              element={
                wrapper(<PrescriptionsTable model="Patient" id={clientId} filter="low_refills" />)
              }
            />
            <Route
              path="unlinked"
              element={
                wrapper(<PrescriptionsTable model="Patient" id={clientId} filter="unlinked" />)
              }
            />
            <Route
              path="discontinued"
              element={
                wrapper(<PrescriptionsTable model="Patient" id={clientId} filter="discontinued" />)
              }
            />
            <Route
              path="deleted"
              element={
                wrapper(<PrescriptionsTable model="Patient" id={clientId} filter="deleted" />)
              }
            />
          </Routes>
        </div>
      </Card>
    </div>
  )
}

export default PrescriptionsOverviewPage
