import React, { useEffect, useState, useCallback } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'

const DELAY_DIALOG_TIMEOUT_IN_MS = 120_000
const MAX_INACTIVITY_TIMEOUT = 86_400_000

async function refreshUserSession() {
  await fetch('/refresh_user_session')
}

async function logoutUser() {
  const options = {
    method: 'DELETE',
    headers: { Accept: 'application/json' },
  }
  await fetch('/admin/logout', options)
  window.location.replace('/admin/login')
}

function minuteOrSecondsLeft(countdownInSeconds) {
  const minutes = Math.floor(countdownInSeconds / 60)
  const seconds = countdownInSeconds % 60

  if (minutes === 0) {
    return `${seconds} second${seconds > 1 ? 's' : ''}`
  }

  if (seconds === 0 && minutes > 0) {
    return `${minutes} minute${minutes > 1 ? 's' : ''}`
  }

  return `${minutes} minute${minutes > 1 ? 's' : ''} and ${seconds} second${seconds > 1 ? 's' : ''}`
}

function UserSession({ currentUser }) {
  const [visible, setVisible] = useState(false)
  const [countdown, setCountdown] = useState(120)

  let signoutInterval = null
  const userInactivityTimeoutInMs = currentUser.sessionTimeoutInMinutes * 60000

  const scheduleSessionCheck = useCallback(() => {
    let mostRecentUserMouseMovementAt = new Date()
    setVisible(false)

    // Due to performance impact and precision timer that lead to unexpected behavior,
    // we ignore when userInactivityTimeoutInMs is greater than MAX_INACTIVITY_TIMEOUT (1 day)
    if (userInactivityTimeoutInMs > MAX_INACTIVITY_TIMEOUT) {
      return
    }

    window.addEventListener('mousemove', () => {
      mostRecentUserMouseMovementAt = new Date()
    })

    if (signoutInterval) clearTimeout(signoutInterval)

    setTimeout(() => {
      const msSinceUserMouseMovement = new Date() - mostRecentUserMouseMovementAt

      if (msSinceUserMouseMovement < (userInactivityTimeoutInMs - DELAY_DIALOG_TIMEOUT_IN_MS)) {
        refreshUserSession()
        scheduleSessionCheck()
      } else {
        setVisible(true)
        setCountdown(120)

        signoutInterval = setInterval(() => {
          setCountdown((prevCountdown) => {
            const newCountdown = prevCountdown - 1

            if (newCountdown <= 1) {
              logoutUser()
              clearInterval(signoutInterval)
            }

            return newCountdown
          })
        }, 1000)
      }
    }, userInactivityTimeoutInMs - DELAY_DIALOG_TIMEOUT_IN_MS)
  }, [currentUser])

  useEffect(() => {
    scheduleSessionCheck()
    refreshUserSession()
  }, [scheduleSessionCheck])

  return (
    <Dialog
      header={`You will be logged out in ${minuteOrSecondsLeft(countdown)}`}
      visible={visible}
      onHide={() => {
        refreshUserSession()
        scheduleSessionCheck()
      }}
      footer={(
        <div>
          <Button
            type="button"
            label="Stay Logged In"
            icon="pi pi-check"
            onClick={() => {
              refreshUserSession()
              scheduleSessionCheck()
            }}
          />
        </div>
      )}
    >
      <div>
        Your session will expire in
        {' '}
        {minuteOrSecondsLeft(countdown)}
        . Please click "Stay Logged In" to continue.
      </div>
    </Dialog>
  )
}

export default UserSession
