import React from 'react'
import { classNames } from 'primereact/utils'

function RequirementsTooltip({ value }) {
  const lengthRequirement = value?.length >= 6
  return (
    <div>
      <ul className="text-xs">
        <li>
          <i
            className={classNames('mr-2 pi', {
              'pi-check-circle text-green-500': lengthRequirement,
              'pi-times-circle text-red-500': !lengthRequirement,
            })}
            style={{ fontSize: '0.7rem' }}
          />
          At least 6 characters
        </li>
      </ul>
    </div>
  )
}

export default RequirementsTooltip
