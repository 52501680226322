import React, { useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import Block from '@components/display/Block'
import SuperAdminCard from '@components/display/SuperAdminCard'
import DirectoryUsersTable from '@components/sso/DirectoryUsersTable'
import WorkosMessagesTable from '@components/sso/WorkosMessagesTable'

const getBreadcrumbs = (siteId) => [
  {
    label: 'Site',
    template: <Link to={`/admin/sites/${siteId}`}>Site</Link>,
  },
  {
    label: 'Directory Sync',
    template: <span>Directory Sync</span>,
  },
]

function DirectorySync({ handleSetHeader }) {
  const { siteId } = useParams()

  useEffect(() => {
    handleSetHeader({ breadcrumbs: getBreadcrumbs(siteId) })
  }, [siteId])

  return (
    <div className="flex flex-row flex-wrap">
      <Block col={12}>
        <SuperAdminCard title="Directory Users">
          <DirectoryUsersTable
            id={siteId}
            model="Site"
          />
        </SuperAdminCard>
      </Block>
      <Block col={12}>
        <SuperAdminCard title="WorkOS Messages">
          <WorkosMessagesTable
            id={siteId}
            model="Site"
          />
        </SuperAdminCard>
      </Block>
    </div>
  )
}

export default DirectorySync
