import React, { useState } from 'react'
import { get, isEmpty, map } from 'lodash'
import moment from 'moment'
import CardWithHeader from '../../components/display/CardWithHeader'
import ActivityFeed from '../../components/medManagement/ActivityFeed'
import DoseTrend from './DoseTrend'
import { timeTemplate, dateTemplate } from '../../services/utils'
import {
  medicineAttribute,
  patientAttribute,
  caregiversAttribute,
} from '../../components/medManagement/DoseAttributes'

function OnTimeDoses({
  organization, timeframe, onTimeConsumptions, highRiskConsumptions, maxDataViewHeight,
}) {
  const [view, setView] = useState('feed')

  const expectedAtAttribute = (consumption) => {
    const timeSinceExpected = moment(new Date(get(consumption, 'expectedAt'))).fromNow()
    return {
      title: `Administered ${timeSinceExpected}`,
      width: '25%',
      details: [
        `${timeTemplate(get(consumption, 'confirmedAt'))} on ${dateTemplate(get(consumption, 'confirmedAt'))}`,
        `Confirmed By: ${get(consumption, 'confirmedBy.fullName', '')}`,
      ],
    }
  }

  const feedItems = map(onTimeConsumptions.data, (consumption) => ({
    attributes: [
      expectedAtAttribute(consumption),
      medicineAttribute(consumption),
      patientAttribute(consumption, organization),
      caregiversAttribute(consumption, organization),
    ],
    key: consumption.id,
  }))

  const viewMenuItems = [
    {
      label: 'Feed',
      isActive: view === 'feed',
      command: () => setView('feed'),
    },
    {
      label: 'Trend',
      isActive: view === 'trend',
      command: () => setView('trend'),
    },
  ]

  return (
    <CardWithHeader title="On Time Doses" menuItems={viewMenuItems}>
      {isEmpty(onTimeConsumptions.data) && !onTimeConsumptions.isLoading
        && (
        <div className="mt-3">
          No On Time Consumptions for
          {' '}
          {timeframe}
        </div>
        )}
      {view === 'feed' && (!isEmpty(onTimeConsumptions.data) || onTimeConsumptions.isLoading)
        && (
        <ActivityFeed
          loading={onTimeConsumptions.isLoading}
          maxDataViewHeight={maxDataViewHeight}
          items={feedItems}
        />
        )}
      {view === 'trend' && (!isEmpty(onTimeConsumptions.data) || onTimeConsumptions.isLoading)
        && (
        <DoseTrend
          timeframe={timeframe}
          loading={highRiskConsumptions.isLoading || onTimeConsumptions.isLoading}
          consumptions={[...highRiskConsumptions.data, ...onTimeConsumptions.data]}
        />
        )}
    </CardWithHeader>
  )
}

export default OnTimeDoses
