import React, { useState, useRef } from 'react'
import { map, orderBy } from 'lodash'
import { Divider } from 'primereact/divider'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import FormWrapper from '@components/display/Form/FormWrapper'
import InputTextWithLabel from '@components/display/InputTextWithLabel'
import TimePickerWithLabel from '@components/display/TimePickerWithLabel'
import { formattedTimeFromMinutes } from '@services/utils'
import { useUpdateDrawer } from '../../../features/MedBoxes/medboxesHooks'

function ReadOnlyDrawer({ drawer = {}, setSelectedDrawer }) {
  const {
    label,
    position,
    color,
    doseScheduleMinTime,
    doseScheduleMaxTime,
  } = drawer

  return (
    <div className="flex flex-column gap-2">
      <div className="flex flex-row justify-content-between align-items-end">
        <div className="flex flex-row gap-2 align-self-center align-items-center">
          <span
            className="flex"
            style={{
              color: color || 'inherit',
              backgroundColor: color || '',
              height: '1rem',
              width: '1rem',
            }}
          />
          <span className="font-semibold">{label}</span>
          <span className="text-400">|</span>
          <span>
            Position:
            {' '}
            {position}
          </span>
        </div>
        <Button
          className="p-button-sm p-button-text p-button-warning"
          label="Edit"
          onClick={() => setSelectedDrawer(drawer)}
        />
      </div>
      <div className="flex flex-row gap-2">
        <span>Dose Schedule Times:</span>
        {
          doseScheduleMinTime && doseScheduleMaxTime && (
            <span className="font-semibold">
              {formattedTimeFromMinutes(doseScheduleMinTime)}
              {' '}
              to
              {' '}
              {formattedTimeFromMinutes(doseScheduleMaxTime)}
            </span>
          )
        }
        {
          (!doseScheduleMinTime || !doseScheduleMaxTime) && (
            <span className="text-500">None</span>
          )
        }
      </div>
    </div>
  )
}

function EditableDrawer({
  medBoxId, id, label, doseScheduleMinTime, doseScheduleMaxTime, handleSetDrawerFields, handleClose,
}) {
  const statusMessage = useRef(null)

  const {
    mutateAsync: updateDrawer,
    isLoading: updateIsLoading,
  } = useUpdateDrawer(medBoxId, statusMessage)

  const handleUpdateDrawer = async () => {
    await updateDrawer({
      id, label, doseScheduleMinTime, doseScheduleMaxTime,
    })
    handleClose()
  }

  return (
    <div className="flex flex-column h-full justify-content-between" data-testid={`drawer-form-${label}`}>
      <Toast ref={statusMessage} />
      <div className="flex flex-column">
        <InputTextWithLabel
          label="Drawer Label"
          value={label || ''}
          inputId="drawer-label"
          name="drawer-label"
          onChange={({ target: { value } }) => handleSetDrawerFields({ label: value })}
        />
        <TimePickerWithLabel
          label="Minimum Dose Schedule Time"
          inputId="doseScheduleMinTimeInput"
          placeholder="Select a minimum time"
          showClear
          value={doseScheduleMinTime}
          onChange={(value) => handleSetDrawerFields({ doseScheduleMinTime: value })}
        />
        <TimePickerWithLabel
          label="Maximum Dose Schedule Time"
          inputId="doseScheduleMinTimeInput"
          placeholder="Select a maximum time"
          minTime={doseScheduleMinTime?.time}
          showClear
          value={doseScheduleMaxTime}
          onChange={(value) => handleSetDrawerFields({ doseScheduleMaxTime: value })}
        />
      </div>
      <Button
        className="p-button-sm"
        label="Save Drawer"
        loading={updateIsLoading}
        onClick={handleUpdateDrawer}
      />
    </div>
  )
}

function MedboxDrawers({ medBoxId, drawers }) {
  const [selectedDrawer, setSelectedDrawer] = useState()

  const handleSetDrawerFields = (fields) => {
    setSelectedDrawer((drawer) => ({ ...drawer, ...fields }))
  }

  const sortedDrawers = orderBy(drawers, 'position')

  let title = 'Drawers'
  if (selectedDrawer) {
    title = (
      <div className="flex flex-row gap-1 align-items-baseline">
        <span className="text-blue-500 cursor-pointer" onClick={() => setSelectedDrawer()}>
          Drawers
        </span>
        <i className="pi pi-chevron-right" style={{ fontSize: '0.75em' }} />
        <span className="text-500 text-sm">
          Edit
          {' '}
          {selectedDrawer.label}
        </span>
      </div>
    )
  }

  return (
    <div className="col-6">
      <FormWrapper title={title} fullHeight>
        <div className="flex flex-column gap-2">
          {
            !selectedDrawer && map(
              sortedDrawers,
              (drawer, index) => (
                <div key={drawer.id}>
                  <ReadOnlyDrawer drawer={drawer} setSelectedDrawer={setSelectedDrawer} />
                  {(index + 1) < drawers?.length && <Divider className="mb-0" />}
                </div>
              ),
            )
          }
          {
            selectedDrawer && (
              <EditableDrawer
                {...selectedDrawer}
                medBoxId={medBoxId}
                handleSetDrawerFields={handleSetDrawerFields}
                handleClose={() => setSelectedDrawer()}
              />
            )
          }
        </div>
      </FormWrapper>
    </div>
  )
}

export default MedboxDrawers
