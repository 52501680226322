export const SUPER_ADMIN_ROLE = 'superadmin'

export const MED_BOX_TYPE = 'simpill'
export const STRIP_MED_BOX_TYPE = 'strip'
export const E_KIT_TYPE = 'ekit'

export const MED_BOXES_PATH = 'admin/med_boxes'
export const E_KITS_PATH = 'admin/ekits'

export const NOT_APPLICABLE_TEXT = 'N/A'

export const MED_BOX_STATUS_TAG_COLOR = {
  online: 'var(--green-400)',
  offline: 'var(--red-500)',
}

export const LABELS = {
  [MED_BOX_TYPE]: 'Med Box',
  [STRIP_MED_BOX_TYPE]: 'Strip Med Box',
  [E_KIT_TYPE]: 'E-Kit',
}
