import React, { useState, useEffect } from 'react'
import moment from 'moment'

function ExpiryCountdownLabel({ expiryDate }) {
  const calculateTimeLeft = () => {
    const now = moment()
    const expiration = moment(expiryDate)

    if (now.isBefore(expiration)) {
      const duration = moment.duration(expiration.diff(now))
      const hours = duration.hours()
      const minutes = duration.minutes()

      if (hours === 0 && minutes === 0) {
        return 'Expires soon'
      }

      let timeLeft = 'Expires in '
      if (hours > 0) {
        timeLeft += `${hours} ${hours === 1 ? 'hr' : 'hrs'}`
        if (minutes > 0) {
          timeLeft += ', '
        }
      }
      if (minutes > 0) {
        timeLeft += `${minutes} ${minutes === 1 ? 'min' : 'mins'}`
      }
      return timeLeft
    }

    return 'Expired'
  }

  const [expiryText, setExpiryText] = useState(calculateTimeLeft())

  useEffect(() => {
    const intervalId = setInterval(() => {
      setExpiryText(calculateTimeLeft())
    }, 1000)

    return () => clearInterval(intervalId)
  }, [expiryDate])

  return <span>{expiryText}</span>
}

export default ExpiryCountdownLabel
