import { Button } from 'primereact/button'
import React from 'react'

function EditButton({
  onClick, isEditing, loading, disabled,
}) {
  return (
    <Button
      className="p-button-text p-button-sm p-button-warning"
      label={isEditing ? 'Cancel' : 'Edit'}
      icon={isEditing ? 'pi pi-times' : 'pi pi-file-edit'}
      onClick={onClick}
      disabled={loading || disabled}
    />
  )
}

export default EditButton
