import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import {
  displayErrorMessages,
} from '@services/errorHandler'

export function useCreatePatientInsurance(statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['useCreatePatientInsurance'],
    mutationFn: (patientInsurance) => ApiClient.post('/patient_insurances', { patientInsurance }),
    onSuccess: () => {
      statusMessage.current.show([
        {
          severity: 'success',
          summary: 'Insurance created!',
        },
      ])
      queryClient.invalidateQueries('patientInsurances')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useUpdatePatientInsurance(statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['useUpdatePatientInsurance'],
    mutationFn: (patientInsurance) => ApiClient.put(`/patient_insurances/${patientInsurance.id}`, { patientInsurance }),
    onSuccess: () => {
      statusMessage.current.show([
        {
          severity: 'success',
          summary: 'Insurance updated!',
        },
      ])
      queryClient.invalidateQueries('patientInsurances')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useDeletePatientInsurance(statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['useDeletePatientInsurance'],
    mutationFn: (patientInsurance) => ApiClient.delete(`/patient_insurances/${patientInsurance.id}`),
    onSuccess: () => {
      statusMessage.current.show([
        {
          severity: 'success',
          summary: 'Insurance deleted!',
        },
      ])
      queryClient.invalidateQueries('patientInsurances')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}
