import React, { useRef } from 'react'
import { get } from 'lodash'
import moment from 'moment'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'
import { medicineDisplayName } from '@components/clientDoses/doseUtils'
import ActivityReviewDiff from './ActivityReviewDiff'
import './pharmacy-dashboard-styles.scss'

const dialogHeaderTemplate = (organization, activity) => {
  const trackableType = get(activity, 'trackable.type', '')
  let title = 'Review Activity'
  let patientName

  if (trackableType === 'Dose') {
    title += ` for Dose: ${medicineDisplayName(activity.current)}`
    patientName = `${get(organization, 'patientLabelSingular')}: ${get(activity, 'current.patientName')}`
  }

  if (trackableType === 'User') {
    title += ` for ${get(organization, 'patientLabelSingular')}: ${get(activity, 'current.fullName')}`
  }

  return (
    <div className="flex flex-column align-items-center w-full">
      <div className="flex flex-row justify-content-center w-full">
        <h3 className="font-semibold text-lg">
          {title}
        </h3>
      </div>
      {patientName && (
        <div className="flex flex-row justify-content-start w-full">
          <span className="text-sm">{patientName}</span>
        </div>
      )}
    </div>
  )
}

const getActivityDetails = (activity) => {
  const diffs = []

  Object.keys(activity.diff).forEach((key) => {
    activity.diff[key].forEach((diff) => {
      diffs.push(ActivityReviewDiff({ activityId: activity.id, diff, resourceType: key }))
    })
  })

  if (diffs.length === 0) {
    return (
      <div>No relevant changes to display at this time. Activity can be marked as complete.</div>
    )
  }

  return (
    <ul>
      {diffs}
    </ul>
  )
}

// TODO: A better, long-term approach would be to query the backend for all authorized activities
//       for the trackable so that we aren't limited to the activities that appeared in the feed.
function ActivityReviewDialog({
  activities,
  canConfirmActivities,
  onAcknowledge,
  onHide,
  organization,
  visible,
}) {
  const activityReviewStatusMessage = useRef(null)

  const header = (activity) => {
    const isCreate = get(activity, 'activityType', '') === 'MANUAL_CREATE'
    const acknowledgedAt = get(activity, 'acknowledged.at')
    let acknowledgedBy = get(activity, 'acknowledged.by', 'Unknown User')
    const timeSinceActivity = moment(get(activity, 'lastOccurredAt')).fromNow()
    const timeSinceAcknowledged = moment(acknowledgedAt).fromNow()
    let trackable = get(activity, 'trackable.type', '')

    if (acknowledgedBy === null) {
      acknowledgedBy = 'Unknown User'
    }

    if (trackable === 'User') {
      trackable = get(organization, 'patientLabelSingular')
    }

    const activityText = `${trackable} ${isCreate ? 'created' : 'updated'} ${timeSinceActivity}`

    return (
      <div className="flex justify-content-between align-items-center">
        <div className="flex align-items-center justify-content-center">{activityText}</div>
        {acknowledgedAt && (
          <div className="white-space-nowrap text-xs">
            {`Completed by ${acknowledgedBy} ${timeSinceAcknowledged}`}
          </div>
        )}
        {!acknowledgedAt && canConfirmActivities && (
          <Button
            className="flex align-items-center ml-auto p-button-text"
            icon="pi pi-check-square"
            label="Complete"
            onClick={() => onAcknowledge(activity)}
          />
        )}
      </div>
    )
  }

  // Get the indexes of all activities that have not been acknowledged
  const activeIndexes = activities.reduce((acc, activity, i) => {
    if (!get(activity, 'acknowledged.at')) acc.push(i)
    return acc
  }, [])

  return (
    <Dialog
      header={dialogHeaderTemplate(organization, activities?.[0])}
      onHide={onHide}
      position="top"
      style={{ width: '50vw' }}
      visible={visible}
    >
      <Toast ref={activityReviewStatusMessage} />
      <Accordion
        activeIndex={activeIndexes}
        className="flex flex-column gap-3 activity-feed"
        multiple
      >
        {activities.map((activity) => (
          <AccordionTab
            className="w-full"
            header={header(activity)}
            key={activity.id}
          >
            <div className="m-0">
              {getActivityDetails(activity)}
            </div>
          </AccordionTab>
        ))}
      </Accordion>
    </Dialog>
  )
}

export default ActivityReviewDialog
