import React from 'react'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import { map } from 'lodash'
import { administrationRoutes } from '../config'

function AdministrationRoute({
  administrationRoute, setAdministrationRoute, handleConfirm,
}) {
  const options = map(administrationRoutes, (value, key) => ({ label: value.name, value: key }))
    .sort((a, b) => a.label.localeCompare(b.label))

  return (
    <div className="grid flex flex-grow-1 justify-content-center align-items-center fadein">
      <div className="col-9 flex flex-column gap-2 text-center">
        <label className="text-lg font-normal mb-1" htmlFor="dose-administration-route">
          {/* eslint-disable-next-line */}
          What is the administration route for this dose?
          <p className="text-sm font-normal">Example, Oral, Subcutaneous, Intramuscular</p>
        </label>
        <Dropdown
          inputId="dose-administration-route"
          value={administrationRoute}
          data-testid="administration-route"
          options={options}
          onChange={(e) => setAdministrationRoute(e.value)}
          aria-label="Administration Route"
          dropdownarialabel="Select Administration Route"
        />
        <Button
          onClick={handleConfirm}
          label="Next"
          className="p-button-text mt-2"
          disabled={administrationRoute === null}
        />
      </div>
    </div>
  )
}

export default AdministrationRoute
