import React, { useEffect, useState } from 'react'
import { Checkbox } from 'primereact/checkbox'
import { SelectButton } from 'primereact/selectbutton'
import { rrulestr } from 'rrule'
import { timeTemplate } from '@services/utils'

const VIEWS = {
  allTimes: 'all_times',
  selectedTimes: 'selected_times',
}

const itemTemplate = ({
  doseSchedule,
  selectedSchedules,
  setSelectedSchedules,
  takenScheduleIds,
  timeSelection,
}) => {
  const allTimesViewSelected = timeSelection === VIEWS.allTimes
  const disabled = allTimesViewSelected || takenScheduleIds.includes(doseSchedule.id)
  const key = `schedule-${doseSchedule.id}`
  return (
    <div className="flex align-items-center" key={`container-${key}`}>
      <Checkbox
        disabled={disabled}
        inputId={key}
        name={key}
        value={doseSchedule.id}
        onChange={(e) => {
          if (e.checked) {
            setSelectedSchedules([...selectedSchedules, doseSchedule.id])
          } else {
            setSelectedSchedules(
              selectedSchedules.filter((scheduleId) => scheduleId !== doseSchedule.id),
            )
          }
        }}
        checked={allTimesViewSelected || selectedSchedules.includes(doseSchedule.id)}
      />
      <label htmlFor={key} className={`ml-2 ${!allTimesViewSelected && disabled && 'line-through'}`}>
        {`${timeTemplate(doseSchedule.time)} ${rrulestr(doseSchedule.rrule).toText()}`}
      </label>
    </div>
  )
}

function DoseScheduleSelector({
  conditionType, doseSchedules, otherDoseConditionsForType, selectedSchedules, setSelectedSchedules,
}) {
  // Get the schedules that have already been selected by another condition of the same type
  const takenScheduleIds = otherDoseConditionsForType
    .flatMap((condition) => condition.schedules.map((schedule) => schedule.id))

  // Only allow all times if no schedules have been selected by another condition of the same type
  const allTimesAvailable = takenScheduleIds.length === 0

  const [timeSelection, setTimeSelection] = useState(VIEWS.allTimes)

  useEffect(() => {
    setTimeSelection(
      allTimesAvailable && selectedSchedules.length === 0 ? VIEWS.allTimes : VIEWS.selectedTimes,
    )
  }, [conditionType])

  if (!conditionType) {
    return null
  }

  const handleTimeSelection = (value) => {
    setTimeSelection(value)

    if (value === VIEWS.allTimes) {
      setSelectedSchedules([])
    }
  }

  const options = [
    { label: 'All Times', value: VIEWS.allTimes, disabled: !allTimesAvailable },
    { label: 'Selected Times', value: VIEWS.selectedTimes },
  ]

  const scheduleSelectDescription = timeSelection === VIEWS.allTimes
    ? 'New schedules added to this dose will be selected automatically.'
    : 'Can only select times not selected by another criteria / vitals of this type.'

  const sortedSchedules = doseSchedules.sort((s1, s2) => s1.time - s2.time)

  return (
    <div className="col-12 flex flex-column gap-2 text-center">
      <label className="text-lg font-normal mb-1 text-center">
        Does this criteria / vitals apply to all dose times or select?
      </label>
      <SelectButton
        className="dose-condition-time-select"
        name="DoseConditionSchedule"
        onChange={(e) => handleTimeSelection(e.value)}
        options={options}
        unselectable={false}
        value={timeSelection}
      />
      <div className="flex flex-column gap-2">
        <div>
          <small><i>{scheduleSelectDescription}</i></small>
        </div>
        <div>
          {sortedSchedules.map((doseSchedule) => itemTemplate({
            doseSchedule,
            selectedSchedules,
            setSelectedSchedules,
            takenScheduleIds,
            timeSelection,
          }))}
        </div>
      </div>
    </div>
  )
}

export default DoseScheduleSelector
