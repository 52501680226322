import { enumerateDaysBetweenMoments } from '@services/utils'
import { momentFormats } from '@services/utils/moment'
import { camelCase, get } from 'lodash'
import moment from 'moment-timezone'

export function formatDashboardItems(vitalsDashboardItems, timezone) {
  if (!vitalsDashboardItems) return []
  return vitalsDashboardItems.map((dashboardItem) => {
    const conditionChecks = dashboardItem.conditionChecks.reduce((acc, dashboardConditionCheck) => {
      const baseTime = dashboardConditionCheck.expectedAt || dashboardConditionCheck.actionedAt
      const date = moment(baseTime).tz(timezone).format(momentFormats.date)
      if (!acc[date]) acc[date] = []
      acc[date].push(dashboardConditionCheck)
      return acc
    }, {})
    return { ...dashboardItem, conditionChecks }
  })
}

export function vitalsDashboardColumns(
  {
    dateRange,
    measurementTypeTemplate,
    conditionCheckTemplate,
  },
) {
  const days = enumerateDaysBetweenMoments(dateRange.startDate, dateRange.endDate)
  const columns = [
    {
      field: 'measurement_type',
      header: 'Measurement Type',
      body: measurementTypeTemplate,
      style: { minWidth: '18rem', width: '18rem', maxWidth: '18rem' },
    },
  ]

  days.forEach((day) => {
    columns.push({
      field: `conditionChecks.${day.format(momentFormats.date)}`,
      header: day.format('MMM Do'),
      body: conditionCheckTemplate,
      style: { minWidth: '10rem', width: '10rem', maxWidth: '10rem' },
    })
  })

  return columns
}

export function conditionChecksByStatus(conditionChecks) {
  return conditionChecks.reduce((acc, conditionCheck) => {
    if (!acc[conditionCheck.status]) acc[conditionCheck.status] = []
    acc[conditionCheck.status].push(conditionCheck)
    return acc
  }, {})
}

export function temperatureUnitDisplay(temperatureUnit) {
  switch (temperatureUnit) {
    case 'fahrenheit':
      return '°F'
    case 'celsius':
      return '°C'
    default:
      return ''
  }
}

export function conditionCheckValueDisplay(conditionCheck) {
  switch (conditionCheck.measurementType) {
    case 'BloodPressure':
      return `${conditionCheck.bloodPressure.systolic}/${conditionCheck.bloodPressure.diastolic} mmHg`
    case 'BloodGlucose':
      return `${conditionCheck.bloodGlucose.value} ${conditionCheck.bloodGlucose.unit}`
    case 'Temperature':
      return `${conditionCheck.temperature.value}${temperatureUnitDisplay(conditionCheck.temperature.unit)}`
    case 'Pulse':
      return `${conditionCheck.pulse.bpm} bpm`
    case 'BloodOxygen':
      return `${conditionCheck.bloodOxygen.value}%`
    case 'Height':
      return `${conditionCheck.height.value} ${conditionCheck.height.unit}`
    case 'Weight':
      return `${conditionCheck.weight.value} ${conditionCheck.weight.unit}`
    default:
      return ''
  }
}

export function conditionCheckTakenBy(conditionCheck) {
  return get(conditionCheck, `${camelCase(conditionCheck.measurementType)}.takenBy`)
}

export function conditionCheckTakenAt(conditionCheck) {
  return get(conditionCheck, `${camelCase(conditionCheck.measurementType)}.createdAt`)
}
