import React from 'react'
import { Button } from 'primereact/button'

function PatientsToLinkTableHeader({ usersLength, patientRoleLabel, action }) {
  const title = usersLength > 0
    ? `Potential Duplicate ${patientRoleLabel} Found - Link to Existing ${patientRoleLabel}`
    : `No Potential Duplicate ${patientRoleLabel} Found - Create New ${patientRoleLabel}`

  return (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="font-bold">{title}</span>
      { action && (
        <Button
          icon="pi pi-user-plus"
          label={`Create New ${patientRoleLabel}`}
          raised
          onClick={action}
        />
      )}
    </div>
  )
}

export default PatientsToLinkTableHeader
