import { Dialog } from 'primereact/dialog'
import React from 'react'
import { useOrderIntegrationData } from '../../../features/ClientDoses/PharmacyOrders/pharmacyOrderHooks'

function DetailsDialog({ pharmacyOrder, isVisible, hide }) {
  const {
    data: { integrationData = [] } = {},
    isLoading,
  } = useOrderIntegrationData(pharmacyOrder?.id)

  if (!pharmacyOrder) {
    return null
  }

  return (
    <Dialog
      header="Details"
      visible={isVisible}
      onHide={() => hide()}
      style={{ width: '50vw' }}
      dismissableMask
    >
      {isLoading && <p>Loading...</p>}
      <div className="flex flex-column gap-1">
        <h3 className="sticky top-0 bg-white">Pharmacy Order</h3>
        <pre>{JSON.stringify(pharmacyOrder, null, 2)}</pre>
        {
          integrationData.map((data) => (
            <div key={data.id}>
              <h3 className="sticky top-0 bg-white">Integration Data</h3>
              <pre>{JSON.stringify(data, null, 2)}</pre>
            </div>
          ))
        }
      </div>
    </Dialog>
  )
}

export default DetailsDialog
