import React, { useRef } from 'react'

import { Button } from 'primereact/button'
import { Messages } from 'primereact/messages'
import moment from 'moment'
import { classNames } from 'primereact/utils'
import { useArchiveDoseFillMutation } from './doseAssignmentHooks'
import { dateTemplate } from '../../../services/utils'

function ArchiveFill({ ekitId, doseFill, onSuccess }) {
  const statusMessage = useRef(null)
  const mutation = useArchiveDoseFillMutation(ekitId, statusMessage)

  const handleArchive = async () => {
    await mutation.mutateAsync(doseFill.id)
    onSuccess()
  }

  const isExpired = moment(doseFill.expiresAt).isBefore(moment())
  const expirationClass = classNames({ 'text-orange-500': isExpired, 'font-bold': isExpired })

  return (
    <>
      <Messages ref={statusMessage} />
      <div className="flex flex-column gap-1">
        <span className="font-bold">Are you sure you want to archive this fill?</span>
        <div className="flex flex-column">
          <span>{doseFill.medicine.displayName}</span>
          <div>
            <span className={expirationClass}> Expiration: </span>
            {' '}
            {dateTemplate(doseFill.expiresAt)}
          </div>
          {
            doseFill.agencyLoadedAt && (
              <span>
                Loaded:
                {' '}
                {dateTemplate(doseFill.agencyLoadedAt)}
              </span>
            )
          }
        </div>
        <div className="flex flex-row gap-2 justify-content-end py-2">
          <Button label="Yes" className="p-button-sm p-button-warning" onClick={handleArchive} />
          <Button label="No" className="p-button-sm p-button-secondary" onClick={onSuccess} />
        </div>
      </div>
    </>
  )
}

export default ArchiveFill
