import React, { useState } from 'react'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { map } from 'lodash'
import { administrationRoutes } from '../clientDoses/config'

function AdministrationRouteEditor({
  administrationRoute, setAdministrationRoute, isLoading,
}) {
  const [route, setRoute] = useState(administrationRoute)

  const handleConfirmation = () => {
    setAdministrationRoute(route)
  }

  const options = map(administrationRoutes, (value, key) => ({ label: value.name, value: key }))
    .sort((a, b) => a.label.localeCompare(b.label))

  return (
    <div className="flex flex-row gap-2 align-items-center w-12">
      <Dropdown
        className="w-16rem"
        value={route}
        options={options}
        onChange={(e) => setRoute(e.value)}
        placeholder="Select a Route"
      />
      <Button
        onClick={handleConfirmation}
        loading={isLoading}
        icon="pi pi-check"
      />
    </div>
  )
}

export default AdministrationRouteEditor
