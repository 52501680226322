import { useState } from 'react'
import { getMiddleMoment, momentFormats } from '@services/utils/moment'
import moment from 'moment'

const useDateNavigatorUtils = (currentOrganization) => {
  const [granularity, setGranularity] = useState('Weekly')

  const granularityToTimeUnit = {
    daily: 'days',
    weekly: 'weeks',
    monthly: 'months',
  }

  const timeUnit = granularityToTimeUnit[granularity.toLowerCase()]

  const [
    dateRange,
    setDateRange,
  ] = useState({
    startDate: moment().tz(currentOrganization.timezone).startOf(timeUnit),
    endDate: moment().tz(currentOrganization.timezone).endOf(timeUnit),
  })

  const nextButtonDisable = () => {
    const startDateAfterNext = moment(dateRange.startDate)
      .add(1, timeUnit)
    return moment().isBefore(startDateAfterNext)
  }

  const onPrevious = () => {
    const startDate = dateRange.startDate.clone().subtract(1, timeUnit)
      .tz(currentOrganization.timezone)
    const startOf = startDate.startOf(timeUnit).clone()
    const endOf = startDate.endOf(timeUnit).clone()
    setDateRange({ startDate: startOf, endDate: endOf })
  }

  const onNext = () => {
    const startDate = dateRange.startDate.clone().add(1, timeUnit)
      .tz(currentOrganization.timezone)
    const startOf = startDate.startOf(timeUnit).clone()
    const endOf = startDate.endOf(timeUnit).clone()
    setDateRange({ startDate: startOf, endDate: endOf })
  }

  const onGranularityChange = (e) => {
    // If the granularity is custom, we will use the current granularity to calculate the date range
    let granularityToCalculate = e.value
    if (granularityToCalculate === 'Custom') {
      granularityToCalculate = granularity || 'Weekly'
    }
    setGranularity(e.value)
    const baseDate = getMiddleMoment(dateRange.startDate, dateRange.endDate)
    const newTimeUnit = granularityToTimeUnit[granularityToCalculate.toLowerCase()]
    const startAt = baseDate.startOf(newTimeUnit).clone()
    const endAt = baseDate.endOf(newTimeUnit).clone()
    setDateRange({ startDate: startAt, endDate: endAt })
  }
  const formatForDayCount = (dayCount) => {
    if (dayCount === 1) return momentFormats.monthDay
    if (dayCount <= 7) return momentFormats.monthShortDay
    return momentFormats.onlyDay
  }

  return {
    onPrevious,
    onNext,
    onGranularityChange,
    dateRange,
    setDateRange,
    granularity,
    granularityToTimeUnit,
    nextButtonDisable,
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
    totalDays: dateRange.endDate.diff(dateRange.startDate, 'days') + 1,
    formatForDayCount,
  }
}

export default useDateNavigatorUtils
