import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Badge } from 'primereact/badge'
import { Button } from 'primereact/button'
import { classNames } from 'primereact/utils'

/**
 * Component to render a button navigator with links.
 *
 * @param {Object} props - Component props
 * @param {Object} props.actionButton - The optional action button to render
 * @param {string} props.actionButton.label - Label to display
 * @param {string} props.actionButton.linkTo - Link to navigate to
 * @param {Array.<{linkTo: string, label: string, badge?: Object, icon?: string}>} props.buttons
 * @param {string | undefined} props.className - Optional class name
 */
function LinkButtonNavigator({
  actionButton,
  buttons,
  className = 'flex flex-row justify-space-between align-items-center pl-2 pr-4 py-3',
}) {
  return (
    <div className={className}>
      <div className="flex flex-row align-items-center">
        {
          buttons.map(({
            linkTo, label, badge, icon,
          }) => (
            <NavLink to={linkTo} key={linkTo}>
              {
                ({ isActive }) => (
                  <Button className={classNames('flex flex-row align-items-center p-button-sm p-button-outlined', { 'p-button-text': !isActive })}>
                    {badge ? <Badge severity={badge.severity} value={badge.value} /> : null}
                    {icon ? <i className={`pi ${icon}`} /> : null}
                    <span className={classNames({ 'text-600': !isActive, 'ml-2': !!badge })}>
                      {label}
                    </span>
                  </Button>
                )
              }
            </NavLink>
          ))
        }
      </div>
      {
        actionButton && (
          <Link to={actionButton.linkTo}>
            <Button className="p-button-sm" label={actionButton.label} />
          </Link>
        )
      }
    </div>
  )
}

export default LinkButtonNavigator
