import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { Divider } from 'primereact/divider'
import SearchField from '@components/display/Form/SearchField'
import { exactSearch } from '@services/utils/search'
import {
  usePatientGuardians,
  usePossiblePatientGuardians,
  usePatientGuardiansMutation,
} from './guardianListHooks'

function GuardianList({ patient, handleSetHeader }) {
  const { patientId } = useParams()
  const [isEditing, setIsEditing] = useState(false)
  const [selectedGuardians, setSelectedGuardians] = useState([])
  const [searchTerm, setSearchTerm] = useState()
  const [filteredGuardians, setFilteredGuardians] = useState([])

  const {
    data: [assignedGuardians],
    isLoading: isLoadingAssignedGuardians,
    refetch: assignedRefetch,
  } = usePatientGuardians(patientId)

  const {
    data: [possibleGuardians],
    isLoading: isLoadingPossibleGuardians,
  } = usePossiblePatientGuardians(patientId)

  const mutation = usePatientGuardiansMutation(patientId)

  const saveGuardians = async () => {
    const guadianIds = selectedGuardians.map((guardian) => guardian.id)
    await mutation.mutateAsync(guadianIds)
    await assignedRefetch()
    setIsEditing(false)
    setSearchTerm()
  }

  const handleSelection = ({ value }) => {
    setSelectedGuardians(value)
    setSearchTerm()
  }

  useEffect(() => handleSetHeader({ primaryAction: null }), [patient])

  useEffect(() => {
    if (!isEditing) {
      setSelectedGuardians([])
    } else {
      setSelectedGuardians(assignedGuardians)
    }
  }, [isEditing])

  useEffect(() => {
    if (isEditing) {
      setFilteredGuardians(exactSearch(possibleGuardians, ['firstName', 'lastName'], searchTerm))
    } else {
      setFilteredGuardians(exactSearch(assignedGuardians, ['firstName', 'lastName'], searchTerm))
    }
  }, [assignedGuardians, possibleGuardians, searchTerm, isEditing])

  return (
    <div className="col-12">
      <Card className="h-full">
        <div className="flex flex-column gap-3 text-base text-900">
          <div className="flex flex-row justify-content-between align-items-center px-1">
            <SearchField
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              placeholder="Search guardians by name"
            />
            { !isEditing
              && (
              <Button
                label="Edit Assignments"
                className="p-button-sm"
                onClick={() => setIsEditing(true)}
              />
              )}
            { isEditing
              && (
              <Button
                className="p-button-sm"
                icon="pi pi-times"
                onClick={() => setIsEditing(false)}
              />
              )}
          </div>
          <Divider className="my-0" />
          <DataTable
            value={filteredGuardians}
            loading={
              isEditing
                ? isLoadingPossibleGuardians
                : isLoadingAssignedGuardians
            }
            emptyMessage={isEditing ? 'No guardians available' : 'No guardians assigned'}
            selection={isEditing ? selectedGuardians : assignedGuardians}
            onSelectionChange={handleSelection}
            dataKey="id"
            sortField="lastName"
            sortOrder={1}
          >
            {isEditing && (
              <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
            )}
            <Column field="firstName" header="First Name" />
            <Column field="lastName" header="Last Name" sortable />
            <Column field="email" header="Email" />
            <Column field="phone" header="Phone" />
          </DataTable>
          <div className="flex flex-row">
            {isEditing
              && (
              <Button
                label="Save Assignments"
                className="p-button-sm p-button-success mb-3"
                loading={mutation.isLoading || isLoadingAssignedGuardians}
                onClick={() => saveGuardians()}
              />
              )}
          </div>
        </div>
      </Card>
    </div>
  )
}

export default GuardianList
