import { get } from 'lodash'
import moment from 'moment'
import { timeTemplate, dateTemplate } from '@services/utils'
import { medicineDisplayName } from '../../components/clientDoses/doseUtils'
import { getPatientNameFromActivity } from './utils'

const activityAtAttribute = (organization, activity) => {
  const isCreate = get(activity, 'activityType') === 'MANUAL_CREATE'
  const timeSinceActivity = moment(get(activity, 'lastOccurredAt')).fromNow()
  let trackable = get(activity, 'trackable.type', '')
  const patientName = getPatientNameFromActivity(activity)

  if (trackable === 'User') {
    trackable = get(organization, 'patientLabelSingular')
  }

  const date = dateTemplate(get(activity, 'lastOccurredAt'))
  const time = timeTemplate(get(activity, 'lastOccurredAt'))

  return {
    title: `${trackable} ${isCreate ? 'created' : 'last updated'} ${timeSinceActivity}`,
    width: '25%',
    details: [
      patientName,
      `${date} at ${time}`,
    ],
  }
}

const medicineAttribute = (dose) => ({
  title: medicineDisplayName(dose),
  width: '30%',
  details: [
    `Strength: ${get(dose, 'medicine.strength', '')}`,
    `Administration Amount: ${get(dose, 'administrationAmount', '')}`,
  ],
})

const patientAttribute = (organization, patient) => ({
  title: get(organization, 'patientLabelSingular', 'Patient'),
  width: '30%',
  details: [
    get(patient, 'fullName', ''),
    `Current Site: ${get(patient, 'site.name', 'N/A')}`,
  ],
})

// This is actually just a function that returns an object, but it is essentially used as a
// child component. Defining separately as though it were a component to prepare for a likely
// future refactor of the ActivityFeed component that consumes this object.
function ActivityFeedItem({
  acknowledgmentChangingForActivityId,
  activity,
  canConfirmActivities,
  handleActivityAcknowledgmentChange,
  isLoading,
  organization,
  setActivityReviewDialogId,
}) {
  const isCompleted = get(activity, 'acknowledged.at') !== null
  const isPendingReview = !isCompleted

  const trackableType = get(activity, 'trackable.type', '')

  const activityFeedItem = ({
    actions: [],
    attributes: [activityAtAttribute(organization, activity)],
    isCompleted,
    isPendingReview,
    key: activity.id,
  })

  if (trackableType === 'Dose') {
    // TODO: Add attribute for dose schedules?
    activityFeedItem.attributes.push(medicineAttribute(activity.current))
  }

  if (trackableType === 'User') {
    // TODO: Add attributes for patient settings, insurances, & allergies?
    activityFeedItem.attributes.push(patientAttribute(organization, activity.current))
  }

  if (isCompleted && canConfirmActivities) {
    activityFeedItem.actions.push(
      {
        icon: 'pi pi-undo',
        label: 'Undo',
        loading: isLoading && activity.id === acknowledgmentChangingForActivityId,
        command: () => handleActivityAcknowledgmentChange(activity, false),
      },
    )
  }

  if (isPendingReview) {
    activityFeedItem.actions.push(
      {
        icon: 'pi pi-file-edit',
        label: 'Review',
        loading: isLoading && activity.id === acknowledgmentChangingForActivityId,
        command: () => setActivityReviewDialogId(get(activity, 'trackable.id')),
      },
    )

    if (canConfirmActivities) {
      activityFeedItem.actions.push(
        {
          icon: 'pi pi-check-square',
          label: 'Complete',
          loading: isLoading && activity.id === acknowledgmentChangingForActivityId,
          command: () => handleActivityAcknowledgmentChange(activity, true),
        },
      )
    }
  }

  return activityFeedItem
}

export default ActivityFeedItem
