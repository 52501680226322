import React from 'react'
import MedsDueNow from './MedsDueNow'
import MedsDueLater from './MedsDueLater'

// This component visually communicates the med pass status of a patient.
//
// If a patient has any consumptions that are currently allowed ("now" is between the allowed and
// expired time of any consumption), it will display:
//
// 1. The text inside the circle will show the number of meds that are due now and not confirmed
// 2. The border will act as a progress bar showing time left until the first med expires
// 3. The fill of the circle will act as a progress bar showing how many meds have been confirmed
// 4. The text to the right of the circle will show:
//    a. If now is before expected - "Due in <>"
//    b. If it's past the expected time - "Expires in <>"
//
// If a patient has no consumptions that are currently allowed ("due later"), it will only display
// the time left until the first consumption is allowed.

function MedsDue({ dueNow, dueLater }) {
  if (dueNow == null && dueLater == null) {
    return null
  }

  return dueNow
    ? (
      <MedsDueNow
        totalCount={dueNow.totalCount}
        unconfirmedCount={dueNow.unconfirmedCount}
        minExpectedAt={dueNow.minExpectedAt}
        minExpiresAt={dueNow.minExpiresAt}
      />
    ) : (
      <MedsDueLater
        totalCount={dueLater.totalCount}
        unconfirmedCount={dueLater.unconfirmedCount}
        minAllowedAt={dueLater.minAllowedAt}
        minExpectedAt={dueLater.minExpectedAt}
        minExpiresAt={dueLater.minExpiresAt}
      />
    )
}

export default React.memo(MedsDue)
