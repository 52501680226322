import React, { useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Toast } from 'primereact/toast'
import moment from 'moment'
import { Button } from 'primereact/button'
import { useExportBowelMovementsReport } from '../../features/Patients/patientHooks'
import {
  usePatientBowelMovements,
} from './bowelMovementsHooks'
import { dateTimeTemplateFromMoment } from '../../services/utils'
import {
  ACTIVITY_TYPES, BRISTOL_STOOL_SCALE, SIZES, WITNESS_TYPE,
} from './config'
import MonthlyExportDialog from './MontlyExportDialog'

function BowelMovements() {
  const [isExportDialogVisible, setIsExportDialogVisible] = useState(false)
  const { patientId } = useParams()
  const statusMessage = useRef(null)

  const {
    data: bowelMovements,
    isLoading,
  } = usePatientBowelMovements(patientId, statusMessage)

  const bristolTypeTemplate = (rowData) => (
    <div className="flex flex-column gap-2">
      <span>
        Type
        {' '}
        {rowData.bristolType}
      </span>
      <span>{BRISTOL_STOOL_SCALE[rowData.bristolType].description}</span>
    </div>
  )

  return (
    <div className="col-12">
      <MonthlyExportDialog
        patientId={patientId}
        isVisible={isExportDialogVisible}
        setIsVisible={setIsExportDialogVisible}
        exportMutation={useExportBowelMovementsReport}
        dialogTitle="Bowel Movements"
      />
      <Toast ref={statusMessage} />
      <div className="flex flex-column align-items-end gap-2">
        <Button
          label="Export"
          className="p-button-sm p-button-outlined"
          onClick={() => setIsExportDialogVisible(true)}
        />
        <DataTable
          className="w-full"
          value={bowelMovements}
          loading={isLoading}
          emptyMessage="No bowel movements reported"
          dataKey="id"
          sortField="reportedAt"
          sortOrder={1}
        >
          <Column field="reportedAt" header="Reported At" body={(rowData) => dateTimeTemplateFromMoment(moment(rowData.reportedAt))} sortable />
          <Column field="reportedBy" header="Reported By" body={(rowData) => rowData.reportedBy.fullName} sortable />
          <Column field="activityType" header="Activity Type" sortable body={(rowData) => ACTIVITY_TYPES[rowData.activityType].name} />
          <Column field="bristolType" header="Bristol Type" sortable body={bristolTypeTemplate} />
          <Column field="size" header="Size" sortable body={(rowData) => SIZES[rowData.size].name} />
          <Column field="witness" header="Witness" sortable body={(rowData) => WITNESS_TYPE[rowData.witness].name} />
        </DataTable>
      </div>
    </div>
  )
}

export default BowelMovements
