import { useMemo, useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  get, isNil, omitBy, unionBy, isEmpty, map,
} from 'lodash'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { downloadFileFromResponse } from '@services/utils'
import ApiClient from '@services/ApiClient'
import { errorMessageFromError, displayErrorMessages } from '@services/errorHandler'

export function useCreateEkitMutation(statusMessage) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['ekit'],
    mutationFn: async (ekit) => ApiClient.post('/simpills', ekit),
    onSuccess: () => {
      queryClient.invalidateQueries('ekits')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useUpdateEkitMutation(ekitId, statusMessage) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['ekit'],
    mutationFn: async (ekit) => ApiClient.put(`/simpills/${ekitId}`, ekit),
    onSuccess: () => {
      queryClient.invalidateQueries('ekits')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useDosesWithInventory({ statusMessage }) {
  const [cachedDoses, setCachedDoses] = useState()
  const [shouldClearCache, setShouldClearCache] = useState(false)
  const [queryParams, setQueryParams] = useSearchParams()

  const searchParamsToObject = () => {
    const doseStatusFilters = queryParams.getAll('doseStatusFilters')
    return {
      offset: queryParams.get('offset') || 0,
      limit: queryParams.get('limit') || 100,
      sort: queryParams.get('sort') || 'fills_left ASC',
      medicineSearchTerm: queryParams.get('medicineSearchTerm') || '',
      doseStatusFilters: isEmpty(doseStatusFilters) ? ['pending', 'loaded', 'no_inventory'] : doseStatusFilters,
      siteIds: queryParams.getAll('siteIds').map((id) => parseInt(id, 10)),
    }
  }

  useEffect(() => { setQueryParams(searchParamsToObject()) }, [])

  const params = useMemo(() => searchParamsToObject(), [queryParams.toString()])

  const handleParamChange = ({ newParams, clearCache = false }) => {
    setShouldClearCache(clearCache)
    setQueryParams({ ...params, ...newParams })
  }

  const handleDoseCache = (response) => {
    const doses = get(response, 'doses', [])

    let newCache = []
    if (shouldClearCache) {
      newCache = doses
    } else {
      newCache = unionBy(cachedDoses, doses, 'id')
    }

    setCachedDoses(newCache)
    return { ...response, doses: newCache }
  }

  const query = useQuery({
    queryKey: ['ekitDoses', params],
    queryFn: async () => {
      const searchParams = omitBy(params, (param) => isNil(param) || param === '')
      const response = await ApiClient.get('/doses/with_inventory', { params: searchParams })
      return handleDoseCache(response)
    },
    enabled: !isEmpty(params),
    keepPreviousData: true,
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })

  return useMemo(() => ({
    ...query,
    doses: get(query, 'data.doses', []),
    totalRecords: get(query, 'data.meta.itemTotal', 0),
    queryParams: params,
    handleParamChange,
  }), [query])
}

export function useDoseFillsQuery(doseId, statusMessage) {
  const query = useQuery({
    queryKey: ['doseFills', doseId],
    queryFn: () => ApiClient.get(`/doses/${doseId}/dose_fills?status=pending_and_loaded`),
    enabled: !!doseId,
    onError: (error) => {
      statusMessage.current.clear()
      statusMessage.current.show([
        errorMessageFromError(error),
      ])
    },
  })

  return useMemo(() => ({
    ...query,
    data: map(get(query, 'data', []), (patient) => (patient)),
  }), [query])
}

export function useDoseUpsert(statusMessage) {
  return useMutation({
    mutationKey: ['useUpsertDose'],
    mutationFn: (dose) => ApiClient.post('/ekit_doses', dose),
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useUpdateDosePARlevel(statusMessage) {
  return useMutation({
    mutationKey: ['updateDoseParLevel'],
    mutationFn: ({ doseId, parLevel }) => ApiClient.put(`/ekit_doses/${doseId}`, { par_level: parLevel }),
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useUpdateDoseCsaSchedule(statusMessage) {
  return useMutation({
    mutationKey: ['updateDoseCsaSchedule'],
    mutationFn: ({ doseId, medicineId, csaSchedule }) => ApiClient.put(`/ekit_doses/${doseId}`, { medicine_attributes: { id: medicineId, local_csa_schedule: csaSchedule } }),
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useDeviceQuery(deviceId, statusMessage) {
  return useQuery({
    queryKey: ['device', deviceId],
    queryFn: () => ApiClient.get(`/simpills/${deviceId}`),
    enabled: false,
    onError: (error) => {
      if (!statusMessage) return
      statusMessage.current.clear()
      statusMessage.current.show([
        errorMessageFromError(error),
      ])
    },
  })
}

export function useDevicesQuery(statusMessage) {
  const query = useQuery({
    queryKey: ['devices'],
    queryFn: () => ApiClient.get('/simpills'),
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })

  return useMemo(() => ({
    ...query,
    data: get(query, 'data.simpills'),
  }), [query])
}

export function useOpenDrawer(deviceId, statusMessage) {
  const mutation = useMutation({
    mutationKey: ['drawer', deviceId],
    mutationFn: (drawer) => ApiClient.post(`/simpills/${deviceId}/open_drawer`, { drawer }),
    onError: () => statusMessage.current.show([{
      severity: 'error',
      sticky: true,
      summary: 'Unable to open E-KIT drawer at this time.',
    }]),
    onSuccess: (_, drawer) => statusMessage.current.show([{
      severity: 'success',
      summary: `Drawer #${drawer} opened successfully.`,
    }]),
  })

  return { openDrawer: mutation.mutateAsync, isLoading: mutation.isLoading }
}

export function useExportDoseConsumptions(statusMessage) {
  const mutation = useMutation({
    mutationKey: ['exportDoseConsumptions'],
    mutationFn: async (params) => {
      const { doseId, startFrom } = params
      const response = await ApiClient.get(`/consumptions/export?dose_id=${doseId}&period=${startFrom}`)
      downloadFileFromResponse(response)
    },
    onError: () => statusMessage.current.show([{
      severity: 'error',
      sticky: true,
      summary: 'Unable to export dose consumptions at this time.',
    }]),
  })
  return {
    exportDoseConsumptions: (doseId, startFrom) => {
      mutation.mutate({ doseId, startFrom })
    },
  }
}

export function useExportSiteConsumptions(statusMessage) {
  const mutation = useMutation({
    mutationKey: ['exportSiteConsumptions'],
    mutationFn: async (params) => {
      const response = await ApiClient.get('/consumptions/export', { params })
      downloadFileFromResponse(response)
    },
    onError: () => statusMessage.current.show([{
      severity: 'error',
      sticky: true,
      summary: 'Unable to export site consumptions at this time.',
    }]),
  })
  return {
    exportSiteConsumptions: async (siteIds, period, startDate, endDate) => {
      await mutation.mutateAsync({
        siteIds, period, startDate, endDate,
      })
    },
    isLoading: mutation.isLoading,
  }
}

export function useExportSiteDoses(statusMessage, organizationId) {
  const mutation = useMutation({
    mutationKey: ['exportSiteDoses'],
    mutationFn: (params) => {
      const { siteId } = params
      return ApiClient.post('/dose_fills/export', { site_id: siteId, organization_id: organizationId })
    },
    onError: () => statusMessage.current.show([{
      severity: 'error',
      sticky: true,
      summary: 'Unable to export site doses at this time.',
    }]),
    onSuccess: (data) => {
      statusMessage.current.show([{
        severity: 'success',
        sticky: false,
        summary: `Site doses exported successfully. Check your email: ${data.emailSentTo}`,
      }])
    },
  })
  return {
    exportSiteDoses: async (siteId) => {
      await mutation.mutateAsync({ siteId })
    },
    isLoading: mutation.isLoading,
  }
}
