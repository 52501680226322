import React from 'react'
import { Card } from 'primereact/card'
import { Skeleton } from 'primereact/skeleton'

function DashboardCard({
  title, stat, description, loading, icon, color, isActive, onClick,
}) {
  const highlightColor = (opacity) => `rgb(59, 130, 246, ${opacity})`
  const style = isActive ? {
    border: '2px solid rgb(59, 130, 246, 0.5)',
    boxShadow: `0 2px 1px -1px ${highlightColor(0.2)}, 0 1px 1px 0 ${highlightColor(0.14)}, 0 1px 3px 0 ${highlightColor(0.12)}`,
  } : {}

  return (
    <div className="col-3 cursor-pointer" onClick={onClick}>
      <Card className="h-full" style={style}>
        <div className="flex justify-content-between mb-2">
          <div className="w-full">
            <span className="flex flex-row justify-content-between align-items-center w-full text-500 font-medium mb-2">
              {title}
              <div className={`flex align-items-center justify-content-center bg-${color}-100 border-round`} style={{ width: '2rem', height: '2rem' }}>
                <i className={`pi ${icon} text-${color}-500 text-xl`} />
              </div>
            </span>
            <div className="text-900 font-medium text-base">
              {loading && <Skeleton className="inline-flex" width="6rem" height="1rem" />}
              {!loading && stat}
            </div>
          </div>
        </div>
        <span className="text-500">
          {loading && <Skeleton className="inline-flex" width="10rem" height="1rem" />}
          {!loading && description}
        </span>
      </Card>
    </div>
  )
}

export default DashboardCard
