import React from 'react'
import { Button } from 'primereact/button'
import { InputTextarea } from 'primereact/inputtextarea'

function Purpose({ purpose, setPurpose, handleConfirm }) {
  return (
    <div className="grid flex flex-grow-1 justify-content-center align-items-center fadein">
      <div className="col-9 flex flex-column gap-2 text-center">
        <label className="text-lg font-normal mb-1" htmlFor="medicine-purpose">
          What is the purpose of this medication?
        </label>
        <InputTextarea
          id="medicine-purpose"
          rows={5}
          cols={30}
          data-testid="medicine-purpose"
          autoResize
          value={purpose}
          onChange={(event) => setPurpose(event.target.value)}
        />
        <Button
          onClick={handleConfirm}
          label="Save and Continue"
          className="p-button-text mt-2"
        />
      </div>
    </div>
  )
}

export default Purpose
