import React from 'react'
import { useParams } from 'react-router-dom'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Card } from 'primereact/card'
import { Button } from 'primereact/button'
import { map } from 'lodash'
import { useEventTimes } from '../../features/Schedules/eventTimesHooks'

const timeTemplate = (datetime) => (
  datetime.toLocaleTimeString('en-us', { hour: '2-digit', minute: '2-digit' })
)

function List({
  handleCreate, handleEdit, orgId,
}) {
  const { organizationId, siteId, patientId } = useParams()
  const query = useEventTimes({ organizationId: organizationId || orgId, siteId, patientId })

  const editButton = (eventTime) => (
    <Button
      icon="pi pi-pencil"
      className="p-button-outlined p-button-text"
      type="button"
      label="Edit"
      size="small"
      onClick={() => handleEdit(eventTime)}
    />
  )

  const headerStyle = { fontSize: '14px', fontWeight: 'bold', textAlign: 'left' }
  const bodyStyle = { fontSize: '14px', textAlign: 'left', padding: '1rem' }

  return (
    <Card>
      <div className="flex flex-row justify-content-end mb-2">
        <Button
          label="Create"
          className="p-button-sm"
          type="button"
          icon="pi pi-plus-circle"
          iconPos="right"
          onClick={() => handleCreate()}
        />
      </div>
      <DataTable
        dataKey="id"
        className="fadein"
        loading={query.isLoading}
        sortField="startTime"
        sortOrder={1}
        sortMode="single"
        showGridlines
        value={map(query.data, (eventTime) => eventTime)}
      >
        <Column
          header="Name"
          field="name"
          bodyStyle={bodyStyle}
          headerStyle={headerStyle}
        />
        <Column
          header="Start Time"
          field="startTime"
          headerStyle={headerStyle}
          bodyStyle={bodyStyle}
          body={({ startTime }) => timeTemplate(startTime)}
        />
        <Column
          header="End Time"
          field="endTime"
          headerStyle={headerStyle}
          bodyStyle={bodyStyle}
          body={({ endTime }) => timeTemplate(endTime)}
        />
        <Column
          header="Action"
          headerStyle={headerStyle}
          bodyStyle={bodyStyle}
          body={(eventTime) => editButton(eventTime)}
        />
      </DataTable>
    </Card>
  )
}

export default List
