import React from 'react'
import { get, update } from 'lodash'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import conditionTypes from '@components/conditions/config'

function ConditionCheckInput({
  condition,
  conditionCheck,
  setConditionCheck,
}) {
  const conditionType = conditionTypes.find((ct) => ct.code === condition?.conditionType)

  if (!conditionType) {
    return null
  }

  const handleChange = (path, value) => {
    const newConditionCheck = { ...conditionCheck }
    update(newConditionCheck, path, () => value)
    setConditionCheck(newConditionCheck)
  }

  const unitPath = `${conditionType.measurementKey}.unit`

  return (
    <div className="p-inputgroup flex-shrink-1">
      <span className="p-inputgroup-addon text-sm" style={{ minWidth: '125px' }}>
        {conditionType.displayText}
      </span>
      <InputText
        keyfilter="num"
        onChange={(e) => handleChange(conditionType.values[0], e.target.value)}
        value={get(conditionCheck, conditionType.values[0], '')}
        className="text-center text-sm"
      />
      {
        conditionType.values.length > 1 && (
          <>
            <span className="p-inputgroup-addon text-sm" style={{ minWidth: '15px' }}>
              /
            </span>
            <InputText
              keyfilter="num"
              onChange={(e) => handleChange(conditionType.values[1], e.target.value)}
              value={get(conditionCheck, conditionType.values[1], '')}
              className="text-center text-sm"
            />
          </>
        )
      }
      {
        conditionType.units.length === 1 && (
          <span className="p-inputgroup-addon text-sm" style={{ minWidth: '125px' }}>
            {conditionType.units[0]}
          </span>
        )
      }
      {
        conditionType.units.length > 1 && (
          <Dropdown
            id={`condition-check-unit-dropdown-${conditionType.measurementType}`}
            value={get(conditionCheck, unitPath, conditionType.units[0])}
            options={conditionType.units.map(
              (unit, index) => ({ label: conditionType.unitLabels[index], value: unit }),
            )}
            optionLabel="label"
            optionValue="value"
            onChange={(e) => handleChange(unitPath, e.value)}
            className="text-right text-sm bg-gray-100"
            style={{ width: '125px', maxWidth: '125px', flexShrink: 0 }}
          />
        )
      }
    </div>
  )
}

export default ConditionCheckInput
