import { useMutation } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { errorMessageFromError } from '@services/errorHandler'
import { useNavigate } from 'react-router-dom'

function useCreateOrganizationMutation(statusMessage) {
  const navigate = useNavigate()
  return useMutation({
    mutationKey: ['organizationCreate'],
    mutationFn: (organization) => ApiClient.post('/organizations', organization),
    onSuccess: (data) => {
      statusMessage.current.show([{ severity: 'success', summary: 'Organization was successfully created.' }])
      navigate(`/admin/organizations/${data.id}/edit`, { state: { organizationId: data.id } })
    },
    onError: async (error) => {
      if (!statusMessage) return
      await statusMessage.current.clear()
      statusMessage.current.show([
        errorMessageFromError(error),
      ])
    },
  })
}

export default useCreateOrganizationMutation
