import React from 'react'
import {
  flatMap, groupBy, map, sortBy, startCase,
} from 'lodash'
import { RRule } from 'rrule'
import { EditableScheduleTime } from '@components/pharmacyOrders/Editors/ScheduleComponents'
import { getDisplayTimeFromMinutes } from '@services/utils/moment'

const getFlexibilityText = (schedule) => {
  const start = (schedule?.time ?? 0) - (schedule?.flexibility?.maxStart ?? 0)
  const end = (schedule?.time ?? 0) + (schedule?.flexibility?.maxEnd ?? 0)
  return `(${getDisplayTimeFromMinutes(start)} - ${getDisplayTimeFromMinutes(end)})`
}

function ScheduleEditor({
  schedules,
  handleAddSchedule,
  handleUpdateSchedule,
  handleDeleteSchedule,
  editable = true,
}) {
  const sortedSchedules = sortBy(schedules, (schedule) => schedule?.time)
  const schedulesByRruleText = groupBy(
    sortedSchedules,
    (schedule) => startCase(RRule.fromString(schedule.rrule).toText()),
  )

  return (
    <ul className="list-none p-0 m-0 gap-2 flex flex-column">
      {flatMap(schedulesByRruleText, (scheduleGroup, rruleText) => (
        map(scheduleGroup, (schedule, index) => (
          <li key={rruleText} className="flex flex-row gap-1">
            {rruleText}
            <span className="mr-1">at</span>
            <div key={index} className="flex flex-row align-items-baseline gap-2">
              {editable && (
                <EditableScheduleTime
                  schedule={schedule}
                  handleCreateSchedule={handleAddSchedule}
                  handleUpdateSchedule={handleUpdateSchedule}
                  handleDestroySchedule={handleDeleteSchedule}
                />
              )}
              {!editable && <span>{getDisplayTimeFromMinutes(schedule?.time)}</span>}
              <small className="font-italic opacity-70">{getFlexibilityText(schedule)}</small>
            </div>
          </li>
        ))
      ))}
    </ul>
  )
}

export default ScheduleEditor
