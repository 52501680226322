import React from 'react'
import { MultiSelect } from 'primereact/multiselect'
import { isEmpty } from 'lodash'

function patientTemplate(option) {
  if (!option) {
    return null
  }
  return (
    <div className="p-multiselect-patient-option">
      <div className="p-multiselect-patient-option-label">
        {option.firstName}
        {' '}
        {option.lastName}
        {' '}
        -
        {' '}
        {option.phone}
      </div>
    </div>
  )
}

function PatientsSelectForm({
  patients, selectedPatientIds, setSelectedPatientIds, isPatientsLoading, siteAlreadySelected,
}) {
  return (
    <div className="field">
      <label className="block" htmlFor="site">Patients</label>
      <div className="flex flex-column">
        <MultiSelect
          id="patients"
          data-testid="patients-select"
          options={patients}
          value={selectedPatientIds}
          optionValue="id"
          onChange={(e) => setSelectedPatientIds(e.value)}
          className="w-full"
          itemTemplate={patientTemplate}
          selectedItemTemplate={(option) => {
            if (!option) return
            const patient = patients.find((p) => p.id === option)
            return patientTemplate(patient)
          }}
          disabled={isPatientsLoading || (!siteAlreadySelected && isEmpty(selectedPatientIds))}
          placeholder={siteAlreadySelected ? 'Select patients' : 'Select a site first'}
          filter
          filterBy="firstName,lastName,phone"
        />
      </div>
    </div>
  )
}

export default PatientsSelectForm
