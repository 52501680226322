import React from 'react'
import { Dropdown } from 'primereact/dropdown'
import { sortBy } from 'lodash'
import { sigHeader } from '@services/utils/sig'

function DoseSigSelector({
  doseSigs = [], doseSig, setDoseSig, editable = true,
}) {
  const sigOptions = sortBy(doseSigs, (sig) => sig.sigNumber).map((sig) => ({
    ...sig,
    displayText: sigHeader(sig.sigNumber),
  }))

  const allSigsOption = { displayText: 'All Sigs' }

  return (
    <Dropdown
      id="dose-condition-dose-sig-dropdown"
      placeholder="Select Sig"
      disabled={!editable}
      inputId="dose-condition-dose-sig"
      value={doseSig || allSigsOption}
      data-testid="dose-condition-dose-sig"
      options={[allSigsOption, ...sigOptions]}
      optionLabel="displayText"
      onChange={(e) => setDoseSig(e.value)}
      aria-label="Sig"
      dropdownarialabel="Select Sig"
    />
  )
}

export default DoseSigSelector
