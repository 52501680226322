import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

const useDeleteSite = (statusMessage) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (siteId) => ApiClient.delete(`/sites/${siteId}`),
    onSuccess: () => {
      queryClient.invalidateQueries('sites')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export default useDeleteSite
