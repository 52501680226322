import React, { useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { Divider } from 'primereact/divider'
import PharmacistSitesHeader from './PharmacistSitesHeader'

// Function to keep row groups expanded by default
function expandRows(sites) {
  if (!sites.length) return
  const orgMap = new Map()
  sites.forEach((site) => {
    const orgName = site.organization.name
    if (!orgMap.has(orgName)) {
      orgMap.set(orgName, site)
    }
  })
  return Array.from(orgMap.values())
}

function PharmacistSites({
  userSites,
  availableSites,
  onUserSitesChange,
  isLoading,
  editEnabled = true,
  maxDataViewHeight,
}) {
  const [expandedRows, setExpandedRows] = useState([])
  const [filteredAvailableSites, setFilteredAvailableSites] = useState([])
  const [filteredAssignedSites, setFilteredAssignedSites] = useState([])
  const [selectedSites, setSelectedSites] = useState([])
  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    setFilteredAvailableSites(availableSites)
    setFilteredAssignedSites(userSites)
    setSelectedSites(userSites)
    setExpandedRows(expandRows(userSites))
  }, [userSites, availableSites])

  const handleSubmit = async () => {
    onUserSitesChange(selectedSites)
    setIsEditing(false)
  }

  const headerTemplate = (data) => (
    <span className="vertical-align-middle ml-2 font-bold text-lg">{data.organization.name}</span>
  )

  // Function to calculate number of sites for selected (by name) organization
  const calculateOrgSites = (sites, orgName) => {
    let total = 0

    sites?.forEach((site) => {
      if (site.organization.name === orgName) {
        total += 1
      }
    })

    return total
  }

  const footerTemplate = (data) => {
    const userOrgSites = calculateOrgSites(userSites, data.organization.name)
    let sitesText
    if (userOrgSites === 1) {
      sitesText = 'Assigned to 1 site'
    } else {
      sitesText = `Assigned to ${userOrgSites} sites`
    }

    return (
      <td colSpan={3}>
        <div className="flex justify-content-end font-bold w-full">
          {sitesText}
        </div>
      </td>
    )
  }

  return (
    <Card className="h-full">
      <div className="flex flex-column gap-3 text-base text-900">
        <PharmacistSitesHeader
          userSites={userSites}
          isEditing={isEditing}
          setSelectedSites={setSelectedSites}
          setIsEditing={setIsEditing}
          editEnabled={editEnabled}
        />
        <Divider className="my-0" />
        <DataTable
          value={isEditing ? filteredAvailableSites : filteredAssignedSites}
          rowGroupMode="subheader"
          groupRowsBy="organization.name"
          sortMode="single"
          sortField="organization.name"
          sortOrder={1}
          expandableRowGroups
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowGroupHeaderTemplate={headerTemplate}
          rowGroupFooterTemplate={footerTemplate}
          selection={isEditing ? selectedSites : null}
          onSelectionChange={
            isEditing ? (e) => setSelectedSites(e.value) : null
          }
          selectionMode={isEditing ? 'multiple' : null}
          dataKey="id"
          scrollable
          scrollHeight={maxDataViewHeight - 100}
        >
          {isEditing && (
            <Column selectionMode="multiple" style={{ maxWidth: '10%' }} field="id" />
          )}
          <Column field="name" header="Name" style={{ maxWidth: '25%' }} />
          <Column field="address" header="Address" style={{ minWidth: '0px' }} />
        </DataTable>
        {isEditing
          && (
          <div className="flex flex-row">
            <Button
              label="Save Assignments"
              onClick={handleSubmit}
              loading={isLoading}
              className="p-button-sm p-button-success"
            />
          </div>
          )}
      </div>
    </Card>
  )
}

export default PharmacistSites
