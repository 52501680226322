import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

const useUpdateTreatment = ({ statusMessage }) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (treatment) => {
      const params = {
        id: treatment.id,
        startAt: treatment.startAt,
        endAt: treatment.endAt,
        rawAdministrationTimes: treatment.rawAdministrationTimes,
        note: treatment.note,
        treatmentType: treatment.treatmentType,
        category: treatment.category,
        showInMedpass: treatment.showInMedpass,
      }

      return ApiClient.patch(`/treatments/${treatment.id}`, params)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries('treatment')
      await queryClient.invalidateQueries('treatments')
      statusMessage.current.clear()
      statusMessage.current.show([
        { severity: 'success', summary: 'Treatment Updated!' },
      ])
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export default useUpdateTreatment
