import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Toast } from 'primereact/toast'
import { useCurrentOrganization } from '@components/App'
import CaregiverCreateForm from '@components/caregivers/CaregiverForm/CaregiverCreateForm'
import {
  useCreateCaregiverMutation,
} from './caregiversHooks'

function CreateCaregiver({
  useCaregiverHeader,
}) {
  const statusMessage = useRef(null)
  const navigate = useNavigate()
  const { setHeader } = useCaregiverHeader()
  const organization = useCurrentOrganization()

  const {
    mutateAsync: createCaregiver,
    isLoading: createIsLoading,
  } = useCreateCaregiverMutation(statusMessage)

  const pageTitle = {
    label: (
      <span>
        <i className="text-xl pi pi-users mr-2" />
        {organization.caregiverLabelSingular}
      </span>
    ),
    linkTo: '/admin/caregivers',
  }

  const breadcrumbs = [
    {
      label: 'Create',
      template: <span>Create</span>,
    },
  ]

  useEffect(() => {
    setHeader({ title: pageTitle, breadcrumbs, primaryAction: null })
  }, [])

  return (
    <div id="create-caregiver" className="flex flex-column">
      <div className="flex flex-row flex-wrap">
        <Toast ref={statusMessage} />
        <div className="col-6">
          <CaregiverCreateForm
            onSave={(userParams) => {
              createCaregiver(userParams, {
                onSuccess: (data) => {
                  navigate(`/admin/caregivers/${data.id}`)
                },
              })
            }}
            saveLoading={createIsLoading}
            statusMessageRef={statusMessage}
          />
        </div>
      </div>
    </div>
  )
}

export default CreateCaregiver
