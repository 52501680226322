import { Card } from 'primereact/card'
import React from 'react'

export default function CardWithTitles({
  children, title, subtitle, titleIcon, titleIconPosition = 'left',
}) {
  const renderTitleIcon = () => {
    if (!titleIcon) return null
    return (
      <span className="text-lg">
        {titleIcon}
      </span>
    )
  }

  const cardTitle = (title && (
    <div className="flex gap-2 mb-3 align-items-center">
      {titleIconPosition === 'left' && renderTitleIcon()}
      <span className="text-lg font-semibold">{title}</span>
      {titleIconPosition === 'right' && renderTitleIcon()}
    </div>
  ))

  const cardSubtitle = (subtitle && (
    <div className="flex items-center">
      <h4 className="text-base font-normal mb-4">{subtitle}</h4>
    </div>
  ))

  return (
    <Card className="w-full" title={cardTitle} subTitle={cardSubtitle}>
      {children}
    </Card>
  )
}
