export function createActionHandlers(dispatch, state) {
  const { startAt, endAt } = state

  const handlerForStateChange = (type) => (newState) => dispatch({ type, ...newState })

  const handlerForScheduleChange = (type) => (id) => (schedule) => dispatch({
    type, schedule, id, startAt, endAt,
  })

  return {
    initializeSchedules: (id) => (schedules) => dispatch({
      id, schedules, startAt, endAt, type: 'initialize_schedules',
    }),
    handleUpdateMedicine: handlerForStateChange('updateMedicine'),
    handleSetPrn: handlerForStateChange('setPrn'),
    handleUpdateStartAt: handlerForStateChange('updateStartAt'),
    handleUpdateEndAt: handlerForStateChange('updateEndAt'),
    handleUpdateFillsLeft: handlerForStateChange('updateFillsLeft'),
    handleRemoveDoseFill: handlerForStateChange('removeDoseFill'),
    handleUpdatePurpose: handlerForStateChange('updatePurpose'),
    handleUpdatePurposeText: handlerForStateChange('updatePurposeText'),
    handleUpdateNote: handlerForStateChange('updateNote'),
    handleUpdatePharmacyInstructions: handlerForStateChange('updatePharmacyInstructions'),
    handleUpdateAdministrationAmount: handlerForStateChange('updateAdministrationAmount'),
    handleUpdateAdministrationRoute: handlerForStateChange('updateAdministrationRoute'),
    handleUpdateRefillsRemaining: handlerForStateChange('updateRefillsRemaining'),
    handleAddSchedule: handlerForScheduleChange('add_schedule'),
    handleUpdateSchedule: handlerForScheduleChange('update_schedule'),
    handleDeleteSchedule: handlerForScheduleChange('delete_schedule'),
    handleResetState: handlerForStateChange('resetState'),
    handleUpdateMatchedDose: handlerForStateChange('updateMatchedDose'),
  }
}

export default createActionHandlers
