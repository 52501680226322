import React, { useState, useEffect } from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { InputNumber } from 'primereact/inputnumber'
import pluralize from 'pluralize'

function FillQuantityEditor({ medicine, fillQuantity, setFillQuantity }) {
  const [dialogVisible, setDialogVisible] = useState(false)
  const [amount, setAmount] = useState()

  const handleConfirmation = () => {
    setFillQuantity({ quantity: amount })
    setDialogVisible(false)
  }

  useEffect(() => {
    if (fillQuantity) {
      setAmount(fillQuantity)
    }
  }, [fillQuantity])

  return (
    <>
      <Button className="p-button-text p-button-sm p-button-warning" label="Edit" icon="pi pi-file-edit" onClick={() => setDialogVisible(true)} />
      <Dialog header="Fill Quantity" visible={dialogVisible} style={{ width: '75vw' }} onHide={() => setDialogVisible(false)}>
        <div className="flex flex-column gap-2">
          <div className="flex flex-row gap-2 align-items-center">
            <InputNumber
              className="col-2"
              value={amount}
              onChange={({ value }) => setAmount(value)}
              min={0}
              showButtons
              autoFocus
            />
            <span>{`${amount} ${pluralize(medicine?.form || 'Medication', amount)}` }</span>
          </div>
          <Button
            label="Confirm"
            className="p-button-sm p-button-success mt-4"
            onClick={handleConfirmation}
          />
        </div>
      </Dialog>
    </>
  )
}

export default FillQuantityEditor
