import { useMemo } from 'react'
import { map } from 'lodash'
import { useMutation, useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { errorMessageFromError, formErrorsFromError } from '@services/errorHandler'

function useUserDevices(userId, statusMessage) {
  const query = useQuery({
    queryKey: ['userDevices', userId],
    queryFn: () => ApiClient.get('/user_devices', { params: { userId } }),
    onError: (error) => {
      statusMessage.current.show([
        errorMessageFromError(error, 'Unable to get user devices at this time.'),
        ...formErrorsFromError(error),
      ])
    },
  })

  return useMemo(() => (
    {
      ...query,
      data: map(query.data, (data) => data),
    }
  ), [query.data])
}

function useTestNotificationMutation(statusMessage, userId) {
  return useMutation({
    mutationFn: (params) => ApiClient.post('/user_devices/send_test_notification', { userId, ...params }),
    onError: (error) => {
      statusMessage.current.show([
        errorMessageFromError(error, 'Unable to send test notification at this time.'),
        ...formErrorsFromError(error),
      ])
    },
    onSuccess: () => {
      statusMessage.current.show([{ severity: 'success', summary: 'Test notification sent.' }])
    },
  })
}

export {
  useUserDevices,
  useTestNotificationMutation,
}
