import React, { useState } from 'react'
import PinInput from '@components/display/PinInput'
import { Button } from 'primereact/button'
import { useCurrentOrganization } from '@components/App'
import moment from 'moment'

function SharedDevice({ sharedDevice, setSharedDevice, loading }) {
  const organization = useCurrentOrganization()
  const [isEditing, setIsEditing] = useState(false)
  const { pin, pinExpiresAt } = sharedDevice
  const setByCaregiver = moment(pinExpiresAt).isAfter(moment())
  if (setByCaregiver && !isEditing) {
    return (
      <>
        <span>
          Pin is already set by the
          {' '}
          {organization.caregiverLabelSingular}
        </span>
        <div className="flex flex-row align-items-center m-2">
          ****
          <Button
            onClick={() => {
              setSharedDevice({ ...sharedDevice, pin: '' })
              setIsEditing(true)
            }}
            className="p-button-sm p-button-text p-button-secondary p-0 ml-1"
            icon="pi pi-pencil"
          />
        </div>
      </>
    )
  }
  return (
    <div className="flex flex-column">
      <PinInput
        pin={pin}
        onPinChanged={(value) => {
          setSharedDevice({ ...sharedDevice, pin: value })
        }}
        disabled={loading}
      />
    </div>
  )
}

export default SharedDevice
