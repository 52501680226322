import React, { useRef, useState } from 'react'
import { Toast } from 'primereact/toast'
import { TabView, TabPanel } from 'primereact/tabview'
import { useCurrentOrganization } from '@components/App'
import { Dialog } from 'primereact/dialog'
import { useOnboardPendingUser, usePatientsAvailableToOnboard } from '@hooks/pendingUsers'
import OnboardNewUser from './OnboardNewUser'
import OnboardExistingUser from './OnboardExistingUser'
import OnboardExistingUserConfirm from './OnboardExistingUserConfirm'

function OnboardDialog({
  visible,
  setVisible,
  pendingUser,
}) {
  const {
    id: orgId,
    patientLabelSingular: patientRoleLabel,
    patientBirthSexOptions,
  } = useCurrentOrganization()
  const statusMessage = useRef(null)
  const [activeIndex, setActiveIndex] = useState(0)
  const [showUsersLinkConfirm, setShowUsersLinkConfirm] = useState()
  const [patientToLink, setPatientToLink] = useState(null)
  const [pendingUserToLink, setPendingUserToLink] = useState(null)

  const {
    data: patients,
    isLoading: isPatientsLoading,
  } = usePatientsAvailableToOnboard(orgId, pendingUser?.id, statusMessage)

  const {
    mutateAsync: onboardPendingUser, isLoading: onboardingInProgress,
  } = useOnboardPendingUser(statusMessage)

  const handleOnboardPendingUser = async (profile) => {
    const patient = await onboardPendingUser({
      ...profile,
      id: pendingUser.id,
    })

    window.location.href = `/admin/patients/${patient.id}/orders/needs-review`
  }

  const handleOnboardToExistingPatient = async (patientId) => {
    await onboardPendingUser({
      id: pendingUser.id,
      patientId,
    }, {
      onSuccess: () => {
        window.location.href = `/admin/patients/${patientId}/orders/needs-review`
      },
    })
  }

  const onCloseDialog = () => {
    if (!visible) return

    setVisible(false)
    setShowUsersLinkConfirm(false)
  }

  return (
    <Dialog
      header="Onboard User"
      visible={visible}
      style={{ width: '75vw', maxHeight: '75vh' }}
      onHide={onCloseDialog}
      className="overflow-hidden"
    >
      <Toast ref={statusMessage} />
      <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
        <TabPanel header={`Create ${patientRoleLabel}`}>
          <OnboardNewUser
            statusMessage={statusMessage}
            handleOnboardPendingUser={handleOnboardPendingUser}
            onboardingInProgress={onboardingInProgress}
            viewMatchCallBack={() => setActiveIndex(1)}
            pendingUser={pendingUser}
            patients={patients}
            isPatientsLoading={isPatientsLoading}
          />
        </TabPanel>
        <TabPanel header={`Use Existing ${patientRoleLabel}`}>
          <OnboardExistingUser
            pendingUser={pendingUser}
            onboardingInProgress={onboardingInProgress}
            patients={patients}
            isPatientsLoading={isPatientsLoading}
            patientRoleLabel={patientRoleLabel}
            patientBirthSexOptions={patientBirthSexOptions}
            setShowUsersLinkConfirm={setShowUsersLinkConfirm}
            setPendingUserToLink={setPendingUserToLink}
            setPatientToLink={setPatientToLink}
            setTabIndex={setActiveIndex}
          />
        </TabPanel>
        {
          showUsersLinkConfirm && (
            <TabPanel header="Confirm Link">
              <OnboardExistingUserConfirm
                pendingUserToLink={pendingUserToLink}
                patientToLink={patientToLink}
                patientBirthSexOptions={patientBirthSexOptions}
                handleLinkUsers={handleOnboardToExistingPatient}
                onboardingInProgress={onboardingInProgress}
                onCloseDialog={onCloseDialog}
              />
            </TabPanel>
          )
        }
      </TabView>
    </Dialog>
  )
}

export default OnboardDialog
