import React, { useState, useRef } from 'react'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { Card } from 'primereact/card'
import { Toast } from 'primereact/toast'
import { useParams } from 'react-router-dom'
import { useSaveSlotConfigQuery } from '../../features/MedBoxes/medboxesHooks'

function DrawerDetailRow({ children, label, color }) {
  return (
    <div className="flex gap-2 align-items-center">
      <div className="text-semibold">
        {label}
        :
      </div>
      <div
        style={{
          color: color || 'inherit',
          backgroundColor: color || '',
        }}
      >
        {children}
      </div>
    </div>
  )
}

function DrawersSetup({ drawers, EKIT_DEFAULT_NUM_SLOTS = 12 }) {
  const formattedDrawers = drawers.map((drawer) => ({
    ...drawer,
    numberOfSlots: drawer.drawerSlots.length,
  }))

  const [drawerData, setDrawerData] = useState(formattedDrawers)
  const statusMessage = useRef(null)
  const { medBoxId } = useParams()
  const {
    mutateAsync: updateMedBox,
    isLoading: updateIsLoading,
  } = useSaveSlotConfigQuery(medBoxId, statusMessage)

  const handleUpdateMedBox = async () => {
    const filteredDrawers = drawerData.filter(
      (drawer) => drawer.numberOfSlots !== drawer.drawerSlots.length,
    )
    const data = await updateMedBox(filteredDrawers)
    setDrawerData(data?.simpillDrawers)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    handleUpdateMedBox()
  }

  const handleSlotChange = (drawerId, event) => {
    const updatedDrawers = drawerData.map((drawer) => {
      if (drawer.id === drawerId) {
        return { ...drawer, numberOfSlots: Number(event.target.value) }
      }
      return drawer
    })
    setDrawerData(updatedDrawers)
  }

  const slotOptions = [{ label: '0', value: 0 }, { label: EKIT_DEFAULT_NUM_SLOTS.toString(), value: EKIT_DEFAULT_NUM_SLOTS }]

  return (
    <div>
      <Toast ref={statusMessage} />
      <h4 className="mb-3 text-base">Drawers</h4>
      <form onSubmit={handleSubmit}>
        <div className="flex gap-4 flex-wrap">
          <div className="flex gap-4 flex-wrap w-full">
            {drawerData.map((drawer) => (
              <Card
                key={drawer.id}
                className="shadow-0 w-fit text-base p-1"
                id={`drawer-row-${drawer.position}`}
              >
                <span>{drawer.label}</span>
                <div className="flex flex-column align-items-start gap-2 mt-1">
                  <DrawerDetailRow label="#">
                    {drawer.position}
                  </DrawerDetailRow>
                  <DrawerDetailRow label="Color" color={drawer.color}>
                    {drawer.color}
                  </DrawerDetailRow>
                  <DrawerDetailRow label="Number Of Slots">
                    <Dropdown
                      id="drawer-set-up--dropdown"
                      value={drawer.numberOfSlots || drawer.drawerSlots.length}
                      options={slotOptions}
                      onChange={(e) => handleSlotChange(drawer.id, e)}
                      className="w-fit"
                      defaultValue={drawer.drawerSlots.length}
                      disabled={drawer.drawerSlots.length > 0}
                    />
                  </DrawerDetailRow>
                </div>
              </Card>
            ))}
          </div>
          <div className="flex-grow-1">
            <Button type="submit" loading={updateIsLoading} disabled={drawerData.every((drawer) => drawer.drawerSlots.length > 0)} label="Save" />
          </div>
        </div>
      </form>
    </div>
  )
}

export default DrawersSetup
