import React from 'react'
import SuperAdminCard from '@components/display/SuperAdminCard'
import PrescriptionsTable from '@components/prescriptions/PrescriptionsTable'

function Prescriptions({ doseId }) {
  return (
    <div className="col-12">
      <SuperAdminCard title="Prescriptions">
        <PrescriptionsTable model="Dose" id={doseId} allowUnlink />
      </SuperAdminCard>
    </div>
  )
}

export default Prescriptions
