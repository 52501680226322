import React, { useState } from 'react'
import { withApp } from '@components/App'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { useExportUserConsumption } from './exportConsumptionsHooks'

function ExportConsumptionsButton({ userId, role }) {
  const mutation = useExportUserConsumption()
  const [exportRange, setExportRange] = useState('7d')
  const [dialogVisible, setDialogVisible] = useState(false)

  const handleExportConsumptions = async () => {
    await mutation.mutateAsync({ userId, role, startFrom: exportRange })
    setDialogVisible(false)
  }

  const exportRangeOptions = [
    { label: 'Last 7 days', value: '7d' },
    { label: 'Last 30 days', value: '30d' },
    { label: 'Last 90 days', value: '90d' },
    { label: 'All time', value: 'all' },
  ]

  return (
    <>
      <Button
        className="p-button-sm p-button-text ml-auto text-green-600"
        icon="pi pi-file-excel"
        label="Export Consumption History"
        onClick={() => setDialogVisible(true)}
      />
      <Dialog
        header="Export consumption history"
        visible={dialogVisible}
        onHide={() => setDialogVisible(false)}
      >
        <div className="flex flex-column text-base font-normal mt-2">
          <div className="flex flex-row gap-2 align-items-center mb-2">
            <span className="font-normal">Export from:</span>
            <Dropdown
              value={exportRange}
              options={exportRangeOptions}
              onChange={(e) => setExportRange(e.value)}
              placeholder="Select a Range"
              className="flex-grow-1"
            />
          </div>
          <Button
            className="p-button-sm p-button-outlined"
            label="Export"
            onClick={() => handleExportConsumptions()}
            loading={mutation.isLoading}
          />
        </div>
      </Dialog>
    </>
  )
}

export { ExportConsumptionsButton }

export default withApp(ExportConsumptionsButton, { disableActiveAdminStyles: false })
