import { Dialog } from 'primereact/dialog'
import React from 'react'
import { useCurrentUser } from '@components/App'

function SuperAdminDialog({
  children,
  header,
  hide,
  isVisible,
}) {
  const { role } = useCurrentUser()

  if (role !== 'superadmin') {
    return null
  }

  return (
    <Dialog
      draggable={false}
      header={header}
      onHide={() => hide()}
      style={{ width: '50vw' }}
      visible={isVisible}
    >
      {children}
    </Dialog>
  )
}

export default SuperAdminDialog
