import ApiClient from '@services/ApiClient'
import { useQuery } from '@tanstack/react-query'

const useLeaveOfAbsencesQuery = (patientId) => useQuery({
  queryKey: ['leave-of-absences', patientId],
  queryFn: () => ApiClient.get('/leave_of_absences', { params: { patient_id: patientId } }),
  enabled: !!patientId,
})

export default useLeaveOfAbsencesQuery
