import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'

const usePrescription = ({ prescriptionId }) => {
  const query = useQuery({
    queryKey: ['prescription', prescriptionId],
    queryFn: () => ApiClient.get(`/prescriptions/${prescriptionId}`),
    enabled: !!prescriptionId,
  })

  return useMemo(() => ({
    ...query,
    data: query.data || {},
  }), [query])
}

export default usePrescription
