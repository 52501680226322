import React, { useState } from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import ActionsTable from '@components/conditions/ActionsTable'
import ConditionNote from '@components/conditions/ConditionNote'
import ConditionScheduleSelector from '@components/conditions/ConditionScheduleSelector'
import ConditionType from '@components/conditions/ConditionType'
import { useCreateTaskCondition } from '@hooks/taskConditions'

const dialogHeaderTemplate = () => (
  <div className="flex flex-row align-items-baseline w-full justify-content-center">
    <h3 className="font-semibold text-lg flex flex-column gap-2">
      Add Criteria / Vitals
    </h3>
  </div>
)

function CreateTaskConditionDialog({
  task,
  statusMessage,
  schedules = [],
  existingConditions,
  visible,
  onHide,
}) {
  const [actions, setActions] = useState([])
  const [conditionType, setConditionType] = useState()
  const [conditionNote, setConditionNote] = useState()
  const [selectedSchedules, setSelectedSchedules] = useState([])

  const {
    mutateAsync: createTaskCondition,
    isLoading: isCreatingCondition,
  } = useCreateTaskCondition(statusMessage)

  const onCreateCondition = async () => {
    const condition = {
      taskId: task.id,
      conditionType,
      note: conditionNote,
      scheduleIds: selectedSchedules.map((scheduleId) => parseInt(scheduleId, 10)),
      actions,
    }
    await createTaskCondition(
      condition,
      {
        onSuccess: () => {
          onHide()
        },
      },
    )
  }

  const otherConditionsForType = existingConditions
    .filter((condition) => condition.conditionType === conditionType)

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header={dialogHeaderTemplate}
      style={{ width: '75vw' }}
    >
      <div className="flex flex-column gap-4 align-items-center px-2">
        <div className="col-12 flex flex-column gap-2 text-center">
          <ConditionType
            conditionType={conditionType}
            schedules={schedules}
            existingConditions={existingConditions}
            isPrn={task.taskType === 'prn'}
            setConditionType={setConditionType}
          />
        </div>
        {
          task.taskType === 'routine' && (
            <ConditionScheduleSelector
              conditionType={conditionType}
              selectableSchedules={schedules}
              otherConditionsForType={otherConditionsForType}
              selectedSchedules={selectedSchedules}
              setSelectedSchedules={setSelectedSchedules}
            />
          )
        }
        <ConditionNote
          conditionNote={conditionNote}
          conditionType={conditionType}
          setConditionNote={setConditionNote}
        />
        <ActionsTable
          actions={actions}
          conditionType={conditionType}
          setActions={setActions}
        />
        <Button
          label="Create"
          className="flex w-full p-button-sm"
          loading={isCreatingCondition}
          onClick={onCreateCondition}
          disabled={!conditionType}
        />
      </div>
    </Dialog>
  )
}

export default CreateTaskConditionDialog
