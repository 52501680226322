import React from 'react'
import { InputSwitch } from 'primereact/inputswitch'

function CheckboxWithLabel({
  name, label, inputId, checked, onChange, disabled = false,
}) {
  return (
    <div className="field-checkbox">
      <label htmlFor={inputId} className="p-checkbox-label flex align-items-center">
        <InputSwitch
          name={name}
          inputId={inputId}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          className="mr-2"
        />
        {label}
      </label>
    </div>
  )
}

export default CheckboxWithLabel
