import React from 'react'
import { startCase } from 'lodash'
import CustomTag from '@components/display/CustomTag'

export function TaskStatusTag({ task }) {
  if (!task) {
    return null
  }

  const color = 'var(--green-400)'
  const text = startCase(task.status)

  return <CustomTag color={color} text={text} />
}

export default TaskStatusTag
