import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import FormWrapper from '@components/display/Form/FormWrapper'
import InputTextWithLabel from '@components/display/InputTextWithLabel'
import { Dropdown } from 'primereact/dropdown'
import { compact, get } from 'lodash'
import { useUpdateIntegrationFacilityMutation } from './facilitiesHooks'
import { usePharmaciesQuery } from '../Pharmacies/pharmaciesHooks'

function UpdateFacility(
  {
    facility,
    handleSetHeader,
  },
) {
  const statusMessage = useRef(null)
  const navigate = useNavigate()

  const [externalId, setExternalId] = useState()
  const [pharmacy, setPharmacy] = useState()

  const {
    mutateAsync: updateFacility,
    isLoading: isUpdatingFacility,
  } = useUpdateIntegrationFacilityMutation(statusMessage)

  const {
    data: pharmacyOptions,
    isLoading: isPharmacyOptionsLoading,
  } = usePharmaciesQuery()

  const handleUpdateFacility = () => {
    updateFacility({
      id: facility.id,
      externalId,
      pharmacyId: get(pharmacy, 'id'),
    }, {
      onSuccess: () => {
        navigate('/admin/facilities')
      },
    })
  }

  const breadcrumbs = compact([
    {
      label: 'Facilities',
      template: <Link to="/admin/facilities">Facilities</Link>,
    },
    {
      label: 'Edit',
      template: <span>Edit</span>,
    },
  ])

  useEffect(() => {
    if (facility) {
      setExternalId(facility.externalId)
      setPharmacy(facility.pharmacy)
    }

    handleSetHeader({ breadcrumbs })
  }, [facility])

  return (
    <div id="create-facility" className="flex flex-column">
      <div className="flex flex-row flex-wrap">
        <Toast ref={statusMessage} />
        <div className="col-6">
          <FormWrapper title="Profile" fullHeight>
            <InputTextWithLabel
              label="External ID"
              value={externalId}
              onChange={(e) => setExternalId(e.target.value)}
            />
            <div className="field">
              <label className="block" htmlFor="pharmacy">Pharmacy</label>
              <Dropdown
                inputId="pharmacy"
                value={pharmacy}
                options={pharmacyOptions}
                dataKey="id"
                optionLabel="name"
                onChange={(e) => setPharmacy(e.value)}
                placeholder="Select Pharmacy"
                loading={isPharmacyOptionsLoading || isUpdatingFacility}
                disabled={isUpdatingFacility}
                className="w-full"
                filter
              />
            </div>
            <Button
              label="Update"
              className="flex w-full p-button-sm"
              loading={isUpdatingFacility}
              onClick={handleUpdateFacility}
              disabled={!externalId}
            />
          </FormWrapper>
        </div>
      </div>
    </div>
  )
}

export default UpdateFacility
