import { classNames } from 'primereact/utils'
import React, { useState } from 'react'

/**
 * This component is a container that changes its style and class based on whether it is
 * hovered or not.
 *
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {string} props.className
 * @param {string} props.hoveredClassName
 * @param {string} props.nonHoveredClassName
 * @param {Object} props.hoveredStyle
 * @param {Object} props.nonHoveredStyle
 * @returns
 */
function HoveredContainer({
  children,
  className,
  hoveredClassName,
  nonHoveredClassName,
  hoveredStyle,
  nonHoveredStyle,
  ...props
}) {
  const [isHovered, setIsHovered] = useState(false)
  const onMouseEnter = () => setIsHovered(true)
  const onMouseLeave = () => setIsHovered(false)

  const classes = classNames(className, {
    [hoveredClassName]: isHovered,
    [nonHoveredClassName]: !isHovered,
  })

  const styles = {
    ...(props.style || {}),
    ...(isHovered ? hoveredStyle : nonHoveredStyle),
  }

  return (
    <div
      {...props}
      className={classes}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={styles}
    >
      {children}
    </div>
  )
}

export default HoveredContainer
