import React from 'react'
import { useFormV2Context } from '@components/display/FormV2/FormContext'

/**
 * FieldRenderer component is responsible for rendering a specific form field component.
 * It uses the FormV2 context to provide form control and error handling to the rendered component
 * and can be extended to include more context if needed.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {React.ComponentType} props.component - The form field component to be rendered.
 * @param {Object} props.fieldProps - The properties to be passed to the form field component.
 * @param {boolean} [props.shouldShowContent=true] - Determine if the field should be rendered.
 * @returns {JSX.Element|null} The rendered form field component or null.
 */
export default function FieldRenderer({
  component: Component,
  fieldProps,
  shouldShowContent = true,
}) {
  const { control, errors } = useFormV2Context()

  if (!shouldShowContent) return null

  return <Component {...fieldProps} inputControl={control} errors={errors} />
}
