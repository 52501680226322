import React from 'react'
import { Divider } from 'primereact/divider'
import { isEmpty } from 'lodash'
import NarcoticCountDetails from './NarcoticCountDetails'

function NarcoticCountsStatusOverlay({
  narcoticCounts,
  timezone,
}) {
  if (isEmpty(narcoticCounts)) return null

  return (
    <div className="w-26rem flex flex-column">
      {
        narcoticCounts.map((narcoticCount) => (
          <NarcoticCountDetails
            key={narcoticCount.id}
            narcoticCount={narcoticCount}
            timezone={timezone}
          />
        )).reduce((prev, curr) => [prev, <Divider />, curr])
      }
    </div>
  )
}

export default NarcoticCountsStatusOverlay
