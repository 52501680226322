import React from 'react'
import { formatAdministrationAmount } from '@components/clientDoses/doseUtils'
import { momentFormats, momentFromIso8601Str } from '@services/utils/moment'

function SigDetails({
  pharmacyMedicine,
  administrationAmount,
  startAtWall,
  endAtWall,
  pharmacyInstructions,
}) {
  const sigAttributes = [
    {
      label: 'Amount',
      value: formatAdministrationAmount({
        administrationAmount,
        pharmacyMedicine,
      }),
    },
    {
      label: 'Strength',
      value: pharmacyMedicine?.strength,
    },
  ]

  if (startAtWall) {
    sigAttributes.push({
      label: 'Start At',
      value: momentFromIso8601Str(startAtWall).format(momentFormats.dateYear),
    })
  }

  if (endAtWall) {
    sigAttributes.push({
      label: 'End At',
      value: momentFromIso8601Str(endAtWall).format(momentFormats.dateYear),
    })
  }

  const attributeTemplate = ({ label, value }) => (
    <div key={label} className="flex flex-row align-items-center">
      <span className="mr-1 text-600">
        {label}
        :
      </span>
      <span>{value}</span>
    </div>
  )

  return (
    <div className="flex flex-column gap-2 text-sm text-900 p-1">
      <div>
        <span className="p-0 mr-1 text-600">Instructions:</span>
        <span>{pharmacyInstructions}</span>
      </div>
      <div className="flex flex-wrap gap-2">
        {sigAttributes.map((attribute) => attributeTemplate(attribute))}
      </div>
    </div>
  )
}

export default SigDetails
