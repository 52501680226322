import React, { useState } from 'react'
import { get } from 'lodash'
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import {
  NavLink, Route, Routes,
} from 'react-router-dom'
import { classNames } from 'primereact/utils'
import ClinicalNotesList from '@components/patients/ClinicalNotes/ClinicalNotesList'
import { Divider } from 'primereact/divider'
import { useCurrentOrganization } from '@components/App'
import Exports from '@components/patients/Exports'
import PatientInsurances from '@components/patients/PatientInsurances/PatientInsurances'
import MedicalConditions from '@components/display/MedicalConditions/MedicalConditions'
import BowelMovements from '../../components/patients/BowelMovements'

function Menu({ showBowelMovements }) {
  const viewLinks = [
    {
      label: 'Clinical Notes',
      linkTo: 'clinical_notes',
    },
    {
      label: 'Medical Conditions',
      linkTo: 'medical_conditions',
    },
    {
      label: 'Bowel Movements',
      linkTo: 'bowel_movements',
      showMenu: showBowelMovements,
    },
    {
      label: 'Insurance',
      linkTo: 'insurance',
    },
  ]

  return (
    <div className="flex justify-space-between align-items-center px-1">
      <div className="flex">
        {viewLinks.map(({ label, linkTo, showMenu = true }) => (
          showMenu && (
          <NavLink to={linkTo} key={linkTo}>
            {
              ({ isActive }) => (
                <Button className={classNames('flex align-items-baseline p-button-sm p-button-outlined', { 'p-button-text': !isActive })}>
                  <span className={classNames({ 'text-600': !isActive })}>
                    {label}
                  </span>
                </Button>
              )
            }
          </NavLink>
          )
        ))}
      </div>
    </div>
  )
}

function HealthRecords({ patient, handleSetHeader }) {
  const [searchTerm, setSearchTerm] = useState()
  const organization = useCurrentOrganization()
  const showBowelMovements = get(organization, 'bowelMovementTrackingEnabled') && get(patient, 'patientSettings.bowelMovementTrackingEnabled')

  if (!patient) {
    return null
  }

  const primaryActionSetup = (action) => {
    handleSetHeader({ primaryAction: action })
  }

  return (
    <div className="col-12">
      <Exports
        patientId={patient?.id}
        exportsMenuSetupCallBack={primaryActionSetup}
      />
      <Card className="h-full">
        <div className="flex flex-column gap-3 text-base text-900">
          <Menu showBowelMovements={showBowelMovements} />
          <Divider className="my-0" />
          <Routes>
            <Route
              path="clinical_notes"
              element={(
                <ClinicalNotesList
                  patient={patient}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                />
              )}
            />
            <Route
              path="medical_conditions"
              element={
                <MedicalConditions patientId={patient.id} />
              }
            />
            <Route
              path="bowel_movements"
              element={<BowelMovements patient={patient} />}
            />
            <Route
              path="insurance"
              element={<PatientInsurances patient={patient} />}
            />
          </Routes>
        </div>
      </Card>
    </div>
  )
}

export default HealthRecords
