import React from 'react'
import { Routes, Route } from 'react-router-dom'
import PageLayout, { usePageOutlet } from '@components/display/PageLayout'
import { withApp, useCurrentUser } from '@components/App'
import PharmaciesTable from './PharmaciesTable'
import Pharmacy from './Pharmacy'
import CreatePharmacy from './CreatePharmacy'
import './pharmacies.scss'

function Pharmacies() {
  const usePharmaciesHeader = () => usePageOutlet()
  const isCurrentUserSuperadmin = useCurrentUser().role === 'superadmin'
  const rootPath = '/admin/pharmacies'

  return (
    <div className="pharmacies">
      <Routes>
        <Route path="/admin/pharmacies/*" element={<PageLayout rootPath={rootPath} rootLabel="Pharmacies" />}>
          <Route
            index
            element={(
              <PharmaciesTable rootPath={rootPath} usePharmaciesHeader={usePharmaciesHeader} />
            )}
          />
          <Route
            path="create"
            element={(
              <CreatePharmacy
                isCurrentUserSuperadmin={isCurrentUserSuperadmin}
                rootPath={rootPath}
                usePharmaciesHeader={usePharmaciesHeader}
              />
            )}
          />
          <Route
            path=":pharmacyId/*"
            element={(
              <Pharmacy
                isCurrentUserSuperadmin={isCurrentUserSuperadmin}
                rootPath={rootPath}
                usePharmaciesHeader={usePharmaciesHeader}
              />
            )}
          />
        </Route>
      </Routes>
    </div>
  )
}

export default withApp(Pharmacies)
