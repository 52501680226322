import React from 'react'
import CheckboxWithLabel from '@components/display/CheckboxWithLabel'

function Settings({
  settings, setSettings, ccmEnabled, orgBowelMovementTrackingEnabled,
}) {
  const { selfAdmin, ccmEligible } = settings
  return (
    <div className="flex flex-column">
      <CheckboxWithLabel
        key="selfAdmin"
        label="Self Admin"
        checked={selfAdmin}
        inputId="selfAdmin"
        name="selfAdmin"
        onChange={({ value }) => setSettings({ ...settings, selfAdmin: value })}
      />
      {ccmEnabled
        && (
          <CheckboxWithLabel
            key="ccmEligible"
            label="CCM Eligible"
            checked={ccmEligible}
            inputId="ccmEligible"
            name="ccmEligible"
            onChange={({ value }) => setSettings({ ...settings, ccmEligible: value })}
          />
        )}
      {
        orgBowelMovementTrackingEnabled
        && (
          <CheckboxWithLabel
            key="bowelMovementTrackingEnabled"
            label="Bowel Movement Tracking"
            checked={settings.bowelMovementTrackingEnabled}
            inputId="bowelMovementTrackingEnabled"
            name="bowelMovementTrackingEnabled"
            onChange={
              ({ value }) => setSettings({ ...settings, bowelMovementTrackingEnabled: value })
            }
          />
        )
      }
    </div>
  )
}

export default Settings
