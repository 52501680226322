import React, { useState, useEffect } from 'react'
import { map, uniqBy, first } from 'lodash'
import { AutoComplete } from 'primereact/autocomplete'
import { Button } from 'primereact/button'
import { defaultDoseForms } from '../config'

function MedicinePhysicalForm({
  rxTermSearchResults, medicineForm, setMedicineForm, handleConfirm, medicineName,
}) {
  const [filteredMedicineForms, setFilteredMedicineForms] = useState([])

  useEffect(() => {
    const forms = map(uniqBy(rxTermSearchResults.filter((rxTerm) => rxTerm.displayName === medicineName), 'form'), ({ form }) => form)
    setFilteredMedicineForms(forms)
    const selectedMedicine = rxTermSearchResults.find(
      (rxTerm) => rxTerm.displayName === medicineName,
    )
    if (selectedMedicine) {
      setMedicineForm(selectedMedicine.form)
    } else {
      setMedicineForm(first(forms))
    }
  }, [rxTermSearchResults])

  const filterMedicineForms = (event) => {
    setTimeout(() => {
      let filtered
      let forms = map(uniqBy(rxTermSearchResults.filter((rxTerm) => rxTerm.displayName === medicineName), 'form'), ({ form }) => form)
      if (forms.length < 1) {
        forms = defaultDoseForms
      }
      if (!event.query.trim().length) {
        filtered = [...forms]
      } else {
        filtered = forms.filter((form) => {
          if (!form) {
            return
          }
          return form.toLowerCase().includes(event.query.toLowerCase())
        })
      }
      setFilteredMedicineForms(filtered)
    }, 250)
  }

  return (
    <div className="grid flex flex-grow-1 justify-content-center align-items-center fadein">
      <div className="col-9 flex flex-column gap-2 text-center">
        <label className="text-lg font-normal mb-1" htmlFor="dose-medicine-form">
          What physical form is the medicine?
          <p className="text-sm font-normal">Example, Oral Pill, Liquid, Spray etc...</p>
        </label>
        <AutoComplete
          inputId="dose-medicine-form"
          value={medicineForm}
          data-testid="medicine-form"
          suggestions={filteredMedicineForms}
          completeMethod={filterMedicineForms}
          dropdown
          autoFocus
          onChange={(e) => setMedicineForm(e.value)}
          aria-label="Medicine Form"
          dropdownarialabel="Select Medicine Form"
        />
        <Button
          onClick={handleConfirm}
          label="Next"
          className="p-button-text mt-2"
          disabled={!medicineForm}
        />
      </div>
    </div>
  )
}

export default MedicinePhysicalForm
