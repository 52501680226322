import React from 'react'
import { Controller } from 'react-hook-form'
import { useFormV2Context } from '@components/display/FormV2/FormContext'
import InputTextWithLabel from '@components/display/InputTextWithLabel'

function OrgTextField({ name, label }) {
  const { control } = useFormV2Context()
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div className="w-6">
          <InputTextWithLabel
            name={name}
            label={label}
            inputId={name}
            value={field.value}
            onChange={(e) => {
              field.onChange(e.target.value)
            }}
          />
        </div>
      )}
    />
  )
}

export default OrgTextField
