import React, { useState } from 'react'
import { Button } from 'primereact/button'
import { InputNumber } from 'primereact/inputnumber'
import { formatAdministrationAmount } from '@components/clientDoses/doseUtils'

function AdministrationAmountEditor({
  administrationAmount, setAdministrationAmount, isLoading, medicine,
}) {
  const [amount, setAmount] = useState(administrationAmount)

  const handleConfirmation = () => {
    setAdministrationAmount(amount)
  }

  return (
    <div className="flex flex-row gap-2 align-items-center">
      <InputNumber
        className="w-4"
        value={amount}
        onChange={({ value }) => setAmount(value)}
        style={{ minWidth: '4rem' }}
        min={0}
        showButtons
        autoFocus
        minFractionDigits={0}
        maxFractionDigits={3}
      />
      <span>{formatAdministrationAmount({ administrationAmount: amount, medicine }) }</span>
      <Button
        onClick={handleConfirmation}
        loading={isLoading}
        icon="pi pi-check"
      />
    </div>
  )
}

export default AdministrationAmountEditor
