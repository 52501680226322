import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'primereact/button'
import { Message } from 'primereact/message'

function NeedsReviewNotification({
  label = 'This prescription has pending pharmacy orders that need review',
  reviewUrl,
}) {
  const navigate = useNavigate()

  const content = (
    <div className="d-flex flex-1 align-items-center justify-content-between">
      <span className="pi pi-info-circle mr-2 text-2xl text-blue-600" />
      <span className="text-base text-blue-600 font-normal">{label}</span>
      {
        reviewUrl && (
          <Button
            label="Review"
            onClick={() => navigate(reviewUrl)}
            icon="pi pi-arrow-right"
            iconPos="right"
            className="p-button-outlined p-button-sm h-2rem w-9rem white-space-nowrap"
          />
        )
      }
    </div>
  )

  return (
    <div className="col-12 gap-3">
      <Message
        className="border-primary w-full justify-content-start"
        content={content}
        severity="info"
      />
    </div>
  )
}

export default NeedsReviewNotification
