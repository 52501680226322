import React from 'react'
import InputTextWithLabel from '@components/display/InputTextWithLabel'

function Details({
  details = {}, setDetails, isSuperAdmin, isLoading = false,
}) {
  const { label, mac } = details
  return (
    <div className="flex flex-column">
      <InputTextWithLabel
        label="Label"
        value={label || ''}
        inputId="label"
        name="label"
        onChange={({ target: { value } }) => setDetails({ label: value })}
        disabled={isLoading}
      />
      <InputTextWithLabel
        label="MAC"
        value={mac || ''}
        inputId="mac"
        name="mac"
        onChange={({ target: { value } }) => setDetails({ mac: value })}
        disabled={!isSuperAdmin || isLoading}
      />
    </div>
  )
}

export default Details
