// eslint-disable-next-line import/prefer-default-export
export const REPORT_TYPES = [
  {
    label: 'MAR',
    value: 'mar',
  },
  {
    label: 'PRN MAR',
    value: 'prn_mar',
  },
  {
    label: 'Medication Errors',
    value: 'medication_errors',
  },
  {
    label: 'Clinical Notes',
    value: 'clinical_notes',
  },
  {
    label: 'Physician Orders',
    value: 'physician_orders',
  },
  {
    label: 'Bowel Movements',
    value: 'bowel_movements',
  },
  {
    label: 'Narcotic Counts',
    value: 'narcotic_counts',
  },
  {
    label: 'Barcode Scans',
    value: 'barcode_scans',
  },
]
