import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from 'primereact/button'
import 'primeflex/primeflex.css'
import { FormContext } from './FormContext'

function FormV2({
  children, onSubmit, submitLabel, showSubmitButton = true, isLoading, currentFormValues = {},
}) {
  const {
    handleSubmit, control, setValue, formState: { errors, isSubmitting }, reset, getValues,
  } = useForm({
    defaultValues: currentFormValues,
  })

  useEffect(() => {
    reset(currentFormValues)
  }, [currentFormValues])

  const values = useMemo(() => ({
    control,
    errors,
    reset,
    getValues,
    setValue,

  }), [control, errors])

  return (
    <FormContext.Provider value={values}>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column gap-4 w-full">
        {children}
        {showSubmitButton
          && (
          <Button
            type="submit"
            disabled={isSubmitting || isLoading}
            icon={isSubmitting || isLoading ? 'pi pi-spin pi-spinner' : ''}
            label={submitLabel}
            className="w-full mt-auto"
          />
          )}
      </form>
    </FormContext.Provider>
  )
}

export default FormV2
