import React from 'react'
import { Button } from 'primereact/button'
import { get, map } from 'lodash'
import { Link, useNavigate } from 'react-router-dom'
import { shouldReturnToReviewListOnAcknowledge } from '@components/pharmacyOrders/utils/directives'
import {
  useReviewableOrderState,
} from '../../../features/ClientDoses/PharmacyOrders/reviewableOrderState/ReviewableOrderContext'
import { buildDoseParams } from '../pharmacyOrderUtils'

function HeaderSection({
  matchedDoseId, patientId, reviewableOrder, selectedDirective,
}) {
  const navigate = useNavigate()
  const reviewableOrderState = useReviewableOrderState()

  const {
    mutateAsync: acknowledgeOrders,
    isLoading: acknowledgeOrdersLoading,
  } = selectedDirective.mutation || {}

  const handleAcknowledgeOrders = async () => {
    const ordersParams = map(reviewableOrder.sourceOrders, ({ id }) => ({ id }))
    const params = { orders: ordersParams }

    if (selectedDirective.code !== 'ignore') {
      params.dose = buildDoseParams(reviewableOrderState, matchedDoseId)
    }

    const response = await acknowledgeOrders(params)

    const route = shouldReturnToReviewListOnAcknowledge(selectedDirective)
      ? '..'
      : `/admin/patients/${patientId}/doses/manage/${response.id}`

    navigate(
      route,
      { state: { previous: `/admin/patients/${patientId}/orders/needs-review` } },
    )
  }

  return (
    <div className="flex flex-row align-items-baseline px-4 mb-2">
      <Link to="..">Back to Orders</Link>
      <Button
        label={`Confirm ${get(selectedDirective, 'text.button')}`}
        onClick={handleAcknowledgeOrders}
        loading={acknowledgeOrdersLoading}
        className="p-button-sm w-fit ml-auto"
      />
    </div>
  )
}

export default HeaderSection
