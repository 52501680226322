import React from 'react'

function LocationFillsDetails({ doseFills }) {
  if (!doseFills || doseFills.length === 0) {
    return null
  }
  return (
    <div className="flex flex-column gap-2">
      <div className="flex flex-column gap-2">
        {doseFills.map((doseFill) => (
          <div key={doseFill.id} className="flex flex-column gap-2">
            <span className="text-xs" style={{ wordBreak: 'break-word' }}>
              {doseFill.medicine.displayName}
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default LocationFillsDetails
