import React from 'react'
import {
  Routes, Route, useNavigate, useParams,
} from 'react-router-dom'
import { EventTimesList, EventTimeForm } from '@components/eventTimes'

function EventTimes({ handleSetHeader, ownerType }) {
  const navigate = useNavigate()
  const { organizationId, siteId, patientId } = useParams()
  const ownerId = organizationId || siteId || patientId

  return (
    <Routes>
      <Route
        index
        element={(
          <EventTimesList
            ownerType={ownerType}
            ownerId={ownerId}
            handleCreate={() => navigate('create')}
            handleEdit={(eventTime) => {
              navigate(`${eventTime.id}/edit`)
            }}
            handleSetHeader={handleSetHeader}
          />
        )}
      />
      <Route
        path="create"
        element={(
          <EventTimeForm
            ownerType={ownerType}
            ownerId={ownerId}
            onSubmit={() => navigate('./')}
          />
        )}
      />
      <Route
        path=":eventTimeId/edit"
        element={(
          <EventTimeForm
            ownerType={ownerType}
            ownerId={ownerId}
            useRouteParams
            onSubmit={() => navigate('./')}
          />
        )}
      />
    </Routes>
  )
}

export default EventTimes
