import React from 'react'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import WeekDayDropdownWithLabel from '@components/display/WeekDayDropdownWithLabel'

function ShiftDialogBody({
  startDayOfWeek,
  setStartDayOfWeek,
  startTime,
  setStartTime,
  endDayOfWeek,
  setEndDayOfWeek,
  endTime,
  setEndTime,
  isSubmitting,
  onSubmit,
  onSubmitLabel,
}) {
  return (
    <div className="flex flex-column gap-5 align-items-center px-2" style={{ width: '30rem' }}>
      <WeekDayDropdownWithLabel
        classNameDiv="flex flex-column gap-3 text-center w-full"
        classNameLabel="text-lg font-normal mb-1"
        label="What day of the week does the shift start?"
        onChange={({ value }) => setStartDayOfWeek(value)}
        placeholder="Select a day"
        value={startDayOfWeek}
      />
      <div className="flex flex-column gap-3 text-center w-full">
        <label className="text-lg font-normal mb-1" htmlFor="shift-start-time">
          What time does the shift start?
        </label>
        <Calendar
          id="shift-start-time"
          value={startTime}
          onChange={(e) => setStartTime(e.value)}
          timeOnly
          hourFormat="12"
          inline
        />
      </div>
      <WeekDayDropdownWithLabel
        classNameDiv="flex flex-column gap-3 text-center w-full"
        classNameLabel="text-lg font-normal mb-1"
        label="What day of the week does the shift end?"
        onChange={({ value }) => setEndDayOfWeek(value)}
        placeholder="Select a day"
        value={endDayOfWeek}
      />
      <div className="flex flex-column gap-3 text-center w-full">
        <label className="text-lg font-normal mb-1" htmlFor="shift-end-time">
          What time does the shift end?
        </label>
        <Calendar
          id="shift-end-time"
          value={endTime}
          onChange={(e) => setEndTime(e.value)}
          timeOnly
          hourFormat="12"
          inline
        />
      </div>
      <Button
        label={onSubmitLabel}
        className="flex w-full p-button-sm"
        loading={isSubmitting}
        onClick={onSubmit}
        disabled={(!startDayOfWeek && startDayOfWeek !== 0)
          || (!endDayOfWeek && endDayOfWeek !== 0) || !startTime || !endTime}
      />
    </div>
  )
}

export default ShiftDialogBody
