import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import DetailsForm from '@components/pharmacies/PharmacyForm'
import FormWrapper from '@components/display/Form/FormWrapper'
import { usePharmacyCreateMutation } from './pharmaciesHooks'

function CreatePharmacy({ isCurrentUserSuperadmin, rootPath, usePharmaciesHeader }) {
  const navigate = useNavigate()

  if (!isCurrentUserSuperadmin) {
    navigate(rootPath)
  }

  const statusMessage = useRef(null)
  const { setHeader } = usePharmaciesHeader()

  const [details, setDetails] = useState({
    name: '',
    externalId: '',
  })

  const {
    mutateAsync: createPharmacy,
    isLoading: createIsLoading,
  } = usePharmacyCreateMutation(statusMessage)

  const handleCreate = async () => {
    const pharmacy = await createPharmacy(details)

    if (pharmacy) {
      navigate(`../${pharmacy.id}`)
    }
  }

  const title = {
    label: (
      <span>
        <i className="text-xl pi pi-users mr-2" />
        Pharmacies
      </span>
    ),
    linkTo: rootPath,
  }

  const breadcrumbs = [
    {
      label: 'Create',
      template: <span>Create</span>,
    },
  ]

  useEffect(() => (
    setHeader({ title, breadcrumbs, primaryAction: null })
  ), [])

  return (
    <div className="flex flex-row flex-wrap">
      <Toast ref={statusMessage} />
      <div className="col-8 xl:col-6">
        <FormWrapper title="Create Pharmacy" fullHeight>
          <DetailsForm
            details={details}
            setDetails={(value) => setDetails((p) => ({ ...p, ...value }))}
          />
          <Button
            label="Create Pharmacy"
            className="p-button-sm mt-4"
            loading={createIsLoading}
            onClick={handleCreate}
          />
        </FormWrapper>
      </div>
    </div>
  )
}

export default CreatePharmacy
