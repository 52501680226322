import { useMemo } from 'react'
import { get, map } from 'lodash'
import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'

const getPath = (model, id) => {
  switch (model) {
    case 'DirectoryUser':
      return `/directory_users/${id}/workos_messages`
    case 'Organization':
      return `/organizations/${id}/workos_messages`
    case 'Site':
      return `/sites/${id}/workos_messages`
    case 'User':
      return `/users/${id}/workos_messages`
    default:
      throw new Error(`Unsupported model: ${model}`)
  }
}

export const useWorkosMessages = (model, id) => {
  const path = getPath(model, id)

  const query = useQuery({
    queryKey: ['workosMessages', path],
    queryFn: () => ApiClient.get(path),
    enabled: !!id,
  })

  return useMemo(() => ({
    ...query,
    data: map(get(query, 'data', [])),
  }), [query])
}

export default useWorkosMessages
