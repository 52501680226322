import {
  first, get, isEmpty, map,
} from 'lodash'
import { consumptionDisplayName } from '@components/clientDoses/doseUtils'

export const medicineAttribute = (consumption) => ({
  title: consumptionDisplayName(consumption),
  width: '30%',
  details: [
    `Strength: ${get(consumption, 'medicine.strength')}`,
    `Administration Amount: ${get(consumption, 'dose.administrationAmount')}`,
  ],
})

export const patientAttribute = (consumption, organization) => ({
  title: get(organization, 'patientLabelSingular'),
  width: '20%',
  details: [
    get(consumption, 'patient.fullName'),
    `Site: ${get(first(get(consumption, 'patient.assignedSites')), 'name')}`,
  ],
})

export const caregiversAttribute = (consumption, organization) => {
  const caregivers = get(consumption, 'patient.caregivers', [])

  let details
  if (isEmpty(caregivers)) {
    details = [`No ${get(organization, 'caregiverLabelPlural')}`, 'Assigned']
  } else if (caregivers.length <= 2) {
    details = map(caregivers, 'fullName')
  } else {
    details = [`${caregivers.length} Assigned`]
  }

  return {
    title: get(organization, 'caregiverLabelPlural'),
    width: '20%',
    details,
  }
}
