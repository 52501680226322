import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import {
  displayErrorMessages,
} from '@services/errorHandler'

export function useHrstPatientMatchMutation(statusMessage, patientId) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ ssn }) => ApiClient.post('/hrst/match_patient', {
      patientId,
      ssn,
    }),
    onSuccess: () => {
      queryClient.invalidateQueries('patient')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useHrstUpdateDiagnosesMutation(statusMessage, patientId) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => ApiClient.post('/hrst/submit_diagnoses', {
      patientId,
    }),
    onSuccess: () => {
      queryClient.invalidateQueries('patient')
      statusMessage.current.clear()
      statusMessage.current.show({
        summary: 'Diagnoses updated',
        severity: 'success',
      })
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}
