import React, { useRef } from 'react'
import { withApp } from '@components/App'
import { Messages } from 'primereact/messages'
import { useSettingsQuery } from './settingsHooks'
import AdminSettings from './AdminSettings/AdminSettings'
import CardWithTitles from './CardWithTitles'

function Settings({ organization }) {
  const statusMessage = useRef(null)

  const query = useSettingsQuery(organization.id, statusMessage)
  return (
    <div className="mt-3">
      <CardWithTitles title="Settings" titleIcon={<i className="pi pi-cog" />}>
        <Messages ref={statusMessage} />
        <AdminSettings
          organization={organization}
          organizationId={organization.id}
          query={query}
          statusMessage={statusMessage}
        />
      </CardWithTitles>
    </div>
  )
}

export default withApp(Settings)
