import React from 'react'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import SuperAdminCard from '@components/display/SuperAdminCard'
import DoseFillsCard from '@components/doseFills/DoseFillsCard'
import { NeedsReviewNotification } from '@components/notifications'
import PharmacyOrdersCard from '@components/pharmacyOrders/Card/PharmacyOrdersCard'
import PrescriptionCard from '@components/prescriptions/PrescriptionCard'
import SmartFlipsTable from '@components/smartFlips/SmartFlipsTable'
import { usePrescription } from '@hooks/prescriptions'

function ManagePrescriptionPage() {
  const { prescriptionId } = useParams()

  const {
    data: prescription,
    isLoading,
  } = usePrescription({ prescriptionId })

  if (isLoading || isEmpty(prescription)) {
    return 'Loading...'
  }

  const { patientId, prescriptionNumber } = prescription
  const reviewUrl = `/admin/patients/${patientId}/orders/needs-review/rx${prescriptionNumber}`

  return (
    <>
      {
        prescription.needsReview && (<NeedsReviewNotification reviewUrl={reviewUrl} />)
      }
      <PrescriptionCard prescription={prescription} />
      <DoseFillsCard model="Prescription" id={prescriptionId} />
      <div className="col-12">
        <SuperAdminCard title="Smart Flips">
          <SmartFlipsTable smartFlips={prescription.smartFlips} />
        </SuperAdminCard>
      </div>
      <PharmacyOrdersCard model="Prescription" id={prescriptionId} />
    </>
  )
}

export default ManagePrescriptionPage
