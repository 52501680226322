import React from 'react'
import SuperAdminCard from '@components/display/SuperAdminCard'
import DoseFillsTable from '@components/doseFills/DoseFillsTable'

function DoseFillsCard({ model, id, status = 'all' }) {
  return (
    <div className="col-12">
      <SuperAdminCard title="Dispensed Fills">
        <DoseFillsTable model={model} id={id} status={status} />
      </SuperAdminCard>
    </div>
  )
}

export default DoseFillsCard
