import React from 'react'
import { SelectButton } from 'primereact/selectbutton'
import { Tooltip } from 'primereact/tooltip'
import { daysOfWeek } from './config'

function DaysOfWeekControls({ selectedDaysOfWeek, setSelectedDaysOfWeek }) {
  const itemTemplate = (option) => (
    <>
      <Tooltip
        target={`.day-of-week-option-${option.title}`}
        position="bottom"
        content={option.title}
      />
      <span className={`day-of-week-option-${option.title}`}>{option.label}</span>
    </>
  )

  return (
    <div className="p-inputgroup w-full">
      <SelectButton
        className="w-full"
        value={selectedDaysOfWeek}
        onChange={(e) => setSelectedDaysOfWeek(e.value)}
        options={daysOfWeek}
        itemTemplate={itemTemplate}
        multiple
      />
    </div>
  )
}

export default DaysOfWeekControls
