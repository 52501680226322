import React, { useState, useCallback, useEffect } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { roleToOrganizationLabel } from '@services/utils'
import { exactSearch } from '@services/utils/search'
import { useOnboardSSOUsers } from './ssoPendingUsersHooks'
import OnboardDialog from './OnboardDialog'

function PendingUsers({
  pendingUsers,
  organization,
  statusMessage,
  isLoading,
  searchTerm,
  setSearchLoading,
}) {
  const [directoryUser, setDirectoryUser] = useState(null)
  const [shouldShowOnboardDialog, setShouldShowOnboardDialog] = useState(false)
  const [showConfirmLinkUser, setShowConfirmLinkUser] = useState(false)
  const [filteredUsers, setFilteredUsers] = useState()
  const canViewSites = !organization.deviceBasedAccess

  useEffect(() => {
    setSearchLoading(true)
    if (searchTerm) {
      setFilteredUsers(exactSearch(pendingUsers, ['firstName', 'lastName'], searchTerm))
    } else {
      setFilteredUsers(pendingUsers)
    }
    setSearchLoading(false)
  }, [pendingUsers, searchTerm, setSearchLoading])

  const {
    isLoading: onboardingInProgress,
    mutateAsync: onboardSSOUser,
  } = useOnboardSSOUsers(directoryUser?.id, statusMessage)

  const handleOnboardSSOUser = useCallback(async (userFormData) => {
    await onboardSSOUser(userFormData)
    setShouldShowOnboardDialog(false)
  }, [onboardSSOUser])

  const handleLinkUsers = useCallback(async (existingUser) => {
    await onboardSSOUser({ userId: existingUser.id })
    setShouldShowOnboardDialog(false)
  }, [onboardSSOUser])

  const setDirectoryUserData = useCallback((user = {}) => {
    setDirectoryUser(user)
    setShouldShowOnboardDialog(true)
  }, [])

  const onboardButton = (user) => (
    <Button
      icon="pi pi-user-plus"
      className="p-button-sm p-button-outlined"
      onClick={() => setDirectoryUserData(user)}
    />
  )

  const sitesTemplate = (rowData) => {
    const { sites } = rowData
    if (!sites || sites.length === 0) {
      return 'No sites assigned'
    }
    if (sites.length === 1) {
      return sites[0].name
    }
    if (sites.length > 1 && sites.length === organization.sites?.length) {
      return 'All sites'
    }
    return `Assigned to ${sites?.length} sites`
  }

  return (
    <>
      {
        shouldShowOnboardDialog && (
          <OnboardDialog
            visible={shouldShowOnboardDialog}
            setVisible={setShouldShowOnboardDialog}
            createNewUser={handleOnboardSSOUser}
            handleLinkUsers={handleLinkUsers}
            onboardingInProgress={onboardingInProgress}
            pendingUser={directoryUser}
            showConfirmLinkUser={showConfirmLinkUser}
            setShowConfirmLinkUser={setShowConfirmLinkUser}
          />
        )
      }
      <div className="flex w-full">
        <div className="flex flex-column gap-3 text-base text-900 w-full">
          <DataTable
            dataKey="id"
            loading={isLoading}
            value={filteredUsers || []}
            sortField="firstName"
            sortOrder={1}
            className="mt-1"
            scrollable
          >
            <Column header="First Name" field="firstName" />
            <Column header="Last Name" field="lastName" sortable />
            <Column header="Email" field="email" />
            <Column header="Role" field="role" body={(user) => roleToOrganizationLabel(user.role, organization, { singular: true })} />
            {
              canViewSites && (
                <Column header="Sites" field="sites" body={sitesTemplate} />
              )
            }
            <Column header="Onboard" body={onboardButton} />
          </DataTable>
        </div>
      </div>
    </>
  )
}

export default PendingUsers
