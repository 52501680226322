import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Menu } from 'primereact/menu'
import { SelectButton } from 'primereact/selectbutton'
import { useCurrentOrganization } from '@components/App'
import { conditionTypes } from './config'

// Dropdown options for supported action types sorted alphabetically
const actionTypes = (organization) => [
  {
    code: 'ADMINISTER',
    displayText: 'Administer',
  }, {
    code: 'DO_NOT_ADMINISTER',
    displayText: 'Do Not Administer',
  }, {
    code: 'NO_CHANGE',
    displayText: 'Proceed As Usual',
  }, {
    code: 'NOTIFY_ADMIN',
    displayText: `Notify ${organization.adminLabelSingular}`,
  },
]

// Supported action condition types and their display text
const actionConditionTypes = {
  GREATER_THAN: 'greater than',
  GREATER_THAN_OR_EQUAL: 'greater than or equal to',
  LESS_THAN: 'less than',
  LESS_THAN_OR_EQUAL: 'less than or equal to',
  RANGE: 'range',
}

// TODO: This should be moved to a shared config as it will likely be used in other places
// Dropdown options for supported administration units sorted alphabetically
const administrationUnits = () => [
  { label: 'g', value: 'g' },
  { label: 'IU', value: 'IU' },
  { label: 'mg', value: 'mg' },
  { label: 'ml', value: 'ml' },
  { label: 'oz', value: 'oz' },
  { label: 'tsp', value: 'tsp' },
  { label: 'Units', value: 'Units' },
]

export default function ActionsTable({ actions, conditionType, setActions }) {
  const addActionMenu = useRef(null)
  const [metric, setMetric] = useState(null)
  const [unit, setUnit] = useState(null)
  const organization = useCurrentOrganization()

  // Reset the table when the condition type changes
  useEffect(() => {
    const newConditionType = conditionTypes.find((ct) => ct.code === conditionType)

    if (newConditionType) {
      setMetric(newConditionType.metrics?.[0])
      setUnit(newConditionType.units?.[0])
    }
  }, [conditionType])

  if (!conditionType) {
    return null
  }

  // Get the config for the selected condition type
  const conditionConfig = conditionTypes.find((ct) => ct.code === conditionType)

  const onAddAction = (actionConditionType) => {
    setActions([...actions, {
      // HACK: We need to generate a unique ID for each row in the DataTable.
      rowId: Math.floor(Math.random() * 1_000_000_000),
      action: 'ADMINISTER',
      administerQuantity: '',
      administerUnit: 'Units',
      conditionMetric: metric,
      conditionType: actionConditionType,
      conditionUnit: unit,
      note: '',
      quantityOne: '',
      quantityTwo: '',
    }])
  }

  const menuActions = [
    {
      label: 'From ... To ...',
      command: () => onAddAction('RANGE'),
    },
    {
      label: 'Greater Than',
      command: () => onAddAction('GREATER_THAN'),
    },
    {
      label: 'Greater Than or Equal To',
      command: () => onAddAction('GREATER_THAN_OR_EQUAL'),
    },
    {
      label: 'Less Than',
      command: () => onAddAction('LESS_THAN'),
    },
    {
      label: 'Less Than or Equal To',
      command: () => onAddAction('LESS_THAN_OR_EQUAL'),
    },
  ]

  const onFieldEdit = (rowData, props, field) => (e) => {
    const actionsCopy = [...actions]
    const actionCopy = actionsCopy[props.rowIndex]
    actionCopy[field] = e.target.value

    if (field === 'action') {
      if (e.target.value === 'ADMINISTER') {
        actionCopy.administerUnit = 'Units'
      } else {
        actionCopy.administerQuantity = null
        actionCopy.administerUnit = null
      }
    }

    setActions(actionsCopy)
  }

  let rangeHeader = `${conditionConfig.displayText} Range`

  if (conditionConfig.units.length === 1) {
    rangeHeader += ` (${unit})`
  }

  const rangeInputGroup = (rowData, props) => {
    if (rowData.conditionType === 'RANGE') {
      return (
        <div className="p-inputgroup flex-shrink-1">
          {conditionConfig.metrics.length > 1 && rowData.conditionMetric
            && <span className="p-inputgroup-addon">{rowData.conditionMetric}</span>}
          <span className="p-inputgroup-addon">from</span>
          <InputText
            keyfilter="num"
            onChange={onFieldEdit(rowData, props, 'quantityOne')}
            placeholder={rowData.conditionUnit}
            value={rowData.quantityOne || ''}
            style={{ minWidth: '60x' }}
          />
          <span className="p-inputgroup-addon">to</span>
          <InputText
            keyfilter="num"
            onChange={onFieldEdit(rowData, props, 'quantityTwo')}
            placeholder={rowData.conditionUnit}
            value={rowData.quantityTwo || ''}
            style={{ minWidth: '60x' }}
          />
          {conditionConfig.units.length > 1 && rowData.conditionUnit
            && <span className="p-inputgroup-addon">{rowData.conditionUnit}</span>}
        </div>
      )
    }

    if (rowData.conditionType in actionConditionTypes) {
      return (
        <div className="p-inputgroup flex-shrink-1">
          {conditionConfig.metrics.length > 1 && rowData.conditionMetric
            && <span className="p-inputgroup-addon">{rowData.conditionMetric}</span>}
          <span className="p-inputgroup-addon">{actionConditionTypes[rowData.conditionType]}</span>
          <InputText
            keyfilter="num"
            onChange={onFieldEdit(rowData, props, 'quantityOne')}
            placeholder={rowData.conditionUnit}
            value={rowData.quantityOne || ''}
            style={{ minWidth: '60x' }}
          />
          {conditionConfig.units.length > 1 && rowData.conditionUnit
            && <span className="p-inputgroup-addon">{rowData.conditionUnit}</span>}
        </div>
      )
    }

    return (<div>unsupported condition type</div>)
  }

  const actionInputGroup = (rowData, props) => (
    <div className="flex gap-2">
      <Dropdown
        onChange={onFieldEdit(rowData, props, 'action')}
        options={actionTypes(organization)}
        optionLabel="displayText"
        optionValue="code"
        placeholder="Select an Action"
        value={rowData.action}
        style={{ flex: 1 }}
      />
      {rowData.action === 'ADMINISTER' && (
        <div className="p-inputgroup flex-1">
          <InputText
            keyfilter="num"
            onChange={onFieldEdit(rowData, props, 'administerQuantity')}
            placeholder="Amount"
            value={rowData.administerQuantity || ''}
            style={{ flex: '1' }}
          />
          <Dropdown
            onChange={onFieldEdit(rowData, props, 'administerUnit')}
            options={administrationUnits()}
            optionLabel="label"
            optionValue="value"
            placeholder="Select a Unit"
            value={rowData.administerUnit}
            style={{ width: '112px', maxWidth: '116px', flexShrink: 0 }}
          />
        </div>
      )}
    </div>
  )

  const noteTextArea = (rowData, props) => (
    <div className="flex justify-content-center">
      <InputTextarea
        onChange={onFieldEdit(rowData, props, 'note')}
        value={rowData.note}
      />
    </div>
  )

  const deleteActionButton = (rowData, props) => (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    >
      <Button
        aria-label="Delete"
        className="p-button-danger p-button-text p-button-rounded"
        icon="pi pi-trash"
        onClick={() => setActions(actions.filter((_, index) => index !== props.rowIndex))}
        severity="danger"
      />
    </div>
  )

  const actionsTableHeader = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold">Actions</span>
      {conditionConfig.metrics.length > 1 && (
        <SelectButton
          onChange={(e) => setMetric(e.value)}
          options={conditionConfig.metrics}
          value={metric}
        />
      )}
      {conditionConfig.units.length > 1 && (
        <SelectButton
          onChange={(e) => setUnit(e.value)}
          options={conditionConfig.units}
          value={unit}
        />
      )}
      <Menu className="w-auto" id="popup_menu" model={menuActions} popup ref={addActionMenu} />
      <Button
        aria-controls="popup_menu"
        aria-haspopup
        className="p-button-sm ml-3"
        icon="pi pi-chevron-down"
        iconPos="right"
        label="Add Action"
        onClick={(event) => addActionMenu.current.toggle(event)}
      />
    </div>
  )

  return (
    <div className="col-12 flex flex-column gap-2 text-center">
      <DataTable
        dataKey="rowId"
        emptyMessage="Add actions to inform the caregiver of pharmacy instructions."
        header={actionsTableHeader}
        value={actions}
        showGridlines
      >
        <Column body={rangeInputGroup} header={rangeHeader} style={{ width: '31%' }} sortable />
        <Column body={actionInputGroup} header="Action" style={{ width: '43%' }} />
        <Column body={noteTextArea} header="Note" style={{ width: '20%' }} />
        <Column body={deleteActionButton} style={{ width: '6%' }} />
      </DataTable>
    </div>
  )
}
