import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

export const useDoseHoldAtTime = (doseId, time, statusMessage) => useQuery({
  queryKey: ['doseHoldAtTime', doseId, time],
  queryFn: () => ApiClient.get('/dose_holds/at_time', { params: { dose_id: doseId, time } }),
  onError: (error) => {
    if (statusMessage) displayErrorMessages(error, statusMessage)
  },
  enabled: !!doseId && !!time,
})

export default useDoseHoldAtTime
