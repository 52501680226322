import React, { useMemo, useRef, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { TabPanel, TabView } from 'primereact/tabview'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useCurrentOrganization } from '@components/App'
import UserInfoTableWithMatchIndicator from '@components/user/UserInfoTableWithMatchIndicator'
import { roleToOrganizationLabel } from '@services/utils'
import MatchLabel from '@components/misc/MatchLabel'
import CaregiverCreateForm from '@components/caregivers/CaregiverForm/CaregiverCreateForm'
import CreateAdmin from '../Admins/CreateAdmin'
import { useSSOLinkableUsers } from './ssoPendingUsersHooks'

function OnboardDialog({
  pendingUser,
  createNewUser,
  handleLinkUsers,
  onboardingInProgress,
  visible,
  setVisible,
  showConfirmLinkUser,
  setShowConfirmLinkUser,
}) {
  const statusMessageRef = useRef()
  const [selectedUserToLink, setSelectedUserToLink] = useState(null)
  const [tabIndex, setTabIndex] = useState(0)
  const {
    data: linkableUsers,
    isLoading: isLoadingLinkableUsers,
  } = useSSOLinkableUsers(statusMessageRef, pendingUser.id)

  const organization = useCurrentOrganization()

  const roleLabel = useMemo(
    () => roleToOrganizationLabel(pendingUser.role, organization, { singular: true }),
    [pendingUser.role, organization],
  )
  const matchTemplate = (fieldType, fieldValue) => (
    <MatchLabel
      referenceValue={fieldValue}
      comparedValue={pendingUser[fieldType]}
      displayValue={fieldValue || ''}
    />
  )

  const linkButton = (user) => (
    <Button
      icon="pi pi-link"
      className="p-button-sm p-button-outlined"
      label={`Link to this ${roleLabel}`}
      onClick={() => {
        setShowConfirmLinkUser(true)
        setSelectedUserToLink(user)
        setTabIndex(2)
      }}
    />
  )

  const onCloseDialog = () => {
    if (!visible) return

    setVisible(false)
    setShowConfirmLinkUser(false)
  }

  const getUsersToConfirmLink = () => {
    const users = [pendingUser, selectedUserToLink]
    return users.map((user) => ({
      ...user,
      type: user === pendingUser ? 'Pending User' : 'Existing User',
    }))
  }

  return (
    <Dialog
      header="Onboard User"
      visible={visible}
      style={{ width: '75vw', maxHeight: '75vh' }}
      onHide={onCloseDialog}
      className="overflow-hidden"
    >
      <div className="flex flex-column gap-3">
        <TabView activeIndex={tabIndex} onTabChange={(e) => setTabIndex(e.index)}>
          <TabPanel header={`Create ${roleLabel}`}>
            {
              pendingUser.role === 'site_admin' && (
                // TODO: Stop using CreateAdmin component and create and use SiteAdminCreateForm
                <CreateAdmin
                  createAdminMutation={createNewUser}
                  pendingUser={pendingUser}
                  shouldRenderHeader={false}
                  onboardingInProgress={onboardingInProgress}
                  isSsoOnboarding
                />
              )
            }
            {
              pendingUser.role === 'caregiver' && (
                <div className="w-6">
                  <CaregiverCreateForm
                    onSave={createNewUser}
                    saveLoading={onboardingInProgress}
                    submitButtonLabel="Onboard"
                    statusMessageRef={statusMessageRef}
                    initialValues={pendingUser}
                  />
                </div>
              )
            }
          </TabPanel>
          <TabPanel header="Existing Users">
            <DataTable
              value={[pendingUser]}
              showGridlines
              tableStyle={{ minWidth: '50rem' }}
              header="Pending User"
              className="sticky top-0 pb-2 bg-white z-5 mb-3"
              cellClassName={() => 'px-3'}
            >
              <Column field="firstName" header="First Name" />
              <Column field="lastName" header="Last Name" />
              <Column field="email" header="Email" />
              <Column field="role" header="Role" body={(user) => roleToOrganizationLabel(user.role, organization, { singular: true })} />
              <Column field="sites" header="Sites" body={(user) => user.sites?.map((site) => site.name).join(', ')} />
            </DataTable>
            <UserInfoTableWithMatchIndicator
              users={linkableUsers}
              loading={isLoadingLinkableUsers}
              organization={organization}
              title={linkableUsers.length > 0 ? `Potential Duplicate ${roleLabel} Found - Link to Existing ${roleLabel}`
                : `No Potential Duplicate ${roleLabel} Found - Create New ${roleLabel}`}
              linkButton={linkButton}
              matchTemplate={matchTemplate}
            />
          </TabPanel>
          {
            showConfirmLinkUser && (
              <TabPanel header="Confirm Link">
                <div className="p-2">
                  <span>Are you sure you want to link these two user?</span>
                  <div className="flex flex-column w-full gap-3 my-2">
                    <DataTable value={getUsersToConfirmLink()} showGridlines tableStyle={{ minWidth: '50rem' }}>
                      <Column field="type" header="Users" />
                      <Column field="firstName" header="First Name" />
                      <Column field="lastName" header="Last Name" />
                      <Column field="dateOfBirth" header="Date of Birth" />
                      <Column header="Birth Sex" body={(user) => organization.patientBirthSexOptions.find((option) => option.value === user.birthSex)?.label} />
                    </DataTable>
                  </div>
                  <div className="flex flex-row gap-2 justify-content-end">
                    <Button
                      label="Cancel"
                      icon="pi pi-times"
                      className="p-button-text p-button-sm"
                      onClick={onCloseDialog}
                    />
                    <Button
                      label="Confirm"
                      icon="pi pi-check"
                      className="p-button-sm"
                      loading={onboardingInProgress}
                      disabled={onboardingInProgress}
                      onClick={() => handleLinkUsers(selectedUserToLink)}
                    />
                  </div>
                </div>
              </TabPanel>
            )
          }
        </TabView>
      </div>
    </Dialog>
  )
}

export default OnboardDialog
