import React, { useRef, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { size } from 'lodash'
import { Button } from 'primereact/button'
import { useCurrentUser } from '@components/App'
import { Messages } from 'primereact/messages'
import { useUserDevices, useTestNotificationMutation } from './userDevicesHooks'
import { InputTextWithLabel } from '../index'

function devicesDialog({
  userId, devices, isLoading, dialogVisible, setDialogVisible,
}) {
  const statusMessage = useRef()
  const [notificationTitle, setNotificationTitle] = useState('Test Notification')
  const [notificationBody, setNotificationBody] = useState('This is a test notification')

  const {
    mutateAsync: sendTestNotification,
    isLoading: sendTestNotificationLoading,
  } = useTestNotificationMutation(statusMessage, userId)

  const sendTestNotificationButton = (label, deviceIds) => (
    <Button
      label={label}
      className="p-button-text p-button-sm"
      onClick={() => sendTestNotification({
        title: notificationTitle,
        body: notificationBody,
        deviceIds,
      })}
      loading={sendTestNotificationLoading}
    />
  )

  const contentForm = (
    <div className="flex flex-column gap-2">
      <InputTextWithLabel
        label="Title"
        value={notificationTitle}
        onChange={(e) => setNotificationTitle(e.target.value)}
      />
      <InputTextWithLabel
        label="Body"
        value={notificationBody}
        onChange={(e) => setNotificationBody(e.target.value)}
      />
    </div>
  )

  return (
    <Dialog
      header="Test Notification"
      visible={dialogVisible}
      style={{ width: '50vw' }}
      onHide={() => setDialogVisible(false)}
    >
      <div className="flex flex-column gap-2">
        <Messages ref={statusMessage} />
        {contentForm}
        <DataTable
          value={devices}
          isLoading={isLoading}
          className="mt-2"
          rowClassName="fadein"
        >
          <Column field="id" header="ID" />
          <Column field="deviceOs" header="OS" />
          <Column field="deviceOsVersion" header="OS Version" />
          <Column body={(row) => sendTestNotificationButton('Send', [row.id])} />
        </DataTable>
        <div className="flex flex-row justify-content-end mt-2">
          {sendTestNotificationButton('Send to All', devices.map((device) => device.id))}
        </div>
      </div>
    </Dialog>
  )
}

function NotificationTestButton({ userId }) {
  const currentUser = useCurrentUser()
  const isCurrentUserSuperAdmin = currentUser.role === 'superadmin'
  if (!isCurrentUserSuperAdmin) return null
  const statusMessage = useRef()
  const [dialogVisible, setDialogVisible] = useState(false)
  const {
    data: devices,
    isLoading,
  } = useUserDevices(userId, statusMessage)

  return (
    <>
      {devicesDialog({
        devices, isLoading, dialogVisible, setDialogVisible, userId,
      })}
      {
        !isLoading && size(devices) > 0 && (
          <Button
            label="Test Notification"
            className="p-button-text p-button-sm"
            onClick={() => setDialogVisible(true)}
          />
        )
      }
    </>
  )
}

export default NotificationTestButton
