import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '../../services/errorHandler'

export function useCaregiverAssignedPatients(caregiverId, siteId, statusMessage) {
  return useQuery({
    queryKey: ['caregiverPatients', caregiverId, siteId],
    queryFn: () => {
      const baseUrl = `/caregivers/${caregiverId}/patients`
      const url = siteId ? `${baseUrl}?site_id=${siteId}` : baseUrl
      return ApiClient.get(url)
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useAssignablePatientsQuery(caregiverId, siteId) {
  return useQuery({
    queryKey: ['assignablePatients', caregiverId, siteId],
    queryFn: () => {
      let url = `/caregivers/${caregiverId}/assignable_patients`
      if (siteId) {
        url = `${url}?site_id=${siteId}`
      }
      return ApiClient.get(url)
    },
  })
}

export function useUpdateClientAccessMutation(caregiverId, statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['updateClientAccess', caregiverId],
    mutationFn: ({ clientAccess, siteId }) => ApiClient.post(`/caregivers/${caregiverId}/update_site_access`, { clientAccess, siteId }),
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
    onSuccess: () => {
      queryClient.invalidateQueries('caregiverPatients')
    },
  })
}

export function useAssignPatientsMutation(caregiverId, statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['assignPatients', caregiverId],
    mutationFn: ({ patientIds, siteId }) => ApiClient.post(`/caregivers/${caregiverId}/replace_patient_assignments`, { patientIds, siteId }),
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
    onSuccess: () => {
      queryClient.invalidateQueries('caregiverPatients')
    },
  })
}
