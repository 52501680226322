// This data is duplicate from app/models/patient_allergy.rb model
// eslint-disable-next-line import/prefer-default-export
export const ALLERGEN_TYPE_LABELS = {
  AA: 'Animal Allergy',
  DA: 'Drug Allergy',
  EA: 'Environmental Allergy',
  FA: 'Food Allergy',
  LA: 'Pollen Allergy',
  MA: 'Miscellaneous Allergy',
  MC: 'Miscellaneous Contraindication',
  PA: 'Plant Allergy',
}
