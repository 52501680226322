import React, { useRef } from 'react'
import {
  useCreateMedicalConditionMutation,
  useDeleteMedicalConditionMutation,
  useMedicalConditionsQuery,
  useUpdateMedicalConditionMutation,
} from '@hooks/medicalConditions'
import MedicalConditionList from './MedicalConditionList'

function MedicalConditions({ patientId, doseId }) {
  const statusMessageRef = useRef()

  const {
    data: medicalConditions,
    isLoading,
  } = useMedicalConditionsQuery({ patientId, doseId, statusMessage: statusMessageRef })

  const {
    mutateAsync: createMutation,
  } = useCreateMedicalConditionMutation({ patientId, doseId, statusMessage: statusMessageRef })

  const {
    mutateAsync: updateMutation,
  } = useUpdateMedicalConditionMutation({ patientId, doseId, statusMessage: statusMessageRef })

  const {
    mutateAsync: deleteMutation,
  } = useDeleteMedicalConditionMutation({ patientId, doseId, statusMessage: statusMessageRef })

  return (
    <MedicalConditionList
      medicalConditions={medicalConditions}
      doseId={doseId}
      patientId={patientId}
      statusMessageRef={statusMessageRef}
      createMutation={createMutation}
      updateMutation={updateMutation}
      deleteMutation={deleteMutation}
      isLoading={isLoading}
    />
  )
}

export default MedicalConditions
