import React, { useState, useRef, useEffect } from 'react'
import { Toast } from 'primereact/toast'
import { withApp } from '@components/App'
import AuthForm from './authForm/AuthForm'
import SetNewPasswordForm from './setNewPasswordForm/SetNewPasswordForm'
import {
  useHandleLogin, useSetPasswordMutation,
} from './authHooks'
import { LoginProvider } from '../../context/AuthContext'
import './AuthPage.scss'

function AuthPage({ alertMessage }) {
  const [passwordSetRequired, setPasswordSetRequired] = useState(false)
  const [tempToken, setTempToken] = useState(null)
  const statusMessage = useRef(null)

  const {
    handleLoginWithUsernamePassword,
    handleRequestOtp,
    isLoggingIn,
    isRequestingOtp,
  } = useHandleLogin(statusMessage, setPasswordSetRequired, setTempToken)

  const {
    mutateAsync: setNewPassword,
    isLoading: isSettingNewPassword,
  } = useSetPasswordMutation(statusMessage)

  const handleSetNewPassword = async (password) => {
    await setNewPassword(
      { password: password.trim(), token: tempToken },
      {
        onSuccess: () => {
          window.location.href = '/'
        },
      },
    )
  }

  useEffect(() => {
    if (alertMessage) {
      statusMessage.current.clear()
      statusMessage.current.show([
        {
          severity: 'error',
          sticky: false,
          summary: alertMessage,
        },

      ])
    }
  }, [alertMessage])

  return (
    <LoginProvider>
      <div className="auth-page">
        <Toast ref={statusMessage} />
        <div className="center">
          <div className="card">
            <div className="card-left">
              <img className="h-30rem" alt="welcome" src="/welcome_stock_photo.jpg" id="welcome-image" />
            </div>
            <div className="card-right">
              {passwordSetRequired && (
                <SetNewPasswordForm
                  onSetNewPassword={handleSetNewPassword}
                  isLoading={isSettingNewPassword}
                />
              )}
              {!passwordSetRequired && (
                <AuthForm
                  onUsernamePasswordLogin={handleLoginWithUsernamePassword}
                  onEmailLogin={handleRequestOtp}
                  isLoading={isLoggingIn || isRequestingOtp}
                  statusMessage={statusMessage}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </LoginProvider>
  )
}

export default withApp(AuthPage)
