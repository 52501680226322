import React from 'react'

function PageHeader({ isCreateOrganization }) {
  return (
    <div className="flex flex-row align-items-center px-3 py-4 gap-3 w-full h-4rem bg-white mb-3 shadow-1">
      {isCreateOrganization
        ? (
          <>
            <i className="pi pi-plus-circle text-3xl" />
            <span className="text-xl font-medium text-gray-600">Create Organization</span>
          </>
        )
        : (
          <>
            <i className="pi pi-cog text-3xl" />
            <span className="text-xl font-medium text-gray-600">Settings</span>
          </>
        )}
    </div>
  )
}

export default PageHeader
