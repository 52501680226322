import React, { useState } from 'react'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'

function InputTextEditor({ text, setText, isLoading }) {
  const [inputText, setInputText] = useState(text)

  const handleConfirmation = () => {
    setText(inputText)
  }

  return (
    <div className="flex flex-row gap-2 align-items-center">
      <InputText
        value={inputText}
        onChange={({ target: { value } }) => setInputText(value)}
      />
      <Button
        onClick={handleConfirmation}
        loading={isLoading}
        icon="pi pi-check"
      />
    </div>
  )
}

export default InputTextEditor
