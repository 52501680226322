import { useMemo } from 'react'
import { get, map } from 'lodash'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

export function useTrackableActivities({ dateRange, siteIds, statusMessage }) {
  const query = useQuery({
    queryKey: ['trackableActivities', dateRange, siteIds],
    queryFn: () => {
      const [startAt, endAt] = dateRange
      const params = { startDate: startAt, endDate: endAt, siteIds }
      return ApiClient.get('/trackable_activities/site_activity_feed', { params })
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })

  return useMemo(() => ({
    ...query,
    data: map(get(query, 'data.trackableActivities'), (activity) => (activity)),
  }), [query])
}

export function useUpdateTrackableActivity(statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['useUpdateTrackableActivity'],
    mutationFn: (activity) => ApiClient.put(`/trackable_activities/${activity.id}`, { activity }),
    onSuccess: (data) => {
      statusMessage.current.show([
        {
          severity: 'success',
          summary: `Activity ${get(data, 'acknowledged.at') ? 'completed' : 'is pending review'}!`,
        },
      ])
      queryClient.invalidateQueries('trackableActivities')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}
