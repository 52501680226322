import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import {
  displayErrorMessages,
} from '@services/errorHandler'

export function useSignature({ userId, enabled, statusMessage }) {
  return useQuery({
    queryKey: ['userSignature', userId],
    queryFn: () => ApiClient.get('/signatures/current_for_user'),
    enabled,
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useCreateSignature(userId, statusMessage) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['createSignature'],
    mutationFn: (svg) => ApiClient.post('/signatures', { userId, signature: { svg } }),
    onSuccess: () => {
      queryClient.invalidateQueries('userSignature')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}
