import React, { useState } from 'react'
import useCountdown from '@services/hooks/useCountdown'
import { formatTimeLeft } from '@services/utils/time'
import MedsDueNow from './MedsDueNow'

function MedsDueLater({
  totalCount, minAllowedAt, minExpectedAt, minExpiresAt,
}) {
  const [isTimeUp, setIsTimeUp] = useState(false)

  const timeLeft = useCountdown(minAllowedAt, {
    countdownInterval: 60_000,
    onComplete: () => {
      setIsTimeUp(true)
    },
  })

  if (isTimeUp || timeLeft.isTimeUp()) {
    // This means the med pass started and we should refresh the row
    // or entire table. For now we can do a sorta correct DueNow thing
    return (
      <MedsDueNow
        totalCount={totalCount}
        unconfirmedCount={totalCount}
        minExpectedAt={minExpectedAt}
        minExpiresAt={minExpiresAt}
      />
    )
  }

  return (
    <div className="flex align-items-center">
      <i style={{ fontSize: 32, color: 'lightgray' }} className="fa-regular fa-clock" />
      <div className="ml-2">
        {formatTimeLeft(timeLeft, {
          includeSeconds: false,
        })}
      </div>
    </div>
  )
}

export default MedsDueLater
