import React from 'react'
import { sortBy } from 'lodash'
import { Dropdown } from 'primereact/dropdown'

function SiteFilterDropdown({ sites, value, handleSiteChange }) {
  const nonEmptySites = sites.filter((site) => !!site)
  const sortedSites = sortBy(nonEmptySites)

  return (
    <Dropdown
      value={value}
      options={sortedSites}
      onChange={handleSiteChange}
      showClear
      placeholder="Select a Site"
      className="p-column-filter"
    />
  )
}

export default SiteFilterDropdown
