import React from 'react'
import { find } from 'lodash'
import { useParams } from 'react-router-dom'
import ReviewDoseOrder from '@components/pharmacyOrders/ReviewDoseOrder'
import { ReviewableOrderProvider } from './reviewableOrderState/ReviewableOrderContext'
import useReportSentryError from '../../../hooks/useReportSentryError'

// TODO: Give this component a better name
function ReviewDoseOrders({ reviewableOrders, statusMessage, isLoading }) {
  // The reviewable orders API returns all of the patient's reviewable orders. Therefore,
  // we need to find the specific reviewable order in the response that matches the current
  // route's groupKey which was set when the user clicked on a specific order in the review list.
  const { groupKey, patientId } = useParams()
  const reviewableOrder = find(reviewableOrders, (order) => order.groupKey === groupKey)

  const reportSentryError = useReportSentryError([groupKey])

  if (isLoading) {
    return null
  }

  if (!reviewableOrder) {
    reportSentryError(`Reviewable order not found for groupKey: ${groupKey}`)
    return (<div>Order not found</div>)
  }

  return (
    <ReviewableOrderProvider reviewableOrder={reviewableOrder}>
      <ReviewDoseOrder
        patientId={patientId}
        reviewableOrder={reviewableOrder}
        statusMessage={statusMessage}
      />
    </ReviewableOrderProvider>
  )
}

export default ReviewDoseOrders
