import React, { useRef } from 'react'
import { Toast } from 'primereact/toast'
import SuperAdminCard from '@components/display/SuperAdminCard'
import DoseFillsTable from '@components/doseFills/DoseFillsTable'
import PatientIntegrationData from '@components/patients/Profile/PatientIntegrationData'
import PrescriptionsTable from '@components/prescriptions/PrescriptionsTable'
import { ProfileCard } from '.'
import PatientAllergies from '../PatientAllergies/PatientAllergies'
import LOAHistory from '../LOAHistory'

function ProfileOverview({
  patient,
  isLoading,
  isCurrentUserAdmin,
  rootPath,
}) {
  const statusMessage = useRef()
  return (
    <>
      <Toast ref={statusMessage} />
      <div className="flex flex-column gap-2">
        <div className="flex flex-wrap gap-2">
          <ProfileCard
            className="flex-1 min-w-full md:min-w-0"
            patient={patient}
            rootPath={rootPath}
            isLoading={isLoading}
            isCurrentUserAdmin={isCurrentUserAdmin}
            statusMessage={statusMessage}
          />
          <PatientAllergies
            className="flex-1 min-w-full md:min-w-0"
            patient={patient}
            isLoading={isLoading}
          />
        </div>
        <LOAHistory
          patient={patient}
          patientLoading={isLoading}
          statusMessage={statusMessage}
        />
        {
          patient && (
            <>
              <SuperAdminCard title="Prescriptions">
                <PrescriptionsTable model="Patient" id={patient.id} />
              </SuperAdminCard>
              <SuperAdminCard title="Dispensed Fills">
                <DoseFillsTable model="Patient" id={patient.id} status="all" />
              </SuperAdminCard>
              <SuperAdminCard title="Integration Data - No Pharmacy Orders">
                <PatientIntegrationData patientId={patient.id} />
              </SuperAdminCard>
            </>
          )
        }
      </div>
    </>
  )
}

export default ProfileOverview
