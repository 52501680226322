import React, { useEffect, useState } from 'react'
import {
  mapValues, keyBy, some, isEqual,
} from 'lodash'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Tag } from 'primereact/tag'
import { Checkbox } from 'primereact/checkbox'
import { isElementInViewport } from '@services/utils'
import DoseFillsList from './DoseFillsList'
import InventoryCell from '../InventoryCell'
import MedicineCell from '../MedicineCell'
import DoseFillsCell from '../DoseFillsCell'

function DoseList({
  doses,
  isLoading,
  page,
  totalRecords,
  sort = '',
  doseStatusFilters,
  handleDoseFocus,
  handleFillStatusFilterChange,
  handleSortingChange,
  handlePageChange,
  maxDataViewHeight,
}) {
  const [selectedDoses, setSelectedDoses] = useState([])
  const expandedDoses = mapValues(keyBy(selectedDoses, 'id'), () => true)
  const [sortField, sortDirection] = sort.split(' ')
  const sortOrder = sortDirection === 'ASC' ? 1 : -1

  const doseFillsTemplate = (dose) => (
    <DoseFillsList
      dose={dose}
      handleDoseFocus={handleDoseFocus}
    />
  )

  useEffect(() => {
    const tableWrapper = document.querySelector(
      '#ekit-dose-list > .p-datatable-wrapper',
    )
    const onScroll = () => {
      const lastRowElement = tableWrapper.querySelector(
        `.ekit-dose-table > tbody > tr:nth-child(${(page + 1) * 100 - 25})`,
      )

      if (lastRowElement && isElementInViewport(lastRowElement)) {
        tableWrapper?.removeEventListener('scroll', onScroll)
        handlePageChange(page + 1)
      }
    }

    if (!isLoading) {
      tableWrapper?.addEventListener('scroll', onScroll)
    }

    return () => tableWrapper?.removeEventListener('scroll', onScroll)
  }, [page, sort, isLoading])

  const fillStatusFilterTemplate = () => {
    const options = [
      { label: 'Pending', value: 'pending', color: 'var(--orange-300)' },
      { label: 'Loaded', value: 'loaded', color: 'var(--green-300)' },
      {
        label: 'No Inventory',
        value: 'no_inventory',
        color: 'var(--bluegray-200)',
      },
    ]

    const handleFilterChange = (event) => {
      const selected = [...doseStatusFilters]

      if (event.checked) {
        selected.push(event.value)
      } else {
        selected.splice(selected.indexOf(event.value), 1)
      }

      handleFillStatusFilterChange(selected)
    }

    const itemTemplate = (option) => (
      <div className="flex align-items-center" key={option.label}>
        <Checkbox
          inputId={option.label}
          name={option.label}
          value={option.value}
          onChange={handleFilterChange}
          checked={some(doseStatusFilters, (o) => isEqual(o, option.value))}
        />
        <label htmlFor={option.label} className="ml-2">
          <Tag
            style={{ background: option.color }}
            className="text-small font-normal p-1"
            value={option.label}
          />
        </label>
      </div>
    )

    return (
      <div className="flex flex-column gap-2">{options.map(itemTemplate)}</div>
    )
  }

  return (
    <>
      <DataTable
        lazy
        id="ekit-dose-list"
        tableClassName="ekit-dose-table"
        dataKey="id"
        value={doses}
        onSort={handleSortingChange}
        sortField={sortField}
        sortOrder={sortOrder}
        filterDisplay="menu"
        selectionMode="multiple"
        selection={selectedDoses}
        onSelectionChange={(e) => setSelectedDoses(e.value)}
        metaKeySelection={false}
        expandedRows={expandedDoses}
        rowExpansionTemplate={doseFillsTemplate}
        className="mt-1"
        rowClassName="fadein"
        scrollable
        scrollHeight={maxDataViewHeight ? maxDataViewHeight - 10 : null}
      >
        <Column
          key="status"
          header="Fill Status"
          field="doseFills"
          filter
          filterElement={fillStatusFilterTemplate}
          showFilterMatchModes={false}
          showApplyButton={false}
          showClearButton={false}
          showAddButton={false}
          showFilterMenuOptions={false}
          style={{ wordBreak: 'break-all', minWidth: '6rem' }}
          body={DoseFillsCell}
        />
        <Column
          key="inventory"
          header="Inventory"
          field="doseFills"
          sortField="fills_left"
          sortable
          style={{ wordBreak: 'break-all', minWidth: '10rem' }}
          body={InventoryCell}
        />
        <Column
          key="medication"
          header="Medicine"
          field="medicineName"
          sortField="medicine.name"
          sortable
          style={{
            wordBreak: 'break-all',
          }}
          body={MedicineCell}
        />
        <Column
          key="site"
          header="Site"
          field="site.name"
          style={{ wordBreak: 'break-all', minWidth: '12rem' }}
        />
      </DataTable>
      {isLoading && (
        <span className="ml-2 text-sm flex flex-row gap-1 align-items-center">
          <i className="pi pi-spin pi-spinner" />
          Loading doses
          {' '}
          {page * 100}
          {' '}
          -
          {' '}
          {page * 100 + 100}
        </span>
      )}
      {!isLoading && (
        <span className="ml-2 text-sm">
          Total doses:
          {' '}
          {totalRecords}
        </span>
      )}
    </>
  )
}

export default DoseList
