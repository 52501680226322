import React, { useState } from 'react'
import { useCurrentOrganization } from '@components/App'
import { EventTimesList, EventTimeForm } from '@components/eventTimes'

function OrganizationEventTimes() {
  const [editEventTime, setEditEventTime] = useState(null)
  const organization = useCurrentOrganization()

  const [mode, setMode] = useState('list')

  const handleEdit = (eventTime) => {
    setEditEventTime(eventTime)
    setMode('edit')
  }

  const handleCreate = () => {
    setMode('create')
  }

  const handleUpsertSubmit = () => {
    setMode('list')
  }

  let content
  if (mode === 'list') {
    content = (
      <EventTimesList
        orgId={organization.id}
        handleEdit={handleEdit}
        handleCreate={handleCreate}
      />
    )
  } else if (mode === 'create') {
    content = (
      <EventTimeForm
        ownerType="Organization"
        ownerId={organization.id}
        onSubmit={handleUpsertSubmit}
      />
    )
  } else if (mode === 'edit') {
    content = (
      <EventTimeForm
        ownerType="Organization"
        ownerId={organization.id}
        onSubmit={handleUpsertSubmit}
        eventTimeId={editEventTime.id}
      />
    )
  }

  return content
}

export default OrganizationEventTimes
