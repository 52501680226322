import React, { useState, useRef } from 'react'
import { get, isEmpty } from 'lodash'
import { Toast } from 'primereact/toast'
import CardWithHeader from '@components/display/CardWithHeader'
import ActivityFeed from '@components/medManagement/ActivityFeed'
import ActivityFeedItem from './ActivityFeedItem'
import ActivityReviewDialog from './ActivityReviewDialog'
import { useUpdateTrackableActivity } from './pharmacyDashboardHooks'
import { getPatientNameFromActivity } from './utils'

function PendingReview({
  canConfirmActivities,
  organization,
  maxDataViewHeight,
  patientNameFilter,
  timeframe,
  trackableActivities,
  trackableActivitiesLoading,
}) {
  const statusMessage = useRef()
  const [view, setView] = useState('pending_review')
  const [
    acknowledgmentChangingForActivityId,
    setAcknowledgmentChangingForActivityId,
  ] = useState()
  const [activityReviewDialogId, setActivityReviewDialogId] = useState(null)

  const {
    isLoading,
    mutateAsync: changeActivityAcknowledgment,
  } = useUpdateTrackableActivity(statusMessage)

  const handleActivityAcknowledgmentChange = async (activity, acknowledge) => {
    setAcknowledgmentChangingForActivityId(activity.id)

    const updatedActivity = {
      id: activity.id,
      acknowledge,
    }

    await changeActivityAcknowledgment(updatedActivity)
    setAcknowledgmentChangingForActivityId(null)
  }

  const completedFeedItems = []
  const pendingReviewFeedItems = []

  trackableActivities.forEach((activity) => {
    if (patientNameFilter && !getPatientNameFromActivity(activity).toLowerCase()
      .includes(patientNameFilter.toLowerCase())) {
      return
    }

    const activityFeedItem = ActivityFeedItem({
      acknowledgmentChangingForActivityId,
      activity,
      canConfirmActivities,
      handleActivityAcknowledgmentChange,
      isLoading,
      organization,
      setActivityReviewDialogId,
    })

    if (activityFeedItem.isCompleted) {
      completedFeedItems.push(activityFeedItem)
    }

    if (activityFeedItem.isPendingReview) {
      pendingReviewFeedItems.push(activityFeedItem)
    }
  })

  const viewMenuItems = [
    {
      label: 'Pending Review',
      isActive: view === 'pending_review',
      command: () => setView('pending_review'),
    },
    {
      label: 'Complete',
      isActive: view === 'complete',
      command: () => setView('complete'),
    },
  ]

  const title = view === 'complete' ? 'Complete' : 'Pending Review'

  const noActivities = isEmpty(trackableActivities)
    || (view === 'pending_review' && isEmpty(pendingReviewFeedItems))
    || (view === 'complete' && isEmpty(completedFeedItems))

  return (
    <CardWithHeader title={title} menuItems={viewMenuItems}>
      <Toast ref={statusMessage} />
      {activityReviewDialogId !== null && (
        <ActivityReviewDialog
          activities={trackableActivities.filter((a) => get(a, 'trackable.id') === activityReviewDialogId)}
          canConfirmActivities={canConfirmActivities}
          onAcknowledge={(activity) => handleActivityAcknowledgmentChange(activity, true)}
          onHide={() => setActivityReviewDialogId(null)}
          organization={organization}
          visible
        />
      )}
      {noActivities && !trackableActivitiesLoading && (
        <div className="mt-3">
          No Activities for
          {' '}
          {timeframe}
        </div>
      )}
      {(!isEmpty(trackableActivities) || trackableActivitiesLoading) && (
        <ActivityFeed
          items={view === 'pending_review' ? pendingReviewFeedItems : completedFeedItems}
          loading={trackableActivitiesLoading}
          maxDataViewHeight={maxDataViewHeight}
        />
      )}
    </CardWithHeader>
  )
}

export default PendingReview
