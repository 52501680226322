import React from 'react'
import CheckboxWithLabel from '@components/display/CheckboxWithLabel'

function Settings({ settings, setSettings, loading }) {
  const {
    inventoryAlertsEnabled,
    trackableActivitiesEmailEnabled,
  } = settings
  return (
    <div className="flex flex-column">
      <CheckboxWithLabel
        key="inventoryAlertsEnabled"
        label="Inventory Alerts Enabled"
        checked={inventoryAlertsEnabled}
        inputId="inventoryAlertsEnabled"
        name="inventoryAlertsEnabled"
        onChange={({ value }) => {
          setSettings({ ...settings, inventoryAlertsEnabled: value })
        }}
        disabled={loading}
      />
      <CheckboxWithLabel
        key="trackableActivitiesEmailEnabled"
        label="Trackable Activities Email Enabled"
        checked={trackableActivitiesEmailEnabled}
        inputId="trackableActivitiesEmailEnabled"
        name="trackableActivitiesEmailEnabled"
        onChange={({ value }) => {
          setSettings({ ...settings, trackableActivitiesEmailEnabled: value })
        }}
        disabled={loading}
      />
    </div>
  )
}

export default Settings
