import { get } from 'lodash'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

const useUpsertSiteWifi = (siteId, statusMessage) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (wifi) => {
      const wifiId = get(wifi, 'siteWifi.id')
      if (wifiId) {
        return ApiClient.put(`/sites/${siteId}/wifis/${wifiId}`, wifi)
      }
      return ApiClient.post(`/sites/${siteId}/wifis`, wifi)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['siteWifi', siteId])
      statusMessage.current.clear()
      statusMessage.current.show([{
        severity: 'success',
        detail: 'Wi-Fi saved successfully',
        sticky: true,
      }])
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export default useUpsertSiteWifi
