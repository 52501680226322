import React from 'react'
import moment from 'moment'
import { Checkbox } from 'primereact/checkbox'
import { Dropdown } from 'primereact/dropdown'
import { useCurrentOrganization } from '@components/App'
import { useResponsibleUsers } from '@hooks/patients'
import { groupedUsersByRole, roleToOrganizationLabel } from '@services/utils'
import { momentFormats } from '@services/utils/moment'

const userOptionTemplate = (option, organization) => (
  <div>
    <span>{option.fullName}</span>
    <span className="text-xs ml-1 text-500">
      {roleToOrganizationLabel(option.role, organization, { singular: true }) || 'No Role'}
    </span>
    {
      option.currentUserActivation?.deactivatedAt && (
        <span className="text-xs ml-1 text-red-500">Deactivated</span>
      )
    }
  </div>
)

const userTemplate = (option, props, organization) => {
  if (option) {
    return userOptionTemplate(option, organization)
  }

  return <span>{props.placeholder}</span>
}

function UserDropdown({
  time,
  selectedUser,
  setSelectedUser,
  deactivatedUserAgreed,
  setDeactivatedUserAgreed,
  label,
  statusMessage,
  patientId,
}) {
  const organization = useCurrentOrganization()

  const {
    data: users,
    isLoading: usersLoading,
  } = useResponsibleUsers(
    {
      statusMessage,
      patientId,
      responsibleAt: time ? moment(time).format(momentFormats.iso8601_utc) : null,
    },
  )

  const user = users?.find((u) => u.id === selectedUser?.id)
  const needsDeactivateConfirmation = user && user.currentUserActivation?.deactivatedAt

  return (
    <div className="flex flex-column gap-2">
      <label className="block" htmlFor="adherence-dialog-form-user-dropdown">{label}</label>
      <Dropdown
        id="adherence-dialog-form-user-dropdown"
        value={user}
        options={groupedUsersByRole(users, organization)}
        optionGroupLabel="label"
        optionGroupChildren="users"
        optionLabel="fullName"
        loading={usersLoading}
        onChange={(e) => setSelectedUser(e.value)}
        valueTemplate={(option, props) => userTemplate(option, props, organization)}
        itemTemplate={(option) => userOptionTemplate(option, organization)}
        placeholder="Select a name"
        className="w-full text-sm"
        filter
        showFilterClear
      />
      {
        needsDeactivateConfirmation && (
          <div className="flex align-items-center">
            <Checkbox
              inputId="adherence-dialog-form-deactivated-user-agreed-checkbox"
              checked={deactivatedUserAgreed}
              onChange={(e) => setDeactivatedUserAgreed(e.checked)}
              className="text-xs"
            />
            <label htmlFor="adherence-dialog-form-deactivated-user-agreed-checkbox" className="ml-2">
              I understand that this user is deactivated
            </label>
          </div>
        )
      }
    </div>
  )
}

export default UserDropdown
