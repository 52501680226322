import React, { useState, useEffect } from 'react'
import { map, uniqBy } from 'lodash'
import { AutoComplete } from 'primereact/autocomplete'
import { Button } from 'primereact/button'

function MedicineName({
  medicineName, setMedicineName, setMedicineSearchTerm, rxTermSearchResults, handleConfirm,
}) {
  const [filteredMedicineNames, setFilteredMedicineNames] = useState([])

  useEffect(() => {
    const names = map(uniqBy(rxTermSearchResults, 'displayName'), ({ displayName }) => displayName)
    setFilteredMedicineNames(names)
  }, [rxTermSearchResults])

  return (
    <div className="grid flex flex-grow-1 justify-content-center align-items-center fadein">
      <div className="col-9 flex flex-column gap-2 text-center">
        <label className="text-lg font-normal mb-1" htmlFor="dose-medicine-name">
          What is the name of the medication?
        </label>
        <AutoComplete
          inputId="dose-medicine-name"
          value={medicineName}
          data-testid="medicine-name"
          completeMethod={(e) => setMedicineSearchTerm(e.query)}
          suggestions={filteredMedicineNames}
          dropdown
          autoFocus
          delay={1000}
          onChange={(e) => setMedicineName(e.value)}
          aria-label="Medicine Name"
          dropdownarialabel="Select Medicine Name"
        />
        <Button
          onClick={handleConfirm}
          label="Next"
          className="p-button-text mt-2"
          disabled={!medicineName}
        />
      </div>
    </div>
  )
}

export default MedicineName
