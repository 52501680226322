/**
 * This is the source of truth for the different types of dose conditions (a.k.a. dose criteria)
 * supported by the frontend. This configuration is used by several DoseCondition React components
 * to render the UI for each condition type in a generic and consistent way.
 * Each condition type is an object with the following properties:
 * - code: The code used by the backend and our APIs.
 * - measurementType: The class name of the ActiveRecord model for the measurement.
 * - displayText: The human-readable name that will be shown to the user.
 * - exampleNote: The placeholder text that will be shown as an example of a criteria note.
 * - methods: The methods of taking the measurement that are recorded for the condition type.
 * - metrics: The human-readable, measurement metrics that are recorded for the condition type.
 * - units: The persisted measurement units that are used for the condition type.
 * - unitLabels: The human-readable, measurement unit labels that are used for the condition type.
 * - values: The keys of the values that are recorded for the condition type.
 */
export const conditionTypes = [
  {
    code: 'BLOOD_GLUCOSE',
    measurementType: 'BloodGlucose',
    measurementKey: 'bloodGlucose',
    displayText: 'Blood Glucose',
    exampleNote: 'Example: Do not administer when blood glucose is below X mg/dL.',
    methods: [],
    metrics: ['blood glucose'],
    units: ['mg/dL'],
    unitLabels: ['mg/dL'],
    values: ['bloodGlucose.value'],
  },
  {
    code: 'BLOOD_OXYGEN',
    measurementType: 'BloodOxygen',
    measurementKey: 'bloodOxygen',
    displayText: 'Blood Oxygen',
    exampleNote: 'Example: Do not administer when blood oxygen saturation is below X%.',
    metrics: ['blood oxygen'],
    units: ['%'],
    unitLabels: ['%'],
    values: ['bloodOxygen.value'],
  },
  {
    code: 'BLOOD_PRESSURE',
    measurementType: 'BloodPressure',
    measurementKey: 'bloodPressure',
    displayText: 'Blood Pressure',
    exampleNote: 'Example: Do not administer med if systolic > X mmHg.',
    methods: [],
    metrics: ['systolic', 'diastolic'],
    units: ['mmHg'],
    unitLabels: ['mmHg'],
    values: ['bloodPressure.systolic', 'bloodPressure.diastolic'],
  },
  {
    code: 'HEIGHT',
    measurementType: 'Height',
    measurementKey: 'height',
    displayText: 'Height',
    exampleNote: 'Example: Do not administer med if height > X ft Y in.',
    methods: [],
    metrics: ['height'],
    units: ['cm', 'in'],
    unitLabels: ['cm', 'in'],
    values: ['height.value'],
  },
  {
    code: 'PULSE',
    measurementType: 'Pulse',
    measurementKey: 'pulse',
    displayText: 'Pulse',
    exampleNote: 'Example: Do not administer med if pulse > X bpm.',
    methods: [],
    metrics: ['pulse'],
    units: ['bpm'],
    unitLabels: ['bpm'],
    values: ['pulse.bpm'],
  },
  {
    code: 'TEMPERATURE',
    measurementType: 'Temperature',
    measurementKey: 'temperature',
    displayText: 'Temperature',
    exampleNote: 'Example: Do not administer med if temperature > X °F.',
    methods: ['axillary', 'oral', 'rectal', 'ear', 'skin'],
    metrics: ['temperature'],
    units: ['fahrenheit', 'celsius'],
    unitLabels: ['°F', '°C'],
    values: ['temperature.value'],
  },
  {
    code: 'WEIGHT',
    measurementType: 'Weight',
    measurementKey: 'weight',
    displayText: 'Weight',
    exampleNote: 'Example: Do not administer med if weight > X lb.',
    methods: [],
    metrics: ['weight'],
    units: ['lb', 'kg'],
    unitLabels: ['lb', 'kg'],
    values: ['weight.value'],
  },
]

export default conditionTypes
