import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'primereact/button'

function MatchNotification({ rootPath, patientId, organization }) {
  const navigate = useNavigate()

  const handleViewMatch = () => {
    navigate(`${rootPath}/${patientId}/matching_users`)
  }

  return (
    <div className="d-flex flex-1 align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <span className="pi pi-info-circle mr-2 text-2xl text-blue-600" />
        <span className="text-base text-blue-600 font-normal">
          We found a matching user for this
          {' '}
          {organization.patientLabelSingular.toLowerCase()}
          !
        </span>
      </div>
      <Button
        label="View"
        onClick={handleViewMatch}
        icon="pi pi-arrow-right"
        iconPos="right"
        className="p-button-outlined p-button-sm h-2rem w-9rem white-space-nowrap"
      />
    </div>
  )
}

export default MatchNotification
