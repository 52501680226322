import React, { useState, useRef, useEffect } from 'react'
import { get } from 'lodash'
import { Divider } from 'primereact/divider'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Messages } from 'primereact/messages'
import { InputNumber } from 'primereact/inputnumber'
import { useCreatePendingDoseFill } from './prepareDoseRefillHooks'
import { dateTemplate } from '../../../services/utils'
import { useLastUsedDoseFillQuery } from '../DoseAssignment/doseAssignmentHooks'

function PrepareDoseRefill({ dose, onSuccess }) {
  const today = new Date()
  const thrityDaysFromNow = new Date(today.setDate(new Date().getDate() + 30))

  const statusMessage = useRef(null)
  const [dosesInFill, setDosesInFill] = useState()
  const [expiresAt, setExpiresAt] = useState(thrityDaysFromNow)

  const {
    isLoading,
    mutateAsync: createPendingDoseFill,
  } = useCreatePendingDoseFill(dose.id, statusMessage)

  const {
    data: lastUsedDoseFill,
    isLoading: lastUsedDoseFillQueryLoading,
  } = useLastUsedDoseFillQuery(get(dose, 'id'))

  const handleRefill = async () => {
    await createPendingDoseFill({ dosesInFill, expiresAt, pharmacyDispensedAt: new Date() })
    onSuccess()
  }

  useEffect(() => {
    if (lastUsedDoseFill) {
      setDosesInFill(lastUsedDoseFill.dosesInFill)
    }
  }, [lastUsedDoseFill])

  return (
    <>
      <Messages ref={statusMessage} />
      <div className="flex flex-column gap-2" data-testid="prepare-dose-refill">
        <label className="text-lg font-normal mb-1" htmlFor="dosesInFill">
          Doses in Fill
        </label>
        <InputNumber
          inputId="dosesInFill"
          inputClassName="py-4"
          disabled={lastUsedDoseFillQueryLoading}
          value={dosesInFill}
          onChange={(e) => setDosesInFill(e.value)}
        />
        <label className="text-lg font-normal mb-1" htmlFor="expiresAt">
          Expiration Date
        </label>
        <Calendar
          id="expiresAt"
          value={expiresAt}
          showIcon
          minDate={new Date()}
          onChange={(e) => setExpiresAt(e.value)}
        />
        <Divider />
        {expiresAt && dosesInFill
          && (
          <>
            <div className="flex flex-column gap-4">
              <div className="flex flex-column gap-2">
                <h5 className="text-base font-bold mb-2">Dose</h5>
                <span className="text-sm">
                  Name:
                  {' '}
                  {get(dose, 'medicineName')}
                </span>
                <span className="text-sm">
                  Strength:
                  {' '}
                  {get(dose, 'medicineStrength')}
                </span>
                <span className="text-sm">
                  Doses in fill:
                  {' '}
                  {dosesInFill}
                </span>
                <span className="text-sm">
                  Expiration Date:
                  {' '}
                  {dateTemplate(expiresAt)}
                </span>
              </div>
            </div>
            <Button
              className="mt-5"
              label="Confirm"
              loading={isLoading}
              onClick={handleRefill}
            />
          </>
          )}
      </div>
    </>
  )
}

export default PrepareDoseRefill
