import React, { useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { InputTextarea } from 'primereact/inputtextarea'
import {
  useUpdatePatientClinicalNoteMutation,
} from './clinicalNotesListHooks'

function UpdateClinicalNoteDialog({
  visible, setVisible, clinicalNote, statusMessage,
}) {
  const [note, setNote] = useState(clinicalNote.note)

  const {
    mutateAsync: updateMutation,
    isLoading,
  } = useUpdatePatientClinicalNoteMutation(statusMessage)

  const updateClinicalNote = async () => {
    await updateMutation(
      { ...clinicalNote, note },
      { onSuccess: () => setVisible(false) },
    )
    setVisible(false)
  }

  return (
    <Dialog
      header="Update Clinical Note"
      visible={visible}
      onHide={() => setVisible(false)}
      footer={(
        <div className="flex flex-row justify-end">
          <Button
            label="Cancel"
            className="p-button-text"
            onClick={() => setVisible(false)}
            disabled={isLoading}
          />
          <Button
            label="Update"
            className="p-button-primary"
            onClick={() => updateClinicalNote()}
            loading={isLoading}
          />
        </div>
      )}
    >
      <InputTextarea
        className="mt-2"
        rows={5}
        cols={30}
        value={note}
        onChange={(e) => setNote(e.target.value)}
        placeholder="Note"
        autoFocus
      />
    </Dialog>
  )
}

export default UpdateClinicalNoteDialog
