import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'primereact/button'
import { Skeleton } from 'primereact/skeleton'
import { Messages } from 'primereact/messages'
import AttributeCard, { Attribute } from '@components/display/AttributeCard'
import Block from '@components/display/Block'
import { dateTemplate } from '@services/utils'
import Shifts from './Shifts/Shifts'

function Details({ site, isLoading, showScimDetails = false }) {
  const title = { label: 'Details', icon: 'pi-user' }
  const {
    name, address, createdAt, isScimManaged, scimGroup,
  } = site || {}

  const valueTemplate = (value) => (
    isLoading ? <Skeleton className="inline-flex" width="5rem" height="1rem" /> : value
  )

  return (
    <AttributeCard title={title} direction="horizontal">
      {
        name && (
          <Attribute
            label="Name:"
            value={valueTemplate(name || 'Empty')}
          />
        )
      }
      {
        address && (
          <Attribute
            label="Address:"
            value={valueTemplate(address || 'Empty')}
          />
        )
      }
      <Attribute
        label="Created At:"
        value={valueTemplate(dateTemplate(createdAt))}
      />
      {
        showScimDetails && (
          <>
            <Attribute
              label="SCIM Management Enabled:"
              value={isScimManaged ? 'Yes' : 'No'}
            />
            <Attribute
              label="SCIM Group:"
              value={scimGroup || 'N/A'}
            />
          </>
        )
      }
    </AttributeCard>
  )
}

function SiteDashboard({
  siteId,
  site,
  siteLoading,
  handleSetHeader,
  isCurrentUserAdmin,
  canManageSsoForOrg = false,
}) {
  const statusMessage = useRef()

  const primaryAction = isCurrentUserAdmin && (
    <Link to={`/admin/sites/${siteId}/update`}>
      <Button className="p-button-sm" label="Edit Site" />
    </Link>
  )

  useEffect(() => handleSetHeader({ primaryAction }), [site])

  return (
    <div className="flex flex-row flex-wrap">
      <Messages ref={statusMessage} />
      <Block col={12}>
        <Details
          site={site}
          isLoading={siteLoading}
          showScimDetails={canManageSsoForOrg}
        />
      </Block>
      <Block col={12}>
        <Shifts site={site} isLoading={siteLoading} />
      </Block>
    </div>
  )
}

export default SiteDashboard
