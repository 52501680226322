import React from 'react'
import CustomTag from '@components/display/CustomTag'

export function TaskTypeTag({ task }) {
  if (!task) {
    return null
  }

  return <CustomTag color="var(--blue-400)" text={task.taskType} />
}

export default TaskTypeTag
