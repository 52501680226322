import React from 'react'
import { Routes, Route } from 'react-router-dom'
import PageLayout, { usePageOutlet } from '@components/display/PageLayout'
import { withApp } from '@components/App'
import ReportsTable from './ReportsTable'
import CreateReport from './CreateReport'

function Reports() {
  const useReportsHeader = () => usePageOutlet()

  return (
    <div className="reports">
      <Routes>
        <Route path="/admin/reports/*" element={<PageLayout rootPath="/admin/reports" rootLabel="Reports" />}>
          <Route index element={<ReportsTable useReportsHeader={useReportsHeader} />} />
          <Route path="create" element={<CreateReport useReportsHeader={useReportsHeader} />} />
        </Route>
      </Routes>
    </div>
  )
}

export default withApp(Reports)
