import React from 'react'
import { withApp, useCurrentUser, useCurrentOrganization } from '@components/App'
import { Route, Routes } from 'react-router-dom'
import PageLayout, { usePageOutlet } from '@components/display/PageLayout'
import InventoryTable from './InventoryTable'
import UpsertDose from './UpsertDose'
import './dashboard.scss'

function Dashboard() {
  const useInventoryManagementHeader = () => usePageOutlet()
  const currentOrganization = useCurrentOrganization()
  const currentUser = useCurrentUser()
  const isEkit = currentUser.role === 'pharmacist' || currentOrganization.ekitsEnabled

  return (
    <div className="inventory-management">
      <Routes>
        <Route
          path="/admin/inventory_management/*"
          element={(
            <PageLayout
              rootPath="/admin/inventory_management"
              rootLabel={`${isEkit ? 'E-KIT' : 'MedBox'} Inventory`}
            />
          )}
        >
          <Route
            index
            element={<InventoryTable useInventoryManagementHeader={useInventoryManagementHeader} />}
          />
          <Route
            path="doses/create"
            element={<UpsertDose useInventoryManagementHeader={useInventoryManagementHeader} />}
          />
        </Route>
      </Routes>
    </div>
  )
}

export default withApp(Dashboard)
