import React from 'react'
import { startCase } from 'lodash'
import CustomTag from '@components/display/CustomTag'

function TreatmentStatusTag({ treatment }) {
  if (!treatment) {
    return null
  }

  // TODO: Color should be based on the status
  const color = 'var(--green-400)'
  const text = startCase(treatment.status)

  return <CustomTag color={color} text={text} />
}

export default TreatmentStatusTag
