import { useMemo } from 'react'
import { map, get } from 'lodash'
import { useQuery, useMutation } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'

export function usePatientGuardians(patientId) {
  const query = useQuery({ queryKey: ['patientGuardians', patientId], queryFn: () => ApiClient.get(`/patients/${patientId}/guardians`) })

  return useMemo(() => ({
    ...query,
    data: map(get(query, 'data'), (guardian) => (guardian)),
  }), [query.data])
}

export function usePossiblePatientGuardians(patientId) {
  const query = useQuery({ queryKey: ['possiblePatientGuardians', patientId], queryFn: () => ApiClient.get(`/patients/${patientId}/possible_guardians`) })

  return useMemo(() => ({
    ...query,
    data: map(get(query, 'data'), (guardian) => (guardian)),
  }), [query.data])
}

export function usePatientGuardiansMutation(patientId) {
  return useMutation({ mutationFn: (guardianIds) => ApiClient.put(`/patients/${patientId}`, { guardianIds }) })
}
