import React, { useState } from 'react'
import { Tag } from 'primereact/tag'
import useCountdown from '@services/hooks/useCountdown'
import { formatTimeLeft } from '@services/utils/time'
import moment from 'moment'
import CircularMedPassProgress from './CircularMedPassProgress'

function calculateMedPassProgress(totalCount, unconfirmedCount) {
  return ((totalCount - unconfirmedCount) / totalCount) * 100
}

function calculateExpirationTimeProgress(timeUntilExpired) {
  let timeProgress = 0
  if (timeUntilExpired.days > 0 || timeUntilExpired.hours > 0) {
    timeProgress = 0
  } else {
    const secondsLeft = (timeUntilExpired.minutes * 60) + timeUntilExpired.seconds
    timeProgress = (secondsLeft / 3600) * 100
  }
  return timeProgress
}

function DueNowExpiredTag() {
  return (
    <Tag className="w-fit" style={{ background: 'var(--bluegray-300)' }}>
      <span className="text-xs font-normal">Expired</span>
    </Tag>
  )
}

// This is tricky since while we know the number of meds due now, we can't really compute
// the time left because each consumption could have a slightly different expected time and
// expiration. For this reason our time progress will show how much time is left before the first
// med expiration occurs.
function MedsDueNow({
  totalCount, unconfirmedCount, minExpectedAt, minExpiresAt,
}) {
  const [isNowExpected, setIsNowExpected] = useState(moment().isAfter(moment(minExpectedAt)))
  const [isNowExpired, setIsNowExpired] = useState(moment().isAfter(moment(minExpiresAt)))

  const timeUntilExpected = useCountdown(minExpectedAt, {
    countdownInterval: 1000,
    onComplete: () => {
      setIsNowExpected(true)
    },
  })

  const timeUntilExpired = useCountdown(minExpiresAt, {
    countdownInterval: 1000,
    onComplete: () => {
      setIsNowExpired(true)
    },
  })

  // TODO: https://trello.com/c/4IVpxZsB/3268-bust-react-query-cache-key-on-due-now-expire-but-debounce-the-api-request
  if (isNowExpired || timeUntilExpired.isTimeUp()) {
    return <DueNowExpiredTag />
  }

  const timeProgress = calculateExpirationTimeProgress(isNowExpected ? timeUntilExpired
    : timeUntilExpected)
  const medPassProgress = calculateMedPassProgress(totalCount, unconfirmedCount)

  return (
    <div className="flex">
      <CircularMedPassProgress
        fillProgress={medPassProgress}
        borderProgress={timeProgress}
        count={unconfirmedCount}
      />
      <div className="ml-2">
        <div>
          <strong>{timeUntilExpected.seconds > 0 ? 'Due In' : 'Expires In'}</strong>
        </div>
        <div>
          {formatTimeLeft(isNowExpected ? timeUntilExpired : timeUntilExpected, {
            includeSeconds: false,
          })}
        </div>
      </div>
    </div>
  )
}

export default MedsDueNow
