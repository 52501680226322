import React, { useEffect, useState } from 'react'
import { find, get, map } from 'lodash'
import { Card } from 'primereact/card'
import { Button } from 'primereact/button'
import { Divider } from 'primereact/divider'
import { classNames } from 'primereact/utils'

function CardHeader({ title, menuItems }) {
  const [activeItemLabel, setActiveItemLabel] = useState(
    get(find(menuItems, ({ isActive }) => isActive), 'label'),
  )

  useEffect(() => {
    const activeItem = find(menuItems, ({ label }) => label === activeItemLabel)
    if (activeItem) {
      activeItem.command()
    }
  }, [activeItemLabel])

  return (
    <div className="flex flex-row gap-2 justify-content-between align-items-center mb-3">
      <div className="ml-1 text-900 font-medium text-xl">{title}</div>
      <div className="flex flex-row gap-2">
        {map(menuItems, (item) => (
          <div key={item.label} className="flex flex-row justify-space-between align-items-center px-1">
            <div className="flex flex-row">
              <Button onClick={() => setActiveItemLabel(item.label)} className={classNames('flex flex-row align-items-baseline p-button-sm p-button-outlined', { 'p-button-text': item.label !== activeItemLabel })}>
                <span className={classNames({ 'text-600': item.label !== activeItemLabel })}>
                  {item.label}
                </span>
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

function CardWithHeader({ children, title, menuItems }) {
  return (
    <div className="col-12">
      <Card className="h-full">
        <CardHeader title={title} menuItems={menuItems} />
        <Divider className="mb-0" />
        {children}
      </Card>
    </div>
  )
}

export default CardWithHeader
