import React from 'react'
import { InputMask } from 'primereact/inputmask'
import InputTextWithLabel from '@components/display/InputTextWithLabel'
import { Dropdown } from 'primereact/dropdown'
import moment from 'moment'
import { useCurrentOrganization, useCurrentUser } from '@components/App'
import { InputTextarea } from 'primereact/inputtextarea'
import DatePickerWithLabel from '../../display/DatePickerWithLabel'

function GenderInput({ currentValue, onChange, options }) {
  return (
    <div className="field">
      <label className="block">
        Gender
      </label>
      <Dropdown
        value={currentValue}
        options={options}
        onChange={onChange}
        placeholder="Please select gender"
      />
    </div>
  )
}

function BirthSexInput({ currentValue, onChange, options }) {
  return (
    <div className="field">
      <label className="block">
        Birth Sex
      </label>
      <Dropdown
        value={currentValue}
        options={options}
        onChange={onChange}
        placeholder="Please select birth sex"
      />
    </div>
  )
}

function ProfileForm({ profile = {}, setProfile }) {
  const {
    id, initials,
    email, phone, firstName, lastName, customerId, roomNumber, dateOfBirth, gender, birthSex,
    ssn, medicaidIdNumber, mrn,
  } = profile

  const organization = useCurrentOrganization()
  const currentUser = useCurrentUser()
  const isSuperAdminOrSiteAdmin = ['superadmin', 'site_admin'].includes(currentUser.role)
  const canEditInitials = !!id

  return (
    <div className="flex flex-column">
      {
        (canEditInitials && isSuperAdminOrSiteAdmin) && (
          <InputTextWithLabel
            label="Initials"
            value={initials || ''}
            inputId="initials"
            name="initials"
            onChange={({ target: { value } }) => setProfile({ initials: value.toUpperCase() })}
          />
        )
      }
      <InputTextWithLabel
        label="Email"
        value={email || ''}
        inputId="email"
        name="email"
        onChange={({ target: { value } }) => setProfile({ email: value })}
      />
      <InputTextWithLabel
        label="Phone"
        value={phone || ''}
        inputId="phone"
        name="phone"
        onChange={({ target: { value } }) => setProfile({ phone: value })}
      />
      <div className="field">
        <label htmlFor="ssn" className="block">SSN</label>
        <InputMask
          autoClear={false}
          id="ssn"
          mask="999-99-9999"
          placeholder="999-99-9999"
          value={ssn || ''}
          onChange={({ target: { value } }) => setProfile({ ssn: value })}
        />
      </div>
      <InputTextWithLabel
        label="First Name"
        value={firstName || ''}
        inputId="firstName"
        name="firstName"
        onChange={({ target: { value } }) => setProfile({ firstName: value })}
      />
      <InputTextWithLabel
        label="Last Name"
        value={lastName || ''}
        inputId="lastName"
        name="lastName"
        onChange={({ target: { value } }) => setProfile({ lastName: value })}
      />
      <InputTextWithLabel
        label="Custom ID"
        value={customerId || ''}
        inputId="customerId"
        name="customerId"
        onChange={({ target: { value } }) => setProfile({ customerId: value })}
      />
      <InputTextWithLabel
        label="Room #"
        value={roomNumber || ''}
        inputId="roomNumber"
        name="roomNumber"
        onChange={({ target: { value } }) => setProfile({ roomNumber: value })}
      />
      <DatePickerWithLabel
        label="Date of Birth"
        value={dateOfBirth ? moment(dateOfBirth).toDate() : null}
        inputId="dateOfBirth"
        name="dateOfBirth"
        onChange={({ target: { value } }) => setProfile({ dateOfBirth: value })}
        minDate={moment().subtract(100, 'years').toDate()}
        maxDate={moment().toDate()}
      />
      <BirthSexInput
        currentValue={birthSex}
        options={organization.patientBirthSexOptions}
        onChange={({ target: { value } }) => setProfile({ birthSex: value })}
      />

      <GenderInput
        currentValue={gender}
        options={organization.patientGenderOptions}
        onChange={({ target: { value } }) => setProfile({ gender: value })}
      />
      <InputTextWithLabel
        label="Medicaid ID"
        inputId="medicaidIdNumber"
        value={medicaidIdNumber || ''}
        name="medicaidIdNumber"
        onChange={({ target: { value } }) => {
          setProfile({ ...profile, medicaidIdNumber: value })
        }}
      />
      <InputTextWithLabel
        label="Medical Record Number"
        inputId="mrn"
        value={mrn || ''}
        name="mrn"
        onChange={({ target: { value } }) => setProfile({ mrn: value })}
      />
      <div className="field">
        <label htmlFor="note" className="block">Note</label>
        <InputTextarea
          id="note"
          value={profile.note || ''}
          onChange={({ target: { value } }) => setProfile({ note: value })}
          rows={5}
          cols={30}
        />
      </div>
    </div>
  )
}

export default ProfileForm
