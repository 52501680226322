import { useMutation, useQuery } from '@tanstack/react-query'
import ApiClient from '../../services/ApiClient'
import {
  errorMessageFromError,
  formErrorsFromError,
} from '../../services/errorHandler'

const useLoginWithUsernamePassword = (statusMessage) => useMutation({
  mutationKey: ['loginWithUsernamePassword'],
  mutationFn: ({ username, password }) => ApiClient.post('/auth_by_username', {
    username,
    password,
  }),
  onError: (error) => {
    statusMessage.current.clear()
    statusMessage.current.show([
      errorMessageFromError(error),
      ...formErrorsFromError(error),
    ])
  },
  onSuccess: () => {
    statusMessage.current.clear()
  },
})

const useRequestOtpMutation = (statusMessage) => useMutation({
  mutationKey: ['requestOtp'],
  mutationFn: ({ email }) => ApiClient.post('/otp', {
    email,
    client: 'web',
  }),
  onError: (error) => {
    statusMessage.current.clear()
    statusMessage.current.show([
      errorMessageFromError(error),
      ...formErrorsFromError(error),
    ])
  },
})

const useSetPasswordMutation = (statusMessage) => useMutation({
  mutationKey: ['setPassword'],
  mutationFn: ({ password, token }) => ApiClient.post('/set_password', {
    password,
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }),
  onError: (error) => {
    statusMessage.current.clear()
    statusMessage.current.show([
      errorMessageFromError(error),
      ...formErrorsFromError(error),
    ])
  },
})

const useSSOLogin = (email, loginButtonClicked) => useQuery({
  queryKey: ['sso_auth_url', email, loginButtonClicked],
  queryFn: () => ApiClient.get('/single_sign_on/login', { params: { email } }),
  enabled: loginButtonClicked && !!email,
  refetchOnWindowFocus: false,
})

function useHandleLogin(statusMessage, setPasswordSetRequired, setTempToken) {
  const {
    mutateAsync: loginWithUsernamePassword,
    isLoading: isLoggingIn,
  } = useLoginWithUsernamePassword(statusMessage)
  const {
    mutateAsync: requestOtp,
    isLoading: isRequestingOtp,
  } = useRequestOtpMutation(statusMessage)

  const handleLoginWithUsernamePassword = (username, password) => {
    loginWithUsernamePassword({ username: username.trim(), password: password.trim() }, {
      onSuccess: (response) => {
        if (response.isPasswordTemporary) {
          setPasswordSetRequired(true)
          setTempToken(response.token)
        } else {
          window.location.href = '/'
        }
      },
    })
  }

  const handleRequestOtp = async (email) => {
    await requestOtp({ email: email.trim() }, {
      onSuccess: () => {
        window.location.href = '/waiting'
      },
    })
  }

  return {
    handleLoginWithUsernamePassword,
    handleRequestOtp,
    isLoggingIn,
    isRequestingOtp,
  }
}

export {
  useLoginWithUsernamePassword,
  useRequestOtpMutation,
  useSetPasswordMutation,
  useHandleLogin,
  useSSOLogin,
}
