import React, { useState, useRef } from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'
import { useCreatePatientAllergy } from './patientAllergiesHooks'
import AllergenTypeDropdown from '../../display/Allergies/AllergenTypeDropdown'
import InputTextWithLabel from '../../display/InputTextWithLabel'

function CreatePatientAllergyDialog({
  patient,
  visible,
  onHide,
}) {
  const statusMessage = useRef(null)
  const [allergenType, setAllergenType] = useState()
  const [allergyText, setAllergyText] = useState()

  const {
    mutateAsync: createAllergy,
    isLoading: isCreatingAllergy,
  } = useCreatePatientAllergy(statusMessage)

  const onCreateAllergy = () => {
    const allergy = {
      patientId: patient.id,
      allergenType,
      text: allergyText,
    }
    createAllergy(
      allergy,
      {
        onSuccess: () => {
          onHide()
        },
      },
    )
  }

  const dialogHeaderTemplate = () => (
    <span className="font-semibold text-lg flex flex-column gap-2">
      Add Allergy
    </span>
  )

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      className="w-24rem"
      header={dialogHeaderTemplate}
    >
      <Toast ref={statusMessage} />
      <div className="flex flex-column gap-2 align-items-start">
        <div className="field flex flex-column w-12">
          <label className="block" htmlFor="allergen_type">Allergen Type:</label>
          <AllergenTypeDropdown
            value={allergenType}
            setValue={setAllergenType}
          />
        </div>
        <InputTextWithLabel
          label="Description"
          inputId="allergy_text"
          fieldContainerClassName="w-12"
          value={allergyText}
          name="allergy_text"
          onChange={(e) => setAllergyText(e.target.value)}
        />
        <Button
          label="Create"
          className="flex w-full p-button-sm w-12"
          loading={isCreatingAllergy}
          onClick={onCreateAllergy}
          disabled={!allergenType || !allergyText}
        />
      </div>
    </Dialog>
  )
}

export default CreatePatientAllergyDialog
