import React, { useEffect } from 'react'

import 'primereact/resources/themes/lara-light-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import './theme.scss'

const removeActiveAdminIds = () => {
  const activeAdminFooter = document.querySelector('#footer')
  activeAdminFooter?.remove()

  const activeAdminDiv = document.querySelector('#active_admin_content')
  activeAdminDiv?.removeAttribute('id')

  const mainContentDiv = document.querySelector('#main_content')
  mainContentDiv?.removeAttribute('id')

  return () => {
    activeAdminDiv?.addAttribute('id', '#active_admin_content')
    mainContentDiv?.addAttribute('id', '#main_content')
  }
}

function Theme({ children, disableActiveAdminStyles = true }) {
  useEffect(() => {
    if (disableActiveAdminStyles) {
      removeActiveAdminIds()
    }
  }, [disableActiveAdminStyles])

  return (
    <div>
      {' '}
      { children }
      {' '}
    </div>
  )
}

export default Theme
