import React from 'react'
import { Route, Routes } from 'react-router-dom'
import PageLayout from '@components/display/PageLayout'
import { withApp, useCurrentOrganization } from '@components/App'
import { ADMIN_PATH } from '../../config'
import Patient from './Patient'
import CreatePatient from './CreatePatient'
import './patients.scss'
import PatientsTable from './PatientsTable'

function Patients() {
  const organization = useCurrentOrganization()

  const rootPath = `${ADMIN_PATH}/patients`
  return (
    <div className="patients">
      <Routes>
        <Route path={`${rootPath}/*`} element={<PageLayout rootPath={rootPath} rootLabel={organization.patientLabelPlural} />}>
          <Route
            index
            element={<PatientsTable />}
          />
          <Route path="create" element={<CreatePatient />} />
          <Route path=":patientId/*" element={<Patient />} />
        </Route>
      </Routes>
    </div>
  )
}

export default withApp(Patients)
