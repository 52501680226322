import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { get, uniqBy } from 'lodash'
import moment from 'moment'
import { DataTable } from 'primereact/datatable'
import { useCurrentOrganization } from '@components/App'
import { Column } from '@components/display/Box/Box.stories'
import UserDirectoryServiceIcon from '@components/integrations/UserDirectoryServiceIcon'
import SiteFilterDropdown from '@components/sites/SiteFilterDropdown'
import { momentFormats } from '@services/utils/moment'
import { exactSearch } from '@services/utils/search'

function ActiveCaregivers({
  caregivers,
  isLoading,
  maxDataViewHeight,
  sitesOptions,
  searchTerm,
  setSearchLoading,
}) {
  const organization = useCurrentOrganization()
  const navigate = useNavigate()

  const [siteFilter, setSiteFilter] = useState('')
  const [filteredCaregivers, setFilteredCaregivers] = useState([])

  const canViewSites = organization.hasSites && !organization.deviceBasedAccess

  useEffect(() => {
    let siteFilteredCaregivers = uniqBy(caregivers, 'id')
    if (siteFilter) {
      siteFilteredCaregivers = caregivers.filter((caregiver) => (get(caregiver, 'sites') || [])
        .map((site) => site.name)
        .includes(siteFilter))
    }

    setSearchLoading(true)
    if (searchTerm) {
      setFilteredCaregivers(exactSearch(siteFilteredCaregivers, ['firstName', 'lastName'], searchTerm))
    } else {
      setFilteredCaregivers(siteFilteredCaregivers)
    }
    setSearchLoading(false)
  }, [caregivers, searchTerm, siteFilter, setSearchLoading])

  const siteDescriptionTemplate = (rowData) => {
    const sites = get(rowData, 'sites')
    if (!sites || sites.length === 0) {
      return 'No sites assigned'
    }
    if (sites.length === 1) {
      return sites[0].name
    }
    return `Assigned to ${sites.length} sites`
  }

  const formatLastActivity = ({ lastActivityAt }) => {
    const formattedValue = lastActivityAt ? moment(lastActivityAt).format(momentFormats.dateYear) : ''
    return <span>{formattedValue}</span>
  }

  const formatDirectorySync = ({ isDirectoryManaged }) => (
    isDirectoryManaged
      ? <UserDirectoryServiceIcon service="active-directory" tooltipPosition="left" />
      : null
  )

  return (
    <DataTable
      dataKey="id"
      loading={isLoading}
      value={filteredCaregivers}
      selectionMode="single"
      onSelectionChange={(e) => navigate(`/admin/caregivers/${e.value.id}`)}
      sortField="lastName"
      sortOrder={1}
      rowClassName="fadein caregiver-row"
      scrollable
      scrollHeight={maxDataViewHeight ? (maxDataViewHeight - 10) : null}
    >
      <Column header="First Name" field="firstName" sortable />
      <Column header="Last Name" field="lastName" sortable />
      <Column header="Last Activity" field="lastActivityAt" sortable body={formatLastActivity} />
      <Column header="Mobile App Version" field="mobileClientVersion" sortable />
      {
        canViewSites && (
          <Column
            header="Sites"
            field="sites"
            body={siteDescriptionTemplate}
            filter
            filterElement={(
              <SiteFilterDropdown
                sites={sitesOptions}
                value={siteFilter}
                handleSiteChange={(e) => {
                  setSiteFilter(e.value)
                  // This is a hack to close the filter menu after selecting a site
                  document.querySelector('.p-column-filter-menu-button-open').click()
                }}
              />
            )}
            showFilterMatchModes={false}
            showFilterMenuOptions={false}
            showApplyButton={false}
            showClearButton={false}
            filterMenuClassName="caregivers-site-filter"
          />
        )
      }
      <Column field="directorySync" body={formatDirectorySync} />
    </DataTable>
  )
}

export default ActiveCaregivers
