import { useRef, useEffect, useState } from 'react'

const useEllipsisTooltip = () => {
  const [isOverflowing, setIsOverflowing] = useState(false)
  const elementRef = useRef(null)

  const checkOverflow = () => {
    if (elementRef.current && elementRef.current.scrollWidth > elementRef.current.clientWidth) {
      setIsOverflowing(true)
    } else {
      setIsOverflowing(false)
    }
  }

  useEffect(() => {
    checkOverflow()
    window.addEventListener('resize', checkOverflow)
    return () => {
      window.removeEventListener('resize', checkOverflow)
    }
  }, [])

  useEffect(() => {
    checkOverflow()
  }, [elementRef])

  return [elementRef, isOverflowing]
}

export default useEllipsisTooltip
