import { useEffect, useState } from 'react'
import moment from 'moment'
import { calculateTimeLeft } from '@services/utils/time'

/**
 * A custom hook that provides a countdown timer.
 *
 * @param {Date|number} untilTime as a Date object or a timestamp in milliseconds.
 * @param {CountdownOptions} [options={}] - Optional settings for the countdown.
 * @param {function} [options.onComplete] - Optional callback function to
 * call when countdown completes.
 * @param {number} [options.countdownInterval=1000] - Optional interval for the countdown
 * in milliseconds, default to 1_000.
 * @returns {TimeLeft} The time left object.
 * @throws {Error} If untilTime is not provided.
 */
const useCountdown = (untilTime, options = {}) => {
  let untilTimeTimestamp = untilTime
  const { onComplete = () => {}, countdownInterval = 1_000 } = options

  if (!untilTime) {
    throw new Error('untilTime is required')
  }

  if (typeof untilTime === 'string') {
    untilTimeTimestamp = moment(untilTime).toDate().getTime()
  }

  if (untilTime instanceof Date) {
    untilTimeTimestamp = untilTime.getTime()
  }

  if (untilTime instanceof moment) {
    untilTimeTimestamp = untilTime.toDate().getTime()
  }

  const now = new Date()
  const [fromTime, setfromTime] = useState(now.getTime())
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(fromTime, untilTimeTimestamp))

  useEffect(() => {
    const interval = setInterval(() => {
      setfromTime((prevTime) => {
        const newFromTime = prevTime + countdownInterval
        const newTimeLeft = calculateTimeLeft(newFromTime, untilTimeTimestamp)

        setTimeLeft(newTimeLeft)

        if (Object.values(newTimeLeft).every((value) => value === 0)) {
          clearInterval(interval)
          onComplete()
        }

        return newFromTime
      })
    }, countdownInterval)

    return () => clearInterval(interval)
  }, [untilTime, onComplete, countdownInterval])

  return timeLeft
}

export default useCountdown
