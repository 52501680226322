import { dateTemplate } from '@services/utils'
import React from 'react'

function DoseFillInfo({ doseFill }) {
  return (
    <>
      <span className="text-sm">
        Doses in fill:
        {' '}
        {`${doseFill.fillsLeft} / ${doseFill.dosesInFill}`}
      </span>
      <span className="text-sm">
        Expiration:
        {' '}
        {dateTemplate(doseFill.expiresAt)}
      </span>
      {
        doseFill.agencyLoadedAt && (
          <span className="text-sm">
            Loaded:
            {' '}
            {dateTemplate(doseFill.agencyLoadedAt)}
          </span>
        )
      }
    </>
  )
}

export default DoseFillInfo
