import React, { useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { orderBy } from 'lodash'
import PatientsToLinkTable from './PatientsToLinkTable'

function OnboardExistingUser({
  pendingUser, onboardUserLoading, patients, isPatientsLoading, patientBirthSexOptions,
  patientRoleLabel, setShowUsersLinkConfirm, setPendingUserToLink, setPatientToLink, setTabIndex,
}) {
  const [patientsWithMatchScore, setPatientsWithMatchScore] = useState([])

  // add match score to each patient
  useEffect(() => {
    if (isPatientsLoading || onboardUserLoading) return

    setPatientsWithMatchScore(orderBy(patients, ['matchScore', 'lastName'], ['desc']))
  }, [patients, isPatientsLoading, onboardUserLoading])

  return (
    <div>
      <div className="flex flex-column gap-2">
        <DataTable
          value={[pendingUser]}
          showGridlines
          tableStyle={{ minWidth: '50rem' }}
          header="Pending User"
          className="sticky top-0 pb-2 bg-white z-5 mb-3"
          cellClassName={() => 'px-3'}
        >
          <Column field="firstName" header="First Name" />
          <Column field="lastName" header="Last Name" />
          <Column field="dateOfBirth" header="Date of Birth" />
          <Column
            field="birthSex"
            header="Birth Sex"
            body={
              patientBirthSexOptions.find(
                (option) => option.value === pendingUser.birthSex,
              )?.label
            }
          />
        </DataTable>
        <PatientsToLinkTable
          patients={patientsWithMatchScore}
          pendingUser={pendingUser}
          isLoading={isPatientsLoading || onboardUserLoading}
          patientRoleLabel={patientRoleLabel}
          patientBirthSexOptions={patientBirthSexOptions}
          setShowUsersLinkConfirm={setShowUsersLinkConfirm}
          setPendingUserToLink={setPendingUserToLink}
          setPatientToLink={setPatientToLink}
          setTabIndex={setTabIndex}
        />
      </div>
    </div>
  )
}

export default OnboardExistingUser
