import React, { useState, useEffect, useRef } from 'react'
import {
  compact, pick, isEqual, keys,
} from 'lodash'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import DetailsForm from '@components/pharmacies/PharmacyForm'
import FormWrapper from '@components/display/Form/FormWrapper'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import {
  usePharmacyUpdateMutation,
  usePharmacyDeleteMutation,
} from './pharmaciesHooks'

function UpdatePharmacy({
  isCurrentUserSuperadmin, rootPath, pharmacy, handleSetHeader,
}) {
  const navigate = useNavigate()

  if (!isCurrentUserSuperadmin) {
    navigate(rootPath)
  }

  const statusMessage = useRef(null)
  const { pharmacyId } = useParams()

  const [details, setDetails] = useState({
    name: '',
    externalId: '',
  })

  const {
    mutateAsync: updatePharmacy,
    isLoading: updateIsLoading,
  } = usePharmacyUpdateMutation(pharmacyId, statusMessage)

  const {
    mutateAsync: deletePharmacy,
  } = usePharmacyDeleteMutation(pharmacyId, statusMessage)

  const handleUpdateDetails = () => {
    updatePharmacy({ ...details })
  }

  const toggleConfirmDialog = () => {
    confirmDialog({
      message: 'Are you sure you want to delete this pharmacy?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => deletePharmacy(),
    })
  }

  const breadcrumbs = compact([
    {
      label: 'Pharmacy',
      template: <Link to={`${rootPath}/${pharmacyId}`}>Pharmacy</Link>,
    },
    {
      label: 'Edit',
      template: <span>Edit</span>,
    },
  ])

  const primaryAction = (
    <Button
      label="Delete Pharmacy"
      className="p-button-sm"
      icon="pi pi-trash"
      onClick={() => toggleConfirmDialog()}
    />
  )

  useEffect(() => {
    if (pharmacy) {
      setDetails({
        name: pharmacy.name,
        externalId: pharmacy.externalId,
      })
    }

    handleSetHeader({ breadcrumbs, primaryAction })
  }, [pharmacy])

  return (
    <div className="flex flex-row flex-wrap">
      <Toast ref={statusMessage} />
      <ConfirmDialog />
      <div className="xl:col-6 col-12">
        <FormWrapper title="Details" fullHeight={false}>
          <DetailsForm
            details={details}
            setDetails={(value) => setDetails((p) => ({ ...p, ...value }))}
          />
          <Button
            label="Save Details"
            className="p-button-sm mt-4"
            disabled={isEqual(details, pick(pharmacy, keys(details)))}
            loading={updateIsLoading}
            onClick={handleUpdateDetails}
          />
        </FormWrapper>
      </div>
    </div>
  )
}

export default UpdatePharmacy
