import React, { useState, useEffect, useRef } from 'react'
import {
  compact, pick, isEqual, keys,
} from 'lodash'
import { useParams, Link } from 'react-router-dom'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { ProfileForm, SettingsForm } from '@components/pharmacists/PharmacistForm'
import FormWrapper from '@components/display/Form/FormWrapper'
import { useCurrentUser } from '@components/App'
import {
  usePharmacistUpdateMutation,
} from './pharmacistsHooks'

function UpdatePharmacist({ pharmacist, handleSetHeader }) {
  const statusMessage = useRef(null)
  const { pharmacistId } = useParams()
  const currentUser = useCurrentUser()

  const [profile, setProfile] = useState({
    email: '',
    firstName: '',
    lastName: '',
  })
  const [settings, setSettings] = useState({ inventoryAlertsEnabled: false })

  const isCurrentUserAdmin = ['superadmin', 'site_admin'].includes(currentUser.role)

  const {
    mutateAsync: updatePharmacist,
    isLoading: updateIsLoading,
  } = usePharmacistUpdateMutation(pharmacistId, statusMessage)

  const handleUpdateProfile = () => {
    updatePharmacist({ ...profile }, {
      onSuccess: () => {
        statusMessage.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Profile updated',
        })
      },
    })
  }

  const handleUpdateSettings = (newSettings) => {
    updatePharmacist({ ...newSettings }, {
      onSuccess: () => {
        statusMessage.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Settings updated',
        })
      },
    })
  }
  const breadcrumbs = isCurrentUserAdmin ? compact([
    {
      label: 'Pharmacist',
      template: <Link to={`/admin/pharmacists/${pharmacistId}`}>Pharmacist</Link>,
    },
    {
      label: 'Edit',
      template: <span>Edit</span>,
    },
  ]) : []

  useEffect(() => {
    if (pharmacist) {
      setProfile({
        email: pharmacist.email,
        firstName: pharmacist.firstName,
        lastName: pharmacist.lastName,
      })
      setSettings({
        inventoryAlertsEnabled: pharmacist.inventoryAlertsEnabled,
        trackableActivitiesEmailEnabled: pharmacist.trackableActivitiesEmailEnabled,
      })
    }

    handleSetHeader({ breadcrumbs, primaryAction: null })
  }, [pharmacist])

  return (
    <div className="flex flex-row flex-wrap">
      <Toast ref={statusMessage} />
      <div className="xl:col-6 col-12">
        <FormWrapper title="Profile" fullHeight={false}>
          <ProfileForm
            profile={profile}
            setProfile={(value) => setProfile((p) => ({ ...p, ...value }))}
          />
          <Button
            label="Save Profile"
            className="p-button-sm mt-4"
            disabled={isEqual(profile, pick(pharmacist, keys(profile)))}
            loading={updateIsLoading}
            onClick={handleUpdateProfile}
          />
        </FormWrapper>
      </div>
      <div className="xl:col-6 col-12 flex flex-column gap-3">
        <FormWrapper title="Settings">
          <SettingsForm
            settings={settings}
            setSettings={
            (value) => {
              handleUpdateSettings(value)
            }
          }
            loading={updateIsLoading}
          />
        </FormWrapper>
      </div>
    </div>
  )
}

export default UpdatePharmacist
