import React, { useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { Fieldset } from 'primereact/fieldset'
import DoseConditionsTable from './DoseConditionsTable'
import DoseConditionDialog from './DoseConditionDialog/DoseConditionDialog'
import '../../../features/ClientDoses/dose-conditions.scss'

function DoseConditionsFieldset({
  doseSchedules,
  dose,
  scheduleStepComplete,
}) {
  const doseConditionStatusMessage = useRef(null)
  const [selectedDoseCondition, setSelectedDoseCondition] = useState(false)
  const [showCreateDoseConditionDialog, setShowCreateDoseConditionDialog] = useState(false)

  return (
    <div className="dose-conditions">
      <DoseConditionDialog
        doseSchedules={doseSchedules}
        dose={dose}
        doseConditionStatusMessage={doseConditionStatusMessage}
        selectedDoseCondition={selectedDoseCondition}
        setSelectedDoseCondition={setSelectedDoseCondition}
        showCreateDoseConditionDialog={showCreateDoseConditionDialog}
        setShowCreateDoseConditionDialog={setShowCreateDoseConditionDialog}
      />
      <Fieldset
        legend="Criteria / Vitals"
        pt={{
          content: {
            className: 'pt-0',
          },
        }}
      >
        {scheduleStepComplete && (
          <Button
            label="Add Criteria / Vitals"
            icon="fa-solid fa-ruler"
            className="flex p-button-sm p-button-info ml-auto mr-0"
            onClick={() => setShowCreateDoseConditionDialog(true)}
          />
        )}
        {scheduleStepComplete ? (
          <DoseConditionsTable
            dose={dose}
            setSelectedDoseCondition={setSelectedDoseCondition}
          />
        )
          : <div className="empty-conditions">Please select and save a medicine, dose and schedule first.</div>}
      </Fieldset>
    </div>
  )
}

export default DoseConditionsFieldset
