import React from 'react'
import { Divider } from 'primereact/divider'
import LoginFormHeader from './LoginFormHeader'
import LoginFields from './LoginFields'
import EulaCheckbox from './EulaCheckbox'
import ToggleLoginTypeLink from './ToggleLoginTypeLink'
import LoginWithSSOButton from './LoginWithSSOButton'
import LoginButton from './LoginButton'
import { useAuth } from '../../../context/AuthContext'

function AuthForm({
  onUsernamePasswordLogin, onEmailLogin, isLoading, statusMessage,
}) {
  const { loginType } = useAuth()

  return (
    <div id="login-form">
      <LoginFormHeader />
      <LoginFields isLoading={isLoading} />
      <EulaCheckbox />
      <LoginButton
        onUsernamePasswordLogin={onUsernamePasswordLogin}
        onEmailLogin={onEmailLogin}
        isLoading={isLoading}
      />
      <ToggleLoginTypeLink />
      {loginType === 'Email' && (
      <>
        <Divider>
          <span className="text-sm text-gray-400">OR</span>
        </Divider>
        <LoginWithSSOButton statusMessage={statusMessage} />
      </>
      )}
    </div>
  )
}

export default AuthForm
