import React from 'react'
import { isEmpty } from 'lodash'
import { Divider } from 'primereact/divider'
import SlotWithAssignment from './SlotWithAssignment'
import SlotWithoutAssignment from './SlotWithoutAssignment'

function DrawerSlots({
  slots, ekitId, siteId, drawerId,
}) {
  return (
    <div className="grid">
      <Divider align="center" className="mx-2">Drawer Back</Divider>
      {
        slots.map((slot) => {
          if (!isEmpty(slot.doseFills)) {
            return (
              <SlotWithAssignment
                key={slot.id}
                ekitId={ekitId}
                siteId={siteId}
                drawerId={drawerId}
                slot={slot}
              />
            )
          }
          return (
            <SlotWithoutAssignment
              key={slot.id}
              ekitId={ekitId}
              siteId={siteId}
              drawerId={drawerId}
              slot={slot}
            />
          )
        })
      }
      <Divider align="center" className="mx-2">Drawer Front</Divider>
    </div>
  )
}

export default DrawerSlots
