import React from 'react'
import SitesSelect from '../../../features/Selects/SitesSelect/SitesSelect'

function SiteForm({
  sites, selectedSites, setSelectedSites, isSitesLoading,
}) {
  return (
    <div className="flex flex-column">
      <SitesSelect
        sites={sites}
        loading={isSitesLoading}
        onChange={(newSites) => setSelectedSites(newSites)}
        singleSelection
        selectedSites={selectedSites}
        className="w-full"
        data-testid="site-select"
      />
    </div>
  )
}

export default SiteForm
