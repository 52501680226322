// Please keep this list up to date in ruby as well
// path: app/models/concerns/dose_constants.rb
const administrationRoutes = {
  AP: {
    name: 'Apply Externally',
  },
  B: {
    name: 'Buccal',
  },
  DT: {
    name: 'Dental',
  },
  EP: {
    name: 'Epidural',
  },
  ET: {
    name: 'Endotrachial Tube',
  },
  GTT: {
    name: 'Gastrostomy Tube',
  },
  GU: {
    name: 'GU Irrigant',
  },
  IA: {
    name: 'Intra-arterial',
  },
  IB: {
    name: 'Intrabursal',
  },
  IC: {
    name: 'Intracardiac',
  },
  ICV: {
    name: 'Intracervical (uterus)',
  },
  ID: {
    name: 'Intradermal',
  },
  IH: {
    name: 'Inhalation',
  },
  IHA: {
    name: 'Intrahepatic Artery',
  },
  IM: {
    name: 'Intramuscular',
  },
  IMR: {
    name: 'Immerse (Soak) Body Part',
  },
  IN: {
    name: 'Intranasal',
  },
  IO: {
    name: 'Intraocular',
  },
  IP: {
    name: 'Intraperitoneal',
  },
  IS: {
    name: 'Intrasynovial',
  },
  IT: {
    name: 'Intrathecal',
  },
  IU: {
    name: 'Intrauterine',
  },
  IV: {
    name: 'Intravenous',
  },
  MM: {
    name: 'Mucous Membrane',
  },
  MTH: {
    name: 'Mouth/Throat',
  },
  NG: {
    name: 'Nasogastric',
  },
  NP: {
    name: 'Nasal Prongs',
  },
  NS: {
    name: 'Nasal',
  },
  NT: {
    name: 'Nasotrachial Tube',
  },
  OP: {
    name: 'Ophthalmic',
  },
  OT: {
    name: 'Otic',
  },
  OTH: {
    name: 'Other/Miscellaneous',
  },
  PF: {
    name: 'Perfusion',
  },
  PO: {
    name: 'Oral',
  },
  PR: {
    name: 'Rectal',
  },
  RM: {
    name: 'Rebreather Mask',
  },
  SC: {
    name: 'Subcutaneous',
  },
  SD: {
    name: 'Soaked Dressing',
  },
  SL: {
    name: 'Sublingual',
  },
  TD: {
    name: 'Transdermal',
  },
  TL: {
    name: 'Translingual',
  },
  TP: {
    name: 'Topical',
  },
  TRA: {
    name: 'Tracheostomy',
  },
  UR: {
    name: 'Urethral',
  },
  VG: {
    name: 'Vaginal',
  },
  VM: {
    name: 'Ventimask',
  },
  WND: {
    name: 'Wound',
  },
}

const manuallyEnteredFrequency = [
  'Daily (QD, OD)',
  '2 x day (BID)',
  '3 x day (TID)',
  '4 x day (QID)',
  'As needed (PRN)',
  'Every other day',
  'Weekly',
  'Every 2 weeks',
  'Monthly',
  'Quarterly',
  'Every 6 months',
  'Annually',
  'Every 4 hours',
  'Every 6 hours',
  'Every 8 hours',
  'Every 12 hours',
  'Other',
]

// Pulled from RxNorm (https://rxnav.nlm.nih.gov/REST/allconcepts.json?tty=DF)
const defaultDoseForms = [
  'Auto-Injector',
  'Buccal Film',
  'Buccal Tablet',
  'Cartridge',
  'Chewable Extended Release Oral Tablet',
  'Chewable Tablet',
  'Chewing Gum',
  'Delayed Release Oral Capsule',
  'Delayed Release Oral Granules',
  'Delayed Release Oral Tablet',
  'Disintegrating Oral Tablet',
  'Douche',
  'Drug Implant',
  'Dry Powder Inhaler',
  'Effervescent Oral Tablet',
  'Enema',
  'Extended Release Oral Capsule',
  'Extended Release Oral Tablet',
  'Extended Release Suspension',
  'Gas for Inhalation',
  'Granules for Oral Solution',
  'Granules for Oral Suspension',
  'Inhalation Powder',
  'Inhalation Solution',
  'Inhalation Spray',
  'Inhalation Suspension',
  'Injectable Foam',
  'Injectable Solution',
  'Injectable Suspension',
  'Injection',
  'Intraperitoneal Solution',
  'Intratracheal Suspension',
  'Intrauterine System',
  'Irrigation Solution',
  'Jet Injector',
  'Medicated Bar Soap',
  'Medicated Liquid Soap',
  'Medicated Pad',
  'Medicated Patch',
  'Medicated Shampoo',
  'Medicated Tape',
  'Metered Dose Inhaler',
  'Metered Dose Nasal Spray',
  'Mouthwash',
  'Mucosal Spray',
  'Mucous Membrane Topical Solution',
  'Nasal Gel',
  'Nasal Inhalant',
  'Nasal Ointment',
  'Nasal Powder',
  'Nasal Solution',
  'Nasal Spray',
  'Ophthalmic Cream',
  'Ophthalmic Gel',
  'Ophthalmic Irrigation Solution',
  'Ophthalmic Ointment',
  'Ophthalmic Solution',
  'Ophthalmic Spray',
  'Ophthalmic Suspension',
  'Oral Capsule',
  'Oral Cream',
  'Oral Film',
  'Oral Flakes',
  'Oral Foam',
  'Oral Gel',
  'Oral Granules',
  'Oral Lozenge',
  'Oral Ointment',
  'Oral Paste',
  'Oral Pellet',
  'Oral Powder',
  'Oral Solution',
  'Oral Spray',
  'Oral Suspension',
  'Oral Tablet',
  'Oral Wafer',
  'Otic Ointment',
  'Otic Solution',
  'Otic Suspension',
  'Pack',
  'Paste',
  'Pen Injector',
  'Powder for Nasal Solution',
  'Powder for Oral Solution',
  'Powder for Oral Suspension',
  'Powder for Pyelocalyceal Solution',
  'Powder Spray',
  'Prefilled Syringe',
  'Rectal Cream',
  'Rectal Foam',
  'Rectal Gel',
  'Rectal Ointment',
  'Rectal Solution',
  'Rectal Spray',
  'Rectal Suppository',
  'Sublingual Film',
  'Sublingual Powder',
  'Sublingual Tablet',
  'Sustained Release Buccal Tablet',
  'Tablet for Oral Suspension',
  'Toothpaste',
  'Topical Cream',
  'Topical Foam',
  'Topical Gel',
  'Topical Liquefied Gas',
  'Topical Lotion',
  'Topical Oil',
  'Topical Ointment',
  'Topical Powder',
  'Topical Solution',
  'Topical Spray',
  'Topical Suspension',
  'Transdermal System',
  'Urethral Suppository',
  'Vaginal Cream',
  'Vaginal Film',
  'Vaginal Foam',
  'Vaginal Gel',
  'Vaginal Insert',
  'Vaginal Ointment',
  'Vaginal Sponge',
  'Vaginal System',
]

export {
  administrationRoutes,
  manuallyEnteredFrequency,
  defaultDoseForms,
}
