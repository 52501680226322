import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

const useUpsertConsumption = ({ consumptionId, chained = false, statusMessage }) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body) => {
      if (consumptionId) {
        return ApiClient.post(`/consumptions/${consumptionId}/override`, body)
      }

      return ApiClient.post('/consumptions', body)
    },
    onSuccess: async (response) => {
      if (chained) {
        return response
      }

      statusMessage.current.clear()
      statusMessage.current.show([
        { severity: 'success', summary: 'MAR Update Successful!' },
      ])

      await queryClient.invalidateQueries('marDashboard')
      await queryClient.invalidateQueries('consumptionHistory')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export default useUpsertConsumption
