import React, { useState, Suspense, useCallback } from 'react'
import { TabView, TabPanel } from 'primereact/tabview'
import { Button } from 'primereact/button'
import { FormV2 } from '@components/display/FormV2'
import FieldRenderer from './FieldRenderer'

export default function Panels({ settingsTabsData, onSubmit, isLoading }) {
  const [activeIndex, setActiveIndex] = useState(0)

  const buildFormValues = useCallback((tab) => (
    tab.content.reduce((acc, { currentOrgData }) => {
      if (!currentOrgData) return acc
      return { ...acc, ...currentOrgData }
    }, {})
  ), [])

  return (
    <TabView
      panelContainerClassName="px-0"
      onTabChange={(e) => setActiveIndex(e.index)}
      activeIndex={activeIndex}
    >
      {settingsTabsData.map(
        (tab) => tab.shouldShowSetting && (
          <TabPanel
            key={tab.header}
            className="flex flex-row gap-2"
            header={<Button className="text-gray-500 font-medium text-sm" label={tab.header} text />}
          >
            <div className="flex w-full m-0">
              <Suspense fallback={(
                <div className="justify-content-center align-items-center">
                  <i className="pi pi-spin pi-spinner text-xl" />
                </div>
                )}
              >
                <FormV2
                  onSubmit={onSubmit}
                  currentFormValues={buildFormValues(tab)}
                  submitLabel={`Save ${tab.header} Settings`}
                  showSubmitButton={tab.showSubmitButton}
                  isLoading={isLoading}
                >
                  {tab.content.map((content) => (
                    <FieldRenderer
                      key={content.id}
                      component={content.component}
                      fieldProps={content.props}
                      shouldShowContent={content.shouldShowContent}
                    />
                  ))}
                </FormV2>
              </Suspense>
            </div>
          </TabPanel>
        ),
      )}
    </TabView>
  )
}
