import React from 'react'
import { InputNumber } from 'primereact/inputnumber'
import { Button } from 'primereact/button'

function NumberOfRefills({ refillsRemaining, setRefillsRemaining, handleConfirm }) {
  return (
    <div className="grid flex flex-grow-1 justify-content-center align-items-center fadein">
      <div className="col-9 flex flex-column gap-2 text-center">
        <label className="text-lg font-normal mb-1" htmlFor="dose-number-of-refills">
          What is the number of refills?
        </label>
        <InputNumber
          inputId="dose-number-of-refills"
          data-testid="number-of-refills"
          value={refillsRemaining}
          showButtons
          placeholder="10"
          onChange={(e) => setRefillsRemaining(e.value)}
        />
        <Button
          onClick={handleConfirm}
          label="Save and Continue"
          className="p-button-text mt-2"
          disabled={!refillsRemaining}
        />
      </div>
    </div>
  )
}

export default NumberOfRefills
