import React, { memo } from 'react'
import { Tooltip } from 'primereact/tooltip'
import { classNames } from 'primereact/utils'

/**
 * An icon and tooltip to indicate the user directory service used to manage a user.
 *
 * @param {string} service - The name of the user directory service.
 * @param {string} tooltipPosition - The position of the tooltip.
 */
function UserDirectoryServiceIcon({ service = 'unknown', tooltipPosition = 'right' }) {
  const supportedServices = ['active-directory', 'google-workspace']
  const serviceName = supportedServices.includes(service) ? service : 'unknown'

  const tooltipText = {
    'active-directory': 'Managed in Azure AD',
    'google-workspace': 'Managed in Google Workspace',
    unknown: 'Managed in your user directory service',
  }

  const iconClassNames = classNames(`${serviceName}-directory-icon text-primary-500 opacity-70 pi`, {
    'pi-microsoft': serviceName === 'active-directory',
    'pi-google': serviceName === 'google-workspace',
    // TODO: Replace with pi-address-book after upgrading primeicons to the next major version
    'pi-id-card': 'unknown',
  })

  return (
    <div>
      <Tooltip
        content={tooltipText[serviceName]}
        position={tooltipPosition}
        target={`.${serviceName}-directory-icon`}
      />
      <i className={iconClassNames} />
    </div>
  )
}

export default memo(UserDirectoryServiceIcon)
