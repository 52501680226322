import React from 'react'
import { InputText } from 'primereact/inputtext'

function PinInput({ pin, onPinChanged, disabled }) {
  const inputToNumber = (e) => {
    if (e.target.value.length === 0) return
    if (e.target.value.length > 4 || Number.isNaN(e.target.value)) {
      e.target.value = e.target.value.slice(0, -1)
    }
  }

  return (
    <div className="field">
      <label className="block">Shared device PIN (4 digits)</label>
      <InputText
        value={pin}
        inputId="pin"
        data-testid="pin-input"
        name="pinInput"
        onChange={(e) => {
          onPinChanged(e.target.value)
        }}
        disabled={disabled}
        className="w-full"
        type="text"
        maxLength={4}
        onInput={inputToNumber}
      />
    </div>
  )
}

export default PinInput
