import React from 'react'

function LoginFormHeader() {
  return (
    <>
      <img alt="impurvon-logo" src="/logo.svg" className="login-logo" />
      <h2 id="title">Welcome to the family!</h2>
    </>
  )
}

export default LoginFormHeader
