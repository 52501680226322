import React from 'react'
import { Divider } from 'primereact/divider'
import SlotIdentifier from '../SlotIdentifier'

function SelectionSummary({
  ekit,
  drawer,
  slot,
}) {
  return (
    <div className="flex flex-row gap-2 align-items-center">
      <i className="pi pi-tablet" style={{ fontSize: '1.2rem' }} />
      <span className="text-sm">
        {ekit?.label}
      </span>
      {
      drawer
      && (
      <>
        <Divider layout="vertical" className="mx-1 h-1rem py-0" />
        <i className="pi pi-inbox" style={{ fontSize: '1.2rem' }} />
        <span className="text-sm">
          Drawer
          {' '}
          {drawer?.position}
        </span>
      </>
      )
    }
      {
      slot
      && (
      <>
        <Divider layout="vertical" className="mx-1 h-1rem py-0" />
        <i className="pi pi-table" style={{ fontSize: '1.2rem' }} />
        <span className="text-sm">Slot</span>
        <div style={{ width: '2rem' }}>
          <SlotIdentifier drawerSlot={slot} />
        </div>
      </>
      )
    }
    </div>
  )
}

export default SelectionSummary
