import React from 'react'
import { Dropdown } from 'primereact/dropdown'
import { sortBy } from 'lodash'
import { conditionTypes } from './config'

function ConditionType({
  conditionType, existingConditions, schedules, isPrn, setConditionType, editable = true,
}) {
  const options = []
  for (let i = 0; i < conditionTypes.length; i += 1) {
    // Get all conditions of this type
    const conditions = existingConditions
      .filter((condition) => condition.conditionType === conditionTypes[i].code)

    let disabled = false

    if (isPrn) {
      // Disable the condition type if it is a PRN that already has a condition of this type
      disabled = conditions.length > 0
    } else {
      // Disable the condition type if all schedules have been assigned a condition of this type
      disabled = conditions.find((condition) => !condition.hasSchedules)
        || schedules.length === conditions.flatMap((condition) => condition.schedules).length
    }

    if (disabled) {
      options.push({ ...conditionTypes[i], disabled: true })
    } else {
      options.push(conditionTypes[i])
    }
  }
  const sortedOptions = sortBy(options, [(o) => (o.disabled ? 'Z' : 'A') + o.displayText])

  const conditionTypeItemTemplate = (option) => {
    const existingStyle = option.disabled ? { textDecoration: 'line-through' } : {}
    return (
      <div style={existingStyle}>{option.displayText}</div>
    )
  }

  return (
    <Dropdown
      id="condition-type-dropdown"
      itemTemplate={conditionTypeItemTemplate}
      placeholder="Select Type"
      disabled={!editable}
      inputId="condition-condition-type"
      value={conditionType}
      data-testid="condition-condition-type"
      options={sortedOptions}
      optionLabel="displayText"
      optionValue="code"
      onChange={(e) => setConditionType(e.value)}
      aria-label="Condition Type"
      dropdownarialabel="Select Type"
    />
  )
}

export default ConditionType
