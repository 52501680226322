import React from 'react'
import { classNames } from 'primereact/utils'

function RequirementCheckItem({ requirement, isMet }) {
  return (
    <li>
      <i
        className={classNames('mr-2 pi', {
          'pi-check-circle text-green-500': isMet,
          'pi-times-circle text-red-500': !isMet,
        })}
        style={{ fontSize: '0.7rem' }}
      />
      {requirement}
    </li>
  )
}

export default RequirementCheckItem
