import ApiClient from '@services/ApiClient'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { displayErrorMessages } from '@services/errorHandler'

export function useCreatePendingDoseFill(doseId, statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['createPendingDoseFill', doseId],
    mutationFn: (doseFill) => ApiClient.post(`/doses/${doseId}/dose_fills`, { doseFill }),
    onSuccess: () => {
      queryClient.invalidateQueries('ekitDoses')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export default {}
