import React from 'react'
import { Routes, Route } from 'react-router-dom'
import PageLayout, { usePageOutlet } from '@components/display/PageLayout'
import { withApp } from '@components/App'
import Dashboard from './Dashboard'

function PharmacyDashboard() {
  const usePharmacyDashboardHeader = () => usePageOutlet()

  return (
    <div className="pharmacy-dashboard">
      <Routes>
        <Route
          path="/admin/pharmacy_dashboard/*"
          element={<PageLayout rootPath="/admin/pharmacy_dashboard" rootLabel="Pharmacy Dashboard" />}
        >
          <Route
            index
            element={<Dashboard usePharmacyDashboardHeader={usePharmacyDashboardHeader} />}
          />
        </Route>
      </Routes>
    </div>
  )
}

export default withApp(PharmacyDashboard)
