import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Skeleton } from 'primereact/skeleton'
import { get } from 'lodash'
import AttributeCard, { Attribute } from '@components/display/AttributeCard'
import { dateTemplate } from '@services/utils'
import { useCurrentOrganization, useCurrentUser } from '@components/App'
import { Button } from 'primereact/button'
import { useRefreshPatient } from '@hooks/drx'
import MedBoxesList from '../MedBoxesList'
import { PatientAvatar } from './PatientAvatar'
import RevealablePin from '../../sharedDevices/SharedPhoneGroup/RevealablePin'
import RevealableIdNumber from '../../sharedDevices/SharedPhoneGroup/RevealableIdNumber'
import { usePatientUnpauseMutation } from '../../../features/Patients/patientHooks'

import './profile.scss'

function ProfileCard({
  patient, rootPath, isLoading, isCurrentUserAdmin = false, className = '', statusMessage,
}) {
  const title = {
    label: 'Profile',
    icon: 'pi-user',
  }
  const navigate = useNavigate()
  const currentUser = useCurrentUser()
  const [ssnRevealed, setSSNRevealed] = useState(false)
  const [idNumberRevealed, setIdNumberRevealed] = useState(false)

  const {
    email,
    initials,
    phone,
    site,
    createdAt,
    timezone,
    customerId,
    currentPause,
    roomNumber,
    medboxes,
    lastActivityAt,
    patientSettings,
    ccmEligible,
    dateOfBirth,
    gender,
    birthSex,
    ssn,
    integrationPatients,
  } = patient || {}

  const {
    mutateAsync: unpausePatient,
  } = usePatientUnpauseMutation(patient?.id, statusMessage)

  const [refreshIntegrationPatientId, setRefreshIntegrationPatientId] = useState()

  const {
    mutateAsync: refreshPatient,
    isLoading: isRefreshPatientLoading,
  } = useRefreshPatient(statusMessage, refreshIntegrationPatientId)

  const handleRefreshPatient = async (integrationPatientId) => {
    setRefreshIntegrationPatientId(integrationPatientId)
    await refreshPatient(integrationPatientId)
    setRefreshIntegrationPatientId(null)
  }

  const valueTemplate = (value) => (
    isLoading ? <Skeleton className="inline-flex" width="5rem" height="1rem" /> : value
  )

  const selfAdminTagConfig = {
    value: 'Self Admin',
    severity: 'success',
  }

  const ccmEligibleTagConfig = {
    value: 'CCM Eligible',
    severity: 'success',
  }

  const editClick = () => {
    navigate(`${rootPath}/${patient.id}/update`)
  }

  const action = { label: 'Edit', command: editClick }

  const getTags = (ccm, selfAdmin) => {
    if (!ccm && !selfAdmin) return null
    const tags = []
    if (ccm) {
      tags.push(ccmEligibleTagConfig)
    }
    if (selfAdmin) {
      tags.push(selfAdminTagConfig)
    }
    return tags
  }

  const buildTooltipContent = () => (
    integrationPatients.map((integrationPatient) => (
      `${integrationPatient.pharmacy.name} - ${integrationPatient.externalId}`
    )).join('\n')
  )

  const integrationPatientsTemplate = () => (
    <span>
      {integrationPatients.map((integrationPatient) => (
        <span key={integrationPatient.id}>
          {integrationPatient.pharmacy.name}
          {' '}
          -
          {' '}
          {integrationPatient.externalId}

          {currentUser.role === 'superadmin' && integrationPatient.pharmacy.isDrx && (
            <Button
              className={`p-button-sm p-button-text py-1 px-2 ${
                refreshIntegrationPatientId === integrationPatient.id
                && isRefreshPatientLoading ? 'spinning' : ''
              }`}
              icon="pi pi-sync"
              tooltip="Refresh integration data"
              tooltipOptions={{ position: 'top' }}
              onClick={() => handleRefreshPatient(integrationPatient.id)}
              loading={refreshIntegrationPatientId === integrationPatient.id
                && isRefreshPatientLoading}
            />
          )}
        </span>
      ))}
    </span>
  )

  const {
    patientBirthSexOptions: birthSexOptions,
    patientGenderOptions: genderOptions,
  } = useCurrentOrganization()
  const tags = getTags(ccmEligible, patientSettings?.selfAdmin)

  const handleUnpause = async () => {
    await unpausePatient()
  }

  // NOTE: PatientAvatar is conditional on patient instead of isLoading
  //    because of a UI / lifecycle bug that prevents the image crop dialog from showing
  return (
    <div className={`h-full ${className}`} data-test="profile-card">
      <AttributeCard title={title} action={action} className="h-full" tags={tags}>
        {patient && (
        <Attribute
          label="Avatar:"
          value={<PatientAvatar patient={patient} />}
        />
        )}
        {initials
            && (
              <Attribute
                label="Initials:"
                value={valueTemplate(initials || 'Empty')}
              />
            )}
        {email
            && (
              <Attribute
                label="Email:"
                value={valueTemplate(email || 'Empty')}
              />
            )}
        {phone
            && (
              <Attribute
                label="Phone:"
                value={valueTemplate(phone || 'Empty')}
              />
            )}
        {
          patientSettings?.note && (
          <Attribute
            label="Note:"
            allowMultiline
            value={valueTemplate(patientSettings?.note)}
          />
          )
        }
        {isCurrentUserAdmin && ssn && (
        <Attribute
          label="SSN:"
          value={(
            <RevealablePin
              pin={ssn}
              pinRevealed={!!ssnRevealed}
              onRevealToggle={() => setSSNRevealed(!ssnRevealed)}
              isTemporaryPin={false}
            />
            )}
        />
        )}
        {
          patientSettings?.mrn && (
          <Attribute
            label="Medical Record #:"
            value={valueTemplate(patientSettings?.mrn || 'Empty')}
          />
          )
        }
        {dateOfBirth
            && (
              <Attribute
                label="Date of Birth:"
                value={valueTemplate(dateOfBirth || 'Empty')}
              />
            )}
        {birthSex
            && (
              <Attribute
                label="Birth Sex:"
                value={valueTemplate(birthSexOptions.find((opt) => opt.value === birthSex).label)}
              />
            )}

        {gender
            && (
              <Attribute
                label="Gender:"
                value={valueTemplate(genderOptions.find((opt) => opt.value === gender).label)}
              />
            )}

        {customerId
            && (
              <Attribute
                label="Custom ID:"
                value={valueTemplate(customerId || 'Empty')}
              />
            )}
        <Attribute
          label="Site:"
          value={valueTemplate(site ? <a href={`/admin/sites/${site.id}`}>{site.name}</a> : 'None Assigned')}
        />
        <Attribute
          label="Timezone:"
          value={valueTemplate(timezone || 'Empty')}
        />
        <Attribute
          label="Created:"
          value={valueTemplate(dateTemplate(createdAt))}
        />
        {roomNumber && (
        <Attribute
          label="Room #:"
          value={valueTemplate(roomNumber)}
        />
        )}
        {currentPause && (
        <>
          <div className="relative">
            <Attribute
              label="LOA From:"
              value={valueTemplate(dateTemplate(currentPause.startAt))}
            />
            <Button className="text-xs p-2 loa-early-return-btn" label="Return Early" onClick={handleUnpause} />
          </div>
          <div className="relative">
            <Attribute
              label="Until:"
              value={valueTemplate(currentPause.endAt ? dateTemplate(currentPause.endAt) : 'No end date')}
            />
          </div>
          {currentPause.note && (
          <Attribute
            label="LOA Note:"
            value={valueTemplate(currentPause.note)}
          />
          )}
          <Attribute
            label="LOA by:"
            value={valueTemplate(currentPause.pausedBy?.fullName)}
          />
        </>
        )}
        {
            integrationPatients && integrationPatients.length > 0 && (
              <Attribute
                label="Integrations:"
                value={integrationPatientsTemplate()}
                tooltipContent={buildTooltipContent()}
              />
            )
          }
        {
            (patientSettings?.selfAdmin && lastActivityAt) && (
              <Attribute
                label="Last Activity:"
                value={valueTemplate(dateTemplate(lastActivityAt))}
              />
            )
          }
        {
            get(medboxes, 'length') > 0 && (
              <MedBoxesList medboxes={medboxes} />
            )
          }
        {
            (isCurrentUserAdmin && patientSettings?.medicaidIdNumber) && (
              <Attribute
                label="Medicaid Id:"
                value={(
                  <RevealableIdNumber
                    idNumber={patientSettings?.medicaidIdNumber}
                    idNumberRevealed={!!idNumberRevealed}
                    onRevealToggle={() => setIdNumberRevealed(!idNumberRevealed)}
                  />
              )}
              />
            )
          }
      </AttributeCard>
    </div>
  )
}

export default ProfileCard
