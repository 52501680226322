import React from 'react'
import moment from 'moment'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { useCurrentOrganization } from '@components/App'
import { momentFormats } from '@services/utils/moment'

function timeTemplate(field, timezone = 'America/New_York', placeholder = '') {
  return function tableCell(rowData) {
    const value = rowData[field]
    return (
      <div>
        { value ? moment(value).tz(timezone).format(momentFormats.datetime_12) : placeholder }
      </div>
    )
  }
}

function DirectorySitesTable({ sites, isLoading }) {
  const { timezone } = useCurrentOrganization()

  const handleDirectorySiteSelection = (e) => {
    // TODO: Use react-router-dom to navigate to the directory sync page after removing ActiveAdmin
    window.location.href = `/admin/sites/${e.value.id}/directory_sync`
  }

  return (
    <DataTable
      dataKey="id"
      loading={isLoading}
      onSelectionChange={handleDirectorySiteSelection}
      removableSort
      scrollable
      scrollHeight="60vh"
      selectionMode="single"
      value={sites}
    >
      <Column
        field="id"
        header="ID"
      />
      <Column
        field="name"
        header="Site Name"
      />
      <Column
        field="scimGroup"
        header="SCIM Group"
      />
      <Column
        body={timeTemplate('createdAt', timezone)}
        field="createdAt"
        header="Created"
      />
    </DataTable>
  )
}

export default DirectorySitesTable
