import React, { useState } from 'react'
import { camelCase, get } from 'lodash'
import moment from 'moment'
import { ALLERGEN_TYPE_LABELS } from '@components/display/Allergies/config'
import RevealableIdNumber from '@components/sharedDevices/SharedPhoneGroup/RevealableIdNumber'
import { PHARMACY_FIELDS } from './config'

// TODO: The API structures diffs for associations differently than it does for other fields since
//       it needs to track the changes to the whole association. For single object diffs like the
//       trackable object or has_one associations, the diffed field will be a string value and left
//       as snake case by the API client. For has_many associations, the diffed field will be an
//       object key and the API client will convert the key to camel case. Therefore, we always
//       define the field as camel case for consistency and convert the field (which may be camel
//       case) to snake case before looking it up in the PHARMACY_FIELDS object. This approach is
//       not ideal and should be revisited.
const lookupPharmacyField = (field) => get(
  PHARMACY_FIELDS,
  camelCase(field),
  { label: field },
)

function FormattedValue({ field, value }) {
  if (field.format === 'date') {
    return (<b>{moment(value).format('MM/DD/YYYY')}</b>)
  }

  if (field.format === 'hidden') {
    const [hiddenValueRevealed, setHiddenValueRevealed] = useState(false)

    return (
      <RevealableIdNumber
        idNumber={value}
        idNumberRevealed={hiddenValueRevealed}
        onRevealToggle={() => setHiddenValueRevealed(!hiddenValueRevealed)}
      />
    )
  }

  return (<b>{`${value}`}</b>)
}

// NOTE: This component is referenced with both a key and a diffKey. The key is required by React
//       to uniquely identify the component in the list. The key is not a prop and therefore is
//       not accessible to the component. The diffKey is a prop and is used to pass the key to the
//       component so that it can be used to define additional keys for its children.
function SingleFieldDiff({ diff, diffKey, field }) {
  if (diff.from === null) {
    return (
      <li key={diffKey}>
        <b>{`${field.label} `}</b>
        set to
        {' '}
        <FormattedValue field={field} value={diff.to} />
      </li>
    )
  }

  if (diff.to === null) {
    return (
      <li key={diffKey}>
        <b>{`${field.label}`}</b>
        {' '}
        removed (was previously
        {' '}
        <FormattedValue field={field} value={diff.from} />
        )
      </li>
    )
  }

  return (
    <li key={diffKey}>
      <b>{`${field.label}`}</b>
      {' '}
      changed from
      {' '}
      <FormattedValue field={field} value={diff.from} />
      {' '}
      to
      {' '}
      <FormattedValue field={field} value={diff.to} />
    </li>
  )
}

const getResourceAction = (field) => {
  switch (field) {
    case 'has_many_added':
      return 'added'
    case 'has_many_deleted':
      return 'removed'
    case 'has_many_updated':
      return 'updated'
    default:
      return 'changed'
  }
}

const getResourceLabel = (resourceType) => {
  switch (resourceType) {
    case 'allergies':
      return 'Allergy'
    case 'dosePurpose':
      return 'Dose Purpose'
    case 'doseSchedules':
      return 'Dose Schedule'
    case 'doseSigs':
      return 'Dose Sig'
    case 'patientInsurances':
      return 'Insurance'
    default:
      return 'Resource'
  }
}

const getHasManyAddedFieldDiffs = (diff, key, resourceType) => {
  const hasManyFieldDiffs = []

  Object.keys(diff.to).forEach((toField) => {
    const diffField = lookupPharmacyField(toField)
    const diffKey = `${key}-${toField}`

    const fromValue = null
    let toValue = diff.to[toField]

    if (resourceType === 'allergies') {
      toValue = get(ALLERGEN_TYPE_LABELS, toValue, toValue)
    }

    hasManyFieldDiffs.push(
      <SingleFieldDiff
        diff={{ from: fromValue, to: toValue }}
        diffKey={diffKey}
        field={diffField}
        key={diffKey}
      />,
    )
  })

  return hasManyFieldDiffs
}

const getHasManyDeletedFieldDiffs = (diff, key, resourceType) => {
  const hasManyFieldDiffs = []

  Object.keys(diff.from).forEach((fromField) => {
    const diffField = lookupPharmacyField(fromField)
    const diffKey = `${key}-${fromField}`

    const fromValue = diff.to[fromField]
    let toValue = null

    if (resourceType === 'allergies') {
      toValue = get(ALLERGEN_TYPE_LABELS, toValue, toValue)
    }

    hasManyFieldDiffs.push(
      <SingleFieldDiff
        diff={{ from: fromValue, to: toValue }}
        diffKey={diffKey}
        field={diffField}
        key={diffKey}
      />,
    )
  })

  return hasManyFieldDiffs
}

const getHasManyUpdatedFieldDiffs = (diff, key, resourceType) => {
  const hasManyFieldDiffs = []
  const updates = diff.updates || []

  updates.forEach((update) => {
    const diffField = lookupPharmacyField(update.field)
    const diffKey = `${key}-${update.id}-${update.field}`

    let fromValue = update.from
    let toValue = update.to

    if (resourceType === 'allergies') {
      fromValue = get(ALLERGEN_TYPE_LABELS, fromValue, fromValue)
      toValue = get(ALLERGEN_TYPE_LABELS, toValue, toValue)
    }

    hasManyFieldDiffs.push(
      <SingleFieldDiff
        diff={{ from: fromValue, to: toValue }}
        diffKey={diffKey}
        field={diffField}
        key={diffKey}
      />,
    )
  })

  return hasManyFieldDiffs
}

// TODO: Should consider a better presentation for the diffs than a list. It looks ok, but there
//       is a lot of wasted space and plenty of room for improvement.
function ActivityReviewDiff({ activityId, diff, resourceType }) {
  if (!diff.field) {
    return null
  }

  const field = lookupPharmacyField(diff.field)
  const key = `${activityId}-${resourceType}-${diff.id}-${field.label}`

  const hasManyFieldDiffs = []

  if (diff.field === 'has_many_added') {
    hasManyFieldDiffs.push(...getHasManyAddedFieldDiffs(diff, key, resourceType))
  }

  if (diff.field === 'has_many_deleted') {
    hasManyFieldDiffs.push(...getHasManyDeletedFieldDiffs(diff, key, resourceType))
  }

  if (diff.field === 'has_many_updated') {
    hasManyFieldDiffs.push(...getHasManyUpdatedFieldDiffs(diff, key, resourceType))
  }

  if (hasManyFieldDiffs.length > 0) {
    return (
      <li key={key}>
        {`${getResourceLabel(resourceType)} ${getResourceAction(diff.field)}:`}
        <ul className="pl-4">
          {hasManyFieldDiffs}
        </ul>
      </li>
    )
  }

  return (<SingleFieldDiff diff={diff} diffKey={key} field={field} key={key} />)
}

export default ActivityReviewDiff
