import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'

export function useSitesQuery({ statusMessage, organizationId }) {
  return useQuery({
    queryKey: ['sites', organizationId],
    queryFn: () => {
      let url = '/sites'
      if (organizationId) {
        url = `${url}?organization_id=${organizationId}`
      }
      return ApiClient.get(url)
    },
    onError: () => statusMessage.current.show([
      {
        severity: 'error',
        sticky: true,
        summary: 'Unable to fetch sites at this time.',
      },
    ]),
  })
}

export default {}
