import React from 'react'
import { Dropdown } from 'primereact/dropdown'
import { useCurrentOrganization } from '@components/App'

// TODO: Remove export after refactoring the MAR PRN form
export const getReasonOptions = (patientLabel, status) => {
  const options = {
    on_hold: [
      { value: 'PARAMETERS', label: 'Criteria Check' },
      { value: 'SYMPTOMS', label: 'Symptoms' },
      { value: 'PROCEDURE', label: 'Medical Procedure' },
    ],
    refused: [
      { value: 'PATIENT_REFUSED', label: `${patientLabel} refused` },
      { value: 'COULD_NOT_LOCATE', label: 'Could not locate' },
      { value: 'CONDITION_CHECK', label: 'Doesn\'t meet condition check' },
    ],
  }

  return options[status] || []
}

// TODO: Remove export after refactoring the MAR status overlay
export const getReasonLabel = (patientLabel, status, reason) => {
  const options = getReasonOptions(patientLabel, status)
  const selectedOption = options.find((option) => option.value === reason)

  return selectedOption ? selectedOption.label : null
}

function ReasonDropdown({ reason, setReason, status = 'refused' }) {
  const { patientLabelSingular } = useCurrentOrganization()
  const options = getReasonOptions(patientLabelSingular, status)

  return (
    <div className="flex flex-column gap-1">
      <label className="block" htmlFor="adherence-dialog-form-reason-dropdown">Reason</label>
      <Dropdown
        id="adherence-dialog-form-reason-dropdown"
        value={reason}
        options={options}
        onChange={(e) => setReason(e.value)}
        placeholder="Please select a reason"
        className="w-full"
      />
    </div>
  )
}

export default ReasonDropdown
