import React from 'react'
import { isEmpty } from 'lodash'
import { classNames } from 'primereact/utils'
import { Tag } from 'primereact/tag'
import { statusOfDay } from '@components/narcoticCounts/narcoticCountUtils'

function NarcoticCountsStatusIndicator({ narcoticCounts, onSelectCount }) {
  if (isEmpty(narcoticCounts)) return null

  const lastCompletedCount = narcoticCounts.filter((count) => count.status === 'Complete').pop()

  const lastCount = lastCompletedCount
    ?.inventoryClaim2?.count || lastCompletedCount?.inventoryClaim1?.count

  const status = statusOfDay(narcoticCounts)
  const missedCounts = narcoticCounts.filter((count) => count.status === 'Missed')

  return (
    <div
      className={
        classNames(
          'flex flex-column gap-1 align-items-center justify-content-center font-medium text-xl cursor-pointer',
          {
            'text-green-500': status === 'success',
            'text-orange-500': status === 'warning',
            'text-red-500': status === 'danger',
          },
        )
      }
      onClick={onSelectCount}
    >
      { lastCount || '' }
      {
        missedCounts.length > 0 && (
          <Tag value={`${missedCounts.length} Missed`} severity={status} />
        )
      }
    </div>
  )
}

export default NarcoticCountsStatusIndicator
