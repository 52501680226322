import React from 'react'

function DirectorySyncHeaderTitle({ directoryUser }) {
  return (
    <div className="flex flex-row gap-4 px-4 pb-2 pt-2 align-items-center">
      <span>
        <i className="text-xl pi pi-sync mr-2" />
        Directory Sync
      </span>
      {
        directoryUser && (
          <span className="text-sm text-gray-500">
            {directoryUser.workosDirectoryUserId}
          </span>
        )
      }
    </div>
  )
}

export default DirectorySyncHeaderTitle
