import React, { useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { InputTextarea } from 'primereact/inputtextarea'
import {
  useCreatePatientClinicalNotesMutation,
} from './clinicalNotesListHooks'

function CreateClinicalNoteDialog({
  visible, setVisible, patientId, statusMessage,
}) {
  const [note, setNote] = useState()

  const {
    mutateAsync: createMutation,
    isLoading,
  } = useCreatePatientClinicalNotesMutation(statusMessage)

  const createClinicalNote = async () => {
    await createMutation({ note, patientId })
    setVisible(false)
  }

  return (
    <Dialog
      header="Create Clinical Note"
      visible={visible}
      onHide={() => setVisible(false)}
      footer={(
        <div className="flex flex-row justify-end">
          <Button
            label="Cancel"
            className="p-button-text"
            onClick={() => setVisible(false)}
          />
          <Button
            label="Create"
            className="p-button-primary"
            onClick={() => createClinicalNote()}
            loading={isLoading}
          />
        </div>
      )}
    >
      <InputTextarea
        className="mt-2"
        rows={5}
        cols={30}
        value={note}
        onChange={(e) => setNote(e.target.value)}
        placeholder="Note"
        autoFocus
      />
    </Dialog>
  )
}

export default CreateClinicalNoteDialog
