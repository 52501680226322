import React, { useCallback, useEffect, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { exactSearch } from '@services/utils/search'
import PatientsTableFooter from '../Patients/PatientsTableFooter'
import OnboardDialog from './OnboardDialog'

function PendingUsers({
  organization,
  pendingUsers,
  isFetching,
  searchTerm,
  setSearchLoading,
  maxDataViewHeight,
  setFooter,
}) {
  const [filteredUsers, setFilteredUsers] = useState()
  const [onboardingUser, setOnboardingUser] = useState(null)
  const [shouldShowOnboardDialog, setShouldShowOnboardDialog] = useState(false)

  useEffect(() => {
    setSearchLoading(true)
    if (searchTerm) {
      setFilteredUsers(exactSearch(pendingUsers, ['firstName', 'lastName'], searchTerm))
    } else {
      setFilteredUsers(pendingUsers)
    }
    setSearchLoading(false)

    if (filteredUsers) {
      setFooter(
        <PatientsTableFooter
          isLoading={isFetching}
          patientsLabel={organization.patientLabelPlural}
          patientsLength={filteredUsers.length}
        />,
      )
    }
  }, [pendingUsers, searchTerm, setSearchLoading,
    setFooter, isFetching, organization, filteredUsers])

  const setOnboardingUserData = useCallback((user = {}) => {
    setOnboardingUser(user)
    setShouldShowOnboardDialog(true)
  }, [])

  const onboardButton = (user) => (
    <Button
      icon="pi pi-user-plus"
      className="p-button-sm p-button-outlined"
      data-testid={`pendingUserButton.${user.id}`}
      onClick={() => setOnboardingUserData(user)}
    />
  )

  return (
    <>
      <OnboardDialog
        visible={shouldShowOnboardDialog}
        setVisible={setShouldShowOnboardDialog}
        pendingUser={onboardingUser}
      />
      <DataTable
        dataKey="id"
        loading={isFetching}
        value={filteredUsers || []}
        scrollable
        scrollHeight={maxDataViewHeight ? (maxDataViewHeight - 10) : null}
      >
        <Column header="First Name" field="firstName" />
        <Column header="Last Name" field="lastName" />
        <Column header="External Id" field="externalId" />
        <Column header="Admitted At" field="admittedAt" />
        <Column
          header="Onboard"
          body={onboardButton}
        />
      </DataTable>
    </>
  )
}

export default PendingUsers
