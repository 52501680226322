import React from 'react'
import moment from 'moment'
import { momentFormats } from '@services/utils/moment'
import { useDoseHoldAtTime, useDoseHoldDelete, useDoseHoldUpdate } from '@hooks/doseHolds'
import DoseHoldDialog from '@components/DoseHold/DoseHoldDialog'

function AdherenceDoseHoldDialog({
  visible,
  onHide,
  doseId,
  time,
  statusMessageRef,
  timezone,
}) {
  const timeMoment = time
    ? moment(time).utc().format(momentFormats.iso8601_utc)
    : null

  const {
    data: hold,
    isLoading: isHoldLoading,
  } = useDoseHoldAtTime(doseId, timeMoment, statusMessageRef)

  const {
    mutateAsync: updateHold,
    isLoading: isUpdatingHold,
  } = useDoseHoldUpdate(statusMessageRef)

  const {
    mutateAsync: deleteHold,
    isLoading: isDeletingHold,
  } = useDoseHoldDelete(statusMessageRef)

  if (!doseId) return null

  const handleUpdateHold = async ({ startAt, endAt, note }) => {
    const payload = {
      id: hold.id,
      startAt: startAt.utc().format(momentFormats.iso8601_utc),
      endAt: endAt.utc().format(momentFormats.iso8601_utc),
      note,
    }
    await updateHold(payload, {
      onSuccess: () => {
        onHide()
      },
    })
  }

  const handleDeleteHold = async () => {
    await deleteHold(hold.id, {
      onSuccess: () => {
        onHide()
      },
    })
  }

  return (
    <DoseHoldDialog
      timezone={timezone}
      visible={visible}
      onHide={onHide}
      onConfirm={handleUpdateHold}
      holdToEdit={hold}
      onDelete={handleDeleteHold}
      isLoading={isHoldLoading || isUpdatingHold || isDeletingHold}
      showDeleteButton
    />
  )
}

export default AdherenceDoseHoldDialog
