import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

const getPath = (model, id) => {
  switch (model) {
    case 'DirectoryUser':
      return `/directory_users/${id}/refresh_from_workos`
    case 'Organization':
      return `/organizations/${id}/refresh_from_workos`
    case 'User':
      return `/users/${id}/refresh_from_workos`
    default:
      throw new Error(`Unsupported model: ${model}`)
  }
}

const useRefreshFromWorkos = ({ model, id, statusMessage }) => {
  const queryClient = useQueryClient()

  const path = getPath(model, id)

  return useMutation({
    mutationFn: async () => ApiClient.post(path),
    enabled: !!id,
    onSuccess: async () => {
      await queryClient.invalidateQueries('admin')
      await queryClient.invalidateQueries('caregiver')
      await queryClient.invalidateQueries('directoryUsers')
      await queryClient.invalidateQueries('workosMessages')
      statusMessage.current?.clear()
      statusMessage.current?.show([
        { severity: 'success', summary: `${model} refreshed!` },
      ])
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export default useRefreshFromWorkos
