import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'

const useTasks = (clientId) => {
  const query = useQuery({
    queryKey: ['tasks', clientId],
    queryFn: () => ApiClient.get(`/patients/${clientId}/tasks`),
    enabled: !!clientId,
  })

  return useMemo(() => ({
    ...query,
    data: query.data?.tasks || [],
  }), [query])
}

export default useTasks
