import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

const useUpdateSite = (statusMessage) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (site) => ApiClient.put(`/sites/${site.id}`, site),
    onSuccess: async () => {
      await queryClient.invalidateQueries('sites')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export default useUpdateSite
