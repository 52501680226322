import React from 'react'
import { FormV2 } from '@components/display/FormV2'
import { Card } from 'primereact/card'
import useScrollTo from '@services/hooks/useScrollTo'
import TimezoneField from './TimezoneField'
import SettingFeatures from './SettingFeatures'
import { useSettingsMutation } from '../settingsHooks'
import OrgTextField from './OrgTextField'
import { getCurrentSettings, getSettingFeatures } from '../orgSettingsUtils'

function OrgDetailsSettings({
  organizationId, query, timezones, statusMessage,
}) {
  const scrollTo = useScrollTo()
  const mutation = useSettingsMutation(organizationId, statusMessage)

  if (query.isLoading) {
    return <div>Loading...</div>
  }

  const currentSettings = getCurrentSettings(query)
  const settingFeatures = getSettingFeatures(query)

  const onSubmit = async (data) => {
    await mutation.mutateAsync({ organization: data })
    scrollTo('top')
  }

  const options = timezones.map((tz) => ({ label: tz, value: tz }))
  return (
    <div className="flex w-full">
      <FormV2
        className="flex flex-column gap-4"
        onSubmit={onSubmit}
        submitLabel="Update Organization"
        currentFormValues={currentSettings}
      >
        <Card>
          <OrgTextField name="name" label="Name" />
          <TimezoneField options={options} />
          <OrgTextField name="hrstDomain" label="HRST domain" />
          <SettingFeatures features={settingFeatures} />
        </Card>
      </FormV2>
    </div>
  )
}

export default OrgDetailsSettings
