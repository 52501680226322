import React from 'react'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { rrulestr } from 'rrule'
import { formattedTimeFromMinutes } from '@services/utils'

function TaskConditionSchedulesCell({ hasSchedules, schedules }) {
  if (!hasSchedules) {
    return (
      <div className="flex flex-column gap-2 justify-content-center">All Schedules</div>
    )
  }

  const sortedSchedules = schedules.sort((s1, s2) => s1.time - s2.time)

  return (
    <div className="flex flex-column gap-2 justify-content-center">
      <ul>
        {sortedSchedules.map((schedule) => (
          <li key={schedule.id}>
            {formattedTimeFromMinutes(schedule.time)}
            {' '}
            {rrulestr(schedule.rrule).toText()}
          </li>
        ))}
      </ul>
    </div>
  )
}

function TaskConditionActionsCell({ actions }) {
  let actionsText = `${actions.length} actions`

  if (actions.length === 1) {
    actionsText = '1 action'
  }

  return (
    <div className="flex flex-column gap-2 justify-content-center">{actionsText}</div>
  )
}

function TaskConditionsTable({ task, setSelectedCondition }) {
  if (!task) {
    return null
  }

  return (
    <DataTable
      id="task-conditions-list"
      dataKey="id"
      value={task.conditions}
      className="mt-2"
      rowClassName="fadein"
      selectionMode="single"
      sortField="conditionName"
      sortOrder={1}
      onSelectionChange={(e) => setSelectedCondition(e.value)}
    >
      <Column
        key="condition_name"
        field="conditionName"
      />
      <Column
        key="condition_note"
        field="note"
      />
      {
        task.taskType === 'routine' && (
          <Column
            key="condition_schedules"
            field="schedules"
            body={TaskConditionSchedulesCell}
          />
        )
      }
      <Column
        key="condition_actions"
        field="actions"
        body={TaskConditionActionsCell}
      />
    </DataTable>
  )
}

export default TaskConditionsTable
