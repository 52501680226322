import React from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { useCurrentOrganization } from '@components/App'

function ReactivatePatientDialog({
  visible, onHide, onConfirm, isLoading,
}) {
  const organization = useCurrentOrganization()

  const footer = (
    <div className="flex flex-row gap-2 justify-content-end">
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text p-button-sm"
        onClick={onHide}
        disabled={isLoading}
      />
      <Button
        label="Confirm"
        icon="pi pi-check"
        className="p-button-sm"
        onClick={onConfirm}
        loading={isLoading}
      />
    </div>
  )

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header="Reactivate"
      footer={footer}
      modal
      className="p-fluid"
      closable={!isLoading}
    >
      <div className="p-field p-2">
        <span>
          Are you sure you want to reactivate this
          {' '}
          {organization.patientLabelSingular.toLowerCase()}
          ?
        </span>
      </div>
    </Dialog>
  )
}

export default ReactivatePatientDialog
