import { displayErrorMessages } from '@services/errorHandler'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'

// eslint-disable-next-line import/prefer-default-export
export function useUpdateMedicalConditionMutation({ patientId, doseId, statusMessage }) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (medicalCondition) => {
      let url = ''

      if (doseId) {
        url = `/doses/${doseId}/medical_conditions/${medicalCondition.id}`
      } else if (patientId) {
        url = `/patients/${patientId}/medical_conditions/${medicalCondition.id}`
      } else {
        throw new Error('Neither doseId nor patientId provided')
      }

      return ApiClient.put(url, medicalCondition)
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
    onSuccess: async () => {
      statusMessage.current.show([
        {
          severity: 'success',
          summary: 'Medical condition updated successfully.',
        },
      ])

      const queryKey = doseId ? 'doseMedicalConditions' : 'patientMedicalConditions'
      await queryClient.invalidateQueries([queryKey])
    },
  })
}
