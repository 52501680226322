import React from 'react'
import { classNames } from 'primereact/utils'

function Box({
  direction = 'row', wrap = true, gap, alignContent, justifyContent, alignItems, children, className,
}) {
  const flexWrap = wrap || direction === 'row'
  return (
    <div className={
      classNames('flex text-overflow-ellipsis white-space-nowrap overflow-hidden', {
        'flex-column': direction === 'column',
        'flex-row': direction === 'row',
        'flex-wrap': flexWrap,
        'flex-nowrap': !flexWrap,
        [`gap-${gap}`]: gap,
        [`align-content-${alignContent}`]: alignContent,
        [`justify-content-${justifyContent}`]: justifyContent,
        [`align-items-${alignItems}`]: alignItems,
        [className]: className,
      })
    }
    >
      {children}
    </div>
  )
}

export default Box
