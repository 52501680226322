import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { find, get } from 'lodash'
import { useSearchParams } from 'react-router-dom'
import { Messages } from 'primereact/messages'
import { useCurrentOrganization, useCurrentUser } from '@components/App'
import ControlBar from '@components/medManagement/ControlBar'
import DashboardCard from '@components/medManagement/DashboardCard'
import SearchField from '@components/display/Form/SearchField'
import PendingReview from './PendingReview'
import { useSitesQuery } from '../Selects/SitesSelect/SitesSelectHooks'
import { useTrackableActivities } from './pharmacyDashboardHooks'

const VIEWS = {
  pendingReview: 'pending_review',
}

const timeframeOptions = [
  {
    label: 'Past 24 Hours',
    value: [moment().subtract(1, 'days').toDate(), moment().toDate()],
  },
  {
    label: 'Past Week',
    value: [moment().subtract(7, 'days').toDate(), moment().toDate()],
  },
  {
    label: 'Past Month',
    value: [moment().subtract(1, 'months').toDate(), moment().toDate()],
  },
]

function PageTitle({ children }) {
  return (
    <div className="flex flex-row gap-4 px-4 pb-2 pt-2 align-items-center">
      <span>
        <i className="text-xl pi pi-inbox mr-2" />
        Pharmacy Dashboard
      </span>
      {children}
    </div>
  )
}

// Helper function to count the number of unacknowledged dose and user activities.
const getActivityCounts = (trackableActivities) => {
  let doseActivityCount = 0
  let userActivityCount = 0

  trackableActivities.forEach((activity) => {
    if (get(activity, 'acknowledged.at')) {
      return
    }

    if (get(activity, 'trackable.type') === 'Dose') {
      doseActivityCount += 1
    }

    if (get(activity, 'trackable.type') === 'User') {
      userActivityCount += 1
    }
  })

  return {
    dose: doseActivityCount,
    user: userActivityCount,
    total: doseActivityCount + userActivityCount,
  }
}

// Helper function to get the stat and description for each dashboard card.
const getStatDescriptions = (activityCounts, organization) => {
  const { dose, user, total } = activityCounts

  const pendingReviewStat = `${total} ${total === 1 ? 'Activity' : 'Activities'}`

  let pendingReviewDescription = `For ${dose} ${dose === 1 ? 'Dose' : 'Doses'}`

  if (user === 1) {
    pendingReviewDescription += ` and ${user} ${get(organization, 'patientLabelSingular', 'Patient')}`
  } else {
    pendingReviewDescription += ` and ${user} ${get(organization, 'patientLabelPlural', 'Patients')}`
  }

  return { pendingReview: { description: pendingReviewDescription, stat: pendingReviewStat } }
}

function Dashboard({ usePharmacyDashboardHeader }) {
  const organization = useCurrentOrganization()
  const currentUser = useCurrentUser()
  const canConfirmActivities = ['pharmacist'].includes(currentUser.role)
  const { setHeader, maxDataViewHeight } = usePharmacyDashboardHeader()
  const statusMessage = useRef(null)

  const [queryParams, setQueryParams] = useSearchParams({
    view: VIEWS.pendingReview,
    timeframe: 'Past Month',
  })

  const dateRange = get(
    find(timeframeOptions, ({ label }) => label === queryParams.get('timeframe')),
    'value',
  )

  const siteIds = queryParams.getAll('siteIds')

  const { data: trackableActivities, isLoading } = useTrackableActivities({
    dateRange,
    siteIds,
    statusMessage,
  })

  const { data: { sites } = [], isLoading: sitesLoading } = useSitesQuery({ statusMessage, organizationId: get(organization, 'id') })

  const paramsToObject = (params) => Object.fromEntries(params.entries())

  const setSelectedSites = (newSiteIds) => setQueryParams((previous) => ({
    ...paramsToObject(previous), siteIds: newSiteIds,
  }))

  const setTimeframe = (timeframe) => setQueryParams((previous) => ({
    ...paramsToObject(previous), timeframe,
  }))

  const [patientNameFilter, setPatientNameFilter] = useState('')

  // TODO: This pattern should be extracted into our component library
  const title = {
    label: (
      <PageTitle organization={organization}>
        <ControlBar
          isSitesLoading={sitesLoading}
          availableSites={sites}
          selectedSites={queryParams.getAll('siteIds')}
          setSelectedSites={setSelectedSites}
          timeframeOptions={timeframeOptions}
          timeframe={queryParams.get('timeframe')}
          setTimeframe={setTimeframe}
        >
          <SearchField
            isLoading={false}
            placeholder={`Search by ${organization.patientLabelSingular} name`}
            searchTerm={patientNameFilter}
            setSearchTerm={setPatientNameFilter}
          />
        </ControlBar>
      </PageTitle>
    ),
  }

  useEffect(() => {
    setHeader({
      title, tabs: [], breadcrumbs: [], primaryAction: null,
    })
  }, [sitesLoading, queryParams, patientNameFilter])

  const activityCounts = getActivityCounts(trackableActivities)
  const statDescriptions = getStatDescriptions(activityCounts, organization)

  return (
    <div className="flex flex-column gap-1">
      <Messages ref={statusMessage} />
      <div className="flex flex-row">
        <DashboardCard
          title="Pending Review"
          isActive
          loading={isLoading}
          icon="pi-bell"
          color="yellow"
          stat={statDescriptions.pendingReview.stat}
          description={statDescriptions.pendingReview.description}
        />
      </div>
      {queryParams.get('view') === VIEWS.pendingReview && (
        <PendingReview
          canConfirmActivities={canConfirmActivities}
          organization={organization}
          maxDataViewHeight={maxDataViewHeight}
          patientNameFilter={patientNameFilter}
          timeframe={queryParams.get('timeframe')}
          trackableActivities={trackableActivities}
          trackableActivitiesLoading={isLoading}
        />
      )}
    </div>
  )
}

export default Dashboard
