import React from 'react'

import { Message } from 'primereact/message'

function OrdersPendingWarning({ ordersPending, organization }) {
  return (
    <Message
      style={{
        border: 'solid $warning-color',
        borderWidth: '0 0 0 6px',
      }}
      className="w-full justify-content-left mb-1"
      severity="warn"
      content={(
        <div className="flex flex-row justify-space-between align-items-center">
          <span className="p-inline-message-text" data-pc-section="text">
            {ordersPending}
            {' '}
            {ordersPending === 1 ? organization.patientLabelSingular
              : organization.patientLabelPlural}
            {' '}
            {ordersPending === 1 ? 'has' : 'have'}
            {' '}
            pending pharmacy orders
          </span>
          {/* TODO: Like MedicationsDueNotification, link to list of reviewable orders when we have
          a way to view those per org / site */}
        </div>
      )}
    />
  )
}

export default OrdersPendingWarning
