import React from 'react'
import { Controller } from 'react-hook-form'
import { Dropdown } from 'primereact/dropdown'
import { useFormV2Context } from '@components/display/FormV2/FormContext'

function TimezoneField({ options }) {
  const { control } = useFormV2Context()
  return (
    <Controller
      name="timezone"
      control={control}
      defaultValue="America/New_York"
      render={({ field }) => (
        <div className="flex flex-column w-6 gap-2 mb-3">
          <label htmlFor="timezone">Timezone</label>
          <Dropdown
            id="timezone"
            className="h-3rem"
            value={field.value}
            onChange={(e) => field.onChange(e.value)}
            options={options}
            optionLabel="label"
            placeholder="Select Timezone"
            filter
          />
        </div>
      )}
    />
  )
}

export default TimezoneField
