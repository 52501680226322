import { useMemo } from 'react'
import { get, map } from 'lodash'
import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

const useSiteWifis = (siteId, statusMessage) => {
  const query = useQuery({
    queryKey: ['siteWifi', siteId],
    queryFn: () => ApiClient.get(`/sites/${siteId}/wifis`),
    enabled: !!siteId,
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })

  return useMemo(() => ({
    ...query,
    data: map(get(query, 'data.siteWifis'), (wifi) => (wifi)),
  }), [query.data])
}

export default useSiteWifis
