import React from 'react'
import { classNames } from 'primereact/utils'

function Block({
  col = 12, sm, md, lg, xl, children,
}) {
  return (
    <div className={classNames(
      {
        [`col-${col}`]: col,
        [`sm:col-${sm}`]: sm,
        [`md:col-${md}`]: md,
        [`lg:col-${lg}`]: lg,
        [`xl:col-${xl}`]: xl,
      },
    )}
    >
      {children}
    </div>
  )
}

export default Block
