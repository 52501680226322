import React, { useEffect, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useSetPin } from '../../../features/SharedDevices/sharedDeviceHooks'
import RevealablePin from './RevealablePin'
import ResetPinButton from './ResetPinButton'
import RequestStatus from './RequestStatus'

function SharedPhoneGroup({ caregivers, statusMessage }) {
  const setPinMutation = useSetPin(statusMessage)
  const [pinsRevealed, setPinsRevealed] = useState({})
  const [buttonStates, setButtonStates] = useState({})

  const revealablePinTemplate = (rowData) => (
    <RevealablePin
      pin={rowData.pin}
      pinRevealed={!!pinsRevealed[rowData.id]}
      onRevealToggle={(show) => setPinsRevealed({ [rowData.id]: show })}
      isTemporaryPin={rowData.isTemporaryPin}
    />
  )

  const resetPinTemplate = (rowData) => (
    <div className="flex justify-content-end align-items-center gap-1">
      <RequestStatus buttonState={buttonStates[rowData.id]} />
      <ResetPinButton
        userId={rowData.id}
        hasPin={!!rowData.pin}
        setPin={setPinMutation.mutateAsync}
        buttonState={buttonStates[rowData.id]}
      />
    </div>
  )

  useEffect(() => {
    const userId = setPinMutation.variables
    if (setPinMutation.isLoading) {
      setButtonStates({ [userId]: 'loading' })
    }
    if (setPinMutation.isError) {
      setButtonStates({ [userId]: 'error' })
      setTimeout(() => setButtonStates({ [userId]: null }), 2000)
    }
    if (setPinMutation.isSuccess) {
      setButtonStates({ [userId]: 'success' })
      setTimeout(() => setButtonStates({ [userId]: null }), 2000)
    }
    if (setPinMutation.isSuccess) {
      setPinsRevealed({ [userId]: true })
    }
  }, [setPinMutation.isSuccess])

  return (
    <DataTable
      value={caregivers}
      dataKey="id"
      selectionMode="single"
      onSelectionChange={(e) => {
        window.location.href = `/admin/caregivers/${e.value.id}`
      }}
    >
      <Column
        header="Name"
        body={(rowData) => `${rowData.firstName} ${rowData.lastName}`}
        style={{ width: '20%' }}
        sortable
      />
      <Column
        field="pin"
        header="Pin"
        style={{ width: '10rem' }}
        body={revealablePinTemplate}
      />
      <Column header="Mobile App Version" field="mobileClientVersion" sortable />
      <Column
        body={resetPinTemplate}
      />
    </DataTable>
  )
}

export default SharedPhoneGroup
