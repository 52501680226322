import React, { useState } from 'react'
import { InputSwitch } from 'primereact/inputswitch'
import { useUpdateNotificationSetting } from '@hooks/notificationSettings'

function NotificationSettingSwitch({ notificationSetting, statusMessage }) {
  const {
    mutateAsync: updateNotificationSettings,
    isLoading,
  } = useUpdateNotificationSetting(
    notificationSetting.id,
    statusMessage,
  )

  const [isActive, setIsActive] = useState(notificationSetting.deactivatedAt === null)

  const handleToggle = (e) => {
    setIsActive(e.value)
    updateNotificationSettings({
      id: notificationSetting.id,
      enabled: e.value,
    })
  }

  return (
    <InputSwitch
      checked={isActive}
      onChange={handleToggle}
      className="notificationSettingSwitch"
      disabled={isLoading}
    />
  )
}

export default NotificationSettingSwitch
