import { Card } from 'primereact/card'
import { Divider } from 'primereact/divider'
import React, { useState } from 'react'
import { Button } from 'primereact/button'
import { classNames } from 'primereact/utils'
import FailedIntegrationData from './FailedIntegrationData'
import SucceededIntegrationData from './SucceededIntegrationData'

function Menu({ currentTab, setCurrentTab }) {
  const viewLinks = [
    {
      id: 'failed',
      label: 'Failed',
      isActive: currentTab === 'failed',
    },
    {
      label: 'Succeeded',
      id: 'succeeded',
      isActive: currentTab === 'succeeded',
    },
  ]

  return (
    <div className="flex flex-row justify-space-between align-items-center px-1">
      <div className="flex flex-row">
        {viewLinks.map((link) => (
          <Button className={classNames('flex flex-row align-items-baseline p-button-sm p-button-outlined', { 'p-button-text': !link.isActive })} onClick={() => setCurrentTab(link.id)}>
            <span className={classNames({ 'text-600': !link.isActive, 'ml-2': !!link.badge })}>
              {link.label}
            </span>
          </Button>
        ))}
      </div>
    </div>
  )
}

function FacilityIntegrationData({ facility }) {
  const [currentTab, setCurrentTab] = useState('failed')
  const [isRunDialogVisible, setIsRunDialogVisible] = useState(false)
  return (
    <Card className="h-full">
      <div className="flex flex-row justify-content-between align-items-center">
        <span className="text-lg">
          <i className="pi pi-chart-bar mr-2" />
          Pharmacy Messages
        </span>
      </div>
      <Divider />
      <div className="flex flex-row mb-4">
        <Menu currentTab={currentTab} setCurrentTab={setCurrentTab} />
        <div className="flex-grow-1" />
        <Button label="Run Failed" className="p-button-sm p-button-outlined" onClick={() => setIsRunDialogVisible(true)} />
      </div>
      <div className="mb-4" />
      {
        currentTab === 'failed' && (
        <FailedIntegrationData
          facilityId={facility?.id}
          isRunDialogVisible={isRunDialogVisible}
          setIsRunDialogVisible={setIsRunDialogVisible}
        />
        )
      }
      {
        currentTab === 'succeeded' && (<SucceededIntegrationData facilityId={facility?.id} />
        )
      }
    </Card>
  )
}

export default FacilityIntegrationData
