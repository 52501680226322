export const FulfillmentStatus = {
  pending: 'pending',
  loadedByAgency: 'loaded_by_agency',
}

export const StatusTags = {
  pending: {
    severity: 'warning',
    value: 'Pending',
  },
  loadedByAgency: {
    severity: 'success',
    value: 'Loaded',
  },
  expired: {
    severity: 'warning',
    value: 'Expired',
  },
  expiringSoon: {
    severity: 'warning',
    value: 'Expiring Soon',
  },
  finished: {
    severity: 'info',
    value: 'Finished',
  },
}

export default {}
