import React, { useRef, useState } from 'react'
import { Card } from 'primereact/card'
import { Button } from 'primereact/button'
import { isEmpty } from 'lodash'
import DoseConditionsTable from '@components/clientDoses/DoseCondition/DoseConditionsTable'
import DoseConditionDialog from '@components/clientDoses/DoseCondition/DoseConditionDialog/DoseConditionDialog'
import '../dose-conditions.scss'

function DoseConditions({
  doseSchedules,
  dose,
}) {
  const doseConditionStatusMessage = useRef(null)
  const [selectedDoseCondition, setSelectedDoseCondition] = useState(false)
  const [showCreateDoseConditionDialog, setShowCreateDoseConditionDialog] = useState(false)

  return (
    <div className="col-12 dose-conditions">
      <DoseConditionDialog
        doseSchedules={doseSchedules}
        dose={dose}
        doseConditionStatusMessage={doseConditionStatusMessage}
        selectedDoseCondition={selectedDoseCondition}
        setSelectedDoseCondition={setSelectedDoseCondition}
        showCreateDoseConditionDialog={showCreateDoseConditionDialog}
        setShowCreateDoseConditionDialog={setShowCreateDoseConditionDialog}
      />
      <Card className="h-full">
        <div className="flex flex-column gap-3 text-base text-lg">
          <div className="flex flex-row justify-content-between align-items-center">
            <div>
              Criteria / Vitals
            </div>
            {!isEmpty(dose) && (
            <Button
              label="Add Criteria / Vitals"
              icon="fa-solid fa-ruler"
              className="p-button-sm p-button-info"
              onClick={() => setShowCreateDoseConditionDialog(true)}
            />
            )}
          </div>
        </div>
        {!isEmpty(dose) && (
          <DoseConditionsTable
            dose={dose}
            setSelectedDoseCondition={setSelectedDoseCondition}
          />
        )}
      </Card>
    </div>
  )
}

export default DoseConditions
