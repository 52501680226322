import React from 'react'
import { Button } from 'primereact/button'

function ResetPinButton({
  userId, hasPin, setPin, buttonState,
}) {
  return (
    <Button
      iconPos="left"
      loading={buttonState === 'loading'}
      aria-label="Reset Pin"
      onClick={() => setPin(userId)}
      label={hasPin ? 'Reset' : 'Setup'}
    />
  )
}

export default ResetPinButton
