import React from 'react'
import CustomTag from '@components/display/CustomTag'

const getPrimaryDirective = (directives) => {
  if (directives.find((d) => d.directive === 'cancel_treatment')) {
    return { color: 'var(--gray-400)', text: 'Cancel Treatment' }
  }

  if (directives.find((d) => d.directive === 'discontinue_treatment')) {
    return { color: 'var(--gray-400)', text: 'Discontinue Treatment' }
  }

  if (directives.find((d) => d.directive === 'new_treatment')) {
    return { color: 'var(--green-400)', text: 'New Treatment' }
  }

  if (directives.find((d) => d.directive === 'treatment_update')) {
    return { color: 'var(--yellow-400)', text: 'Treatment Update' }
  }

  return { color: 'var(--gray-400)', text: 'Unknown Change' }
}

function TreatmentDirectiveTag({ treatment, directives = [] }) {
  if (!treatment) {
    return null
  }

  let directive

  if (Array.isArray(directives) && directives.length > 0) {
    directive = getPrimaryDirective(directives)
  } else {
    directive = getPrimaryDirective([{ directive: treatment.onboardedAt ? 'treatment_update' : 'new_treatment' }])
  }

  return <CustomTag color={directive.color} text={directive.text} />
}

export default TreatmentDirectiveTag
