import React, { useState, useRef, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import {
  get, isEmpty, sumBy, orderBy, flatMap,
} from 'lodash'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import DrawerSlots from '@components/inventoryManagement/DrawerSlots'
import Drawer from '@components/inventoryManagement/Drawer'
import { FulfillmentStatus } from '@components/inventoryManagement/DoseRefill'
import moment from 'moment'
import { useEkitQuery, useOpenDrawer } from './ekitHooks'
import { dateTemplate } from '../../services/utils'
import { LABELS, E_KITS_PATH } from '../MedBoxes/config'

function Header({ ekit = {}, isLoading }) {
  return (
    <div className="flex flex-row justify-space-between align-items-baseline">
      <span className="text-base font-normal mb-0">
        { isLoading ? 'Loading' : `Configuration for ${ekit.label}` }
      </span>
      { !isLoading
        && (
        <span className="text-sm font-normal">
          MAC:
          {ekit.mac}
        </span>
        )}
    </div>
  )
}

function EkitsConfiguration({ useMedBoxesHeader }) {
  const statusMessage = useRef(null)
  const { medBoxId } = useParams()
  const [expandedRows, setExpandedRows] = useState(null)
  const { data: ekit, isLoading } = useEkitQuery(medBoxId, statusMessage)
  const { openDrawer } = useOpenDrawer(medBoxId, statusMessage)
  const { setHeader } = useMedBoxesHeader()

  const breadcrumbs = [
    {
      label: LABELS.ekit,
      template: <Link to={`/${E_KITS_PATH}/${medBoxId}`}>{LABELS.ekit}</Link>,
    },
    {
      label: 'Configure',
      template: <span>Configure</span>,
    },
  ]

  const title = {
    label: (
      <span>
        <i className="text-xl pi pi-box mr-2" />
        {ekit?.label}
      </span>
    ),
    linkTo: `/${E_KITS_PATH}/${medBoxId}`,
  }

  useEffect(() => {
    setHeader({
      title,
      tabs: [],
      breadcrumbs,
      primaryAction: null,
    })
  }, [ekit?.label])

  const rowClass = (data) => ({
    'border-primary': get(expandedRows, data.id),
  })

  const assignedSlots = (drawer) => {
    const slots = get(drawer, 'drawerSlots', [])
    if (!isEmpty(slots)) {
      return sumBy(slots, (slot) => (!isEmpty(slot.doseFills) ? 1 : 0))
    }
    return get(drawer, 'doseFills', []).length
  }

  const drawerColor = ({ color }) => <div className="shadow-1" style={{ height: '1rem', width: '1rem', backgroundColor: color }} />

  const earliestExpirationDate = (drawer) => {
    const slots = get(drawer, 'drawerSlots', [])
    const doseFills = isEmpty(slots) ? drawer.doseFills : flatMap(slots, 'doseFills')
    if (isEmpty(doseFills)) return ''
    const earliestExpiration = moment.min(doseFills.map((fill) => moment(fill.expiresAt)))

    return dateTemplate(earliestExpiration.toDate())
  }

  const pendingDoseFills = (drawer) => {
    const slots = get(drawer, 'drawerSlots', [])
    if (!isEmpty(slots)) {
      return slots.reduce((count, slot) => {
        let newCount = count
        slot.doseFills.forEach((fill) => {
          if (fill.status === FulfillmentStatus.pending) {
            newCount += 1
          }
        })
        return newCount
      }, 0)
    }
    return drawer.doseFills.reduce((count, fill) => {
      let newCount = count
      if (fill.status === FulfillmentStatus.pending) {
        newCount += 1
      }
      return newCount
    }, 0)
  }

  const openDrawerColumn = ({ position }) => (
    <Button
      icon="pi pi-lock"
      className="p-button-text p-0"
      aria-label="Submit"
      onClick={() => openDrawer(position)}
    />
  )

  const renderDrawerContents = (data) => {
    const slots = get(data, 'drawerSlots', [])
    if (isEmpty(slots)) {
      return (
        <Drawer
          doseFills={data.doseFills}
          ekitId={medBoxId}
          siteId={ekit.siteId}
          drawer={data}
        />
      )
    }
    const orderedSlots = orderBy(slots, ['yPos', 'xPos'], 'desc')
    return (
      <DrawerSlots
        slots={orderedSlots}
        ekitId={medBoxId}
        siteId={ekit.siteId}
        drawer={data}
      />
    )
  }

  return (
    <>
      <Toast ref={statusMessage} />
      <DataTable
        loading={isLoading}
        value={get(ekit, 'simpillDrawers', [])}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowClassName={rowClass}
        rowExpansionTemplate={renderDrawerContents}
        dataKey="id"
        header={<Header ekit={ekit} isLoading={isLoading} />}
      >
        <Column expander style={{ width: '3em' }} bodyClassName="configuration-expander" />
        <Column body={drawerColor} header="Color" />
        <Column field="position" header="Position From Top" />
        <Column body={assignedSlots} header="Assigned Slots" />
        <Column body={earliestExpirationDate} header="Earliest Expiration Date" />
        <Column body={pendingDoseFills} header="Pending Dose Refills" />
        <Column body={openDrawerColumn} header="Open Drawer" />
      </DataTable>
    </>
  )
}

export default EkitsConfiguration
