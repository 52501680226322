import React, { useRef } from 'react'
import IntegrationDataTable from '@components/integrationData/IntegrationDataTable'
import { useFacilityIntegrationDataQuery } from '@components/integrationData/integrationDataHooks'
import RunMessagesDialog from '@components/integrationData/RunMessagesDialog'
import { Toast } from 'primereact/toast'

function FailedIntegrationData({ facilityId, isRunDialogVisible, setIsRunDialogVisible }) {
  const messagesRef = useRef()
  const {
    data: failedData,
    isLoading,
  } = useFacilityIntegrationDataQuery(facilityId, 'failed')

  return (
    <div>
      <Toast ref={messagesRef} />
      <RunMessagesDialog
        facilityId={facilityId}
        isVisible={isRunDialogVisible}
        setIsVisible={setIsRunDialogVisible}
        integrationData={failedData}
        messagesRef={messagesRef}
      />
      <IntegrationDataTable
        integrationData={failedData}
        isLoading={isLoading}
        showIgnored={false}
        showProcessed={false}
      />
    </div>
  )
}

export default FailedIntegrationData
