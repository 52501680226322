import { useMemo } from 'react'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import {
  map,
} from 'lodash'
import { displayErrorMessages } from '@services/errorHandler'

export function useSSOPendingUsers({ organizationId, role, enabled }) {
  const query = useQuery({
    queryKey: ['pendingSSOUsers', organizationId, role],
    queryFn: () => ApiClient.get('/directory_users/pending', { params: { organizationId, role } }),
    enabled,
  })

  return useMemo(() => ({
    ...query,
    data: map(query.data, (user) => (user)),
  }), [query.data])
}

export function useOnboardSSOUsers(directoryUserId, statusMessage) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['onboardSSOUsers', directoryUserId],
    mutationFn: (params = {}) => ApiClient.post(`/directory_users/${directoryUserId}/onboard`, params),
    onSuccess: () => {
      statusMessage.current.show([
        { severity: 'success', summary: 'SSO User onboarded successfully' },
      ])
      queryClient.invalidateQueries('pendingSSOUsers')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useSSOLinkableUsers(statusMessage, directoryUserId) {
  const query = useQuery({
    queryKey: ['linkableSSOUsers', directoryUserId],
    queryFn: () => ApiClient.get('/linkable_users/for_directory_user/', { params: { directoryUserId } }),
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })

  return useMemo(() => ({
    ...query,
    data: map(query.data?.linkableUsers, (user) => (user)),
  }), [query.data])
}
