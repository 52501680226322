import ApiClient from '@services/ApiClient'
import { get } from 'lodash'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export function useDoseRefill(doseId, shouldCreate, statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['doseRefill', doseId],
    mutationFn: async ({ doseFill }) => {
      await ApiClient.post('/ekits/dose_fills', { doseFill, doseId })
    },
    onSuccess: () => {
      queryClient.invalidateQueries('ekit', 'organizationDoseFills')
    },
    onError: (error) => statusMessage.current.show([{
      severity: 'error',
      sticky: true,
      summary: 'Dose Refill Error: ',
      detail: get(error, 'response.data.error'),
    }]),
  })
}

export default {}
