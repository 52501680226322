import React, { useState, useEffect, useCallback } from 'react'
import { Button } from 'primereact/button'
import { errorMessageFromError } from '@services/errorHandler'
import { useSSOLogin } from '../authHooks'
import { useAuth } from '../../../context/AuthContext'

export default function LoginWithSSOButton({ statusMessage }) {
  const [loginButtonClicked, setLoginButtonClicked] = useState(false)
  const { email, eulaAccepted } = useAuth()
  const { data: sso, error, isFetching } = useSSOLogin(email, loginButtonClicked)

  useEffect(() => {
    if (error) {
      statusMessage.current.clear()
      statusMessage.current.show([errorMessageFromError(error)])
      setLoginButtonClicked(false)
    }
  }, [error, statusMessage])

  useEffect(() => {
    if (loginButtonClicked && sso?.authorizationUrl) {
      window.location.href = sso.authorizationUrl
    }
  }, [sso])

  const handleSSOLoginClick = useCallback(() => {
    setLoginButtonClicked(true)
  }, [])

  return (
    <div className="field text-center">
      <Button
        className="flex flex-row justify-content-center gap-2 p-button-filled w-full login-button-sso"
        onClick={handleSSOLoginClick}
        disabled={isFetching || !eulaAccepted || !email}
        rounded
      >
        {isFetching && <i className="pi pi-spin pi-spinner mr-2" />}
        <div className="flex justify-content-center align-items-center flex-grow-1">
          <i className="pi pi-cloud font-medium text-xl" />
          <span className="ml-2 font-medium">Login with SSO</span>
        </div>
      </Button>
    </div>
  )
}
