import React, { useState } from 'react'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import moment from 'moment'
/**
 * @typedef {moment.Moment} Moment
 */

/**
 *
 * @param {Object} props
 * @param {Moment} props.momentDate The moment date value, it will be convered to local time
 * @param {Function} props.setMomenDate Callback to set the moment date, the moment value is in
 * local time so be sure to convert it to the timezone you need
 * @param {Boolean} props.isLoading If the component is in a loading state
 * @param {Moment} props.min Minimum date allowed, it will be converted to local time
 * @param {Moment} props.max Maximum date allowed, it will be converted to local time
 * @param {Boolean} props.showButtonBar If the calendar should show the button bar
 * @returns
 */
function DateEditor({
  momentDate,
  setMomenDate,
  isLoading,
  min,
  max,
  showButtonBar,
}) {
  const [formMoment, setFormMoment] = useState(momentDate || moment())

  const handleConfirmation = () => {
    setMomenDate(formMoment)
  }

  return (
    <div className="flex flex-row gap-2 align-items-center">
      <Calendar
        inputId="dose-expiration-date"
        data-testid="expiration-date"
        value={formMoment.toDate()}
        minDate={min?.toDate()}
        maxDate={max?.toDate()}
        showIcon
        showButtonBar={showButtonBar}
        onChange={(e) => setFormMoment(moment(e.value))}
      />
      <Button
        onClick={handleConfirmation}
        loading={isLoading}
        icon="pi pi-check"
      />
    </div>
  )
}

export default DateEditor
