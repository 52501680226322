import React, { useEffect, useState, useRef } from 'react'
import { isEqual } from 'lodash'
import { Outlet, useOutletContext } from 'react-router-dom'
import PageHeader from '../PageHeader'
import './page-layout.scss'

export const usePageOutlet = () => useOutletContext()

function PageLayout({ rootPath, rootLabel }) {
  const layoutRef = useRef(null)
  const headerRef = useRef(null)
  const [height, setHeight] = useState()
  const [maxDataViewHeight, setMaxDataViewHeight] = useState()
  const [header, setHeader] = useState({
    rootPath,
    rootLabel,
    title: '',
    breadcrumbs: [],
    primaryAction: null,
    notifications: [],
  })

  const handleSetHeader = (props) => {
    if (isEqual(header, props)) {
      return
    }
    setHeader((previous) => ({ ...previous, ...props }))
  }

  useEffect(() => {
    const layout = layoutRef.current
    const headerElement = headerRef.current
    if (layout) {
      const viewportHeight = window.innerHeight
      const top = layout.offsetTop
      const maxLayoutHeight = viewportHeight - top
      setHeight(maxLayoutHeight)
      setMaxDataViewHeight(maxLayoutHeight - headerElement.clientHeight - 95)
    }
  }, [header])

  return (
    <div ref={layoutRef} className="bg-blue-50 overflow-auto page-layout" style={{ height }}>
      <div className="md:sticky top-0 z-5" ref={headerRef}>
        <PageHeader {...header} />
      </div>
      <div className="w-full p-2">
        <Outlet context={{
          setHeader:
          handleSetHeader,
          layoutRef,
          maxDataViewHeight,
          rootPath,
        }}
        />
      </div>
    </div>
  )
}

export default PageLayout
