import React from 'react'
import Box from './Box'
import Block from '../Block'

export default {
  title: 'Layout/Box',
  component: Box,
  subcomponents: { Block },
}

const props = {
  direction: 'row',
  wrap: true,
}

export function Row() {
  return (
    <Box>
      <Block col={4}>
        <div className="p-3 border-1 border-primary text-center">
          <span>4 Column Block</span>
        </div>
      </Block>
      <Block col={4}>
        <div className="p-3 border-1 border-primary text-center">
          <span>4 Column Block</span>
        </div>
      </Block>
      <Block col={4}>
        <div className="p-3 border-1 border-primary text-center">
          <span>4 Column Block</span>
        </div>
      </Block>
    </Box>
  )
}

Row.args = props

export function Column(args) {
  return (
    <Box {...args}>
      <Block col={3}>
        <div className="p-3 border-1 border-primary text-center">
          <span>6 Column Block</span>
        </div>
      </Block>
      <Block col={6}>
        <div className="p-3 border-1 border-primary text-center">
          <span>6 Column Block</span>
        </div>
      </Block>
      <Block col={9}>
        <div className="p-3 border-1 border-primary text-center">
          <span>9 Column Block</span>
        </div>
      </Block>
    </Box>
  )
}

Column.args = { ...props, direction: 'column' }
