import React, { useState } from 'react'
import { Dropdown } from 'primereact/dropdown'

// This component creates a dropdown that reloads the page when the value
// changes adding the paramName to the query string and
// preserving the existing query string parameters.

function ReloadDropdown({
  options,
  value,
  inputId,
  placeholder,
  showClear,
  paramName,
  style,
  optionGroupLabel,
  optionGroupChildren,
  optionLabel,
}) {
  const [selectedValue, setSelectedValue] = useState(value)
  return (
    <Dropdown
      data-testid={inputId}
      inputId={inputId}
      value={selectedValue || null}
      options={options}
      placeholder={placeholder}
      showClear={showClear}
      style={style}
      optionGroupLabel={optionGroupLabel}
      optionGroupChildren={optionGroupChildren}
      optionLabel={optionLabel}
      onChange={(e) => {
        setSelectedValue(e.value)
        const params = new URLSearchParams(window.location.search)
        if (e.value) {
          params.set(paramName, e.value)
        } else {
          params.set(paramName, '')
        }
        window.location.href = `${window.location.pathname}?${params.toString()}`
      }}
    />
  )
}

export default ReloadDropdown
