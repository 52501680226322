import React from 'react'
import { InputSwitch } from 'primereact/inputswitch'

function PrnEditor({ prn, setPrn }) {
  return (
    <div className="px-3 py-1">
      <label htmlFor="prn">
        <InputSwitch checked={prn} onChange={(e) => setPrn(e.value)} inputId="prn" />
      </label>
    </div>
  )
}

export default PrnEditor
