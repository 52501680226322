import { useMutation, useQueryClient } from '@tanstack/react-query'
import conditionTypes from '@components/conditions/config'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

const bulkUpsertMeasurements = ({
  patientId,
  consumption,
  taskOccurrence,
  conditionChecks,
}) => {
  const measurements = conditionChecks.map((conditionCheck) => {
    const measurement = {
      type: conditionCheck.condition.conditionType,
      checkId: conditionCheck.id,
      conditionId: conditionCheck.condition.id,
    }

    if (consumption) {
      measurement.consumptionId = consumption.id
      measurement.takenById = consumption.confirmedById || patientId
    }

    if (taskOccurrence) {
      measurement.occurrenceId = taskOccurrence.id
      measurement.takenById = taskOccurrence.actionedById || patientId
    }

    const conditionType = conditionTypes.find((type) => type.code === measurement.type)

    measurement.id = conditionCheck[conditionType.measurementKey].id

    conditionType.values.forEach((value) => {
      const valueKey = value.split('.')[1]
      measurement[valueKey] = conditionCheck[conditionType.measurementKey][valueKey]
    })

    if (conditionCheck[conditionType.measurementKey].unit) {
      measurement.unit = conditionCheck[conditionType.measurementKey].unit
    }

    return measurement
  })

  return ApiClient.post('/measurements/bulk_upsert', {
    patientId,
    measurements,
  })
}

const useUpsertMeasurements = ({ patientId, statusMessage, mode = 'tar' }) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params) => {
      if (!params.conditionChecks || params.conditionChecks.length === 0) {
        return
      }

      return bulkUpsertMeasurements({ ...params, patientId })
    },
    onSuccess: async () => {
      const summary = mode === 'tar' ? 'TAR Update Successful!' : 'MAR Update Successful!'

      statusMessage.current.clear()
      statusMessage.current.show([
        { severity: 'success', summary },
      ])

      if (mode === 'tar') {
        await queryClient.invalidateQueries('tarDashboard')
        await queryClient.invalidateQueries('taskOccurrenceHistory')
      } else {
        await queryClient.invalidateQueries('marDashboard')
        await queryClient.invalidateQueries('consumptionHistory')
      }
    },
    onError: async (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export default useUpsertMeasurements
