import React, { useRef, useState } from 'react'
import { get } from 'lodash'
import { Toast } from 'primereact/toast'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import moment from 'moment'
import { formatAdministrationAmount, isPrn } from '@components/clientDoses/doseUtils'
import ScheduleEditor from '@components/clientDoses/Schedule/ScheduleEditor/ScheduleEditor'
import SigActionMenu from '@components/clientDoses/Sig/SigActionMenu'
import AttributeCard from '@components/display/AttributeCard'
import {
  DoseAttributeRow,
  DateEditor,
  TextAreaEditor,
  EditButton,
  AdministrationAmountEditor,
} from '@components/DoseAttributeRow'
import { AddTimeEditor } from '@components/pharmacyOrders/Editors'
import { useCreateDoseSchedule, useDestroyDoseSchedule, useUpdateDoseSchedule } from '@hooks//schedules'
import { sigHeader } from '@services/utils/sig'
import {
  momentFormats, momentFromIso8601Str, momentTzWihtoutChangingTime,
} from '@services/utils/moment'
import { useUpdateDoseSig } from '../clientDosesHooks'
import './client-dose.scss'

function SigTable({ dose, sig, timezone }) {
  const statusMessages = useRef(null)

  const {
    mutateAsync: updateDoseSig,
    isLoading,
  } = useUpdateDoseSig({
    doseSigId: sig.id,
    statusMessage: statusMessages,
  })

  const {
    mutateAsync: createSchedule,
  } = useCreateDoseSchedule(statusMessages)

  const {
    mutateAsync: updateSchedule,
  } = useUpdateDoseSchedule(statusMessages)

  const {
    mutateAsync: destroySchedule,
  } = useDestroyDoseSchedule(statusMessages)

  const [editAttribute, setEditAttribute] = useState(null)

  const handleUpdateAttribute = (attribute, value) => {
    updateDoseSig({
      id: sig.id,
      [attribute]: value,
    }, {
      onSuccess: () => {
        setEditAttribute(null)
      },
    })
  }

  const handleCreateSchedule = ({ schedule }) => createSchedule({
    doseId: dose.id,
    schedule: {
      doseSigId: sig.id,
      flexibilityAttributes: schedule.flexibilityAttributes,
      rrule: schedule.rrule,
      time_hh_mm: schedule.time_hh_mm,
    },
  })

  const handleUpdateSchedule = ({ schedule }) => updateSchedule({
    doseId: dose.id,
    schedule: {
      id: schedule.id,
      flexibilityAttributes: schedule.flexibilityAttributes,
      rrule: schedule.rrule,
      time_hh_mm: schedule.time_hh_mm,
    },
  })

  const handleDeleteSchedule = ({ schedule }) => destroySchedule({
    doseId: dose.id,
    scheduleId: schedule.id,
  })

  const handleSetPrn = async (prn) => updateDoseSig({ id: sig.id, prn })

  // end at can't be the same or before start at
  const minEndAt = moment(get(dose, 'startAt'))

  // const schedules = sig?.schedules
  const schedules = sig?.schedules?.map((schedule) => ({
    ...schedule,
    // TODO: This is a hack to avoid refactoring a bunch of schedule components. We should remove
    //       this and refactor frontend schedule components after the introduction of TypeScript.
    momentTime: moment().set({
      hour: schedule.timeHours,
      minute: schedule.timeMinutes,
      second: 0,
      millisecond: 0,
    }).valueOf(),
  }))

  const doseAttributes = [
    {
      label: 'Pharmacy Instructions',
      currentValue: sig?.pharmacyInstructions,
      display: !!sig?.pharmacyInstructions,
    },
    {
      label: 'Administration Amount',
      currentValue: formatAdministrationAmount({
        administrationAmount: sig?.administrationAmount,
        medicine: dose.medicine,
      }),
      action: sig?.source === 'PHARMACY' ? (
        <EditButton
          onClick={() => (editAttribute === 'administrationAmount' ? setEditAttribute(null) : setEditAttribute('administrationAmount'))}
          isEditing={editAttribute === 'administrationAmount'}
          loading={isLoading}
          disabled={editAttribute && editAttribute !== 'administrationAmount'}
        />
      ) : null,
      editor: <AdministrationAmountEditor
        administrationAmount={sig?.administrationAmount}
        setAdministrationAmount={(value) => handleUpdateAttribute('administrationAmount', value)}
        isLoading={isLoading}
        medicine={dose.medicine}
        units={dose.units}
      />,
      isEditing: editAttribute === 'administrationAmount',
    },
    {
      label: 'Start Date',
      currentValue: sig?.startAtWall
        ? momentFromIso8601Str(sig.startAtWall).tz(timezone).format(momentFormats.dateYear) : null,
      action: <EditButton
        onClick={() => (editAttribute === 'startAt' ? setEditAttribute(null) : setEditAttribute('startAt'))}
        isEditing={editAttribute === 'startAt'}
        loading={isLoading}
        disabled={editAttribute && editAttribute !== 'startAt'}
      />,
      editor: <DateEditor
        momentDate={sig?.startAt
          ? momentFromIso8601Str(sig.startAtWall)
          : null}
        setMomenDate={(value) => {
          const startToSet = momentTzWihtoutChangingTime(value.startOf('day'), timezone)
          return handleUpdateAttribute('startAt', startToSet.toISOString())
        }}
        max={sig?.endAt ? moment(sig.endAt) : null}
        isLoading={isLoading}
      />,
      isEditing: editAttribute === 'startAt',
    },
    {
      label: 'End Date',
      currentValue: sig?.endAtWall
        ? momentFromIso8601Str(sig.endAtWall).format(momentFormats.dateYear) : null,
      action: (
        <EditButton
          onClick={() => (editAttribute === 'endAt' ? setEditAttribute(null) : setEditAttribute('endAt'))}
          isEditing={editAttribute === 'endAt'}
          loading={isLoading}
          disabled={editAttribute && editAttribute !== 'endAt'}
        />
      ),
      editor: <DateEditor
        momentDate={sig?.endAtWall
          ? momentFromIso8601Str(sig.endAtWall).tz(timezone)
          : null}
        min={minEndAt}
        setMomenDate={(value) => {
          if (!value) {
            return handleUpdateAttribute('endAt', null)
          }
          const newEnd = momentTzWihtoutChangingTime(value.endOf('day'), timezone)
          if (newEnd.startOf('day').isBefore(moment().startOf('day'))) {
            confirmDialog({
              message: 'The end date is before today, are you sure you want to continue?',
              header: 'Warning',
              accept: () => handleUpdateAttribute('endAt', newEnd.toISOString()),
              icon: 'pi pi-exclamation-triangle',
            })
          } else {
            return handleUpdateAttribute('endAt', newEnd.toISOString())
          }
        }}
        isLoading={isLoading}
        showButtonBar
      />,
      isEditing: editAttribute === 'endAt',
    },
    {
      label: 'Notes',
      currentValue: sig.note,
      action: <EditButton
        onClick={() => (editAttribute === 'note' ? setEditAttribute(null) : setEditAttribute('note'))}
        isEditing={editAttribute === 'note'}
        loading={isLoading}
        disabled={editAttribute && editAttribute !== 'note'}
      />,
      isEditing: editAttribute === 'note',
      editor: <TextAreaEditor
        text={sig.note}
        setText={(value) => handleUpdateAttribute('note', value)}
        isLoading={isLoading}
      />,
    },
    {
      label: 'Schedule',
      display: !sig.prn,
      currentValue: (
        <ScheduleEditor
          schedules={schedules}
          handleUpdateSchedule={handleUpdateSchedule}
          handleDeleteSchedule={handleDeleteSchedule}
        />
      ),
      action: (
        <AddTimeEditor
          handleCreateSchedule={handleCreateSchedule}
          schedulableType="DoseSig"
        />
      ),
    },
  ]

  const actionMenuTemplate = () => (
    <SigActionMenu
      handleSetPrn={handleSetPrn}
      isLoading={isLoading}
      sig={sig}
    />
  )

  const tags = [{ value: isPrn(dose, sig) ? 'prn' : 'schedule', rounded: false, severity: 'info' }]

  return (
    <div className="col-12 dosing-table" data-testid="sig-table">
      <Toast ref={statusMessages} />
      <ConfirmDialog />
      <AttributeCard
        ActionComponent={actionMenuTemplate}
        tags={tags}
        title={{ label: sigHeader(sig.sigNumber) }}
      >
        <ul className="list-none p-0 m-0 dose-order-fields">
          {doseAttributes
            .filter(({ display = true }) => display)
            .map(({
              label, currentValue, newValue, action, valueChangeHandler, editor, isEditing,
            }, index) => (
              <DoseAttributeRow
                key={label}
                label={label}
                valueChangeHandler={valueChangeHandler}
                currentValue={currentValue}
                newValue={newValue}
                action={action}
                editor={editor}
                isEditing={isEditing}
                backgroundHighlight={index % 2 === 0}
              />
            ))}
        </ul>
      </AttributeCard>
    </div>
  )
}

export default SigTable
