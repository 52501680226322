import React, { useState, useEffect } from 'react'
import { first } from 'lodash'
import { AutoComplete } from 'primereact/autocomplete'
import { Button } from 'primereact/button'

function MedicinePackaging({
  medicinePackaging, setMedicinePackaging, medicineForm, handleConfirm,
}) {
  const oralPackagingOptions = [
    'Pill Bottle',
    'Single Dose Blister Pack',
    'Multiple Dose Blister Pack',
    'Single Dose Pill Packet',
    'Multiple Dose Pill Packet',
  ]

  const injectablePackagingOptions = [
    'Ampoule',
    'Vial',
    'Prefilled Syringes',
    'Cartridges',
    'Auto Injector',
  ]

  const ointmentPackagingOptions = [
    'Ointment Tube',
    'Bottle',
  ]

  const allPackagingOptions = [
    ...oralPackagingOptions,
    ...injectablePackagingOptions,
    ...ointmentPackagingOptions,
  ]

  const [packagingOptions, setPackagingOptions] = useState(allPackagingOptions)
  const [filteredMedicinePackaging, setFilteredMedicinePackaging] = useState(packagingOptions)

  const filterMedicinePackaging = (event) => {
    setTimeout(() => {
      let filtered
      if (!event.query.trim().length) {
        filtered = [...packagingOptions]
      } else {
        filtered = packagingOptions.filter((form) => (
          form.toLowerCase().includes(event.query.toLowerCase())
        ))
      }
      setFilteredMedicinePackaging(filtered)
    }, 250)
  }

  useEffect(() => {
    if (medicineForm) {
      if (medicineForm.includes('Oral')) {
        setPackagingOptions(oralPackagingOptions)
        setMedicinePackaging(first(oralPackagingOptions))
      } else if (medicineForm.includes('Inject')) {
        setPackagingOptions(injectablePackagingOptions)
        setMedicinePackaging(first(injectablePackagingOptions))
      } else if (medicineForm.includes('Ointment')) {
        setPackagingOptions(ointmentPackagingOptions)
        setMedicinePackaging(first(ointmentPackagingOptions))
      }
    } else {
      setPackagingOptions(allPackagingOptions)
    }
  }, [medicineForm])

  return (
    <div className="grid flex flex-grow-1 justify-content-center align-items-center fadein">
      <div className="col-9 flex flex-column gap-2 text-center">
        <label className="text-lg font-normal mb-1" htmlFor="dose-medicine-packaging">
          How is this medicine packaged?
          <p className="text-sm font-normal">Example, Blister Packet, Pill Bottle etc...</p>
        </label>
        <AutoComplete
          inputId="dose-medicine-packaging"
          value={medicinePackaging}
          data-testid="medicine-packaging"
          suggestions={filteredMedicinePackaging}
          completeMethod={filterMedicinePackaging}
          dropdown
          onChange={(e) => setMedicinePackaging(e.value)}
          aria-label="Medicine Packaging"
          dropdownarialabel="Select Medicine Packaging"
        />
        <Button
          onClick={handleConfirm}
          label="Next"
          className="p-button-text mt-2"
          disabled={!medicinePackaging}
        />
      </div>
    </div>
  )
}

export default MedicinePackaging
