import React from 'react'
import '@components/Theme'
import { MultiSelect } from 'primereact/multiselect'
import { Dropdown } from 'primereact/dropdown'
import { sortBy } from 'lodash'

function groupAndFormatSites(sites) {
  if (!sites) return []
  return sites.reduce((acc, site) => {
    const { organization } = site
    const organizationSites = acc.find(
      (organizationSite) => organizationSite.label === organization.name,
    )
    if (organizationSites) {
      organizationSites.value.push({
        label: site.name,
        value: site.id,
      })
    } else {
      acc.push({
        label: organization.name,
        value: [
          {
            label: site.name,
            value: site.id,
          },
        ],
      })
    }
    return acc
  }, [])
}

function SitesSelect({
  sites,
  onChange,
  selectedSites,
  isSsoOnboarding = false,
  placeholder,
  singleSelection,
  oneOrganization = false,
  loading,
  inputId = 'sites-select',
  ...props
}) {
  if (loading) {
    return <div>Loading...</div>
  }
  const sortedSites = sortBy(sites, 'name')
  if (oneOrganization) {
    return (
      <MultiSelect
        inputId={inputId}
        style={{ maxWidth: props.maxWidth || '17rem' }}
        display="chip"
        options={sortedSites}
        onChange={(e) => onChange(e.value)}
        value={selectedSites}
        placeholder={placeholder || 'Select Sites'}
        optionLabel="name"
        optionValue="id"
        filter
        filterPlaceholder="Search"
        showSelectAll={false}
        disabled={isSsoOnboarding}
        {...props}
      />
    )
  }

  let dropdownGroupingProps = {
    optionGroupLabel: 'label',
    optionGroupChildren: 'value',
    ...props,
  }

  let sitesByOrg = groupAndFormatSites(sortedSites)
  if (sitesByOrg.length === 1) {
    sitesByOrg = sitesByOrg[0].value
    dropdownGroupingProps = { ...props }
  }

  if (singleSelection) {
    return (
      <Dropdown
        inputId={inputId}
        options={sitesByOrg}
        onChange={(e) => onChange([e.value])}
        value={selectedSites[0]}
        placeholder={placeholder || 'Select Sites'}
        optionLabel="label"
        filter
        filterPlaceholder="Search"
        showClear
        {...dropdownGroupingProps}
      />
    )
  }

  return (
    <MultiSelect
      inputId={inputId}
      options={groupAndFormatSites(sortedSites)}
      onChange={(e) => onChange(e.value)}
      value={selectedSites}
      placeholder={placeholder || 'Select Sites'}
      optionLabel="label"
      optionGroupLabel="label"
      optionGroupChildren="value"
      filter
      filterPlaceholder="Search"
      showSelectAll={false}
      disabled={isSsoOnboarding}
      {...props}
    />
  )
}

export default SitesSelect
