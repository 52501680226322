import { startCase, toLower } from 'lodash'

/**
 * @param {Integer} number
 * @param {String} language
 * @returns {String|null}
 */
export const numberToOrdinalWord = (number, language = 'en') => {
  const ordinalWords = {
    en: ['First', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth', 'Seventh', 'Eighth', 'Ninth'],
  }

  const selectedWords = ordinalWords[language] || ordinalWords.en

  if (number < 1 || number > selectedWords.length) {
    return null
  }

  return selectedWords[number - 1]
}

/**
 * Converts a snake case string to title case (e.g. 'on_hold' to 'On Hold').
 *
 * @param {string} text - The snake case string to be converted.
 * @returns {string} - The converted title case string.
 */
export const titleize = (text) => startCase(toLower(text))

/**
 * Converts a string to a valid CSS class name.
 *
 * @param {String} str
 * @param {String} elementType
 * @returns {String|null}
 */
export const convertToCssClassName = (str, elementType) => {
  const validElementTypes = ['tooltip']

  if (typeof str !== 'string' || str.trim() === '' || !validElementTypes.includes(elementType)) {
    return null
  }

  const baseClassName = str.trim()
    .toLowerCase()
    .replace(/[^a-z0-9\s]/g, '') // Remove special characters
    .replace(/\s+/g, '-') // Replace spaces with hyphens

  return `${baseClassName}-${elementType}`
}

export default {}
