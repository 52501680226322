import React, { useRef, useState } from 'react'
import { DataView } from 'primereact/dataview'
import { DoseDetails } from '@components/pharmacyOrders/Details'
import { Divider } from 'primereact/divider'
import { TabMenu } from 'primereact/tabmenu'
import { Toast } from 'primereact/toast'
import TreatmentDetails from '../treatments/ReviewableTreatmentsList/TreatmentDetails'
import {
  useIgnoredPrescriptions,
  useUndoIgnoredPrescription,
  useIgnoredTreatments,
  useUndoIgnoredTreatment,
} from '../../features/ClientDoses/PharmacyOrders/pharmacyOrderHooks'

function IgnoredOrders({ patientId, showTreatments }) {
  const statusMessage = useRef(null)
  const [activeIndex, setActiveIndex] = useState(0)

  const {
    isLoading: isLoadingPrescriptions,
    data: ignoredPrescriptions,
  } = useIgnoredPrescriptions(patientId)

  const {
    mutateAsync: undoIgnoredPrescription,
    isLoading: undoPrescriptionIsLoading,
  } = useUndoIgnoredPrescription(statusMessage)

  const prescriptionTemplate = ({ doseDirectives, compositeOrder, prescription }) => {
    const handleUndo = async () => {
      await undoIgnoredPrescription(prescription.id)
    }

    return (
      <div className="w-full">
        <DoseDetails
          doseDirectives={doseDirectives}
          pharmacyMedicine={compositeOrder.pharmacyMedicine}
          orderType={compositeOrder.orderType}
          prescription={prescription}
          sigs={compositeOrder.sigs}
          handleUndo={handleUndo}
        />
        <Divider className="my-0" />
      </div>
    )
  }

  const {
    isLoading: isLoadingTreatments,
    data: ignoredTreatments,
  } = useIgnoredTreatments(patientId, statusMessage, showTreatments)

  const {
    mutateAsync: undoIgnoredTreatment,
    isLoading: undoTreatmentIsLoading,
  } = useUndoIgnoredTreatment(statusMessage)

  const treatmentTemplate = (treatment) => {
    const handleUndo = async () => {
      await undoIgnoredTreatment(treatment.id)
    }

    return (
      <div className="w-full">
        <TreatmentDetails treatment={treatment} handleUndo={handleUndo} />
        <Divider className="my-0" />
      </div>
    )
  }

  const items = [
    { label: 'Prescriptions' },
  ]

  if (showTreatments) {
    items.push({ label: 'Treatments' })
  }

  return (
    <div className="flex flex-column">
      <Toast ref={statusMessage} />
      <TabMenu className="mb-2" model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
      {activeIndex === 0 && (
        <DataView
          value={ignoredPrescriptions}
          itemTemplate={prescriptionTemplate}
          loading={isLoadingPrescriptions || undoPrescriptionIsLoading}
          emptyMessage="No ignored prescriptions found"
        />
      )}
      {activeIndex === 1 && (
        <DataView
          value={ignoredTreatments}
          itemTemplate={treatmentTemplate}
          loading={isLoadingTreatments || undoTreatmentIsLoading}
          emptyMessage="No ignored treatments found"
        />
      )}
    </div>
  )
}

export default IgnoredOrders
