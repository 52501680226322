import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'
import { useMutation, useQueryClient } from '@tanstack/react-query'

const useDoseHoldUpdate = (statusMessage) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['updateDoseHold'],
    mutationFn: (payload) => ApiClient.put(`/dose_holds/${payload.id}`, payload),
    onSuccess: () => {
      queryClient.invalidateQueries('doseHolds')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export default useDoseHoldUpdate
