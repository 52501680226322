import { flatMap, get } from 'lodash'

/**
 * The directives object structure could use some work and will benefit from a refactor
 * and TypeScript. This file should support all directives defined in
 * app/services/pharmacy_orders/directives_computer.rb. The directive object definition
 * here controls the visibility and behavior of the directive in the UI. A text object
 * is configured with custom text to show for a tag in the ReviewList, the option text
 * to show in the overview section when picking an action, the info text beneath it, and
 * the button text to show on the action button. If a text is not defined for any of these
 * elements, the directive will not be shown for that context. For example, a tag will be
 * shown for Fill Dispensed, but an option will not. This allows the backend to send any
 * interesting, relevant directives for the reviewable order and the frontend to decide
 * on a case-by-case basis how to handle them.
 */
export function doseDirectiveValues(directives, {
  acknowledgeNewDose,
  acknowledgeDoseUpdate,
  acknowledgeDoseCancel,
  acknowledgeDoseDiscontinue,
  acknowledgeDoseMatch,
  acknowledgeIgnoreOrders,
}) {
  return flatMap(directives, (directive) => {
    if (directive.directive === 'new_prescription') {
      return {
        code: directive.directive,
        color: 'var(--green-400)',
        isPrimary: directive.isPrimary,
        mutation: acknowledgeNewDose,
        hasDoseParams: true,
        text: {
          button: 'Create New Dose',
          info: 'A new dose will be created for the prescription with the configured settings.',
          option: 'Create New Dose',
          tag: 'New Prescription',
        },
      }
    }

    if (directive.directive === 'prescription_update') {
      return {
        code: directive.directive,
        color: 'var(--yellow-400)',
        isPrimary: directive.isPrimary,
        mutation: acknowledgeDoseUpdate,
        hasDoseParams: true,
        text: {
          button: 'Update Existing Dose',
          info: 'The existing dose for the prescription will be updated with the configured settings.',
          option: 'Update Existing Dose',
          tag: 'Prescription Update',
        },
      }
    }

    if (directive.directive === 'pending_refill') {
      return {
        code: directive.directive,
        color: 'var(--orange-400)',
        isPrimary: directive.isPrimary,
        mutation: acknowledgeDoseUpdate,
        hasDoseParams: true,
        text: {
          tag: 'Fill Pending Dispense',
        },
      }
    }

    if (directive.directive === 'refill') {
      return {
        code: directive.directive,
        color: 'var(--orange-400)',
        isPrimary: directive.isPrimary,
        mutation: acknowledgeDoseUpdate,
        hasDoseParams: true,
        text: {
          tag: 'Fill Dispensed',
        },
      }
    }

    if (directive.directive === 'cancel_dose') {
      return {
        code: directive.directive,
        color: 'var(--gray-400)',
        isPrimary: directive.isPrimary,
        mutation: acknowledgeDoseCancel,
        hasDoseParams: true,
        text: {
          button: 'Cancel Existing Dose',
          info: `The prescription will be cancelled and the existing dose will be ended.
                 If there is a more recent prescription for this dose to review, this prescription
                 will automatically be cancelled without ending the dose. Alternatively, you can
                 choose to ignore the changes.`,
          option: 'Cancel Existing Dose',
          tag: 'Cancel Dose',
        },
      }
    }

    if (directive.directive === 'cancel_prescription') {
      return {
        code: directive.directive,
        color: 'var(--gray-400)',
        isPrimary: directive.isPrimary,
        // TODO: Should use a dedicated endpoint or support in the cancel endpoint
        mutation: acknowledgeIgnoreOrders,
        hasDoseParams: false,
        text: {
          button: 'Cancel Prescription',
          info: `The prescription will be cancelled. No dose will be impacted by these changes because
                 the prescription is not currently associated with a dose. Alternatively, you can choose
                 to ignore the changes.`,
          option: 'Cancel Prescription',
          tag: 'Cancel Prescription',
        },
      }
    }

    if (directive.directive === 'discontinue_dose') {
      return {
        code: directive.directive,
        color: 'var(--gray-400)',
        isPrimary: directive.isPrimary,
        mutation: acknowledgeDoseDiscontinue,
        hasDoseParams: true,
        text: {
          button: 'Discontinue Existing Dose',
          info: `The prescription will be discontinued and the existing dose will be ended.
                 If there is a more recent prescription for this dose to review, this prescription
                 will automatically be discontinued without ending the dose. Alternatively, you can
                 choose to ignore the changes.`,
          option: 'Discontinue Existing Dose',
          tag: 'Discontinue Dose',
        },
      }
    }

    if (directive.directive === 'discontinue_prescription') {
      return {
        code: directive.directive,
        color: 'var(--gray-400)',
        isPrimary: directive.isPrimary,
        mutation: acknowledgeIgnoreOrders,
        // TODO: Should use a dedicated endpoint or support in the discontinue endpoint
        hasDoseParams: false,
        text: {
          button: 'Discontinue Prescription',
          info: `The prescription will be discontinued. No dose will be impacted by these changes
                 because the prescription is not currently associated with a dose. Alternatively, you
                 can choose to ignore the changes.`,
          option: 'Discontinue Prescription',
          tag: 'Discontinue Prescription',
        },
      }
    }

    if (directive.directive === 'match') {
      return {
        code: directive.directive,
        color: 'var(--blue-400)',
        isPrimary: directive.isPrimary,
        matchId: get(directive, 'meta.doseMatches[0]'),
        mutation: acknowledgeDoseMatch,
        hasDoseParams: true,
        text: {
          button: 'Update Existing Dose',
          info: `A match was found for the new prescription with the following existing dose.
                 The existing dose will be updated with the configured settings.`,
          option: 'Update Existing Dose',
          tag: 'Match',
        },
      }
    }

    // TODO: Consider hiding the other sections when this is selected
    if (directive.directive === 'ignore') {
      return {
        code: directive.directive,
        color: 'var(--gray-400)',
        isPrimary: directive.isPrimary,
        mutation: acknowledgeIgnoreOrders,
        hasDoseParams: false,
        text: {
          button: 'Ignore Changes',
          info: 'The prescription changes will be ignored and no dose will be changed.',
          option: 'Ignore Changes',
        },
      }
    }
  })
}

/**
 * Returns a boolean indicating whether the given directive should redirect to the review list
 * rather than the dose detail page on acknowledge.
 * @returns {boolean}
 */
export const shouldReturnToReviewListOnAcknowledge = ({ code }) => [
  'cancel_prescription', 'discontinue_prescription', 'ignore',
].includes(code)

export default {}
