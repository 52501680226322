import React, { useState, useRef, useEffect } from 'react'
import { get } from 'lodash'
import { useCurrentOrganization } from '@components/App'
import { Messages } from 'primereact/messages'
import { Button } from 'primereact/button'
import { InputSwitch } from 'primereact/inputswitch'
import SelectableUserList from '../../components/selectableUserList/SelectableUserList'
import {
  useCaregiverAssignedPatients,
  useAssignPatientsMutation,
  useUpdateClientAccessMutation,
  useAssignablePatientsQuery,
} from './caregiverPatientsAssignmentHooks'

function PatientsSelection({ caregiver, site, siteSelector }) {
  const statusMessage = useRef(null)
  const [assignedPatients, setAssignedPatients] = useState([])
  const [possiblePatients, setPossiblePatients] = useState([])
  const [selectedPatients, setSelectedPatients] = useState([])
  const [siteClientAccess, setSiteClientAccess] = useState(
    get(site, 'clientAccess'),
  )
  const [isEditing, setIsEditing] = useState(false)
  const organization = useCurrentOrganization()

  const {
    data: caregiverPatientsData,
    isLoading: caregiverPatientsLoading,
  } = useCaregiverAssignedPatients(
    caregiver.id,
    get(site, 'id'),
    statusMessage,
  )

  const {
    mutateAsync: assignPatients,
    isLoading: assignPatientsLoading,
  } = useAssignPatientsMutation(caregiver.id, statusMessage)

  const {
    mutateAsync: updateClientAccess,
    isLoading: updateClientAccessLoading,
  } = useUpdateClientAccessMutation(caregiver.id, statusMessage)

  const {
    data: possiblePatientsData,
    isLoading: possiblePatientsLoading,
  } = useAssignablePatientsQuery(caregiver.id, get(site, 'id'))

  const shouldShowActions = !site || siteClientAccess === 'select'

  useEffect(() => {
    if (possiblePatientsData) {
      setPossiblePatients(possiblePatientsData.patients)
    }
  }, [possiblePatientsData])

  useEffect(() => {
    if (caregiverPatientsData) {
      setAssignedPatients(caregiverPatientsData.patients)
      setSelectedPatients(caregiverPatientsData.patients)
    }
  }, [caregiverPatientsData])

  const handleAccessChange = async (newAccess) => {
    if (newAccess === siteClientAccess) {
      return
    }
    await updateClientAccess({
      clientAccess: newAccess,
      siteId: get(site, 'id'),
    })
    setSiteClientAccess(newAccess)
  }

  return (
    <>
      <Messages ref={statusMessage} />
      <div className="flex flex-column">
        <div className="flex flex-row mb-2 align-items-center">
          {site && (
          <div className="field-checkbox">
            <label htmlFor="acess-all-switch" className="p-checkbox-label flex align-items-center">
              { `Access to all ${organization.patientLabelPlural} at ${site.name}`}
              <InputSwitch
                className="m-2"
                inputId="acess-all-switch"
                checked={siteClientAccess === 'all'}
                disabled={updateClientAccessLoading}
                onChange={(e) => {
                  setIsEditing(false)
                  handleAccessChange(e.value ? 'all' : 'select')
                }}
              />
              {(updateClientAccessLoading || caregiverPatientsLoading || assignPatientsLoading) && (
                <i
                  className="pi pi-spin pi-spinner mx-2"
                  style={{ width: '1rem', height: '1rem' }}
                />
              )}
            </label>
          </div>

          )}
          <div className="flex flex-grow-1 flex-row justify-content-end gap-2">
            {siteSelector && siteSelector()}
            {shouldShowActions && isEditing && (
              <Button
                className="p-button-sm"
                label="Save"
                loading={assignPatientsLoading || caregiverPatientsLoading}
                onClick={async () => {
                  await assignPatients({
                    patientIds: selectedPatients.map((p) => p.id),
                    siteId: get(site, 'id'),
                  })
                  setIsEditing(false)
                }}
              />
            )}
            {shouldShowActions && !isEditing && (
              <Button
                className="p-button-sm"
                label="Edit"
                onClick={() => {
                  setIsEditing(true)
                }}
              />
            )}
          </div>
        </div>
        <div className="flex-1">
          <SelectableUserList
            users={assignedPatients}
            possibleUsers={possiblePatients}
            selectedUsers={selectedPatients}
            setSelectedUsers={setSelectedPatients}
            isEditing={isEditing}
            isLoading={
                caregiverPatientsLoading || possiblePatientsLoading
              }
            onUserClick={(user) => {
              window.location.href = `/admin/patients/${user.id}`
            }}
          />
        </div>
      </div>
    </>
  )
}

export default PatientsSelection
