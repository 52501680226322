import React from 'react'
import useScrollTo from '@services/hooks/useScrollTo'
import { useCurrentCapabilities } from '@components/App'
import { useSettingsMutation } from '../settingsHooks'
import Panels from './Panels'
import getPanelsConfig from './panelsConfig'

function AdminSettings({
  organization, organizationId, query, statusMessage,
}) {
  const scrollTo = useScrollTo()
  const { organization: { canManageSsoForOrg } } = useCurrentCapabilities()

  const { mutateAsync, isLoading: isUpdating } = useSettingsMutation(organizationId, statusMessage)

  const onSubmit = async (data, event) => {
    event.preventDefault()
    await mutateAsync({ organization: data })
    scrollTo('top')
  }

  const settingsTabsData = getPanelsConfig(query, organization, canManageSsoForOrg, statusMessage)

  return (
    <Panels
      settingsTabsData={settingsTabsData}
      onSubmit={onSubmit}
      isLoading={query.isLoading || isUpdating}
    />
  )
}

export default AdminSettings
