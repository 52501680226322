import { enumerateDaysBetweenMoments, getCssVariable } from '@services/utils'
import { momentFormats } from '@services/utils/moment'
import { get } from 'lodash'
import moment from 'moment'

export const statusOfDay = (dayNarcoticCounts) => {
  const statuses = dayNarcoticCounts.map((narcoticCount) => narcoticCount.status)
  if (statuses.every((status) => status === 'Complete' || status === 'Pending')) return 'success'
  if (statuses.every((status) => status === 'Missed')) return 'danger'
  if (statuses.some((status) => status === 'Missed')) return 'warning'
}

export const formatNarcoticDashboardItems = ({
  narcoticDashboardItems, timezone,
}) => {
  if (!narcoticDashboardItems) return []

  return narcoticDashboardItems.map((item) => {
    const narcoticCounts = item.narcoticCounts.reduce((acc, narcoticCount) => {
      const date = moment(narcoticCount.dueAt).tz(timezone).format(momentFormats.date)
      if (!acc[date]) acc[date] = []
      acc[date].push(narcoticCount)
      return acc
    }, {})
    return { ...item, narcoticCounts }
  })
}

export function dashboardColumns(
  {
    dateRange,
    patientLabel,
    patientTemplate,
    medicineTemplate,
    narcoticCountTemplate,
    formatForDayCount = momentFormats.monthShortDay,
  },
) {
  const days = enumerateDaysBetweenMoments(dateRange.startDate, dateRange.endDate)
  const columns = [
    {
      field: 'patient.id',
      header: patientLabel,
      body: patientTemplate,
      style: { minWidth: '14rem', width: '14rem', maxWidth: '14rem' },
    },
    {
      field: 'narcotic.id',
      header: 'Medication',
      body: medicineTemplate,
      style: { minWidth: '20rem', width: '20rem', maxWidth: '20rem' },
    },
  ]

  days.forEach((day) => {
    columns.push({
      field: `narcoticCounts.${day.format(momentFormats.date)}`,
      header: day.format(formatForDayCount),
      body: narcoticCountTemplate,
      style: { minWidth: '100px', width: '100px', maxWidth: '100px' },
    })
  })

  return columns
}

export const getCountColor = (code) => {
  const statusArray = [
    { value: 'Complete', color: getCssVariable('--circle-given') },
    { value: 'Missed', color: getCssVariable('--circle-missed') },
    { value: 'Pending', color: getCssVariable('--circle-pending') },
  ]

  const statusObj = statusArray.find((status) => status.value === code)
  return get(statusObj, 'color', 'transparent')
}
