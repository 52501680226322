const allowedHrstPurposes = {
  EMERGENCY: 'Emergency (PRN Psychotropic)',
  PSYCHOTROPIC: 'Psychotropic',
  GASTROINTESTINAL: 'Gastrointestinal',
  ANTIEPILEPTIC: 'Anti-epileptic',
  BOWEL: 'Bowel',
  OTHER: 'Other',
}

export {
  // eslint-disable-next-line import/prefer-default-export
  allowedHrstPurposes,
}
