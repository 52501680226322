import React, { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  find, first, isEmpty, map,
} from 'lodash'
import { Card } from 'primereact/card'
import { Timeline } from 'primereact/timeline'
import MedicineName from '@components/clientDoses/Medication/Name'
import MedicinePhysicalForm from '@components/clientDoses/Medication/PhysicalForm'
import MedicineStrength from '@components/clientDoses/Medication/Strength'
import MedicinePackaging from '@components/clientDoses/Dosing/Packaging'
import PARLevel from '@components/clientDoses/Dosing/ParLevel'
import SitesSelector from '@components/inventoryManagement/DoseFieldset/SitesSelector'
import { useRxTermSearch } from '../ClientDoses/clientDosesHooks'
import { useDoseUpsert } from './inventoryHooks'

function PageTitle() {
  return (
    <div className="flex flex-row px-4 pb-2 pt-2 align-items-center">
      <span>
        Create Dose
      </span>
    </div>
  )
}

function UpsertDoses({ useInventoryManagementHeader }) {
  const doseStatusMessage = useRef(null)
  const { setHeader } = useInventoryManagementHeader()
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = useState(0)
  const [name, setName] = useState()
  const [form, setForm] = useState()
  const [strength, setStrength] = useState()
  const [packaging, setPackaging] = useState()
  const [parLevel, setParLevel] = useState()
  const [sites, setSites] = useState([])
  const [medicineSearchTerm, setMedicineSearchTerm] = useState()

  const [rxTermSearchResults] = useRxTermSearch(medicineSearchTerm)

  const { mutateAsync: upsertDose } = useDoseUpsert(doseStatusMessage)

  const steps = [
    { label: 'Medicine Name', value: name },
    { label: 'Medicine Physical Form', value: form },
    { label: 'Medicine Strength', value: strength },
    { label: 'Medicine Packaging', value: packaging },
    { label: 'Dose PAR Level', value: parLevel },
    { label: 'Available Sites', value: sites },
  ]

  const handleFieldSelection = (index) => {
    setActiveStep(index)
  }

  const handleNameChange = (value) => {
    setName(value)
    setMedicineSearchTerm(null)
    setForm(null)
    setStrength(null)
  }

  const dashboardSearchParams = () => {
    const medicineSearchParams = `medicineSearchTerm=${name}`
    const siteIdParams = map(sites, (id) => `siteIds=${id}`).join('&')
    const statusFilterParams = ['no_inventory', 'pending', 'loaded'].map(
      (filter) => `doseStatusFilters=${filter}`,
    ).join('&')

    return `${medicineSearchParams}&${siteIdParams}&${statusFilterParams}`
  }

  const handleConfirmName = () => {
    const matchingNames = rxTermSearchResults.filter((rxTerm) => rxTerm.displayName === name)
    if (matchingNames.length > 1 || matchingNames.length === 0) {
      setActiveStep(activeStep + 1)
    } else {
      setForm(first(matchingNames)?.form)
      setActiveStep(activeStep + 2)
    }
  }

  const handleLastStep = async () => {
    setActiveStep(null)
    const medicine = { name, form, strength }
    const searchResult = find(rxTermSearchResults, (rxTerm) => rxTerm.displayName === name
      && rxTerm.form === form && rxTerm.strength === strength)
    if (searchResult) {
      medicine.rcui = searchResult.rxcui
    }
    await upsertDose({
      packaging,
      parLevel,
      medicineAttributes: medicine,
      siteIds: sites,
    })

    navigate(`/admin/inventory_management?${dashboardSearchParams()}`)
  }

  const fieldMarker = (item, index) => {
    let color = '#dee2e6'
    let icon = 'pi pi-stop'
    if (activeStep === index) {
      color = '#fa8e42'
      icon = 'pi pi-file-edit'
    } else if (item.value) {
      color = '#0bda51'
      icon = 'pi pi-check-square'
    }

    if (item.label === 'Available Sites' && isEmpty(item.value)) {
      color = '#dee2e6'
      icon = 'pi pi-stop'
    }

    return (
      <span onClick={() => handleFieldSelection(index)} className="cursor-pointer p-1" style={{ color }}>
        <i className={icon} />
      </span>
    )
  }

  const fieldContent = (item, index) => {
    const getTextColor = () => (activeStep === index ? 'text-color' : 'text-color-secondary')

    let value
    if (item.label === 'Available Sites') {
      value = isEmpty(sites) ? null : `${sites.length} Selected`
    } else {
      value = item.value
    }

    return (
      <div onClick={() => handleFieldSelection(index)} className={`cursor-pointer flex flex-column pt-1 gap-2 ${getTextColor()}`}>
        <span className="text-base">{item.label}</span>
        <span className="text-sm">{value}</span>
      </div>
    )
  }

  const title = { label: <PageTitle /> }

  const breadcrumbs = [
    {
      label: 'Create',
      template: <span>Create</span>,
    },
  ]

  const primaryAction = (
    <a href="/admin/ekit_doses_copy">Copy Site Doses</a>
  )

  useEffect(() => {
    setHeader({
      title, tabs: [], breadcrumbs, primaryAction,
    })
  }, [])

  return (
    <Card>
      <div className="flex align-items-start flex-column w-full">
        <div className="flex flex-row pt-2 w-full xl:w-10">
          <Timeline
            style={{ minWidth: '16em' }}
            value={steps}
            marker={fieldMarker}
            content={fieldContent}
          />
          { activeStep === 0
            && (
            <MedicineName
              rxTermSearchResults={rxTermSearchResults}
              medicineName={name || medicineSearchTerm}
              setMedicineSearchTerm={setMedicineSearchTerm}
              setMedicineName={handleNameChange}
              handleConfirm={handleConfirmName}
            />
            )}
          { activeStep === 1
            && (
            <MedicinePhysicalForm
              rxTermSearchResults={rxTermSearchResults}
              medicineForm={form}
              setMedicineForm={setForm}
              medicineName={name}
              handleConfirm={() => setActiveStep(activeStep + 1)}
            />
            )}
          { activeStep === 2
            && (
            <MedicineStrength
              rxTermSearchResults={rxTermSearchResults}
              medicineForm={form}
              medicineName={name}
              medicineStrength={strength}
              setMedicineStrength={setStrength}
              handleConfirm={() => setActiveStep(activeStep + 1)}
              buttonText="Next"
            />
            )}
          { activeStep === 3
            && (
            <MedicinePackaging
              medicineForm={form}
              medicinePackaging={packaging}
              setMedicinePackaging={setPackaging}
              handleConfirm={() => setActiveStep(activeStep + 1)}
            />
            )}
          { activeStep === 4
            && (
            <PARLevel
              parLevel={parLevel}
              setParLevel={setParLevel}
              handleConfirm={() => setActiveStep(activeStep + 1)}
            />
            )}
          { activeStep === 5
            && (
            <SitesSelector
              selectedSites={sites}
              setSelectedSites={setSites}
              doseStatusMessage={doseStatusMessage}
              handleConfirm={handleLastStep}
            />
            )}
        </div>
      </div>
    </Card>
  )
}

export default UpsertDoses
