import React from 'react'
import { Route, Routes, useParams } from 'react-router-dom'
import { get } from 'lodash'
import { Skeleton } from 'primereact/skeleton'
import { Tag } from 'primereact/tag'
import { useCurrentOrganization, useCurrentUser } from '@components/App'
import { useAdmin } from './adminsHooks'
import AdminDashboard from '../../components/admins/AdminDashboard'
import UpdateAdmin from './UpdateAdmin'

function Admin({ useAdminHeader }) {
  const { adminId } = useParams()
  const currentUser = useCurrentUser()
  const organization = useCurrentOrganization()
  const { setHeader, maxDataViewHeight } = useAdminHeader()
  const { data: admin, isLoading } = useAdmin(adminId || currentUser.id)

  const isCurrentUserAdmin = ['superadmin', 'site_admin'].includes(currentUser.role)

  const title = {
    label: (
      <span className="flex flex-row align-items-center" id="admin-name-tab">
        <i className="text-xl pi pi-user mr-2" />
        {isLoading && (
          <Skeleton className="inline-flex" width="10rem" height="1.5rem" />
        )}
        {!isLoading && (
          <>
            <span className="fadein">
              {`${admin.firstName} ${admin.lastName}`}
            </span>
            {get(admin, 'isActive') === false && (
              <Tag className="ml-2" value="Deactivated" severity="danger" />
            )}
          </>
        )}
      </span>
    ),
    linkTo: `/admin/admins/${adminId}`,
  }

  const tabs = []

  const breadcrumbs = [
    {
      label: 'Admin',
      template: <span>{organization.adminLabelSingular}</span>,
    },
  ]

  const handleSetHeader = (props = {}) => {
    setHeader({
      title, tabs, breadcrumbs, ...props,
    })
  }

  return (
    <Routes>
      <Route
        index
        element={(
          <AdminDashboard
            maxDataViewHeight={maxDataViewHeight}
            isCurrentUserAdmin={isCurrentUserAdmin}
            adminId={adminId || currentUser.id}
            handleSetHeader={handleSetHeader}
            adminLoading={isLoading}
            admin={admin}
          />
        )}
      />
      <Route
        path="update"
        element={<UpdateAdmin admin={admin} handleSetHeader={handleSetHeader} />}
      />
    </Routes>
  )
}

export default Admin
