import React from 'react'
import { useAuth } from '../../../context/AuthContext'

function ToggleLoginTypeLink() {
  const { loginType, setLoginType } = useAuth()

  return (
    <div className="field text-center">
      <span
        className="login-option-link"
        onClick={(e) => {
          e.preventDefault()
          setLoginType(loginType === 'Username' ? 'Email' : 'Username')
        }}
        style={{ cursor: 'pointer' }}
      >
        {loginType === 'Username'
          ? 'Login with Email'
          : 'Login with Username'}
      </span>
    </div>
  )
}

export default ToggleLoginTypeLink
