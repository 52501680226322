import React, { useState, useRef, useEffect } from 'react'
import {
  filter, get, maxBy, isEmpty,
} from 'lodash'
import { Button } from 'primereact/button'
import { Messages } from 'primereact/messages'
import { Steps } from 'primereact/steps'
import DrawerSelection from './DrawerSelection'
import SlotSelection from './SlotSelection'
import DeviceSelection from './DeviceSelection'
import { FulfillmentStatus } from '../FullfillmentStatuses'
import { useAssignPendingDoseFill } from './loadToDeviceHooks'
import {
  useDevicesQuery,
  useDeviceQuery,
  useOpenDrawer,
} from '../../../features/InventoryManagement/inventoryHooks'
import './load-to-device.scss'
import ConfirmDetails from './ConfirmDetails'

function LoadDoseFillToEkit({
  dose, doseFillToBeLoaded, doseFills, site, onSuccess,
}) {
  const statusMessage = useRef(null)
  const [activeStep, setActiveStep] = useState(0)
  const [selectedEkitId, setSelectedEkitId] = useState()
  const [selectedDrawerId, setSelectedDrawerId] = useState()
  const [selectedSlotId, setSelectedSlotId] = useState({})
  const [isLoaded, setIsLoaded] = useState(false)

  const loadedDoseFills = filter(doseFills, {
    status: FulfillmentStatus.loadedByAgency,
  })
  const mostRecentlyLoadedDoseFill = maxBy(loadedDoseFills, 'agencyLoadedAt')

  const { data: ekits, isLoading: isLoadingEkits } = useDevicesQuery(statusMessage)

  const {
    data: ekit,
    refetch: refetchEkit,
    isFetching: isFetchingEkit,
  } = useDeviceQuery(
    selectedEkitId,
    statusMessage,
  )

  const drawer = ekit?.simpillDrawers.find(
    ({ id }) => id === selectedDrawerId,
  )
  const slot = drawer?.drawerSlots.find(
    ({ id }) => id === selectedSlotId,
  )

  const selectedDrawerHasSlots = !isEmpty(
    get(drawer, 'drawerSlots', []),
  )

  const {
    mutateAsync: loadPendingDoseFill,
    isLoading: loadPendingDoseFillLoading,
  } = useAssignPendingDoseFill(dose.id, statusMessage)

  const { openDrawer, isLoading: openDrawerLoading } = useOpenDrawer(
    selectedEkitId,
    statusMessage,
  )

  useEffect(() => {
    if (!mostRecentlyLoadedDoseFill) return
    if (selectedEkitId) return

    const doseFillDrawer = mostRecentlyLoadedDoseFill.simpillDrawers[0]
    || mostRecentlyLoadedDoseFill.drawerSlots[0].simpillDrawer
    const doseFillSlot = mostRecentlyLoadedDoseFill.drawerSlots[0]
    const ekitId = doseFillDrawer.simpill.id

    setSelectedEkitId(ekitId)
    setSelectedDrawerId(doseFillDrawer?.id)
    setSelectedSlotId(doseFillSlot?.id)
  }, [mostRecentlyLoadedDoseFill, dose])

  const handleEkitConfirmation = async () => {
    const { data } = await refetchEkit()
    if (!isEmpty(data)) {
      setActiveStep(1)
    }
  }

  const handleDrawerConfirmation = async () => {
    await openDrawer(drawer.position)
    if (selectedDrawerHasSlots) {
      setActiveStep(2)
    } else {
      setSelectedSlotId()
      setActiveStep(3)
    }
  }

  const handleDoseFillAssignation = async () => {
    setActiveStep(3)
  }

  const handleConfirmLoad = async () => {
    const params = {
      doseFillId: doseFillToBeLoaded.id,
    }
    if (selectedSlotId) {
      params.slotId = selectedSlotId
    } else {
      params.drawerId = selectedDrawerId
    }
    await loadPendingDoseFill(params, {
      onSuccess: () => setIsLoaded(true),
    })
  }

  const handleFinish = () => {
    setSelectedSlotId()
    setSelectedDrawerId()
    setSelectedEkitId()
    onSuccess()
  }

  const ekitLabel = (ekit?.label?.length ?? 0) > 20 ? `${ekit?.label.slice(0, 20)}...` : ekit?.label

  const steps = [
    {
      label: ekit ? ekitLabel : 'E-Kit',
    },
    {
      label: drawer ? `Drawer ${drawer?.position}` : 'Drawer',
    },
    {
      label: 'Slot',
    },
    {
      label: 'Confirm',
    },
  ]

  const ekitsToAssign = filter(ekits, (e) => e.site.id === site.id)
  return (
    <>
      <Messages ref={statusMessage} />
      <div className="flex flex-column justify-content-between gap-2">
        <Steps
          model={steps}
          activeIndex={activeStep}
          readOnly={false}
          className="mb-3"
        />
        {activeStep === 0 && (
          <>
            <DeviceSelection
              devices={ekitsToAssign}
              selectedDeviceId={selectedEkitId}
              setSelectedDeviceId={setSelectedEkitId}
              isLoading={isLoadingEkits}
            />
            <Button
              className="p-button-primary p-button-sm h-3rem"
              label="Select"
              onClick={handleEkitConfirmation}
              loading={isFetchingEkit}
              disabled={!selectedEkitId || isFetchingEkit}
            />
          </>
        )}
        {activeStep === 1 && (
          <>
            <DrawerSelection
              ekit={ekit}
              selectedDrawer={drawer}
              setSelectedDrawerId={setSelectedDrawerId}
            />
            <Button
              className="p-button-primary p-button-sm h-3rem"
              label="Open And Select"
              loading={openDrawerLoading}
              disabled={!selectedDrawerId}
              onClick={handleDrawerConfirmation}
            />
          </>
        )}
        {
          activeStep === 2
          && (
            <>
              <SlotSelection
                ekit={ekit}
                drawer={drawer}
                selectedSlot={slot}
                drawerSlots={drawer?.drawerSlots}
                setSelectedSlotId={setSelectedSlotId}
              />
              <Button
                className="p-button-primary p-button-sm h-3rem"
                label="Select Slot"
                loading={loadPendingDoseFillLoading}
                onClick={handleDoseFillAssignation}
              />
            </>
          )
        }
        {activeStep === 3 && (
          <>
            <ConfirmDetails
              doseFill={doseFillToBeLoaded}
              dose={dose}
              ekit={ekit}
              drawer={drawer}
              slot={slot}
              isLoaded={isLoaded}
            />
            {
              isLoaded
                ? (
                  <Button
                    className="p-button-primary p-button-sm h-3rem"
                    label="Finish"
                    onClick={handleFinish}
                  />
                ) : (
                  <Button
                    className="p-button-primary p-button-sm h-3rem"
                    label="Confirm and Load Refill"
                    loading={loadPendingDoseFillLoading}
                    onClick={handleConfirmLoad}
                  />
                )
            }

          </>
        )}
      </div>
    </>
  )
}

export default LoadDoseFillToEkit
