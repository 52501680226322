import React, { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import FormWrapper from '@components/display/Form/FormWrapper'
import { TreatmentForm } from '@components/treatments/TreatmentForm'
import { useCreateTreatment } from '@hooks/treatments'

function CreateTreatmentPage({ clientId }) {
  const statusMessage = useRef(null)
  const navigate = useNavigate()

  const [treatment, setTreatment] = useState({
    startAt: '',
    endAt: '',
    pharmacyInstructions: '',
    rawAdministrationTimes: '',
    note: '',
    treatmentType: 'prn',
    category: '',
  })

  const {
    mutateAsync: createTreatment,
    isLoading,
  } = useCreateTreatment({ statusMessage })

  if (!clientId) {
    return null
  }

  const handleCreateTreatment = async () => {
    const createdTreatment = await createTreatment({
      ...treatment,
      patientId: clientId,
    })

    if (createdTreatment) {
      navigate(`/admin/patients/${clientId}/treatments/manage/${createdTreatment.id}`)
    }
  }

  return (
    <div className="flex flex-row flex-wrap">
      <Toast ref={statusMessage} />
      <div className="col-8 xl:col-6">
        <FormWrapper title="Create Treatment" fullHeight>
          <TreatmentForm
            treatment={treatment}
            setTreatment={(value) => setTreatment((t) => ({ ...t, ...value }))}
          />
          <Button
            label="Create Treatment"
            className="p-button-sm mt-4"
            loading={isLoading}
            onClick={handleCreateTreatment}
          />
        </FormWrapper>
      </div>
    </div>
  )
}

export default CreateTreatmentPage
