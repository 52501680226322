import React from 'react'
import { Controller } from 'react-hook-form'
import CardWithTitles from '../../features/OrgSettings/CardWithTitles'
import { InputTextWithLabel } from '../display'

function RoleLabels({ inputControl }) {
  return (
    <CardWithTitles title="Role Labels">
      <h3 className="font-normal text-base text-color-secondary">
        {/* eslint-disable-next-line */}
        These labels will be used in the app to refer to the different roles.
      </h3>

      <div className="flex">
        <div className="w-25rem">
          <Controller
            name="patientLabel"
            control={inputControl}
            render={({ field, fieldState: { error } }) => (
              <InputTextWithLabel
                error={error?.message}
                label="Patient Label"
                inputId="patientLabel"
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          />
          <Controller
            name="caregiverLabel"
            control={inputControl}
            render={({ field, fieldState: { error } }) => (
              <InputTextWithLabel
                error={error?.message}
                label="Caregiver Label"
                inputId="caregiverLabel"
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          />
          <Controller
            name="adminLabel"
            control={inputControl}
            render={({ field, fieldState: { error } }) => (
              <InputTextWithLabel
                error={error?.message}
                label="Admin Label"
                inputId="adminLabel"
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          />
        </div>

        <div className="w-25rem mx-4">
          <Controller
            name="patientLabelPlural"
            control={inputControl}
            render={({ field, fieldState: { error } }) => (
              <InputTextWithLabel
                error={error?.message}
                label="Patient Label (Plural)"
                inputId="patientLabelPlural"
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          />
          <Controller
            name="caregiverLabelPlural"
            control={inputControl}
            render={({ field, fieldState: { error } }) => (
              <InputTextWithLabel
                error={error?.message}
                label="Caregiver Label (Plural)"
                inputId="caregiverLabelPlural"
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          />
          <Controller
            name="adminLabelPlural"
            control={inputControl}
            render={({ field, fieldState: { error } }) => (
              <InputTextWithLabel
                error={error?.message}
                label="Admin Label (Plural)"
                inputId="adminLabelPlural"
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          />
        </div>
      </div>

    </CardWithTitles>
  )
}

export default React.memo(RoleLabels)
