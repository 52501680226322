import React from 'react'
import InputTextWithLabel from '@components/display/InputTextWithLabel'

function DetailsForm({ details = {}, setDetails }) {
  const { name, externalId } = details
  return (
    <div className="flex flex-column">
      <InputTextWithLabel
        label="Name"
        value={name || ''}
        inputId="name"
        name="name"
        onChange={({ target: { value } }) => setDetails({ name: value })}
      />
      <InputTextWithLabel
        label="External ID"
        value={externalId || ''}
        inputId="externalId"
        name="externalId"
        onChange={({ target: { value } }) => setDetails({ externalId: value })}
      />
    </div>
  )
}

export default DetailsForm
