import React, { useRef, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import moment from 'moment'
import { isEmpty } from 'lodash'

import { Button } from 'primereact/button'
import { Messages } from 'primereact/messages'
import { useCurrentUser } from '@components/App'
import { useApproveMARMutation } from '@services/hooks'
import { enumerateDaysBetweenMoments } from '@services/utils'
import { momentFormats } from '@services/utils/moment'
import { useSignature } from '@components/user/ElectronicSignature/signatureHooks'
import ApprovalSignature from './ApprovalSignature'

/**
 * @typedef {import('moment').Moment} Moment
 */

/**
 * A dialog component that allows a user to approve a MAR for a specific period of time.
 *
 * @component
 * @param {object} props
 * @param {boolean} props.visible - Whether the dialog is visible.
 * @param {function} props.onHide - Function to call when the dialog is hidden.
 * @param {string} props.patientId - The ID of the patient.
 * @param {Moment} props.currentStart - The start of the current period.
 * @param {Moment} props.currentEnd - The end of the current period.
 * @param {string} props.currentGranularity - The current granularity of the period.
 * @return {JSX.Element}
 */
function MarApprovalDialog({
  visible,
  onHide,
  patientId,
  currentStart,
  currentEnd,
  existingMarApprovals,
}) {
  const errorStatusMessage = useRef(null)
  const currentUser = useCurrentUser()
  const [isSignatureEditing, setIsSignatureEditing] = useState(false)
  const {
    mutateAsync: approveMar,
    isLoading: isApproving,
  } = useApproveMARMutation({ statusMessage: errorStatusMessage })
  const {
    data: userSignature,
  } = useSignature({ userId: currentUser.id, enabled: true, errorStatusMessage })
  const myApprovals = existingMarApprovals?.filter((mar) => mar.approvedBy.id === currentUser.id)
  const myApprovalsDays = myApprovals.map((approval) => approval.days).flat()
  const alreadyApprovedDays = enumerateDaysBetweenMoments(currentStart, currentEnd)
    .filter((day) => myApprovalsDays.includes(day.format(momentFormats.date)))
  // if currentEnd is after today, set it to today
  const approvalEnd = currentEnd.isAfter(moment()) ? moment() : currentEnd

  const [signature, setSignature] = useState()

  const handleApprove = async () => {
    await approveMar({
      patientId,
      dayMin: currentStart.format('YYYY-MM-DD'),
      dayMax: approvalEnd.format('YYYY-MM-DD'),
    }, {
      onSuccess: () => {
        onHide()
      },
    })
  }

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header="MAR Approval"
    >
      <Messages ref={errorStatusMessage} />
      <div className="flex flex-column gap-3 text-base text-900 align-items-center">
        <span className="text-lg">
          You're going to approve the following days:
        </span>
        <span className="text-lg">
          {currentStart.format(momentFormats.dateYear)}
          {' - '}
          {approvalEnd.format(momentFormats.dateYear)}
        </span>
        {
          alreadyApprovedDays.length > 0
          && (
            <span className="text-sm text-700">
              Your approval will replace the previous approval
              {alreadyApprovedDays.length > 0 ? 's' : ''}
              , select Approve to confirm
            </span>
          )
        }
        <ApprovalSignature
          userSignature={userSignature}
          adminId={currentUser.id}
          signature={signature}
          setSignature={setSignature}
          isEditing={isSignatureEditing}
          setIsEditing={setIsSignatureEditing}
        />
        <Button
          label="Approve"
          className="p-button-primary"
          disabled={isEmpty(userSignature) || isSignatureEditing}
          onClick={() => {
            handleApprove()
          }}
          loading={isApproving}
        />
      </div>
    </Dialog>
  )
}

export default MarApprovalDialog
