import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { errorMessageFromError, formErrorsFromError } from '@services/errorHandler'

export function useDeleteSchedule(statusMessage) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['deleteSchedule'],
    mutationFn: ({ scheduleId }) => ApiClient.delete(`/schedules/${scheduleId}`),
    onSuccess: () => {
      statusMessage.current.show([
        { severity: 'warn', summary: 'Schedule Deleted!' },
      ])
      queryClient.invalidateQueries('patientDose')
      queryClient.invalidateQueries('schedule')
      queryClient.invalidateQueries('task')
    },
    onError: (error) => {
      statusMessage.current.show([
        errorMessageFromError(error, 'Unable to delete schedule at this time.'),
        ...formErrorsFromError(error),
      ])
    },
  })
}

export default useDeleteSchedule
