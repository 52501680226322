import React from 'react'
import { Routes, Route } from 'react-router-dom'
import PageLayout, { usePageOutlet } from '@components/display/PageLayout'
import { withApp, useCurrentOrganization, useCurrentCapabilities } from '@components/App'
import './caregivers.scss'
import CaregiversTable from './CaregiversTable'
import Caregiver from './Caregiver'
import CreateCaregiver from './CreateCaregiver'

function Caregivers() {
  const useCaregiverHeader = () => usePageOutlet()
  const organization = useCurrentOrganization()
  const { organization: { canManageSsoForOrg } } = useCurrentCapabilities()

  return (
    <div className="caregivers">
      <Routes>
        <Route path="/admin/caregivers/*" element={<PageLayout rootPath="/admin/caregivers" rootLabel={organization.caregiverLabelPlural} />}>
          <Route
            index
            element={(
              <CaregiversTable
                useCaregiverHeader={useCaregiverHeader}
                canManageSsoForOrg={canManageSsoForOrg}
              />
            )}
          />
          <Route path="create" element={<CreateCaregiver useCaregiverHeader={useCaregiverHeader} />} />
          <Route path=":caregiverId/*" element={<Caregiver useCaregiverHeader={useCaregiverHeader} />} />
        </Route>
      </Routes>
    </div>
  )
}

export default withApp(Caregivers)
