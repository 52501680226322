import React, {
  useEffect, useRef, useState,
} from 'react'
import { Card } from 'primereact/card'
import { Divider } from 'primereact/divider'
import { useCurrentOrganization } from '@components/App'
import { useParams, Link } from 'react-router-dom'
import { compact, get } from 'lodash'
import { useNarcoticCountsDashboardsQuery } from '@hooks/narcoticCounts/dashboards'
import DateTable from '@components/display/DateTable/DateTable'
import { medicineDisplayNameWithStrength } from '@components/clientDoses/doseUtils'
import PatientAvatarThumbnail from '@components/patients/PatientList/PatientAvatarThumbnail'
import { OverlayPanel } from 'primereact/overlaypanel'
import { dashboardColumns, formatNarcoticDashboardItems } from '@components/narcoticCounts/narcoticCountUtils'
import useDateNavigatorUtils from '@components/display/DateNavigator/dateNavigatorUtils'
import DateNavigator from '@components/display/DateNavigator/DateNavigator'
import NarcoticCountsStatusOverlay from './NarcoticCountsDashboard/NarcoticCountsStatus'
import NarcoticCountsStatusIndicator from './NarcoticCountsDashboard/NarcoticCountStatusIndicator'

function PatientRow({ rowData }) {
  return (
    <div className="patient-name flex flex-row align-items-center gap-2">
      {
        rowData.patient?.avatar && (
          <PatientAvatarThumbnail patient={rowData.patient} />
        )
      }
      {rowData.patient.fullName}
    </div>
  )
}

function MedicationDetails({ rowData }) {
  return (
    <div className="medication-name">
      {medicineDisplayNameWithStrength(rowData.narcotic)}
    </div>
  )
}

function NarcoticCounts({ handleSetHeader }) {
  const currentOrganization = useCurrentOrganization()
  const { siteId } = useParams()

  const statusOverlay = useRef()
  const [selectedCounts, setSelectedCounts] = useState([])

  const {
    onPrevious, onNext, onGranularityChange, dateRange, setDateRange,
    granularity, nextButtonDisable, formatForDayCount, totalDays,
  } = useDateNavigatorUtils(currentOrganization)

  const {
    data: narcoticDashboardItems,
    isLoading,
  } = useNarcoticCountsDashboardsQuery(siteId, dateRange)

  const onSelectCount = (e, counts) => {
    setSelectedCounts(counts)
    if (statusOverlay.current) {
      statusOverlay.current.hide()
      requestAnimationFrame(() => {
        statusOverlay.current.show(e)
      })
    } else {
      statusOverlay.current.show(e)
    }
  }

  const patientTemplate = (rowData) => (
    <PatientRow rowData={rowData} />
  )

  const medicineTemplate = (rowData) => (
    <MedicationDetails rowData={rowData} />
  )

  const statusTemplate = (
    rowData,
    column,
  ) => {
    const countsForDay = get(rowData, column.field)
    if (!countsForDay) return null
    return (
      <NarcoticCountsStatusIndicator
        narcoticCounts={countsForDay}
        onSelectCount={(e) => onSelectCount(e, countsForDay)}
      />
    )
  }

  const formattedDashboardData = formatNarcoticDashboardItems({
    narcoticDashboardItems, timezone: currentOrganization.timezone,
  })

  const breadcrumbs = compact([
    {
      label: 'Site',
      template: <Link to={`/admin/sites/${siteId}`}>Site</Link>,
    },
    {
      label: 'Narcotic Counts',
      template: <span>Narcotic Counts</span>,
    },
  ])

  useEffect(() => {
    handleSetHeader({ breadcrumbs })
  }, [])

  return (
    <div>
      <Card className="h-full">
        <div className="flex flex-column gap-3 text-base text-lg">
          <div>
            Narcotic Counts
          </div>
          <Divider className="my-0" />
          <DateNavigator
            dateRange={dateRange}
            onPrevious={onPrevious}
            onNext={onNext}
            granularity={granularity}
            setDateRange={setDateRange}
            onGranularityChange={onGranularityChange}
            nextButtonDisable={nextButtonDisable}
          />
        </div>
        <Divider className="my-3" />
        <OverlayPanel ref={statusOverlay} className="adherence-status-overlay">
          <NarcoticCountsStatusOverlay
            narcoticCounts={selectedCounts}
            timezone={currentOrganization.timezone}
          />
        </OverlayPanel>
        <div>
          <DateTable
            columns={dashboardColumns({
              dateRange,
              patientLabel: currentOrganization.patientLabelSingular,
              patientTemplate,
              medicineTemplate,
              narcoticCountTemplate: statusTemplate,
              formatForDayCount: formatForDayCount(totalDays),
            })}
            data={formattedDashboardData}
            isLoading={isLoading}
            tableClassName="narcotic-counts"
            emptyMessage="No narcotic counts available"
            className="narcotic-counts"
            sortField="patient.fullName"
            rowGroupMode="rowspan"
            groupRowsBy={['patient.id']}
            sortMode="single"
            scrollHeight="70vh"
          />
        </div>
      </Card>
    </div>
  )
}

export default NarcoticCounts
