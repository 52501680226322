import React from 'react'
import SearchField from '@components/display/Form/SearchField'
import SitesSelect from '../../../features/Selects/SitesSelect/SitesSelect'

function ControlBar({
  medicineSearchTerm,
  setMedicineSearchTerm,
  isSitesLoading,
  availableSites,
  selectedSites,
  setSelectedSites,
  isLoading,
}) {
  const handleMedSearch = (value) => setMedicineSearchTerm(value)

  return (
    <div className="flex flex-row gap-3 align-items-center">
      <SearchField
        searchTerm={medicineSearchTerm}
        setSearchTerm={handleMedSearch}
        placeholder="Search by medicine name"
        isLoading={isLoading}
      />
      <SitesSelect
        oneOrganization
        sites={availableSites}
        selectedSites={selectedSites}
        loading={isSitesLoading}
        onChange={setSelectedSites}
        placeholder="Filter by site"
      />
    </div>
  )
}

export default ControlBar
