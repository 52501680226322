import React, { useRef, useState } from 'react'
import { Card } from 'primereact/card'
import { get } from 'lodash'
import { Toast } from 'primereact/toast'
import { Divider } from 'primereact/divider'
import { useCurrentOrganization } from '../../../components/App'
import { useUpsertDose } from '../clientDosesHooks'
import DosePrnPermissionInput from '../../../components/display/DosePrnPermissionInput'
import DosePrnReasonRequiredInput from '../../../components/display/DosePrnReasonRequiredInput'
import CheckTimeInput from '../../../components/display/CheckTimeInput'

function PrnSettings({
  dose,
  patientId,
}) {
  const statusMessage = useRef()
  const organization = useCurrentOrganization()

  const isNarcotic = get(dose, 'medicine.csaSchedule', 0) >= 2
  const orgRequiresPrnReason = organization.prnReasonRequired === 'all_medicines'
    || (organization.prnReasonRequired === 'schedule_ii_v' && isNarcotic)

  const [
    checkTime, setCheckTime,
  ] = useState(dose.prnCheckTimeMinutes || organization.impactCheckTime)

  const [
    prnPermissionRequired, setPrnPermissionRequired,
  ] = useState(dose.prnPermissionRequired || organization.prnPermissionRequired)
  const [
    prnReasonRequired, setPrnReasonRequired,
  ] = useState(dose.prnReasonRequired || orgRequiresPrnReason)

  const {
    mutateAsync: upsertDose,
    isLoading: isUpsertingDose,
  } = useUpsertDose(
    {
      patientId,
      statusMessage,
      isUpdate: true,
    },
  )

  return (
    <div className="col-12" data-testid="prn-settings">
      <Card className="h-full">
        <Toast ref={statusMessage} />
        <div className="flex flex-column gap-2">
          <div className="flex flex-row gap-3 text-base text-lg">
            PRN Settings
            { isUpsertingDose
              && <i className="pi pi-spin pi-spinner" style={{ fontSize: '1.25rem' }} />}
          </div>
          <Divider className="my-0" />
          { organization.impactChecksEnabled
            && (
            <CheckTimeInput
              value={checkTime}
              onChange={(value) => {
                setCheckTime(value)
              }}
              onSubmit={() => {
                upsertDose({ id: dose.id, impactCheckTime: checkTime })
              }}
              disabled={isUpsertingDose}
            />
            )}
          <div className="flex flex-column">
            <div className="text-sm font-normal mb-2">
              Who needs permission before administering this medication?
            </div>
            <DosePrnPermissionInput
              value={prnPermissionRequired}
              onChange={(value) => {
                setPrnPermissionRequired(value)
                upsertDose({ id: dose.id, prnPermissionRequired: value })
              }}
              disabled={isUpsertingDose}
            />
          </div>
          { !orgRequiresPrnReason
            && (
            <div className="flex flex-column mt-3">
              <div className="text-sm font-normal mb-2">
                Is a reason required for administering this medication?
              </div>
              <DosePrnReasonRequiredInput
                value={prnReasonRequired}
                onChange={(value) => {
                  setPrnReasonRequired(value)
                  upsertDose({ id: dose.id, prnReasonRequired: value })
                }}
                disabled={isUpsertingDose}
              />
            </div>
            )}
        </div>
      </Card>
    </div>
  )
}

export default PrnSettings
