import React from 'react'
import { isEmpty } from 'lodash'
import { Button } from 'primereact/button'
import SitesSelect from '../../../features/Selects/SitesSelect/SitesSelect'
import { useSitesQuery } from '../../../features/Selects/SitesSelect/SitesSelectHooks'

function SitesSelector({
  selectedSites, setSelectedSites, handleConfirm, statusMessage,
}) {
  const {
    data: { sites } = [],
    isLoading: sitesLoading,
  } = useSitesQuery({ statusMessage })

  return (
    <div className="grid flex flex-grow-1 justify-content-center align-items-center fadein">
      <div className="col-9 flex flex-column gap-2 text-center">
        <label className="text-lg font-normal mb-1">
          What sites should this dose be available at?
        </label>
        <SitesSelect
          oneOrganization
          maxWidth="100%"
          sites={sites}
          selectedSites={selectedSites}
          loading={sitesLoading}
          onChange={setSelectedSites}
          placeholder="Select sites"
        />
        <Button
          onClick={handleConfirm}
          label="Next"
          className="p-button-text mt-2"
          disabled={isEmpty(sites)}
        />
      </div>
    </div>
  )
}

export default SitesSelector
