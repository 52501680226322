import { get } from 'lodash'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import {
  errorMessageFromError,
} from '@services/errorHandler'

export function approvePermissionRequestMutation(permissionRequestId, statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => ApiClient.post(`/permission_requests/${permissionRequestId}/approve`),
    onSuccess: () => {
      queryClient.invalidateQueries('permission_requests')
    },
    onError: (error) => {
      if (!get(statusMessage, 'current')) return
      statusMessage.current.clear()
      statusMessage.current.show([
        errorMessageFromError(error),
      ])
    },
  })
}

export function rejectPermissionRequestMutation(permissionRequestId, statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => ApiClient.post(`/permission_requests/${permissionRequestId}/reject`),
    onSuccess: () => {
      queryClient.invalidateQueries('permission_requests')
    },
    onError: (error) => {
      if (!get(statusMessage, 'current')) return
      statusMessage.current.clear()
      statusMessage.current.show([
        errorMessageFromError(error),
      ])
    },
  })
}
