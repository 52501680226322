import { useMemo } from 'react'
import { map, get } from 'lodash'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import {
  displayErrorMessages,
} from '@services/errorHandler'

export function usePatientClinicalNotes(patientId, statusMessage) {
  const query = useQuery({
    queryKey: ['patientClinicalNotes', patientId],
    queryFn: () => ApiClient.get('/clinical_notes', { params: { patient_id: patientId } }),
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })

  return useMemo(() => ({
    ...query,
    data: map(get(query, 'data'), (clinicalNote) => (clinicalNote)),
  }), [query.data])
}

export function useCreatePatientClinicalNotesMutation(statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (clinicalNote) => ApiClient.post('/clinical_notes', {
      clinicalNote,
    }),
    onSuccess: () => {
      queryClient.invalidateQueries('patientClinicalNotes')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useUpdatePatientClinicalNoteMutation(statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (clinicalNote) => ApiClient.put(`clinical_notes/${clinicalNote.id}`, {
      clinicalNote,
    }),
    onSuccess: () => {
      queryClient.invalidateQueries('patientClinicalNotes')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}
